export class LeaveTypeList {
    id: number;
    leave_type: string;
}

export class TsLeave {
    id?: number;
    leg_id?: number;
    leg_table_id?: number;
    technical_specialist_id?: number;
    r_leave_type_id?: number;
    date_from?: string;
    date_to?: string;
    distribution?: string;
    reason_for_leave: string;
    sequence?: number;
    organization_unit_id?: number;
    creation_time?: string;
    creator_user_id?: number;
    last_modification_time?: string;
    last_modifier_user_id?: number;
    is_deleted?: boolean;
    deleter_user_id?: number;
    deletion_time?: string;
    is_locked?: boolean;
}

export class LeaveDistributionList {
    ts_name: string;
    ts_email: string;
    date_from: string;
    date_to: string;
    comments: string;
    sender: string;
    to_distribution_list: string;
    cc_distribution_list: string;
    bcc_distribution_list: string;
}
// export class LeaveRequestModel
// {
//     id?: number;
//     badgeColor?: string;
//     creationTime?: string;
//     creatorUserId?: number;
//     dateFrom?: string;
//     dateTo?: string;
//     deleterUserId?: number;
//     deletionTime?: string;
//     distribution?: string;
//     isDeleted?: boolean;
//     isLocked?: boolean;
//     lastModificationTime?: string;
//     lastModifierUserId?: number;
//     leaveTypeId?: number;
//     name?: string;
//     organizationUnitId?: number;
//     reasonForLeave?: string;
//     sequence?: number;
//     tsId?: number;
//     userId?: number;
// }

// export class TsLeaveRequestModel {
//     bcc_distribution_list: string;
//     cc_distribution_list: string;
//     comments: string;
//     date_from: Date;
//     date_to: Date;
//     sender: string;
//     to_distribution_list: string;
//     ts_email: string;
//     ts_name: string;
// }
export class LeaveCheckResult {
    leave_exists: number;
}
export class LeaveCheckParams {
    technical_specialist_id: number;
    date_from: string;
    date_to: string;
}
