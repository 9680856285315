export class WeekDate {
        week: Week;
        month: Month;
        year: Year;
        day: {
            monday: Day;
            tuesday: Day;
            wednesday: Day;
            thursday: Day;
            friday: Day;
            saturday: Day;
            sunday: Day;
        };
        todays_julian_date?: number;
        selected_date?: Date;
}

export interface Week {
    number: number;
    offset: number;
    start: string;
    end: string;
    start_end_dates: string;
}

export interface Month {
    number: number;
    start: string;
    end: string;
}

export interface Year {
    number: number;
    start: string;
    end: string;
}

export interface Day {
    dom: string;
    month: string;
    julian_date: number;
    calendar_date: string;
}
