import { NgModule } from '@angular/core';
import { NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { NgxSpinnerService } from 'ngx-spinner';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [
                            { path: 'notifications', component: NotificationsComponent },
                            { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
                        ],
                    },
                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then((m) => m.MainModule), //Lazy load main module
                        data: { preload: true },
                    },
                    {
                        path: 'home',
                        loadChildren: () => import('app/home/home.module').then((m) => m.HomeModule), //Lazy load main module
                        data: { preload: true },
                    },
                    {
                        path: 'finance',
                        loadChildren: () => import('app/finance/finance.module').then(m => m.FinanceModule),
                        data: { preload: true },
                    },
                    {
                        path: 'leave',
                        loadChildren: () => import('app/leave/leave.module').then(m => m.LeaveModule),
                        data: { preload: true },
                    },
                    {
                        path: 'referral',
                        loadChildren: () => import('app/referral/referral.module').then(m => m.ReferralModule),
                        data: { preload: true },
                    },
                    {
                        path: 'email',
                        loadChildren: () => import('app/email/email.module').then(m => m.EmailModule),
                        data: { preload: true },
                    },
                    {
                        path: 'svl',
                        loadChildren: () => import('app/svl/svl.module').then(m => m.SvlModule),
                        data: { preload: true },
                    },
                    {
                        path: 'my-profile',
                        loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule),
                        data: { preload: true },
                    },


                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: '**',
                        redirectTo: 'notifications',
                    },
                ],
            },
            { path: 'finance', loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule) },

        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router, private spinnerService: NgxSpinnerService) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                spinnerService.show();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
                spinnerService.hide();
            }
        });
    }
}
