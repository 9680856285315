import { Injectable } from '@angular/core';
import { CheckEmailModel, ReferralDistributionList, ReferralModel } from '../_modal/referral.model';
import { HttpBaseService } from '@app/shared/_services/http-base.service';
import { Observable } from 'rxjs';
import { GetManyFilter } from '@app/shared/_interfaces/get-many-filter';

@Injectable({
    providedIn: 'root'
})

export class ReferralService {
    
    isCollapsed = true;

    constructor(
        private _http: HttpBaseService,
        //private _http: HttpBaseService,
    ) {}

    getReferralsByTsId(tsId) {
        return this._http.get('/referral/get-referrals-by-ts-id/' + tsId);
    }
    
    checkEmail(params: GetManyFilter): Observable<ReferralModel> {
        return this._http.post('/referral/get-many/', params);
    }

    createReferral(params: ReferralModel) {
        return this._http.post('/referral/create-one', params );
    }

    deleteReferral(referral_id: number) {
        return this._http.delete(`/referral/delete-referral/${referral_id}`);
    }
    //createReferral(referralModel: ReferralModel): Observable<number> {
    //    return this.http.post<number>(
    //        environment.dataapi + 'Referral/CreateReferral', referralModel,
    //        { headers: this.global.dataApiHeaders }
    //    );
    //}
}