/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
//import { ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import { RootModule } from './root.module';

import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
config({ licenseKey });
//import * as Sentry from "@sentry/angular-ivy";
//import { enableDebugTools } from '@angular/platform-browser';

//Sentry.init({
//    dsn: "https://330ef338168546568a59c513f4b21b3e@o250828.ingest.sentry.io/4505567637864448",
//    integrations: [
//      new Sentry.BrowserTracing({
//        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//      }),
//      new Sentry.Replay(),
//    ],
//    // Performance Monitoring
//    tracesSampleRate: 1.0, //  Capture 100% of the transactions
//    // Session Replay
//    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//  });

  

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(RootModule);

/* "Hot Module Replacement" is enabled as described on
 * https://medium.com/@beeman/tutorial-enable-hrm-in-angular-cli-apps-1b0d13b80130#.sa87zkloh
 */

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap); //HMR enabled bootstrap
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap()
    //.then(moduleRef => {
        //const applicationRef = moduleRef.injector.get(ApplicationRef);
        //const componentRef = applicationRef.components[0];
        //enableDebugTools(componentRef);
    //  })
    //  .catch(err => console.error(err));
    ; //Regular bootstrap
}
