import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { WhiteCoastCommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';

import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { SingleLineStringInputTypeComponent } from './input-types/single-line-string-input-type/single-line-string-input-type.component';
import { ComboboxInputTypeComponent } from './input-types/combobox-input-type/combobox-input-type.component';
import { CheckboxInputTypeComponent } from './input-types/checkbox-input-type/checkbox-input-type.component';
import { MultipleSelectComboboxInputTypeComponent } from './input-types/multiple-select-combobox-input-type/multiple-select-combobox-input-type.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PasswordInputWithShowButtonComponent } from './password-input-with-show-button/password-input-with-show-button.component';
import { KeyValueListManagerComponent } from './key-value-list-manager/key-value-list-manager.component';

import { ComponentSharedModule } from '../_components/component-shared.module';

import { ModalModule } from 'ngx-bootstrap/modal';
// import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
    BsDatepickerModule,
    BsDatepickerConfig,
    BsDaterangepickerConfig,
    BsLocaleService,
} from 'ngx-bootstrap/datepicker';
// import { PaginatorModule } from 'primeng/paginator';
// import { TableModule } from 'primeng/table';
// import { PerfectScrollbarModule } from '@craftsjs/perfect-scrollbar';
// import { Angular2CountoModule } from '@awaismirza/angular2-counto';

import { SubheaderModule } from './sub-header/subheader.module';




// vvv NON VANILLA 
// import { NgSelectModule } from '@ng-select/ng-select';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { TooltipModule } from 'ngx-bootstrap/tooltip';
//import { LeaveRequestAddComponent } from '@app/leave/leave-request-add/leave-request-add.component';
//import { LeaveRequestEditComponent } from '@app/leave/leave-request-edit/leave-request-edit.component';

//import { SubMenuModule } from './sub-menu/sub-menu.module';

//PRIMENG
// import { AccordionModule } from 'primeng/accordion';
// import { CalendarModule } from 'primeng/calendar';
// import { CardModule } from 'primeng/card';
// import { ChipModule } from 'primeng/chip';
// import { ContextMenuModule } from 'primeng/contextmenu';
// import { DropdownModule } from 'primeng/dropdown';
// import { EditorModule } from 'primeng/editor';
// import { FieldsetModule } from 'primeng/fieldset';
// import { InputTextareaModule } from 'primeng/inputtextarea';
// import { InputTextModule } from 'primeng/inputtext';
// import { ListboxModule } from 'primeng/listbox';
// import { MenubarModule } from 'primeng/menubar';
// import { MenuModule } from 'primeng/menu';
// import { OverlayPanelModule } from 'primeng/overlaypanel';
// import { PanelModule } from 'primeng/panel'
// import { PickListModule } from 'primeng/picklist';
// import { ProgressSpinnerModule } from 'primeng/progressspinner';
// import { ScrollPanelModule } from 'primeng/scrollpanel';
// import { SidebarModule } from 'primeng/sidebar';
// import { SliderModule } from 'primeng/slider'
// import { TabViewModule } from 'primeng/tabview'
// import { TagModule } from 'primeng/tag';
// import { ToggleButtonModule } from 'primeng/togglebutton';
// import { TreeSelectModule } from 'primeng/treeselect';
//import { ReferralAddComponent } from '@app/referral/referral-add/referral-add.component';
//import { SvlAddEditComponent } from '@app/svl/svl-add-edit/svl-add-edit.component';

//DEVEXPRESS

// import { DxAccordionModule } from 'devextreme-angular';
// import { DxCheckBoxModule } from 'devextreme-angular';
// import { DxDataGridModule, DxPopupModule, DxTreeListModule, DxButtonModule } from 'devextreme-angular';
// import { DxDateBoxModule } from 'devextreme-angular';
// import { DxListModule } from 'devextreme-angular';
// import { DxMultiViewModule } from 'devextreme-angular';
// import { DxSparklineModule } from 'devextreme-angular';
// import { DxToastModule } from 'devextreme-angular';

@NgModule({
    imports: [
        ComponentSharedModule,
        // Angular2CountoModule,
        AppBsModalModule,
        AutoCompleteModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        CommonModule,
        //FontAwesomeModule,
        FormsModule,
        ModalModule.forRoot(),
        //NgSelectModule,
        // PaginatorModule,
        // PerfectScrollbarModule,
        ReactiveFormsModule,
        // TableModule,
        // TabsModule.forRoot(),
        //TooltipModule.forRoot(),
        // AccordionModule,
        // CalendarModule,
        // CardModule,
        // ChipModule,
        // ContextMenuModule,
        // DropdownModule,
        // EditorModule,
        // FieldsetModule,
        // InputTextareaModule,
        // InputTextModule,
        // ListboxModule,
        // MenubarModule,
        // MenuModule,
        // OverlayPanelModule,
        // PanelModule,
        // PickListModule,
        // ProgressSpinnerModule,
        // ScrollPanelModule,
        // SidebarModule,
        // SliderModule,
        // TabViewModule,
        // TagModule,
        // ToggleButtonModule,
        // TreeSelectModule,
        UtilsModule,
        WhiteCoastCommonModule,
        // DxAccordionModule,
        // DxCheckBoxModule,
        // DxDataGridModule,
        // DxDateBoxModule,
        // DxListModule,
        // DxMultiViewModule,
        // DxSparklineModule,
        // DxToastModule,
        // DxPopupModule, 
        // DxTreeListModule, 
        // DxButtonModule,
        //SubMenuModule,
        SubheaderModule
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        MultipleSelectComboboxInputTypeComponent,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        //LeaveRequestAddComponent,
        //LeaveRequestEditComponent,
        //ReferralAddComponent,
        //SvlAddEditComponent,

    ],
    exports: [
        ComponentSharedModule,
        AppBsModalModule,
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        //FontAwesomeModule,
        //TooltipModule,
        //NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        //LeaveRequestAddComponent,
        //LeaveRequestEditComponent,
        //ReferralAddComponent,
        //SvlAddEditComponent,
        AutoCompleteModule,
        // PaginatorModule,
        // TableModule,
        // DxAccordionModule,
        // DxCheckBoxModule,
        // DxDataGridModule,
        // DxDateBoxModule,
        // DxListModule,
        // DxMultiViewModule,
        // DxSparklineModule,
        // DxToastModule,
        // DxPopupModule, 
        // DxTreeListModule, 
        // DxButtonModule,
        // AccordionModule,
        // CalendarModule,
        // CardModule,
        // ChipModule,
        // ContextMenuModule,
        // DropdownModule,
        // EditorModule,
        // FieldsetModule,
        // InputTextareaModule,
        // InputTextModule,
        // ListboxModule,
        // MenubarModule,
        // MenuModule,
        // OverlayPanelModule,
        // PanelModule,
        // PickListModule,
        // ProgressSpinnerModule,
        // ScrollPanelModule,
        // SidebarModule,
        // SliderModule,
        // TabViewModule,
        // TagModule,
        // ToggleButtonModule,
        // TreeSelectModule,
        //SubMenuModule,
        SubheaderModule
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
        { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [AppAuthService, AppRouteGuard],
        };
    }
}
