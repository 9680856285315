// prettier-ignore
export class RowIdentifier {
    day_index     ?: number;
    row_index     ?: number;
    rec_id        ?: any;
    work_type_id  ?: number;
    field_type    ?: string;
    event         ?: any;
    from          ?: string;
    auto_save     ?: boolean;
    selected_date ?: string;
    prompt_type   ?: string;
    head          ?: string;
    receiver      ?: string;
    focus_field   ?: string;
    action        ?: string;
    po_index      ?: number;
    fetch_row     ?: string;
    fetch_day     ?: string;
    save_row      ?: string;
}
