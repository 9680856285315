// "Production" enabled environment

import { HttpHeaders } from "@angular/common/http";

export const environment = {
    production: true,
    hmr: false,
    appConfig: 'appconfig.production.json',

    xApiKey: 'ca03na188ame03u1d78620de67282882a84',

    apiBaseUrl: 'https://wc-api-13.ntslabs-unitek.com',

    mockApiBaseUrl: 'http://10.0.1.4:3005',

    apiHeaders: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('x-api-key', 'ca03na188ame03u1d78620de67282882a84'),

    mockApiHeaders: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('x-api-key', 'ca03na188ame03u1d78620de67282882a84x'),

    axHeaders: { 'content-type': 'application/json', 'x-api-key': 'ca03na188ame03u1d78620de67282882a84' },

    axMultiPartFormHeaders: 
        {
            //'content-type': 'multipart/form-data; boundary=---011000010111000001101001',
            'x-api-key': 'ca03na188ame03u1d78620de67282882a84'
        },

    multiPartApiHeaders: new HttpHeaders().set('x-api-key', 'ca03na188ame03u1d78620de67282882a84'),

    azureMultiPartApiHeaders: new HttpHeaders().set(
        'x-functions-key',
        'FoUdP_hKo1uVFOZeEVwXCTnjRhTHgPcmy_LNR3HcH3BvAzFuOnvGBg==',
    ),
};
