import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { Observable } from 'rxjs';

export class HashModel
    {
        dbid: number;
        hash: string;
        key: string;
        value: string;
        p0?: string;
        p1?: string;
        p2?: string;
        p3?: string;
        p4?: string;
        p5?: string;
        p6?: string;
        p7?: string;
        p8?: string;
        p9?: string;
        p10?: string;
        p11?: string;
        p12?: string;
        p13?: string;
        p14?: string;
        p15?: string;
        p16?: string;
        p17?: string;
        p18?: string;
        p19?: string;
    }
export class StringModel {
  dbid: number;
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class RedisService {

  hashModel: HashModel = new HashModel();
  stringModel: StringModel = new StringModel();

  constructor(
    private httpBaseService: HttpBaseService
  ) { }

  getString(dbid: number, key: string): Observable<string> {
    const apiUrl = 'v1/dyn/string/' + dbid + '/' + key;
    return this.httpBaseService.getReturnText(apiUrl);
  }
  hashSet(): Observable<any[]> {
    const apiUrl = 'v1/dyn/HashSet';
    return this.httpBaseService.postReturnText(apiUrl, this.hashModel);
  }
  stringSet(str: string): Observable<any[]> {
    this.stringModel.dbid = 0; //COMMON DB
    this.stringModel.key = 'env';
    this.stringModel.value = str;
    const apiUrl = 'v1/dyn/StringSet';
    return this.httpBaseService.postReturnText(apiUrl, this.stringModel);
  }
  hashGetAll(dbid, hash): Observable<HashModel[]> {
    this.hashModel = new HashModel();
    this.hashModel.dbid = dbid;
    this.hashModel.hash = hash;
    const apiUrl = 'v1/dyn/HashGetAll';
    return this.httpBaseService.post(apiUrl, this.hashModel);
  }
  // getTimecardWorkTypes(clientId): Observable<WorkType[]> {
  //   const apiUrl = 'WorkType/GetTimecardWorkTypes/' + abp.session.userId + '/false' + '/' + clientId;
  //   return this.httpBaseService.get(apiUrl);
  // }
}
