<p-autoComplete
    [(ngModel)]="selected"
    [suggestions]="searchMenuResults"
    (completeMethod)="searchMenuItem($event)"
    *ngIf="isMenuSearchActive"
    (onSelect)="selectMenuItem($event)"
    field="name"
    placeholder="{{ 'QuickNav' | localize }}"
    [minLength]="1"
    inputStyleClass="form-control menuSearchBarInput"
    styleClass="menuSearchBarContainer"
></p-autoComplete>
