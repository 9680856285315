import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'day'
})
export class DayPipe implements PipeTransform {

  transform(value: any) {
    const date = new Date(value);
    return String(date.getDate()).padStart(2, '0');
  }

}