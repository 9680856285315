import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PoPart } from '../_models/po-part.interface';
import { Timecard } from '../_models/timecard.interface';
import { CalendarParams } from '../_models/calendar-params.interface';
import { RootService } from '@app/shared/_services/root.service';

@Injectable({
    providedIn: 'root',
})
export class TimecardApiService {
    constructor(
        //private _http: HttpBaseService
        private _root: RootService,
        ) {}

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    getTimecardStub(tsId, week_number): Observable<any> {
        return this._root._http.post('/timecard/timecard-stub', {
            ts_id: `${tsId}`,
            week_number: `${week_number}`
            });
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    getPayrollCalendar(): Observable<any> {
        return this._root._http.mget('/timecard/payroll-calendar');
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    getByTsAndWeek(tsId, type, week, year): Observable<Timecard[]> {
        return this._root._http.post('/request/get-ts-week', {
            technical_specialist_id: `${tsId}`,
            type: `${type}`,
            week: `${week}`,
            year: `${year}`,
        });
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    getPoPartByTsAndWeek(tsId, type, week, year): Observable<PoPart[]> {
        const params = {
            technical_specialist_id: `${tsId}`,
            type: `${type}`,
            week: `${week}`,
            year: `${year}`,
        };
        return this._root._http.post('/request/get-po-part-ts-week', params);
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    findByWeekMgo(body: CalendarParams): Observable<Timecard[]> {
        return this._root._http.post('/timecard-submission/mgo/find-by-week', body);
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    createTeEntryMgo(body: Timecard): Observable<Timecard> {
        //body.errors = body.errors;
        return this._root._http.post('/timecard-submission/mgo/create', body);
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    updateTeEntryMgo(body: Timecard): Observable<Timecard> {
        return this._root._http.post('/timecard-submission/mgo/update', body);
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    getWorkTypeListByClientId(clientId) {
        return this._root._http.get(`/r-work-type/client-id/${clientId}`);
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    getRedisTimecardKeys(body: { key: string }) {
        return this._root._http.post('/redis/members', body);
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
}
