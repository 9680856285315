<div [@routerTransition] class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
        <!--begin::Wrapper-->
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <!--begin::Header-->
            <div
                id="kt_header"
                class="header"
                data-kt-sticky="true"
                data-kt-sticky-name="header"
                data-kt-sticky-offset="{default: '200px', lg: '300px'}"
                style="animation-duration: 0.3s"
            >
                <!--begin::Container-->
                <div [class]="containerClass + ' d-flex flex-grow-1 flex-stack'">
                    <!--begin::Header Logo-->
                    <div class="d-flex align-items-center me-5">
                        <!--begin::Heaeder menu toggle-->
                        <div
                            class="d-lg-none btn btn-icon btn-active-color-primary w-30px h-30px ms-n2 me-3"
                            id="kt_header_menu_toggle"
                        >
                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                            <span class="svg-icon svg-icon-2">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        opacity="0.3"
                                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </div>
                        <!--end::Heaeder menu toggle-->
                        <!-- <theme11-brand></theme11-brand> -->
                        <top-bar-menu
                            [menuClass]="
                                'menu menu-rounded menu-column menu-lg-row menu-root-here-bg-desktop menu-active-bg menu-state-primary menu-title-gray-800 menu-arrow-gray-400 align-items-stretch flex-grow-1 my-5 my-lg-0 px-2 px-lg-0 fw-semibold fs-6'
                            "
                        ></top-bar-menu>
                    </div>
                    <!--end::Header Logo-->
                    <!--begin::Topbar-->
                    <div class="d-flex align-items-center flex-shrink-0">
                        <active-delegated-users-combo [customStyle]="'me-2'"></active-delegated-users-combo>
                        <subscription-notification-bar
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px position-relative'
                            "
                        ></subscription-notification-bar>
                        <quick-theme-selection
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px position-relative'
                            "
                        ></quick-theme-selection>
                        <language-switch-dropdown
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px position-relative'
                            "
                        ></language-switch-dropdown>
                        <header-notifications
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px position-relative'
                            "
                        ></header-notifications>
                        <chat-toggle-button
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px position-relative'
                            "
                        ></chat-toggle-button>

                        <toggle-dark-mode
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px position-relative'
                            "
                            [isDarkModeActive]="isDarkModeActive()"
                        ></toggle-dark-mode>

                        <user-menu [togglerCssClass]="'symbol symbol-40px cursor-pointer'"></user-menu>
                    </div>
                    <!--end::Topbar-->
                </div>
                <!--end::Container-->
                <!--begin::Separator-->
                <!-- <div class="separator"></div> -->
                <!--end::Separator-->
                <!--begin::Container-->
                <div *ngIf="false" class="header-menu-container container-xxl d-flex flex-stack h-lg-75px w-100" id="kt_header_nav">
                    <!--begin::Menu wrapper-->
                    <div
                        class="header-menu flex-column flex-lg-row"
                        data-kt-drawer="true"
                        data-kt-drawer-name="header-menu"
                        data-kt-drawer-activate="{default: true, lg: false}"
                        data-kt-drawer-overlay="true"
                        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                        data-kt-drawer-direction="start"
                        data-kt-drawer-toggle="#kt_header_menu_toggle"
                        data-kt-swapper="true"
                        data-kt-swapper-mode="prepend"
                        data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_header_nav'}"
                    >
                        <top-bar-menu
                            [menuClass]="
                                'menu menu-rounded menu-column menu-lg-row menu-root-here-bg-desktop menu-active-bg menu-state-primary menu-title-gray-800 menu-arrow-gray-400 align-items-stretch flex-grow-1 my-5 my-lg-0 px-2 px-lg-0 fw-semibold fs-6'
                            "
                        ></top-bar-menu>
                        <div class="flex-shrink-0 p-4 p-lg-0 me-lg-2"></div>
                        <!--end::Actions-->
                    </div>
                    <!--end::Menu wrapper-->
                </div>
                <!--end::Container-->
            </div>
            <!--end::Header-->
            <router-outlet></router-outlet>
            <footer-bar style="position: absolute;bottom: 0;"></footer-bar>
            <!--end::Footer-->
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Page-->
</div>
