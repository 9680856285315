<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div
                id="kt_header"
                class="header align-items-stretch"
                data-kt-sticky="true"
                data-kt-sticky-name="header"
                data-kt-sticky-offset="{default: '200px', lg: '300px'}"
            >
                <div [class]="containerClass + '  d-flex align-items-center'">
                    <!--begin::Heaeder menu toggle-->
                    <div class="d-flex topbar align-items-center d-lg-none ms-n2 me-3" title="Show aside menu">
                        <div
                            class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                            id="kt_header_menu_mobile_toggle"
                        >
                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                            <span class="svg-icon svg-icon-1">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        opacity="0.3"
                                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </div>
                    </div>
                    <theme2-brand></theme2-brand>
                    <!--end::Aside mobile toggle-->
                    <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1 ms-10">
                        <div class="d-flex align-items-stretch" id="kt_header_nav">
                            <div
                                class="header-menu align-items-stretch"
                                data-kt-drawer="true"
                                data-kt-drawer-name="header-menu"
                                data-kt-drawer-activate="{default: true, lg: false}"
                                data-kt-drawer-overlay="true"
                                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                                data-kt-drawer-direction="start"
                                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                                data-kt-swapper="true"
                                data-kt-swapper-mode="prepend"
                                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                            >
                                <top-bar-menu class="w-100"></top-bar-menu>
                            </div>
                        </div>
                        <div class="d-flex align-items-stretch flex-shrink-0">
                            <div class="topbar d-flex align-items-stretch flex-shrink-0">
                                <active-delegated-users-combo></active-delegated-users-combo>
                                <subscription-notification-bar
                                    [customStyle]="
                                        'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'
                                    "
                                ></subscription-notification-bar>
                                <quick-theme-selection
                                    [customStyle]="
                                        'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'
                                    "
                                ></quick-theme-selection>

                                <language-switch-dropdown
                                    [customStyle]="
                                        'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'
                                    "
                                ></language-switch-dropdown>

                                <header-notifications
                                    [customStyle]="
                                        'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'
                                    "
                                ></header-notifications>

                                <chat-toggle-button
                                    [customStyle]="
                                        'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'
                                    "
                                ></chat-toggle-button>

                                <toggle-dark-mode
                                    [customStyle]="
                                        'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'
                                    "
                                    [isDarkModeActive]="isDarkModeActive()"
                                ></toggle-dark-mode>

                                <user-menu
                                    [iconOnly]="true"
                                    [togglerCssClass]="
                                        'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'
                                    "
                                ></user-menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <router-outlet></router-outlet>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
