import { Injectable, Injector } from '@angular/core';
import moment from 'moment';
import { Timecard } from '../_models/timecard.interface';
import { CalendarParams } from '../_models/calendar-params.interface';
import { ClonePipe } from '@app/shared/_pipes/clone.pipe';
import { RootService } from '@app/shared/_services/root.service';
import { TimecardVariablesService } from './timecard-variables.service';
import { TimecardMainService } from './timecard-main.service';
import { TimecardApiService } from './timecard-api.service';



@Injectable({
    providedIn: 'root',
})
export class TimecardDataService {

    constructor(
        private clone: ClonePipe,
        private _root: RootService,
        //private _var: TimecardVariablesService,
        //private _main: TimecardMainService,
    ) {
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    //prettier-ignore
    parseEventValues(e): Promise<string> {
        return new Promise((resolve, reject) => {
            if (e?.target?.dataset?.resetRowIdentifier === 'true') {
                this._root._var.rowIdentifier = this._root._var.initRowIdentifier;
            }

            this._root._var.rowIdentifier.event = e ? e : this._root._var.rowIdentifier.event;
            this._root._var.rowIdentifier.row_index = e?.target?.dataset?.rowIndex >= 0 ? e.target.dataset.rowIndex : this._root._var.rowIdentifier.row_index;
            this._root._var.rowIdentifier.day_index = e?.target?.dataset?.dayIndex >= 0 ? e.target.dataset.dayIndex : this._root._var.rowIdentifier.day_index;
            this._root._var.rowIdentifier.field_type = e?.target?.dataset?.fieldType? e.target.dataset.fieldType: this._root._var.rowIdentifier.field_type;
            this._root._var.rowIdentifier.fetch_row = e?.target?.dataset?.fetchRow? e.target.dataset.fetchRow: this._root._var.rowIdentifier.fetch_row;
            this._root._var.rowIdentifier.fetch_day = e?.target?.dataset?.fetchDay? e.target.dataset.fetchDay: this._root._var.rowIdentifier.fetch_day;
            this._root._var.rowIdentifier.save_row = e?.target?.dataset?.saveRow? e.target.dataset.saveRow: this._root._var.rowIdentifier.save_row;

            this._root._var.rowIdentifier = this.clone.transform(this._root._var.rowIdentifier);
            resolve('parseEventValues(2)');
        });
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    setRowIdentifier() {
        if (this._root._var.rowIdentifier.save_row === 'true') {
            this.saveRow();
        }
        if (this._root._var.rowIdentifier.fetch_row === 'true' && this._root._var.selectedRowIndex !== this._root._var.rowIdentifier.row_index) {
            //this.getRow();
            this.setDayHeaders();
        }
        if (this._root._var.rowIdentifier.fetch_day === 'true') {
            if (
                this._root._var.selectedDayIndex !== this._root._var.rowIdentifier.day_index && //** if day_index is different
                this._root._var.selectedRowIndex === this._root._var.rowIdentifier.row_index //and row is the same, getday will be run
            ) {
                //this.getDay();
                this.setDayHeaders();
            }
        }
        if (this._root._var.rowIdentifier.field_type === 'expenses') {
            this._root._main.showExpenseModal();
        } else if (this._root._var.rowIdentifier.field_type === 'po_parts') {
            this._root._main.showPoPartsModal();
        }
    }
    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    setDayHeaders() {
        moment.updateLocale('en', {
            week: {
                dow: 1, //?-- ** First day of week is Monday **
            },
        });
        this._root._var.isEditMode = false;
        this._root._var.expenseModalHeader = `Expenses for ${moment()
            .day(Number(this._root._var.rowIdentifier.day_index) + 1)
            .isoWeek(Number(this._root._var.page.weekDates.week.number))
            .format('dddd LL')}`;
        this._root._var.poPartsModalHeader = `PO/Parts for ${moment()
            .day(Number(this._root._var.rowIdentifier.day_index) + 1)
            .isoWeek(Number(this._root._var.page.weekDates.week.number))
            .format('dddd LL')}`;
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    setWeekData(offset): Promise<string> {
        return new Promise((resolve, reject) => {
            this._root._var.page.weekDates.week.offset = offset === 0 ? 0 : this._root._var.page.weekDates.week.offset + offset;
            this._root._var.page.weekDates.selected_date = moment()
                .add(this._root._var.page.weekDates.week.offset, 'weeks')
                .startOf('isoWeek')
                .add(0, 'days')
                .toDate();
            this._root._var.page.weekDates.week.number = moment(this._root._var.page.weekDates.selected_date).isoWeek();
            this._root._var.page.weekDates.year.number = moment(this._root._var.page.weekDates.selected_date).year();

            this._root._var.page.weekDates.week = {
                number: parseInt(
                    moment().add(this._root._var.page.weekDates.week.offset, 'weeks').locale('en-gb').startOf('week').format('W'),
                ),
                offset: this._root._var.page.weekDates.week.offset,
                start: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .format('MM/DD/YYYY'),
                end: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(6, 'day')
                    .format('MM/DD/YYYY'),
                start_end_dates: `${moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .format('MM/DD/YYYY')},${moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .endOf('week')
                    .format('MM/DD/YYYY')}`,
            };
            this._root._var.page.weekDates.month = {
                number: parseInt(
                    moment().add(this._root._var.page.weekDates.week.offset, 'weeks').locale('en-gb').startOf('month').format('M'),
                ),
                start: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('month')
                    .format('MM/DD/YYYY'),
                end: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .endOf('month')
                    .format('MM/DD/YYYY'),
            };
            this._root._var.page.weekDates.year = {
                number: parseInt(
                    moment().add(this._root._var.page.weekDates.week.offset, 'weeks').locale('en-gb').startOf('week').format('Y'),
                ),
                start: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('year')
                    .format('MM/DD/YYYY'),
                end: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .endOf('year')
                    .format('MM/DD/YYYY'),
            };
            this._root._var.page.weekDates.day.monday = {
                dom: moment().add(this._root._var.page.weekDates.week.offset, 'weeks').locale('en-gb').startOf('week').format('D'),
                month: moment().add(this._root._var.page.weekDates.week.offset, 'weeks').locale('en-gb').startOf('week').format('MMM'),
                julian_date: parseInt(
                    moment().add(this._root._var.page.weekDates.week.offset, 'weeks').locale('en-gb').startOf('week').format('YYYYDDDD'),
                ),
                calendar_date: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .format('MM/DD/YYYY'),
            };
            this._root._var.page.weekDates.day.tuesday = {
                dom: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(1, 'day')
                    .format('D'),
                month: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(1, 'day')
                    .format('MMM'),
                julian_date: parseInt(
                    moment()
                        .add(this._root._var.page.weekDates.week.offset, 'weeks')
                        .locale('en-gb')
                        .startOf('week')
                        .add(1, 'day')
                        .format('YYYYDDDD'),
                ),
                calendar_date: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(1, 'day')
                    .format('MM/DD/YYYY'),
            };
            this._root._var.page.weekDates.day.wednesday = {
                dom: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(2, 'day')
                    .format('D'),
                month: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(2, 'day')
                    .format('MMM'),
                julian_date: parseInt(
                    moment()
                        .add(this._root._var.page.weekDates.week.offset, 'weeks')
                        .locale('en-gb')
                        .startOf('week')
                        .add(2, 'day')
                        .format('YYYYDDDD'),
                ),
                calendar_date: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(2, 'day')
                    .format('MM/DD/YYYY'),
            };
            this._root._var.page.weekDates.day.thursday = {
                dom: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(3, 'day')
                    .format('D'),
                month: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(3, 'day')
                    .format('MMM'),
                julian_date: parseInt(
                    moment()
                        .add(this._root._var.page.weekDates.week.offset, 'weeks')
                        .locale('en-gb')
                        .startOf('week')
                        .add(3, 'day')
                        .format('YYYYDDDD'),
                ),
                calendar_date: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(3, 'day')
                    .format('MM/DD/YYYY'),
            };
            this._root._var.page.weekDates.day.friday = {
                dom: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(4, 'day')
                    .format('D'),
                month: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(4, 'day')
                    .format('MMM'),
                julian_date: parseInt(
                    moment()
                        .add(this._root._var.page.weekDates.week.offset, 'weeks')
                        .locale('en-gb')
                        .startOf('week')
                        .add(4, 'day')
                        .format('YYYYDDDD'),
                ),
                calendar_date: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(4, 'day')
                    .format('MM/DD/YYYY'),
            };
            this._root._var.page.weekDates.day.saturday = {
                dom: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(5, 'day')
                    .format('D'),
                month: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(5, 'day')
                    .format('MMM'),
                julian_date: parseInt(
                    moment()
                        .add(this._root._var.page.weekDates.week.offset, 'weeks')
                        .locale('en-gb')
                        .startOf('week')
                        .add(5, 'day')
                        .format('YYYYDDDD'),
                ),
                calendar_date: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(5, 'day')
                    .format('MM/DD/YYYY'),
            };
            this._root._var.page.weekDates.day.sunday = {
                dom: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(6, 'day')
                    .format('D'),
                month: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(6, 'day')
                    .format('MMM'),
                julian_date: parseInt(
                    moment()
                        .add(this._root._var.page.weekDates.week.offset, 'weeks')
                        .locale('en-gb')
                        .startOf('week')
                        .add(6, 'day')
                        .format('YYYYDDDD'),
                ),
                calendar_date: moment()
                    .add(this._root._var.page.weekDates.week.offset, 'weeks')
                    .locale('en-gb')
                    .startOf('week')
                    .add(6, 'day')
                    .format('MM/DD/YYYY'),
            };
            this._root._var.page.weekDates.todays_julian_date = parseInt(moment().format('YYYYDDDD'));
            this._root._var.page.weekDates.selected_date = moment()
                .add(this._root._var.page.weekDates.week.offset, 'weeks')
                .locale('en-gb')
                .startOf('week')
                .toDate();

            //this._root._var.page.weekDatesForm.reset(this._root._var.page.weekDates);
            //this._ms.onFormChange('weekDatesForm');

            //this._root._f2.createRootForm({weekDates: this._root._var.page.weekDates});
            resolve('setWeekData(3)');
        });
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    saving() {
        setTimeout(() => {
            this._root._var.savingRow = !this._root._var.savingRow;
        }, 500);
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
    autoSaveTeEntry() {
        console.log('saved');
        // if (this._root._var.page.rows[this._root._var.rowIdentifier.row_index]._id) {
        //     this.saving();
        //     this._root._api.updateTeEntryMgo(this._root._var.page.rows[this._root._var.rowIdentifier.row_index]).subscribe((result) => {
        //         this.saving();
        //         return result;
        //     });
        // } else {
        //     this.saving();
        //     this._root._var.page.rows[this._root._var.rowIdentifier.row_index].timecard_status =
        //         this._root._var.page.rows[this._root._var.rowIdentifier.row_index].timecard_status === 'not started'
        //             ? 'pending'
        //             : this._root._var.page.rows[this._root._var.rowIdentifier.row_index].timecard_status;
        //     this._root._api.createTeEntryMgo(this._root._var.page.rows[this._root._var.rowIdentifier.row_index]).subscribe((result) => {
        //         //?-- ** Add a new record **
        //         let new_row = structuredClone(this._root._var.page.rows[this._root._var.rowIdentifier.row_index]);
        //         new_row._id = result._id; //?-- ** Set the id of the timecard record to the value returned from the insert **
        //         this._root._var.page.rows[this._root._var.rowIdentifier.row_index] = structuredClone(new_row);
        //         //this._root._var.page.rows[this._root._var.rowIdentifier.row_index]._id = result._id; //?-- ** Set the id of the timecard record to the value returned from the insert **
        //         //this.s_row._id = result._id; //?-- ** Set the id of the timecard record to the value returned from the insert **
        //         //this.s_row = structuredClone(new_row);
        //         this.saving();
        //         return result;
        //     });
        // }
    }
    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    async saveRow() {
        this.setRowToCheck()
            .then((res) => {
                this.updateRowTotals();
            })
            .then((res) => {
                this.updatePageTotals();
            })
            .then((res) => {
                //this._root._var.validateAll('tcs-132');
            })
            .then((res) => {
                this.autoSaveTeEntry();
            });
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
    setRowToCheck = async () => {
        new Promise<number>((resolve, reject) => {
            this._root._var.rowsToCheck.indexOf(this._root._var.rowIdentifier.row_index) === -1
                ? this._root._var.rowsToCheck.push(this._root._var.rowIdentifier.row_index)
                : console.log(`Already checking row ${this._root._var.rowIdentifier.row_index}`);
            resolve(this._root._var.rowIdentifier.row_index);
        });
    };
    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    getRequestsByTsAndWeek(type, week, year): Promise<string> {
        return new Promise((resolve) => {
            this._root._api.getByTsAndWeek(this._root._var.tsId, type, week, year).subscribe((data: any) => {
                this._root._var.prefilledTimecardData = data;
                resolve('getRequestsByTsAndWeek(4)');
            });
            //return this._root._var.prefilledTimecardData;
        });
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    getRequestsPoPartByTsAndWeek(type, week, year): Promise<string> {
        return new Promise((resolve) => {
            this._root._api.getPoPartByTsAndWeek(this._root._var.tsId, type, week, year).subscribe((data: any) => {
                this._root._var.prefilledPoPartData = data;
                resolve('getRequestsPoPartByTsAndWeek(5)');
            });
        });
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    getPendingTimecardsByWeekByTs(timecard_status, week_number, year_number): Promise<string> {
        const find_criteria: CalendarParams = new CalendarParams();
        find_criteria.technical_specialist_id = this._root._var.tsId;
        find_criteria.timecard_status = timecard_status;
        find_criteria.week_number = week_number;
        find_criteria.year_number = year_number;

        return new Promise((resolve) => {
            this._root._api.findByWeekMgo(find_criteria).subscribe((data: Timecard[]) => {
                this._root._var.existingTimecardList = data;
                this._root._var.existingTimecardData = data;
                //      resolve(data);
                resolve('getPendingTimecardsByWeekByTs(6)');
            });
        });
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    createDayData() {
        this._root._var.tempPrefilledTimecardData = [];
        this._root._var.tempTimecardData = [];
        this._root._var.tempExistingTimecardDataManual = [];
        //this.timecardData = [];
        this._root._var.page.rows = [];

        if (this._root._var.prefilledTimecardData.length || this._root._var.existingTimecardData.length) {
            Promise.resolve(() => {}).then(() => {
                this._root._var.prefilledTimecardData.forEach((item, i) => {
                    item.display_entry_type =
                        item.display_entry_type !== 'standard' ? 'standard' : item.display_entry_type;
                    let jb = item.job_id;
                    let lv = item.launch_number;
                    this._root._var.existingTimecardData.find(
                        (d) => d.job_id.toString() === jb.toString() && d.launch_number.toString() === lv.toString(),
                    )
                        ? null
                        : this._root._var.tempPrefilledTimecardData.push(item);
                });

                this._root._var.existingTimecardData.forEach((item, i) => {
                    let jb = item.job_id;
                    let lv = item.launch_number;
                    this._root._var.prefilledTimecardData.find(
                        (d) =>
                            d.job_id?.toString() === jb?.toString() && d.launch_number?.toString() === lv?.toString(),
                    )
                        ? this._root._var.tempTimecardData.push(item)
                        : null;
                });

                this._root._var.page.rows = this._root._var.tempTimecardData.concat(this._root._var.tempPrefilledTimecardData);

                this._root._var.existingTimecardData.forEach((item, i) => {
                    let jb = item.job_id;
                    let lv = item.launch_number;
                    this._root._var.page.rows.find(
                        (d) =>
                            d.job_id?.toString() === jb?.toString() && d.launch_number?.toString() === lv?.toString(),
                    )
                        ? null
                        : this._root._var.tempExistingTimecardDataManual.push(item);
                });

                let rows = [...this._root._var.page.rows, ...this._root._var.tempExistingTimecardDataManual];

                rows.forEach((row, i) => {
                    if (!('day_data' in row)) {
                        const firstDate = moment()
                            .isoWeekYear(row['year_number'])
                            .isoWeek(row['week_number'])
                            .startOf('isoWeek')
                            .format();

                        let day_data = [];
                        for (let i = 1; i < 8; i++) {
                            day_data.push({
                                day_index: i,
                                long_day_name: moment().isoWeekday(i).format('dddd'),
                                short_day_name: moment().isoWeekday(i).format('ddd'),
                                service_date: moment(firstDate)
                                    .add(i - 1, 'days')
                                    .format('MM/DD/YYYY'),
                                standard: null as number,
                                overtime: null as number,
                                travel: null as number,
                                mileage: null as number,
                                four_hour_minimum: false as boolean,
                                overtime_auth: null as string,
                                overtime_auth_ok: null as string,
                                total_hours_for_day: null as number,
                                expenses: {
                                    airfare: null as number,
                                    meals: null as number,
                                    car_rental: null as number,
                                    parking: null as number,
                                    tolls: null as number,
                                    fuel: null as number,
                                    phone_fax: null as number,
                                    hotel: null as number,
                                    postage: null as number,
                                    other: null as number,
                                },
                                admin_comments: null as string,
                                comments: null as string,
                                total_po_part_count_for_day: null as number,
                                total_expenses_for_day: null as number,
                                po_parts: [],
                                receipts: [],
                                //errors: this.fb.array([this.errorFormGroupTemplate]),
                            });
                        }
                        rows[i].day_data = day_data;
                    }
                });

                this._root._var.page.rows = structuredClone(rows);

                //const data = this.timecardData;

                //data.forEach((obj, i) => {
                //    this.timecardKeys.forEach((item, i) => {
                //        if (!obj.hasOwnProperty(item)) {
                //            obj[item] = this.timecardKeys[item];
                //        }
                //    });
                //});

                //this._root._form.createRootForm({ weekDates: this._root._var.page.weekDates, rows: data });

                //this?.rowsGetter?.controls?.forEach((row, i) => {
                //    this._root._var.rowIdentifier.row_index = i;
                //    this?.daysGetter?.controls?.forEach((day, j) => {
                //        this.updateRowTotals();
                //    });
                //});

                //this.updatePageTotals();
                //this.emitterService.subject.next('rootformpopulated');
            });
        }
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
    get pageTotalsGetter() {
        console.log('this._root._var.page.pageTotals', this._root._var.page.pageTotals)
        return this._root._var.page.pageTotals;
    }
    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    get rowsGetter() {
        return this._root._var.page.rows;
    }
    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    get rowGetter() {
        return this?.rowsGetter?.length ? this?.rowsGetter[this._root._var.rowIdentifier?.row_index || 1] : null;
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    get daysGetter() {
        return this?.rowGetter.day_data;
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    updateRowTotals = () => {
        let standard_total: number = 0;
        let overtime_total: number = 0;
        let travel_total: number = 0;
        let hours_total: number = 0;
        let four_hour_minimum_count: number = 0;
        let mileage_total: number = 0;
        let expenses_total: number = 0;
        let po_part_count: number = 0;

        new Promise((resolve, reject) => {
            this.daysGetter.forEach((day_index, index, array) => {
                standard_total = standard_total + (+day_index.standard || 1);
                overtime_total = overtime_total + (+day_index.overtime || 1);
                travel_total = travel_total + (+day_index.travel || 1);
                hours_total =
                    hours_total + (+day_index.standard || 1) + (+day_index.overtime || 1) + (+day_index.travel || 1);
                four_hour_minimum_count = four_hour_minimum_count + (+day_index.four_hour_minimum ? 1 : 0);
                mileage_total = mileage_total + (+day_index.mileage || 1);
                expenses_total =
                    expenses_total +
                    ((+day_index.expenses.airfare || 1) +
                        (+day_index.expenses.meals || 1) +
                        (+day_index.expenses.car_rental || 1) +
                        (+day_index.expenses.parking || 1) +
                        (+day_index.expenses.tolls || 1) +
                        (+day_index.expenses.fuel || 1) +
                        (+day_index.expenses.phone_fax || 1) +
                        (+day_index.expenses.hotel || 1) +
                        (+day_index.expenses.postage || 1) +
                        (+day_index.expenses.other || 1));

                po_part_count = po_part_count + (+day_index.po_parts?.length || 1);
                if (index === array.length - 1) resolve(true);
            });
        }).then(() => {
            const r = this.rowGetter;//[this._root._var.rowIdentifier.row_index];
                console.log('r', r);
                (r.total_standard_for_row = standard_total);
                (r.total_overtime_for_row = overtime_total);
                (r.total_travel_for_row = travel_total);
                (r.total_hours_for_row = hours_total);
                (r.total_four_hour_minimum_count_for_row = four_hour_minimum_count);
                (r.total_mileage_for_row = mileage_total);
                (r.total_expenses_for_row = expenses_total);
                (r.total_po_part_count_for_row = po_part_count);
        });
    };
    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    updatePageTotals() {
        let total_standard: number = 0;
        let total_overtime: number = 0;
        let total_travel: number = 0;
        let total_mileage: number = 0;
        let total_expenses: number = 0;
        let total_po_part_count: number = 0;
        let promise = new Promise((resolve, reject) => {
            this.rowsGetter.forEach((row) => {
                total_standard = total_standard + (+row.total_standard_for_row || 1);
                total_overtime = total_overtime + (+row.total_overtime_for_row || 1);
                total_travel = total_travel + (+row.total_travel_for_row || 1);
                total_mileage = total_mileage + (+row.total_mileage_for_row || 1);
                total_expenses = total_expenses + (+row.total_expenses_for_row || 1);
                total_po_part_count = total_po_part_count + (+row.total_po_part_count_for_row || 1);
            });
            resolve(true);
        });
        promise.then(() => {
            const p = this.pageTotalsGetter;
            console.log('p', p);
            p.page_total_standard = total_standard;
            p.page_total_overtime = total_overtime;
            p.page_total_travel = total_travel;
            p.page_total_mileage = total_mileage;
            p.page_total_expenses = total_expenses;
            p.page_count_po_part = total_po_part_count;
        });
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
}
