import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageName'
})
export class ImageNamePipe implements PipeTransform {

    transform(fileName: string): string {
        const imageNameWithExtension = fileName.split('/').pop();
        const imageName = imageNameWithExtension.split('.')[0];
        return imageName;
      }

}
