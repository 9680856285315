import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mimeType'
})
export class MimeTypePipe implements PipeTransform {

    transform(file_name: string): string {
        const extension = file_name.split('.').pop();
        let mime_type = '';
    
        switch (extension) {
          case 'txt':
            mime_type = 'text/plain';
            break;
          case 'pdf':
            mime_type = 'application/pdf';
            break;
          case 'jpg':
          case 'jpeg':
            mime_type = 'image/jpeg';
            break;
          case 'png':
            mime_type = 'image/png';
            break;
          case 'gif':
            mime_type = 'image/gif';
            break;
          case 'doc':
            mime_type = 'application/msword';
            break;
          case 'docx':
            mime_type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            break;
          case 'xls':
            mime_type = 'application/vnd.ms-excel';
            break;
          case 'xlsx':
            mime_type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            break;
          // Add more cases for other file extensions and their corresponding mime-types
          default:
            mime_type = 'application/octet-stream';
            break;
        }
    
        return mime_type;
      }

}
