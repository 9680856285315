import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Timecard } from '../_models/timecard.interface';
import { WeekDate } from '../_models/week-date.model';
import moment from 'moment';
import { RootService } from '@app/shared/_services/root.service';

@Injectable({
  providedIn: 'root'
})
export class TimecardFormService {

    rootForm: FormGroup = this.fb.group({rows: this.fb.array([])});
    rowForm: FormArray;

  constructor(
    private fb: FormBuilder,
    ) { }


    // initRowForm() {
    //     this.rowForm = this.fb.array([]);

    //     this._t._data.timecardData.forEach((row, index) => {
    //         this.rowForm.push(this.createRowForm(row));
    //     });
    // }

    createRowForm(row) {

    }

    createRootForm(data) {
        console.log('data', data);
        //this.rootForm = this.fb.group({}); 
        this.rootForm = this.fb.group({
            weekData: this.createWeekDataForm(data.weekData),
            rows: this.createRowsForm(data.rows),
            page_totals: this.createPageTotalsForm(data.pageTotals)
        });
    }

    createWeekDataForm(data: WeekDate): FormGroup {
        let formData: FormGroup = this.fb.group({
            week: this.fb.group({
                number: [data ? data.week.number : (99 as number)],
                offset: [data ? data.week.offset : (null as number)],
                start: [data ? data.week.start : (null as string)],
                end: [data ? data.week.end : (null as string)],
                start_end_dates: [data ? data.week.start_end_dates : (null as string)],
            }),
            month: this.fb.group({
                number: [data ? data.month.number : (null as number)],
                start: [data ? data.month.start : (null as string)],
                end: [data ? data.month.end : (null as string)],
            }),
            year: this.fb.group({
                number: [data ? data.year.number : (null as number)],
                start: [data ? data.year.start : (null as string)],
                end: [data ? data.year.end : (null as string)],
            }),
            day: this.fb.group({
                monday: this.fb.group({
                    dom: [data ? data.day.monday.dom : (null as string)],
                    month: [data ? data.day.monday.month : (null as string)],
                    julian_date: [data ? data.day.monday.julian_date : (null as number)],
                    calendar_date: [data ? data.day.monday.calendar_date : (null as string)],
                }),
                tuesday: this.fb.group({
                    dom: [data ? data.day.tuesday.dom : (null as string)],
                    month: [data ? data.day.tuesday.month : (null as string)],
                    julian_date: [data ? data.day.tuesday.julian_date : (null as number)],
                    calendar_date: [data ? data.day.tuesday.calendar_date : (null as string)],
                }),
                wednesday: this.fb.group({
                    dom: [data ? data.day.wednesday.dom : (null as string)],
                    month: [data ? data.day.wednesday.month : (null as string)],
                    julian_date: [data ? data.day.wednesday.julian_date : (null as number)],
                    calendar_date: [data ? data.day.wednesday.calendar_date : (null as string)],
                }),
                thursday: this.fb.group({
                    dom: [data ? data.day.thursday.dom : (null as string)],
                    month: [data ? data.day.thursday.month : (null as string)],
                    julian_date: [data ? data.day.thursday.julian_date : (null as number)],
                    calendar_date: [data ? data.day.thursday.calendar_date : (null as string)],
                }),
                friday: this.fb.group({
                    dom: [data ? data.day.friday.dom : (null as string)],
                    month: [data ? data.day.friday.month : (null as string)],
                    julian_date: [data ? data.day.friday.julian_date : (null as number)],
                    calendar_date: [data ? data.day.friday.calendar_date : (null as string)],
                }),
                saturday: this.fb.group({
                    dom: [data ? data.day.saturday.dom : (null as string)],
                    month: [data ? data.day.saturday.month : (null as string)],
                    julian_date: [data ? data.day.saturday.julian_date : (null as number)],
                    calendar_date: [data ? data.day.saturday.calendar_date : (null as string)],
                }),
                sunday: this.fb.group({
                    dom: [data ? data.day.sunday.dom : (null as string)],
                    month: [data ? data.day.sunday.month : (null as string)],
                    julian_date: [data ? data.day.sunday.julian_date : (null as number)],
                    calendar_date: [data ? data.day.sunday.calendar_date : (null as string)],
                }),
            }),
            todays_julian_date: [parseInt(moment().format('YYYYDDDD'))],
            selected_date: [new Date()],
        });

        return formData;
        //console.log('this.weekDataForm', this.weekDataForm);

        //if (data && this.weekDataForm) {
        //    this.weekDataForm.patchValue(formData);
        //} else {
        //    return formData;
        //}
    }

    createRowsForm(data: Timecard[]): FormArray {
        console.log('data', data.length)

        let arr = [];
        data.forEach((row, index) => {
            let formData = this.fb.group({
                _id: [row._id],
                unique_id: [row.unique_id],
                timecard_ref: [row.timecard_ref],
                row_index: [row.row_index],
                day_index: [row.day_index],
                launch_number: [row.launch_number],
                request_id: [row.request_id],
                technical_specialist_id: [row.technical_specialist_id],
                client_id: [row.client_id],
                client_name: [row.client_name],
                supplier_id: [row.supplier_id],
                supplier_code: [row.supplier_code],
                supplier_name: [row.supplier_name],
                job_id: [row.job_id],
                job: [row.job],
                dash: [row.dash],
                job_status: [row.job_status],
                pay_status: [row.pay_status],
                job_description: [row.job_description],
                work_type_id: [row.work_type_id],
                work_type_name: [row.work_type_name],
                comments: [row.comments],
                admin_comments: [row.admin_comments],
                total_standard_for_row: [row.total_standard_for_row],
                total_overtime_for_row: [row.total_overtime_for_row],
                total_travel_for_row: [row.total_travel_for_row],
                total_hours_for_row: [row.total_hours_for_row],
                total_mileage_for_row: [row.total_mileage_for_row],
                total_expenses_for_row: [row.total_expenses_for_row],
                total_four_hour_minimum_count_for_row: [row.total_four_hour_minimum_count_for_row],
                total_po_part_count_for_row: [row.total_po_part_count_for_row],
                julian_date: [row.julian_date],
                week_number: [row.week_number],
                year_number: [row.year_number],
                display_entry_type: [row.display_entry_type],
                timecard_status: [row.timecard_status],
                is_prefill: [row.is_prefill],
                submit_selected: [row.submit_selected],
                submit_disabled: [row.submit_disabled],
                day_data: this.createDayDataForm(row.day_data, row.week_number, row.year_number),
            });
            arr.push(formData);
        });
        console.log('arr', arr.length)
        return this.fb.array(arr);
    }

    createDayDataForm(data, week_number, year_number) {
        const firstDate = moment().isoWeekYear(year_number).isoWeek(week_number).startOf('isoWeek').format();
        console.log('firstDate', firstDate);
        let arr = [];
        if (data) {
            data.forEach((row, i) => {
                let formData = this.fb.group({
                    day_index: [i],
                    long_day_name: [moment().isoWeekday(i).format('dddd')],
                    short_day_name: [moment().isoWeekday(i).format('ddd')],
                    service_date: [row.service_date],
                    standard: [row.standard],
                    overtime: [row.overtime],
                    travel: [row.travel],
                    mileage: [row.mileage],
                    four_hour_minimum: [row.four_hour_minimum],
                    overtime_auth: [row.overtime_auth],
                    overtime_auth_ok: [row.overtime_auth_ok],
                    total_hours_for_day: [row.total_hours_for_day],
                    expenses: this.createExpenseForm(row.expenses),
                    admin_comments: [row.admin_comments],
                    comments: [row.comments],
                    total_po_part_count_for_day: [row.total_po_part_count_for_day],
                    total_expenses_for_day: [row.total_expenses_for_day],
                    po_parts: this.createPoPartForm(row.po_parts),
                    receipts: this.createReceiptForm(row.receipts),
                    //errors: this.fb.array([this.setErrorForm(null)]),
                });
                arr.push(formData);
            });
        } else {
            let day_data = this.fb.array([]);
            for (let i = 1; i < 8; i++) {
                (day_data as FormArray).push(
                    this.fb.group({
                        day_index: [i],
                        long_day_name: [moment().isoWeekday(i).format('dddd')],
                        short_day_name: [moment().isoWeekday(i).format('ddd')],
                        service_date: [moment(firstDate).add(i - 1, 'days').format('MM/DD/YYYY')],
                        standard: [null as number],
                        overtime: [null as number],
                        travel: [null as number],
                        mileage: [null as number],
                        four_hour_minimum: [null as boolean],
                        overtime_auth: [null as string],
                        overtime_auth_ok: [null as string],
                        total_hours_for_day: [null as number],
                        expenses: this.createExpenseForm(null),
                        admin_comments: [null as string],
                        comments: [null as string],
                        total_po_part_count_for_day: [null as number],
                        total_expenses_for_day: [null as number],
                        po_parts: this.createPoPartForm(null),
                        receipts: this.createReceiptForm(null),
                        //errors: this.fb.array([this.errorFormGroupTemplate]),
                    }),
                );
            }
            return day_data;
        }

        return this.fb.array(arr);
    }

    createPoPartForm(data) {
        let arr = [];
        if(data) {
            data.forEach((row, i) => {
                let formData = this.fb.group({
                    po_index: [row.po_index],
                    id: [row.id],
                    po_descriptor: [row.po_descriptor],
                    launch_number: [row.launch_number],
                    service_date: [row.service_date],
                    work_type_id: [row.work_type_id],
                    po_number: [row.po_number],
                    part_number: [row.part_number],
                    line_number: [row.line_number],
                    charge_number: [row.charge_number],
                    inspection_hours: [row.inspection_hours],
                    inspected_quantity: [row.inspected_quantity],
                    rework_quantity: [row.rework_quantity],
                    reject_quantity: [row.reject_quantity],
                    accepted_quantity: [row.accepted_quantity],
                    non_conformance_description: [row.non_conformance_description],
                    rework_reasons: this.createReworkReasonForm(row?.reworkReason),
                    reject_reasons: this.createRejectReasonForm(row?.reworkReason),
                });
                arr.push(formData);
            });
        }
        return this.fb.array(arr);
    }

    createExpenseForm(data) {
        let formData: FormGroup;

        if(data){
            formData = this.fb.group({
                airfare: [data.airfare],
                meals: [data.meals],
                car_rental: [data.car_rental],
                parking: [data.parking],
                tolls: [data.tolls],
                fuel: [data.fuel],
                phone_fax: [data.phone_fax],
                postage: [data.postage],
                hotel: [data.hotel],
                other: [data.other],
                other_comment: [data.other_comment],
                has_airfare_receipt: [data.has_airfare_receipt],
                has_meals_receipt: [data.has_meals_receipt],
                has_car_rental_receipt: [data.has_car_rental_receipt],
                has_parking_receipt: [data.has_parking_receipt],
                has_tolls_receipt: [data.has_tolls_receipt],
                has_fuel_receipt: [data.has_fuel_receipt],
                has_phone_fax_receipt: [data.has_phone_fax_receipt],
                has_postage_receipt: [data.has_postage_receipt],
                has_hotel_receipt: [data.has_hotel_receipt],
                has_other_receipt: [data.has_other_receipt],
                travel_auth: [data.travel_auth],
                travel_auth_ok: [data.travel_auth_ok],
            });
        } else {
            formData = this.fb.group({
                airfare: [null as number],
                meals: [null as number],
                car_rental: [null as number],
                parking: [null as number],
                tolls: [null as number],
                fuel: [null as number],
                phone_fax: [null as number],
                postage: [null as number],
                hotel: [null as number],
                other: [null as number],
                other_comment: [null as string],
                has_airfare_receipt: [null as boolean],
                has_meals_receipt: [null as boolean],
                has_car_rental_receipt: [null as boolean],
                has_parking_receipt: [null as boolean],
                has_tolls_receipt: [null as boolean],
                has_fuel_receipt: [null as boolean],
                has_phone_fax_receipt: [null as boolean],
                has_postage_receipt: [null as boolean],
                has_hotel_receipt: [null as boolean],
                has_other_receipt: [null as boolean],
                travel_auth: [null as string],
                travel_auth_ok: [null as boolean],
            });
        }
        return formData;
    }

    createReceiptForm(data) {
        let arr = [];
        if (data) {
            data.forEach((row, i) => {
                let formData = this.fb.group({
                    receipt_index: [row.receipt_index],
                    row_index: [row.row_index],
                    file_name: [row.file_name],
                    file_url: [row.file_url],
                    expense_type: [row.expense_type],
                    amount: [row.amount],
                });
                arr.push(formData);
            });
        } 
        return this.fb.array(arr);
    }

    createRejectReasonForm(data) {
        let arr = [];
        if(data) {
            data.forEach((row, i) => {
                let formData = this.fb.group({
                    reject_reason_id: [row.reject_reason_id],
                    reject_reason_name: [row.reject_reason_name],
                });
                arr.push(formData);
            });
        }
        return this.fb.array(arr);
    }

    createReworkReasonForm(data) {
        let arr = [];
        if (data) {
            data.forEach((row, i) => {
                let formData = this.fb.group({
                    rework_reason_id: [row.rework_reason_id],
                    rework_reason_name: [row.rework_reason_name],
                });
                arr.push(formData);
            });
        }
        return this.fb.array(arr);
    }

    createPageTotalsForm(data) {
        let formData = this.fb.group({
            page_total_standard: [null as number],
            page_total_overtime: [null as number],
            page_total_travel: [null as number],
            page_total_mileage: [null as number],
            page_total_expenses: [null as number],
            page_count_po_part: [null as number],
        });
        return formData;
    }

}
