import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';



@Injectable({
    providedIn: 'root',
})
export class AzureFileService {

    constructor() {
    }

    //ax$ = axios.create({
    //    baseURL: environment.apiBaseUrl,
    //    headers: environment.axMultiPartFormHeaders,
    //});

    uploadFile(form: FormData): Observable<any> {
        const options = {
            method: 'POST',
            url: 'https://wc-api.ntslabs-unitek.com/files-whitecoast/upload-file',
            headers: {
              'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
              'x-api-key': environment.xApiKey
            },
            data: form
          };
        return new Observable((observer) => {
            axios.request(options)
            .then((response) => {
                observer.next(response.data);
                observer.complete();
            })
            .catch((error) => {
                observer.error(error);
            });
        });
    }

    getSasUrl(container_name:string, tsId: string, timecard_id: string, file_name: string) {
        const options = {
            method: 'GET',
            url: `https://wc-api.ntslabs-unitek.com/files-whitecoast/get-sas-url/?fileName=${tsId}/${timecard_id}/${file_name}&containerName=${container_name}`,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': environment.xApiKey
              }
        };
        return new Observable<any>((observer) => {
            axios.request(options)
            .then((response) => {
                observer.next(response.data);
                observer.complete();
            })
            .catch((error) => {
                observer.error(error);
            });
        });
    }
    //uploadFile(form: FormData): Observable<any> {
    //    return new Observable((observer) => {
    //        this.ax$.post('/files-whitecoast/upload-file', form)
    //        .then((res) => {
    //            observer.next(res.data);
    //            observer.complete();
    //        })
    //        .catch((err) => {
    //            observer.error(err);
    //        });
    //    });
    //}
}
