<div
    appBsModal
    #modal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal"
    (onShown)="shown()"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{ options.title }}
                </h4>
                <button type="button" class="btn-close" (click)="close()" [attr.aria-label]="l('Close')">
                </button>
            </div>
            <div class="modal-body">
                <form novalidate (ngSubmit)="refreshTable()" autocomplete="new-password">
                    <div *ngIf="options.isFilterEnabled" class="input-group mb-4">
                        <input
                            autoFocus
                            [(ngModel)]="filterText"
                            name="filterText"
                            class="form-control"
                            placeholder="{{ 'SearchWithThreeDot' | localize }}"
                            type="text"
                        />
                        <div class="input-group-append">
                            <button class="btn btn btn-primary" type="submit">
                                <i class="flaticon-search-1" [attr.aria-label]="l('Search')"></i>
                            </button>
                        </div>
                    </div>

                    <!--<Primeng-TurboTable-Start>-->
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table
                            #dataTable
                            (onLazyLoad)="getRecordsIfNeeds($event)"
                            [value]="primengTableHelper.records"
                            rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                            [paginator]="false"
                            [lazy]="true"
                            [tableStyle]="{'min-width': '25rem'}"
                        >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 15%">
                                        {{ 'Select' | localize }}
                                    </th>
                                    <th>
                                        {{ 'Name' | localize }}
                                    </th>
                                    <th>
                                        {{ 'Surname' | localize }}
                                    </th>
                                    <th>
                                        {{ 'Email' | localize }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td>
                                        <button
                                            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                            (click)="selectItem(record)"
                                            title="{{ 'Select' | localize }}"
                                        >
                                            <i class="la la-chevron-circle-right" [attr.aria-label]="l('Select')"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <span class="p-column-title">{{ 'Name' | localize }}</span>
                                        {{ record.name }}
                                    </td>
                                    <td>
                                        <span class="p-column-title">{{ 'Surname' | localize }}</span>
                                        {{ record.surname }}
                                    </td>
                                    <td>
                                        <span class="p-column-title">{{ 'Email' | localize }}</span>
                                        {{ record.emailAddress }}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{ 'NoData' | localize }}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator
                                [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                #paginator
                                (onPageChange)="getRecordsIfNeeds($event)"
                                [totalRecords]="primengTableHelper.totalRecordsCount"
                                [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                [showCurrentPageReport]="true"
                                [currentPageReportTemplate]="
                                    'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                                "
                            ></p-paginator>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-light-primary fw-bold" (click)="close()">
                    {{ 'Cancel' | localize }}
                </button>
            </div>
        </div>
    </div>
</div>
