import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'month'
})
export class MonthPipe implements PipeTransform {

  transform(value: any) {
    const date = new Date(value);
    return date.toLocaleString('default', {month: 'short'})
  }

}