import { Injectable } from '@angular/core';
import { FaIconLibraryInterface } from '@fortawesome/angular-fontawesome';
import { IconDefinition, IconName, IconPack, IconPrefix } from '@fortawesome/fontawesome-svg-core';

import { fa0 as far0 } from '@fortawesome/pro-regular-svg-icons/fa0';
import { fa00 as far00 } from '@fortawesome/pro-regular-svg-icons/fa00';
import { fa1 as far1 } from '@fortawesome/pro-regular-svg-icons/fa1';
import { fa100 as far100 } from '@fortawesome/pro-regular-svg-icons/fa100';
import { fa2 as far2 } from '@fortawesome/pro-regular-svg-icons/fa2';
import { fa3 as far3 } from '@fortawesome/pro-regular-svg-icons/fa3';
import { fa360Degrees as far360Degrees } from '@fortawesome/pro-regular-svg-icons/fa360Degrees';
import { fa4 as far4 } from '@fortawesome/pro-regular-svg-icons/fa4';
import { fa42Group as far42Group } from '@fortawesome/free-brands-svg-icons/fa42Group';
import { fa5 as far5 } from '@fortawesome/pro-regular-svg-icons/fa5';
import { fa500px as far500px } from '@fortawesome/free-brands-svg-icons/fa500px';
import { fa6 as far6 } from '@fortawesome/pro-regular-svg-icons/fa6';
import { fa7 as far7 } from '@fortawesome/pro-regular-svg-icons/fa7';
import { fa8 as far8 } from '@fortawesome/pro-regular-svg-icons/fa8';
import { fa9 as far9 } from '@fortawesome/pro-regular-svg-icons/fa9';
import { faA as farA } from '@fortawesome/pro-regular-svg-icons/faA';
import { faAbacus as farAbacus } from '@fortawesome/pro-regular-svg-icons/faAbacus';
import { faAccentGrave as farAccentGrave } from '@fortawesome/pro-regular-svg-icons/faAccentGrave';
import { faAccessibleIcon as farAccessibleIcon } from '@fortawesome/free-brands-svg-icons/faAccessibleIcon';
import { faAccusoft as farAccusoft } from '@fortawesome/free-brands-svg-icons/faAccusoft';
import { faAcorn as farAcorn } from '@fortawesome/pro-regular-svg-icons/faAcorn';
import { faAd as farAd } from '@fortawesome/pro-regular-svg-icons/faAd';
import { faAdd as farAdd } from '@fortawesome/pro-regular-svg-icons/faAdd';
import { faAddressBook as farAddressBook } from '@fortawesome/pro-regular-svg-icons/faAddressBook';
import { faAddressCard as farAddressCard } from '@fortawesome/pro-regular-svg-icons/faAddressCard';
import { faAdjust as farAdjust } from '@fortawesome/pro-regular-svg-icons/faAdjust';
import { faAdn as farAdn } from '@fortawesome/free-brands-svg-icons/faAdn';
import { faAdversal as farAdversal } from '@fortawesome/free-brands-svg-icons/faAdversal';
import { faAffiliatetheme as farAffiliatetheme } from '@fortawesome/free-brands-svg-icons/faAffiliatetheme';
import { faAirbnb as farAirbnb } from '@fortawesome/free-brands-svg-icons/faAirbnb';
import { faAirConditioner as farAirConditioner } from '@fortawesome/pro-regular-svg-icons/faAirConditioner';
import { faAirFreshener as farAirFreshener } from '@fortawesome/pro-regular-svg-icons/faAirFreshener';
import { faAirplay as farAirplay } from '@fortawesome/pro-regular-svg-icons/faAirplay';
import { faAlarmClock as farAlarmClock } from '@fortawesome/pro-regular-svg-icons/faAlarmClock';
import { faAlarmExclamation as farAlarmExclamation } from '@fortawesome/pro-regular-svg-icons/faAlarmExclamation';
import { faAlarmPlus as farAlarmPlus } from '@fortawesome/pro-regular-svg-icons/faAlarmPlus';
import { faAlarmSnooze as farAlarmSnooze } from '@fortawesome/pro-regular-svg-icons/faAlarmSnooze';
import { faAlbum as farAlbum } from '@fortawesome/pro-regular-svg-icons/faAlbum';
import { faAlbumCirclePlus as farAlbumCirclePlus } from '@fortawesome/pro-regular-svg-icons/faAlbumCirclePlus';
import { faAlbumCircleUser as farAlbumCircleUser } from '@fortawesome/pro-regular-svg-icons/faAlbumCircleUser';
import { faAlbumCollection as farAlbumCollection } from '@fortawesome/pro-regular-svg-icons/faAlbumCollection';
import { faAlbumCollectionCirclePlus as farAlbumCollectionCirclePlus } from '@fortawesome/pro-regular-svg-icons/faAlbumCollectionCirclePlus';
import { faAlbumCollectionCircleUser as farAlbumCollectionCircleUser } from '@fortawesome/pro-regular-svg-icons/faAlbumCollectionCircleUser';
import { faAlgolia as farAlgolia } from '@fortawesome/free-brands-svg-icons/faAlgolia';
import { faAlicorn as farAlicorn } from '@fortawesome/pro-regular-svg-icons/faAlicorn';
import { faAlien as farAlien } from '@fortawesome/pro-regular-svg-icons/faAlien';
import { faAlienMonster as farAlienMonster } from '@fortawesome/pro-regular-svg-icons/faAlienMonster';
import { faAlignCenter as farAlignCenter } from '@fortawesome/pro-regular-svg-icons/faAlignCenter';
import { faAlignJustify as farAlignJustify } from '@fortawesome/pro-regular-svg-icons/faAlignJustify';
import { faAlignLeft as farAlignLeft } from '@fortawesome/pro-regular-svg-icons/faAlignLeft';
import { faAlignRight as farAlignRight } from '@fortawesome/pro-regular-svg-icons/faAlignRight';
import { faAlignSlash as farAlignSlash } from '@fortawesome/pro-regular-svg-icons/faAlignSlash';
import { faAlipay as farAlipay } from '@fortawesome/free-brands-svg-icons/faAlipay';
import { faAllergies as farAllergies } from '@fortawesome/pro-regular-svg-icons/faAllergies';
import { faAlt as farAlt } from '@fortawesome/pro-regular-svg-icons/faAlt';
import { faAmazon as farAmazon } from '@fortawesome/free-brands-svg-icons/faAmazon';
import { faAmazonPay as farAmazonPay } from '@fortawesome/free-brands-svg-icons/faAmazonPay';
import { faAmbulance as farAmbulance } from '@fortawesome/pro-regular-svg-icons/faAmbulance';
import { faAmericanSignLanguageInterpreting as farAmericanSignLanguageInterpreting } from '@fortawesome/pro-regular-svg-icons/faAmericanSignLanguageInterpreting';
import { faAmilia as farAmilia } from '@fortawesome/free-brands-svg-icons/faAmilia';
import { faAmpersand as farAmpersand } from '@fortawesome/pro-regular-svg-icons/faAmpersand';
import { faAmpGuitar as farAmpGuitar } from '@fortawesome/pro-regular-svg-icons/faAmpGuitar';
import { faAnalytics as farAnalytics } from '@fortawesome/pro-regular-svg-icons/faAnalytics';
import { faAnchor as farAnchor } from '@fortawesome/pro-regular-svg-icons/faAnchor';
import { faAnchorCircleCheck as farAnchorCircleCheck } from '@fortawesome/pro-regular-svg-icons/faAnchorCircleCheck';
import { faAnchorCircleExclamation as farAnchorCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faAnchorCircleExclamation';
import { faAnchorCircleXmark as farAnchorCircleXmark } from '@fortawesome/pro-regular-svg-icons/faAnchorCircleXmark';
import { faAnchorLock as farAnchorLock } from '@fortawesome/pro-regular-svg-icons/faAnchorLock';
import { faAndroid as farAndroid } from '@fortawesome/free-brands-svg-icons/faAndroid';
import { faAngel as farAngel } from '@fortawesome/pro-regular-svg-icons/faAngel';
import { faAngellist as farAngellist } from '@fortawesome/free-brands-svg-icons/faAngellist';
import { faAngle as farAngle } from '@fortawesome/pro-regular-svg-icons/faAngle';
import { faAngle90 as farAngle90 } from '@fortawesome/pro-regular-svg-icons/faAngle90';
import { faAngleDoubleDown as farAngleDoubleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleDown';
import { faAngleDoubleLeft as farAngleDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight as farAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleRight';
import { faAngleDoubleUp as farAngleDoubleUp } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleUp';
import { faAngleDown as farAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faAngleLeft as farAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { faAngleRight as farAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { faAnglesDown as farAnglesDown } from '@fortawesome/pro-regular-svg-icons/faAnglesDown';
import { faAnglesLeft as farAnglesLeft } from '@fortawesome/pro-regular-svg-icons/faAnglesLeft';
import { faAnglesRight as farAnglesRight } from '@fortawesome/pro-regular-svg-icons/faAnglesRight';
import { faAnglesUp as farAnglesUp } from '@fortawesome/pro-regular-svg-icons/faAnglesUp';
import { faAnglesUpDown as farAnglesUpDown } from '@fortawesome/pro-regular-svg-icons/faAnglesUpDown';
import { faAngleUp as farAngleUp } from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import { faAngry as farAngry } from '@fortawesome/pro-regular-svg-icons/faAngry';
import { faAngrycreative as farAngrycreative } from '@fortawesome/free-brands-svg-icons/faAngrycreative';
import { faAngular as farAngular } from '@fortawesome/free-brands-svg-icons/faAngular';
import { faAnkh as farAnkh } from '@fortawesome/pro-regular-svg-icons/faAnkh';
import { faApartment as farApartment } from '@fortawesome/pro-regular-svg-icons/faApartment';
import { faAperture as farAperture } from '@fortawesome/pro-regular-svg-icons/faAperture';
import { faApostrophe as farApostrophe } from '@fortawesome/pro-regular-svg-icons/faApostrophe';
import { faApper as farApper } from '@fortawesome/free-brands-svg-icons/faApper';
import { faApple as farApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faAppleAlt as farAppleAlt } from '@fortawesome/pro-regular-svg-icons/faAppleAlt';
import { faAppleCore as farAppleCore } from '@fortawesome/pro-regular-svg-icons/faAppleCore';
import { faAppleCrate as farAppleCrate } from '@fortawesome/pro-regular-svg-icons/faAppleCrate';
import { faApplePay as farApplePay } from '@fortawesome/free-brands-svg-icons/faApplePay';
import { faAppleWhole as farAppleWhole } from '@fortawesome/pro-regular-svg-icons/faAppleWhole';
import { faAppStore as farAppStore } from '@fortawesome/free-brands-svg-icons/faAppStore';
import { faAppStoreIos as farAppStoreIos } from '@fortawesome/free-brands-svg-icons/faAppStoreIos';
import { faArchive as farArchive } from '@fortawesome/pro-regular-svg-icons/faArchive';
import { faArchway as farArchway } from '@fortawesome/pro-regular-svg-icons/faArchway';
import { faAreaChart as farAreaChart } from '@fortawesome/pro-regular-svg-icons/faAreaChart';
import { faArrowAltCircleDown as farArrowAltCircleDown } from '@fortawesome/pro-regular-svg-icons/faArrowAltCircleDown';
import { faArrowAltCircleLeft as farArrowAltCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowAltCircleLeft';
import { faArrowAltCircleRight as farArrowAltCircleRight } from '@fortawesome/pro-regular-svg-icons/faArrowAltCircleRight';
import { faArrowAltCircleUp as farArrowAltCircleUp } from '@fortawesome/pro-regular-svg-icons/faArrowAltCircleUp';
import { faArrowAltDown as farArrowAltDown } from '@fortawesome/pro-regular-svg-icons/faArrowAltDown';
import { faArrowAltFromBottom as farArrowAltFromBottom } from '@fortawesome/pro-regular-svg-icons/faArrowAltFromBottom';
import { faArrowAltFromLeft as farArrowAltFromLeft } from '@fortawesome/pro-regular-svg-icons/faArrowAltFromLeft';
import { faArrowAltFromRight as farArrowAltFromRight } from '@fortawesome/pro-regular-svg-icons/faArrowAltFromRight';
import { faArrowAltFromTop as farArrowAltFromTop } from '@fortawesome/pro-regular-svg-icons/faArrowAltFromTop';
import { faArrowAltLeft as farArrowAltLeft } from '@fortawesome/pro-regular-svg-icons/faArrowAltLeft';
import { faArrowAltRight as farArrowAltRight } from '@fortawesome/pro-regular-svg-icons/faArrowAltRight';
import { faArrowAltSquareDown as farArrowAltSquareDown } from '@fortawesome/pro-regular-svg-icons/faArrowAltSquareDown';
import { faArrowAltSquareLeft as farArrowAltSquareLeft } from '@fortawesome/pro-regular-svg-icons/faArrowAltSquareLeft';
import { faArrowAltSquareRight as farArrowAltSquareRight } from '@fortawesome/pro-regular-svg-icons/faArrowAltSquareRight';
import { faArrowAltSquareUp as farArrowAltSquareUp } from '@fortawesome/pro-regular-svg-icons/faArrowAltSquareUp';
import { faArrowAltToBottom as farArrowAltToBottom } from '@fortawesome/pro-regular-svg-icons/faArrowAltToBottom';
import { faArrowAltToLeft as farArrowAltToLeft } from '@fortawesome/pro-regular-svg-icons/faArrowAltToLeft';
import { faArrowAltToRight as farArrowAltToRight } from '@fortawesome/pro-regular-svg-icons/faArrowAltToRight';
import { faArrowAltToTop as farArrowAltToTop } from '@fortawesome/pro-regular-svg-icons/faArrowAltToTop';
import { faArrowAltUp as farArrowAltUp } from '@fortawesome/pro-regular-svg-icons/faArrowAltUp';
import { faArrowCircleDown as farArrowCircleDown } from '@fortawesome/pro-regular-svg-icons/faArrowCircleDown';
import { faArrowCircleLeft as farArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft';
import { faArrowCircleRight as farArrowCircleRight } from '@fortawesome/pro-regular-svg-icons/faArrowCircleRight';
import { faArrowCircleUp as farArrowCircleUp } from '@fortawesome/pro-regular-svg-icons/faArrowCircleUp';
import { faArrowDown as farArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown';
import { faArrowDown19 as farArrowDown19 } from '@fortawesome/pro-regular-svg-icons/faArrowDown19';
import { faArrowDown91 as farArrowDown91 } from '@fortawesome/pro-regular-svg-icons/faArrowDown91';
import { faArrowDownArrowUp as farArrowDownArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowDownArrowUp';
import { faArrowDownAZ as farArrowDownAZ } from '@fortawesome/pro-regular-svg-icons/faArrowDownAZ';
import { faArrowDownBigSmall as farArrowDownBigSmall } from '@fortawesome/pro-regular-svg-icons/faArrowDownBigSmall';
import { faArrowDownFromArc as farArrowDownFromArc } from '@fortawesome/pro-regular-svg-icons/faArrowDownFromArc';
import { faArrowDownFromDottedLine as farArrowDownFromDottedLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownFromDottedLine';
import { faArrowDownFromLine as farArrowDownFromLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownFromLine';
import { faArrowDownLeft as farArrowDownLeft } from '@fortawesome/pro-regular-svg-icons/faArrowDownLeft';
import { faArrowDownLeftAndArrowUpRightToCenter as farArrowDownLeftAndArrowUpRightToCenter } from '@fortawesome/pro-regular-svg-icons/faArrowDownLeftAndArrowUpRightToCenter';
import { faArrowDownLong as farArrowDownLong } from '@fortawesome/pro-regular-svg-icons/faArrowDownLong';
import { faArrowDownRight as farArrowDownRight } from '@fortawesome/pro-regular-svg-icons/faArrowDownRight';
import { faArrowDownShortWide as farArrowDownShortWide } from '@fortawesome/pro-regular-svg-icons/faArrowDownShortWide';
import { faArrowDownSmallBig as farArrowDownSmallBig } from '@fortawesome/pro-regular-svg-icons/faArrowDownSmallBig';
import { faArrowDownSquareTriangle as farArrowDownSquareTriangle } from '@fortawesome/pro-regular-svg-icons/faArrowDownSquareTriangle';
import { faArrowDownToArc as farArrowDownToArc } from '@fortawesome/pro-regular-svg-icons/faArrowDownToArc';
import { faArrowDownToBracket as farArrowDownToBracket } from '@fortawesome/pro-regular-svg-icons/faArrowDownToBracket';
import { faArrowDownToDottedLine as farArrowDownToDottedLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToDottedLine';
import { faArrowDownToLine as farArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToLine';
import { faArrowDownToSquare as farArrowDownToSquare } from '@fortawesome/pro-regular-svg-icons/faArrowDownToSquare';
import { faArrowDownTriangleSquare as farArrowDownTriangleSquare } from '@fortawesome/pro-regular-svg-icons/faArrowDownTriangleSquare';
import { faArrowDownUpAcrossLine as farArrowDownUpAcrossLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownUpAcrossLine';
import { faArrowDownUpLock as farArrowDownUpLock } from '@fortawesome/pro-regular-svg-icons/faArrowDownUpLock';
import { faArrowDownWideShort as farArrowDownWideShort } from '@fortawesome/pro-regular-svg-icons/faArrowDownWideShort';
import { faArrowDownZA as farArrowDownZA } from '@fortawesome/pro-regular-svg-icons/faArrowDownZA';
import { faArrowFromBottom as farArrowFromBottom } from '@fortawesome/pro-regular-svg-icons/faArrowFromBottom';
import { faArrowFromLeft as farArrowFromLeft } from '@fortawesome/pro-regular-svg-icons/faArrowFromLeft';
import { faArrowFromRight as farArrowFromRight } from '@fortawesome/pro-regular-svg-icons/faArrowFromRight';
import { faArrowFromTop as farArrowFromTop } from '@fortawesome/pro-regular-svg-icons/faArrowFromTop';
import { faArrowLeft as farArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faArrowLeftFromArc as farArrowLeftFromArc } from '@fortawesome/pro-regular-svg-icons/faArrowLeftFromArc';
import { faArrowLeftFromLine as farArrowLeftFromLine } from '@fortawesome/pro-regular-svg-icons/faArrowLeftFromLine';
import { faArrowLeftLong as farArrowLeftLong } from '@fortawesome/pro-regular-svg-icons/faArrowLeftLong';
import { faArrowLeftLongToLine as farArrowLeftLongToLine } from '@fortawesome/pro-regular-svg-icons/faArrowLeftLongToLine';
import { faArrowLeftRotate as farArrowLeftRotate } from '@fortawesome/pro-regular-svg-icons/faArrowLeftRotate';
import { faArrowLeftToArc as farArrowLeftToArc } from '@fortawesome/pro-regular-svg-icons/faArrowLeftToArc';
import { faArrowLeftToLine as farArrowLeftToLine } from '@fortawesome/pro-regular-svg-icons/faArrowLeftToLine';
import { faArrowPointer as farArrowPointer } from '@fortawesome/pro-regular-svg-icons/faArrowPointer';
import { faArrowProgress as farArrowProgress } from '@fortawesome/pro-regular-svg-icons/faArrowProgress';
import { faArrowRight as farArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faArrowRightArrowLeft as farArrowRightArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowRightArrowLeft';
import { faArrowRightFromArc as farArrowRightFromArc } from '@fortawesome/pro-regular-svg-icons/faArrowRightFromArc';
import { faArrowRightFromBracket as farArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons/faArrowRightFromBracket';
import { faArrowRightFromFile as farArrowRightFromFile } from '@fortawesome/pro-regular-svg-icons/faArrowRightFromFile';
import { faArrowRightFromLine as farArrowRightFromLine } from '@fortawesome/pro-regular-svg-icons/faArrowRightFromLine';
import { faArrowRightLong as farArrowRightLong } from '@fortawesome/pro-regular-svg-icons/faArrowRightLong';
import { faArrowRightLongToLine as farArrowRightLongToLine } from '@fortawesome/pro-regular-svg-icons/faArrowRightLongToLine';
import { faArrowRightRotate as farArrowRightRotate } from '@fortawesome/pro-regular-svg-icons/faArrowRightRotate';
import { faArrowRightToArc as farArrowRightToArc } from '@fortawesome/pro-regular-svg-icons/faArrowRightToArc';
import { faArrowRightToBracket as farArrowRightToBracket } from '@fortawesome/pro-regular-svg-icons/faArrowRightToBracket';
import { faArrowRightToCity as farArrowRightToCity } from '@fortawesome/pro-regular-svg-icons/faArrowRightToCity';
import { faArrowRightToFile as farArrowRightToFile } from '@fortawesome/pro-regular-svg-icons/faArrowRightToFile';
import { faArrowRightToLine as farArrowRightToLine } from '@fortawesome/pro-regular-svg-icons/faArrowRightToLine';
import { faArrowRotateBack as farArrowRotateBack } from '@fortawesome/pro-regular-svg-icons/faArrowRotateBack';
import { faArrowRotateBackward as farArrowRotateBackward } from '@fortawesome/pro-regular-svg-icons/faArrowRotateBackward';
import { faArrowRotateForward as farArrowRotateForward } from '@fortawesome/pro-regular-svg-icons/faArrowRotateForward';
import { faArrowRotateLeft as farArrowRotateLeft } from '@fortawesome/pro-regular-svg-icons/faArrowRotateLeft';
import { faArrowRotateRight as farArrowRotateRight } from '@fortawesome/pro-regular-svg-icons/faArrowRotateRight';
import { faArrows as farArrows } from '@fortawesome/pro-regular-svg-icons/faArrows';
import { faArrowsAlt as farArrowsAlt } from '@fortawesome/pro-regular-svg-icons/faArrowsAlt';
import { faArrowsAltH as farArrowsAltH } from '@fortawesome/pro-regular-svg-icons/faArrowsAltH';
import { faArrowsAltV as farArrowsAltV } from '@fortawesome/pro-regular-svg-icons/faArrowsAltV';
import { faArrowsCross as farArrowsCross } from '@fortawesome/pro-regular-svg-icons/faArrowsCross';
import { faArrowsDownToLine as farArrowsDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowsDownToLine';
import { faArrowsDownToPeople as farArrowsDownToPeople } from '@fortawesome/pro-regular-svg-icons/faArrowsDownToPeople';
import { faArrowsFromDottedLine as farArrowsFromDottedLine } from '@fortawesome/pro-regular-svg-icons/faArrowsFromDottedLine';
import { faArrowsFromLine as farArrowsFromLine } from '@fortawesome/pro-regular-svg-icons/faArrowsFromLine';
import { faArrowsH as farArrowsH } from '@fortawesome/pro-regular-svg-icons/faArrowsH';
import { faArrowsLeftRight as farArrowsLeftRight } from '@fortawesome/pro-regular-svg-icons/faArrowsLeftRight';
import { faArrowsLeftRightToLine as farArrowsLeftRightToLine } from '@fortawesome/pro-regular-svg-icons/faArrowsLeftRightToLine';
import { faArrowsMaximize as farArrowsMaximize } from '@fortawesome/pro-regular-svg-icons/faArrowsMaximize';
import { faArrowsMinimize as farArrowsMinimize } from '@fortawesome/pro-regular-svg-icons/faArrowsMinimize';
import { faArrowSquareDown as farArrowSquareDown } from '@fortawesome/pro-regular-svg-icons/faArrowSquareDown';
import { faArrowSquareLeft as farArrowSquareLeft } from '@fortawesome/pro-regular-svg-icons/faArrowSquareLeft';
import { faArrowSquareRight as farArrowSquareRight } from '@fortawesome/pro-regular-svg-icons/faArrowSquareRight';
import { faArrowSquareUp as farArrowSquareUp } from '@fortawesome/pro-regular-svg-icons/faArrowSquareUp';
import { faArrowsRepeat as farArrowsRepeat } from '@fortawesome/pro-regular-svg-icons/faArrowsRepeat';
import { faArrowsRepeat1 as farArrowsRepeat1 } from '@fortawesome/pro-regular-svg-icons/faArrowsRepeat1';
import { faArrowsRetweet as farArrowsRetweet } from '@fortawesome/pro-regular-svg-icons/faArrowsRetweet';
import { faArrowsRotate as farArrowsRotate } from '@fortawesome/pro-regular-svg-icons/faArrowsRotate';
import { faArrowsRotateReverse as farArrowsRotateReverse } from '@fortawesome/pro-regular-svg-icons/faArrowsRotateReverse';
import { faArrowsSpin as farArrowsSpin } from '@fortawesome/pro-regular-svg-icons/faArrowsSpin';
import { faArrowsSplitUpAndLeft as farArrowsSplitUpAndLeft } from '@fortawesome/pro-regular-svg-icons/faArrowsSplitUpAndLeft';
import { faArrowsToCircle as farArrowsToCircle } from '@fortawesome/pro-regular-svg-icons/faArrowsToCircle';
import { faArrowsToDot as farArrowsToDot } from '@fortawesome/pro-regular-svg-icons/faArrowsToDot';
import { faArrowsToDottedLine as farArrowsToDottedLine } from '@fortawesome/pro-regular-svg-icons/faArrowsToDottedLine';
import { faArrowsToEye as farArrowsToEye } from '@fortawesome/pro-regular-svg-icons/faArrowsToEye';
import { faArrowsToLine as farArrowsToLine } from '@fortawesome/pro-regular-svg-icons/faArrowsToLine';
import { faArrowsTurnRight as farArrowsTurnRight } from '@fortawesome/pro-regular-svg-icons/faArrowsTurnRight';
import { faArrowsTurnToDots as farArrowsTurnToDots } from '@fortawesome/pro-regular-svg-icons/faArrowsTurnToDots';
import { faArrowsUpDown as farArrowsUpDown } from '@fortawesome/pro-regular-svg-icons/faArrowsUpDown';
import { faArrowsUpDownLeftRight as farArrowsUpDownLeftRight } from '@fortawesome/pro-regular-svg-icons/faArrowsUpDownLeftRight';
import { faArrowsUpToLine as farArrowsUpToLine } from '@fortawesome/pro-regular-svg-icons/faArrowsUpToLine';
import { faArrowsV as farArrowsV } from '@fortawesome/pro-regular-svg-icons/faArrowsV';
import { faArrowToBottom as farArrowToBottom } from '@fortawesome/pro-regular-svg-icons/faArrowToBottom';
import { faArrowToLeft as farArrowToLeft } from '@fortawesome/pro-regular-svg-icons/faArrowToLeft';
import { faArrowToRight as farArrowToRight } from '@fortawesome/pro-regular-svg-icons/faArrowToRight';
import { faArrowToTop as farArrowToTop } from '@fortawesome/pro-regular-svg-icons/faArrowToTop';
import { faArrowTrendDown as farArrowTrendDown } from '@fortawesome/pro-regular-svg-icons/faArrowTrendDown';
import { faArrowTrendUp as farArrowTrendUp } from '@fortawesome/pro-regular-svg-icons/faArrowTrendUp';
import { faArrowTurnDown as farArrowTurnDown } from '@fortawesome/pro-regular-svg-icons/faArrowTurnDown';
import { faArrowTurnDownLeft as farArrowTurnDownLeft } from '@fortawesome/pro-regular-svg-icons/faArrowTurnDownLeft';
import { faArrowTurnDownRight as farArrowTurnDownRight } from '@fortawesome/pro-regular-svg-icons/faArrowTurnDownRight';
import { faArrowTurnLeft as farArrowTurnLeft } from '@fortawesome/pro-regular-svg-icons/faArrowTurnLeft';
import { faArrowTurnLeftDown as farArrowTurnLeftDown } from '@fortawesome/pro-regular-svg-icons/faArrowTurnLeftDown';
import { faArrowTurnLeftUp as farArrowTurnLeftUp } from '@fortawesome/pro-regular-svg-icons/faArrowTurnLeftUp';
import { faArrowTurnRight as farArrowTurnRight } from '@fortawesome/pro-regular-svg-icons/faArrowTurnRight';
import { faArrowTurnUp as farArrowTurnUp } from '@fortawesome/pro-regular-svg-icons/faArrowTurnUp';
import { faArrowUp as farArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp';
import { faArrowUp19 as farArrowUp19 } from '@fortawesome/pro-regular-svg-icons/faArrowUp19';
import { faArrowUp91 as farArrowUp91 } from '@fortawesome/pro-regular-svg-icons/faArrowUp91';
import { faArrowUpArrowDown as farArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowUpArrowDown';
import { faArrowUpAZ as farArrowUpAZ } from '@fortawesome/pro-regular-svg-icons/faArrowUpAZ';
import { faArrowUpBigSmall as farArrowUpBigSmall } from '@fortawesome/pro-regular-svg-icons/faArrowUpBigSmall';
import { faArrowUpFromArc as farArrowUpFromArc } from '@fortawesome/pro-regular-svg-icons/faArrowUpFromArc';
import { faArrowUpFromBracket as farArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons/faArrowUpFromBracket';
import { faArrowUpFromDottedLine as farArrowUpFromDottedLine } from '@fortawesome/pro-regular-svg-icons/faArrowUpFromDottedLine';
import { faArrowUpFromGroundWater as farArrowUpFromGroundWater } from '@fortawesome/pro-regular-svg-icons/faArrowUpFromGroundWater';
import { faArrowUpFromLine as farArrowUpFromLine } from '@fortawesome/pro-regular-svg-icons/faArrowUpFromLine';
import { faArrowUpFromSquare as farArrowUpFromSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpFromSquare';
import { faArrowUpFromWaterPump as farArrowUpFromWaterPump } from '@fortawesome/pro-regular-svg-icons/faArrowUpFromWaterPump';
import { faArrowUpLeft as farArrowUpLeft } from '@fortawesome/pro-regular-svg-icons/faArrowUpLeft';
import { faArrowUpLeftFromCircle as farArrowUpLeftFromCircle } from '@fortawesome/pro-regular-svg-icons/faArrowUpLeftFromCircle';
import { faArrowUpLong as farArrowUpLong } from '@fortawesome/pro-regular-svg-icons/faArrowUpLong';
import { faArrowUpRight as farArrowUpRight } from '@fortawesome/pro-regular-svg-icons/faArrowUpRight';
import { faArrowUpRightAndArrowDownLeftFromCenter as farArrowUpRightAndArrowDownLeftFromCenter } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightAndArrowDownLeftFromCenter';
import { faArrowUpRightDots as farArrowUpRightDots } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightDots';
import { faArrowUpRightFromSquare as farArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare';
import { faArrowUpShortWide as farArrowUpShortWide } from '@fortawesome/pro-regular-svg-icons/faArrowUpShortWide';
import { faArrowUpSmallBig as farArrowUpSmallBig } from '@fortawesome/pro-regular-svg-icons/faArrowUpSmallBig';
import { faArrowUpSquareTriangle as farArrowUpSquareTriangle } from '@fortawesome/pro-regular-svg-icons/faArrowUpSquareTriangle';
import { faArrowUpToArc as farArrowUpToArc } from '@fortawesome/pro-regular-svg-icons/faArrowUpToArc';
import { faArrowUpToDottedLine as farArrowUpToDottedLine } from '@fortawesome/pro-regular-svg-icons/faArrowUpToDottedLine';
import { faArrowUpToLine as farArrowUpToLine } from '@fortawesome/pro-regular-svg-icons/faArrowUpToLine';
import { faArrowUpTriangleSquare as farArrowUpTriangleSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpTriangleSquare';
import { faArrowUpWideShort as farArrowUpWideShort } from '@fortawesome/pro-regular-svg-icons/faArrowUpWideShort';
import { faArrowUpZA as farArrowUpZA } from '@fortawesome/pro-regular-svg-icons/faArrowUpZA';
import { faArtstation as farArtstation } from '@fortawesome/free-brands-svg-icons/faArtstation';
import { faAslInterpreting as farAslInterpreting } from '@fortawesome/pro-regular-svg-icons/faAslInterpreting';
import { faAssistiveListeningSystems as farAssistiveListeningSystems } from '@fortawesome/pro-regular-svg-icons/faAssistiveListeningSystems';
import { faAsterisk as farAsterisk } from '@fortawesome/pro-regular-svg-icons/faAsterisk';
import { faAsymmetrik as farAsymmetrik } from '@fortawesome/free-brands-svg-icons/faAsymmetrik';
import { faAt as farAt } from '@fortawesome/pro-regular-svg-icons/faAt';
import { faAtlas as farAtlas } from '@fortawesome/pro-regular-svg-icons/faAtlas';
import { faAtlassian as farAtlassian } from '@fortawesome/free-brands-svg-icons/faAtlassian';
import { faAtom as farAtom } from '@fortawesome/pro-regular-svg-icons/faAtom';
import { faAtomAlt as farAtomAlt } from '@fortawesome/pro-regular-svg-icons/faAtomAlt';
import { faAtomSimple as farAtomSimple } from '@fortawesome/pro-regular-svg-icons/faAtomSimple';
import { faAudible as farAudible } from '@fortawesome/free-brands-svg-icons/faAudible';
import { faAudioDescription as farAudioDescription } from '@fortawesome/pro-regular-svg-icons/faAudioDescription';
import { faAudioDescriptionSlash as farAudioDescriptionSlash } from '@fortawesome/pro-regular-svg-icons/faAudioDescriptionSlash';
import { faAustralSign as farAustralSign } from '@fortawesome/pro-regular-svg-icons/faAustralSign';
import { faAutomobile as farAutomobile } from '@fortawesome/pro-regular-svg-icons/faAutomobile';
import { faAutoprefixer as farAutoprefixer } from '@fortawesome/free-brands-svg-icons/faAutoprefixer';
import { faAvianex as farAvianex } from '@fortawesome/free-brands-svg-icons/faAvianex';
import { faAviato as farAviato } from '@fortawesome/free-brands-svg-icons/faAviato';
import { faAvocado as farAvocado } from '@fortawesome/pro-regular-svg-icons/faAvocado';
import { faAward as farAward } from '@fortawesome/pro-regular-svg-icons/faAward';
import { faAwardSimple as farAwardSimple } from '@fortawesome/pro-regular-svg-icons/faAwardSimple';
import { faAws as farAws } from '@fortawesome/free-brands-svg-icons/faAws';
import { faAxe as farAxe } from '@fortawesome/pro-regular-svg-icons/faAxe';
import { faAxeBattle as farAxeBattle } from '@fortawesome/pro-regular-svg-icons/faAxeBattle';
import { faB as farB } from '@fortawesome/pro-regular-svg-icons/faB';
import { faBaby as farBaby } from '@fortawesome/pro-regular-svg-icons/faBaby';
import { faBabyCarriage as farBabyCarriage } from '@fortawesome/pro-regular-svg-icons/faBabyCarriage';
import { faBackpack as farBackpack } from '@fortawesome/pro-regular-svg-icons/faBackpack';
import { faBackspace as farBackspace } from '@fortawesome/pro-regular-svg-icons/faBackspace';
import { faBackward as farBackward } from '@fortawesome/pro-regular-svg-icons/faBackward';
import { faBackwardFast as farBackwardFast } from '@fortawesome/pro-regular-svg-icons/faBackwardFast';
import { faBackwardStep as farBackwardStep } from '@fortawesome/pro-regular-svg-icons/faBackwardStep';
import { faBacon as farBacon } from '@fortawesome/pro-regular-svg-icons/faBacon';
import { faBacteria as farBacteria } from '@fortawesome/pro-regular-svg-icons/faBacteria';
import { faBacterium as farBacterium } from '@fortawesome/pro-regular-svg-icons/faBacterium';
import { faBadge as farBadge } from '@fortawesome/pro-regular-svg-icons/faBadge';
import { faBadgeCheck as farBadgeCheck } from '@fortawesome/pro-regular-svg-icons/faBadgeCheck';
import { faBadgeDollar as farBadgeDollar } from '@fortawesome/pro-regular-svg-icons/faBadgeDollar';
import { faBadgePercent as farBadgePercent } from '@fortawesome/pro-regular-svg-icons/faBadgePercent';
import { faBadgerHoney as farBadgerHoney } from '@fortawesome/pro-regular-svg-icons/faBadgerHoney';
import { faBadgeSheriff as farBadgeSheriff } from '@fortawesome/pro-regular-svg-icons/faBadgeSheriff';
import { faBadminton as farBadminton } from '@fortawesome/pro-regular-svg-icons/faBadminton';
import { faBagel as farBagel } from '@fortawesome/pro-regular-svg-icons/faBagel';
import { faBagSeedling as farBagSeedling } from '@fortawesome/pro-regular-svg-icons/faBagSeedling';
import { faBagShopping as farBagShopping } from '@fortawesome/pro-regular-svg-icons/faBagShopping';
import { faBagShoppingMinus as farBagShoppingMinus } from '@fortawesome/pro-regular-svg-icons/faBagShoppingMinus';
import { faBagShoppingPlus as farBagShoppingPlus } from '@fortawesome/pro-regular-svg-icons/faBagShoppingPlus';
import { faBagsShopping as farBagsShopping } from '@fortawesome/pro-regular-svg-icons/faBagsShopping';
import { faBaguette as farBaguette } from '@fortawesome/pro-regular-svg-icons/faBaguette';
import { faBahai as farBahai } from '@fortawesome/pro-regular-svg-icons/faBahai';
import { faBahtSign as farBahtSign } from '@fortawesome/pro-regular-svg-icons/faBahtSign';
import { faBalanceScale as farBalanceScale } from '@fortawesome/pro-regular-svg-icons/faBalanceScale';
import { faBalanceScaleLeft as farBalanceScaleLeft } from '@fortawesome/pro-regular-svg-icons/faBalanceScaleLeft';
import { faBalanceScaleRight as farBalanceScaleRight } from '@fortawesome/pro-regular-svg-icons/faBalanceScaleRight';
import { faBalloon as farBalloon } from '@fortawesome/pro-regular-svg-icons/faBalloon';
import { faBalloons as farBalloons } from '@fortawesome/pro-regular-svg-icons/faBalloons';
import { faBallot as farBallot } from '@fortawesome/pro-regular-svg-icons/faBallot';
import { faBallotCheck as farBallotCheck } from '@fortawesome/pro-regular-svg-icons/faBallotCheck';
import { faBallPile as farBallPile } from '@fortawesome/pro-regular-svg-icons/faBallPile';
import { faBan as farBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faBanana as farBanana } from '@fortawesome/pro-regular-svg-icons/faBanana';
import { faBanBug as farBanBug } from '@fortawesome/pro-regular-svg-icons/faBanBug';
import { faBandage as farBandage } from '@fortawesome/pro-regular-svg-icons/faBandage';
import { faBandAid as farBandAid } from '@fortawesome/pro-regular-svg-icons/faBandAid';
import { faBandcamp as farBandcamp } from '@fortawesome/free-brands-svg-icons/faBandcamp';
import { faBangladeshiTakaSign as farBangladeshiTakaSign } from '@fortawesome/pro-regular-svg-icons/faBangladeshiTakaSign';
import { faBanjo as farBanjo } from '@fortawesome/pro-regular-svg-icons/faBanjo';
import { faBank as farBank } from '@fortawesome/pro-regular-svg-icons/faBank';
import { faBanParking as farBanParking } from '@fortawesome/pro-regular-svg-icons/faBanParking';
import { faBanSmoking as farBanSmoking } from '@fortawesome/pro-regular-svg-icons/faBanSmoking';
import { faBarChart as farBarChart } from '@fortawesome/pro-regular-svg-icons/faBarChart';
import { faBarcode as farBarcode } from '@fortawesome/pro-regular-svg-icons/faBarcode';
import { faBarcodeAlt as farBarcodeAlt } from '@fortawesome/pro-regular-svg-icons/faBarcodeAlt';
import { faBarcodeRead as farBarcodeRead } from '@fortawesome/pro-regular-svg-icons/faBarcodeRead';
import { faBarcodeScan as farBarcodeScan } from '@fortawesome/pro-regular-svg-icons/faBarcodeScan';
import { faBarnSilo as farBarnSilo } from '@fortawesome/pro-regular-svg-icons/faBarnSilo';
import { faBars as farBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import { faBarsFilter as farBarsFilter } from '@fortawesome/pro-regular-svg-icons/faBarsFilter';
import { faBarsProgress as farBarsProgress } from '@fortawesome/pro-regular-svg-icons/faBarsProgress';
import { faBarsSort as farBarsSort } from '@fortawesome/pro-regular-svg-icons/faBarsSort';
import { faBarsStaggered as farBarsStaggered } from '@fortawesome/pro-regular-svg-icons/faBarsStaggered';
import { faBaseball as farBaseball } from '@fortawesome/pro-regular-svg-icons/faBaseball';
import { faBaseballBall as farBaseballBall } from '@fortawesome/pro-regular-svg-icons/faBaseballBall';
import { faBaseballBatBall as farBaseballBatBall } from '@fortawesome/pro-regular-svg-icons/faBaseballBatBall';
import { faBasketball as farBasketball } from '@fortawesome/pro-regular-svg-icons/faBasketball';
import { faBasketballBall as farBasketballBall } from '@fortawesome/pro-regular-svg-icons/faBasketballBall';
import { faBasketballHoop as farBasketballHoop } from '@fortawesome/pro-regular-svg-icons/faBasketballHoop';
import { faBasketShopping as farBasketShopping } from '@fortawesome/pro-regular-svg-icons/faBasketShopping';
import { faBasketShoppingMinus as farBasketShoppingMinus } from '@fortawesome/pro-regular-svg-icons/faBasketShoppingMinus';
import { faBasketShoppingPlus as farBasketShoppingPlus } from '@fortawesome/pro-regular-svg-icons/faBasketShoppingPlus';
import { faBasketShoppingSimple as farBasketShoppingSimple } from '@fortawesome/pro-regular-svg-icons/faBasketShoppingSimple';
import { faBat as farBat } from '@fortawesome/pro-regular-svg-icons/faBat';
import { faBath as farBath } from '@fortawesome/pro-regular-svg-icons/faBath';
import { faBathtub as farBathtub } from '@fortawesome/pro-regular-svg-icons/faBathtub';
import { faBattery as farBattery } from '@fortawesome/pro-regular-svg-icons/faBattery';
import { faBattery0 as farBattery0 } from '@fortawesome/pro-regular-svg-icons/faBattery0';
import { faBattery1 as farBattery1 } from '@fortawesome/pro-regular-svg-icons/faBattery1';
import { faBattery2 as farBattery2 } from '@fortawesome/pro-regular-svg-icons/faBattery2';
import { faBattery3 as farBattery3 } from '@fortawesome/pro-regular-svg-icons/faBattery3';
import { faBattery4 as farBattery4 } from '@fortawesome/pro-regular-svg-icons/faBattery4';
import { faBattery5 as farBattery5 } from '@fortawesome/pro-regular-svg-icons/faBattery5';
import { faBatteryBolt as farBatteryBolt } from '@fortawesome/pro-regular-svg-icons/faBatteryBolt';
import { faBatteryCar as farBatteryCar } from '@fortawesome/pro-regular-svg-icons/faBatteryCar';
import { faBatteryEmpty as farBatteryEmpty } from '@fortawesome/pro-regular-svg-icons/faBatteryEmpty';
import { faBatteryExclamation as farBatteryExclamation } from '@fortawesome/pro-regular-svg-icons/faBatteryExclamation';
import { faBatteryFull as farBatteryFull } from '@fortawesome/pro-regular-svg-icons/faBatteryFull';
import { faBatteryHalf as farBatteryHalf } from '@fortawesome/pro-regular-svg-icons/faBatteryHalf';
import { faBatteryLow as farBatteryLow } from '@fortawesome/pro-regular-svg-icons/faBatteryLow';
import { faBatteryQuarter as farBatteryQuarter } from '@fortawesome/pro-regular-svg-icons/faBatteryQuarter';
import { faBatterySlash as farBatterySlash } from '@fortawesome/pro-regular-svg-icons/faBatterySlash';
import { faBatteryThreeQuarters as farBatteryThreeQuarters } from '@fortawesome/pro-regular-svg-icons/faBatteryThreeQuarters';
import { faBattleNet as farBattleNet } from '@fortawesome/free-brands-svg-icons/faBattleNet';
import { faBed as farBed } from '@fortawesome/pro-regular-svg-icons/faBed';
import { faBedAlt as farBedAlt } from '@fortawesome/pro-regular-svg-icons/faBedAlt';
import { faBedBunk as farBedBunk } from '@fortawesome/pro-regular-svg-icons/faBedBunk';
import { faBedEmpty as farBedEmpty } from '@fortawesome/pro-regular-svg-icons/faBedEmpty';
import { faBedFront as farBedFront } from '@fortawesome/pro-regular-svg-icons/faBedFront';
import { faBedPulse as farBedPulse } from '@fortawesome/pro-regular-svg-icons/faBedPulse';
import { faBee as farBee } from '@fortawesome/pro-regular-svg-icons/faBee';
import { faBeer as farBeer } from '@fortawesome/pro-regular-svg-icons/faBeer';
import { faBeerFoam as farBeerFoam } from '@fortawesome/pro-regular-svg-icons/faBeerFoam';
import { faBeerMug as farBeerMug } from '@fortawesome/pro-regular-svg-icons/faBeerMug';
import { faBeerMugEmpty as farBeerMugEmpty } from '@fortawesome/pro-regular-svg-icons/faBeerMugEmpty';
import { faBehance as farBehance } from '@fortawesome/free-brands-svg-icons/faBehance';
import { faBehanceSquare as farBehanceSquare } from '@fortawesome/free-brands-svg-icons/faBehanceSquare';
import { faBell as farBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faBellConcierge as farBellConcierge } from '@fortawesome/pro-regular-svg-icons/faBellConcierge';
import { faBellExclamation as farBellExclamation } from '@fortawesome/pro-regular-svg-icons/faBellExclamation';
import { faBellOn as farBellOn } from '@fortawesome/pro-regular-svg-icons/faBellOn';
import { faBellPlus as farBellPlus } from '@fortawesome/pro-regular-svg-icons/faBellPlus';
import { faBellRing as farBellRing } from '@fortawesome/pro-regular-svg-icons/faBellRing';
import { faBells as farBells } from '@fortawesome/pro-regular-svg-icons/faBells';
import { faBellSchool as farBellSchool } from '@fortawesome/pro-regular-svg-icons/faBellSchool';
import { faBellSchoolSlash as farBellSchoolSlash } from '@fortawesome/pro-regular-svg-icons/faBellSchoolSlash';
import { faBellSlash as farBellSlash } from '@fortawesome/pro-regular-svg-icons/faBellSlash';
import { faBenchTree as farBenchTree } from '@fortawesome/pro-regular-svg-icons/faBenchTree';
import { faBetamax as farBetamax } from '@fortawesome/pro-regular-svg-icons/faBetamax';
import { faBezierCurve as farBezierCurve } from '@fortawesome/pro-regular-svg-icons/faBezierCurve';
import { faBible as farBible } from '@fortawesome/pro-regular-svg-icons/faBible';
import { faBicycle as farBicycle } from '@fortawesome/pro-regular-svg-icons/faBicycle';
import { faBiking as farBiking } from '@fortawesome/pro-regular-svg-icons/faBiking';
import { faBikingMountain as farBikingMountain } from '@fortawesome/pro-regular-svg-icons/faBikingMountain';
import { faBilibili as farBilibili } from '@fortawesome/free-brands-svg-icons/faBilibili';
import { faBillboard as farBillboard } from '@fortawesome/pro-regular-svg-icons/faBillboard';
import { faBimobject as farBimobject } from '@fortawesome/free-brands-svg-icons/faBimobject';
import { faBinary as farBinary } from '@fortawesome/pro-regular-svg-icons/faBinary';
import { faBinaryCircleCheck as farBinaryCircleCheck } from '@fortawesome/pro-regular-svg-icons/faBinaryCircleCheck';
import { faBinaryLock as farBinaryLock } from '@fortawesome/pro-regular-svg-icons/faBinaryLock';
import { faBinarySlash as farBinarySlash } from '@fortawesome/pro-regular-svg-icons/faBinarySlash';
import { faBinBottles as farBinBottles } from '@fortawesome/pro-regular-svg-icons/faBinBottles';
import { faBinBottlesRecycle as farBinBottlesRecycle } from '@fortawesome/pro-regular-svg-icons/faBinBottlesRecycle';
import { faBinoculars as farBinoculars } from '@fortawesome/pro-regular-svg-icons/faBinoculars';
import { faBinRecycle as farBinRecycle } from '@fortawesome/pro-regular-svg-icons/faBinRecycle';
import { faBiohazard as farBiohazard } from '@fortawesome/pro-regular-svg-icons/faBiohazard';
import { faBird as farBird } from '@fortawesome/pro-regular-svg-icons/faBird';
import { faBirthdayCake as farBirthdayCake } from '@fortawesome/pro-regular-svg-icons/faBirthdayCake';
import { faBitbucket as farBitbucket } from '@fortawesome/free-brands-svg-icons/faBitbucket';
import { faBitcoin as farBitcoin } from '@fortawesome/free-brands-svg-icons/faBitcoin';
import { faBitcoinSign as farBitcoinSign } from '@fortawesome/pro-regular-svg-icons/faBitcoinSign';
import { faBity as farBity } from '@fortawesome/free-brands-svg-icons/faBity';
import { faBlackberry as farBlackberry } from '@fortawesome/free-brands-svg-icons/faBlackberry';
import { faBlackboard as farBlackboard } from '@fortawesome/pro-regular-svg-icons/faBlackboard';
import { faBlackTie as farBlackTie } from '@fortawesome/free-brands-svg-icons/faBlackTie';
import { faBlanket as farBlanket } from '@fortawesome/pro-regular-svg-icons/faBlanket';
import { faBlanketFire as farBlanketFire } from '@fortawesome/pro-regular-svg-icons/faBlanketFire';
import { faBlender as farBlender } from '@fortawesome/pro-regular-svg-icons/faBlender';
import { faBlenderPhone as farBlenderPhone } from '@fortawesome/pro-regular-svg-icons/faBlenderPhone';
import { faBlind as farBlind } from '@fortawesome/pro-regular-svg-icons/faBlind';
import { faBlinds as farBlinds } from '@fortawesome/pro-regular-svg-icons/faBlinds';
import { faBlindsOpen as farBlindsOpen } from '@fortawesome/pro-regular-svg-icons/faBlindsOpen';
import { faBlindsRaised as farBlindsRaised } from '@fortawesome/pro-regular-svg-icons/faBlindsRaised';
import { faBlock as farBlock } from '@fortawesome/pro-regular-svg-icons/faBlock';
import { faBlockBrick as farBlockBrick } from '@fortawesome/pro-regular-svg-icons/faBlockBrick';
import { faBlockBrickFire as farBlockBrickFire } from '@fortawesome/pro-regular-svg-icons/faBlockBrickFire';
import { faBlockQuestion as farBlockQuestion } from '@fortawesome/pro-regular-svg-icons/faBlockQuestion';
import { faBlockQuote as farBlockQuote } from '@fortawesome/pro-regular-svg-icons/faBlockQuote';
import { faBlog as farBlog } from '@fortawesome/pro-regular-svg-icons/faBlog';
import { faBlogger as farBlogger } from '@fortawesome/free-brands-svg-icons/faBlogger';
import { faBloggerB as farBloggerB } from '@fortawesome/free-brands-svg-icons/faBloggerB';
import { faBlueberries as farBlueberries } from '@fortawesome/pro-regular-svg-icons/faBlueberries';
import { faBluetooth as farBluetooth } from '@fortawesome/pro-regular-svg-icons/faBluetooth';
import { faBluetoothB as farBluetoothB } from '@fortawesome/free-brands-svg-icons/faBluetoothB';
import { faBold as farBold } from '@fortawesome/pro-regular-svg-icons/faBold';
import { faBolt as farBolt } from '@fortawesome/pro-regular-svg-icons/faBolt';
import { faBoltAuto as farBoltAuto } from '@fortawesome/pro-regular-svg-icons/faBoltAuto';
import { faBoltLightning as farBoltLightning } from '@fortawesome/pro-regular-svg-icons/faBoltLightning';
import { faBoltSlash as farBoltSlash } from '@fortawesome/pro-regular-svg-icons/faBoltSlash';
import { faBomb as farBomb } from '@fortawesome/pro-regular-svg-icons/faBomb';
import { faBone as farBone } from '@fortawesome/pro-regular-svg-icons/faBone';
import { faBoneBreak as farBoneBreak } from '@fortawesome/pro-regular-svg-icons/faBoneBreak';
import { faBong as farBong } from '@fortawesome/pro-regular-svg-icons/faBong';
import { faBook as farBook } from '@fortawesome/pro-regular-svg-icons/faBook';
import { faBookAlt as farBookAlt } from '@fortawesome/pro-regular-svg-icons/faBookAlt';
import { faBookArrowRight as farBookArrowRight } from '@fortawesome/pro-regular-svg-icons/faBookArrowRight';
import { faBookArrowUp as farBookArrowUp } from '@fortawesome/pro-regular-svg-icons/faBookArrowUp';
import { faBookAtlas as farBookAtlas } from '@fortawesome/pro-regular-svg-icons/faBookAtlas';
import { faBookBible as farBookBible } from '@fortawesome/pro-regular-svg-icons/faBookBible';
import { faBookBlank as farBookBlank } from '@fortawesome/pro-regular-svg-icons/faBookBlank';
import { faBookBookmark as farBookBookmark } from '@fortawesome/pro-regular-svg-icons/faBookBookmark';
import { faBookCircle as farBookCircle } from '@fortawesome/pro-regular-svg-icons/faBookCircle';
import { faBookCircleArrowRight as farBookCircleArrowRight } from '@fortawesome/pro-regular-svg-icons/faBookCircleArrowRight';
import { faBookCircleArrowUp as farBookCircleArrowUp } from '@fortawesome/pro-regular-svg-icons/faBookCircleArrowUp';
import { faBookCopy as farBookCopy } from '@fortawesome/pro-regular-svg-icons/faBookCopy';
import { faBookDead as farBookDead } from '@fortawesome/pro-regular-svg-icons/faBookDead';
import { faBookFont as farBookFont } from '@fortawesome/pro-regular-svg-icons/faBookFont';
import { faBookHeart as farBookHeart } from '@fortawesome/pro-regular-svg-icons/faBookHeart';
import { faBookJournalWhills as farBookJournalWhills } from '@fortawesome/pro-regular-svg-icons/faBookJournalWhills';
import { faBookLaw as farBookLaw } from '@fortawesome/pro-regular-svg-icons/faBookLaw';
import { faBookmark as farBookmark } from '@fortawesome/pro-regular-svg-icons/faBookmark';
import { faBookmarkCircle as farBookmarkCircle } from '@fortawesome/pro-regular-svg-icons/faBookmarkCircle';
import { faBookmarkSlash as farBookmarkSlash } from '@fortawesome/pro-regular-svg-icons/faBookmarkSlash';
import { faBookMedical as farBookMedical } from '@fortawesome/pro-regular-svg-icons/faBookMedical';
import { faBookOpen as farBookOpen } from '@fortawesome/pro-regular-svg-icons/faBookOpen';
import { faBookOpenAlt as farBookOpenAlt } from '@fortawesome/pro-regular-svg-icons/faBookOpenAlt';
import { faBookOpenCover as farBookOpenCover } from '@fortawesome/pro-regular-svg-icons/faBookOpenCover';
import { faBookOpenReader as farBookOpenReader } from '@fortawesome/pro-regular-svg-icons/faBookOpenReader';
import { faBookQuran as farBookQuran } from '@fortawesome/pro-regular-svg-icons/faBookQuran';
import { faBookReader as farBookReader } from '@fortawesome/pro-regular-svg-icons/faBookReader';
import { faBooks as farBooks } from '@fortawesome/pro-regular-svg-icons/faBooks';
import { faBookSection as farBookSection } from '@fortawesome/pro-regular-svg-icons/faBookSection';
import { faBookSkull as farBookSkull } from '@fortawesome/pro-regular-svg-icons/faBookSkull';
import { faBooksMedical as farBooksMedical } from '@fortawesome/pro-regular-svg-icons/faBooksMedical';
import { faBookSparkles as farBookSparkles } from '@fortawesome/pro-regular-svg-icons/faBookSparkles';
import { faBookSpells as farBookSpells } from '@fortawesome/pro-regular-svg-icons/faBookSpells';
import { faBookTanakh as farBookTanakh } from '@fortawesome/pro-regular-svg-icons/faBookTanakh';
import { faBookUser as farBookUser } from '@fortawesome/pro-regular-svg-icons/faBookUser';
import { faBoombox as farBoombox } from '@fortawesome/pro-regular-svg-icons/faBoombox';
import { faBoot as farBoot } from '@fortawesome/pro-regular-svg-icons/faBoot';
import { faBoothCurtain as farBoothCurtain } from '@fortawesome/pro-regular-svg-icons/faBoothCurtain';
import { faBootHeeled as farBootHeeled } from '@fortawesome/pro-regular-svg-icons/faBootHeeled';
import { faBootstrap as farBootstrap } from '@fortawesome/free-brands-svg-icons/faBootstrap';
import { faBorderAll as farBorderAll } from '@fortawesome/pro-regular-svg-icons/faBorderAll';
import { faBorderBottom as farBorderBottom } from '@fortawesome/pro-regular-svg-icons/faBorderBottom';
import { faBorderBottomRight as farBorderBottomRight } from '@fortawesome/pro-regular-svg-icons/faBorderBottomRight';
import { faBorderCenterH as farBorderCenterH } from '@fortawesome/pro-regular-svg-icons/faBorderCenterH';
import { faBorderCenterV as farBorderCenterV } from '@fortawesome/pro-regular-svg-icons/faBorderCenterV';
import { faBorderInner as farBorderInner } from '@fortawesome/pro-regular-svg-icons/faBorderInner';
import { faBorderLeft as farBorderLeft } from '@fortawesome/pro-regular-svg-icons/faBorderLeft';
import { faBorderNone as farBorderNone } from '@fortawesome/pro-regular-svg-icons/faBorderNone';
import { faBorderOuter as farBorderOuter } from '@fortawesome/pro-regular-svg-icons/faBorderOuter';
import { faBorderRight as farBorderRight } from '@fortawesome/pro-regular-svg-icons/faBorderRight';
import { faBorderStyle as farBorderStyle } from '@fortawesome/pro-regular-svg-icons/faBorderStyle';
import { faBorderStyleAlt as farBorderStyleAlt } from '@fortawesome/pro-regular-svg-icons/faBorderStyleAlt';
import { faBorderTop as farBorderTop } from '@fortawesome/pro-regular-svg-icons/faBorderTop';
import { faBorderTopLeft as farBorderTopLeft } from '@fortawesome/pro-regular-svg-icons/faBorderTopLeft';
import { faBoreHole as farBoreHole } from '@fortawesome/pro-regular-svg-icons/faBoreHole';
import { faBots as farBots } from '@fortawesome/free-brands-svg-icons/faBots';
import { faBottleDroplet as farBottleDroplet } from '@fortawesome/pro-regular-svg-icons/faBottleDroplet';
import { faBottleWater as farBottleWater } from '@fortawesome/pro-regular-svg-icons/faBottleWater';
import { faBowArrow as farBowArrow } from '@fortawesome/pro-regular-svg-icons/faBowArrow';
import { faBowlChopsticks as farBowlChopsticks } from '@fortawesome/pro-regular-svg-icons/faBowlChopsticks';
import { faBowlChopsticksNoodles as farBowlChopsticksNoodles } from '@fortawesome/pro-regular-svg-icons/faBowlChopsticksNoodles';
import { faBowlFood as farBowlFood } from '@fortawesome/pro-regular-svg-icons/faBowlFood';
import { faBowlHot as farBowlHot } from '@fortawesome/pro-regular-svg-icons/faBowlHot';
import { faBowlingBall as farBowlingBall } from '@fortawesome/pro-regular-svg-icons/faBowlingBall';
import { faBowlingBallPin as farBowlingBallPin } from '@fortawesome/pro-regular-svg-icons/faBowlingBallPin';
import { faBowlingPins as farBowlingPins } from '@fortawesome/pro-regular-svg-icons/faBowlingPins';
import { faBowlRice as farBowlRice } from '@fortawesome/pro-regular-svg-icons/faBowlRice';
import { faBowlSalad as farBowlSalad } from '@fortawesome/pro-regular-svg-icons/faBowlSalad';
import { faBowlScoop as farBowlScoop } from '@fortawesome/pro-regular-svg-icons/faBowlScoop';
import { faBowlScoops as farBowlScoops } from '@fortawesome/pro-regular-svg-icons/faBowlScoops';
import { faBowlShavedIce as farBowlShavedIce } from '@fortawesome/pro-regular-svg-icons/faBowlShavedIce';
import { faBowlSoftServe as farBowlSoftServe } from '@fortawesome/pro-regular-svg-icons/faBowlSoftServe';
import { faBowlSpoon as farBowlSpoon } from '@fortawesome/pro-regular-svg-icons/faBowlSpoon';
import { faBox as farBox } from '@fortawesome/pro-regular-svg-icons/faBox';
import { faBoxAlt as farBoxAlt } from '@fortawesome/pro-regular-svg-icons/faBoxAlt';
import { faBoxArchive as farBoxArchive } from '@fortawesome/pro-regular-svg-icons/faBoxArchive';
import { faBoxBallot as farBoxBallot } from '@fortawesome/pro-regular-svg-icons/faBoxBallot';
import { faBoxCheck as farBoxCheck } from '@fortawesome/pro-regular-svg-icons/faBoxCheck';
import { faBoxCircleCheck as farBoxCircleCheck } from '@fortawesome/pro-regular-svg-icons/faBoxCircleCheck';
import { faBoxDollar as farBoxDollar } from '@fortawesome/pro-regular-svg-icons/faBoxDollar';
import { faBoxes as farBoxes } from '@fortawesome/pro-regular-svg-icons/faBoxes';
import { faBoxesAlt as farBoxesAlt } from '@fortawesome/pro-regular-svg-icons/faBoxesAlt';
import { faBoxesPacking as farBoxesPacking } from '@fortawesome/pro-regular-svg-icons/faBoxesPacking';
import { faBoxesStacked as farBoxesStacked } from '@fortawesome/pro-regular-svg-icons/faBoxesStacked';
import { faBoxFragile as farBoxFragile } from '@fortawesome/pro-regular-svg-icons/faBoxFragile';
import { faBoxFull as farBoxFull } from '@fortawesome/pro-regular-svg-icons/faBoxFull';
import { faBoxHeart as farBoxHeart } from '@fortawesome/pro-regular-svg-icons/faBoxHeart';
import { faBoxingGlove as farBoxingGlove } from '@fortawesome/pro-regular-svg-icons/faBoxingGlove';
import { faBoxOpen as farBoxOpen } from '@fortawesome/pro-regular-svg-icons/faBoxOpen';
import { faBoxOpenFull as farBoxOpenFull } from '@fortawesome/pro-regular-svg-icons/faBoxOpenFull';
import { faBoxTaped as farBoxTaped } from '@fortawesome/pro-regular-svg-icons/faBoxTaped';
import { faBoxTissue as farBoxTissue } from '@fortawesome/pro-regular-svg-icons/faBoxTissue';
import { faBoxUp as farBoxUp } from '@fortawesome/pro-regular-svg-icons/faBoxUp';
import { faBoxUsd as farBoxUsd } from '@fortawesome/pro-regular-svg-icons/faBoxUsd';
import { faBracket as farBracket } from '@fortawesome/pro-regular-svg-icons/faBracket';
import { faBracketCurly as farBracketCurly } from '@fortawesome/pro-regular-svg-icons/faBracketCurly';
import { faBracketCurlyLeft as farBracketCurlyLeft } from '@fortawesome/pro-regular-svg-icons/faBracketCurlyLeft';
import { faBracketCurlyRight as farBracketCurlyRight } from '@fortawesome/pro-regular-svg-icons/faBracketCurlyRight';
import { faBracketLeft as farBracketLeft } from '@fortawesome/pro-regular-svg-icons/faBracketLeft';
import { faBracketRound as farBracketRound } from '@fortawesome/pro-regular-svg-icons/faBracketRound';
import { faBracketRoundRight as farBracketRoundRight } from '@fortawesome/pro-regular-svg-icons/faBracketRoundRight';
import { faBrackets as farBrackets } from '@fortawesome/pro-regular-svg-icons/faBrackets';
import { faBracketsCurly as farBracketsCurly } from '@fortawesome/pro-regular-svg-icons/faBracketsCurly';
import { faBracketSquare as farBracketSquare } from '@fortawesome/pro-regular-svg-icons/faBracketSquare';
import { faBracketSquareRight as farBracketSquareRight } from '@fortawesome/pro-regular-svg-icons/faBracketSquareRight';
import { faBracketsRound as farBracketsRound } from '@fortawesome/pro-regular-svg-icons/faBracketsRound';
import { faBracketsSquare as farBracketsSquare } from '@fortawesome/pro-regular-svg-icons/faBracketsSquare';
import { faBraille as farBraille } from '@fortawesome/pro-regular-svg-icons/faBraille';
import { faBrain as farBrain } from '@fortawesome/pro-regular-svg-icons/faBrain';
import { faBrainArrowCurvedRight as farBrainArrowCurvedRight } from '@fortawesome/pro-regular-svg-icons/faBrainArrowCurvedRight';
import { faBrainCircuit as farBrainCircuit } from '@fortawesome/pro-regular-svg-icons/faBrainCircuit';
import { faBrakeWarning as farBrakeWarning } from '@fortawesome/pro-regular-svg-icons/faBrakeWarning';
import { faBrave as farBrave } from '@fortawesome/free-brands-svg-icons/faBrave';
import { faBraveReverse as farBraveReverse } from '@fortawesome/free-brands-svg-icons/faBraveReverse';
import { faBrazilianRealSign as farBrazilianRealSign } from '@fortawesome/pro-regular-svg-icons/faBrazilianRealSign';
import { faBreadLoaf as farBreadLoaf } from '@fortawesome/pro-regular-svg-icons/faBreadLoaf';
import { faBreadSlice as farBreadSlice } from '@fortawesome/pro-regular-svg-icons/faBreadSlice';
import { faBreadSliceButter as farBreadSliceButter } from '@fortawesome/pro-regular-svg-icons/faBreadSliceButter';
import { faBridge as farBridge } from '@fortawesome/pro-regular-svg-icons/faBridge';
import { faBridgeCircleCheck as farBridgeCircleCheck } from '@fortawesome/pro-regular-svg-icons/faBridgeCircleCheck';
import { faBridgeCircleExclamation as farBridgeCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faBridgeCircleExclamation';
import { faBridgeCircleXmark as farBridgeCircleXmark } from '@fortawesome/pro-regular-svg-icons/faBridgeCircleXmark';
import { faBridgeLock as farBridgeLock } from '@fortawesome/pro-regular-svg-icons/faBridgeLock';
import { faBridgeSuspension as farBridgeSuspension } from '@fortawesome/pro-regular-svg-icons/faBridgeSuspension';
import { faBridgeWater as farBridgeWater } from '@fortawesome/pro-regular-svg-icons/faBridgeWater';
import { faBriefcase as farBriefcase } from '@fortawesome/pro-regular-svg-icons/faBriefcase';
import { faBriefcaseArrowRight as farBriefcaseArrowRight } from '@fortawesome/pro-regular-svg-icons/faBriefcaseArrowRight';
import { faBriefcaseBlank as farBriefcaseBlank } from '@fortawesome/pro-regular-svg-icons/faBriefcaseBlank';
import { faBriefcaseClock as farBriefcaseClock } from '@fortawesome/pro-regular-svg-icons/faBriefcaseClock';
import { faBriefcaseMedical as farBriefcaseMedical } from '@fortawesome/pro-regular-svg-icons/faBriefcaseMedical';
import { faBrightness as farBrightness } from '@fortawesome/pro-regular-svg-icons/faBrightness';
import { faBrightnessLow as farBrightnessLow } from '@fortawesome/pro-regular-svg-icons/faBrightnessLow';
import { faBringForward as farBringForward } from '@fortawesome/pro-regular-svg-icons/faBringForward';
import { faBringFront as farBringFront } from '@fortawesome/pro-regular-svg-icons/faBringFront';
import { faBroadcastTower as farBroadcastTower } from '@fortawesome/pro-regular-svg-icons/faBroadcastTower';
import { faBroccoli as farBroccoli } from '@fortawesome/pro-regular-svg-icons/faBroccoli';
import { faBroom as farBroom } from '@fortawesome/pro-regular-svg-icons/faBroom';
import { faBroomBall as farBroomBall } from '@fortawesome/pro-regular-svg-icons/faBroomBall';
import { faBroomWide as farBroomWide } from '@fortawesome/pro-regular-svg-icons/faBroomWide';
import { faBrowser as farBrowser } from '@fortawesome/pro-regular-svg-icons/faBrowser';
import { faBrowsers as farBrowsers } from '@fortawesome/pro-regular-svg-icons/faBrowsers';
import { faBrush as farBrush } from '@fortawesome/pro-regular-svg-icons/faBrush';
import { faBtc as farBtc } from '@fortawesome/free-brands-svg-icons/faBtc';
import { faBucket as farBucket } from '@fortawesome/pro-regular-svg-icons/faBucket';
import { faBuffer as farBuffer } from '@fortawesome/free-brands-svg-icons/faBuffer';
import { faBug as farBug } from '@fortawesome/pro-regular-svg-icons/faBug';
import { faBugs as farBugs } from '@fortawesome/pro-regular-svg-icons/faBugs';
import { faBugSlash as farBugSlash } from '@fortawesome/pro-regular-svg-icons/faBugSlash';
import { faBuilding as farBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding';
import { faBuildingCircleArrowRight as farBuildingCircleArrowRight } from '@fortawesome/pro-regular-svg-icons/faBuildingCircleArrowRight';
import { faBuildingCircleCheck as farBuildingCircleCheck } from '@fortawesome/pro-regular-svg-icons/faBuildingCircleCheck';
import { faBuildingCircleExclamation as farBuildingCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faBuildingCircleExclamation';
import { faBuildingCircleXmark as farBuildingCircleXmark } from '@fortawesome/pro-regular-svg-icons/faBuildingCircleXmark';
import { faBuildingColumns as farBuildingColumns } from '@fortawesome/pro-regular-svg-icons/faBuildingColumns';
import { faBuildingFlag as farBuildingFlag } from '@fortawesome/pro-regular-svg-icons/faBuildingFlag';
import { faBuildingLock as farBuildingLock } from '@fortawesome/pro-regular-svg-icons/faBuildingLock';
import { faBuildingMagnifyingGlass as farBuildingMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faBuildingMagnifyingGlass';
import { faBuildingMemo as farBuildingMemo } from '@fortawesome/pro-regular-svg-icons/faBuildingMemo';
import { faBuildingNgo as farBuildingNgo } from '@fortawesome/pro-regular-svg-icons/faBuildingNgo';
import { faBuildings as farBuildings } from '@fortawesome/pro-regular-svg-icons/faBuildings';
import { faBuildingShield as farBuildingShield } from '@fortawesome/pro-regular-svg-icons/faBuildingShield';
import { faBuildingUn as farBuildingUn } from '@fortawesome/pro-regular-svg-icons/faBuildingUn';
import { faBuildingUser as farBuildingUser } from '@fortawesome/pro-regular-svg-icons/faBuildingUser';
import { faBuildingWheat as farBuildingWheat } from '@fortawesome/pro-regular-svg-icons/faBuildingWheat';
import { faBulldozer as farBulldozer } from '@fortawesome/pro-regular-svg-icons/faBulldozer';
import { faBullhorn as farBullhorn } from '@fortawesome/pro-regular-svg-icons/faBullhorn';
import { faBullseye as farBullseye } from '@fortawesome/pro-regular-svg-icons/faBullseye';
import { faBullseyeArrow as farBullseyeArrow } from '@fortawesome/pro-regular-svg-icons/faBullseyeArrow';
import { faBullseyePointer as farBullseyePointer } from '@fortawesome/pro-regular-svg-icons/faBullseyePointer';
import { faBuoy as farBuoy } from '@fortawesome/pro-regular-svg-icons/faBuoy';
import { faBuoyMooring as farBuoyMooring } from '@fortawesome/pro-regular-svg-icons/faBuoyMooring';
import { faBurger as farBurger } from '@fortawesome/pro-regular-svg-icons/faBurger';
import { faBurgerCheese as farBurgerCheese } from '@fortawesome/pro-regular-svg-icons/faBurgerCheese';
import { faBurgerFries as farBurgerFries } from '@fortawesome/pro-regular-svg-icons/faBurgerFries';
import { faBurgerGlass as farBurgerGlass } from '@fortawesome/pro-regular-svg-icons/faBurgerGlass';
import { faBurgerLettuce as farBurgerLettuce } from '@fortawesome/pro-regular-svg-icons/faBurgerLettuce';
import { faBurgerSoda as farBurgerSoda } from '@fortawesome/pro-regular-svg-icons/faBurgerSoda';
import { faBurn as farBurn } from '@fortawesome/pro-regular-svg-icons/faBurn';
import { faBuromobelexperte as farBuromobelexperte } from '@fortawesome/free-brands-svg-icons/faBuromobelexperte';
import { faBurrito as farBurrito } from '@fortawesome/pro-regular-svg-icons/faBurrito';
import { faBurst as farBurst } from '@fortawesome/pro-regular-svg-icons/faBurst';
import { faBus as farBus } from '@fortawesome/pro-regular-svg-icons/faBus';
import { faBusAlt as farBusAlt } from '@fortawesome/pro-regular-svg-icons/faBusAlt';
import { faBusinessFront as farBusinessFront } from '@fortawesome/pro-regular-svg-icons/faBusinessFront';
import { faBusinessTime as farBusinessTime } from '@fortawesome/pro-regular-svg-icons/faBusinessTime';
import { faBusSchool as farBusSchool } from '@fortawesome/pro-regular-svg-icons/faBusSchool';
import { faBusSimple as farBusSimple } from '@fortawesome/pro-regular-svg-icons/faBusSimple';
import { faButter as farButter } from '@fortawesome/pro-regular-svg-icons/faButter';
import { faBuyNLarge as farBuyNLarge } from '@fortawesome/free-brands-svg-icons/faBuyNLarge';
import { faBuysellads as farBuysellads } from '@fortawesome/free-brands-svg-icons/faBuysellads';
import { faC as farC } from '@fortawesome/pro-regular-svg-icons/faC';
import { faCab as farCab } from '@fortawesome/pro-regular-svg-icons/faCab';
import { faCabin as farCabin } from '@fortawesome/pro-regular-svg-icons/faCabin';
import { faCabinetFiling as farCabinetFiling } from '@fortawesome/pro-regular-svg-icons/faCabinetFiling';
import { faCableCar as farCableCar } from '@fortawesome/pro-regular-svg-icons/faCableCar';
import { faCactus as farCactus } from '@fortawesome/pro-regular-svg-icons/faCactus';
import { faCake as farCake } from '@fortawesome/pro-regular-svg-icons/faCake';
import { faCakeCandles as farCakeCandles } from '@fortawesome/pro-regular-svg-icons/faCakeCandles';
import { faCakeSlice as farCakeSlice } from '@fortawesome/pro-regular-svg-icons/faCakeSlice';
import { faCalculator as farCalculator } from '@fortawesome/pro-regular-svg-icons/faCalculator';
import { faCalculatorAlt as farCalculatorAlt } from '@fortawesome/pro-regular-svg-icons/faCalculatorAlt';
import { faCalculatorSimple as farCalculatorSimple } from '@fortawesome/pro-regular-svg-icons/faCalculatorSimple';
import { faCalendar as farCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { faCalendarAlt as farCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faCalendarArrowDown as farCalendarArrowDown } from '@fortawesome/pro-regular-svg-icons/faCalendarArrowDown';
import { faCalendarArrowUp as farCalendarArrowUp } from '@fortawesome/pro-regular-svg-icons/faCalendarArrowUp';
import { faCalendarCheck as farCalendarCheck } from '@fortawesome/pro-regular-svg-icons/faCalendarCheck';
import { faCalendarCircle as farCalendarCircle } from '@fortawesome/pro-regular-svg-icons/faCalendarCircle';
import { faCalendarCircleExclamation as farCalendarCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCalendarCircleExclamation';
import { faCalendarCircleMinus as farCalendarCircleMinus } from '@fortawesome/pro-regular-svg-icons/faCalendarCircleMinus';
import { faCalendarCirclePlus as farCalendarCirclePlus } from '@fortawesome/pro-regular-svg-icons/faCalendarCirclePlus';
import { faCalendarCircleUser as farCalendarCircleUser } from '@fortawesome/pro-regular-svg-icons/faCalendarCircleUser';
import { faCalendarClock as farCalendarClock } from '@fortawesome/pro-regular-svg-icons/faCalendarClock';
import { faCalendarDay as farCalendarDay } from '@fortawesome/pro-regular-svg-icons/faCalendarDay';
import { faCalendarDays as farCalendarDays } from '@fortawesome/pro-regular-svg-icons/faCalendarDays';
import { faCalendarDownload as farCalendarDownload } from '@fortawesome/pro-regular-svg-icons/faCalendarDownload';
import { faCalendarEdit as farCalendarEdit } from '@fortawesome/pro-regular-svg-icons/faCalendarEdit';
import { faCalendarExclamation as farCalendarExclamation } from '@fortawesome/pro-regular-svg-icons/faCalendarExclamation';
import { faCalendarHeart as farCalendarHeart } from '@fortawesome/pro-regular-svg-icons/faCalendarHeart';
import { faCalendarImage as farCalendarImage } from '@fortawesome/pro-regular-svg-icons/faCalendarImage';
import { faCalendarLines as farCalendarLines } from '@fortawesome/pro-regular-svg-icons/faCalendarLines';
import { faCalendarLinesPen as farCalendarLinesPen } from '@fortawesome/pro-regular-svg-icons/faCalendarLinesPen';
import { faCalendarMinus as farCalendarMinus } from '@fortawesome/pro-regular-svg-icons/faCalendarMinus';
import { faCalendarNote as farCalendarNote } from '@fortawesome/pro-regular-svg-icons/faCalendarNote';
import { faCalendarPen as farCalendarPen } from '@fortawesome/pro-regular-svg-icons/faCalendarPen';
import { faCalendarPlus as farCalendarPlus } from '@fortawesome/pro-regular-svg-icons/faCalendarPlus';
import { faCalendarRange as farCalendarRange } from '@fortawesome/pro-regular-svg-icons/faCalendarRange';
import { faCalendars as farCalendars } from '@fortawesome/pro-regular-svg-icons/faCalendars';
import { faCalendarStar as farCalendarStar } from '@fortawesome/pro-regular-svg-icons/faCalendarStar';
import { faCalendarTime as farCalendarTime } from '@fortawesome/pro-regular-svg-icons/faCalendarTime';
import { faCalendarTimes as farCalendarTimes } from '@fortawesome/pro-regular-svg-icons/faCalendarTimes';
import { faCalendarUpload as farCalendarUpload } from '@fortawesome/pro-regular-svg-icons/faCalendarUpload';
import { faCalendarUsers as farCalendarUsers } from '@fortawesome/pro-regular-svg-icons/faCalendarUsers';
import { faCalendarWeek as farCalendarWeek } from '@fortawesome/pro-regular-svg-icons/faCalendarWeek';
import { faCalendarXmark as farCalendarXmark } from '@fortawesome/pro-regular-svg-icons/faCalendarXmark';
import { faCamcorder as farCamcorder } from '@fortawesome/pro-regular-svg-icons/faCamcorder';
import { faCamera as farCamera } from '@fortawesome/pro-regular-svg-icons/faCamera';
import { faCameraAlt as farCameraAlt } from '@fortawesome/pro-regular-svg-icons/faCameraAlt';
import { faCameraCctv as farCameraCctv } from '@fortawesome/pro-regular-svg-icons/faCameraCctv';
import { faCameraCircle as farCameraCircle } from '@fortawesome/pro-regular-svg-icons/faCameraCircle';
import { faCameraHome as farCameraHome } from '@fortawesome/pro-regular-svg-icons/faCameraHome';
import { faCameraMovie as farCameraMovie } from '@fortawesome/pro-regular-svg-icons/faCameraMovie';
import { faCameraPolaroid as farCameraPolaroid } from '@fortawesome/pro-regular-svg-icons/faCameraPolaroid';
import { faCameraRetro as farCameraRetro } from '@fortawesome/pro-regular-svg-icons/faCameraRetro';
import { faCameraRotate as farCameraRotate } from '@fortawesome/pro-regular-svg-icons/faCameraRotate';
import { faCameraSecurity as farCameraSecurity } from '@fortawesome/pro-regular-svg-icons/faCameraSecurity';
import { faCameraSlash as farCameraSlash } from '@fortawesome/pro-regular-svg-icons/faCameraSlash';
import { faCameraViewfinder as farCameraViewfinder } from '@fortawesome/pro-regular-svg-icons/faCameraViewfinder';
import { faCameraWeb as farCameraWeb } from '@fortawesome/pro-regular-svg-icons/faCameraWeb';
import { faCameraWebSlash as farCameraWebSlash } from '@fortawesome/pro-regular-svg-icons/faCameraWebSlash';
import { faCampfire as farCampfire } from '@fortawesome/pro-regular-svg-icons/faCampfire';
import { faCampground as farCampground } from '@fortawesome/pro-regular-svg-icons/faCampground';
import { faCanadianMapleLeaf as farCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons/faCanadianMapleLeaf';
import { faCancel as farCancel } from '@fortawesome/pro-regular-svg-icons/faCancel';
import { faCandleHolder as farCandleHolder } from '@fortawesome/pro-regular-svg-icons/faCandleHolder';
import { faCandy as farCandy } from '@fortawesome/pro-regular-svg-icons/faCandy';
import { faCandyBar as farCandyBar } from '@fortawesome/pro-regular-svg-icons/faCandyBar';
import { faCandyCane as farCandyCane } from '@fortawesome/pro-regular-svg-icons/faCandyCane';
import { faCandyCorn as farCandyCorn } from '@fortawesome/pro-regular-svg-icons/faCandyCorn';
import { faCanFood as farCanFood } from '@fortawesome/pro-regular-svg-icons/faCanFood';
import { faCannabis as farCannabis } from '@fortawesome/pro-regular-svg-icons/faCannabis';
import { faCannon as farCannon } from '@fortawesome/pro-regular-svg-icons/faCannon';
import { faCapsules as farCapsules } from '@fortawesome/pro-regular-svg-icons/faCapsules';
import { faCar as farCar } from '@fortawesome/pro-regular-svg-icons/faCar';
import { faCarAlt as farCarAlt } from '@fortawesome/pro-regular-svg-icons/faCarAlt';
import { faCaravan as farCaravan } from '@fortawesome/pro-regular-svg-icons/faCaravan';
import { faCaravanAlt as farCaravanAlt } from '@fortawesome/pro-regular-svg-icons/faCaravanAlt';
import { faCaravanSimple as farCaravanSimple } from '@fortawesome/pro-regular-svg-icons/faCaravanSimple';
import { faCarBattery as farCarBattery } from '@fortawesome/pro-regular-svg-icons/faCarBattery';
import { faCarBolt as farCarBolt } from '@fortawesome/pro-regular-svg-icons/faCarBolt';
import { faCarBuilding as farCarBuilding } from '@fortawesome/pro-regular-svg-icons/faCarBuilding';
import { faCarBump as farCarBump } from '@fortawesome/pro-regular-svg-icons/faCarBump';
import { faCarBurst as farCarBurst } from '@fortawesome/pro-regular-svg-icons/faCarBurst';
import { faCarBus as farCarBus } from '@fortawesome/pro-regular-svg-icons/faCarBus';
import { faCarCircleBolt as farCarCircleBolt } from '@fortawesome/pro-regular-svg-icons/faCarCircleBolt';
import { faCarCrash as farCarCrash } from '@fortawesome/pro-regular-svg-icons/faCarCrash';
import { faCardClub as farCardClub } from '@fortawesome/pro-regular-svg-icons/faCardClub';
import { faCardDiamond as farCardDiamond } from '@fortawesome/pro-regular-svg-icons/faCardDiamond';
import { faCardHeart as farCardHeart } from '@fortawesome/pro-regular-svg-icons/faCardHeart';
import { faCards as farCards } from '@fortawesome/pro-regular-svg-icons/faCards';
import { faCardsBlank as farCardsBlank } from '@fortawesome/pro-regular-svg-icons/faCardsBlank';
import { faCardSpade as farCardSpade } from '@fortawesome/pro-regular-svg-icons/faCardSpade';
import { faCaretCircleDown as farCaretCircleDown } from '@fortawesome/pro-regular-svg-icons/faCaretCircleDown';
import { faCaretCircleLeft as farCaretCircleLeft } from '@fortawesome/pro-regular-svg-icons/faCaretCircleLeft';
import { faCaretCircleRight as farCaretCircleRight } from '@fortawesome/pro-regular-svg-icons/faCaretCircleRight';
import { faCaretCircleUp as farCaretCircleUp } from '@fortawesome/pro-regular-svg-icons/faCaretCircleUp';
import { faCaretDown as farCaretDown } from '@fortawesome/pro-regular-svg-icons/faCaretDown';
import { faCaretLeft as farCaretLeft } from '@fortawesome/pro-regular-svg-icons/faCaretLeft';
import { faCaretRight as farCaretRight } from '@fortawesome/pro-regular-svg-icons/faCaretRight';
import { faCaretSquareDown as farCaretSquareDown } from '@fortawesome/pro-regular-svg-icons/faCaretSquareDown';
import { faCaretSquareLeft as farCaretSquareLeft } from '@fortawesome/pro-regular-svg-icons/faCaretSquareLeft';
import { faCaretSquareRight as farCaretSquareRight } from '@fortawesome/pro-regular-svg-icons/faCaretSquareRight';
import { faCaretSquareUp as farCaretSquareUp } from '@fortawesome/pro-regular-svg-icons/faCaretSquareUp';
import { faCaretUp as farCaretUp } from '@fortawesome/pro-regular-svg-icons/faCaretUp';
import { faCarGarage as farCarGarage } from '@fortawesome/pro-regular-svg-icons/faCarGarage';
import { faCarMechanic as farCarMechanic } from '@fortawesome/pro-regular-svg-icons/faCarMechanic';
import { faCarMirrors as farCarMirrors } from '@fortawesome/pro-regular-svg-icons/faCarMirrors';
import { faCarOn as farCarOn } from '@fortawesome/pro-regular-svg-icons/faCarOn';
import { faCarRear as farCarRear } from '@fortawesome/pro-regular-svg-icons/faCarRear';
import { faCarriageBaby as farCarriageBaby } from '@fortawesome/pro-regular-svg-icons/faCarriageBaby';
import { faCarrot as farCarrot } from '@fortawesome/pro-regular-svg-icons/faCarrot';
import { faCars as farCars } from '@fortawesome/pro-regular-svg-icons/faCars';
import { faCarSide as farCarSide } from '@fortawesome/pro-regular-svg-icons/faCarSide';
import { faCarSideBolt as farCarSideBolt } from '@fortawesome/pro-regular-svg-icons/faCarSideBolt';
import { faCartArrowDown as farCartArrowDown } from '@fortawesome/pro-regular-svg-icons/faCartArrowDown';
import { faCartArrowUp as farCartArrowUp } from '@fortawesome/pro-regular-svg-icons/faCartArrowUp';
import { faCartCircleArrowDown as farCartCircleArrowDown } from '@fortawesome/pro-regular-svg-icons/faCartCircleArrowDown';
import { faCartCircleArrowUp as farCartCircleArrowUp } from '@fortawesome/pro-regular-svg-icons/faCartCircleArrowUp';
import { faCartCircleCheck as farCartCircleCheck } from '@fortawesome/pro-regular-svg-icons/faCartCircleCheck';
import { faCartCircleExclamation as farCartCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCartCircleExclamation';
import { faCartCirclePlus as farCartCirclePlus } from '@fortawesome/pro-regular-svg-icons/faCartCirclePlus';
import { faCartCircleXmark as farCartCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCartCircleXmark';
import { faCartFlatbed as farCartFlatbed } from '@fortawesome/pro-regular-svg-icons/faCartFlatbed';
import { faCartFlatbedBoxes as farCartFlatbedBoxes } from '@fortawesome/pro-regular-svg-icons/faCartFlatbedBoxes';
import { faCartFlatbedEmpty as farCartFlatbedEmpty } from '@fortawesome/pro-regular-svg-icons/faCartFlatbedEmpty';
import { faCartFlatbedSuitcase as farCartFlatbedSuitcase } from '@fortawesome/pro-regular-svg-icons/faCartFlatbedSuitcase';
import { faCarTilt as farCarTilt } from '@fortawesome/pro-regular-svg-icons/faCarTilt';
import { faCartMinus as farCartMinus } from '@fortawesome/pro-regular-svg-icons/faCartMinus';
import { faCartPlus as farCartPlus } from '@fortawesome/pro-regular-svg-icons/faCartPlus';
import { faCartShopping as farCartShopping } from '@fortawesome/pro-regular-svg-icons/faCartShopping';
import { faCartShoppingFast as farCartShoppingFast } from '@fortawesome/pro-regular-svg-icons/faCartShoppingFast';
import { faCarTunnel as farCarTunnel } from '@fortawesome/pro-regular-svg-icons/faCarTunnel';
import { faCartXmark as farCartXmark } from '@fortawesome/pro-regular-svg-icons/faCartXmark';
import { faCarWash as farCarWash } from '@fortawesome/pro-regular-svg-icons/faCarWash';
import { faCarWrench as farCarWrench } from '@fortawesome/pro-regular-svg-icons/faCarWrench';
import { faCashRegister as farCashRegister } from '@fortawesome/pro-regular-svg-icons/faCashRegister';
import { faCassetteBetamax as farCassetteBetamax } from '@fortawesome/pro-regular-svg-icons/faCassetteBetamax';
import { faCassetteTape as farCassetteTape } from '@fortawesome/pro-regular-svg-icons/faCassetteTape';
import { faCassetteVhs as farCassetteVhs } from '@fortawesome/pro-regular-svg-icons/faCassetteVhs';
import { faCastle as farCastle } from '@fortawesome/pro-regular-svg-icons/faCastle';
import { faCat as farCat } from '@fortawesome/pro-regular-svg-icons/faCat';
import { faCatSpace as farCatSpace } from '@fortawesome/pro-regular-svg-icons/faCatSpace';
import { faCauldron as farCauldron } from '@fortawesome/pro-regular-svg-icons/faCauldron';
import { faCcAmazonPay as farCcAmazonPay } from '@fortawesome/free-brands-svg-icons/faCcAmazonPay';
import { faCcAmex as farCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex';
import { faCcApplePay as farCcApplePay } from '@fortawesome/free-brands-svg-icons/faCcApplePay';
import { faCcDinersClub as farCcDinersClub } from '@fortawesome/free-brands-svg-icons/faCcDinersClub';
import { faCcDiscover as farCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover';
import { faCcJcb as farCcJcb } from '@fortawesome/free-brands-svg-icons/faCcJcb';
import { faCcMastercard as farCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard';
import { faCcPaypal as farCcPaypal } from '@fortawesome/free-brands-svg-icons/faCcPaypal';
import { faCcStripe as farCcStripe } from '@fortawesome/free-brands-svg-icons/faCcStripe';
import { faCctv as farCctv } from '@fortawesome/pro-regular-svg-icons/faCctv';
import { faCcVisa as farCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa';
import { faCediSign as farCediSign } from '@fortawesome/pro-regular-svg-icons/faCediSign';
import { faCentercode as farCentercode } from '@fortawesome/free-brands-svg-icons/faCentercode';
import { faCentos as farCentos } from '@fortawesome/free-brands-svg-icons/faCentos';
import { faCentSign as farCentSign } from '@fortawesome/pro-regular-svg-icons/faCentSign';
import { faCertificate as farCertificate } from '@fortawesome/pro-regular-svg-icons/faCertificate';
import { faChain as farChain } from '@fortawesome/pro-regular-svg-icons/faChain';
import { faChainBroken as farChainBroken } from '@fortawesome/pro-regular-svg-icons/faChainBroken';
import { faChainHorizontal as farChainHorizontal } from '@fortawesome/pro-regular-svg-icons/faChainHorizontal';
import { faChainHorizontalSlash as farChainHorizontalSlash } from '@fortawesome/pro-regular-svg-icons/faChainHorizontalSlash';
import { faChainSlash as farChainSlash } from '@fortawesome/pro-regular-svg-icons/faChainSlash';
import { faChair as farChair } from '@fortawesome/pro-regular-svg-icons/faChair';
import { faChairOffice as farChairOffice } from '@fortawesome/pro-regular-svg-icons/faChairOffice';
import { faChalkboard as farChalkboard } from '@fortawesome/pro-regular-svg-icons/faChalkboard';
import { faChalkboardTeacher as farChalkboardTeacher } from '@fortawesome/pro-regular-svg-icons/faChalkboardTeacher';
import { faChalkboardUser as farChalkboardUser } from '@fortawesome/pro-regular-svg-icons/faChalkboardUser';
import { faChampagneGlass as farChampagneGlass } from '@fortawesome/pro-regular-svg-icons/faChampagneGlass';
import { faChampagneGlasses as farChampagneGlasses } from '@fortawesome/pro-regular-svg-icons/faChampagneGlasses';
import { faChargingStation as farChargingStation } from '@fortawesome/pro-regular-svg-icons/faChargingStation';
import { faChartArea as farChartArea } from '@fortawesome/pro-regular-svg-icons/faChartArea';
import { faChartBar as farChartBar } from '@fortawesome/pro-regular-svg-icons/faChartBar';
import { faChartBullet as farChartBullet } from '@fortawesome/pro-regular-svg-icons/faChartBullet';
import { faChartCandlestick as farChartCandlestick } from '@fortawesome/pro-regular-svg-icons/faChartCandlestick';
import { faChartColumn as farChartColumn } from '@fortawesome/pro-regular-svg-icons/faChartColumn';
import { faChartGantt as farChartGantt } from '@fortawesome/pro-regular-svg-icons/faChartGantt';
import { faChartKanban as farChartKanban } from '@fortawesome/pro-regular-svg-icons/faChartKanban';
import { faChartLine as farChartLine } from '@fortawesome/pro-regular-svg-icons/faChartLine';
import { faChartLineDown as farChartLineDown } from '@fortawesome/pro-regular-svg-icons/faChartLineDown';
import { faChartLineUp as farChartLineUp } from '@fortawesome/pro-regular-svg-icons/faChartLineUp';
import { faChartLineUpDown as farChartLineUpDown } from '@fortawesome/pro-regular-svg-icons/faChartLineUpDown';
import { faChartMixed as farChartMixed } from '@fortawesome/pro-regular-svg-icons/faChartMixed';
import { faChartMixedUpCircleCurrency as farChartMixedUpCircleCurrency } from '@fortawesome/pro-regular-svg-icons/faChartMixedUpCircleCurrency';
import { faChartMixedUpCircleDollar as farChartMixedUpCircleDollar } from '@fortawesome/pro-regular-svg-icons/faChartMixedUpCircleDollar';
import { faChartNetwork as farChartNetwork } from '@fortawesome/pro-regular-svg-icons/faChartNetwork';
import { faChartPie as farChartPie } from '@fortawesome/pro-regular-svg-icons/faChartPie';
import { faChartPieAlt as farChartPieAlt } from '@fortawesome/pro-regular-svg-icons/faChartPieAlt';
import { faChartPieSimple as farChartPieSimple } from '@fortawesome/pro-regular-svg-icons/faChartPieSimple';
import { faChartPieSimpleCircleCurrency as farChartPieSimpleCircleCurrency } from '@fortawesome/pro-regular-svg-icons/faChartPieSimpleCircleCurrency';
import { faChartPieSimpleCircleDollar as farChartPieSimpleCircleDollar } from '@fortawesome/pro-regular-svg-icons/faChartPieSimpleCircleDollar';
import { faChartPyramid as farChartPyramid } from '@fortawesome/pro-regular-svg-icons/faChartPyramid';
import { faChartRadar as farChartRadar } from '@fortawesome/pro-regular-svg-icons/faChartRadar';
import { faChartScatter as farChartScatter } from '@fortawesome/pro-regular-svg-icons/faChartScatter';
import { faChartScatter3d as farChartScatter3d } from '@fortawesome/pro-regular-svg-icons/faChartScatter3d';
import { faChartScatterBubble as farChartScatterBubble } from '@fortawesome/pro-regular-svg-icons/faChartScatterBubble';
import { faChartSimple as farChartSimple } from '@fortawesome/pro-regular-svg-icons/faChartSimple';
import { faChartSimpleHorizontal as farChartSimpleHorizontal } from '@fortawesome/pro-regular-svg-icons/faChartSimpleHorizontal';
import { faChartTreeMap as farChartTreeMap } from '@fortawesome/pro-regular-svg-icons/faChartTreeMap';
import { faChartUser as farChartUser } from '@fortawesome/pro-regular-svg-icons/faChartUser';
import { faChartWaterfall as farChartWaterfall } from '@fortawesome/pro-regular-svg-icons/faChartWaterfall';
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCheckCircle as farCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faCheckDouble as farCheckDouble } from '@fortawesome/pro-regular-svg-icons/faCheckDouble';
import { faCheckSquare as farCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import { faCheckToSlot as farCheckToSlot } from '@fortawesome/pro-regular-svg-icons/faCheckToSlot';
import { faCheese as farCheese } from '@fortawesome/pro-regular-svg-icons/faCheese';
import { faCheeseburger as farCheeseburger } from '@fortawesome/pro-regular-svg-icons/faCheeseburger';
import { faCheeseSwiss as farCheeseSwiss } from '@fortawesome/pro-regular-svg-icons/faCheeseSwiss';
import { faCherries as farCherries } from '@fortawesome/pro-regular-svg-icons/faCherries';
import { faChess as farChess } from '@fortawesome/pro-regular-svg-icons/faChess';
import { faChessBishop as farChessBishop } from '@fortawesome/pro-regular-svg-icons/faChessBishop';
import { faChessBishopAlt as farChessBishopAlt } from '@fortawesome/pro-regular-svg-icons/faChessBishopAlt';
import { faChessBishopPiece as farChessBishopPiece } from '@fortawesome/pro-regular-svg-icons/faChessBishopPiece';
import { faChessBoard as farChessBoard } from '@fortawesome/pro-regular-svg-icons/faChessBoard';
import { faChessClock as farChessClock } from '@fortawesome/pro-regular-svg-icons/faChessClock';
import { faChessClockAlt as farChessClockAlt } from '@fortawesome/pro-regular-svg-icons/faChessClockAlt';
import { faChessClockFlip as farChessClockFlip } from '@fortawesome/pro-regular-svg-icons/faChessClockFlip';
import { faChessKing as farChessKing } from '@fortawesome/pro-regular-svg-icons/faChessKing';
import { faChessKingAlt as farChessKingAlt } from '@fortawesome/pro-regular-svg-icons/faChessKingAlt';
import { faChessKingPiece as farChessKingPiece } from '@fortawesome/pro-regular-svg-icons/faChessKingPiece';
import { faChessKnight as farChessKnight } from '@fortawesome/pro-regular-svg-icons/faChessKnight';
import { faChessKnightAlt as farChessKnightAlt } from '@fortawesome/pro-regular-svg-icons/faChessKnightAlt';
import { faChessKnightPiece as farChessKnightPiece } from '@fortawesome/pro-regular-svg-icons/faChessKnightPiece';
import { faChessPawn as farChessPawn } from '@fortawesome/pro-regular-svg-icons/faChessPawn';
import { faChessPawnAlt as farChessPawnAlt } from '@fortawesome/pro-regular-svg-icons/faChessPawnAlt';
import { faChessPawnPiece as farChessPawnPiece } from '@fortawesome/pro-regular-svg-icons/faChessPawnPiece';
import { faChessQueen as farChessQueen } from '@fortawesome/pro-regular-svg-icons/faChessQueen';
import { faChessQueenAlt as farChessQueenAlt } from '@fortawesome/pro-regular-svg-icons/faChessQueenAlt';
import { faChessQueenPiece as farChessQueenPiece } from '@fortawesome/pro-regular-svg-icons/faChessQueenPiece';
import { faChessRook as farChessRook } from '@fortawesome/pro-regular-svg-icons/faChessRook';
import { faChessRookAlt as farChessRookAlt } from '@fortawesome/pro-regular-svg-icons/faChessRookAlt';
import { faChessRookPiece as farChessRookPiece } from '@fortawesome/pro-regular-svg-icons/faChessRookPiece';
import { faChestnut as farChestnut } from '@fortawesome/pro-regular-svg-icons/faChestnut';
import { faChevronCircleDown as farChevronCircleDown } from '@fortawesome/pro-regular-svg-icons/faChevronCircleDown';
import { faChevronCircleLeft as farChevronCircleLeft } from '@fortawesome/pro-regular-svg-icons/faChevronCircleLeft';
import { faChevronCircleRight as farChevronCircleRight } from '@fortawesome/pro-regular-svg-icons/faChevronCircleRight';
import { faChevronCircleUp as farChevronCircleUp } from '@fortawesome/pro-regular-svg-icons/faChevronCircleUp';
import { faChevronDoubleDown as farChevronDoubleDown } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleDown';
import { faChevronDoubleLeft as farChevronDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleLeft';
import { faChevronDoubleRight as farChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';
import { faChevronDoubleUp as farChevronDoubleUp } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleUp';
import { faChevronDown as farChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronsDown as farChevronsDown } from '@fortawesome/pro-regular-svg-icons/faChevronsDown';
import { faChevronsLeft as farChevronsLeft } from '@fortawesome/pro-regular-svg-icons/faChevronsLeft';
import { faChevronSquareDown as farChevronSquareDown } from '@fortawesome/pro-regular-svg-icons/faChevronSquareDown';
import { faChevronSquareLeft as farChevronSquareLeft } from '@fortawesome/pro-regular-svg-icons/faChevronSquareLeft';
import { faChevronSquareRight as farChevronSquareRight } from '@fortawesome/pro-regular-svg-icons/faChevronSquareRight';
import { faChevronSquareUp as farChevronSquareUp } from '@fortawesome/pro-regular-svg-icons/faChevronSquareUp';
import { faChevronsRight as farChevronsRight } from '@fortawesome/pro-regular-svg-icons/faChevronsRight';
import { faChevronsUp as farChevronsUp } from '@fortawesome/pro-regular-svg-icons/faChevronsUp';
import { faChevronUp as farChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faChfSign as farChfSign } from '@fortawesome/pro-regular-svg-icons/faChfSign';
import { faChild as farChild } from '@fortawesome/pro-regular-svg-icons/faChild';
import { faChildCombatant as farChildCombatant } from '@fortawesome/pro-regular-svg-icons/faChildCombatant';
import { faChildDress as farChildDress } from '@fortawesome/pro-regular-svg-icons/faChildDress';
import { faChildReaching as farChildReaching } from '@fortawesome/pro-regular-svg-icons/faChildReaching';
import { faChildren as farChildren } from '@fortawesome/pro-regular-svg-icons/faChildren';
import { faChildRifle as farChildRifle } from '@fortawesome/pro-regular-svg-icons/faChildRifle';
import { faChimney as farChimney } from '@fortawesome/pro-regular-svg-icons/faChimney';
import { faChocolateBar as farChocolateBar } from '@fortawesome/pro-regular-svg-icons/faChocolateBar';
import { faChopsticks as farChopsticks } from '@fortawesome/pro-regular-svg-icons/faChopsticks';
import { faChrome as farChrome } from '@fortawesome/free-brands-svg-icons/faChrome';
import { faChromecast as farChromecast } from '@fortawesome/free-brands-svg-icons/faChromecast';
import { faChurch as farChurch } from '@fortawesome/pro-regular-svg-icons/faChurch';
import { faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCircle0 as farCircle0 } from '@fortawesome/pro-regular-svg-icons/faCircle0';
import { faCircle1 as farCircle1 } from '@fortawesome/pro-regular-svg-icons/faCircle1';
import { faCircle2 as farCircle2 } from '@fortawesome/pro-regular-svg-icons/faCircle2';
import { faCircle3 as farCircle3 } from '@fortawesome/pro-regular-svg-icons/faCircle3';
import { faCircle4 as farCircle4 } from '@fortawesome/pro-regular-svg-icons/faCircle4';
import { faCircle5 as farCircle5 } from '@fortawesome/pro-regular-svg-icons/faCircle5';
import { faCircle6 as farCircle6 } from '@fortawesome/pro-regular-svg-icons/faCircle6';
import { faCircle7 as farCircle7 } from '@fortawesome/pro-regular-svg-icons/faCircle7';
import { faCircle8 as farCircle8 } from '@fortawesome/pro-regular-svg-icons/faCircle8';
import { faCircle9 as farCircle9 } from '@fortawesome/pro-regular-svg-icons/faCircle9';
import { faCircleA as farCircleA } from '@fortawesome/pro-regular-svg-icons/faCircleA';
import { faCircleAmpersand as farCircleAmpersand } from '@fortawesome/pro-regular-svg-icons/faCircleAmpersand';
import { faCircleArrowDown as farCircleArrowDown } from '@fortawesome/pro-regular-svg-icons/faCircleArrowDown';
import { faCircleArrowDownLeft as farCircleArrowDownLeft } from '@fortawesome/pro-regular-svg-icons/faCircleArrowDownLeft';
import { faCircleArrowDownRight as farCircleArrowDownRight } from '@fortawesome/pro-regular-svg-icons/faCircleArrowDownRight';
import { faCircleArrowLeft as farCircleArrowLeft } from '@fortawesome/pro-regular-svg-icons/faCircleArrowLeft';
import { faCircleArrowRight as farCircleArrowRight } from '@fortawesome/pro-regular-svg-icons/faCircleArrowRight';
import { faCircleArrowUp as farCircleArrowUp } from '@fortawesome/pro-regular-svg-icons/faCircleArrowUp';
import { faCircleArrowUpLeft as farCircleArrowUpLeft } from '@fortawesome/pro-regular-svg-icons/faCircleArrowUpLeft';
import { faCircleArrowUpRight as farCircleArrowUpRight } from '@fortawesome/pro-regular-svg-icons/faCircleArrowUpRight';
import { faCircleB as farCircleB } from '@fortawesome/pro-regular-svg-icons/faCircleB';
import { faCircleBolt as farCircleBolt } from '@fortawesome/pro-regular-svg-icons/faCircleBolt';
import { faCircleBookmark as farCircleBookmark } from '@fortawesome/pro-regular-svg-icons/faCircleBookmark';
import { faCircleBookOpen as farCircleBookOpen } from '@fortawesome/pro-regular-svg-icons/faCircleBookOpen';
import { faCircleC as farCircleC } from '@fortawesome/pro-regular-svg-icons/faCircleC';
import { faCircleCalendar as farCircleCalendar } from '@fortawesome/pro-regular-svg-icons/faCircleCalendar';
import { faCircleCamera as farCircleCamera } from '@fortawesome/pro-regular-svg-icons/faCircleCamera';
import { faCircleCaretDown as farCircleCaretDown } from '@fortawesome/pro-regular-svg-icons/faCircleCaretDown';
import { faCircleCaretLeft as farCircleCaretLeft } from '@fortawesome/pro-regular-svg-icons/faCircleCaretLeft';
import { faCircleCaretRight as farCircleCaretRight } from '@fortawesome/pro-regular-svg-icons/faCircleCaretRight';
import { faCircleCaretUp as farCircleCaretUp } from '@fortawesome/pro-regular-svg-icons/faCircleCaretUp';
import { faCircleCheck as farCircleCheck } from '@fortawesome/pro-regular-svg-icons/faCircleCheck';
import { faCircleChevronDown as farCircleChevronDown } from '@fortawesome/pro-regular-svg-icons/faCircleChevronDown';
import { faCircleChevronLeft as farCircleChevronLeft } from '@fortawesome/pro-regular-svg-icons/faCircleChevronLeft';
import { faCircleChevronRight as farCircleChevronRight } from '@fortawesome/pro-regular-svg-icons/faCircleChevronRight';
import { faCircleChevronUp as farCircleChevronUp } from '@fortawesome/pro-regular-svg-icons/faCircleChevronUp';
import { faCircleD as farCircleD } from '@fortawesome/pro-regular-svg-icons/faCircleD';
import { faCircleDashed as farCircleDashed } from '@fortawesome/pro-regular-svg-icons/faCircleDashed';
import { faCircleDivide as farCircleDivide } from '@fortawesome/pro-regular-svg-icons/faCircleDivide';
import { faCircleDollar as farCircleDollar } from '@fortawesome/pro-regular-svg-icons/faCircleDollar';
import { faCircleDollarToSlot as farCircleDollarToSlot } from '@fortawesome/pro-regular-svg-icons/faCircleDollarToSlot';
import { faCircleDot as farCircleDot } from '@fortawesome/pro-regular-svg-icons/faCircleDot';
import { faCircleDown as farCircleDown } from '@fortawesome/pro-regular-svg-icons/faCircleDown';
import { faCircleDownLeft as farCircleDownLeft } from '@fortawesome/pro-regular-svg-icons/faCircleDownLeft';
import { faCircleDownRight as farCircleDownRight } from '@fortawesome/pro-regular-svg-icons/faCircleDownRight';
import { faCircleE as farCircleE } from '@fortawesome/pro-regular-svg-icons/faCircleE';
import { faCircleEllipsis as farCircleEllipsis } from '@fortawesome/pro-regular-svg-icons/faCircleEllipsis';
import { faCircleEllipsisVertical as farCircleEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faCircleEllipsisVertical';
import { faCircleEnvelope as farCircleEnvelope } from '@fortawesome/pro-regular-svg-icons/faCircleEnvelope';
import { faCircleEuro as farCircleEuro } from '@fortawesome/pro-regular-svg-icons/faCircleEuro';
import { faCircleExclamation as farCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import { faCircleExclamationCheck as farCircleExclamationCheck } from '@fortawesome/pro-regular-svg-icons/faCircleExclamationCheck';
import { faCircleF as farCircleF } from '@fortawesome/pro-regular-svg-icons/faCircleF';
import { faCircleG as farCircleG } from '@fortawesome/pro-regular-svg-icons/faCircleG';
import { faCircleH as farCircleH } from '@fortawesome/pro-regular-svg-icons/faCircleH';
import { faCircleHalf as farCircleHalf } from '@fortawesome/pro-regular-svg-icons/faCircleHalf';
import { faCircleHalfStroke as farCircleHalfStroke } from '@fortawesome/pro-regular-svg-icons/faCircleHalfStroke';
import { faCircleHeart as farCircleHeart } from '@fortawesome/pro-regular-svg-icons/faCircleHeart';
import { faCircleI as farCircleI } from '@fortawesome/pro-regular-svg-icons/faCircleI';
import { faCircleInfo as farCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo';
import { faCircleJ as farCircleJ } from '@fortawesome/pro-regular-svg-icons/faCircleJ';
import { faCircleK as farCircleK } from '@fortawesome/pro-regular-svg-icons/faCircleK';
import { faCircleL as farCircleL } from '@fortawesome/pro-regular-svg-icons/faCircleL';
import { faCircleLeft as farCircleLeft } from '@fortawesome/pro-regular-svg-icons/faCircleLeft';
import { faCircleLocationArrow as farCircleLocationArrow } from '@fortawesome/pro-regular-svg-icons/faCircleLocationArrow';
import { faCircleM as farCircleM } from '@fortawesome/pro-regular-svg-icons/faCircleM';
import { faCircleMicrophone as farCircleMicrophone } from '@fortawesome/pro-regular-svg-icons/faCircleMicrophone';
import { faCircleMicrophoneLines as farCircleMicrophoneLines } from '@fortawesome/pro-regular-svg-icons/faCircleMicrophoneLines';
import { faCircleMinus as farCircleMinus } from '@fortawesome/pro-regular-svg-icons/faCircleMinus';
import { faCircleN as farCircleN } from '@fortawesome/pro-regular-svg-icons/faCircleN';
import { faCircleNodes as farCircleNodes } from '@fortawesome/pro-regular-svg-icons/faCircleNodes';
import { faCircleNotch as farCircleNotch } from '@fortawesome/pro-regular-svg-icons/faCircleNotch';
import { faCircleO as farCircleO } from '@fortawesome/pro-regular-svg-icons/faCircleO';
import { faCircleP as farCircleP } from '@fortawesome/pro-regular-svg-icons/faCircleP';
import { faCircleParking as farCircleParking } from '@fortawesome/pro-regular-svg-icons/faCircleParking';
import { faCirclePause as farCirclePause } from '@fortawesome/pro-regular-svg-icons/faCirclePause';
import { faCirclePhone as farCirclePhone } from '@fortawesome/pro-regular-svg-icons/faCirclePhone';
import { faCirclePhoneFlip as farCirclePhoneFlip } from '@fortawesome/pro-regular-svg-icons/faCirclePhoneFlip';
import { faCirclePhoneHangup as farCirclePhoneHangup } from '@fortawesome/pro-regular-svg-icons/faCirclePhoneHangup';
import { faCirclePlay as farCirclePlay } from '@fortawesome/pro-regular-svg-icons/faCirclePlay';
import { faCirclePlus as farCirclePlus } from '@fortawesome/pro-regular-svg-icons/faCirclePlus';
import { faCircleQ as farCircleQ } from '@fortawesome/pro-regular-svg-icons/faCircleQ';
import { faCircleQuarter as farCircleQuarter } from '@fortawesome/pro-regular-svg-icons/faCircleQuarter';
import { faCircleQuarters as farCircleQuarters } from '@fortawesome/pro-regular-svg-icons/faCircleQuarters';
import { faCircleQuarterStroke as farCircleQuarterStroke } from '@fortawesome/pro-regular-svg-icons/faCircleQuarterStroke';
import { faCircleQuestion as farCircleQuestion } from '@fortawesome/pro-regular-svg-icons/faCircleQuestion';
import { faCircleR as farCircleR } from '@fortawesome/pro-regular-svg-icons/faCircleR';
import { faCircleRadiation as farCircleRadiation } from '@fortawesome/pro-regular-svg-icons/faCircleRadiation';
import { faCircleRight as farCircleRight } from '@fortawesome/pro-regular-svg-icons/faCircleRight';
import { faCircleS as farCircleS } from '@fortawesome/pro-regular-svg-icons/faCircleS';
import { faCircleSmall as farCircleSmall } from '@fortawesome/pro-regular-svg-icons/faCircleSmall';
import { faCircleSort as farCircleSort } from '@fortawesome/pro-regular-svg-icons/faCircleSort';
import { faCircleSortDown as farCircleSortDown } from '@fortawesome/pro-regular-svg-icons/faCircleSortDown';
import { faCircleSortUp as farCircleSortUp } from '@fortawesome/pro-regular-svg-icons/faCircleSortUp';
import { faCirclesOverlap as farCirclesOverlap } from '@fortawesome/pro-regular-svg-icons/faCirclesOverlap';
import { faCircleStar as farCircleStar } from '@fortawesome/pro-regular-svg-icons/faCircleStar';
import { faCircleSterling as farCircleSterling } from '@fortawesome/pro-regular-svg-icons/faCircleSterling';
import { faCircleStop as farCircleStop } from '@fortawesome/pro-regular-svg-icons/faCircleStop';
import { faCircleT as farCircleT } from '@fortawesome/pro-regular-svg-icons/faCircleT';
import { faCircleThreeQuarters as farCircleThreeQuarters } from '@fortawesome/pro-regular-svg-icons/faCircleThreeQuarters';
import { faCircleThreeQuartersStroke as farCircleThreeQuartersStroke } from '@fortawesome/pro-regular-svg-icons/faCircleThreeQuartersStroke';
import { faCircleTrash as farCircleTrash } from '@fortawesome/pro-regular-svg-icons/faCircleTrash';
import { faCircleU as farCircleU } from '@fortawesome/pro-regular-svg-icons/faCircleU';
import { faCircleUp as farCircleUp } from '@fortawesome/pro-regular-svg-icons/faCircleUp';
import { faCircleUpLeft as farCircleUpLeft } from '@fortawesome/pro-regular-svg-icons/faCircleUpLeft';
import { faCircleUpRight as farCircleUpRight } from '@fortawesome/pro-regular-svg-icons/faCircleUpRight';
import { faCircleUser as farCircleUser } from '@fortawesome/pro-regular-svg-icons/faCircleUser';
import { faCircleV as farCircleV } from '@fortawesome/pro-regular-svg-icons/faCircleV';
import { faCircleVideo as farCircleVideo } from '@fortawesome/pro-regular-svg-icons/faCircleVideo';
import { faCircleW as farCircleW } from '@fortawesome/pro-regular-svg-icons/faCircleW';
import { faCircleWaveformLines as farCircleWaveformLines } from '@fortawesome/pro-regular-svg-icons/faCircleWaveformLines';
import { faCircleX as farCircleX } from '@fortawesome/pro-regular-svg-icons/faCircleX';
import { faCircleXmark as farCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faCircleY as farCircleY } from '@fortawesome/pro-regular-svg-icons/faCircleY';
import { faCircleYen as farCircleYen } from '@fortawesome/pro-regular-svg-icons/faCircleYen';
import { faCircleZ as farCircleZ } from '@fortawesome/pro-regular-svg-icons/faCircleZ';
import { faCitrus as farCitrus } from '@fortawesome/pro-regular-svg-icons/faCitrus';
import { faCitrusSlice as farCitrusSlice } from '@fortawesome/pro-regular-svg-icons/faCitrusSlice';
import { faCity as farCity } from '@fortawesome/pro-regular-svg-icons/faCity';
import { faClapperboard as farClapperboard } from '@fortawesome/pro-regular-svg-icons/faClapperboard';
import { faClapperboardPlay as farClapperboardPlay } from '@fortawesome/pro-regular-svg-icons/faClapperboardPlay';
import { faClarinet as farClarinet } from '@fortawesome/pro-regular-svg-icons/faClarinet';
import { faClawMarks as farClawMarks } from '@fortawesome/pro-regular-svg-icons/faClawMarks';
import { faClinicMedical as farClinicMedical } from '@fortawesome/pro-regular-svg-icons/faClinicMedical';
import { faClipboard as farClipboard } from '@fortawesome/pro-regular-svg-icons/faClipboard';
import { faClipboardCheck as farClipboardCheck } from '@fortawesome/pro-regular-svg-icons/faClipboardCheck';
import { faClipboardList as farClipboardList } from '@fortawesome/pro-regular-svg-icons/faClipboardList';
import { faClipboardListCheck as farClipboardListCheck } from '@fortawesome/pro-regular-svg-icons/faClipboardListCheck';
import { faClipboardMedical as farClipboardMedical } from '@fortawesome/pro-regular-svg-icons/faClipboardMedical';
import { faClipboardPrescription as farClipboardPrescription } from '@fortawesome/pro-regular-svg-icons/faClipboardPrescription';
import { faClipboardQuestion as farClipboardQuestion } from '@fortawesome/pro-regular-svg-icons/faClipboardQuestion';
import { faClipboardUser as farClipboardUser } from '@fortawesome/pro-regular-svg-icons/faClipboardUser';
import { faClock as farClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faClockDesk as farClockDesk } from '@fortawesome/pro-regular-svg-icons/faClockDesk';
import { faClockEight as farClockEight } from '@fortawesome/pro-regular-svg-icons/faClockEight';
import { faClockEightThirty as farClockEightThirty } from '@fortawesome/pro-regular-svg-icons/faClockEightThirty';
import { faClockEleven as farClockEleven } from '@fortawesome/pro-regular-svg-icons/faClockEleven';
import { faClockElevenThirty as farClockElevenThirty } from '@fortawesome/pro-regular-svg-icons/faClockElevenThirty';
import { faClockFive as farClockFive } from '@fortawesome/pro-regular-svg-icons/faClockFive';
import { faClockFiveThirty as farClockFiveThirty } from '@fortawesome/pro-regular-svg-icons/faClockFiveThirty';
import { faClockFour as farClockFour } from '@fortawesome/pro-regular-svg-icons/faClockFour';
import { faClockFourThirty as farClockFourThirty } from '@fortawesome/pro-regular-svg-icons/faClockFourThirty';
import { faClockNine as farClockNine } from '@fortawesome/pro-regular-svg-icons/faClockNine';
import { faClockNineThirty as farClockNineThirty } from '@fortawesome/pro-regular-svg-icons/faClockNineThirty';
import { faClockOne as farClockOne } from '@fortawesome/pro-regular-svg-icons/faClockOne';
import { faClockOneThirty as farClockOneThirty } from '@fortawesome/pro-regular-svg-icons/faClockOneThirty';
import { faClockRotateLeft as farClockRotateLeft } from '@fortawesome/pro-regular-svg-icons/faClockRotateLeft';
import { faClockSeven as farClockSeven } from '@fortawesome/pro-regular-svg-icons/faClockSeven';
import { faClockSevenThirty as farClockSevenThirty } from '@fortawesome/pro-regular-svg-icons/faClockSevenThirty';
import { faClockSix as farClockSix } from '@fortawesome/pro-regular-svg-icons/faClockSix';
import { faClockSixThirty as farClockSixThirty } from '@fortawesome/pro-regular-svg-icons/faClockSixThirty';
import { faClockTen as farClockTen } from '@fortawesome/pro-regular-svg-icons/faClockTen';
import { faClockTenThirty as farClockTenThirty } from '@fortawesome/pro-regular-svg-icons/faClockTenThirty';
import { faClockThree as farClockThree } from '@fortawesome/pro-regular-svg-icons/faClockThree';
import { faClockThreeThirty as farClockThreeThirty } from '@fortawesome/pro-regular-svg-icons/faClockThreeThirty';
import { faClockTwelve as farClockTwelve } from '@fortawesome/pro-regular-svg-icons/faClockTwelve';
import { faClockTwelveThirty as farClockTwelveThirty } from '@fortawesome/pro-regular-svg-icons/faClockTwelveThirty';
import { faClockTwo as farClockTwo } from '@fortawesome/pro-regular-svg-icons/faClockTwo';
import { faClockTwoThirty as farClockTwoThirty } from '@fortawesome/pro-regular-svg-icons/faClockTwoThirty';
import { faClone as farClone } from '@fortawesome/pro-regular-svg-icons/faClone';
import { faClose as farClose } from '@fortawesome/pro-regular-svg-icons/faClose';
import { faClosedCaptioning as farClosedCaptioning } from '@fortawesome/pro-regular-svg-icons/faClosedCaptioning';
import { faClosedCaptioningSlash as farClosedCaptioningSlash } from '@fortawesome/pro-regular-svg-icons/faClosedCaptioningSlash';
import { faClothesHanger as farClothesHanger } from '@fortawesome/pro-regular-svg-icons/faClothesHanger';
import { faCloud as farCloud } from '@fortawesome/pro-regular-svg-icons/faCloud';
import { faCloudArrowDown as farCloudArrowDown } from '@fortawesome/pro-regular-svg-icons/faCloudArrowDown';
import { faCloudArrowUp as farCloudArrowUp } from '@fortawesome/pro-regular-svg-icons/faCloudArrowUp';
import { faCloudBinary as farCloudBinary } from '@fortawesome/pro-regular-svg-icons/faCloudBinary';
import { faCloudBolt as farCloudBolt } from '@fortawesome/pro-regular-svg-icons/faCloudBolt';
import { faCloudBoltMoon as farCloudBoltMoon } from '@fortawesome/pro-regular-svg-icons/faCloudBoltMoon';
import { faCloudBoltSun as farCloudBoltSun } from '@fortawesome/pro-regular-svg-icons/faCloudBoltSun';
import { faCloudCheck as farCloudCheck } from '@fortawesome/pro-regular-svg-icons/faCloudCheck';
import { faCloudDownload as farCloudDownload } from '@fortawesome/pro-regular-svg-icons/faCloudDownload';
import { faCloudDownloadAlt as farCloudDownloadAlt } from '@fortawesome/pro-regular-svg-icons/faCloudDownloadAlt';
import { faCloudDrizzle as farCloudDrizzle } from '@fortawesome/pro-regular-svg-icons/faCloudDrizzle';
import { faCloudExclamation as farCloudExclamation } from '@fortawesome/pro-regular-svg-icons/faCloudExclamation';
import { faCloudflare as farCloudflare } from '@fortawesome/free-brands-svg-icons/faCloudflare';
import { faCloudFog as farCloudFog } from '@fortawesome/pro-regular-svg-icons/faCloudFog';
import { faCloudHail as farCloudHail } from '@fortawesome/pro-regular-svg-icons/faCloudHail';
import { faCloudHailMixed as farCloudHailMixed } from '@fortawesome/pro-regular-svg-icons/faCloudHailMixed';
import { faCloudMeatball as farCloudMeatball } from '@fortawesome/pro-regular-svg-icons/faCloudMeatball';
import { faCloudMinus as farCloudMinus } from '@fortawesome/pro-regular-svg-icons/faCloudMinus';
import { faCloudMoon as farCloudMoon } from '@fortawesome/pro-regular-svg-icons/faCloudMoon';
import { faCloudMoonRain as farCloudMoonRain } from '@fortawesome/pro-regular-svg-icons/faCloudMoonRain';
import { faCloudMusic as farCloudMusic } from '@fortawesome/pro-regular-svg-icons/faCloudMusic';
import { faCloudPlus as farCloudPlus } from '@fortawesome/pro-regular-svg-icons/faCloudPlus';
import { faCloudQuestion as farCloudQuestion } from '@fortawesome/pro-regular-svg-icons/faCloudQuestion';
import { faCloudRain as farCloudRain } from '@fortawesome/pro-regular-svg-icons/faCloudRain';
import { faCloudRainbow as farCloudRainbow } from '@fortawesome/pro-regular-svg-icons/faCloudRainbow';
import { faClouds as farClouds } from '@fortawesome/pro-regular-svg-icons/faClouds';
import { faCloudscale as farCloudscale } from '@fortawesome/free-brands-svg-icons/faCloudscale';
import { faCloudShowers as farCloudShowers } from '@fortawesome/pro-regular-svg-icons/faCloudShowers';
import { faCloudShowersHeavy as farCloudShowersHeavy } from '@fortawesome/pro-regular-svg-icons/faCloudShowersHeavy';
import { faCloudShowersWater as farCloudShowersWater } from '@fortawesome/pro-regular-svg-icons/faCloudShowersWater';
import { faCloudSlash as farCloudSlash } from '@fortawesome/pro-regular-svg-icons/faCloudSlash';
import { faCloudSleet as farCloudSleet } from '@fortawesome/pro-regular-svg-icons/faCloudSleet';
import { faCloudsmith as farCloudsmith } from '@fortawesome/free-brands-svg-icons/faCloudsmith';
import { faCloudsMoon as farCloudsMoon } from '@fortawesome/pro-regular-svg-icons/faCloudsMoon';
import { faCloudSnow as farCloudSnow } from '@fortawesome/pro-regular-svg-icons/faCloudSnow';
import { faCloudsSun as farCloudsSun } from '@fortawesome/pro-regular-svg-icons/faCloudsSun';
import { faCloudSun as farCloudSun } from '@fortawesome/pro-regular-svg-icons/faCloudSun';
import { faCloudSunRain as farCloudSunRain } from '@fortawesome/pro-regular-svg-icons/faCloudSunRain';
import { faCloudUpload as farCloudUpload } from '@fortawesome/pro-regular-svg-icons/faCloudUpload';
import { faCloudUploadAlt as farCloudUploadAlt } from '@fortawesome/pro-regular-svg-icons/faCloudUploadAlt';
import { faCloudversify as farCloudversify } from '@fortawesome/free-brands-svg-icons/faCloudversify';
import { faCloudWord as farCloudWord } from '@fortawesome/pro-regular-svg-icons/faCloudWord';
import { faCloudXmark as farCloudXmark } from '@fortawesome/pro-regular-svg-icons/faCloudXmark';
import { faClover as farClover } from '@fortawesome/pro-regular-svg-icons/faClover';
import { faClub as farClub } from '@fortawesome/pro-regular-svg-icons/faClub';
import { faCmplid as farCmplid } from '@fortawesome/free-brands-svg-icons/faCmplid';
import { faCny as farCny } from '@fortawesome/pro-regular-svg-icons/faCny';
import { faCocktail as farCocktail } from '@fortawesome/pro-regular-svg-icons/faCocktail';
import { faCoconut as farCoconut } from '@fortawesome/pro-regular-svg-icons/faCoconut';
import { faCode as farCode } from '@fortawesome/pro-regular-svg-icons/faCode';
import { faCodeBranch as farCodeBranch } from '@fortawesome/pro-regular-svg-icons/faCodeBranch';
import { faCodeCommit as farCodeCommit } from '@fortawesome/pro-regular-svg-icons/faCodeCommit';
import { faCodeCompare as farCodeCompare } from '@fortawesome/pro-regular-svg-icons/faCodeCompare';
import { faCodeFork as farCodeFork } from '@fortawesome/pro-regular-svg-icons/faCodeFork';
import { faCodeMerge as farCodeMerge } from '@fortawesome/pro-regular-svg-icons/faCodeMerge';
import { faCodepen as farCodepen } from '@fortawesome/free-brands-svg-icons/faCodepen';
import { faCodePullRequest as farCodePullRequest } from '@fortawesome/pro-regular-svg-icons/faCodePullRequest';
import { faCodePullRequestClosed as farCodePullRequestClosed } from '@fortawesome/pro-regular-svg-icons/faCodePullRequestClosed';
import { faCodePullRequestDraft as farCodePullRequestDraft } from '@fortawesome/pro-regular-svg-icons/faCodePullRequestDraft';
import { faCodeSimple as farCodeSimple } from '@fortawesome/pro-regular-svg-icons/faCodeSimple';
import { faCodiepie as farCodiepie } from '@fortawesome/free-brands-svg-icons/faCodiepie';
import { faCoffee as farCoffee } from '@fortawesome/pro-regular-svg-icons/faCoffee';
import { faCoffeeBean as farCoffeeBean } from '@fortawesome/pro-regular-svg-icons/faCoffeeBean';
import { faCoffeeBeans as farCoffeeBeans } from '@fortawesome/pro-regular-svg-icons/faCoffeeBeans';
import { faCoffeePot as farCoffeePot } from '@fortawesome/pro-regular-svg-icons/faCoffeePot';
import { faCoffeeTogo as farCoffeeTogo } from '@fortawesome/pro-regular-svg-icons/faCoffeeTogo';
import { faCoffin as farCoffin } from '@fortawesome/pro-regular-svg-icons/faCoffin';
import { faCoffinCross as farCoffinCross } from '@fortawesome/pro-regular-svg-icons/faCoffinCross';
import { faCog as farCog } from '@fortawesome/pro-regular-svg-icons/faCog';
import { faCogs as farCogs } from '@fortawesome/pro-regular-svg-icons/faCogs';
import { faCoin as farCoin } from '@fortawesome/pro-regular-svg-icons/faCoin';
import { faCoinBlank as farCoinBlank } from '@fortawesome/pro-regular-svg-icons/faCoinBlank';
import { faCoinFront as farCoinFront } from '@fortawesome/pro-regular-svg-icons/faCoinFront';
import { faCoins as farCoins } from '@fortawesome/pro-regular-svg-icons/faCoins';
import { faCoinVertical as farCoinVertical } from '@fortawesome/pro-regular-svg-icons/faCoinVertical';
import { faColon as farColon } from '@fortawesome/pro-regular-svg-icons/faColon';
import { faColonSign as farColonSign } from '@fortawesome/pro-regular-svg-icons/faColonSign';
import { faColumns as farColumns } from '@fortawesome/pro-regular-svg-icons/faColumns';
import { faColumns3 as farColumns3 } from '@fortawesome/pro-regular-svg-icons/faColumns3';
import { faComet as farComet } from '@fortawesome/pro-regular-svg-icons/faComet';
import { faComma as farComma } from '@fortawesome/pro-regular-svg-icons/faComma';
import { faCommand as farCommand } from '@fortawesome/pro-regular-svg-icons/faCommand';
import { faComment as farComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faCommentAlt as farCommentAlt } from '@fortawesome/pro-regular-svg-icons/faCommentAlt';
import { faCommentAltArrowDown as farCommentAltArrowDown } from '@fortawesome/pro-regular-svg-icons/faCommentAltArrowDown';
import { faCommentAltArrowUp as farCommentAltArrowUp } from '@fortawesome/pro-regular-svg-icons/faCommentAltArrowUp';
import { faCommentAltCaptions as farCommentAltCaptions } from '@fortawesome/pro-regular-svg-icons/faCommentAltCaptions';
import { faCommentAltCheck as farCommentAltCheck } from '@fortawesome/pro-regular-svg-icons/faCommentAltCheck';
import { faCommentAltDollar as farCommentAltDollar } from '@fortawesome/pro-regular-svg-icons/faCommentAltDollar';
import { faCommentAltDots as farCommentAltDots } from '@fortawesome/pro-regular-svg-icons/faCommentAltDots';
import { faCommentAltEdit as farCommentAltEdit } from '@fortawesome/pro-regular-svg-icons/faCommentAltEdit';
import { faCommentAltExclamation as farCommentAltExclamation } from '@fortawesome/pro-regular-svg-icons/faCommentAltExclamation';
import { faCommentAltImage as farCommentAltImage } from '@fortawesome/pro-regular-svg-icons/faCommentAltImage';
import { faCommentAltLines as farCommentAltLines } from '@fortawesome/pro-regular-svg-icons/faCommentAltLines';
import { faCommentAltMedical as farCommentAltMedical } from '@fortawesome/pro-regular-svg-icons/faCommentAltMedical';
import { faCommentAltMinus as farCommentAltMinus } from '@fortawesome/pro-regular-svg-icons/faCommentAltMinus';
import { faCommentAltMusic as farCommentAltMusic } from '@fortawesome/pro-regular-svg-icons/faCommentAltMusic';
import { faCommentAltPlus as farCommentAltPlus } from '@fortawesome/pro-regular-svg-icons/faCommentAltPlus';
import { faCommentAltQuote as farCommentAltQuote } from '@fortawesome/pro-regular-svg-icons/faCommentAltQuote';
import { faCommentAltSlash as farCommentAltSlash } from '@fortawesome/pro-regular-svg-icons/faCommentAltSlash';
import { faCommentAltSmile as farCommentAltSmile } from '@fortawesome/pro-regular-svg-icons/faCommentAltSmile';
import { faCommentAltText as farCommentAltText } from '@fortawesome/pro-regular-svg-icons/faCommentAltText';
import { faCommentAltTimes as farCommentAltTimes } from '@fortawesome/pro-regular-svg-icons/faCommentAltTimes';
import { faCommentArrowDown as farCommentArrowDown } from '@fortawesome/pro-regular-svg-icons/faCommentArrowDown';
import { faCommentArrowUp as farCommentArrowUp } from '@fortawesome/pro-regular-svg-icons/faCommentArrowUp';
import { faCommentArrowUpRight as farCommentArrowUpRight } from '@fortawesome/pro-regular-svg-icons/faCommentArrowUpRight';
import { faCommentCaptions as farCommentCaptions } from '@fortawesome/pro-regular-svg-icons/faCommentCaptions';
import { faCommentCheck as farCommentCheck } from '@fortawesome/pro-regular-svg-icons/faCommentCheck';
import { faCommentCode as farCommentCode } from '@fortawesome/pro-regular-svg-icons/faCommentCode';
import { faCommentDollar as farCommentDollar } from '@fortawesome/pro-regular-svg-icons/faCommentDollar';
import { faCommentDots as farCommentDots } from '@fortawesome/pro-regular-svg-icons/faCommentDots';
import { faCommentEdit as farCommentEdit } from '@fortawesome/pro-regular-svg-icons/faCommentEdit';
import { faCommentExclamation as farCommentExclamation } from '@fortawesome/pro-regular-svg-icons/faCommentExclamation';
import { faCommentHeart as farCommentHeart } from '@fortawesome/pro-regular-svg-icons/faCommentHeart';
import { faCommentImage as farCommentImage } from '@fortawesome/pro-regular-svg-icons/faCommentImage';
import { faCommenting as farCommenting } from '@fortawesome/pro-regular-svg-icons/faCommenting';
import { faCommentLines as farCommentLines } from '@fortawesome/pro-regular-svg-icons/faCommentLines';
import { faCommentMedical as farCommentMedical } from '@fortawesome/pro-regular-svg-icons/faCommentMedical';
import { faCommentMiddle as farCommentMiddle } from '@fortawesome/pro-regular-svg-icons/faCommentMiddle';
import { faCommentMiddleAlt as farCommentMiddleAlt } from '@fortawesome/pro-regular-svg-icons/faCommentMiddleAlt';
import { faCommentMiddleTop as farCommentMiddleTop } from '@fortawesome/pro-regular-svg-icons/faCommentMiddleTop';
import { faCommentMiddleTopAlt as farCommentMiddleTopAlt } from '@fortawesome/pro-regular-svg-icons/faCommentMiddleTopAlt';
import { faCommentMinus as farCommentMinus } from '@fortawesome/pro-regular-svg-icons/faCommentMinus';
import { faCommentMusic as farCommentMusic } from '@fortawesome/pro-regular-svg-icons/faCommentMusic';
import { faCommentPen as farCommentPen } from '@fortawesome/pro-regular-svg-icons/faCommentPen';
import { faCommentPlus as farCommentPlus } from '@fortawesome/pro-regular-svg-icons/faCommentPlus';
import { faCommentQuestion as farCommentQuestion } from '@fortawesome/pro-regular-svg-icons/faCommentQuestion';
import { faCommentQuote as farCommentQuote } from '@fortawesome/pro-regular-svg-icons/faCommentQuote';
import { faComments as farComments } from '@fortawesome/pro-regular-svg-icons/faComments';
import { faCommentsAlt as farCommentsAlt } from '@fortawesome/pro-regular-svg-icons/faCommentsAlt';
import { faCommentsAltDollar as farCommentsAltDollar } from '@fortawesome/pro-regular-svg-icons/faCommentsAltDollar';
import { faCommentsDollar as farCommentsDollar } from '@fortawesome/pro-regular-svg-icons/faCommentsDollar';
import { faCommentSlash as farCommentSlash } from '@fortawesome/pro-regular-svg-icons/faCommentSlash';
import { faCommentSmile as farCommentSmile } from '@fortawesome/pro-regular-svg-icons/faCommentSmile';
import { faCommentSms as farCommentSms } from '@fortawesome/pro-regular-svg-icons/faCommentSms';
import { faCommentsQuestion as farCommentsQuestion } from '@fortawesome/pro-regular-svg-icons/faCommentsQuestion';
import { faCommentsQuestionCheck as farCommentsQuestionCheck } from '@fortawesome/pro-regular-svg-icons/faCommentsQuestionCheck';
import { faCommentText as farCommentText } from '@fortawesome/pro-regular-svg-icons/faCommentText';
import { faCommentTimes as farCommentTimes } from '@fortawesome/pro-regular-svg-icons/faCommentTimes';
import { faCommentXmark as farCommentXmark } from '@fortawesome/pro-regular-svg-icons/faCommentXmark';
import { faCompactDisc as farCompactDisc } from '@fortawesome/pro-regular-svg-icons/faCompactDisc';
import { faCompass as farCompass } from '@fortawesome/pro-regular-svg-icons/faCompass';
import { faCompassDrafting as farCompassDrafting } from '@fortawesome/pro-regular-svg-icons/faCompassDrafting';
import { faCompassSlash as farCompassSlash } from '@fortawesome/pro-regular-svg-icons/faCompassSlash';
import { faCompress as farCompress } from '@fortawesome/pro-regular-svg-icons/faCompress';
import { faCompressAlt as farCompressAlt } from '@fortawesome/pro-regular-svg-icons/faCompressAlt';
import { faCompressArrows as farCompressArrows } from '@fortawesome/pro-regular-svg-icons/faCompressArrows';
import { faCompressArrowsAlt as farCompressArrowsAlt } from '@fortawesome/pro-regular-svg-icons/faCompressArrowsAlt';
import { faCompressWide as farCompressWide } from '@fortawesome/pro-regular-svg-icons/faCompressWide';
import { faComputer as farComputer } from '@fortawesome/pro-regular-svg-icons/faComputer';
import { faComputerClassic as farComputerClassic } from '@fortawesome/pro-regular-svg-icons/faComputerClassic';
import { faComputerMouse as farComputerMouse } from '@fortawesome/pro-regular-svg-icons/faComputerMouse';
import { faComputerMouseScrollwheel as farComputerMouseScrollwheel } from '@fortawesome/pro-regular-svg-icons/faComputerMouseScrollwheel';
import { faComputerSpeaker as farComputerSpeaker } from '@fortawesome/pro-regular-svg-icons/faComputerSpeaker';
import { faConciergeBell as farConciergeBell } from '@fortawesome/pro-regular-svg-icons/faConciergeBell';
import { faConfluence as farConfluence } from '@fortawesome/free-brands-svg-icons/faConfluence';
import { faConnectdevelop as farConnectdevelop } from '@fortawesome/free-brands-svg-icons/faConnectdevelop';
import { faConstruction as farConstruction } from '@fortawesome/pro-regular-svg-icons/faConstruction';
import { faContactBook as farContactBook } from '@fortawesome/pro-regular-svg-icons/faContactBook';
import { faContactCard as farContactCard } from '@fortawesome/pro-regular-svg-icons/faContactCard';
import { faContainerStorage as farContainerStorage } from '@fortawesome/pro-regular-svg-icons/faContainerStorage';
import { faContao as farContao } from '@fortawesome/free-brands-svg-icons/faContao';
import { faConveyorBelt as farConveyorBelt } from '@fortawesome/pro-regular-svg-icons/faConveyorBelt';
import { faConveyorBeltAlt as farConveyorBeltAlt } from '@fortawesome/pro-regular-svg-icons/faConveyorBeltAlt';
import { faConveyorBeltArm as farConveyorBeltArm } from '@fortawesome/pro-regular-svg-icons/faConveyorBeltArm';
import { faConveyorBeltBoxes as farConveyorBeltBoxes } from '@fortawesome/pro-regular-svg-icons/faConveyorBeltBoxes';
import { faConveyorBeltEmpty as farConveyorBeltEmpty } from '@fortawesome/pro-regular-svg-icons/faConveyorBeltEmpty';
import { faCookie as farCookie } from '@fortawesome/pro-regular-svg-icons/faCookie';
import { faCookieBite as farCookieBite } from '@fortawesome/pro-regular-svg-icons/faCookieBite';
import { faCopy as farCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faCopyright as farCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';
import { faCorn as farCorn } from '@fortawesome/pro-regular-svg-icons/faCorn';
import { faCorner as farCorner } from '@fortawesome/pro-regular-svg-icons/faCorner';
import { faCottonBureau as farCottonBureau } from '@fortawesome/free-brands-svg-icons/faCottonBureau';
import { faCouch as farCouch } from '@fortawesome/pro-regular-svg-icons/faCouch';
import { faCouchSmall as farCouchSmall } from '@fortawesome/pro-regular-svg-icons/faCouchSmall';
import { faCourtSport as farCourtSport } from '@fortawesome/pro-regular-svg-icons/faCourtSport';
import { faCow as farCow } from '@fortawesome/pro-regular-svg-icons/faCow';
import { faCowbell as farCowbell } from '@fortawesome/pro-regular-svg-icons/faCowbell';
import { faCowbellCirclePlus as farCowbellCirclePlus } from '@fortawesome/pro-regular-svg-icons/faCowbellCirclePlus';
import { faCowbellMore as farCowbellMore } from '@fortawesome/pro-regular-svg-icons/faCowbellMore';
import { faCpanel as farCpanel } from '@fortawesome/free-brands-svg-icons/faCpanel';
import { faCrab as farCrab } from '@fortawesome/pro-regular-svg-icons/faCrab';
import { faCrateApple as farCrateApple } from '@fortawesome/pro-regular-svg-icons/faCrateApple';
import { faCrateEmpty as farCrateEmpty } from '@fortawesome/pro-regular-svg-icons/faCrateEmpty';
import { faCreativeCommons as farCreativeCommons } from '@fortawesome/free-brands-svg-icons/faCreativeCommons';
import { faCreativeCommonsBy as farCreativeCommonsBy } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsBy';
import { faCreativeCommonsNc as farCreativeCommonsNc } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsNc';
import { faCreativeCommonsNcEu as farCreativeCommonsNcEu } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsNcEu';
import { faCreativeCommonsNcJp as farCreativeCommonsNcJp } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsNcJp';
import { faCreativeCommonsNd as farCreativeCommonsNd } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsNd';
import { faCreativeCommonsPd as farCreativeCommonsPd } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsPd';
import { faCreativeCommonsPdAlt as farCreativeCommonsPdAlt } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsPdAlt';
import { faCreativeCommonsRemix as farCreativeCommonsRemix } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsRemix';
import { faCreativeCommonsSa as farCreativeCommonsSa } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsSa';
import { faCreativeCommonsSampling as farCreativeCommonsSampling } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsSampling';
import { faCreativeCommonsSamplingPlus as farCreativeCommonsSamplingPlus } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsSamplingPlus';
import { faCreativeCommonsShare as farCreativeCommonsShare } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsShare';
import { faCreativeCommonsZero as farCreativeCommonsZero } from '@fortawesome/free-brands-svg-icons/faCreativeCommonsZero';
import { faCreditCard as farCreditCard } from '@fortawesome/pro-regular-svg-icons/faCreditCard';
import { faCreditCardAlt as farCreditCardAlt } from '@fortawesome/pro-regular-svg-icons/faCreditCardAlt';
import { faCreditCardBlank as farCreditCardBlank } from '@fortawesome/pro-regular-svg-icons/faCreditCardBlank';
import { faCreditCardFront as farCreditCardFront } from '@fortawesome/pro-regular-svg-icons/faCreditCardFront';
import { faCreemee as farCreemee } from '@fortawesome/pro-regular-svg-icons/faCreemee';
import { faCricket as farCricket } from '@fortawesome/pro-regular-svg-icons/faCricket';
import { faCricketBatBall as farCricketBatBall } from '@fortawesome/pro-regular-svg-icons/faCricketBatBall';
import { faCriticalRole as farCriticalRole } from '@fortawesome/free-brands-svg-icons/faCriticalRole';
import { faCroissant as farCroissant } from '@fortawesome/pro-regular-svg-icons/faCroissant';
import { faCrop as farCrop } from '@fortawesome/pro-regular-svg-icons/faCrop';
import { faCropAlt as farCropAlt } from '@fortawesome/pro-regular-svg-icons/faCropAlt';
import { faCropSimple as farCropSimple } from '@fortawesome/pro-regular-svg-icons/faCropSimple';
import { faCross as farCross } from '@fortawesome/pro-regular-svg-icons/faCross';
import { faCrosshairs as farCrosshairs } from '@fortawesome/pro-regular-svg-icons/faCrosshairs';
import { faCrosshairsSimple as farCrosshairsSimple } from '@fortawesome/pro-regular-svg-icons/faCrosshairsSimple';
import { faCrow as farCrow } from '@fortawesome/pro-regular-svg-icons/faCrow';
import { faCrown as farCrown } from '@fortawesome/pro-regular-svg-icons/faCrown';
import { faCrutch as farCrutch } from '@fortawesome/pro-regular-svg-icons/faCrutch';
import { faCrutches as farCrutches } from '@fortawesome/pro-regular-svg-icons/faCrutches';
import { faCruzeiroSign as farCruzeiroSign } from '@fortawesome/pro-regular-svg-icons/faCruzeiroSign';
import { faCrystalBall as farCrystalBall } from '@fortawesome/pro-regular-svg-icons/faCrystalBall';
import { faCss3 as farCss3 } from '@fortawesome/free-brands-svg-icons/faCss3';
import { faCss3Alt as farCss3Alt } from '@fortawesome/free-brands-svg-icons/faCss3Alt';
import { faCube as farCube } from '@fortawesome/pro-regular-svg-icons/faCube';
import { faCubes as farCubes } from '@fortawesome/pro-regular-svg-icons/faCubes';
import { faCubesStacked as farCubesStacked } from '@fortawesome/pro-regular-svg-icons/faCubesStacked';
import { faCucumber as farCucumber } from '@fortawesome/pro-regular-svg-icons/faCucumber';
import { faCupcake as farCupcake } from '@fortawesome/pro-regular-svg-icons/faCupcake';
import { faCupStraw as farCupStraw } from '@fortawesome/pro-regular-svg-icons/faCupStraw';
import { faCupStrawSwoosh as farCupStrawSwoosh } from '@fortawesome/pro-regular-svg-icons/faCupStrawSwoosh';
import { faCupTogo as farCupTogo } from '@fortawesome/pro-regular-svg-icons/faCupTogo';
import { faCurling as farCurling } from '@fortawesome/pro-regular-svg-icons/faCurling';
import { faCurlingStone as farCurlingStone } from '@fortawesome/pro-regular-svg-icons/faCurlingStone';
import { faCustard as farCustard } from '@fortawesome/pro-regular-svg-icons/faCustard';
import { faCut as farCut } from '@fortawesome/pro-regular-svg-icons/faCut';
import { faCutlery as farCutlery } from '@fortawesome/pro-regular-svg-icons/faCutlery';
import { faCuttlefish as farCuttlefish } from '@fortawesome/free-brands-svg-icons/faCuttlefish';
import { faD as farD } from '@fortawesome/pro-regular-svg-icons/faD';
import { faDagger as farDagger } from '@fortawesome/pro-regular-svg-icons/faDagger';
import { faDailymotion as farDailymotion } from '@fortawesome/free-brands-svg-icons/faDailymotion';
import { faDAndD as farDAndD } from '@fortawesome/free-brands-svg-icons/faDAndD';
import { faDAndDBeyond as farDAndDBeyond } from '@fortawesome/free-brands-svg-icons/faDAndDBeyond';
import { faDash as farDash } from '@fortawesome/pro-regular-svg-icons/faDash';
import { faDashboard as farDashboard } from '@fortawesome/pro-regular-svg-icons/faDashboard';
import { faDashcube as farDashcube } from '@fortawesome/free-brands-svg-icons/faDashcube';
import { faDatabase as farDatabase } from '@fortawesome/pro-regular-svg-icons/faDatabase';
import { faDeaf as farDeaf } from '@fortawesome/pro-regular-svg-icons/faDeaf';
import { faDeafness as farDeafness } from '@fortawesome/pro-regular-svg-icons/faDeafness';
import { faDebian as farDebian } from '@fortawesome/free-brands-svg-icons/faDebian';
import { faDebug as farDebug } from '@fortawesome/pro-regular-svg-icons/faDebug';
import { faDedent as farDedent } from '@fortawesome/pro-regular-svg-icons/faDedent';
import { faDeer as farDeer } from '@fortawesome/pro-regular-svg-icons/faDeer';
import { faDeerRudolph as farDeerRudolph } from '@fortawesome/pro-regular-svg-icons/faDeerRudolph';
import { faDeezer as farDeezer } from '@fortawesome/free-brands-svg-icons/faDeezer';
import { faDeleteLeft as farDeleteLeft } from '@fortawesome/pro-regular-svg-icons/faDeleteLeft';
import { faDeleteRight as farDeleteRight } from '@fortawesome/pro-regular-svg-icons/faDeleteRight';
import { faDelicious as farDelicious } from '@fortawesome/free-brands-svg-icons/faDelicious';
import { faDemocrat as farDemocrat } from '@fortawesome/pro-regular-svg-icons/faDemocrat';
import { faDeploydog as farDeploydog } from '@fortawesome/free-brands-svg-icons/faDeploydog';
import { faDeskpro as farDeskpro } from '@fortawesome/free-brands-svg-icons/faDeskpro';
import { faDesktop as farDesktop } from '@fortawesome/pro-regular-svg-icons/faDesktop';
import { faDesktopAlt as farDesktopAlt } from '@fortawesome/pro-regular-svg-icons/faDesktopAlt';
import { faDesktopArrowDown as farDesktopArrowDown } from '@fortawesome/pro-regular-svg-icons/faDesktopArrowDown';
import { faDesktopCode as farDesktopCode } from '@fortawesome/pro-regular-svg-icons/faDesktopCode';
import { faDesktopMedical as farDesktopMedical } from '@fortawesome/pro-regular-svg-icons/faDesktopMedical';
import { faDesktopSlash as farDesktopSlash } from '@fortawesome/pro-regular-svg-icons/faDesktopSlash';
import { faDev as farDev } from '@fortawesome/free-brands-svg-icons/faDev';
import { faDeviantart as farDeviantart } from '@fortawesome/free-brands-svg-icons/faDeviantart';
import { faDewpoint as farDewpoint } from '@fortawesome/pro-regular-svg-icons/faDewpoint';
import { faDharmachakra as farDharmachakra } from '@fortawesome/pro-regular-svg-icons/faDharmachakra';
import { faDhl as farDhl } from '@fortawesome/free-brands-svg-icons/faDhl';
import { faDiagnoses as farDiagnoses } from '@fortawesome/pro-regular-svg-icons/faDiagnoses';
import { faDiagramCells as farDiagramCells } from '@fortawesome/pro-regular-svg-icons/faDiagramCells';
import { faDiagramLeanCanvas as farDiagramLeanCanvas } from '@fortawesome/pro-regular-svg-icons/faDiagramLeanCanvas';
import { faDiagramNested as farDiagramNested } from '@fortawesome/pro-regular-svg-icons/faDiagramNested';
import { faDiagramNext as farDiagramNext } from '@fortawesome/pro-regular-svg-icons/faDiagramNext';
import { faDiagramPredecessor as farDiagramPredecessor } from '@fortawesome/pro-regular-svg-icons/faDiagramPredecessor';
import { faDiagramPrevious as farDiagramPrevious } from '@fortawesome/pro-regular-svg-icons/faDiagramPrevious';
import { faDiagramProject as farDiagramProject } from '@fortawesome/pro-regular-svg-icons/faDiagramProject';
import { faDiagramSankey as farDiagramSankey } from '@fortawesome/pro-regular-svg-icons/faDiagramSankey';
import { faDiagramSubtask as farDiagramSubtask } from '@fortawesome/pro-regular-svg-icons/faDiagramSubtask';
import { faDiagramSuccessor as farDiagramSuccessor } from '@fortawesome/pro-regular-svg-icons/faDiagramSuccessor';
import { faDiagramVenn as farDiagramVenn } from '@fortawesome/pro-regular-svg-icons/faDiagramVenn';
import { faDial as farDial } from '@fortawesome/pro-regular-svg-icons/faDial';
import { faDialHigh as farDialHigh } from '@fortawesome/pro-regular-svg-icons/faDialHigh';
import { faDialLow as farDialLow } from '@fortawesome/pro-regular-svg-icons/faDialLow';
import { faDialMax as farDialMax } from '@fortawesome/pro-regular-svg-icons/faDialMax';
import { faDialMed as farDialMed } from '@fortawesome/pro-regular-svg-icons/faDialMed';
import { faDialMedHigh as farDialMedHigh } from '@fortawesome/pro-regular-svg-icons/faDialMedHigh';
import { faDialMedLow as farDialMedLow } from '@fortawesome/pro-regular-svg-icons/faDialMedLow';
import { faDialMin as farDialMin } from '@fortawesome/pro-regular-svg-icons/faDialMin';
import { faDialOff as farDialOff } from '@fortawesome/pro-regular-svg-icons/faDialOff';
import { faDiamond as farDiamond } from '@fortawesome/pro-regular-svg-icons/faDiamond';
import { faDiamondExclamation as farDiamondExclamation } from '@fortawesome/pro-regular-svg-icons/faDiamondExclamation';
import { faDiamondHalf as farDiamondHalf } from '@fortawesome/pro-regular-svg-icons/faDiamondHalf';
import { faDiamondHalfStroke as farDiamondHalfStroke } from '@fortawesome/pro-regular-svg-icons/faDiamondHalfStroke';
import { faDiamondTurnRight as farDiamondTurnRight } from '@fortawesome/pro-regular-svg-icons/faDiamondTurnRight';
import { faDiaspora as farDiaspora } from '@fortawesome/free-brands-svg-icons/faDiaspora';
import { faDice as farDice } from '@fortawesome/pro-regular-svg-icons/faDice';
import { faDiceD10 as farDiceD10 } from '@fortawesome/pro-regular-svg-icons/faDiceD10';
import { faDiceD12 as farDiceD12 } from '@fortawesome/pro-regular-svg-icons/faDiceD12';
import { faDiceD20 as farDiceD20 } from '@fortawesome/pro-regular-svg-icons/faDiceD20';
import { faDiceD4 as farDiceD4 } from '@fortawesome/pro-regular-svg-icons/faDiceD4';
import { faDiceD6 as farDiceD6 } from '@fortawesome/pro-regular-svg-icons/faDiceD6';
import { faDiceD8 as farDiceD8 } from '@fortawesome/pro-regular-svg-icons/faDiceD8';
import { faDiceFive as farDiceFive } from '@fortawesome/pro-regular-svg-icons/faDiceFive';
import { faDiceFour as farDiceFour } from '@fortawesome/pro-regular-svg-icons/faDiceFour';
import { faDiceOne as farDiceOne } from '@fortawesome/pro-regular-svg-icons/faDiceOne';
import { faDiceSix as farDiceSix } from '@fortawesome/pro-regular-svg-icons/faDiceSix';
import { faDiceThree as farDiceThree } from '@fortawesome/pro-regular-svg-icons/faDiceThree';
import { faDiceTwo as farDiceTwo } from '@fortawesome/pro-regular-svg-icons/faDiceTwo';
import { faDigg as farDigg } from '@fortawesome/free-brands-svg-icons/faDigg';
import { faDigging as farDigging } from '@fortawesome/pro-regular-svg-icons/faDigging';
import { faDigitalOcean as farDigitalOcean } from '@fortawesome/free-brands-svg-icons/faDigitalOcean';
import { faDigitalTachograph as farDigitalTachograph } from '@fortawesome/pro-regular-svg-icons/faDigitalTachograph';
import { faDinosaur as farDinosaur } from '@fortawesome/pro-regular-svg-icons/faDinosaur';
import { faDiploma as farDiploma } from '@fortawesome/pro-regular-svg-icons/faDiploma';
import { faDirections as farDirections } from '@fortawesome/pro-regular-svg-icons/faDirections';
import { faDiscDrive as farDiscDrive } from '@fortawesome/pro-regular-svg-icons/faDiscDrive';
import { faDiscord as farDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord';
import { faDiscourse as farDiscourse } from '@fortawesome/free-brands-svg-icons/faDiscourse';
import { faDisease as farDisease } from '@fortawesome/pro-regular-svg-icons/faDisease';
import { faDisplay as farDisplay } from '@fortawesome/pro-regular-svg-icons/faDisplay';
import { faDisplayArrowDown as farDisplayArrowDown } from '@fortawesome/pro-regular-svg-icons/faDisplayArrowDown';
import { faDisplayChartUp as farDisplayChartUp } from '@fortawesome/pro-regular-svg-icons/faDisplayChartUp';
import { faDisplayChartUpCircleCurrency as farDisplayChartUpCircleCurrency } from '@fortawesome/pro-regular-svg-icons/faDisplayChartUpCircleCurrency';
import { faDisplayChartUpCircleDollar as farDisplayChartUpCircleDollar } from '@fortawesome/pro-regular-svg-icons/faDisplayChartUpCircleDollar';
import { faDisplayCode as farDisplayCode } from '@fortawesome/pro-regular-svg-icons/faDisplayCode';
import { faDisplayMedical as farDisplayMedical } from '@fortawesome/pro-regular-svg-icons/faDisplayMedical';
import { faDisplaySlash as farDisplaySlash } from '@fortawesome/pro-regular-svg-icons/faDisplaySlash';
import { faDistributeSpacingHorizontal as farDistributeSpacingHorizontal } from '@fortawesome/pro-regular-svg-icons/faDistributeSpacingHorizontal';
import { faDistributeSpacingVertical as farDistributeSpacingVertical } from '@fortawesome/pro-regular-svg-icons/faDistributeSpacingVertical';
import { faDitto as farDitto } from '@fortawesome/pro-regular-svg-icons/faDitto';
import { faDivide as farDivide } from '@fortawesome/pro-regular-svg-icons/faDivide';
import { faDizzy as farDizzy } from '@fortawesome/pro-regular-svg-icons/faDizzy';
import { faDna as farDna } from '@fortawesome/pro-regular-svg-icons/faDna';
import { faDochub as farDochub } from '@fortawesome/free-brands-svg-icons/faDochub';
import { faDocker as farDocker } from '@fortawesome/free-brands-svg-icons/faDocker';
import { faDog as farDog } from '@fortawesome/pro-regular-svg-icons/faDog';
import { faDogLeashed as farDogLeashed } from '@fortawesome/pro-regular-svg-icons/faDogLeashed';
import { faDollar as farDollar } from '@fortawesome/pro-regular-svg-icons/faDollar';
import { faDollarCircle as farDollarCircle } from '@fortawesome/pro-regular-svg-icons/faDollarCircle';
import { faDollarSign as farDollarSign } from '@fortawesome/pro-regular-svg-icons/faDollarSign';
import { faDollarSquare as farDollarSquare } from '@fortawesome/pro-regular-svg-icons/faDollarSquare';
import { faDolly as farDolly } from '@fortawesome/pro-regular-svg-icons/faDolly';
import { faDollyBox as farDollyBox } from '@fortawesome/pro-regular-svg-icons/faDollyBox';
import { faDollyEmpty as farDollyEmpty } from '@fortawesome/pro-regular-svg-icons/faDollyEmpty';
import { faDollyFlatbed as farDollyFlatbed } from '@fortawesome/pro-regular-svg-icons/faDollyFlatbed';
import { faDollyFlatbedAlt as farDollyFlatbedAlt } from '@fortawesome/pro-regular-svg-icons/faDollyFlatbedAlt';
import { faDollyFlatbedEmpty as farDollyFlatbedEmpty } from '@fortawesome/pro-regular-svg-icons/faDollyFlatbedEmpty';
import { faDolphin as farDolphin } from '@fortawesome/pro-regular-svg-icons/faDolphin';
import { faDonate as farDonate } from '@fortawesome/pro-regular-svg-icons/faDonate';
import { faDongSign as farDongSign } from '@fortawesome/pro-regular-svg-icons/faDongSign';
import { faDoNotEnter as farDoNotEnter } from '@fortawesome/pro-regular-svg-icons/faDoNotEnter';
import { faDonut as farDonut } from '@fortawesome/pro-regular-svg-icons/faDonut';
import { faDoorClosed as farDoorClosed } from '@fortawesome/pro-regular-svg-icons/faDoorClosed';
import { faDoorOpen as farDoorOpen } from '@fortawesome/pro-regular-svg-icons/faDoorOpen';
import { faDotCircle as farDotCircle } from '@fortawesome/pro-regular-svg-icons/faDotCircle';
import { faDoughnut as farDoughnut } from '@fortawesome/pro-regular-svg-icons/faDoughnut';
import { faDove as farDove } from '@fortawesome/pro-regular-svg-icons/faDove';
import { faDown as farDown } from '@fortawesome/pro-regular-svg-icons/faDown';
import { faDownFromDottedLine as farDownFromDottedLine } from '@fortawesome/pro-regular-svg-icons/faDownFromDottedLine';
import { faDownFromLine as farDownFromLine } from '@fortawesome/pro-regular-svg-icons/faDownFromLine';
import { faDownLeft as farDownLeft } from '@fortawesome/pro-regular-svg-icons/faDownLeft';
import { faDownLeftAndUpRightToCenter as farDownLeftAndUpRightToCenter } from '@fortawesome/pro-regular-svg-icons/faDownLeftAndUpRightToCenter';
import { faDownload as farDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faDownLong as farDownLong } from '@fortawesome/pro-regular-svg-icons/faDownLong';
import { faDownRight as farDownRight } from '@fortawesome/pro-regular-svg-icons/faDownRight';
import { faDownToBracket as farDownToBracket } from '@fortawesome/pro-regular-svg-icons/faDownToBracket';
import { faDownToDottedLine as farDownToDottedLine } from '@fortawesome/pro-regular-svg-icons/faDownToDottedLine';
import { faDownToLine as farDownToLine } from '@fortawesome/pro-regular-svg-icons/faDownToLine';
import { faDraft2digital as farDraft2digital } from '@fortawesome/free-brands-svg-icons/faDraft2digital';
import { faDraftingCompass as farDraftingCompass } from '@fortawesome/pro-regular-svg-icons/faDraftingCompass';
import { faDragon as farDragon } from '@fortawesome/pro-regular-svg-icons/faDragon';
import { faDrawCircle as farDrawCircle } from '@fortawesome/pro-regular-svg-icons/faDrawCircle';
import { faDrawPolygon as farDrawPolygon } from '@fortawesome/pro-regular-svg-icons/faDrawPolygon';
import { faDrawSquare as farDrawSquare } from '@fortawesome/pro-regular-svg-icons/faDrawSquare';
import { faDreidel as farDreidel } from '@fortawesome/pro-regular-svg-icons/faDreidel';
import { faDribbble as farDribbble } from '@fortawesome/free-brands-svg-icons/faDribbble';
import { faDribbbleSquare as farDribbbleSquare } from '@fortawesome/free-brands-svg-icons/faDribbbleSquare';
import { faDriversLicense as farDriversLicense } from '@fortawesome/pro-regular-svg-icons/faDriversLicense';
import { faDrone as farDrone } from '@fortawesome/pro-regular-svg-icons/faDrone';
import { faDroneAlt as farDroneAlt } from '@fortawesome/pro-regular-svg-icons/faDroneAlt';
import { faDroneFront as farDroneFront } from '@fortawesome/pro-regular-svg-icons/faDroneFront';
import { faDropbox as farDropbox } from '@fortawesome/free-brands-svg-icons/faDropbox';
import { faDroplet as farDroplet } from '@fortawesome/pro-regular-svg-icons/faDroplet';
import { faDropletDegree as farDropletDegree } from '@fortawesome/pro-regular-svg-icons/faDropletDegree';
import { faDropletPercent as farDropletPercent } from '@fortawesome/pro-regular-svg-icons/faDropletPercent';
import { faDropletSlash as farDropletSlash } from '@fortawesome/pro-regular-svg-icons/faDropletSlash';
import { faDrum as farDrum } from '@fortawesome/pro-regular-svg-icons/faDrum';
import { faDrumSteelpan as farDrumSteelpan } from '@fortawesome/pro-regular-svg-icons/faDrumSteelpan';
import { faDrumstick as farDrumstick } from '@fortawesome/pro-regular-svg-icons/faDrumstick';
import { faDrumstickBite as farDrumstickBite } from '@fortawesome/pro-regular-svg-icons/faDrumstickBite';
import { faDrupal as farDrupal } from '@fortawesome/free-brands-svg-icons/faDrupal';
import { faDryer as farDryer } from '@fortawesome/pro-regular-svg-icons/faDryer';
import { faDryerAlt as farDryerAlt } from '@fortawesome/pro-regular-svg-icons/faDryerAlt';
import { faDryerHeat as farDryerHeat } from '@fortawesome/pro-regular-svg-icons/faDryerHeat';
import { faDuck as farDuck } from '@fortawesome/pro-regular-svg-icons/faDuck';
import { faDumbbell as farDumbbell } from '@fortawesome/pro-regular-svg-icons/faDumbbell';
import { faDumpster as farDumpster } from '@fortawesome/pro-regular-svg-icons/faDumpster';
import { faDumpsterFire as farDumpsterFire } from '@fortawesome/pro-regular-svg-icons/faDumpsterFire';
import { faDungeon as farDungeon } from '@fortawesome/pro-regular-svg-icons/faDungeon';
import { faDyalog as farDyalog } from '@fortawesome/free-brands-svg-icons/faDyalog';
import { faE as farE } from '@fortawesome/pro-regular-svg-icons/faE';
import { faEar as farEar } from '@fortawesome/pro-regular-svg-icons/faEar';
import { faEarDeaf as farEarDeaf } from '@fortawesome/pro-regular-svg-icons/faEarDeaf';
import { faEarListen as farEarListen } from '@fortawesome/pro-regular-svg-icons/faEarListen';
import { faEarlybirds as farEarlybirds } from '@fortawesome/free-brands-svg-icons/faEarlybirds';
import { faEarMuffs as farEarMuffs } from '@fortawesome/pro-regular-svg-icons/faEarMuffs';
import { faEarth as farEarth } from '@fortawesome/pro-regular-svg-icons/faEarth';
import { faEarthAfrica as farEarthAfrica } from '@fortawesome/pro-regular-svg-icons/faEarthAfrica';
import { faEarthAmerica as farEarthAmerica } from '@fortawesome/pro-regular-svg-icons/faEarthAmerica';
import { faEarthAmericas as farEarthAmericas } from '@fortawesome/pro-regular-svg-icons/faEarthAmericas';
import { faEarthAsia as farEarthAsia } from '@fortawesome/pro-regular-svg-icons/faEarthAsia';
import { faEarthEurope as farEarthEurope } from '@fortawesome/pro-regular-svg-icons/faEarthEurope';
import { faEarthOceania as farEarthOceania } from '@fortawesome/pro-regular-svg-icons/faEarthOceania';
import { faEbay as farEbay } from '@fortawesome/free-brands-svg-icons/faEbay';
import { faEclipse as farEclipse } from '@fortawesome/pro-regular-svg-icons/faEclipse';
import { faEclipseAlt as farEclipseAlt } from '@fortawesome/pro-regular-svg-icons/faEclipseAlt';
import { faEdge as farEdge } from '@fortawesome/free-brands-svg-icons/faEdge';
import { faEdgeLegacy as farEdgeLegacy } from '@fortawesome/free-brands-svg-icons/faEdgeLegacy';
import { faEdit as farEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { faEgg as farEgg } from '@fortawesome/pro-regular-svg-icons/faEgg';
import { faEggFried as farEggFried } from '@fortawesome/pro-regular-svg-icons/faEggFried';
import { faEggplant as farEggplant } from '@fortawesome/pro-regular-svg-icons/faEggplant';
import { faEject as farEject } from '@fortawesome/pro-regular-svg-icons/faEject';
import { faElementor as farElementor } from '@fortawesome/free-brands-svg-icons/faElementor';
import { faElephant as farElephant } from '@fortawesome/pro-regular-svg-icons/faElephant';
import { faElevator as farElevator } from '@fortawesome/pro-regular-svg-icons/faElevator';
import { faEllipsis as farEllipsis } from '@fortawesome/pro-regular-svg-icons/faEllipsis';
import { faEllipsisH as farEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';
import { faEllipsisHAlt as farEllipsisHAlt } from '@fortawesome/pro-regular-svg-icons/faEllipsisHAlt';
import { faEllipsisStroke as farEllipsisStroke } from '@fortawesome/pro-regular-svg-icons/faEllipsisStroke';
import { faEllipsisStrokeVertical as farEllipsisStrokeVertical } from '@fortawesome/pro-regular-svg-icons/faEllipsisStrokeVertical';
import { faEllipsisV as farEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { faEllipsisVAlt as farEllipsisVAlt } from '@fortawesome/pro-regular-svg-icons/faEllipsisVAlt';
import { faEllipsisVertical as farEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faEllipsisVertical';
import { faEllo as farEllo } from '@fortawesome/free-brands-svg-icons/faEllo';
import { faEmber as farEmber } from '@fortawesome/free-brands-svg-icons/faEmber';
import { faEmpire as farEmpire } from '@fortawesome/free-brands-svg-icons/faEmpire';
import { faEmptySet as farEmptySet } from '@fortawesome/pro-regular-svg-icons/faEmptySet';
import { faEngine as farEngine } from '@fortawesome/pro-regular-svg-icons/faEngine';
import { faEngineExclamation as farEngineExclamation } from '@fortawesome/pro-regular-svg-icons/faEngineExclamation';
import { faEngineWarning as farEngineWarning } from '@fortawesome/pro-regular-svg-icons/faEngineWarning';
import { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faEnvelopeBadge as farEnvelopeBadge } from '@fortawesome/pro-regular-svg-icons/faEnvelopeBadge';
import { faEnvelopeCircle as farEnvelopeCircle } from '@fortawesome/pro-regular-svg-icons/faEnvelopeCircle';
import { faEnvelopeCircleCheck as farEnvelopeCircleCheck } from '@fortawesome/pro-regular-svg-icons/faEnvelopeCircleCheck';
import { faEnvelopeDot as farEnvelopeDot } from '@fortawesome/pro-regular-svg-icons/faEnvelopeDot';
import { faEnvelopeOpen as farEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpen';
import { faEnvelopeOpenDollar as farEnvelopeOpenDollar } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpenDollar';
import { faEnvelopeOpenText as farEnvelopeOpenText } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpenText';
import { faEnvelopes as farEnvelopes } from '@fortawesome/pro-regular-svg-icons/faEnvelopes';
import { faEnvelopesBulk as farEnvelopesBulk } from '@fortawesome/pro-regular-svg-icons/faEnvelopesBulk';
import { faEnvelopeSquare as farEnvelopeSquare } from '@fortawesome/pro-regular-svg-icons/faEnvelopeSquare';
import { faEnvira as farEnvira } from '@fortawesome/free-brands-svg-icons/faEnvira';
import { faEquals as farEquals } from '@fortawesome/pro-regular-svg-icons/faEquals';
import { faEraser as farEraser } from '@fortawesome/pro-regular-svg-icons/faEraser';
import { faErlang as farErlang } from '@fortawesome/free-brands-svg-icons/faErlang';
import { faEscalator as farEscalator } from '@fortawesome/pro-regular-svg-icons/faEscalator';
import { faEthereum as farEthereum } from '@fortawesome/free-brands-svg-icons/faEthereum';
import { faEthernet as farEthernet } from '@fortawesome/pro-regular-svg-icons/faEthernet';
import { faEtsy as farEtsy } from '@fortawesome/free-brands-svg-icons/faEtsy';
import { faEur as farEur } from '@fortawesome/pro-regular-svg-icons/faEur';
import { faEuro as farEuro } from '@fortawesome/pro-regular-svg-icons/faEuro';
import { faEuroSign as farEuroSign } from '@fortawesome/pro-regular-svg-icons/faEuroSign';
import { faEvernote as farEvernote } from '@fortawesome/free-brands-svg-icons/faEvernote';
import { faExcavator as farExcavator } from '@fortawesome/pro-regular-svg-icons/faExcavator';
import { faExchange as farExchange } from '@fortawesome/pro-regular-svg-icons/faExchange';
import { faExchangeAlt as farExchangeAlt } from '@fortawesome/pro-regular-svg-icons/faExchangeAlt';
import { faExclamation as farExclamation } from '@fortawesome/pro-regular-svg-icons/faExclamation';
import { faExclamationCircle as farExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faExclamationSquare as farExclamationSquare } from '@fortawesome/pro-regular-svg-icons/faExclamationSquare';
import { faExclamationTriangle as farExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import { faExpand as farExpand } from '@fortawesome/pro-regular-svg-icons/faExpand';
import { faExpandAlt as farExpandAlt } from '@fortawesome/pro-regular-svg-icons/faExpandAlt';
import { faExpandArrows as farExpandArrows } from '@fortawesome/pro-regular-svg-icons/faExpandArrows';
import { faExpandArrowsAlt as farExpandArrowsAlt } from '@fortawesome/pro-regular-svg-icons/faExpandArrowsAlt';
import { faExpandWide as farExpandWide } from '@fortawesome/pro-regular-svg-icons/faExpandWide';
import { faExpeditedssl as farExpeditedssl } from '@fortawesome/free-brands-svg-icons/faExpeditedssl';
import { faExplodingHead as farExplodingHead } from '@fortawesome/pro-regular-svg-icons/faExplodingHead';
import { faExplosion as farExplosion } from '@fortawesome/pro-regular-svg-icons/faExplosion';
import { faExternalLink as farExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink';
import { faExternalLinkAlt as farExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkAlt';
import { faExternalLinkSquare as farExternalLinkSquare } from '@fortawesome/pro-regular-svg-icons/faExternalLinkSquare';
import { faExternalLinkSquareAlt as farExternalLinkSquareAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkSquareAlt';
import { faEye as farEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeDropper as farEyedropper } from '@fortawesome/pro-regular-svg-icons/faEyeDropper';
import { faEyeDropperEmpty as farEyeDropperEmpty } from '@fortawesome/pro-regular-svg-icons/faEyeDropperEmpty';
import { faEyeDropperFull as farEyeDropperFull } from '@fortawesome/pro-regular-svg-icons/faEyeDropperFull';
import { faEyeDropperHalf as farEyeDropperHalf } from '@fortawesome/pro-regular-svg-icons/faEyeDropperHalf';
import { faEyeEvil as farEyeEvil } from '@fortawesome/pro-regular-svg-icons/faEyeEvil';
import { faEyeLowVision as farEyeLowVision } from '@fortawesome/pro-regular-svg-icons/faEyeLowVision';
import { faEyes as farEyes } from '@fortawesome/pro-regular-svg-icons/faEyes';
import { faEyeSlash as farEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faF as farF } from '@fortawesome/pro-regular-svg-icons/faF';
import { faFaceAngry as farFaceAngry } from '@fortawesome/pro-regular-svg-icons/faFaceAngry';
import { faFaceAngryHorns as farFaceAngryHorns } from '@fortawesome/pro-regular-svg-icons/faFaceAngryHorns';
import { faFaceAnguished as farFaceAnguished } from '@fortawesome/pro-regular-svg-icons/faFaceAnguished';
import { faFaceAnxiousSweat as farFaceAnxiousSweat } from '@fortawesome/pro-regular-svg-icons/faFaceAnxiousSweat';
import { faFaceAstonished as farFaceAstonished } from '@fortawesome/pro-regular-svg-icons/faFaceAstonished';
import { faFaceAwesome as farFaceAwesome } from '@fortawesome/pro-regular-svg-icons/faFaceAwesome';
import { faFaceBeamHandOverMouth as farFaceBeamHandOverMouth } from '@fortawesome/pro-regular-svg-icons/faFaceBeamHandOverMouth';
import { faFacebook as farFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faFacebookF as farFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faFacebookMessenger as farFacebookMessenger } from '@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { faFacebookSquare as farFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faFaceClouds as farFaceClouds } from '@fortawesome/pro-regular-svg-icons/faFaceClouds';
import { faFaceConfounded as farFaceConfounded } from '@fortawesome/pro-regular-svg-icons/faFaceConfounded';
import { faFaceConfused as farFaceConfused } from '@fortawesome/pro-regular-svg-icons/faFaceConfused';
import { faFaceCowboyHat as farFaceCowboyHat } from '@fortawesome/pro-regular-svg-icons/faFaceCowboyHat';
import { faFaceDiagonalMouth as farFaceDiagonalMouth } from '@fortawesome/pro-regular-svg-icons/faFaceDiagonalMouth';
import { faFaceDisappointed as farFaceDisappointed } from '@fortawesome/pro-regular-svg-icons/faFaceDisappointed';
import { faFaceDisguise as farFaceDisguise } from '@fortawesome/pro-regular-svg-icons/faFaceDisguise';
import { faFaceDizzy as farFaceDizzy } from '@fortawesome/pro-regular-svg-icons/faFaceDizzy';
import { faFaceDotted as farFaceDotted } from '@fortawesome/pro-regular-svg-icons/faFaceDotted';
import { faFaceDowncastSweat as farFaceDowncastSweat } from '@fortawesome/pro-regular-svg-icons/faFaceDowncastSweat';
import { faFaceDrooling as farFaceDrooling } from '@fortawesome/pro-regular-svg-icons/faFaceDrooling';
import { faFaceExhaling as farFaceExhaling } from '@fortawesome/pro-regular-svg-icons/faFaceExhaling';
import { faFaceExplode as farFaceExplode } from '@fortawesome/pro-regular-svg-icons/faFaceExplode';
import { faFaceExpressionless as farFaceExpressionless } from '@fortawesome/pro-regular-svg-icons/faFaceExpressionless';
import { faFaceEyesXmarks as farFaceEyesXmarks } from '@fortawesome/pro-regular-svg-icons/faFaceEyesXmarks';
import { faFaceFearful as farFaceFearful } from '@fortawesome/pro-regular-svg-icons/faFaceFearful';
import { faFaceFlushed as farFaceFlushed } from '@fortawesome/pro-regular-svg-icons/faFaceFlushed';
import { faFaceFrown as farFaceFrown } from '@fortawesome/pro-regular-svg-icons/faFaceFrown';
import { faFaceFrownOpen as farFaceFrownOpen } from '@fortawesome/pro-regular-svg-icons/faFaceFrownOpen';
import { faFaceFrownSlight as farFaceFrownSlight } from '@fortawesome/pro-regular-svg-icons/faFaceFrownSlight';
import { faFaceGlasses as farFaceGlasses } from '@fortawesome/pro-regular-svg-icons/faFaceGlasses';
import { faFaceGrimace as farFaceGrimace } from '@fortawesome/pro-regular-svg-icons/faFaceGrimace';
import { faFaceGrin as farFaceGrin } from '@fortawesome/pro-regular-svg-icons/faFaceGrin';
import { faFaceGrinBeam as farFaceGrinBeam } from '@fortawesome/pro-regular-svg-icons/faFaceGrinBeam';
import { faFaceGrinBeamSweat as farFaceGrinBeamSweat } from '@fortawesome/pro-regular-svg-icons/faFaceGrinBeamSweat';
import { faFaceGrinHearts as farFaceGrinHearts } from '@fortawesome/pro-regular-svg-icons/faFaceGrinHearts';
import { faFaceGrinSquint as farFaceGrinSquint } from '@fortawesome/pro-regular-svg-icons/faFaceGrinSquint';
import { faFaceGrinSquintTears as farFaceGrinSquintTears } from '@fortawesome/pro-regular-svg-icons/faFaceGrinSquintTears';
import { faFaceGrinStars as farFaceGrinStars } from '@fortawesome/pro-regular-svg-icons/faFaceGrinStars';
import { faFaceGrinTears as farFaceGrinTears } from '@fortawesome/pro-regular-svg-icons/faFaceGrinTears';
import { faFaceGrinTongue as farFaceGrinTongue } from '@fortawesome/pro-regular-svg-icons/faFaceGrinTongue';
import { faFaceGrinTongueSquint as farFaceGrinTongueSquint } from '@fortawesome/pro-regular-svg-icons/faFaceGrinTongueSquint';
import { faFaceGrinTongueWink as farFaceGrinTongueWink } from '@fortawesome/pro-regular-svg-icons/faFaceGrinTongueWink';
import { faFaceGrinWide as farFaceGrinWide } from '@fortawesome/pro-regular-svg-icons/faFaceGrinWide';
import { faFaceGrinWink as farFaceGrinWink } from '@fortawesome/pro-regular-svg-icons/faFaceGrinWink';
import { faFaceHandOverMouth as farFaceHandOverMouth } from '@fortawesome/pro-regular-svg-icons/faFaceHandOverMouth';
import { faFaceHandPeeking as farFaceHandPeeking } from '@fortawesome/pro-regular-svg-icons/faFaceHandPeeking';
import { faFaceHandYawn as farFaceHandYawn } from '@fortawesome/pro-regular-svg-icons/faFaceHandYawn';
import { faFaceHeadBandage as farFaceHeadBandage } from '@fortawesome/pro-regular-svg-icons/faFaceHeadBandage';
import { faFaceHoldingBackTears as farFaceHoldingBackTears } from '@fortawesome/pro-regular-svg-icons/faFaceHoldingBackTears';
import { faFaceHushed as farFaceHushed } from '@fortawesome/pro-regular-svg-icons/faFaceHushed';
import { faFaceIcicles as farFaceIcicles } from '@fortawesome/pro-regular-svg-icons/faFaceIcicles';
import { faFaceKiss as farFaceKiss } from '@fortawesome/pro-regular-svg-icons/faFaceKiss';
import { faFaceKissBeam as farFaceKissBeam } from '@fortawesome/pro-regular-svg-icons/faFaceKissBeam';
import { faFaceKissClosedEyes as farFaceKissClosedEyes } from '@fortawesome/pro-regular-svg-icons/faFaceKissClosedEyes';
import { faFaceKissWinkHeart as farFaceKissWinkHeart } from '@fortawesome/pro-regular-svg-icons/faFaceKissWinkHeart';
import { faFaceLaugh as farFaceLaugh } from '@fortawesome/pro-regular-svg-icons/faFaceLaugh';
import { faFaceLaughBeam as farFaceLaughBeam } from '@fortawesome/pro-regular-svg-icons/faFaceLaughBeam';
import { faFaceLaughSquint as farFaceLaughSquint } from '@fortawesome/pro-regular-svg-icons/faFaceLaughSquint';
import { faFaceLaughWink as farFaceLaughWink } from '@fortawesome/pro-regular-svg-icons/faFaceLaughWink';
import { faFaceLying as farFaceLying } from '@fortawesome/pro-regular-svg-icons/faFaceLying';
import { faFaceMask as farFaceMask } from '@fortawesome/pro-regular-svg-icons/faFaceMask';
import { faFaceMeh as farFaceMeh } from '@fortawesome/pro-regular-svg-icons/faFaceMeh';
import { faFaceMehBlank as farFaceMehBlank } from '@fortawesome/pro-regular-svg-icons/faFaceMehBlank';
import { faFaceMelting as farFaceMelting } from '@fortawesome/pro-regular-svg-icons/faFaceMelting';
import { faFaceMonocle as farFaceMonocle } from '@fortawesome/pro-regular-svg-icons/faFaceMonocle';
import { faFaceNauseated as farFaceNauseated } from '@fortawesome/pro-regular-svg-icons/faFaceNauseated';
import { faFaceNoseSteam as farFaceNoseSteam } from '@fortawesome/pro-regular-svg-icons/faFaceNoseSteam';
import { faFaceParty as farFaceParty } from '@fortawesome/pro-regular-svg-icons/faFaceParty';
import { faFacePensive as farFacePensive } from '@fortawesome/pro-regular-svg-icons/faFacePensive';
import { faFacePersevering as farFacePersevering } from '@fortawesome/pro-regular-svg-icons/faFacePersevering';
import { faFacePleading as farFacePleading } from '@fortawesome/pro-regular-svg-icons/faFacePleading';
import { faFacePouting as farFacePouting } from '@fortawesome/pro-regular-svg-icons/faFacePouting';
import { faFaceRaisedEyebrow as farFaceRaisedEyebrow } from '@fortawesome/pro-regular-svg-icons/faFaceRaisedEyebrow';
import { faFaceRelieved as farFaceRelieved } from '@fortawesome/pro-regular-svg-icons/faFaceRelieved';
import { faFaceRollingEyes as farFaceRollingEyes } from '@fortawesome/pro-regular-svg-icons/faFaceRollingEyes';
import { faFaceSadCry as farFaceSadCry } from '@fortawesome/pro-regular-svg-icons/faFaceSadCry';
import { faFaceSadSweat as farFaceSadSweat } from '@fortawesome/pro-regular-svg-icons/faFaceSadSweat';
import { faFaceSadTear as farFaceSadTear } from '@fortawesome/pro-regular-svg-icons/faFaceSadTear';
import { faFaceSaluting as farFaceSaluting } from '@fortawesome/pro-regular-svg-icons/faFaceSaluting';
import { faFaceScream as farFaceScream } from '@fortawesome/pro-regular-svg-icons/faFaceScream';
import { faFaceShush as farFaceShush } from '@fortawesome/pro-regular-svg-icons/faFaceShush';
import { faFaceSleeping as farFaceSleeping } from '@fortawesome/pro-regular-svg-icons/faFaceSleeping';
import { faFaceSleepy as farFaceSleepy } from '@fortawesome/pro-regular-svg-icons/faFaceSleepy';
import { faFaceSmile as farFaceSmile } from '@fortawesome/pro-regular-svg-icons/faFaceSmile';
import { faFaceSmileBeam as farFaceSmileBeam } from '@fortawesome/pro-regular-svg-icons/faFaceSmileBeam';
import { faFaceSmileHalo as farFaceSmileHalo } from '@fortawesome/pro-regular-svg-icons/faFaceSmileHalo';
import { faFaceSmileHearts as farFaceSmileHearts } from '@fortawesome/pro-regular-svg-icons/faFaceSmileHearts';
import { faFaceSmileHorns as farFaceSmileHorns } from '@fortawesome/pro-regular-svg-icons/faFaceSmileHorns';
import { faFaceSmilePlus as farFaceSmilePlus } from '@fortawesome/pro-regular-svg-icons/faFaceSmilePlus';
import { faFaceSmileRelaxed as farFaceSmileRelaxed } from '@fortawesome/pro-regular-svg-icons/faFaceSmileRelaxed';
import { faFaceSmileTear as farFaceSmileTear } from '@fortawesome/pro-regular-svg-icons/faFaceSmileTear';
import { faFaceSmileTongue as farFaceSmileTongue } from '@fortawesome/pro-regular-svg-icons/faFaceSmileTongue';
import { faFaceSmileUpsideDown as farFaceSmileUpsideDown } from '@fortawesome/pro-regular-svg-icons/faFaceSmileUpsideDown';
import { faFaceSmileWink as farFaceSmileWink } from '@fortawesome/pro-regular-svg-icons/faFaceSmileWink';
import { faFaceSmilingHands as farFaceSmilingHands } from '@fortawesome/pro-regular-svg-icons/faFaceSmilingHands';
import { faFaceSmirking as farFaceSmirking } from '@fortawesome/pro-regular-svg-icons/faFaceSmirking';
import { faFaceSpiralEyes as farFaceSpiralEyes } from '@fortawesome/pro-regular-svg-icons/faFaceSpiralEyes';
import { faFaceSunglasses as farFaceSunglasses } from '@fortawesome/pro-regular-svg-icons/faFaceSunglasses';
import { faFaceSurprise as farFaceSurprise } from '@fortawesome/pro-regular-svg-icons/faFaceSurprise';
import { faFaceSwear as farFaceSwear } from '@fortawesome/pro-regular-svg-icons/faFaceSwear';
import { faFaceThermometer as farFaceThermometer } from '@fortawesome/pro-regular-svg-icons/faFaceThermometer';
import { faFaceThinking as farFaceThinking } from '@fortawesome/pro-regular-svg-icons/faFaceThinking';
import { faFaceTired as farFaceTired } from '@fortawesome/pro-regular-svg-icons/faFaceTired';
import { faFaceTissue as farFaceTissue } from '@fortawesome/pro-regular-svg-icons/faFaceTissue';
import { faFaceTongueMoney as farFaceTongueMoney } from '@fortawesome/pro-regular-svg-icons/faFaceTongueMoney';
import { faFaceTongueSweat as farFaceTongueSweat } from '@fortawesome/pro-regular-svg-icons/faFaceTongueSweat';
import { faFaceUnamused as farFaceUnamused } from '@fortawesome/pro-regular-svg-icons/faFaceUnamused';
import { faFaceViewfinder as farFaceViewfinder } from '@fortawesome/pro-regular-svg-icons/faFaceViewfinder';
import { faFaceVomit as farFaceVomit } from '@fortawesome/pro-regular-svg-icons/faFaceVomit';
import { faFaceWeary as farFaceWeary } from '@fortawesome/pro-regular-svg-icons/faFaceWeary';
import { faFaceWoozy as farFaceWoozy } from '@fortawesome/pro-regular-svg-icons/faFaceWoozy';
import { faFaceWorried as farFaceWorried } from '@fortawesome/pro-regular-svg-icons/faFaceWorried';
import { faFaceZany as farFaceZany } from '@fortawesome/pro-regular-svg-icons/faFaceZany';
import { faFaceZipper as farFaceZipper } from '@fortawesome/pro-regular-svg-icons/faFaceZipper';
import { faFalafel as farFalafel } from '@fortawesome/pro-regular-svg-icons/faFalafel';
import { faFamily as farFamily } from '@fortawesome/pro-regular-svg-icons/faFamily';
import { faFamilyDress as farFamilyDress } from '@fortawesome/pro-regular-svg-icons/faFamilyDress';
import { faFamilyPants as farFamilyPants } from '@fortawesome/pro-regular-svg-icons/faFamilyPants';
import { faFan as farFan } from '@fortawesome/pro-regular-svg-icons/faFan';
import { faFanTable as farFanTable } from '@fortawesome/pro-regular-svg-icons/faFanTable';
import { faFantasyFlightGames as farFantasyFlightGames } from '@fortawesome/free-brands-svg-icons/faFantasyFlightGames';
import { faFarm as farFarm } from '@fortawesome/pro-regular-svg-icons/faFarm';
import { faFastBackward as farFastBackward } from '@fortawesome/pro-regular-svg-icons/faFastBackward';
import { faFastForward as farFastForward } from '@fortawesome/pro-regular-svg-icons/faFastForward';
import { faFaucet as farFaucet } from '@fortawesome/pro-regular-svg-icons/faFaucet';
import { faFaucetDrip as farFaucetDrip } from '@fortawesome/pro-regular-svg-icons/faFaucetDrip';
import { faFax as farFax } from '@fortawesome/pro-regular-svg-icons/faFax';
import { faFeather as farFeather } from '@fortawesome/pro-regular-svg-icons/faFeather';
import { faFeatherAlt as farFeatherAlt } from '@fortawesome/pro-regular-svg-icons/faFeatherAlt';
import { faFeatherPointed as farFeatherPointed } from '@fortawesome/pro-regular-svg-icons/faFeatherPointed';
import { faFedex as farFedex } from '@fortawesome/free-brands-svg-icons/faFedex';
import { faFedora as farFedora } from '@fortawesome/free-brands-svg-icons/faFedora';
import { faFeed as farFeed } from '@fortawesome/pro-regular-svg-icons/faFeed';
import { faFemale as farFemale } from '@fortawesome/pro-regular-svg-icons/faFemale';
import { faFence as farFence } from '@fortawesome/pro-regular-svg-icons/faFence';
import { faFerrisWheel as farFerrisWheel } from '@fortawesome/pro-regular-svg-icons/faFerrisWheel';
import { faFerry as farFerry } from '@fortawesome/pro-regular-svg-icons/faFerry';
import { faFieldHockey as farFieldHockey } from '@fortawesome/pro-regular-svg-icons/faFieldHockey';
import { faFieldHockeyStickBall as farFieldHockeyStickBall } from '@fortawesome/pro-regular-svg-icons/faFieldHockeyStickBall';
import { faFighterJet as farFighterJet } from '@fortawesome/pro-regular-svg-icons/faFighterJet';
import { faFigma as farFigma } from '@fortawesome/free-brands-svg-icons/faFigma';
import { faFile as farFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faFileAlt as farFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faFileArchive as farFileArchive } from '@fortawesome/pro-regular-svg-icons/faFileArchive';
import { faFileArrowDown as farFileArrowDown } from '@fortawesome/pro-regular-svg-icons/faFileArrowDown';
import { faFileArrowUp as farFileArrowUp } from '@fortawesome/pro-regular-svg-icons/faFileArrowUp';
import { faFileAudio as farFileAudio } from '@fortawesome/pro-regular-svg-icons/faFileAudio';
import { faFileAward as farFileAward } from '@fortawesome/pro-regular-svg-icons/faFileAward';
import { faFileBinary as farFileBinary } from '@fortawesome/pro-regular-svg-icons/faFileBinary';
import { faFileCaretDown as farFileCaretDown } from '@fortawesome/pro-regular-svg-icons/faFileCaretDown';
import { faFileCaretUp as farFileCaretUp } from '@fortawesome/pro-regular-svg-icons/faFileCaretUp';
import { faFileCertificate as farFileCertificate } from '@fortawesome/pro-regular-svg-icons/faFileCertificate';
import { faFileChartColumn as farFileChartColumn } from '@fortawesome/pro-regular-svg-icons/faFileChartColumn';
import { faFileChartLine as farFileChartLine } from '@fortawesome/pro-regular-svg-icons/faFileChartLine';
import { faFileChartPie as farFileChartPie } from '@fortawesome/pro-regular-svg-icons/faFileChartPie';
import { faFileCheck as farFileCheck } from '@fortawesome/pro-regular-svg-icons/faFileCheck';
import { faFileCircleCheck as farFileCircleCheck } from '@fortawesome/pro-regular-svg-icons/faFileCircleCheck';
import { faFileCircleExclamation as farFileCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faFileCircleExclamation';
import { faFileCircleInfo as farFileCircleInfo } from '@fortawesome/pro-regular-svg-icons/faFileCircleInfo';
import { faFileCircleMinus as farFileCircleMinus } from '@fortawesome/pro-regular-svg-icons/faFileCircleMinus';
import { faFileCirclePlus as farFileCirclePlus } from '@fortawesome/pro-regular-svg-icons/faFileCirclePlus';
import { faFileCircleQuestion as farFileCircleQuestion } from '@fortawesome/pro-regular-svg-icons/faFileCircleQuestion';
import { faFileCircleXmark as farFileCircleXmark } from '@fortawesome/pro-regular-svg-icons/faFileCircleXmark';
import { faFileClipboard as farFileClipboard } from '@fortawesome/pro-regular-svg-icons/faFileClipboard';
import { faFileCode as farFileCode } from '@fortawesome/pro-regular-svg-icons/faFileCode';
import { faFileContract as farFileContract } from '@fortawesome/pro-regular-svg-icons/faFileContract';
import { faFileCsv as farFileCsv } from '@fortawesome/pro-regular-svg-icons/faFileCsv';
import { faFileDashedLine as farFileDashedLine } from '@fortawesome/pro-regular-svg-icons/faFileDashedLine';
import { faFileDoc as farFileDoc } from '@fortawesome/pro-regular-svg-icons/faFileDoc';
import { faFileDownload as farFileDownload } from '@fortawesome/pro-regular-svg-icons/faFileDownload';
import { faFileEdit as farFileEdit } from '@fortawesome/pro-regular-svg-icons/faFileEdit';
import { faFileEps as farFileEps } from '@fortawesome/pro-regular-svg-icons/faFileEps';
import { faFileExcel as farFileExcel } from '@fortawesome/pro-regular-svg-icons/faFileExcel';
import { faFileExclamation as farFileExclamation } from '@fortawesome/pro-regular-svg-icons/faFileExclamation';
import { faFileExport as farFileExport } from '@fortawesome/pro-regular-svg-icons/faFileExport';
import { faFileGif as farFileGif } from '@fortawesome/pro-regular-svg-icons/faFileGif';
import { faFileHeart as farFileHeart } from '@fortawesome/pro-regular-svg-icons/faFileHeart';
import { faFileImage as farFileImage } from '@fortawesome/pro-regular-svg-icons/faFileImage';
import { faFileImport as farFileImport } from '@fortawesome/pro-regular-svg-icons/faFileImport';
import { faFileInvoice as farFileInvoice } from '@fortawesome/pro-regular-svg-icons/faFileInvoice';
import { faFileInvoiceDollar as farFileInvoiceDollar } from '@fortawesome/pro-regular-svg-icons/faFileInvoiceDollar';
import { faFileJpg as farFileJpg } from '@fortawesome/pro-regular-svg-icons/faFileJpg';
import { faFileLines as farFileLines } from '@fortawesome/pro-regular-svg-icons/faFileLines';
import { faFileLock as farFileLock } from '@fortawesome/pro-regular-svg-icons/faFileLock';
import { faFileMagnifyingGlass as farFileMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faFileMagnifyingGlass';
import { faFileMedical as farFileMedical } from '@fortawesome/pro-regular-svg-icons/faFileMedical';
import { faFileMedicalAlt as farFileMedicalAlt } from '@fortawesome/pro-regular-svg-icons/faFileMedicalAlt';
import { faFileMinus as farFileMinus } from '@fortawesome/pro-regular-svg-icons/faFileMinus';
import { faFileMov as farFileMov } from '@fortawesome/pro-regular-svg-icons/faFileMov';
import { faFileMp3 as farFileMp3 } from '@fortawesome/pro-regular-svg-icons/faFileMp3';
import { faFileMp4 as farFileMp4 } from '@fortawesome/pro-regular-svg-icons/faFileMp4';
import { faFileMusic as farFileMusic } from '@fortawesome/pro-regular-svg-icons/faFileMusic';
import { faFilePdf as farFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faFilePen as farFilePen } from '@fortawesome/pro-regular-svg-icons/faFilePen';
import { faFilePlus as farFilePlus } from '@fortawesome/pro-regular-svg-icons/faFilePlus';
import { faFilePlusMinus as farFilePlusMinus } from '@fortawesome/pro-regular-svg-icons/faFilePlusMinus';
import { faFilePng as farFilePng } from '@fortawesome/pro-regular-svg-icons/faFilePng';
import { faFilePowerpoint as farFilePowerpoint } from '@fortawesome/pro-regular-svg-icons/faFilePowerpoint';
import { faFilePpt as farFilePpt } from '@fortawesome/pro-regular-svg-icons/faFilePpt';
import { faFilePrescription as farFilePrescription } from '@fortawesome/pro-regular-svg-icons/faFilePrescription';
import { faFiles as farFiles } from '@fortawesome/pro-regular-svg-icons/faFiles';
import { faFileSearch as farFileSearch } from '@fortawesome/pro-regular-svg-icons/faFileSearch';
import { faFileShield as farFileShield } from '@fortawesome/pro-regular-svg-icons/faFileShield';
import { faFileSignature as farFileSignature } from '@fortawesome/pro-regular-svg-icons/faFileSignature';
import { faFileSlash as farFileSlash } from '@fortawesome/pro-regular-svg-icons/faFileSlash';
import { faFilesMedical as farFilesMedical } from '@fortawesome/pro-regular-svg-icons/faFilesMedical';
import { faFileSpreadsheet as farFileSpreadsheet } from '@fortawesome/pro-regular-svg-icons/faFileSpreadsheet';
import { faFileSvg as farFileSvg } from '@fortawesome/pro-regular-svg-icons/faFileSvg';
import { faFileText as farFileText } from '@fortawesome/pro-regular-svg-icons/faFileText';
import { faFileTimes as farFileTimes } from '@fortawesome/pro-regular-svg-icons/faFileTimes';
import { faFileUpload as farFileUpload } from '@fortawesome/pro-regular-svg-icons/faFileUpload';
import { faFileUser as farFileUser } from '@fortawesome/pro-regular-svg-icons/faFileUser';
import { faFileVector as farFileVector } from '@fortawesome/pro-regular-svg-icons/faFileVector';
import { faFileVideo as farFileVideo } from '@fortawesome/pro-regular-svg-icons/faFileVideo';
import { faFileWaveform as farFileWaveform } from '@fortawesome/pro-regular-svg-icons/faFileWaveform';
import { faFileWord as farFileWord } from '@fortawesome/pro-regular-svg-icons/faFileWord';
import { faFileXls as farFileXls } from '@fortawesome/pro-regular-svg-icons/faFileXls';
import { faFileXmark as farFileXmark } from '@fortawesome/pro-regular-svg-icons/faFileXmark';
import { faFileXml as farFileXml } from '@fortawesome/pro-regular-svg-icons/faFileXml';
import { faFileZip as farFileZip } from '@fortawesome/pro-regular-svg-icons/faFileZip';
import { faFileZipper as farFileZipper } from '@fortawesome/pro-regular-svg-icons/faFileZipper';
import { faFill as farFill } from '@fortawesome/pro-regular-svg-icons/faFill';
import { faFillDrip as farFillDrip } from '@fortawesome/pro-regular-svg-icons/faFillDrip';
import { faFilm as farFilm } from '@fortawesome/pro-regular-svg-icons/faFilm';
import { faFilmAlt as farFilmAlt } from '@fortawesome/pro-regular-svg-icons/faFilmAlt';
import { faFilmCanister as farFilmCanister } from '@fortawesome/pro-regular-svg-icons/faFilmCanister';
import { faFilmCannister as farFilmCannister } from '@fortawesome/pro-regular-svg-icons/faFilmCannister';
import { faFilms as farFilms } from '@fortawesome/pro-regular-svg-icons/faFilms';
import { faFilmSimple as farFilmSimple } from '@fortawesome/pro-regular-svg-icons/faFilmSimple';
import { faFilmSlash as farFilmSlash } from '@fortawesome/pro-regular-svg-icons/faFilmSlash';
import { faFilter as farFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faFilterCircleDollar as farFilterCircleDollar } from '@fortawesome/pro-regular-svg-icons/faFilterCircleDollar';
import { faFilterCircleXmark as farFilterCircleXmark } from '@fortawesome/pro-regular-svg-icons/faFilterCircleXmark';
import { faFilterList as farFilterList } from '@fortawesome/pro-regular-svg-icons/faFilterList';
import { faFilters as farFilters } from '@fortawesome/pro-regular-svg-icons/faFilters';
import { faFilterSlash as farFilterSlash } from '@fortawesome/pro-regular-svg-icons/faFilterSlash';
import { faFingerprint as farFingerprint } from '@fortawesome/pro-regular-svg-icons/faFingerprint';
import { faFire as farFire } from '@fortawesome/pro-regular-svg-icons/faFire';
import { faFireAlt as farFireAlt } from '@fortawesome/pro-regular-svg-icons/faFireAlt';
import { faFireBurner as farFireBurner } from '@fortawesome/pro-regular-svg-icons/faFireBurner';
import { faFireExtinguisher as farFireExtinguisher } from '@fortawesome/pro-regular-svg-icons/faFireExtinguisher';
import { faFireFlame as farFireFlame } from '@fortawesome/pro-regular-svg-icons/faFireFlame';
import { faFireFlameCurved as farFireFlameCurved } from '@fortawesome/pro-regular-svg-icons/faFireFlameCurved';
import { faFireFlameSimple as farFireFlameSimple } from '@fortawesome/pro-regular-svg-icons/faFireFlameSimple';
import { faFirefox as farFirefox } from '@fortawesome/free-brands-svg-icons/faFirefox';
import { faFirefoxBrowser as farFirefoxBrowser } from '@fortawesome/free-brands-svg-icons/faFirefoxBrowser';
import { faFireHydrant as farFireHydrant } from '@fortawesome/pro-regular-svg-icons/faFireHydrant';
import { faFireplace as farFireplace } from '@fortawesome/pro-regular-svg-icons/faFireplace';
import { faFireSmoke as farFireSmoke } from '@fortawesome/pro-regular-svg-icons/faFireSmoke';
import { faFirewall as farFirewall } from '@fortawesome/pro-regular-svg-icons/faFirewall';
import { faFirstAid as farFirstAid } from '@fortawesome/pro-regular-svg-icons/faFirstAid';
import { faFirstdraft as farFirstdraft } from '@fortawesome/free-brands-svg-icons/faFirstdraft';
import { faFirstOrder as farFirstOrder } from '@fortawesome/free-brands-svg-icons/faFirstOrder';
import { faFirstOrderAlt as farFirstOrderAlt } from '@fortawesome/free-brands-svg-icons/faFirstOrderAlt';
import { faFish as farFish } from '@fortawesome/pro-regular-svg-icons/faFish';
import { faFishBones as farFishBones } from '@fortawesome/pro-regular-svg-icons/faFishBones';
import { faFishCooked as farFishCooked } from '@fortawesome/pro-regular-svg-icons/faFishCooked';
import { faFishFins as farFishFins } from '@fortawesome/pro-regular-svg-icons/faFishFins';
import { faFishingRod as farFishingRod } from '@fortawesome/pro-regular-svg-icons/faFishingRod';
import { faFistRaised as farFistRaised } from '@fortawesome/pro-regular-svg-icons/faFistRaised';
import { faFlag as farFlag } from '@fortawesome/pro-regular-svg-icons/faFlag';
import { faFlagAlt as farFlagAlt } from '@fortawesome/pro-regular-svg-icons/faFlagAlt';
import { faFlagCheckered as farFlagCheckered } from '@fortawesome/pro-regular-svg-icons/faFlagCheckered';
import { faFlagPennant as farFlagPennant } from '@fortawesome/pro-regular-svg-icons/faFlagPennant';
import { faFlagSwallowtail as farFlagSwallowtail } from '@fortawesome/pro-regular-svg-icons/faFlagSwallowtail';
import { faFlagUsa as farFlagUsa } from '@fortawesome/pro-regular-svg-icons/faFlagUsa';
import { faFlame as farFlame } from '@fortawesome/pro-regular-svg-icons/faFlame';
import { faFlashlight as farFlashlight } from '@fortawesome/pro-regular-svg-icons/faFlashlight';
import { faFlask as farFlask } from '@fortawesome/pro-regular-svg-icons/faFlask';
import { faFlaskGear as farFlaskGear } from '@fortawesome/pro-regular-svg-icons/faFlaskGear';
import { faFlaskPoison as farFlaskPoison } from '@fortawesome/pro-regular-svg-icons/faFlaskPoison';
import { faFlaskPotion as farFlaskPotion } from '@fortawesome/pro-regular-svg-icons/faFlaskPotion';
import { faFlaskRoundPoison as farFlaskRoundPoison } from '@fortawesome/pro-regular-svg-icons/faFlaskRoundPoison';
import { faFlaskRoundPotion as farFlaskRoundPotion } from '@fortawesome/pro-regular-svg-icons/faFlaskRoundPotion';
import { faFlaskVial as farFlaskVial } from '@fortawesome/pro-regular-svg-icons/faFlaskVial';
import { faFlatbread as farFlatbread } from '@fortawesome/pro-regular-svg-icons/faFlatbread';
import { faFlatbreadStuffed as farFlatbreadStuffed } from '@fortawesome/pro-regular-svg-icons/faFlatbreadStuffed';
import { faFlickr as farFlickr } from '@fortawesome/free-brands-svg-icons/faFlickr';
import { faFlipboard as farFlipboard } from '@fortawesome/free-brands-svg-icons/faFlipboard';
import { faFloppyDisk as farFloppyDisk } from '@fortawesome/pro-regular-svg-icons/faFloppyDisk';
import { faFloppyDiskCircleArrowRight as farFloppyDiskCircleArrowRight } from '@fortawesome/pro-regular-svg-icons/faFloppyDiskCircleArrowRight';
import { faFloppyDiskCircleXmark as farFloppyDiskCircleXmark } from '@fortawesome/pro-regular-svg-icons/faFloppyDiskCircleXmark';
import { faFloppyDiskPen as farFloppyDiskPen } from '@fortawesome/pro-regular-svg-icons/faFloppyDiskPen';
import { faFloppyDisks as farFloppyDisks } from '@fortawesome/pro-regular-svg-icons/faFloppyDisks';
import { faFloppyDiskTimes as farFloppyDiskTimes } from '@fortawesome/pro-regular-svg-icons/faFloppyDiskTimes';
import { faFlorinSign as farFlorinSign } from '@fortawesome/pro-regular-svg-icons/faFlorinSign';
import { faFlower as farFlower } from '@fortawesome/pro-regular-svg-icons/faFlower';
import { faFlowerDaffodil as farFlowerDaffodil } from '@fortawesome/pro-regular-svg-icons/faFlowerDaffodil';
import { faFlowerTulip as farFlowerTulip } from '@fortawesome/pro-regular-svg-icons/faFlowerTulip';
import { faFlushed as farFlushed } from '@fortawesome/pro-regular-svg-icons/faFlushed';
import { faFlute as farFlute } from '@fortawesome/pro-regular-svg-icons/faFlute';
import { faFluxCapacitor as farFluxCapacitor } from '@fortawesome/pro-regular-svg-icons/faFluxCapacitor';
import { faFly as farFly } from '@fortawesome/free-brands-svg-icons/faFly';
import { faFlyingDisc as farFlyingDisc } from '@fortawesome/pro-regular-svg-icons/faFlyingDisc';
import { faFog as farFog } from '@fortawesome/pro-regular-svg-icons/faFog';
import { faFolder as farFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { faFolderArrowDown as farFolderArrowDown } from '@fortawesome/pro-regular-svg-icons/faFolderArrowDown';
import { faFolderArrowUp as farFolderArrowUp } from '@fortawesome/pro-regular-svg-icons/faFolderArrowUp';
import { faFolderBlank as farFolderBlank } from '@fortawesome/pro-regular-svg-icons/faFolderBlank';
import { faFolderBookmark as farFolderBookmark } from '@fortawesome/pro-regular-svg-icons/faFolderBookmark';
import { faFolderCheck as farFolderCheck } from '@fortawesome/pro-regular-svg-icons/faFolderCheck';
import { faFolderClosed as farFolderClosed } from '@fortawesome/pro-regular-svg-icons/faFolderClosed';
import { faFolderCog as farFolderCog } from '@fortawesome/pro-regular-svg-icons/faFolderCog';
import { faFolderDownload as farFolderDownload } from '@fortawesome/pro-regular-svg-icons/faFolderDownload';
import { faFolderGear as farFolderGear } from '@fortawesome/pro-regular-svg-icons/faFolderGear';
import { faFolderGrid as farFolderGrid } from '@fortawesome/pro-regular-svg-icons/faFolderGrid';
import { faFolderHeart as farFolderHeart } from '@fortawesome/pro-regular-svg-icons/faFolderHeart';
import { faFolderImage as farFolderImage } from '@fortawesome/pro-regular-svg-icons/faFolderImage';
import { faFolderMagnifyingGlass as farFolderMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faFolderMagnifyingGlass';
import { faFolderMedical as farFolderMedical } from '@fortawesome/pro-regular-svg-icons/faFolderMedical';
import { faFolderMinus as farFolderMinus } from '@fortawesome/pro-regular-svg-icons/faFolderMinus';
import { faFolderMusic as farFolderMusic } from '@fortawesome/pro-regular-svg-icons/faFolderMusic';
import { faFolderOpen as farFolderOpen } from '@fortawesome/pro-regular-svg-icons/faFolderOpen';
import { faFolderPlus as farFolderPlus } from '@fortawesome/pro-regular-svg-icons/faFolderPlus';
import { faFolders as farFolders } from '@fortawesome/pro-regular-svg-icons/faFolders';
import { faFolderSearch as farFolderSearch } from '@fortawesome/pro-regular-svg-icons/faFolderSearch';
import { faFolderTimes as farFolderTimes } from '@fortawesome/pro-regular-svg-icons/faFolderTimes';
import { faFolderTree as farFolderTree } from '@fortawesome/pro-regular-svg-icons/faFolderTree';
import { faFolderUpload as farFolderUpload } from '@fortawesome/pro-regular-svg-icons/faFolderUpload';
import { faFolderUser as farFolderUser } from '@fortawesome/pro-regular-svg-icons/faFolderUser';
import { faFolderXmark as farFolderXmark } from '@fortawesome/pro-regular-svg-icons/faFolderXmark';
import { faFonduePot as farFonduePot } from '@fortawesome/pro-regular-svg-icons/faFonduePot';
import { faFont as farFont } from '@fortawesome/pro-regular-svg-icons/faFont';
import { faFontAwesome as farFontAwesome } from '@fortawesome/pro-regular-svg-icons/faFontAwesome';
import { faFontAwesomeAlt as farFontAwesomeAlt } from '@fortawesome/free-brands-svg-icons/faFontAwesomeAlt';
import { faFontAwesomeFlag as farFontAwesomeFlag } from '@fortawesome/pro-regular-svg-icons/faFontAwesomeFlag';
import { faFontAwesomeLogoFull as farFontAwesomeLogoFull } from '@fortawesome/pro-regular-svg-icons/faFontAwesomeLogoFull';
import { faFontCase as farFontCase } from '@fortawesome/pro-regular-svg-icons/faFontCase';
import { faFonticons as farFonticons } from '@fortawesome/free-brands-svg-icons/faFonticons';
import { faFonticonsFi as farFonticonsFi } from '@fortawesome/free-brands-svg-icons/faFonticonsFi';
import { faFootball as farFootball } from '@fortawesome/pro-regular-svg-icons/faFootball';
import { faFootballBall as farFootballBall } from '@fortawesome/pro-regular-svg-icons/faFootballBall';
import { faFootballHelmet as farFootballHelmet } from '@fortawesome/pro-regular-svg-icons/faFootballHelmet';
import { faFork as farFork } from '@fortawesome/pro-regular-svg-icons/faFork';
import { faForkKnife as farForkKnife } from '@fortawesome/pro-regular-svg-icons/faForkKnife';
import { faForklift as farForklift } from '@fortawesome/pro-regular-svg-icons/faForklift';
import { faFort as farFort } from '@fortawesome/pro-regular-svg-icons/faFort';
import { faFortAwesome as farFortAwesome } from '@fortawesome/free-brands-svg-icons/faFortAwesome';
import { faFortAwesomeAlt as farFortAwesomeAlt } from '@fortawesome/free-brands-svg-icons/faFortAwesomeAlt';
import { faForumbee as farForumbee } from '@fortawesome/free-brands-svg-icons/faForumbee';
import { faForward as farForward } from '@fortawesome/pro-regular-svg-icons/faForward';
import { faForwardFast as farForwardFast } from '@fortawesome/pro-regular-svg-icons/faForwardFast';
import { faForwardStep as farForwardStep } from '@fortawesome/pro-regular-svg-icons/faForwardStep';
import { faFoursquare as farFoursquare } from '@fortawesome/free-brands-svg-icons/faFoursquare';
import { faFragile as farFragile } from '@fortawesome/pro-regular-svg-icons/faFragile';
import { faFrame as farFrame } from '@fortawesome/pro-regular-svg-icons/faFrame';
import { faFrancSign as farFrancSign } from '@fortawesome/pro-regular-svg-icons/faFrancSign';
import { faFreebsd as farFreebsd } from '@fortawesome/free-brands-svg-icons/faFreebsd';
import { faFreeCodeCamp as farFreeCodeCamp } from '@fortawesome/free-brands-svg-icons/faFreeCodeCamp';
import { faFrenchFries as farFrenchFries } from '@fortawesome/pro-regular-svg-icons/faFrenchFries';
import { faFrog as farFrog } from '@fortawesome/pro-regular-svg-icons/faFrog';
import { faFrostyHead as farFrostyHead } from '@fortawesome/pro-regular-svg-icons/faFrostyHead';
import { faFrown as farFrown } from '@fortawesome/pro-regular-svg-icons/faFrown';
import { faFrownOpen as farFrownOpen } from '@fortawesome/pro-regular-svg-icons/faFrownOpen';
import { faFulcrum as farFulcrum } from '@fortawesome/free-brands-svg-icons/faFulcrum';
import { faFunction as farFunction } from '@fortawesome/pro-regular-svg-icons/faFunction';
import { faFunnelDollar as farFunnelDollar } from '@fortawesome/pro-regular-svg-icons/faFunnelDollar';
import { faFutbol as farFutbol } from '@fortawesome/pro-regular-svg-icons/faFutbol';
import { faFutbolBall as farFutbolBall } from '@fortawesome/pro-regular-svg-icons/faFutbolBall';
import { faG as farG } from '@fortawesome/pro-regular-svg-icons/faG';
import { faGalacticRepublic as farGalacticRepublic } from '@fortawesome/free-brands-svg-icons/faGalacticRepublic';
import { faGalacticSenate as farGalacticSenate } from '@fortawesome/free-brands-svg-icons/faGalacticSenate';
import { faGalaxy as farGalaxy } from '@fortawesome/pro-regular-svg-icons/faGalaxy';
import { faGalleryThumbnails as farGalleryThumbnails } from '@fortawesome/pro-regular-svg-icons/faGalleryThumbnails';
import { faGameBoard as farGameBoard } from '@fortawesome/pro-regular-svg-icons/faGameBoard';
import { faGameBoardAlt as farGameBoardAlt } from '@fortawesome/pro-regular-svg-icons/faGameBoardAlt';
import { faGameBoardSimple as farGameBoardSimple } from '@fortawesome/pro-regular-svg-icons/faGameBoardSimple';
import { faGameConsoleHandheld as farGameConsoleHandheld } from '@fortawesome/pro-regular-svg-icons/faGameConsoleHandheld';
import { faGameConsoleHandheldCrank as farGameConsoleHandheldCrank } from '@fortawesome/pro-regular-svg-icons/faGameConsoleHandheldCrank';
import { faGamepad as farGamepad } from '@fortawesome/pro-regular-svg-icons/faGamepad';
import { faGamepadAlt as farGamepadAlt } from '@fortawesome/pro-regular-svg-icons/faGamepadAlt';
import { faGamepadModern as farGamepadModern } from '@fortawesome/pro-regular-svg-icons/faGamepadModern';
import { faGarage as farGarage } from '@fortawesome/pro-regular-svg-icons/faGarage';
import { faGarageCar as farGarageCar } from '@fortawesome/pro-regular-svg-icons/faGarageCar';
import { faGarageOpen as farGarageOpen } from '@fortawesome/pro-regular-svg-icons/faGarageOpen';
import { faGarlic as farGarlic } from '@fortawesome/pro-regular-svg-icons/faGarlic';
import { faGasPump as farGasPump } from '@fortawesome/pro-regular-svg-icons/faGasPump';
import { faGasPumpSlash as farGasPumpSlash } from '@fortawesome/pro-regular-svg-icons/faGasPumpSlash';
import { faGauge as farGauge } from '@fortawesome/pro-regular-svg-icons/faGauge';
import { faGaugeCircleBolt as farGaugeCircleBolt } from '@fortawesome/pro-regular-svg-icons/faGaugeCircleBolt';
import { faGaugeCircleMinus as farGaugeCircleMinus } from '@fortawesome/pro-regular-svg-icons/faGaugeCircleMinus';
import { faGaugeCirclePlus as farGaugeCirclePlus } from '@fortawesome/pro-regular-svg-icons/faGaugeCirclePlus';
import { faGaugeHigh as farGaugeHigh } from '@fortawesome/pro-regular-svg-icons/faGaugeHigh';
import { faGaugeLow as farGaugeLow } from '@fortawesome/pro-regular-svg-icons/faGaugeLow';
import { faGaugeMax as farGaugeMax } from '@fortawesome/pro-regular-svg-icons/faGaugeMax';
import { faGaugeMed as farGaugeMed } from '@fortawesome/pro-regular-svg-icons/faGaugeMed';
import { faGaugeMin as farGaugeMin } from '@fortawesome/pro-regular-svg-icons/faGaugeMin';
import { faGaugeSimple as farGaugeSimple } from '@fortawesome/pro-regular-svg-icons/faGaugeSimple';
import { faGaugeSimpleHigh as farGaugeSimpleHigh } from '@fortawesome/pro-regular-svg-icons/faGaugeSimpleHigh';
import { faGaugeSimpleLow as farGaugeSimpleLow } from '@fortawesome/pro-regular-svg-icons/faGaugeSimpleLow';
import { faGaugeSimpleMax as farGaugeSimpleMax } from '@fortawesome/pro-regular-svg-icons/faGaugeSimpleMax';
import { faGaugeSimpleMed as farGaugeSimpleMed } from '@fortawesome/pro-regular-svg-icons/faGaugeSimpleMed';
import { faGaugeSimpleMin as farGaugeSimpleMin } from '@fortawesome/pro-regular-svg-icons/faGaugeSimpleMin';
import { faGaveDandy as farGaveDandy } from '@fortawesome/pro-regular-svg-icons/faGaveDandy';
import { faGavel as farGavel } from '@fortawesome/pro-regular-svg-icons/faGavel';
import { faGbp as farGbp } from '@fortawesome/pro-regular-svg-icons/faGbp';
import { faGear as farGear } from '@fortawesome/pro-regular-svg-icons/faGear';
import { faGearCode as farGearCode } from '@fortawesome/pro-regular-svg-icons/faGearCode';
import { faGearComplex as farGearComplex } from '@fortawesome/pro-regular-svg-icons/faGearComplex';
import { faGearComplexCode as farGearComplexCode } from '@fortawesome/pro-regular-svg-icons/faGearComplexCode';
import { faGears as farGears } from '@fortawesome/pro-regular-svg-icons/faGears';
import { faGem as farGem } from '@fortawesome/pro-regular-svg-icons/faGem';
import { faGenderless as farGenderless } from '@fortawesome/pro-regular-svg-icons/faGenderless';
import { faGetPocket as farGetPocket } from '@fortawesome/free-brands-svg-icons/faGetPocket';
import { faGg as farGg } from '@fortawesome/free-brands-svg-icons/faGg';
import { faGgCircle as farGgCircle } from '@fortawesome/free-brands-svg-icons/faGgCircle';
import { faGhost as farGhost } from '@fortawesome/pro-regular-svg-icons/faGhost';
import { faGif as farGif } from '@fortawesome/pro-regular-svg-icons/faGif';
import { faGift as farGift } from '@fortawesome/pro-regular-svg-icons/faGift';
import { faGiftCard as farGiftCard } from '@fortawesome/pro-regular-svg-icons/faGiftCard';
import { faGifts as farGifts } from '@fortawesome/pro-regular-svg-icons/faGifts';
import { faGingerbreadMan as farGingerbreadMan } from '@fortawesome/pro-regular-svg-icons/faGingerbreadMan';
import { faGit as farGit } from '@fortawesome/free-brands-svg-icons/faGit';
import { faGitAlt as farGitAlt } from '@fortawesome/free-brands-svg-icons/faGitAlt';
import { faGithub as farGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faGithubAlt as farGithubAlt } from '@fortawesome/free-brands-svg-icons/faGithubAlt';
import { faGithubSquare as farGithubSquare } from '@fortawesome/free-brands-svg-icons/faGithubSquare';
import { faGitkraken as farGitkraken } from '@fortawesome/free-brands-svg-icons/faGitkraken';
import { faGitlab as farGitlab } from '@fortawesome/free-brands-svg-icons/faGitlab';
import { faGitlabSquare as farGitlabSquare } from '@fortawesome/free-brands-svg-icons/faGitlabSquare';
import { faGitSquare as farGitSquare } from '@fortawesome/free-brands-svg-icons/faGitSquare';
import { faGitter as farGitter } from '@fortawesome/free-brands-svg-icons/faGitter';
import { faGlass as farGlass } from '@fortawesome/pro-regular-svg-icons/faGlass';
import { faGlassChampagne as farGlassChampagne } from '@fortawesome/pro-regular-svg-icons/faGlassChampagne';
import { faGlassCheers as farGlassCheers } from '@fortawesome/pro-regular-svg-icons/faGlassCheers';
import { faGlassCitrus as farGlassCitrus } from '@fortawesome/pro-regular-svg-icons/faGlassCitrus';
import { faGlassEmpty as farGlassEmpty } from '@fortawesome/pro-regular-svg-icons/faGlassEmpty';
import { faGlasses as farGlasses } from '@fortawesome/pro-regular-svg-icons/faGlasses';
import { faGlassesAlt as farGlassesAlt } from '@fortawesome/pro-regular-svg-icons/faGlassesAlt';
import { faGlassesRound as farGlassesRound } from '@fortawesome/pro-regular-svg-icons/faGlassesRound';
import { faGlassHalf as farGlassHalf } from '@fortawesome/pro-regular-svg-icons/faGlassHalf';
import { faGlassHalfEmpty as farGlassHalfEmpty } from '@fortawesome/pro-regular-svg-icons/faGlassHalfEmpty';
import { faGlassHalfFull as farGlassHalfFull } from '@fortawesome/pro-regular-svg-icons/faGlassHalfFull';
import { faGlassMartini as farGlassMartini } from '@fortawesome/pro-regular-svg-icons/faGlassMartini';
import { faGlassMartiniAlt as farGlassMartiniAlt } from '@fortawesome/pro-regular-svg-icons/faGlassMartiniAlt';
import { faGlassWater as farGlassWater } from '@fortawesome/pro-regular-svg-icons/faGlassWater';
import { faGlassWaterDroplet as farGlassWaterDroplet } from '@fortawesome/pro-regular-svg-icons/faGlassWaterDroplet';
import { faGlassWhiskey as farGlassWhiskey } from '@fortawesome/pro-regular-svg-icons/faGlassWhiskey';
import { faGlassWhiskeyRocks as farGlassWhiskeyRocks } from '@fortawesome/pro-regular-svg-icons/faGlassWhiskeyRocks';
import { faGlide as farGlide } from '@fortawesome/free-brands-svg-icons/faGlide';
import { faGlideG as farGlideG } from '@fortawesome/free-brands-svg-icons/faGlideG';
import { faGlobe as farGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';
import { faGlobeAfrica as farGlobeAfrica } from '@fortawesome/pro-regular-svg-icons/faGlobeAfrica';
import { faGlobeAmericas as farGlobeAmericas } from '@fortawesome/pro-regular-svg-icons/faGlobeAmericas';
import { faGlobeAsia as farGlobeAsia } from '@fortawesome/pro-regular-svg-icons/faGlobeAsia';
import { faGlobeEurope as farGlobeEurope } from '@fortawesome/pro-regular-svg-icons/faGlobeEurope';
import { faGlobeOceania as farGlobeOceania } from '@fortawesome/pro-regular-svg-icons/faGlobeOceania';
import { faGlobePointer as farGlobePointer } from '@fortawesome/pro-regular-svg-icons/faGlobePointer';
import { faGlobeSnow as farGlobeSnow } from '@fortawesome/pro-regular-svg-icons/faGlobeSnow';
import { faGlobeStand as farGlobeStand } from '@fortawesome/pro-regular-svg-icons/faGlobeStand';
import { faGloveBoxing as farGloveBoxing } from '@fortawesome/pro-regular-svg-icons/faGloveBoxing';
import { faGoalNet as farGoalNet } from '@fortawesome/pro-regular-svg-icons/faGoalNet';
import { faGofore as farGofore } from '@fortawesome/free-brands-svg-icons/faGofore';
import { faGolang as farGolang } from '@fortawesome/free-brands-svg-icons/faGolang';
import { faGolfBall as farGolfBall } from '@fortawesome/pro-regular-svg-icons/faGolfBall';
import { faGolfBallTee as farGolfBallTee } from '@fortawesome/pro-regular-svg-icons/faGolfBallTee';
import { faGolfClub as farGolfClub } from '@fortawesome/pro-regular-svg-icons/faGolfClub';
import { faGolfFlagHole as farGolfFlagHole } from '@fortawesome/pro-regular-svg-icons/faGolfFlagHole';
import { faGoodreads as farGoodreads } from '@fortawesome/free-brands-svg-icons/faGoodreads';
import { faGoodreadsG as farGoodreadsG } from '@fortawesome/free-brands-svg-icons/faGoodreadsG';
import { faGoogle as farGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faGoogleDrive as farGoogleDrive } from '@fortawesome/free-brands-svg-icons/faGoogleDrive';
import { faGooglePay as farGooglePay } from '@fortawesome/free-brands-svg-icons/faGooglePay';
import { faGooglePlay as farGooglePlay } from '@fortawesome/free-brands-svg-icons/faGooglePlay';
import { faGooglePlus as farGooglePlus } from '@fortawesome/free-brands-svg-icons/faGooglePlus';
import { faGooglePlusG as farGooglePlusG } from '@fortawesome/free-brands-svg-icons/faGooglePlusG';
import { faGooglePlusSquare as farGooglePlusSquare } from '@fortawesome/free-brands-svg-icons/faGooglePlusSquare';
import { faGoogleScholar as farGoogleScholar } from '@fortawesome/free-brands-svg-icons/faGoogleScholar';
import { faGoogleWallet as farGoogleWallet } from '@fortawesome/free-brands-svg-icons/faGoogleWallet';
import { faGopuram as farGopuram } from '@fortawesome/pro-regular-svg-icons/faGopuram';
import { faGraduationCap as farGraduationCap } from '@fortawesome/pro-regular-svg-icons/faGraduationCap';
import { faGramophone as farGramophone } from '@fortawesome/pro-regular-svg-icons/faGramophone';
import { faGrapes as farGrapes } from '@fortawesome/pro-regular-svg-icons/faGrapes';
import { faGrate as farGrate } from '@fortawesome/pro-regular-svg-icons/faGrate';
import { faGrateDroplet as farGrateDroplet } from '@fortawesome/pro-regular-svg-icons/faGrateDroplet';
import { faGratipay as farGratipay } from '@fortawesome/free-brands-svg-icons/faGratipay';
import { faGrav as farGrav } from '@fortawesome/free-brands-svg-icons/faGrav';
import { faGreaterThan as farGreaterThan } from '@fortawesome/pro-regular-svg-icons/faGreaterThan';
import { faGreaterThanEqual as farGreaterThanEqual } from '@fortawesome/pro-regular-svg-icons/faGreaterThanEqual';
import { faGrid as farGrid } from '@fortawesome/pro-regular-svg-icons/faGrid';
import { faGrid2 as farGrid2 } from '@fortawesome/pro-regular-svg-icons/faGrid2';
import { faGrid2Plus as farGrid2Plus } from '@fortawesome/pro-regular-svg-icons/faGrid2Plus';
import { faGrid3 as farGrid3 } from '@fortawesome/pro-regular-svg-icons/faGrid3';
import { faGrid4 as farGrid4 } from '@fortawesome/pro-regular-svg-icons/faGrid4';
import { faGrid5 as farGrid5 } from '@fortawesome/pro-regular-svg-icons/faGrid5';
import { faGridDividers as farGridDividers } from '@fortawesome/pro-regular-svg-icons/faGridDividers';
import { faGridHorizontal as farGridHorizontal } from '@fortawesome/pro-regular-svg-icons/faGridHorizontal';
import { faGridRound as farGridRound } from '@fortawesome/pro-regular-svg-icons/faGridRound';
import { faGridRound2 as farGridRound2 } from '@fortawesome/pro-regular-svg-icons/faGridRound2';
import { faGridRound2Plus as farGridRound2Plus } from '@fortawesome/pro-regular-svg-icons/faGridRound2Plus';
import { faGridRound4 as farGridRound4 } from '@fortawesome/pro-regular-svg-icons/faGridRound4';
import { faGridRound5 as farGridRound5 } from '@fortawesome/pro-regular-svg-icons/faGridRound5';
import { faGrill as farGrill } from '@fortawesome/pro-regular-svg-icons/faGrill';
import { faGrillFire as farGrillFire } from '@fortawesome/pro-regular-svg-icons/faGrillFire';
import { faGrillHot as farGrillHot } from '@fortawesome/pro-regular-svg-icons/faGrillHot';
import { faGrimace as farGrimace } from '@fortawesome/pro-regular-svg-icons/faGrimace';
import { faGrin as farGrin } from '@fortawesome/pro-regular-svg-icons/faGrin';
import { faGrinAlt as farGrinAlt } from '@fortawesome/pro-regular-svg-icons/faGrinAlt';
import { faGrinBeam as farGrinBeam } from '@fortawesome/pro-regular-svg-icons/faGrinBeam';
import { faGrinBeamSweat as farGrinBeamSweat } from '@fortawesome/pro-regular-svg-icons/faGrinBeamSweat';
import { faGrinHearts as farGrinHearts } from '@fortawesome/pro-regular-svg-icons/faGrinHearts';
import { faGrinSquint as farGrinSquint } from '@fortawesome/pro-regular-svg-icons/faGrinSquint';
import { faGrinSquintTears as farGrinSquintTears } from '@fortawesome/pro-regular-svg-icons/faGrinSquintTears';
import { faGrinStars as farGrinStars } from '@fortawesome/pro-regular-svg-icons/faGrinStars';
import { faGrinTears as farGrinTears } from '@fortawesome/pro-regular-svg-icons/faGrinTears';
import { faGrinTongue as farGrinTongue } from '@fortawesome/pro-regular-svg-icons/faGrinTongue';
import { faGrinTongueSquint as farGrinTongueSquint } from '@fortawesome/pro-regular-svg-icons/faGrinTongueSquint';
import { faGrinTongueWink as farGrinTongueWink } from '@fortawesome/pro-regular-svg-icons/faGrinTongueWink';
import { faGrinWink as farGrinWink } from '@fortawesome/pro-regular-svg-icons/faGrinWink';
import { faGrip as farGrip } from '@fortawesome/pro-regular-svg-icons/faGrip';
import { faGripDots as farGripDots } from '@fortawesome/pro-regular-svg-icons/faGripDots';
import { faGripDotsVertical as farGripDotsVertical } from '@fortawesome/pro-regular-svg-icons/faGripDotsVertical';
import { faGripfire as farGripfire } from '@fortawesome/free-brands-svg-icons/faGripfire';
import { faGripHorizontal as farGripHorizontal } from '@fortawesome/pro-regular-svg-icons/faGripHorizontal';
import { faGripLines as farGripLines } from '@fortawesome/pro-regular-svg-icons/faGripLines';
import { faGripLinesVertical as farGripLinesVertical } from '@fortawesome/pro-regular-svg-icons/faGripLinesVertical';
import { faGripVertical as farGripVertical } from '@fortawesome/pro-regular-svg-icons/faGripVertical';
import { faGroupArrowsRotate as farGroupArrowsRotate } from '@fortawesome/pro-regular-svg-icons/faGroupArrowsRotate';
import { faGrunt as farGrunt } from '@fortawesome/free-brands-svg-icons/faGrunt';
import { faGuaraniSign as farGuaraniSign } from '@fortawesome/pro-regular-svg-icons/faGuaraniSign';
import { faGuilded as farGuilded } from '@fortawesome/free-brands-svg-icons/faGuilded';
import { faGuitar as farGuitar } from '@fortawesome/pro-regular-svg-icons/faGuitar';
import { faGuitarElectric as farGuitarElectric } from '@fortawesome/pro-regular-svg-icons/faGuitarElectric';
import { faGuitars as farGuitars } from '@fortawesome/pro-regular-svg-icons/faGuitars';
import { faGulp as farGulp } from '@fortawesome/free-brands-svg-icons/faGulp';
import { faGun as farGun } from '@fortawesome/pro-regular-svg-icons/faGun';
import { faGunSlash as farGunSlash } from '@fortawesome/pro-regular-svg-icons/faGunSlash';
import { faGunSquirt as farGunSquirt } from '@fortawesome/pro-regular-svg-icons/faGunSquirt';
import { faH as farH } from '@fortawesome/pro-regular-svg-icons/faH';
import { faH1 as farH1 } from '@fortawesome/pro-regular-svg-icons/faH1';
import { faH2 as farH2 } from '@fortawesome/pro-regular-svg-icons/faH2';
import { faH3 as farH3 } from '@fortawesome/pro-regular-svg-icons/faH3';
import { faH4 as farH4 } from '@fortawesome/pro-regular-svg-icons/faH4';
import { faH5 as farH5 } from '@fortawesome/pro-regular-svg-icons/faH5';
import { faH6 as farH6 } from '@fortawesome/pro-regular-svg-icons/faH6';
import { faHackerNews as farHackerNews } from '@fortawesome/free-brands-svg-icons/faHackerNews';
import { faHackerNewsSquare as farHackerNewsSquare } from '@fortawesome/free-brands-svg-icons/faHackerNewsSquare';
import { faHackerrank as farHackerrank } from '@fortawesome/free-brands-svg-icons/faHackerrank';
import { faHamburger as farHamburger } from '@fortawesome/pro-regular-svg-icons/faHamburger';
import { faHammer as farHammer } from '@fortawesome/pro-regular-svg-icons/faHammer';
import { faHammerBrush as farHammerBrush } from '@fortawesome/pro-regular-svg-icons/faHammerBrush';
import { faHammerCrash as farHammerCrash } from '@fortawesome/pro-regular-svg-icons/faHammerCrash';
import { faHammerWar as farHammerWar } from '@fortawesome/pro-regular-svg-icons/faHammerWar';
import { faHamsa as farHamsa } from '@fortawesome/pro-regular-svg-icons/faHamsa';
import { faHand as farHand } from '@fortawesome/pro-regular-svg-icons/faHand';
import { faHandBackFist as farHandBackFist } from '@fortawesome/pro-regular-svg-icons/faHandBackFist';
import { faHandBackPointDown as farHandBackPointDown } from '@fortawesome/pro-regular-svg-icons/faHandBackPointDown';
import { faHandBackPointLeft as farHandBackPointLeft } from '@fortawesome/pro-regular-svg-icons/faHandBackPointLeft';
import { faHandBackPointRibbon as farHandBackPointRibbon } from '@fortawesome/pro-regular-svg-icons/faHandBackPointRibbon';
import { faHandBackPointRight as farHandBackPointRight } from '@fortawesome/pro-regular-svg-icons/faHandBackPointRight';
import { faHandBackPointUp as farHandBackPointUp } from '@fortawesome/pro-regular-svg-icons/faHandBackPointUp';
import { faHandcuffs as farHandcuffs } from '@fortawesome/pro-regular-svg-icons/faHandcuffs';
import { faHandDots as farHandDots } from '@fortawesome/pro-regular-svg-icons/faHandDots';
import { faHandFingersCrossed as farHandFingersCrossed } from '@fortawesome/pro-regular-svg-icons/faHandFingersCrossed';
import { faHandFist as farHandFist } from '@fortawesome/pro-regular-svg-icons/faHandFist';
import { faHandHeart as farHandHeart } from '@fortawesome/pro-regular-svg-icons/faHandHeart';
import { faHandHolding as farHandHolding } from '@fortawesome/pro-regular-svg-icons/faHandHolding';
import { faHandHoldingBox as farHandHoldingBox } from '@fortawesome/pro-regular-svg-icons/faHandHoldingBox';
import { faHandHoldingCircleDollar as farHandHoldingCircleDollar } from '@fortawesome/pro-regular-svg-icons/faHandHoldingCircleDollar';
import { faHandHoldingDollar as farHandHoldingDollar } from '@fortawesome/pro-regular-svg-icons/faHandHoldingDollar';
import { faHandHoldingDroplet as farHandHoldingDroplet } from '@fortawesome/pro-regular-svg-icons/faHandHoldingDroplet';
import { faHandHoldingHand as farHandHoldingHand } from '@fortawesome/pro-regular-svg-icons/faHandHoldingHand';
import { faHandHoldingHeart as farHandHoldingHeart } from '@fortawesome/pro-regular-svg-icons/faHandHoldingHeart';
import { faHandHoldingMagic as farHandHoldingMagic } from '@fortawesome/pro-regular-svg-icons/faHandHoldingMagic';
import { faHandHoldingMedical as farHandHoldingMedical } from '@fortawesome/pro-regular-svg-icons/faHandHoldingMedical';
import { faHandHoldingSeedling as farHandHoldingSeedling } from '@fortawesome/pro-regular-svg-icons/faHandHoldingSeedling';
import { faHandHoldingSkull as farHandHoldingSkull } from '@fortawesome/pro-regular-svg-icons/faHandHoldingSkull';
import { faHandHoldingUsd as farHandHoldingUsd } from '@fortawesome/pro-regular-svg-icons/faHandHoldingUsd';
import { faHandHoldingWater as farHandHoldingWater } from '@fortawesome/pro-regular-svg-icons/faHandHoldingWater';
import { faHandHorns as farHandHorns } from '@fortawesome/pro-regular-svg-icons/faHandHorns';
import { faHandLizard as farHandLizard } from '@fortawesome/pro-regular-svg-icons/faHandLizard';
import { faHandLove as farHandLove } from '@fortawesome/pro-regular-svg-icons/faHandLove';
import { faHandMiddleFinger as farHandMiddleFinger } from '@fortawesome/pro-regular-svg-icons/faHandMiddleFinger';
import { faHandPaper as farHandPaper } from '@fortawesome/pro-regular-svg-icons/faHandPaper';
import { faHandPeace as farHandPeace } from '@fortawesome/pro-regular-svg-icons/faHandPeace';
import { faHandPointDown as farHandPointDown } from '@fortawesome/pro-regular-svg-icons/faHandPointDown';
import { faHandPointer as farHandPointer } from '@fortawesome/pro-regular-svg-icons/faHandPointer';
import { faHandPointLeft as farHandPointLeft } from '@fortawesome/pro-regular-svg-icons/faHandPointLeft';
import { faHandPointRibbon as farHandPointRibbon } from '@fortawesome/pro-regular-svg-icons/faHandPointRibbon';
import { faHandPointRight as farHandPointRight } from '@fortawesome/pro-regular-svg-icons/faHandPointRight';
import { faHandPointUp as farHandPointUp } from '@fortawesome/pro-regular-svg-icons/faHandPointUp';
import { faHandReceiving as farHandReceiving } from '@fortawesome/pro-regular-svg-icons/faHandReceiving';
import { faHandRock as farHandRock } from '@fortawesome/pro-regular-svg-icons/faHandRock';
import { faHands as farHands } from '@fortawesome/pro-regular-svg-icons/faHands';
import { faHandsAmericanSignLanguageInterpreting as farHandsAmericanSignLanguageInterpreting } from '@fortawesome/pro-regular-svg-icons/faHandsAmericanSignLanguageInterpreting';
import { faHandsAslInterpreting as farHandsAslInterpreting } from '@fortawesome/pro-regular-svg-icons/faHandsAslInterpreting';
import { faHandsBound as farHandsBound } from '@fortawesome/pro-regular-svg-icons/faHandsBound';
import { faHandsBubbles as farHandsBubbles } from '@fortawesome/pro-regular-svg-icons/faHandsBubbles';
import { faHandScissors as farHandScissors } from '@fortawesome/pro-regular-svg-icons/faHandScissors';
import { faHandsClapping as farHandsClapping } from '@fortawesome/pro-regular-svg-icons/faHandsClapping';
import { faHandshake as farHandshake } from '@fortawesome/pro-regular-svg-icons/faHandshake';
import { faHandshakeAlt as farHandshakeAlt } from '@fortawesome/pro-regular-svg-icons/faHandshakeAlt';
import { faHandshakeAltSlash as farHandshakeAltSlash } from '@fortawesome/pro-regular-svg-icons/faHandshakeAltSlash';
import { faHandshakeAngle as farHandshakeAngle } from '@fortawesome/pro-regular-svg-icons/faHandshakeAngle';
import { faHandshakeSimple as farHandshakeSimple } from '@fortawesome/pro-regular-svg-icons/faHandshakeSimple';
import { faHandshakeSimpleSlash as farHandshakeSimpleSlash } from '@fortawesome/pro-regular-svg-icons/faHandshakeSimpleSlash';
import { faHandshakeSlash as farHandshakeSlash } from '@fortawesome/pro-regular-svg-icons/faHandshakeSlash';
import { faHandsHeart as farHandsHeart } from '@fortawesome/pro-regular-svg-icons/faHandsHeart';
import { faHandsHelping as farHandsHelping } from '@fortawesome/pro-regular-svg-icons/faHandsHelping';
import { faHandsHolding as farHandsHolding } from '@fortawesome/pro-regular-svg-icons/faHandsHolding';
import { faHandsHoldingChild as farHandsHoldingChild } from '@fortawesome/pro-regular-svg-icons/faHandsHoldingChild';
import { faHandsHoldingCircle as farHandsHoldingCircle } from '@fortawesome/pro-regular-svg-icons/faHandsHoldingCircle';
import { faHandsHoldingDiamond as farHandsHoldingDiamond } from '@fortawesome/pro-regular-svg-icons/faHandsHoldingDiamond';
import { faHandsHoldingDollar as farHandsHoldingDollar } from '@fortawesome/pro-regular-svg-icons/faHandsHoldingDollar';
import { faHandsHoldingHeart as farHandsHoldingHeart } from '@fortawesome/pro-regular-svg-icons/faHandsHoldingHeart';
import { faHandSparkles as farHandSparkles } from '@fortawesome/pro-regular-svg-icons/faHandSparkles';
import { faHandSpock as farHandSpock } from '@fortawesome/pro-regular-svg-icons/faHandSpock';
import { faHandsPraying as farHandsPraying } from '@fortawesome/pro-regular-svg-icons/faHandsPraying';
import { faHandsUsd as farHandsUsd } from '@fortawesome/pro-regular-svg-icons/faHandsUsd';
import { faHandsWash as farHandsWash } from '@fortawesome/pro-regular-svg-icons/faHandsWash';
import { faHandWave as farHandWave } from '@fortawesome/pro-regular-svg-icons/faHandWave';
import { faHanukiah as farHanukiah } from '@fortawesome/pro-regular-svg-icons/faHanukiah';
import { faHardDrive as farHardDrive } from '@fortawesome/pro-regular-svg-icons/faHardDrive';
import { faHardHat as farHardHat } from '@fortawesome/pro-regular-svg-icons/faHardHat';
import { faHardOfHearing as farHardOfHearing } from '@fortawesome/pro-regular-svg-icons/faHardOfHearing';
import { faHashnode as farHashnode } from '@fortawesome/free-brands-svg-icons/faHashnode';
import { faHashtag as farHashtag } from '@fortawesome/pro-regular-svg-icons/faHashtag';
import { faHashtagLock as farHashtagLock } from '@fortawesome/pro-regular-svg-icons/faHashtagLock';
import { faHatBeach as farHatBeach } from '@fortawesome/pro-regular-svg-icons/faHatBeach';
import { faHatChef as farHatChef } from '@fortawesome/pro-regular-svg-icons/faHatChef';
import { faHatCowboy as farHatCowboy } from '@fortawesome/pro-regular-svg-icons/faHatCowboy';
import { faHatCowboySide as farHatCowboySide } from '@fortawesome/pro-regular-svg-icons/faHatCowboySide';
import { faHatHard as farHatHard } from '@fortawesome/pro-regular-svg-icons/faHatHard';
import { faHatSanta as farHatSanta } from '@fortawesome/pro-regular-svg-icons/faHatSanta';
import { faHatWinter as farHatWinter } from '@fortawesome/pro-regular-svg-icons/faHatWinter';
import { faHatWitch as farHatWitch } from '@fortawesome/pro-regular-svg-icons/faHatWitch';
import { faHatWizard as farHatWizard } from '@fortawesome/pro-regular-svg-icons/faHatWizard';
import { faHaykal as farHaykal } from '@fortawesome/pro-regular-svg-icons/faHaykal';
import { faHdd as farHdd } from '@fortawesome/pro-regular-svg-icons/faHdd';
import { faHeader as farHeader } from '@fortawesome/pro-regular-svg-icons/faHeader';
import { faHeading as farHeading } from '@fortawesome/pro-regular-svg-icons/faHeading';
import { faHeadphones as farHeadphones } from '@fortawesome/pro-regular-svg-icons/faHeadphones';
import { faHeadphonesAlt as farHeadphonesAlt } from '@fortawesome/pro-regular-svg-icons/faHeadphonesAlt';
import { faHeadphonesSimple as farHeadphonesSimple } from '@fortawesome/pro-regular-svg-icons/faHeadphonesSimple';
import { faHeadset as farHeadset } from '@fortawesome/pro-regular-svg-icons/faHeadset';
import { faHeadSide as farHeadSide } from '@fortawesome/pro-regular-svg-icons/faHeadSide';
import { faHeadSideBrain as farHeadSideBrain } from '@fortawesome/pro-regular-svg-icons/faHeadSideBrain';
import { faHeadSideCough as farHeadSideCough } from '@fortawesome/pro-regular-svg-icons/faHeadSideCough';
import { faHeadSideCoughSlash as farHeadSideCoughSlash } from '@fortawesome/pro-regular-svg-icons/faHeadSideCoughSlash';
import { faHeadSideGear as farHeadSideGear } from '@fortawesome/pro-regular-svg-icons/faHeadSideGear';
import { faHeadSideGoggles as farHeadSideGoggles } from '@fortawesome/pro-regular-svg-icons/faHeadSideGoggles';
import { faHeadSideHeadphones as farHeadSideHeadphones } from '@fortawesome/pro-regular-svg-icons/faHeadSideHeadphones';
import { faHeadSideHeart as farHeadSideHeart } from '@fortawesome/pro-regular-svg-icons/faHeadSideHeart';
import { faHeadSideMask as farHeadSideMask } from '@fortawesome/pro-regular-svg-icons/faHeadSideMask';
import { faHeadSideMedical as farHeadSideMedical } from '@fortawesome/pro-regular-svg-icons/faHeadSideMedical';
import { faHeadSideVirus as farHeadSideVirus } from '@fortawesome/pro-regular-svg-icons/faHeadSideVirus';
import { faHeadVr as farHeadVr } from '@fortawesome/pro-regular-svg-icons/faHeadVr';
import { faHeart as farHeart } from '@fortawesome/pro-regular-svg-icons/faHeart';
import { faHeartbeat as farHeartbeat } from '@fortawesome/pro-regular-svg-icons/faHeartbeat';
import { faHeartBroken as farHeartBroken } from '@fortawesome/pro-regular-svg-icons/faHeartBroken';
import { faHeartCircle as farHeartCircle } from '@fortawesome/pro-regular-svg-icons/faHeartCircle';
import { faHeartCircleBolt as farHeartCircleBolt } from '@fortawesome/pro-regular-svg-icons/faHeartCircleBolt';
import { faHeartCircleCheck as farHeartCircleCheck } from '@fortawesome/pro-regular-svg-icons/faHeartCircleCheck';
import { faHeartCircleExclamation as farHeartCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faHeartCircleExclamation';
import { faHeartCircleMinus as farHeartCircleMinus } from '@fortawesome/pro-regular-svg-icons/faHeartCircleMinus';
import { faHeartCirclePlus as farHeartCirclePlus } from '@fortawesome/pro-regular-svg-icons/faHeartCirclePlus';
import { faHeartCircleXmark as farHeartCircleXmark } from '@fortawesome/pro-regular-svg-icons/faHeartCircleXmark';
import { faHeartCrack as farHeartCrack } from '@fortawesome/pro-regular-svg-icons/faHeartCrack';
import { faHeartHalf as farHeartHalf } from '@fortawesome/pro-regular-svg-icons/faHeartHalf';
import { faHeartHalfAlt as farHeartHalfAlt } from '@fortawesome/pro-regular-svg-icons/faHeartHalfAlt';
import { faHeartHalfStroke as farHeartHalfStroke } from '@fortawesome/pro-regular-svg-icons/faHeartHalfStroke';
import { faHeartMusicCameraBolt as farHeartMusicCameraBolt } from '@fortawesome/pro-regular-svg-icons/faHeartMusicCameraBolt';
import { faHeartPulse as farHeartPulse } from '@fortawesome/pro-regular-svg-icons/faHeartPulse';
import { faHeartRate as farHeartRate } from '@fortawesome/pro-regular-svg-icons/faHeartRate';
import { faHeartSquare as farHeartSquare } from '@fortawesome/pro-regular-svg-icons/faHeartSquare';
import { faHeat as farHeat } from '@fortawesome/pro-regular-svg-icons/faHeat';
import { faHelicopter as farHelicopter } from '@fortawesome/pro-regular-svg-icons/faHelicopter';
import { faHelicopterSymbol as farHelicopterSymbol } from '@fortawesome/pro-regular-svg-icons/faHelicopterSymbol';
import { faHelmetBattle as farHelmetBattle } from '@fortawesome/pro-regular-svg-icons/faHelmetBattle';
import { faHelmetSafety as farHelmetSafety } from '@fortawesome/pro-regular-svg-icons/faHelmetSafety';
import { faHelmetUn as farHelmetUn } from '@fortawesome/pro-regular-svg-icons/faHelmetUn';
import { faHexagon as farHexagon } from '@fortawesome/pro-regular-svg-icons/faHexagon';
import { faHexagonCheck as farHexagonCheck } from '@fortawesome/pro-regular-svg-icons/faHexagonCheck';
import { faHexagonDivide as farHexagonDivide } from '@fortawesome/pro-regular-svg-icons/faHexagonDivide';
import { faHexagonExclamation as farHexagonExclamation } from '@fortawesome/pro-regular-svg-icons/faHexagonExclamation';
import { faHexagonImage as farHexagonImage } from '@fortawesome/pro-regular-svg-icons/faHexagonImage';
import { faHexagonMinus as farHexagonMinus } from '@fortawesome/pro-regular-svg-icons/faHexagonMinus';
import { faHexagonPlus as farHexagonPlus } from '@fortawesome/pro-regular-svg-icons/faHexagonPlus';
import { faHexagonVerticalNft as farHexagonVerticalNft } from '@fortawesome/pro-regular-svg-icons/faHexagonVerticalNft';
import { faHexagonVerticalNftSlanted as farHexagonVerticalNftSlanted } from '@fortawesome/pro-regular-svg-icons/faHexagonVerticalNftSlanted';
import { faHexagonXmark as farHexagonXmark } from '@fortawesome/pro-regular-svg-icons/faHexagonXmark';
import { faHighDefinition as farHighDefinition } from '@fortawesome/pro-regular-svg-icons/faHighDefinition';
import { faHighlighter as farHighlighter } from '@fortawesome/pro-regular-svg-icons/faHighlighter';
import { faHighlighterLine as farHighlighterLine } from '@fortawesome/pro-regular-svg-icons/faHighlighterLine';
import { faHiking as farHiking } from '@fortawesome/pro-regular-svg-icons/faHiking';
import { faHillAvalanche as farHillAvalanche } from '@fortawesome/pro-regular-svg-icons/faHillAvalanche';
import { faHillRockslide as farHillRockslide } from '@fortawesome/pro-regular-svg-icons/faHillRockslide';
import { faHippo as farHippo } from '@fortawesome/pro-regular-svg-icons/faHippo';
import { faHips as farHips } from '@fortawesome/free-brands-svg-icons/faHips';
import { faHireAHelper as farHireAHelper } from '@fortawesome/free-brands-svg-icons/faHireAHelper';
import { faHistory as farHistory } from '@fortawesome/pro-regular-svg-icons/faHistory';
import { faHive as farHive } from '@fortawesome/free-brands-svg-icons/faHive';
import { faHockeyMask as farHockeyMask } from '@fortawesome/pro-regular-svg-icons/faHockeyMask';
import { faHockeyPuck as farHockeyPuck } from '@fortawesome/pro-regular-svg-icons/faHockeyPuck';
import { faHockeyStickPuck as farHockeyStickPuck } from '@fortawesome/pro-regular-svg-icons/faHockeyStickPuck';
import { faHockeySticks as farHockeySticks } from '@fortawesome/pro-regular-svg-icons/faHockeySticks';
import { faHollyBerry as farHollyBerry } from '@fortawesome/pro-regular-svg-icons/faHollyBerry';
import { faHome as farHome } from '@fortawesome/pro-regular-svg-icons/faHome';
import { faHomeAlt as farHomeAlt } from '@fortawesome/pro-regular-svg-icons/faHomeAlt';
import { faHomeBlank as farHomeBlank } from '@fortawesome/pro-regular-svg-icons/faHomeBlank';
import { faHomeHeart as farHomeHeart } from '@fortawesome/pro-regular-svg-icons/faHomeHeart';
import { faHomeLg as farHomeLg } from '@fortawesome/pro-regular-svg-icons/faHomeLg';
import { faHomeLgAlt as farHomeLgAlt } from '@fortawesome/pro-regular-svg-icons/faHomeLgAlt';
import { faHomeUser as farHomeUser } from '@fortawesome/pro-regular-svg-icons/faHomeUser';
import { faHoneyPot as farHoneyPot } from '@fortawesome/pro-regular-svg-icons/faHoneyPot';
import { faHoodCloak as farHoodCloak } from '@fortawesome/pro-regular-svg-icons/faHoodCloak';
import { faHooli as farHooli } from '@fortawesome/free-brands-svg-icons/faHooli';
import { faHorizontalRule as farHorizontalRule } from '@fortawesome/pro-regular-svg-icons/faHorizontalRule';
import { faHornbill as farHornbill } from '@fortawesome/free-brands-svg-icons/faHornbill';
import { faHorse as farHorse } from '@fortawesome/pro-regular-svg-icons/faHorse';
import { faHorseHead as farHorseHead } from '@fortawesome/pro-regular-svg-icons/faHorseHead';
import { faHorseSaddle as farHorseSaddle } from '@fortawesome/pro-regular-svg-icons/faHorseSaddle';
import { faHose as farHose } from '@fortawesome/pro-regular-svg-icons/faHose';
import { faHoseReel as farHoseReel } from '@fortawesome/pro-regular-svg-icons/faHoseReel';
import { faHospital as farHospital } from '@fortawesome/pro-regular-svg-icons/faHospital';
import { faHospitalAlt as farHospitalAlt } from '@fortawesome/pro-regular-svg-icons/faHospitalAlt';
import { faHospitals as farHospitals } from '@fortawesome/pro-regular-svg-icons/faHospitals';
import { faHospitalSymbol as farHospitalSymbol } from '@fortawesome/pro-regular-svg-icons/faHospitalSymbol';
import { faHospitalUser as farHospitalUser } from '@fortawesome/pro-regular-svg-icons/faHospitalUser';
import { faHospitalWide as farHospitalWide } from '@fortawesome/pro-regular-svg-icons/faHospitalWide';
import { faHotdog as farHotdog } from '@fortawesome/pro-regular-svg-icons/faHotdog';
import { faHotel as farHotel } from '@fortawesome/pro-regular-svg-icons/faHotel';
import { faHotjar as farHotjar } from '@fortawesome/free-brands-svg-icons/faHotjar';
import { faHotTub as farHotTub } from '@fortawesome/pro-regular-svg-icons/faHotTub';
import { faHotTubPerson as farHotTubPerson } from '@fortawesome/pro-regular-svg-icons/faHotTubPerson';
import { faHourglass as farHourglass } from '@fortawesome/pro-regular-svg-icons/faHourglass';
import { faHourglass1 as farHourglass1 } from '@fortawesome/pro-regular-svg-icons/faHourglass1';
import { faHourglass2 as farHourglass2 } from '@fortawesome/pro-regular-svg-icons/faHourglass2';
import { faHourglass3 as farHourglass3 } from '@fortawesome/pro-regular-svg-icons/faHourglass3';
import { faHourglassClock as farHourglassClock } from '@fortawesome/pro-regular-svg-icons/faHourglassClock';
import { faHourglassEmpty as farHourglassEmpty } from '@fortawesome/pro-regular-svg-icons/faHourglassEmpty';
import { faHourglassEnd as farHourglassEnd } from '@fortawesome/pro-regular-svg-icons/faHourglassEnd';
import { faHourglassHalf as farHourglassHalf } from '@fortawesome/pro-regular-svg-icons/faHourglassHalf';
import { faHourglassStart as farHourglassStart } from '@fortawesome/pro-regular-svg-icons/faHourglassStart';
import { faHouse as farHouse } from '@fortawesome/pro-regular-svg-icons/faHouse';
import { faHouseBlank as farHouseBlank } from '@fortawesome/pro-regular-svg-icons/faHouseBlank';
import { faHouseBuilding as farHouseBuilding } from '@fortawesome/pro-regular-svg-icons/faHouseBuilding';
import { faHouseChimney as farHouseChimney } from '@fortawesome/pro-regular-svg-icons/faHouseChimney';
import { faHouseChimneyBlank as farHouseChimneyBlank } from '@fortawesome/pro-regular-svg-icons/faHouseChimneyBlank';
import { faHouseChimneyCrack as farHouseChimneyCrack } from '@fortawesome/pro-regular-svg-icons/faHouseChimneyCrack';
import { faHouseChimneyHeart as farHouseChimneyHeart } from '@fortawesome/pro-regular-svg-icons/faHouseChimneyHeart';
import { faHouseChimneyMedical as farHouseChimneyMedical } from '@fortawesome/pro-regular-svg-icons/faHouseChimneyMedical';
import { faHouseChimneyUser as farHouseChimneyUser } from '@fortawesome/pro-regular-svg-icons/faHouseChimneyUser';
import { faHouseChimneyWindow as farHouseChimneyWindow } from '@fortawesome/pro-regular-svg-icons/faHouseChimneyWindow';
import { faHouseCircleCheck as farHouseCircleCheck } from '@fortawesome/pro-regular-svg-icons/faHouseCircleCheck';
import { faHouseCircleExclamation as farHouseCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faHouseCircleExclamation';
import { faHouseCircleXmark as farHouseCircleXmark } from '@fortawesome/pro-regular-svg-icons/faHouseCircleXmark';
import { faHouseCrack as farHouseCrack } from '@fortawesome/pro-regular-svg-icons/faHouseCrack';
import { faHouseDamage as farHouseDamage } from '@fortawesome/pro-regular-svg-icons/faHouseDamage';
import { faHouseDay as farHouseDay } from '@fortawesome/pro-regular-svg-icons/faHouseDay';
import { faHouseFire as farHouseFire } from '@fortawesome/pro-regular-svg-icons/faHouseFire';
import { faHouseFlag as farHouseFlag } from '@fortawesome/pro-regular-svg-icons/faHouseFlag';
import { faHouseFlood as farHouseFlood } from '@fortawesome/pro-regular-svg-icons/faHouseFlood';
import { faHouseFloodWater as farHouseFloodWater } from '@fortawesome/pro-regular-svg-icons/faHouseFloodWater';
import { faHouseFloodWaterCircleArrowRight as farHouseFloodWaterCircleArrowRight } from '@fortawesome/pro-regular-svg-icons/faHouseFloodWaterCircleArrowRight';
import { faHouseHeart as farHouseHeart } from '@fortawesome/pro-regular-svg-icons/faHouseHeart';
import { faHouseLaptop as farHouseLaptop } from '@fortawesome/pro-regular-svg-icons/faHouseLaptop';
import { faHouseLeave as farHouseLeave } from '@fortawesome/pro-regular-svg-icons/faHouseLeave';
import { faHouseLock as farHouseLock } from '@fortawesome/pro-regular-svg-icons/faHouseLock';
import { faHouseMedical as farHouseMedical } from '@fortawesome/pro-regular-svg-icons/faHouseMedical';
import { faHouseMedicalCircleCheck as farHouseMedicalCircleCheck } from '@fortawesome/pro-regular-svg-icons/faHouseMedicalCircleCheck';
import { faHouseMedicalCircleExclamation as farHouseMedicalCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faHouseMedicalCircleExclamation';
import { faHouseMedicalCircleXmark as farHouseMedicalCircleXmark } from '@fortawesome/pro-regular-svg-icons/faHouseMedicalCircleXmark';
import { faHouseMedicalFlag as farHouseMedicalFlag } from '@fortawesome/pro-regular-svg-icons/faHouseMedicalFlag';
import { faHouseNight as farHouseNight } from '@fortawesome/pro-regular-svg-icons/faHouseNight';
import { faHousePersonArrive as farHousePersonArrive } from '@fortawesome/pro-regular-svg-icons/faHousePersonArrive';
import { faHousePersonDepart as farHousePersonDepart } from '@fortawesome/pro-regular-svg-icons/faHousePersonDepart';
import { faHousePersonLeave as farHousePersonLeave } from '@fortawesome/pro-regular-svg-icons/faHousePersonLeave';
import { faHousePersonReturn as farHousePersonReturn } from '@fortawesome/pro-regular-svg-icons/faHousePersonReturn';
import { faHouseReturn as farHouseReturn } from '@fortawesome/pro-regular-svg-icons/faHouseReturn';
import { faHouseSignal as farHouseSignal } from '@fortawesome/pro-regular-svg-icons/faHouseSignal';
import { faHouseTree as farHouseTree } from '@fortawesome/pro-regular-svg-icons/faHouseTree';
import { faHouseTsunami as farHouseTsunami } from '@fortawesome/pro-regular-svg-icons/faHouseTsunami';
import { faHouseTurret as farHouseTurret } from '@fortawesome/pro-regular-svg-icons/faHouseTurret';
import { faHouseUser as farHouseUser } from '@fortawesome/pro-regular-svg-icons/faHouseUser';
import { faHouseWater as farHouseWater } from '@fortawesome/pro-regular-svg-icons/faHouseWater';
import { faHouseWindow as farHouseWindow } from '@fortawesome/pro-regular-svg-icons/faHouseWindow';
import { faHouzz as farHouzz } from '@fortawesome/free-brands-svg-icons/faHouzz';
import { faHryvnia as farHryvnia } from '@fortawesome/pro-regular-svg-icons/faHryvnia';
import { faHryvniaSign as farHryvniaSign } from '@fortawesome/pro-regular-svg-icons/faHryvniaSign';
import { faHSquare as farHSquare } from '@fortawesome/pro-regular-svg-icons/faHSquare';
import { faHtml5 as farHtml5 } from '@fortawesome/free-brands-svg-icons/faHtml5';
import { faHubspot as farHubspot } from '@fortawesome/free-brands-svg-icons/faHubspot';
import { faHumidity as farHumidity } from '@fortawesome/pro-regular-svg-icons/faHumidity';
import { faHundredPoints as farHundredPoints } from '@fortawesome/pro-regular-svg-icons/faHundredPoints';
import { faHurricane as farHurricane } from '@fortawesome/pro-regular-svg-icons/faHurricane';
import { faHyphen as farHyphen } from '@fortawesome/pro-regular-svg-icons/faHyphen';
import { faI as farI } from '@fortawesome/pro-regular-svg-icons/faI';
import { faIceCream as farIceCream } from '@fortawesome/pro-regular-svg-icons/faIceCream';
import { faIceSkate as farIceSkate } from '@fortawesome/pro-regular-svg-icons/faIceSkate';
import { faIcicles as farIcicles } from '@fortawesome/pro-regular-svg-icons/faIcicles';
import { faIcons as farIcons } from '@fortawesome/pro-regular-svg-icons/faIcons';
import { faIconsAlt as farIconsAlt } from '@fortawesome/pro-regular-svg-icons/faIconsAlt';
import { faICursor as farICursor } from '@fortawesome/pro-regular-svg-icons/faICursor';
import { faIdBadge as farIdBadge } from '@fortawesome/pro-regular-svg-icons/faIdBadge';
import { faIdCard as farIdCard } from '@fortawesome/pro-regular-svg-icons/faIdCard';
import { faIdCardAlt as farIdCardAlt } from '@fortawesome/pro-regular-svg-icons/faIdCardAlt';
import { faIdCardClip as farIdCardClip } from '@fortawesome/pro-regular-svg-icons/faIdCardClip';
import { faIdeal as farIdeal } from '@fortawesome/free-brands-svg-icons/faIdeal';
import { faIgloo as farIgloo } from '@fortawesome/pro-regular-svg-icons/faIgloo';
import { faIls as farIls } from '@fortawesome/pro-regular-svg-icons/faIls';
import { faImage as farImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faImageLandscape as farImageLandscape } from '@fortawesome/pro-regular-svg-icons/faImageLandscape';
import { faImagePolaroid as farImagePolaroid } from '@fortawesome/pro-regular-svg-icons/faImagePolaroid';
import { faImagePolaroidUser as farImagePolaroidUser } from '@fortawesome/pro-regular-svg-icons/faImagePolaroidUser';
import { faImagePortrait as farImagePortrait } from '@fortawesome/pro-regular-svg-icons/faImagePortrait';
import { faImages as farImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { faImageSlash as farImageSlash } from '@fortawesome/pro-regular-svg-icons/faImageSlash';
import { faImagesUser as farImagesUser } from '@fortawesome/pro-regular-svg-icons/faImagesUser';
import { faImageUser as farImageUser } from '@fortawesome/pro-regular-svg-icons/faImageUser';
import { faImdb as farImdb } from '@fortawesome/free-brands-svg-icons/faImdb';
import { faInbox as farInbox } from '@fortawesome/pro-regular-svg-icons/faInbox';
import { faInboxArrowDown as farInboxArrowDown } from '@fortawesome/pro-regular-svg-icons/faInboxArrowDown';
import { faInboxArrowUp as farInboxArrowUp } from '@fortawesome/pro-regular-svg-icons/faInboxArrowUp';
import { faInboxes as farInboxes } from '@fortawesome/pro-regular-svg-icons/faInboxes';
import { faInboxFull as farInboxFull } from '@fortawesome/pro-regular-svg-icons/faInboxFull';
import { faInboxIn as farInboxIn } from '@fortawesome/pro-regular-svg-icons/faInboxIn';
import { faInboxOut as farInboxOut } from '@fortawesome/pro-regular-svg-icons/faInboxOut';
import { faIndent as farIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faIndianRupee as farIndianRupee } from '@fortawesome/pro-regular-svg-icons/faIndianRupee';
import { faIndianRupeeSign as farIndianRupeeSign } from '@fortawesome/pro-regular-svg-icons/faIndianRupeeSign';
import { faIndustry as farIndustry } from '@fortawesome/pro-regular-svg-icons/faIndustry';
import { faIndustryAlt as farIndustryAlt } from '@fortawesome/pro-regular-svg-icons/faIndustryAlt';
import { faIndustryWindows as farIndustryWindows } from '@fortawesome/pro-regular-svg-icons/faIndustryWindows';
import { faInfinity as farInfinity } from '@fortawesome/pro-regular-svg-icons/faInfinity';
import { faInfo as farInfo } from '@fortawesome/pro-regular-svg-icons/faInfo';
import { faInfoCircle as farInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faInfoSquare as farInfoSquare } from '@fortawesome/pro-regular-svg-icons/faInfoSquare';
import { faInhaler as farInhaler } from '@fortawesome/pro-regular-svg-icons/faInhaler';
import { faInnosoft as farInnosoft } from '@fortawesome/free-brands-svg-icons/faInnosoft';
import { faInputNumeric as farInputNumeric } from '@fortawesome/pro-regular-svg-icons/faInputNumeric';
import { faInputPipe as farInputPipe } from '@fortawesome/pro-regular-svg-icons/faInputPipe';
import { faInputText as farInputText } from '@fortawesome/pro-regular-svg-icons/faInputText';
import { faInr as farInr } from '@fortawesome/pro-regular-svg-icons/faInr';
import { faInstagram as farInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faInstagramSquare as farInstagramSquare } from '@fortawesome/free-brands-svg-icons/faInstagramSquare';
import { faInstalod as farInstalod } from '@fortawesome/free-brands-svg-icons/faInstalod';
import { faInstitution as farInstitution } from '@fortawesome/pro-regular-svg-icons/faInstitution';
import { faIntegral as farIntegral } from '@fortawesome/pro-regular-svg-icons/faIntegral';
import { faIntercom as farIntercom } from '@fortawesome/free-brands-svg-icons/faIntercom';
import { faInternetExplorer as farInternetExplorer } from '@fortawesome/free-brands-svg-icons/faInternetExplorer';
import { faInterrobang as farInterrobang } from '@fortawesome/pro-regular-svg-icons/faInterrobang';
import { faIntersection as farIntersection } from '@fortawesome/pro-regular-svg-icons/faIntersection';
import { faInventory as farInventory } from '@fortawesome/pro-regular-svg-icons/faInventory';
import { faInvision as farInvision } from '@fortawesome/free-brands-svg-icons/faInvision';
import { faIoxhost as farIoxhost } from '@fortawesome/free-brands-svg-icons/faIoxhost';
import { faIslandTreePalm as farIslandTreePalm } from '@fortawesome/pro-regular-svg-icons/faIslandTreePalm';
import { faIslandTropical as farIslandTropical } from '@fortawesome/pro-regular-svg-icons/faIslandTropical';
import { faItalic as farItalic } from '@fortawesome/pro-regular-svg-icons/faItalic';
import { faItchIo as farItchIo } from '@fortawesome/free-brands-svg-icons/faItchIo';
import { faItunes as farItunes } from '@fortawesome/free-brands-svg-icons/faItunes';
import { faItunesNote as farItunesNote } from '@fortawesome/free-brands-svg-icons/faItunesNote';
import { faJ as farJ } from '@fortawesome/pro-regular-svg-icons/faJ';
import { faJackOLantern as farJackOLantern } from '@fortawesome/pro-regular-svg-icons/faJackOLantern';
import { faJar as farJar } from '@fortawesome/pro-regular-svg-icons/faJar';
import { faJarWheat as farJarWheat } from '@fortawesome/pro-regular-svg-icons/faJarWheat';
import { faJava as farJava } from '@fortawesome/free-brands-svg-icons/faJava';
import { faJedi as farJedi } from '@fortawesome/pro-regular-svg-icons/faJedi';
import { faJediOrder as farJediOrder } from '@fortawesome/free-brands-svg-icons/faJediOrder';
import { faJenkins as farJenkins } from '@fortawesome/free-brands-svg-icons/faJenkins';
import { faJetFighter as farJetFighter } from '@fortawesome/pro-regular-svg-icons/faJetFighter';
import { faJetFighterUp as farJetFighterUp } from '@fortawesome/pro-regular-svg-icons/faJetFighterUp';
import { faJira as farJira } from '@fortawesome/free-brands-svg-icons/faJira';
import { faJoget as farJoget } from '@fortawesome/free-brands-svg-icons/faJoget';
import { faJoint as farJoint } from '@fortawesome/pro-regular-svg-icons/faJoint';
import { faJoomla as farJoomla } from '@fortawesome/free-brands-svg-icons/faJoomla';
import { faJournalWhills as farJournalWhills } from '@fortawesome/pro-regular-svg-icons/faJournalWhills';
import { faJoystick as farJoystick } from '@fortawesome/pro-regular-svg-icons/faJoystick';
import { faJpy as farJpy } from '@fortawesome/pro-regular-svg-icons/faJpy';
import { faJs as farJs } from '@fortawesome/free-brands-svg-icons/faJs';
import { faJsfiddle as farJsfiddle } from '@fortawesome/free-brands-svg-icons/faJsfiddle';
import { faJsSquare as farJsSquare } from '@fortawesome/free-brands-svg-icons/faJsSquare';
import { faJug as farJug } from '@fortawesome/pro-regular-svg-icons/faJug';
import { faJugBottle as farJugBottle } from '@fortawesome/pro-regular-svg-icons/faJugBottle';
import { faJugDetergent as farJugDetergent } from '@fortawesome/pro-regular-svg-icons/faJugDetergent';
import { faK as farK } from '@fortawesome/pro-regular-svg-icons/faK';
import { faKaaba as farKaaba } from '@fortawesome/pro-regular-svg-icons/faKaaba';
import { faKaggle as farKaggle } from '@fortawesome/free-brands-svg-icons/faKaggle';
import { faKazoo as farKazoo } from '@fortawesome/pro-regular-svg-icons/faKazoo';
import { faKerning as farKerning } from '@fortawesome/pro-regular-svg-icons/faKerning';
import { faKey as farKey } from '@fortawesome/pro-regular-svg-icons/faKey';
import { faKeybase as farKeybase } from '@fortawesome/free-brands-svg-icons/faKeybase';
import { faKeyboard as farKeyboard } from '@fortawesome/pro-regular-svg-icons/faKeyboard';
import { faKeyboardBrightness as farKeyboardBrightness } from '@fortawesome/pro-regular-svg-icons/faKeyboardBrightness';
import { faKeyboardBrightnessLow as farKeyboardBrightnessLow } from '@fortawesome/pro-regular-svg-icons/faKeyboardBrightnessLow';
import { faKeyboardDown as farKeyboardDown } from '@fortawesome/pro-regular-svg-icons/faKeyboardDown';
import { faKeyboardLeft as farKeyboardLeft } from '@fortawesome/pro-regular-svg-icons/faKeyboardLeft';
import { faKeycdn as farKeycdn } from '@fortawesome/free-brands-svg-icons/faKeycdn';
import { faKeynote as farKeynote } from '@fortawesome/pro-regular-svg-icons/faKeynote';
import { faKeySkeleton as farKeySkeleton } from '@fortawesome/pro-regular-svg-icons/faKeySkeleton';
import { faKeySkeletonLeftRight as farKeySkeletonLeftRight } from '@fortawesome/pro-regular-svg-icons/faKeySkeletonLeftRight';
import { faKhanda as farKhanda } from '@fortawesome/pro-regular-svg-icons/faKhanda';
import { faKickstarter as farKickstarter } from '@fortawesome/free-brands-svg-icons/faKickstarter';
import { faKickstarterK as farKickstarterK } from '@fortawesome/free-brands-svg-icons/faKickstarterK';
import { faKidneys as farKidneys } from '@fortawesome/pro-regular-svg-icons/faKidneys';
import { faKipSign as farKipSign } from '@fortawesome/pro-regular-svg-icons/faKipSign';
import { faKiss as farKiss } from '@fortawesome/pro-regular-svg-icons/faKiss';
import { faKissBeam as farKissBeam } from '@fortawesome/pro-regular-svg-icons/faKissBeam';
import { faKissWinkHeart as farKissWinkHeart } from '@fortawesome/pro-regular-svg-icons/faKissWinkHeart';
import { faKitchenSet as farKitchenSet } from '@fortawesome/pro-regular-svg-icons/faKitchenSet';
import { faKite as farKite } from '@fortawesome/pro-regular-svg-icons/faKite';
import { faKitMedical as farKitMedical } from '@fortawesome/pro-regular-svg-icons/faKitMedical';
import { faKiwiBird as farKiwiBird } from '@fortawesome/pro-regular-svg-icons/faKiwiBird';
import { faKiwiFruit as farKiwiFruit } from '@fortawesome/pro-regular-svg-icons/faKiwiFruit';
import { faKnife as farKnife } from '@fortawesome/pro-regular-svg-icons/faKnife';
import { faKnifeKitchen as farKnifeKitchen } from '@fortawesome/pro-regular-svg-icons/faKnifeKitchen';
import { faKorvue as farKorvue } from '@fortawesome/free-brands-svg-icons/faKorvue';
import { faKrw as farKrw } from '@fortawesome/pro-regular-svg-icons/faKrw';
import { faL as farL } from '@fortawesome/pro-regular-svg-icons/faL';
import { faLacrosseStick as farLacrosseStick } from '@fortawesome/pro-regular-svg-icons/faLacrosseStick';
import { faLacrosseStickBall as farLacrosseStickBall } from '@fortawesome/pro-regular-svg-icons/faLacrosseStickBall';
import { faLadderWater as farLadderWater } from '@fortawesome/pro-regular-svg-icons/faLadderWater';
import { faLambda as farLambda } from '@fortawesome/pro-regular-svg-icons/faLambda';
import { faLamp as farLamp } from '@fortawesome/pro-regular-svg-icons/faLamp';
import { faLampDesk as farLampDesk } from '@fortawesome/pro-regular-svg-icons/faLampDesk';
import { faLampFloor as farLampFloor } from '@fortawesome/pro-regular-svg-icons/faLampFloor';
import { faLampStreet as farLampStreet } from '@fortawesome/pro-regular-svg-icons/faLampStreet';
import { faLandmark as farLandmark } from '@fortawesome/pro-regular-svg-icons/faLandmark';
import { faLandmarkAlt as farLandmarkAlt } from '@fortawesome/pro-regular-svg-icons/faLandmarkAlt';
import { faLandmarkDome as farLandmarkDome } from '@fortawesome/pro-regular-svg-icons/faLandmarkDome';
import { faLandmarkFlag as farLandmarkFlag } from '@fortawesome/pro-regular-svg-icons/faLandmarkFlag';
import { faLandmarkMagnifyingGlass as farLandmarkMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faLandmarkMagnifyingGlass';
import { faLandMineOn as farLandMineOn } from '@fortawesome/pro-regular-svg-icons/faLandMineOn';
import { faLandscape as farLandscape } from '@fortawesome/pro-regular-svg-icons/faLandscape';
import { faLanguage as farLanguage } from '@fortawesome/pro-regular-svg-icons/faLanguage';
import { faLaptop as farLaptop } from '@fortawesome/pro-regular-svg-icons/faLaptop';
import { faLaptopArrowDown as farLaptopArrowDown } from '@fortawesome/pro-regular-svg-icons/faLaptopArrowDown';
import { faLaptopBinary as farLaptopBinary } from '@fortawesome/pro-regular-svg-icons/faLaptopBinary';
import { faLaptopCode as farLaptopCode } from '@fortawesome/pro-regular-svg-icons/faLaptopCode';
import { faLaptopFile as farLaptopFile } from '@fortawesome/pro-regular-svg-icons/faLaptopFile';
import { faLaptopHouse as farLaptopHouse } from '@fortawesome/pro-regular-svg-icons/faLaptopHouse';
import { faLaptopMedical as farLaptopMedical } from '@fortawesome/pro-regular-svg-icons/faLaptopMedical';
import { faLaptopMobile as farLaptopMobile } from '@fortawesome/pro-regular-svg-icons/faLaptopMobile';
import { faLaptopSlash as farLaptopSlash } from '@fortawesome/pro-regular-svg-icons/faLaptopSlash';
import { faLaravel as farLaravel } from '@fortawesome/free-brands-svg-icons/faLaravel';
import { faLariSign as farLariSign } from '@fortawesome/pro-regular-svg-icons/faLariSign';
import { faLasso as farLasso } from '@fortawesome/pro-regular-svg-icons/faLasso';
import { faLassoSparkles as farLassoSparkles } from '@fortawesome/pro-regular-svg-icons/faLassoSparkles';
import { faLastfm as farLastfm } from '@fortawesome/free-brands-svg-icons/faLastfm';
import { faLastfmSquare as farLastfmSquare } from '@fortawesome/free-brands-svg-icons/faLastfmSquare';
import { faLaugh as farLaugh } from '@fortawesome/pro-regular-svg-icons/faLaugh';
import { faLaughBeam as farLaughBeam } from '@fortawesome/pro-regular-svg-icons/faLaughBeam';
import { faLaughSquint as farLaughSquint } from '@fortawesome/pro-regular-svg-icons/faLaughSquint';
import { faLaughWink as farLaughWink } from '@fortawesome/pro-regular-svg-icons/faLaughWink';
import { faLayerGroup as farLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import { faLayerGroupMinus as farLayerGroupMinus } from '@fortawesome/pro-regular-svg-icons/faLayerGroupMinus';
import { faLayerGroupPlus as farLayerGroupPlus } from '@fortawesome/pro-regular-svg-icons/faLayerGroupPlus';
import { faLayerMinus as farLayerMinus } from '@fortawesome/pro-regular-svg-icons/faLayerMinus';
import { faLayerPlus as farLayerPlus } from '@fortawesome/pro-regular-svg-icons/faLayerPlus';
import { faLeaf as farLeaf } from '@fortawesome/pro-regular-svg-icons/faLeaf';
import { faLeafHeart as farLeafHeart } from '@fortawesome/pro-regular-svg-icons/faLeafHeart';
import { faLeafMaple as farLeafMaple } from '@fortawesome/pro-regular-svg-icons/faLeafMaple';
import { faLeafOak as farLeafOak } from '@fortawesome/pro-regular-svg-icons/faLeafOak';
import { faLeafyGreen as farLeafyGreen } from '@fortawesome/pro-regular-svg-icons/faLeafyGreen';
import { faLeanpub as farLeanpub } from '@fortawesome/free-brands-svg-icons/faLeanpub';
import { faLeft as farLeft } from '@fortawesome/pro-regular-svg-icons/faLeft';
import { faLeftFromLine as farLeftFromLine } from '@fortawesome/pro-regular-svg-icons/faLeftFromLine';
import { faLeftLong as farLeftLong } from '@fortawesome/pro-regular-svg-icons/faLeftLong';
import { faLeftLongToLine as farLeftLongToLine } from '@fortawesome/pro-regular-svg-icons/faLeftLongToLine';
import { faLeftRight as farLeftRight } from '@fortawesome/pro-regular-svg-icons/faLeftRight';
import { faLeftToLine as farLeftToLine } from '@fortawesome/pro-regular-svg-icons/faLeftToLine';
import { faLegal as farLegal } from '@fortawesome/pro-regular-svg-icons/faLegal';
import { faLemon as farLemon } from '@fortawesome/pro-regular-svg-icons/faLemon';
import { faLess as farLess } from '@fortawesome/free-brands-svg-icons/faLess';
import { faLessThan as farLessThan } from '@fortawesome/pro-regular-svg-icons/faLessThan';
import { faLessThanEqual as farLessThanEqual } from '@fortawesome/pro-regular-svg-icons/faLessThanEqual';
import { faLetterboxd as farLetterboxd } from '@fortawesome/free-brands-svg-icons/faLetterboxd';
import { faLevelDown as farLevelDown } from '@fortawesome/pro-regular-svg-icons/faLevelDown';
import { faLevelDownAlt as farLevelDownAlt } from '@fortawesome/pro-regular-svg-icons/faLevelDownAlt';
import { faLevelUp as farLevelUp } from '@fortawesome/pro-regular-svg-icons/faLevelUp';
import { faLevelUpAlt as farLevelUpAlt } from '@fortawesome/pro-regular-svg-icons/faLevelUpAlt';
import { faLifeRing as farLifeRing } from '@fortawesome/pro-regular-svg-icons/faLifeRing';
import { faLightbulb as farLightbulb } from '@fortawesome/pro-regular-svg-icons/faLightbulb';
import { faLightbulbCfl as farLightbulbCfl } from '@fortawesome/pro-regular-svg-icons/faLightbulbCfl';
import { faLightbulbCflOn as farLightbulbCflOn } from '@fortawesome/pro-regular-svg-icons/faLightbulbCflOn';
import { faLightbulbDollar as farLightbulbDollar } from '@fortawesome/pro-regular-svg-icons/faLightbulbDollar';
import { faLightbulbExclamation as farLightbulbExclamation } from '@fortawesome/pro-regular-svg-icons/faLightbulbExclamation';
import { faLightbulbExclamationOn as farLightbulbExclamationOn } from '@fortawesome/pro-regular-svg-icons/faLightbulbExclamationOn';
import { faLightbulbGear as farLightbulbGear } from '@fortawesome/pro-regular-svg-icons/faLightbulbGear';
import { faLightbulbOn as farLightbulbOn } from '@fortawesome/pro-regular-svg-icons/faLightbulbOn';
import { faLightbulbSlash as farLightbulbSlash } from '@fortawesome/pro-regular-svg-icons/faLightbulbSlash';
import { faLightCeiling as farLightCeiling } from '@fortawesome/pro-regular-svg-icons/faLightCeiling';
import { faLightEmergency as farLightEmergency } from '@fortawesome/pro-regular-svg-icons/faLightEmergency';
import { faLightEmergencyOn as farLightEmergencyOn } from '@fortawesome/pro-regular-svg-icons/faLightEmergencyOn';
import { faLighthouse as farLighthouse } from '@fortawesome/pro-regular-svg-icons/faLighthouse';
import { faLightsHoliday as farLightsHoliday } from '@fortawesome/pro-regular-svg-icons/faLightsHoliday';
import { faLightSwitch as farLightSwitch } from '@fortawesome/pro-regular-svg-icons/faLightSwitch';
import { faLightSwitchOff as farLightSwitchOff } from '@fortawesome/pro-regular-svg-icons/faLightSwitchOff';
import { faLightSwitchOn as farLightSwitchOn } from '@fortawesome/pro-regular-svg-icons/faLightSwitchOn';
import { faLine as farLine } from '@fortawesome/free-brands-svg-icons/faLine';
import { faLineChart as farLineChart } from '@fortawesome/pro-regular-svg-icons/faLineChart';
import { faLineColumns as farLineColumns } from '@fortawesome/pro-regular-svg-icons/faLineColumns';
import { faLineHeight as farLineHeight } from '@fortawesome/pro-regular-svg-icons/faLineHeight';
import { faLinesLeaning as farLinesLeaning } from '@fortawesome/pro-regular-svg-icons/faLinesLeaning';
import { faLink as farLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faLinkedin as farLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faLinkedinIn as farLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faLinkHorizontal as farLinkHorizontal } from '@fortawesome/pro-regular-svg-icons/faLinkHorizontal';
import { faLinkHorizontalSlash as farLinkHorizontalSlash } from '@fortawesome/pro-regular-svg-icons/faLinkHorizontalSlash';
import { faLinkSimple as farLinkSimple } from '@fortawesome/pro-regular-svg-icons/faLinkSimple';
import { faLinkSimpleSlash as farLinkSimpleSlash } from '@fortawesome/pro-regular-svg-icons/faLinkSimpleSlash';
import { faLinkSlash as farLinkSlash } from '@fortawesome/pro-regular-svg-icons/faLinkSlash';
import { faLinode as farLinode } from '@fortawesome/free-brands-svg-icons/faLinode';
import { faLinux as farLinux } from '@fortawesome/free-brands-svg-icons/faLinux';
import { faLips as farLips } from '@fortawesome/pro-regular-svg-icons/faLips';
import { faLiraSign as farLiraSign } from '@fortawesome/pro-regular-svg-icons/faLiraSign';
import { faList as farList } from '@fortawesome/pro-regular-svg-icons/faList';
import { faList12 as farList12 } from '@fortawesome/pro-regular-svg-icons/faList12';
import { faListAlt as farListAlt } from '@fortawesome/pro-regular-svg-icons/faListAlt';
import { faListCheck as farListCheck } from '@fortawesome/pro-regular-svg-icons/faListCheck';
import { faListDots as farListDots } from '@fortawesome/pro-regular-svg-icons/faListDots';
import { faListDropdown as farListDropdown } from '@fortawesome/pro-regular-svg-icons/faListDropdown';
import { faListMusic as farListMusic } from '@fortawesome/pro-regular-svg-icons/faListMusic';
import { faListNumeric as farListNumeric } from '@fortawesome/pro-regular-svg-icons/faListNumeric';
import { faListOl as farListOl } from '@fortawesome/pro-regular-svg-icons/faListOl';
import { faListRadio as farListRadio } from '@fortawesome/pro-regular-svg-icons/faListRadio';
import { faListSquares as farListSquares } from '@fortawesome/pro-regular-svg-icons/faListSquares';
import { faListTimeline as farListTimeline } from '@fortawesome/pro-regular-svg-icons/faListTimeline';
import { faListTree as farListTree } from '@fortawesome/pro-regular-svg-icons/faListTree';
import { faListUl as farListUl } from '@fortawesome/pro-regular-svg-icons/faListUl';
import { faLitecoinSign as farLitecoinSign } from '@fortawesome/pro-regular-svg-icons/faLitecoinSign';
import { faLoader as farLoader } from '@fortawesome/pro-regular-svg-icons/faLoader';
import { faLobster as farLobster } from '@fortawesome/pro-regular-svg-icons/faLobster';
import { faLocation as farLocation } from '@fortawesome/pro-regular-svg-icons/faLocation';
import { faLocationArrow as farLocationArrow } from '@fortawesome/pro-regular-svg-icons/faLocationArrow';
import { faLocationArrowUp as farLocationArrowUp } from '@fortawesome/pro-regular-svg-icons/faLocationArrowUp';
import { faLocationCheck as farLocationCheck } from '@fortawesome/pro-regular-svg-icons/faLocationCheck';
import { faLocationCircle as farLocationCircle } from '@fortawesome/pro-regular-svg-icons/faLocationCircle';
import { faLocationCrosshairs as farLocationCrosshairs } from '@fortawesome/pro-regular-svg-icons/faLocationCrosshairs';
import { faLocationCrosshairsSlash as farLocationCrosshairsSlash } from '@fortawesome/pro-regular-svg-icons/faLocationCrosshairsSlash';
import { faLocationDot as farLocationDot } from '@fortawesome/pro-regular-svg-icons/faLocationDot';
import { faLocationDotSlash as farLocationDotSlash } from '@fortawesome/pro-regular-svg-icons/faLocationDotSlash';
import { faLocationExclamation as farLocationExclamation } from '@fortawesome/pro-regular-svg-icons/faLocationExclamation';
import { faLocationMinus as farLocationMinus } from '@fortawesome/pro-regular-svg-icons/faLocationMinus';
import { faLocationPen as farLocationPen } from '@fortawesome/pro-regular-svg-icons/faLocationPen';
import { faLocationPin as farLocationPin } from '@fortawesome/pro-regular-svg-icons/faLocationPin';
import { faLocationPinLock as farLocationPinLock } from '@fortawesome/pro-regular-svg-icons/faLocationPinLock';
import { faLocationPinSlash as farLocationPinSlash } from '@fortawesome/pro-regular-svg-icons/faLocationPinSlash';
import { faLocationPlus as farLocationPlus } from '@fortawesome/pro-regular-svg-icons/faLocationPlus';
import { faLocationQuestion as farLocationQuestion } from '@fortawesome/pro-regular-svg-icons/faLocationQuestion';
import { faLocationSlash as farLocationSlash } from '@fortawesome/pro-regular-svg-icons/faLocationSlash';
import { faLocationSmile as farLocationSmile } from '@fortawesome/pro-regular-svg-icons/faLocationSmile';
import { faLocationXmark as farLocationXmark } from '@fortawesome/pro-regular-svg-icons/faLocationXmark';
import { faLock as farLock } from '@fortawesome/pro-regular-svg-icons/faLock';
import { faLockA as farLockA } from '@fortawesome/pro-regular-svg-icons/faLockA';
import { faLockAlt as farLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt';
import { faLockHashtag as farLockHashtag } from '@fortawesome/pro-regular-svg-icons/faLockHashtag';
import { faLockKeyhole as farLockKeyhole } from '@fortawesome/pro-regular-svg-icons/faLockKeyhole';
import { faLockKeyholeOpen as farLockKeyholeOpen } from '@fortawesome/pro-regular-svg-icons/faLockKeyholeOpen';
import { faLockOpen as farLockOpen } from '@fortawesome/pro-regular-svg-icons/faLockOpen';
import { faLockOpenAlt as farLockOpenAlt } from '@fortawesome/pro-regular-svg-icons/faLockOpenAlt';
import { faLocust as farLocust } from '@fortawesome/pro-regular-svg-icons/faLocust';
import { faLollipop as farLollipop } from '@fortawesome/pro-regular-svg-icons/faLollipop';
import { faLollypop as farLollypop } from '@fortawesome/pro-regular-svg-icons/faLollypop';
import { faLongArrowAltDown as farLongArrowAltDown } from '@fortawesome/pro-regular-svg-icons/faLongArrowAltDown';
import { faLongArrowAltLeft as farLongArrowAltLeft } from '@fortawesome/pro-regular-svg-icons/faLongArrowAltLeft';
import { faLongArrowAltRight as farLongArrowAltRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowAltRight';
import { faLongArrowAltUp as farLongArrowAltUp } from '@fortawesome/pro-regular-svg-icons/faLongArrowAltUp';
import { faLongArrowDown as farLongArrowDown } from '@fortawesome/pro-regular-svg-icons/faLongArrowDown';
import { faLongArrowLeft as farLongArrowLeft } from '@fortawesome/pro-regular-svg-icons/faLongArrowLeft';
import { faLongArrowRight as farLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight';
import { faLongArrowUp as farLongArrowUp } from '@fortawesome/pro-regular-svg-icons/faLongArrowUp';
import { faLoveseat as farLoveseat } from '@fortawesome/pro-regular-svg-icons/faLoveseat';
import { faLowVision as farLowVision } from '@fortawesome/pro-regular-svg-icons/faLowVision';
import { faLuchador as farLuchador } from '@fortawesome/pro-regular-svg-icons/faLuchador';
import { faLuchadorMask as farLuchadorMask } from '@fortawesome/pro-regular-svg-icons/faLuchadorMask';
import { faLuggageCart as farLuggageCart } from '@fortawesome/pro-regular-svg-icons/faLuggageCart';
import { faLungs as farLungs } from '@fortawesome/pro-regular-svg-icons/faLungs';
import { faLungsVirus as farLungsVirus } from '@fortawesome/pro-regular-svg-icons/faLungsVirus';
import { faLyft as farLyft } from '@fortawesome/free-brands-svg-icons/faLyft';
import { faM as farM } from '@fortawesome/pro-regular-svg-icons/faM';
import { faMace as farMace } from '@fortawesome/pro-regular-svg-icons/faMace';
import { faMagento as farMagento } from '@fortawesome/free-brands-svg-icons/faMagento';
import { faMagic as farMagic } from '@fortawesome/pro-regular-svg-icons/faMagic';
import { faMagicWandSparkles as farMagicWandSparkles } from '@fortawesome/pro-regular-svg-icons/faMagicWandSparkles';
import { faMagnet as farMagnet } from '@fortawesome/pro-regular-svg-icons/faMagnet';
import { faMagnifyingGlass as farMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faMagnifyingGlassArrowRight as farMagnifyingGlassArrowRight } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassArrowRight';
import { faMagnifyingGlassArrowsRotate as farMagnifyingGlassArrowsRotate } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassArrowsRotate';
import { faMagnifyingGlassChart as farMagnifyingGlassChart } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassChart';
import { faMagnifyingGlassDollar as farMagnifyingGlassDollar } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassDollar';
import { faMagnifyingGlassLocation as farMagnifyingGlassLocation } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassLocation';
import { faMagnifyingGlassMinus as farMagnifyingGlassMinus } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassMinus';
import { faMagnifyingGlassMusic as farMagnifyingGlassMusic } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassMusic';
import { faMagnifyingGlassPlay as farMagnifyingGlassPlay } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassPlay';
import { faMagnifyingGlassPlus as farMagnifyingGlassPlus } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassPlus';
import { faMagnifyingGlassWaveform as farMagnifyingGlassWaveform } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassWaveform';
import { faMailbox as farMailbox } from '@fortawesome/pro-regular-svg-icons/faMailbox';
import { faMailboxFlagUp as farMailboxFlagUp } from '@fortawesome/pro-regular-svg-icons/faMailboxFlagUp';
import { faMailBulk as farMailBulk } from '@fortawesome/pro-regular-svg-icons/faMailBulk';
import { faMailchimp as farMailchimp } from '@fortawesome/free-brands-svg-icons/faMailchimp';
import { faMailForward as farMailForward } from '@fortawesome/pro-regular-svg-icons/faMailForward';
import { faMailReply as farMailReply } from '@fortawesome/pro-regular-svg-icons/faMailReply';
import { faMailReplyAll as farMailReplyAll } from '@fortawesome/pro-regular-svg-icons/faMailReplyAll';
import { faMakiRoll as farMakiRoll } from '@fortawesome/pro-regular-svg-icons/faMakiRoll';
import { faMakizushi as farMakizushi } from '@fortawesome/pro-regular-svg-icons/faMakizushi';
import { faMale as farMale } from '@fortawesome/pro-regular-svg-icons/faMale';
import { faManatSign as farManatSign } from '@fortawesome/pro-regular-svg-icons/faManatSign';
import { faMandalorian as farMandalorian } from '@fortawesome/free-brands-svg-icons/faMandalorian';
import { faMandolin as farMandolin } from '@fortawesome/pro-regular-svg-icons/faMandolin';
import { faMango as farMango } from '@fortawesome/pro-regular-svg-icons/faMango';
import { faManhole as farManhole } from '@fortawesome/pro-regular-svg-icons/faManhole';
import { faMap as farMap } from '@fortawesome/pro-regular-svg-icons/faMap';
import { faMapLocation as farMapLocation } from '@fortawesome/pro-regular-svg-icons/faMapLocation';
import { faMapLocationDot as farMapLocationDot } from '@fortawesome/pro-regular-svg-icons/faMapLocationDot';
import { faMapMarked as farMapMarked } from '@fortawesome/pro-regular-svg-icons/faMapMarked';
import { faMapMarkedAlt as farMapMarkedAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkedAlt';
import { faMapMarker as farMapMarker } from '@fortawesome/pro-regular-svg-icons/faMapMarker';
import { faMapMarkerAlt as farMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
import { faMapMarkerAltSlash as farMapMarkerAltSlash } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAltSlash';
import { faMapMarkerCheck as farMapMarkerCheck } from '@fortawesome/pro-regular-svg-icons/faMapMarkerCheck';
import { faMapMarkerEdit as farMapMarkerEdit } from '@fortawesome/pro-regular-svg-icons/faMapMarkerEdit';
import { faMapMarkerExclamation as farMapMarkerExclamation } from '@fortawesome/pro-regular-svg-icons/faMapMarkerExclamation';
import { faMapMarkerMinus as farMapMarkerMinus } from '@fortawesome/pro-regular-svg-icons/faMapMarkerMinus';
import { faMapMarkerPlus as farMapMarkerPlus } from '@fortawesome/pro-regular-svg-icons/faMapMarkerPlus';
import { faMapMarkerQuestion as farMapMarkerQuestion } from '@fortawesome/pro-regular-svg-icons/faMapMarkerQuestion';
import { faMapMarkerSlash as farMapMarkerSlash } from '@fortawesome/pro-regular-svg-icons/faMapMarkerSlash';
import { faMapMarkerSmile as farMapMarkerSmile } from '@fortawesome/pro-regular-svg-icons/faMapMarkerSmile';
import { faMapMarkerTimes as farMapMarkerTimes } from '@fortawesome/pro-regular-svg-icons/faMapMarkerTimes';
import { faMapMarkerXmark as farMapMarkerXmark } from '@fortawesome/pro-regular-svg-icons/faMapMarkerXmark';
import { faMapPin as farMapPin } from '@fortawesome/pro-regular-svg-icons/faMapPin';
import { faMapSigns as farMapSigns } from '@fortawesome/pro-regular-svg-icons/faMapSigns';
import { faMarkdown as farMarkdown } from '@fortawesome/free-brands-svg-icons/faMarkdown';
import { faMarker as farMarker } from '@fortawesome/pro-regular-svg-icons/faMarker';
import { faMars as farMars } from '@fortawesome/pro-regular-svg-icons/faMars';
import { faMarsAndVenus as farMarsAndVenus } from '@fortawesome/pro-regular-svg-icons/faMarsAndVenus';
import { faMarsAndVenusBurst as farMarsAndVenusBurst } from '@fortawesome/pro-regular-svg-icons/faMarsAndVenusBurst';
import { faMarsDouble as farMarsDouble } from '@fortawesome/pro-regular-svg-icons/faMarsDouble';
import { faMarsStroke as farMarsStroke } from '@fortawesome/pro-regular-svg-icons/faMarsStroke';
import { faMarsStrokeH as farMarsStrokeH } from '@fortawesome/pro-regular-svg-icons/faMarsStrokeH';
import { faMarsStrokeRight as farMarsStrokeRight } from '@fortawesome/pro-regular-svg-icons/faMarsStrokeRight';
import { faMarsStrokeUp as farMarsStrokeUp } from '@fortawesome/pro-regular-svg-icons/faMarsStrokeUp';
import { faMarsStrokeV as farMarsStrokeV } from '@fortawesome/pro-regular-svg-icons/faMarsStrokeV';
import { faMartiniGlass as farMartiniGlass } from '@fortawesome/pro-regular-svg-icons/faMartiniGlass';
import { faMartiniGlassCitrus as farMartiniGlassCitrus } from '@fortawesome/pro-regular-svg-icons/faMartiniGlassCitrus';
import { faMartiniGlassEmpty as farMartiniGlassEmpty } from '@fortawesome/pro-regular-svg-icons/faMartiniGlassEmpty';
import { faMask as farMask } from '@fortawesome/pro-regular-svg-icons/faMask';
import { faMaskFace as farMaskFace } from '@fortawesome/pro-regular-svg-icons/faMaskFace';
import { faMaskLuchador as farMaskLuchador } from '@fortawesome/pro-regular-svg-icons/faMaskLuchador';
import { faMaskSnorkel as farMaskSnorkel } from '@fortawesome/pro-regular-svg-icons/faMaskSnorkel';
import { faMasksTheater as farMasksTheater } from '@fortawesome/pro-regular-svg-icons/faMasksTheater';
import { faMaskVentilator as farMaskVentilator } from '@fortawesome/pro-regular-svg-icons/faMaskVentilator';
import { faMastodon as farMastodon } from '@fortawesome/free-brands-svg-icons/faMastodon';
import { faMattressPillow as farMattressPillow } from '@fortawesome/pro-regular-svg-icons/faMattressPillow';
import { faMaxcdn as farMaxcdn } from '@fortawesome/free-brands-svg-icons/faMaxcdn';
import { faMaximize as farMaximize } from '@fortawesome/pro-regular-svg-icons/faMaximize';
import { faMdb as farMdb } from '@fortawesome/free-brands-svg-icons/faMdb';
import { faMeat as farMeat } from '@fortawesome/pro-regular-svg-icons/faMeat';
import { faMedal as farMedal } from '@fortawesome/pro-regular-svg-icons/faMedal';
import { faMedapps as farMedapps } from '@fortawesome/free-brands-svg-icons/faMedapps';
import { faMedium as farMedium } from '@fortawesome/free-brands-svg-icons/faMedium';
import { faMediumM as farMediumM } from '@fortawesome/free-brands-svg-icons/faMediumM';
import { faMedkit as farMedkit } from '@fortawesome/pro-regular-svg-icons/faMedkit';
import { faMedrt as farMedrt } from '@fortawesome/free-brands-svg-icons/faMedrt';
import { faMeetup as farMeetup } from '@fortawesome/free-brands-svg-icons/faMeetup';
import { faMegaphone as farMegaphone } from '@fortawesome/pro-regular-svg-icons/faMegaphone';
import { faMegaport as farMegaport } from '@fortawesome/free-brands-svg-icons/faMegaport';
import { faMeh as farMeh } from '@fortawesome/pro-regular-svg-icons/faMeh';
import { faMehBlank as farMehBlank } from '@fortawesome/pro-regular-svg-icons/faMehBlank';
import { faMehRollingEyes as farMehRollingEyes } from '@fortawesome/pro-regular-svg-icons/faMehRollingEyes';
import { faMelon as farMelon } from '@fortawesome/pro-regular-svg-icons/faMelon';
import { faMelonSlice as farMelonSlice } from '@fortawesome/pro-regular-svg-icons/faMelonSlice';
import { faMemo as farMemo } from '@fortawesome/pro-regular-svg-icons/faMemo';
import { faMemoCircleCheck as farMemoCircleCheck } from '@fortawesome/pro-regular-svg-icons/faMemoCircleCheck';
import { faMemoCircleInfo as farMemoCircleInfo } from '@fortawesome/pro-regular-svg-icons/faMemoCircleInfo';
import { faMemoPad as farMemoPad } from '@fortawesome/pro-regular-svg-icons/faMemoPad';
import { faMemory as farMemory } from '@fortawesome/pro-regular-svg-icons/faMemory';
import { faMendeley as farMendeley } from '@fortawesome/free-brands-svg-icons/faMendeley';
import { faMenorah as farMenorah } from '@fortawesome/pro-regular-svg-icons/faMenorah';
import { faMercury as farMercury } from '@fortawesome/pro-regular-svg-icons/faMercury';
import { faMerge as farMerge } from '@fortawesome/pro-regular-svg-icons/faMerge';
import { faMessage as farMessage } from '@fortawesome/pro-regular-svg-icons/faMessage';
import { faMessageArrowDown as farMessageArrowDown } from '@fortawesome/pro-regular-svg-icons/faMessageArrowDown';
import { faMessageArrowUp as farMessageArrowUp } from '@fortawesome/pro-regular-svg-icons/faMessageArrowUp';
import { faMessageArrowUpRight as farMessageArrowUpRight } from '@fortawesome/pro-regular-svg-icons/faMessageArrowUpRight';
import { faMessageBot as farMessageBot } from '@fortawesome/pro-regular-svg-icons/faMessageBot';
import { faMessageCaptions as farMessageCaptions } from '@fortawesome/pro-regular-svg-icons/faMessageCaptions';
import { faMessageCheck as farMessageCheck } from '@fortawesome/pro-regular-svg-icons/faMessageCheck';
import { faMessageCode as farMessageCode } from '@fortawesome/pro-regular-svg-icons/faMessageCode';
import { faMessageDollar as farMessageDollar } from '@fortawesome/pro-regular-svg-icons/faMessageDollar';
import { faMessageDots as farMessageDots } from '@fortawesome/pro-regular-svg-icons/faMessageDots';
import { faMessageEdit as farMessageEdit } from '@fortawesome/pro-regular-svg-icons/faMessageEdit';
import { faMessageExclamation as farMessageExclamation } from '@fortawesome/pro-regular-svg-icons/faMessageExclamation';
import { faMessageHeart as farMessageHeart } from '@fortawesome/pro-regular-svg-icons/faMessageHeart';
import { faMessageImage as farMessageImage } from '@fortawesome/pro-regular-svg-icons/faMessageImage';
import { faMessageLines as farMessageLines } from '@fortawesome/pro-regular-svg-icons/faMessageLines';
import { faMessageMedical as farMessageMedical } from '@fortawesome/pro-regular-svg-icons/faMessageMedical';
import { faMessageMiddle as farMessageMiddle } from '@fortawesome/pro-regular-svg-icons/faMessageMiddle';
import { faMessageMiddleTop as farMessageMiddleTop } from '@fortawesome/pro-regular-svg-icons/faMessageMiddleTop';
import { faMessageMinus as farMessageMinus } from '@fortawesome/pro-regular-svg-icons/faMessageMinus';
import { faMessageMusic as farMessageMusic } from '@fortawesome/pro-regular-svg-icons/faMessageMusic';
import { faMessagePen as farMessagePen } from '@fortawesome/pro-regular-svg-icons/faMessagePen';
import { faMessagePlus as farMessagePlus } from '@fortawesome/pro-regular-svg-icons/faMessagePlus';
import { faMessageQuestion as farMessageQuestion } from '@fortawesome/pro-regular-svg-icons/faMessageQuestion';
import { faMessageQuote as farMessageQuote } from '@fortawesome/pro-regular-svg-icons/faMessageQuote';
import { faMessages as farMessages } from '@fortawesome/pro-regular-svg-icons/faMessages';
import { faMessagesDollar as farMessagesDollar } from '@fortawesome/pro-regular-svg-icons/faMessagesDollar';
import { faMessageSlash as farMessageSlash } from '@fortawesome/pro-regular-svg-icons/faMessageSlash';
import { faMessageSmile as farMessageSmile } from '@fortawesome/pro-regular-svg-icons/faMessageSmile';
import { faMessageSms as farMessageSms } from '@fortawesome/pro-regular-svg-icons/faMessageSms';
import { faMessagesQuestion as farMessagesQuestion } from '@fortawesome/pro-regular-svg-icons/faMessagesQuestion';
import { faMessageText as farMessageText } from '@fortawesome/pro-regular-svg-icons/faMessageText';
import { faMessageTimes as farMessageTimes } from '@fortawesome/pro-regular-svg-icons/faMessageTimes';
import { faMessageXmark as farMessageXmark } from '@fortawesome/pro-regular-svg-icons/faMessageXmark';
import { faMessaging as farMessaging } from '@fortawesome/pro-regular-svg-icons/faMessaging';
import { faMeta as farMeta } from '@fortawesome/free-brands-svg-icons/faMeta';
import { faMeteor as farMeteor } from '@fortawesome/pro-regular-svg-icons/faMeteor';
import { faMeter as farMeter } from '@fortawesome/pro-regular-svg-icons/faMeter';
import { faMeterBolt as farMeterBolt } from '@fortawesome/pro-regular-svg-icons/faMeterBolt';
import { faMeterDroplet as farMeterDroplet } from '@fortawesome/pro-regular-svg-icons/faMeterDroplet';
import { faMeterFire as farMeterFire } from '@fortawesome/pro-regular-svg-icons/faMeterFire';
import { faMicroblog as farMicroblog } from '@fortawesome/free-brands-svg-icons/faMicroblog';
import { faMicrochip as farMicrochip } from '@fortawesome/pro-regular-svg-icons/faMicrochip';
import { faMicrochipAi as farMicrochipAi } from '@fortawesome/pro-regular-svg-icons/faMicrochipAi';
import { faMicrophone as farMicrophone } from '@fortawesome/pro-regular-svg-icons/faMicrophone';
import { faMicrophoneAlt as farMicrophoneAlt } from '@fortawesome/pro-regular-svg-icons/faMicrophoneAlt';
import { faMicrophoneAltSlash as farMicrophoneAltSlash } from '@fortawesome/pro-regular-svg-icons/faMicrophoneAltSlash';
import { faMicrophoneCircle as farMicrophoneCircle } from '@fortawesome/pro-regular-svg-icons/faMicrophoneCircle';
import { faMicrophoneCircleAlt as farMicrophoneCircleAlt } from '@fortawesome/pro-regular-svg-icons/faMicrophoneCircleAlt';
import { faMicrophoneLines as farMicrophoneLines } from '@fortawesome/pro-regular-svg-icons/faMicrophoneLines';
import { faMicrophoneLinesSlash as farMicrophoneLinesSlash } from '@fortawesome/pro-regular-svg-icons/faMicrophoneLinesSlash';
import { faMicrophoneSlash as farMicrophoneSlash } from '@fortawesome/pro-regular-svg-icons/faMicrophoneSlash';
import { faMicrophoneStand as farMicrophoneStand } from '@fortawesome/pro-regular-svg-icons/faMicrophoneStand';
import { faMicroscope as farMicroscope } from '@fortawesome/pro-regular-svg-icons/faMicroscope';
import { faMicrosoft as farMicrosoft } from '@fortawesome/free-brands-svg-icons/faMicrosoft';
import { faMicrowave as farMicrowave } from '@fortawesome/pro-regular-svg-icons/faMicrowave';
import { faMillSign as farMillSign } from '@fortawesome/pro-regular-svg-icons/faMillSign';
import { faMindShare as farMindShare } from '@fortawesome/pro-regular-svg-icons/faMindShare';
import { faMinimize as farMinimize } from '@fortawesome/pro-regular-svg-icons/faMinimize';
import { faMintbit as farMintbit } from '@fortawesome/free-brands-svg-icons/faMintbit';
import { faMinus as farMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faMinusCircle as farMinusCircle } from '@fortawesome/pro-regular-svg-icons/faMinusCircle';
import { faMinusHexagon as farMinusHexagon } from '@fortawesome/pro-regular-svg-icons/faMinusHexagon';
import { faMinusLarge as farMinusLarge } from '@fortawesome/pro-regular-svg-icons/faMinusLarge';
import { faMinusOctagon as farMinusOctagon } from '@fortawesome/pro-regular-svg-icons/faMinusOctagon';
import { faMinusSquare as farMinusSquare } from '@fortawesome/pro-regular-svg-icons/faMinusSquare';
import { faMistletoe as farMistletoe } from '@fortawesome/pro-regular-svg-icons/faMistletoe';
import { faMitten as farMitten } from '@fortawesome/pro-regular-svg-icons/faMitten';
import { faMix as farMix } from '@fortawesome/free-brands-svg-icons/faMix';
import { faMixcloud as farMixcloud } from '@fortawesome/free-brands-svg-icons/faMixcloud';
import { faMixer as farMixer } from '@fortawesome/free-brands-svg-icons/faMixer';
import { faMizuni as farMizuni } from '@fortawesome/free-brands-svg-icons/faMizuni';
import { faMobile as farMobile } from '@fortawesome/pro-regular-svg-icons/faMobile';
import { faMobileAlt as farMobileAlt } from '@fortawesome/pro-regular-svg-icons/faMobileAlt';
import { faMobileAndroid as farMobileAndroid } from '@fortawesome/pro-regular-svg-icons/faMobileAndroid';
import { faMobileAndroidAlt as farMobileAndroidAlt } from '@fortawesome/pro-regular-svg-icons/faMobileAndroidAlt';
import { faMobileButton as farMobileButton } from '@fortawesome/pro-regular-svg-icons/faMobileButton';
import { faMobileIphone as farMobileIphone } from '@fortawesome/pro-regular-svg-icons/faMobileIphone';
import { faMobileNotch as farMobileNotch } from '@fortawesome/pro-regular-svg-icons/faMobileNotch';
import { faMobilePhone as farMobilePhone } from '@fortawesome/pro-regular-svg-icons/faMobilePhone';
import { faMobileRetro as farMobileRetro } from '@fortawesome/pro-regular-svg-icons/faMobileRetro';
import { faMobileScreen as farMobileScreen } from '@fortawesome/pro-regular-svg-icons/faMobileScreen';
import { faMobileScreenButton as farMobileScreenButton } from '@fortawesome/pro-regular-svg-icons/faMobileScreenButton';
import { faMobileSignal as farMobileSignal } from '@fortawesome/pro-regular-svg-icons/faMobileSignal';
import { faMobileSignalOut as farMobileSignalOut } from '@fortawesome/pro-regular-svg-icons/faMobileSignalOut';
import { faModx as farModx } from '@fortawesome/free-brands-svg-icons/faModx';
import { faMonero as farMonero } from '@fortawesome/free-brands-svg-icons/faMonero';
import { faMoneyBill as farMoneyBill } from '@fortawesome/pro-regular-svg-icons/faMoneyBill';
import { faMoneyBill1 as farMoneyBill1 } from '@fortawesome/pro-regular-svg-icons/faMoneyBill1';
import { faMoneyBill1Wave as farMoneyBill1Wave } from '@fortawesome/pro-regular-svg-icons/faMoneyBill1Wave';
import { faMoneyBillAlt as farMoneyBillAlt } from '@fortawesome/pro-regular-svg-icons/faMoneyBillAlt';
import { faMoneyBills as farMoneyBills } from '@fortawesome/pro-regular-svg-icons/faMoneyBills';
import { faMoneyBillsAlt as farMoneyBillsAlt } from '@fortawesome/pro-regular-svg-icons/faMoneyBillsAlt';
import { faMoneyBillSimple as farMoneyBillSimple } from '@fortawesome/pro-regular-svg-icons/faMoneyBillSimple';
import { faMoneyBillSimpleWave as farMoneyBillSimpleWave } from '@fortawesome/pro-regular-svg-icons/faMoneyBillSimpleWave';
import { faMoneyBillsSimple as farMoneyBillsSimple } from '@fortawesome/pro-regular-svg-icons/faMoneyBillsSimple';
import { faMoneyBillTransfer as farMoneyBillTransfer } from '@fortawesome/pro-regular-svg-icons/faMoneyBillTransfer';
import { faMoneyBillTrendUp as farMoneyBillTrendUp } from '@fortawesome/pro-regular-svg-icons/faMoneyBillTrendUp';
import { faMoneyBillWave as farMoneyBillWave } from '@fortawesome/pro-regular-svg-icons/faMoneyBillWave';
import { faMoneyBillWaveAlt as farMoneyBillWaveAlt } from '@fortawesome/pro-regular-svg-icons/faMoneyBillWaveAlt';
import { faMoneyBillWheat as farMoneyBillWheat } from '@fortawesome/pro-regular-svg-icons/faMoneyBillWheat';
import { faMoneyCheck as farMoneyCheck } from '@fortawesome/pro-regular-svg-icons/faMoneyCheck';
import { faMoneyCheckAlt as farMoneyCheckAlt } from '@fortawesome/pro-regular-svg-icons/faMoneyCheckAlt';
import { faMoneyCheckDollar as farMoneyCheckDollar } from '@fortawesome/pro-regular-svg-icons/faMoneyCheckDollar';
import { faMoneyCheckDollarPen as farMoneyCheckDollarPen } from '@fortawesome/pro-regular-svg-icons/faMoneyCheckDollarPen';
import { faMoneyCheckEdit as farMoneyCheckEdit } from '@fortawesome/pro-regular-svg-icons/faMoneyCheckEdit';
import { faMoneyCheckEditAlt as farMoneyCheckEditAlt } from '@fortawesome/pro-regular-svg-icons/faMoneyCheckEditAlt';
import { faMoneyCheckPen as farMoneyCheckPen } from '@fortawesome/pro-regular-svg-icons/faMoneyCheckPen';
import { faMoneyFromBracket as farMoneyFromBracket } from '@fortawesome/pro-regular-svg-icons/faMoneyFromBracket';
import { faMoneySimpleFromBracket as farMoneySimpleFromBracket } from '@fortawesome/pro-regular-svg-icons/faMoneySimpleFromBracket';
import { faMonitorHeartRate as farMonitorHeartRate } from '@fortawesome/pro-regular-svg-icons/faMonitorHeartRate';
import { faMonitorWaveform as farMonitorWaveform } from '@fortawesome/pro-regular-svg-icons/faMonitorWaveform';
import { faMonkey as farMonkey } from '@fortawesome/pro-regular-svg-icons/faMonkey';
import { faMonument as farMonument } from '@fortawesome/pro-regular-svg-icons/faMonument';
import { faMoon as farMoon } from '@fortawesome/pro-regular-svg-icons/faMoon';
import { faMoonCloud as farMoonCloud } from '@fortawesome/pro-regular-svg-icons/faMoonCloud';
import { faMoonOverSun as farMoonOverSun } from '@fortawesome/pro-regular-svg-icons/faMoonOverSun';
import { faMoonStars as farMoonStars } from '@fortawesome/pro-regular-svg-icons/faMoonStars';
import { faMoped as farMoped } from '@fortawesome/pro-regular-svg-icons/faMoped';
import { faMortarBoard as farMortarBoard } from '@fortawesome/pro-regular-svg-icons/faMortarBoard';
import { faMortarPestle as farMortarPestle } from '@fortawesome/pro-regular-svg-icons/faMortarPestle';
import { faMosque as farMosque } from '@fortawesome/pro-regular-svg-icons/faMosque';
import { faMosquito as farMosquito } from '@fortawesome/pro-regular-svg-icons/faMosquito';
import { faMosquitoNet as farMosquitoNet } from '@fortawesome/pro-regular-svg-icons/faMosquitoNet';
import { faMotorcycle as farMotorcycle } from '@fortawesome/pro-regular-svg-icons/faMotorcycle';
import { faMound as farMound } from '@fortawesome/pro-regular-svg-icons/faMound';
import { faMountain as farMountain } from '@fortawesome/pro-regular-svg-icons/faMountain';
import { faMountainCity as farMountainCity } from '@fortawesome/pro-regular-svg-icons/faMountainCity';
import { faMountains as farMountains } from '@fortawesome/pro-regular-svg-icons/faMountains';
import { faMountainSun as farMountainSun } from '@fortawesome/pro-regular-svg-icons/faMountainSun';
import { faMouse as farMouse } from '@fortawesome/pro-regular-svg-icons/faMouse';
import { faMouseAlt as farMouseAlt } from '@fortawesome/pro-regular-svg-icons/faMouseAlt';
import { faMouseField as farMouseField } from '@fortawesome/pro-regular-svg-icons/faMouseField';
import { faMousePointer as farMousePointer } from '@fortawesome/pro-regular-svg-icons/faMousePointer';
import { faMp3Player as farMp3Player } from '@fortawesome/pro-regular-svg-icons/faMp3Player';
import { faMug as farMug } from '@fortawesome/pro-regular-svg-icons/faMug';
import { faMugHot as farMugHot } from '@fortawesome/pro-regular-svg-icons/faMugHot';
import { faMugMarshmallows as farMugMarshmallows } from '@fortawesome/pro-regular-svg-icons/faMugMarshmallows';
import { faMugSaucer as farMugSaucer } from '@fortawesome/pro-regular-svg-icons/faMugSaucer';
import { faMugTea as farMugTea } from '@fortawesome/pro-regular-svg-icons/faMugTea';
import { faMugTeaSaucer as farMugTeaSaucer } from '@fortawesome/pro-regular-svg-icons/faMugTeaSaucer';
import { faMultiply as farMultiply } from '@fortawesome/pro-regular-svg-icons/faMultiply';
import { faMuseum as farMuseum } from '@fortawesome/pro-regular-svg-icons/faMuseum';
import { faMushroom as farMushroom } from '@fortawesome/pro-regular-svg-icons/faMushroom';
import { faMusic as farMusic } from '@fortawesome/pro-regular-svg-icons/faMusic';
import { faMusicAlt as farMusicAlt } from '@fortawesome/pro-regular-svg-icons/faMusicAlt';
import { faMusicAltSlash as farMusicAltSlash } from '@fortawesome/pro-regular-svg-icons/faMusicAltSlash';
import { faMusicMagnifyingGlass as farMusicMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMusicMagnifyingGlass';
import { faMusicNote as farMusicNote } from '@fortawesome/pro-regular-svg-icons/faMusicNote';
import { faMusicNoteSlash as farMusicNoteSlash } from '@fortawesome/pro-regular-svg-icons/faMusicNoteSlash';
import { faMusicSlash as farMusicSlash } from '@fortawesome/pro-regular-svg-icons/faMusicSlash';
import { faMustache as farMustache } from '@fortawesome/pro-regular-svg-icons/faMustache';
import { faN as farN } from '@fortawesome/pro-regular-svg-icons/faN';
import { faNairaSign as farNairaSign } from '@fortawesome/pro-regular-svg-icons/faNairaSign';
import { faNapster as farNapster } from '@fortawesome/free-brands-svg-icons/faNapster';
import { faNarwhal as farNarwhal } from '@fortawesome/pro-regular-svg-icons/faNarwhal';
import { faNavicon as farNavicon } from '@fortawesome/pro-regular-svg-icons/faNavicon';
import { faNeos as farNeos } from '@fortawesome/free-brands-svg-icons/faNeos';
import { faNestingDolls as farNestingDolls } from '@fortawesome/pro-regular-svg-icons/faNestingDolls';
import { faNetworkWired as farNetworkWired } from '@fortawesome/pro-regular-svg-icons/faNetworkWired';
import { faNeuter as farNeuter } from '@fortawesome/pro-regular-svg-icons/faNeuter';
import { faNewspaper as farNewspaper } from '@fortawesome/pro-regular-svg-icons/faNewspaper';
import { faNfc as farNfc } from '@fortawesome/pro-regular-svg-icons/faNfc';
import { faNfcDirectional as farNfcDirectional } from '@fortawesome/free-brands-svg-icons/faNfcDirectional';
import { faNfcLock as farNfcLock } from '@fortawesome/pro-regular-svg-icons/faNfcLock';
import { faNfcMagnifyingGlass as farNfcMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faNfcMagnifyingGlass';
import { faNfcPen as farNfcPen } from '@fortawesome/pro-regular-svg-icons/faNfcPen';
import { faNfcSignal as farNfcSignal } from '@fortawesome/pro-regular-svg-icons/faNfcSignal';
import { faNfcSlash as farNfcSlash } from '@fortawesome/pro-regular-svg-icons/faNfcSlash';
import { faNfcSymbol as farNfcSymbol } from '@fortawesome/pro-regular-svg-icons/faNfcSymbol';
import { faNfcTrash as farNfcTrash } from '@fortawesome/pro-regular-svg-icons/faNfcTrash';
import { faNigiri as farNigiri } from '@fortawesome/pro-regular-svg-icons/faNigiri';
import { faNimblr as farNimblr } from '@fortawesome/free-brands-svg-icons/faNimblr';
import { faNode as farNode } from '@fortawesome/free-brands-svg-icons/faNode';
import { faNodeJs as farNodeJs } from '@fortawesome/free-brands-svg-icons/faNodeJs';
import { faNose as farNose } from '@fortawesome/pro-regular-svg-icons/faNose';
import { faNotdef as farNotdef } from '@fortawesome/pro-regular-svg-icons/faNotdef';
import { faNote as farNote } from '@fortawesome/pro-regular-svg-icons/faNote';
import { faNotebook as farNotebook } from '@fortawesome/pro-regular-svg-icons/faNotebook';
import { faNoteMedical as farNoteMedical } from '@fortawesome/pro-regular-svg-icons/faNoteMedical';
import { faNotEqual as farNotEqual } from '@fortawesome/pro-regular-svg-icons/faNotEqual';
import { faNotes as farNotes } from '@fortawesome/pro-regular-svg-icons/faNotes';
import { faNotesMedical as farNotesMedical } from '@fortawesome/pro-regular-svg-icons/faNotesMedical';
import { faNoteSticky as farNoteSticky } from '@fortawesome/pro-regular-svg-icons/faNoteSticky';
import { faNpm as farNpm } from '@fortawesome/free-brands-svg-icons/faNpm';
import { faNs8 as farNs8 } from '@fortawesome/free-brands-svg-icons/faNs8';
import { faNutritionix as farNutritionix } from '@fortawesome/free-brands-svg-icons/faNutritionix';
import { faO as farO } from '@fortawesome/pro-regular-svg-icons/faO';
import { faObjectExclude as farObjectExclude } from '@fortawesome/pro-regular-svg-icons/faObjectExclude';
import { faObjectGroup as farObjectGroup } from '@fortawesome/pro-regular-svg-icons/faObjectGroup';
import { faObjectIntersect as farObjectIntersect } from '@fortawesome/pro-regular-svg-icons/faObjectIntersect';
import { faObjectsAlignBottom as farObjectsAlignBottom } from '@fortawesome/pro-regular-svg-icons/faObjectsAlignBottom';
import { faObjectsAlignCenterHorizontal as farObjectsAlignCenterHorizontal } from '@fortawesome/pro-regular-svg-icons/faObjectsAlignCenterHorizontal';
import { faObjectsAlignCenterVertical as farObjectsAlignCenterVertical } from '@fortawesome/pro-regular-svg-icons/faObjectsAlignCenterVertical';
import { faObjectsAlignLeft as farObjectsAlignLeft } from '@fortawesome/pro-regular-svg-icons/faObjectsAlignLeft';
import { faObjectsAlignRight as farObjectsAlignRight } from '@fortawesome/pro-regular-svg-icons/faObjectsAlignRight';
import { faObjectsAlignTop as farObjectsAlignTop } from '@fortawesome/pro-regular-svg-icons/faObjectsAlignTop';
import { faObjectsColumn as farObjectsColumn } from '@fortawesome/pro-regular-svg-icons/faObjectsColumn';
import { faObjectSubtract as farObjectSubtract } from '@fortawesome/pro-regular-svg-icons/faObjectSubtract';
import { faObjectUngroup as farObjectUngroup } from '@fortawesome/pro-regular-svg-icons/faObjectUngroup';
import { faObjectUnion as farObjectUnion } from '@fortawesome/pro-regular-svg-icons/faObjectUnion';
import { faOctagon as farOctagon } from '@fortawesome/pro-regular-svg-icons/faOctagon';
import { faOctagonCheck as farOctagonCheck } from '@fortawesome/pro-regular-svg-icons/faOctagonCheck';
import { faOctagonDivide as farOctagonDivide } from '@fortawesome/pro-regular-svg-icons/faOctagonDivide';
import { faOctagonExclamation as farOctagonExclamation } from '@fortawesome/pro-regular-svg-icons/faOctagonExclamation';
import { faOctagonMinus as farOctagonMinus } from '@fortawesome/pro-regular-svg-icons/faOctagonMinus';
import { faOctagonPlus as farOctagonPlus } from '@fortawesome/pro-regular-svg-icons/faOctagonPlus';
import { faOctagonXmark as farOctagonXmark } from '@fortawesome/pro-regular-svg-icons/faOctagonXmark';
import { faOctopusDeploy as farOctopusDeploy } from '@fortawesome/free-brands-svg-icons/faOctopusDeploy';
import { faOdnoklassniki as farOdnoklassniki } from '@fortawesome/free-brands-svg-icons/faOdnoklassniki';
import { faOdnoklassnikiSquare as farOdnoklassnikiSquare } from '@fortawesome/free-brands-svg-icons/faOdnoklassnikiSquare';
import { faOdysee as farOdysee } from '@fortawesome/free-brands-svg-icons/faOdysee';
import { faOilCan as farOilCan } from '@fortawesome/pro-regular-svg-icons/faOilCan';
import { faOilCanDrip as farOilCanDrip } from '@fortawesome/pro-regular-svg-icons/faOilCanDrip';
import { faOilTemp as farOilTemp } from '@fortawesome/pro-regular-svg-icons/faOilTemp';
import { faOilTemperature as farOilTemperature } from '@fortawesome/pro-regular-svg-icons/faOilTemperature';
import { faOilWell as farOilWell } from '@fortawesome/pro-regular-svg-icons/faOilWell';
import { faOldRepublic as farOldRepublic } from '@fortawesome/free-brands-svg-icons/faOldRepublic';
import { faOlive as farOlive } from '@fortawesome/pro-regular-svg-icons/faOlive';
import { faOliveBranch as farOliveBranch } from '@fortawesome/pro-regular-svg-icons/faOliveBranch';
import { faOm as farOm } from '@fortawesome/pro-regular-svg-icons/faOm';
import { faOmega as farOmega } from '@fortawesome/pro-regular-svg-icons/faOmega';
import { faOnion as farOnion } from '@fortawesome/pro-regular-svg-icons/faOnion';
import { faOpencart as farOpencart } from '@fortawesome/free-brands-svg-icons/faOpencart';
import { faOpenid as farOpenid } from '@fortawesome/free-brands-svg-icons/faOpenid';
import { faOpensuse as farOpensuse } from '@fortawesome/free-brands-svg-icons/faOpensuse';
import { faOpera as farOpera } from '@fortawesome/free-brands-svg-icons/faOpera';
import { faOptinMonster as farOptinMonster } from '@fortawesome/free-brands-svg-icons/faOptinMonster';
import { faOption as farOption } from '@fortawesome/pro-regular-svg-icons/faOption';
import { faOrcid as farOrcid } from '@fortawesome/free-brands-svg-icons/faOrcid';
import { faOrnament as farOrnament } from '@fortawesome/pro-regular-svg-icons/faOrnament';
import { faOsi as farOsi } from '@fortawesome/free-brands-svg-icons/faOsi';
import { faOtter as farOtter } from '@fortawesome/pro-regular-svg-icons/faOtter';
import { faOutdent as farOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faOutlet as farOutlet } from '@fortawesome/pro-regular-svg-icons/faOutlet';
import { faOven as farOven } from '@fortawesome/pro-regular-svg-icons/faOven';
import { faOverline as farOverline } from '@fortawesome/pro-regular-svg-icons/faOverline';
import { faP as farP } from '@fortawesome/pro-regular-svg-icons/faP';
import { faPadlet as farPadlet } from '@fortawesome/free-brands-svg-icons/faPadlet';
import { faPage as farPage } from '@fortawesome/pro-regular-svg-icons/faPage';
import { faPage4 as farPage4 } from '@fortawesome/free-brands-svg-icons/faPage4';
import { faPageBreak as farPageBreak } from '@fortawesome/pro-regular-svg-icons/faPageBreak';
import { faPageCaretDown as farPageCaretDown } from '@fortawesome/pro-regular-svg-icons/faPageCaretDown';
import { faPageCaretUp as farPageCaretUp } from '@fortawesome/pro-regular-svg-icons/faPageCaretUp';
import { faPagelines as farPagelines } from '@fortawesome/free-brands-svg-icons/faPagelines';
import { faPager as farPager } from '@fortawesome/pro-regular-svg-icons/faPager';
import { faPaintbrush as farPaintbrush } from '@fortawesome/pro-regular-svg-icons/faPaintbrush';
import { faPaintbrushAlt as farPaintbrushAlt } from '@fortawesome/pro-regular-svg-icons/faPaintbrushAlt';
import { faPaintbrushFine as farPaintbrushFine } from '@fortawesome/pro-regular-svg-icons/faPaintbrushFine';
import { faPaintbrushPencil as farPaintbrushPencil } from '@fortawesome/pro-regular-svg-icons/faPaintbrushPencil';
import { faPaintRoller as farPaintRoller } from '@fortawesome/pro-regular-svg-icons/faPaintRoller';
import { faPalette as farPalette } from '@fortawesome/pro-regular-svg-icons/faPalette';
import { faPaletteBoxes as farPaletteBoxes } from '@fortawesome/pro-regular-svg-icons/faPaletteBoxes';
import { faPalfed as farPalfed } from '@fortawesome/free-brands-svg-icons/faPalfed';
import { faPallet as farPallet } from '@fortawesome/pro-regular-svg-icons/faPallet';
import { faPalletAlt as farPalletAlt } from '@fortawesome/pro-regular-svg-icons/faPalletAlt';
import { faPalletBox as farPalletBox } from '@fortawesome/pro-regular-svg-icons/faPalletBox';
import { faPalletBoxes as farPalletBoxes } from '@fortawesome/pro-regular-svg-icons/faPalletBoxes';
import { faPancakes as farPancakes } from '@fortawesome/pro-regular-svg-icons/faPancakes';
import { faPanelEws as farPanelEws } from '@fortawesome/pro-regular-svg-icons/faPanelEws';
import { faPanelFire as farPanelFire } from '@fortawesome/pro-regular-svg-icons/faPanelFire';
import { faPanFood as farPanFood } from '@fortawesome/pro-regular-svg-icons/faPanFood';
import { faPanFrying as farPanFrying } from '@fortawesome/pro-regular-svg-icons/faPanFrying';
import { faPanorama as farPanorama } from '@fortawesome/pro-regular-svg-icons/faPanorama';
import { faPaperclip as farPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip';
import { faPaperclipVertical as farPaperclipVertical } from '@fortawesome/pro-regular-svg-icons/faPaperclipVertical';
import { faPaperPlane as farPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import { faPaperPlaneAlt as farPaperPlaneAlt } from '@fortawesome/pro-regular-svg-icons/faPaperPlaneAlt';
import { faPaperPlaneTop as farPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons/faPaperPlaneTop';
import { faParachuteBox as farParachuteBox } from '@fortawesome/pro-regular-svg-icons/faParachuteBox';
import { faParagraph as farParagraph } from '@fortawesome/pro-regular-svg-icons/faParagraph';
import { faParagraphLeft as farParagraphLeft } from '@fortawesome/pro-regular-svg-icons/faParagraphLeft';
import { faParagraphRtl as farParagraphRtl } from '@fortawesome/pro-regular-svg-icons/faParagraphRtl';
import { faParentheses as farParentheses } from '@fortawesome/pro-regular-svg-icons/faParentheses';
import { faParenthesis as farParenthesis } from '@fortawesome/pro-regular-svg-icons/faParenthesis';
import { faParking as farParking } from '@fortawesome/pro-regular-svg-icons/faParking';
import { faParkingCircle as farParkingCircle } from '@fortawesome/pro-regular-svg-icons/faParkingCircle';
import { faParkingCircleSlash as farParkingCircleSlash } from '@fortawesome/pro-regular-svg-icons/faParkingCircleSlash';
import { faParkingSlash as farParkingSlash } from '@fortawesome/pro-regular-svg-icons/faParkingSlash';
import { faPartyBack as farPartyBack } from '@fortawesome/pro-regular-svg-icons/faPartyBack';
import { faPartyBell as farPartyBell } from '@fortawesome/pro-regular-svg-icons/faPartyBell';
import { faPartyHorn as farPartyHorn } from '@fortawesome/pro-regular-svg-icons/faPartyHorn';
import { faPassport as farPassport } from '@fortawesome/pro-regular-svg-icons/faPassport';
import { faPastafarianism as farPastafarianism } from '@fortawesome/pro-regular-svg-icons/faPastafarianism';
import { faPaste as farPaste } from '@fortawesome/pro-regular-svg-icons/faPaste';
import { faPatreon as farPatreon } from '@fortawesome/free-brands-svg-icons/faPatreon';
import { faPause as farPause } from '@fortawesome/pro-regular-svg-icons/faPause';
import { faPauseCircle as farPauseCircle } from '@fortawesome/pro-regular-svg-icons/faPauseCircle';
import { faPaw as farPaw } from '@fortawesome/pro-regular-svg-icons/faPaw';
import { faPawAlt as farPawAlt } from '@fortawesome/pro-regular-svg-icons/faPawAlt';
import { faPawClaws as farPawClaws } from '@fortawesome/pro-regular-svg-icons/faPawClaws';
import { faPawSimple as farPawSimple } from '@fortawesome/pro-regular-svg-icons/faPawSimple';
import { faPaypal as farPaypal } from '@fortawesome/free-brands-svg-icons/faPaypal';
import { faPeace as farPeace } from '@fortawesome/pro-regular-svg-icons/faPeace';
import { faPeach as farPeach } from '@fortawesome/pro-regular-svg-icons/faPeach';
import { faPeanut as farPeanut } from '@fortawesome/pro-regular-svg-icons/faPeanut';
import { faPeanuts as farPeanuts } from '@fortawesome/pro-regular-svg-icons/faPeanuts';
import { faPeapod as farPeapod } from '@fortawesome/pro-regular-svg-icons/faPeapod';
import { faPear as farPear } from '@fortawesome/pro-regular-svg-icons/faPear';
import { faPedestal as farPedestal } from '@fortawesome/pro-regular-svg-icons/faPedestal';
import { faPegasus as farPegasus } from '@fortawesome/pro-regular-svg-icons/faPegasus';
import { faPen as farPen } from '@fortawesome/pro-regular-svg-icons/faPen';
import { faPenAlt as farPenAlt } from '@fortawesome/pro-regular-svg-icons/faPenAlt';
import { faPenAltSlash as farPenAltSlash } from '@fortawesome/pro-regular-svg-icons/faPenAltSlash';
import { faPencil as farPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faPencilAlt as farPencilAlt } from '@fortawesome/pro-regular-svg-icons/faPencilAlt';
import { faPencilMechanical as farPencilMechanical } from '@fortawesome/pro-regular-svg-icons/faPencilMechanical';
import { faPencilPaintbrush as farPencilPaintbrush } from '@fortawesome/pro-regular-svg-icons/faPencilPaintbrush';
import { faPencilRuler as farPencilRuler } from '@fortawesome/pro-regular-svg-icons/faPencilRuler';
import { faPencilSlash as farPencilSlash } from '@fortawesome/pro-regular-svg-icons/faPencilSlash';
import { faPencilSquare as farPencilSquare } from '@fortawesome/pro-regular-svg-icons/faPencilSquare';
import { faPenCircle as farPenCircle } from '@fortawesome/pro-regular-svg-icons/faPenCircle';
import { faPenClip as farPenClip } from '@fortawesome/pro-regular-svg-icons/faPenClip';
import { faPenClipSlash as farPenClipSlash } from '@fortawesome/pro-regular-svg-icons/faPenClipSlash';
import { faPenFancy as farPenFancy } from '@fortawesome/pro-regular-svg-icons/faPenFancy';
import { faPenFancySlash as farPenFancySlash } from '@fortawesome/pro-regular-svg-icons/faPenFancySlash';
import { faPenField as farPenField } from '@fortawesome/pro-regular-svg-icons/faPenField';
import { faPenLine as farPenLine } from '@fortawesome/pro-regular-svg-icons/faPenLine';
import { faPennant as farPennant } from '@fortawesome/pro-regular-svg-icons/faPennant';
import { faPenNib as farPenNib } from '@fortawesome/pro-regular-svg-icons/faPenNib';
import { faPenNibSlash as farPenNibSlash } from '@fortawesome/pro-regular-svg-icons/faPenNibSlash';
import { faPenPaintbrush as farPenPaintbrush } from '@fortawesome/pro-regular-svg-icons/faPenPaintbrush';
import { faPenRuler as farPenRuler } from '@fortawesome/pro-regular-svg-icons/faPenRuler';
import { faPenSlash as farPenSlash } from '@fortawesome/pro-regular-svg-icons/faPenSlash';
import { faPenSquare as farPenSquare } from '@fortawesome/pro-regular-svg-icons/faPenSquare';
import { faPenSwirl as farPenSwirl } from '@fortawesome/pro-regular-svg-icons/faPenSwirl';
import { faPenToSquare as farPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import { faPeople as farPeople } from '@fortawesome/pro-regular-svg-icons/faPeople';
import { faPeopleArrows as farPeopleArrows } from '@fortawesome/pro-regular-svg-icons/faPeopleArrows';
import { faPeopleArrowsLeftRight as farPeopleArrowsLeftRight } from '@fortawesome/pro-regular-svg-icons/faPeopleArrowsLeftRight';
import { faPeopleCarry as farPeopleCarry } from '@fortawesome/pro-regular-svg-icons/faPeopleCarry';
import { faPeopleCarryBox as farPeopleCarryBox } from '@fortawesome/pro-regular-svg-icons/faPeopleCarryBox';
import { faPeopleDress as farPeopleDress } from '@fortawesome/pro-regular-svg-icons/faPeopleDress';
import { faPeopleDressSimple as farPeopleDressSimple } from '@fortawesome/pro-regular-svg-icons/faPeopleDressSimple';
import { faPeopleGroup as farPeopleGroup } from '@fortawesome/pro-regular-svg-icons/faPeopleGroup';
import { faPeopleLine as farPeopleLine } from '@fortawesome/pro-regular-svg-icons/faPeopleLine';
import { faPeoplePants as farPeoplePants } from '@fortawesome/pro-regular-svg-icons/faPeoplePants';
import { faPeoplePantsSimple as farPeoplePantsSimple } from '@fortawesome/pro-regular-svg-icons/faPeoplePantsSimple';
import { faPeoplePulling as farPeoplePulling } from '@fortawesome/pro-regular-svg-icons/faPeoplePulling';
import { faPeopleRobbery as farPeopleRobbery } from '@fortawesome/pro-regular-svg-icons/faPeopleRobbery';
import { faPeopleRoof as farPeopleRoof } from '@fortawesome/pro-regular-svg-icons/faPeopleRoof';
import { faPeopleSimple as farPeopleSimple } from '@fortawesome/pro-regular-svg-icons/faPeopleSimple';
import { faPepper as farPepper } from '@fortawesome/pro-regular-svg-icons/faPepper';
import { faPepperHot as farPepperHot } from '@fortawesome/pro-regular-svg-icons/faPepperHot';
import { faPerbyte as farPerbyte } from '@fortawesome/free-brands-svg-icons/faPerbyte';
import { faPercent as farPercent } from '@fortawesome/pro-regular-svg-icons/faPercent';
import { faPercentage as farPercentage } from '@fortawesome/pro-regular-svg-icons/faPercentage';
import { faPeriod as farPeriod } from '@fortawesome/pro-regular-svg-icons/faPeriod';
import { faPeriscope as farPeriscope } from '@fortawesome/free-brands-svg-icons/faPeriscope';
import { faPerson as farPerson } from '@fortawesome/pro-regular-svg-icons/faPerson';
import { faPersonArrowDownToLine as farPersonArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faPersonArrowDownToLine';
import { faPersonArrowUpFromLine as farPersonArrowUpFromLine } from '@fortawesome/pro-regular-svg-icons/faPersonArrowUpFromLine';
import { faPersonBiking as farPersonBiking } from '@fortawesome/pro-regular-svg-icons/faPersonBiking';
import { faPersonBikingMountain as farPersonBikingMountain } from '@fortawesome/pro-regular-svg-icons/faPersonBikingMountain';
import { faPersonBooth as farPersonBooth } from '@fortawesome/pro-regular-svg-icons/faPersonBooth';
import { faPersonBreastfeeding as farPersonBreastfeeding } from '@fortawesome/pro-regular-svg-icons/faPersonBreastfeeding';
import { faPersonBurst as farPersonBurst } from '@fortawesome/pro-regular-svg-icons/faPersonBurst';
import { faPersonCane as farPersonCane } from '@fortawesome/pro-regular-svg-icons/faPersonCane';
import { faPersonCarry as farPersonCarry } from '@fortawesome/pro-regular-svg-icons/faPersonCarry';
import { faPersonCarryBox as farPersonCarryBox } from '@fortawesome/pro-regular-svg-icons/faPersonCarryBox';
import { faPersonChalkboard as farPersonChalkboard } from '@fortawesome/pro-regular-svg-icons/faPersonChalkboard';
import { faPersonCircleCheck as farPersonCircleCheck } from '@fortawesome/pro-regular-svg-icons/faPersonCircleCheck';
import { faPersonCircleExclamation as farPersonCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faPersonCircleExclamation';
import { faPersonCircleMinus as farPersonCircleMinus } from '@fortawesome/pro-regular-svg-icons/faPersonCircleMinus';
import { faPersonCirclePlus as farPersonCirclePlus } from '@fortawesome/pro-regular-svg-icons/faPersonCirclePlus';
import { faPersonCircleQuestion as farPersonCircleQuestion } from '@fortawesome/pro-regular-svg-icons/faPersonCircleQuestion';
import { faPersonCircleXmark as farPersonCircleXmark } from '@fortawesome/pro-regular-svg-icons/faPersonCircleXmark';
import { faPersonDigging as farPersonDigging } from '@fortawesome/pro-regular-svg-icons/faPersonDigging';
import { faPersonDolly as farPersonDolly } from '@fortawesome/pro-regular-svg-icons/faPersonDolly';
import { faPersonDollyEmpty as farPersonDollyEmpty } from '@fortawesome/pro-regular-svg-icons/faPersonDollyEmpty';
import { faPersonDotsFromLine as farPersonDotsFromLine } from '@fortawesome/pro-regular-svg-icons/faPersonDotsFromLine';
import { faPersonDress as farPersonDress } from '@fortawesome/pro-regular-svg-icons/faPersonDress';
import { faPersonDressBurst as farPersonDressBurst } from '@fortawesome/pro-regular-svg-icons/faPersonDressBurst';
import { faPersonDressFairy as farPersonDressFairy } from '@fortawesome/pro-regular-svg-icons/faPersonDressFairy';
import { faPersonDressSimple as farPersonDressSimple } from '@fortawesome/pro-regular-svg-icons/faPersonDressSimple';
import { faPersonDrowning as farPersonDrowning } from '@fortawesome/pro-regular-svg-icons/faPersonDrowning';
import { faPersonFairy as farPersonFairy } from '@fortawesome/pro-regular-svg-icons/faPersonFairy';
import { faPersonFalling as farPersonFalling } from '@fortawesome/pro-regular-svg-icons/faPersonFalling';
import { faPersonFallingBurst as farPersonFallingBurst } from '@fortawesome/pro-regular-svg-icons/faPersonFallingBurst';
import { faPersonFromPortal as farPersonFromPortal } from '@fortawesome/pro-regular-svg-icons/faPersonFromPortal';
import { faPersonHalfDress as farPersonHalfDress } from '@fortawesome/pro-regular-svg-icons/faPersonHalfDress';
import { faPersonHarassing as farPersonHarassing } from '@fortawesome/pro-regular-svg-icons/faPersonHarassing';
import { faPersonHiking as farPersonHiking } from '@fortawesome/pro-regular-svg-icons/faPersonHiking';
import { faPersonMilitaryPointing as farPersonMilitaryPointing } from '@fortawesome/pro-regular-svg-icons/faPersonMilitaryPointing';
import { faPersonMilitaryRifle as farPersonMilitaryRifle } from '@fortawesome/pro-regular-svg-icons/faPersonMilitaryRifle';
import { faPersonMilitaryToPerson as farPersonMilitaryToPerson } from '@fortawesome/pro-regular-svg-icons/faPersonMilitaryToPerson';
import { faPersonPinball as farPersonPinball } from '@fortawesome/pro-regular-svg-icons/faPersonPinball';
import { faPersonPraying as farPersonPraying } from '@fortawesome/pro-regular-svg-icons/faPersonPraying';
import { faPersonPregnant as farPersonPregnant } from '@fortawesome/pro-regular-svg-icons/faPersonPregnant';
import { faPersonRays as farPersonRays } from '@fortawesome/pro-regular-svg-icons/faPersonRays';
import { faPersonRifle as farPersonRifle } from '@fortawesome/pro-regular-svg-icons/faPersonRifle';
import { faPersonRunning as farPersonRunning } from '@fortawesome/pro-regular-svg-icons/faPersonRunning';
import { faPersonRunningFast as farPersonRunningFast } from '@fortawesome/pro-regular-svg-icons/faPersonRunningFast';
import { faPersonSeat as farPersonSeat } from '@fortawesome/pro-regular-svg-icons/faPersonSeat';
import { faPersonSeatReclined as farPersonSeatReclined } from '@fortawesome/pro-regular-svg-icons/faPersonSeatReclined';
import { faPersonShelter as farPersonShelter } from '@fortawesome/pro-regular-svg-icons/faPersonShelter';
import { faPersonSign as farPersonSign } from '@fortawesome/pro-regular-svg-icons/faPersonSign';
import { faPersonSimple as farPersonSimple } from '@fortawesome/pro-regular-svg-icons/faPersonSimple';
import { faPersonSkating as farPersonSkating } from '@fortawesome/pro-regular-svg-icons/faPersonSkating';
import { faPersonSkiing as farPersonSkiing } from '@fortawesome/pro-regular-svg-icons/faPersonSkiing';
import { faPersonSkiingNordic as farPersonSkiingNordic } from '@fortawesome/pro-regular-svg-icons/faPersonSkiingNordic';
import { faPersonSkiJumping as farPersonSkiJumping } from '@fortawesome/pro-regular-svg-icons/faPersonSkiJumping';
import { faPersonSkiLift as farPersonSkiLift } from '@fortawesome/pro-regular-svg-icons/faPersonSkiLift';
import { faPersonSledding as farPersonSledding } from '@fortawesome/pro-regular-svg-icons/faPersonSledding';
import { faPersonSnowboarding as farPersonSnowboarding } from '@fortawesome/pro-regular-svg-icons/faPersonSnowboarding';
import { faPersonSnowmobiling as farPersonSnowmobiling } from '@fortawesome/pro-regular-svg-icons/faPersonSnowmobiling';
import { faPersonSwimming as farPersonSwimming } from '@fortawesome/pro-regular-svg-icons/faPersonSwimming';
import { faPersonThroughWindow as farPersonThroughWindow } from '@fortawesome/pro-regular-svg-icons/faPersonThroughWindow';
import { faPersonToDoor as farPersonToDoor } from '@fortawesome/pro-regular-svg-icons/faPersonToDoor';
import { faPersonToPortal as farPersonToPortal } from '@fortawesome/pro-regular-svg-icons/faPersonToPortal';
import { faPersonWalking as farPersonWalking } from '@fortawesome/pro-regular-svg-icons/faPersonWalking';
import { faPersonWalkingArrowLoopLeft as farPersonWalkingArrowLoopLeft } from '@fortawesome/pro-regular-svg-icons/faPersonWalkingArrowLoopLeft';
import { faPersonWalkingArrowRight as farPersonWalkingArrowRight } from '@fortawesome/pro-regular-svg-icons/faPersonWalkingArrowRight';
import { faPersonWalkingDashedLineArrowRight as farPersonWalkingDashedLineArrowRight } from '@fortawesome/pro-regular-svg-icons/faPersonWalkingDashedLineArrowRight';
import { faPersonWalkingLuggage as farPersonWalkingLuggage } from '@fortawesome/pro-regular-svg-icons/faPersonWalkingLuggage';
import { faPersonWalkingWithCane as farPersonWalkingWithCane } from '@fortawesome/pro-regular-svg-icons/faPersonWalkingWithCane';
import { faPesetaSign as farPesetaSign } from '@fortawesome/pro-regular-svg-icons/faPesetaSign';
import { faPesoSign as farPesoSign } from '@fortawesome/pro-regular-svg-icons/faPesoSign';
import { faPhabricator as farPhabricator } from '@fortawesome/free-brands-svg-icons/faPhabricator';
import { faPhoenixFramework as farPhoenixFramework } from '@fortawesome/free-brands-svg-icons/faPhoenixFramework';
import { faPhoenixSquadron as farPhoenixSquadron } from '@fortawesome/free-brands-svg-icons/faPhoenixSquadron';
import { faPhone as farPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
import { faPhoneAlt as farPhoneAlt } from '@fortawesome/pro-regular-svg-icons/faPhoneAlt';
import { faPhoneArrowDown as farPhoneArrowDown } from '@fortawesome/pro-regular-svg-icons/faPhoneArrowDown';
import { faPhoneArrowDownLeft as farPhoneArrowDownLeft } from '@fortawesome/pro-regular-svg-icons/faPhoneArrowDownLeft';
import { faPhoneArrowRight as farPhoneArrowRight } from '@fortawesome/pro-regular-svg-icons/faPhoneArrowRight';
import { faPhoneArrowUp as farPhoneArrowUp } from '@fortawesome/pro-regular-svg-icons/faPhoneArrowUp';
import { faPhoneArrowUpRight as farPhoneArrowUpRight } from '@fortawesome/pro-regular-svg-icons/faPhoneArrowUpRight';
import { faPhoneCircle as farPhoneCircle } from '@fortawesome/pro-regular-svg-icons/faPhoneCircle';
import { faPhoneCircleAlt as farPhoneCircleAlt } from '@fortawesome/pro-regular-svg-icons/faPhoneCircleAlt';
import { faPhoneCircleDown as farPhoneCircleDown } from '@fortawesome/pro-regular-svg-icons/faPhoneCircleDown';
import { faPhoneFlip as farPhoneFlip } from '@fortawesome/pro-regular-svg-icons/faPhoneFlip';
import { faPhoneHangup as farPhoneHangup } from '@fortawesome/pro-regular-svg-icons/faPhoneHangup';
import { faPhoneIncoming as farPhoneIncoming } from '@fortawesome/pro-regular-svg-icons/faPhoneIncoming';
import { faPhoneIntercom as farPhoneIntercom } from '@fortawesome/pro-regular-svg-icons/faPhoneIntercom';
import { faPhoneLaptop as farPhoneLaptop } from '@fortawesome/pro-regular-svg-icons/faPhoneLaptop';
import { faPhoneMissed as farPhoneMissed } from '@fortawesome/pro-regular-svg-icons/faPhoneMissed';
import { faPhoneOffice as farPhoneOffice } from '@fortawesome/pro-regular-svg-icons/faPhoneOffice';
import { faPhoneOutgoing as farPhoneOutgoing } from '@fortawesome/pro-regular-svg-icons/faPhoneOutgoing';
import { faPhonePlus as farPhonePlus } from '@fortawesome/pro-regular-svg-icons/faPhonePlus';
import { faPhoneRotary as farPhoneRotary } from '@fortawesome/pro-regular-svg-icons/faPhoneRotary';
import { faPhoneSlash as farPhoneSlash } from '@fortawesome/pro-regular-svg-icons/faPhoneSlash';
import { faPhoneSquare as farPhoneSquare } from '@fortawesome/pro-regular-svg-icons/faPhoneSquare';
import { faPhoneSquareAlt as farPhoneSquareAlt } from '@fortawesome/pro-regular-svg-icons/faPhoneSquareAlt';
import { faPhoneSquareDown as farPhoneSquareDown } from '@fortawesome/pro-regular-svg-icons/faPhoneSquareDown';
import { faPhoneVolume as farPhoneVolume } from '@fortawesome/pro-regular-svg-icons/faPhoneVolume';
import { faPhoneXmark as farPhoneXmark } from '@fortawesome/pro-regular-svg-icons/faPhoneXmark';
import { faPhotoFilm as farPhotoFilm } from '@fortawesome/pro-regular-svg-icons/faPhotoFilm';
import { faPhotoFilmMusic as farPhotoFilmMusic } from '@fortawesome/pro-regular-svg-icons/faPhotoFilmMusic';
import { faPhotoVideo as farPhotoVideo } from '@fortawesome/pro-regular-svg-icons/faPhotoVideo';
import { faPhp as farPhp } from '@fortawesome/free-brands-svg-icons/faPhp';
import { faPi as farPi } from '@fortawesome/pro-regular-svg-icons/faPi';
import { faPiano as farPiano } from '@fortawesome/pro-regular-svg-icons/faPiano';
import { faPianoKeyboard as farPianoKeyboard } from '@fortawesome/pro-regular-svg-icons/faPianoKeyboard';
import { faPickaxe as farPickaxe } from '@fortawesome/pro-regular-svg-icons/faPickaxe';
import { faPickleball as farPickleball } from '@fortawesome/pro-regular-svg-icons/faPickleball';
import { faPie as farPie } from '@fortawesome/pro-regular-svg-icons/faPie';
import { faPieChart as farPieChart } from '@fortawesome/pro-regular-svg-icons/faPieChart';
import { faPiedPiper as farPiedPiper } from '@fortawesome/free-brands-svg-icons/faPiedPiper';
import { faPiedPiperAlt as farPiedPiperAlt } from '@fortawesome/free-brands-svg-icons/faPiedPiperAlt';
import { faPiedPiperHat as farPiedPiperHat } from '@fortawesome/free-brands-svg-icons/faPiedPiperHat';
import { faPiedPiperPp as farPiedPiperPp } from '@fortawesome/free-brands-svg-icons/faPiedPiperPp';
import { faPiedPiperSquare as farPiedPiperSquare } from '@fortawesome/free-brands-svg-icons/faPiedPiperSquare';
import { faPig as farPig } from '@fortawesome/pro-regular-svg-icons/faPig';
import { faPiggyBank as farPiggyBank } from '@fortawesome/pro-regular-svg-icons/faPiggyBank';
import { faPills as farPills } from '@fortawesome/pro-regular-svg-icons/faPills';
import { faPinata as farPinata } from '@fortawesome/pro-regular-svg-icons/faPinata';
import { faPinball as farPinball } from '@fortawesome/pro-regular-svg-icons/faPinball';
import { faPineapple as farPineapple } from '@fortawesome/pro-regular-svg-icons/faPineapple';
import { faPingPongPaddleBall as farPingPongPaddleBall } from '@fortawesome/pro-regular-svg-icons/faPingPongPaddleBall';
import { faPinterest as farPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faPinterestP as farPinterestP } from '@fortawesome/free-brands-svg-icons/faPinterestP';
import { faPinterestSquare as farPinterestSquare } from '@fortawesome/free-brands-svg-icons/faPinterestSquare';
import { faPipe as farPipe } from '@fortawesome/pro-regular-svg-icons/faPipe';
import { faPipeCircleCheck as farPipeCircleCheck } from '@fortawesome/pro-regular-svg-icons/faPipeCircleCheck';
import { faPipeCollar as farPipeCollar } from '@fortawesome/pro-regular-svg-icons/faPipeCollar';
import { faPipeSection as farPipeSection } from '@fortawesome/pro-regular-svg-icons/faPipeSection';
import { faPipeSmoking as farPipeSmoking } from '@fortawesome/pro-regular-svg-icons/faPipeSmoking';
import { faPipeValve as farPipeValve } from '@fortawesome/pro-regular-svg-icons/faPipeValve';
import { faPix as farPix } from '@fortawesome/free-brands-svg-icons/faPix';
import { faPixiv as farPixiv } from '@fortawesome/free-brands-svg-icons/faPixiv';
import { faPizza as farPizza } from '@fortawesome/pro-regular-svg-icons/faPizza';
import { faPizzaSlice as farPizzaSlice } from '@fortawesome/pro-regular-svg-icons/faPizzaSlice';
import { faPlaceOfWorship as farPlaceOfWorship } from '@fortawesome/pro-regular-svg-icons/faPlaceOfWorship';
import { faPlane as farPlane } from '@fortawesome/pro-regular-svg-icons/faPlane';
import { faPlaneAlt as farPlaneAlt } from '@fortawesome/pro-regular-svg-icons/faPlaneAlt';
import { faPlaneArrival as farPlaneArrival } from '@fortawesome/pro-regular-svg-icons/faPlaneArrival';
import { faPlaneCircleCheck as farPlaneCircleCheck } from '@fortawesome/pro-regular-svg-icons/faPlaneCircleCheck';
import { faPlaneCircleExclamation as farPlaneCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faPlaneCircleExclamation';
import { faPlaneCircleXmark as farPlaneCircleXmark } from '@fortawesome/pro-regular-svg-icons/faPlaneCircleXmark';
import { faPlaneDeparture as farPlaneDeparture } from '@fortawesome/pro-regular-svg-icons/faPlaneDeparture';
import { faPlaneEngines as farPlaneEngines } from '@fortawesome/pro-regular-svg-icons/faPlaneEngines';
import { faPlaneLock as farPlaneLock } from '@fortawesome/pro-regular-svg-icons/faPlaneLock';
import { faPlaneProp as farPlaneProp } from '@fortawesome/pro-regular-svg-icons/faPlaneProp';
import { faPlaneSlash as farPlaneSlash } from '@fortawesome/pro-regular-svg-icons/faPlaneSlash';
import { faPlaneTail as farPlaneTail } from '@fortawesome/pro-regular-svg-icons/faPlaneTail';
import { faPlanetMoon as farPlanetMoon } from '@fortawesome/pro-regular-svg-icons/faPlanetMoon';
import { faPlanetRinged as farPlanetRinged } from '@fortawesome/pro-regular-svg-icons/faPlanetRinged';
import { faPlaneUp as farPlaneUp } from '@fortawesome/pro-regular-svg-icons/faPlaneUp';
import { faPlaneUpSlash as farPlaneUpSlash } from '@fortawesome/pro-regular-svg-icons/faPlaneUpSlash';
import { faPlantWilt as farPlantWilt } from '@fortawesome/pro-regular-svg-icons/faPlantWilt';
import { faPlateUtensils as farPlateUtensils } from '@fortawesome/pro-regular-svg-icons/faPlateUtensils';
import { faPlateWheat as farPlateWheat } from '@fortawesome/pro-regular-svg-icons/faPlateWheat';
import { faPlay as farPlay } from '@fortawesome/pro-regular-svg-icons/faPlay';
import { faPlayCircle as farPlayCircle } from '@fortawesome/pro-regular-svg-icons/faPlayCircle';
import { faPlayPause as farPlayPause } from '@fortawesome/pro-regular-svg-icons/faPlayPause';
import { faPlaystation as farPlaystation } from '@fortawesome/free-brands-svg-icons/faPlaystation';
import { faPlug as farPlug } from '@fortawesome/pro-regular-svg-icons/faPlug';
import { faPlugCircleBolt as farPlugCircleBolt } from '@fortawesome/pro-regular-svg-icons/faPlugCircleBolt';
import { faPlugCircleCheck as farPlugCircleCheck } from '@fortawesome/pro-regular-svg-icons/faPlugCircleCheck';
import { faPlugCircleExclamation as farPlugCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faPlugCircleExclamation';
import { faPlugCircleMinus as farPlugCircleMinus } from '@fortawesome/pro-regular-svg-icons/faPlugCircleMinus';
import { faPlugCirclePlus as farPlugCirclePlus } from '@fortawesome/pro-regular-svg-icons/faPlugCirclePlus';
import { faPlugCircleXmark as farPlugCircleXmark } from '@fortawesome/pro-regular-svg-icons/faPlugCircleXmark';
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faPlusCircle as farPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import { faPlusHexagon as farPlusHexagon } from '@fortawesome/pro-regular-svg-icons/faPlusHexagon';
import { faPlusLarge as farPlusLarge } from '@fortawesome/pro-regular-svg-icons/faPlusLarge';
import { faPlusMinus as farPlusMinus } from '@fortawesome/pro-regular-svg-icons/faPlusMinus';
import { faPlusOctagon as farPlusOctagon } from '@fortawesome/pro-regular-svg-icons/faPlusOctagon';
import { faPlusSquare as farPlusSquare } from '@fortawesome/pro-regular-svg-icons/faPlusSquare';
import { faPodcast as farPodcast } from '@fortawesome/pro-regular-svg-icons/faPodcast';
import { faPodium as farPodium } from '@fortawesome/pro-regular-svg-icons/faPodium';
import { faPodiumStar as farPodiumStar } from '@fortawesome/pro-regular-svg-icons/faPodiumStar';
import { faPoliceBox as farPoliceBox } from '@fortawesome/pro-regular-svg-icons/faPoliceBox';
import { faPoll as farPoll } from '@fortawesome/pro-regular-svg-icons/faPoll';
import { faPollH as farPollH } from '@fortawesome/pro-regular-svg-icons/faPollH';
import { faPollPeople as farPollPeople } from '@fortawesome/pro-regular-svg-icons/faPollPeople';
import { faPompebled as farPompebled } from '@fortawesome/pro-regular-svg-icons/faPompebled';
import { faPoo as farPoo } from '@fortawesome/pro-regular-svg-icons/faPoo';
import { faPooBolt as farPooBolt } from '@fortawesome/pro-regular-svg-icons/faPooBolt';
import { faPool8Ball as farPool8Ball } from '@fortawesome/pro-regular-svg-icons/faPool8Ball';
import { faPoop as farPoop } from '@fortawesome/pro-regular-svg-icons/faPoop';
import { faPooStorm as farPooStorm } from '@fortawesome/pro-regular-svg-icons/faPooStorm';
import { faPopcorn as farPopcorn } from '@fortawesome/pro-regular-svg-icons/faPopcorn';
import { faPopsicle as farPopsicle } from '@fortawesome/pro-regular-svg-icons/faPopsicle';
import { faPortalEnter as farPortalEnter } from '@fortawesome/pro-regular-svg-icons/faPortalEnter';
import { faPortalExit as farPortalExit } from '@fortawesome/pro-regular-svg-icons/faPortalExit';
import { faPortrait as farPortrait } from '@fortawesome/pro-regular-svg-icons/faPortrait';
import { faPotato as farPotato } from '@fortawesome/pro-regular-svg-icons/faPotato';
import { faPotFood as farPotFood } from '@fortawesome/pro-regular-svg-icons/faPotFood';
import { faPoundSign as farPoundSign } from '@fortawesome/pro-regular-svg-icons/faPoundSign';
import { faPowerOff as farPowerOff } from '@fortawesome/pro-regular-svg-icons/faPowerOff';
import { faPray as farPray } from '@fortawesome/pro-regular-svg-icons/faPray';
import { faPrayingHands as farPrayingHands } from '@fortawesome/pro-regular-svg-icons/faPrayingHands';
import { faPrescription as farPrescription } from '@fortawesome/pro-regular-svg-icons/faPrescription';
import { faPrescriptionBottle as farPrescriptionBottle } from '@fortawesome/pro-regular-svg-icons/faPrescriptionBottle';
import { faPrescriptionBottleAlt as farPrescriptionBottleAlt } from '@fortawesome/pro-regular-svg-icons/faPrescriptionBottleAlt';
import { faPrescriptionBottleMedical as farPrescriptionBottleMedical } from '@fortawesome/pro-regular-svg-icons/faPrescriptionBottleMedical';
import { faPrescriptionBottlePill as farPrescriptionBottlePill } from '@fortawesome/pro-regular-svg-icons/faPrescriptionBottlePill';
import { faPresentation as farPresentation } from '@fortawesome/pro-regular-svg-icons/faPresentation';
import { faPresentationScreen as farPresentationScreen } from '@fortawesome/pro-regular-svg-icons/faPresentationScreen';
import { faPretzel as farPretzel } from '@fortawesome/pro-regular-svg-icons/faPretzel';
import { faPrint as farPrint } from '@fortawesome/pro-regular-svg-icons/faPrint';
import { faPrintMagnifyingGlass as farPrintMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faPrintMagnifyingGlass';
import { faPrintSearch as farPrintSearch } from '@fortawesome/pro-regular-svg-icons/faPrintSearch';
import { faPrintSlash as farPrintSlash } from '@fortawesome/pro-regular-svg-icons/faPrintSlash';
import { faPro as farPro } from '@fortawesome/pro-regular-svg-icons/faPro';
import { faProcedures as farProcedures } from '@fortawesome/pro-regular-svg-icons/faProcedures';
import { faProductHunt as farProductHunt } from '@fortawesome/free-brands-svg-icons/faProductHunt';
import { faProjectDiagram as farProjectDiagram } from '@fortawesome/pro-regular-svg-icons/faProjectDiagram';
import { faProjector as farProjector } from '@fortawesome/pro-regular-svg-icons/faProjector';
import { faPump as farPump } from '@fortawesome/pro-regular-svg-icons/faPump';
import { faPumpkin as farPumpkin } from '@fortawesome/pro-regular-svg-icons/faPumpkin';
import { faPumpMedical as farPumpMedical } from '@fortawesome/pro-regular-svg-icons/faPumpMedical';
import { faPumpSoap as farPumpSoap } from '@fortawesome/pro-regular-svg-icons/faPumpSoap';
import { faPushed as farPushed } from '@fortawesome/free-brands-svg-icons/faPushed';
import { faPuzzle as farPuzzle } from '@fortawesome/pro-regular-svg-icons/faPuzzle';
import { faPuzzlePiece as farPuzzlePiece } from '@fortawesome/pro-regular-svg-icons/faPuzzlePiece';
import { faPuzzlePieceAlt as farPuzzlePieceAlt } from '@fortawesome/pro-regular-svg-icons/faPuzzlePieceAlt';
import { faPuzzlePieceSimple as farPuzzlePieceSimple } from '@fortawesome/pro-regular-svg-icons/faPuzzlePieceSimple';
import { faPython as farPython } from '@fortawesome/free-brands-svg-icons/faPython';
import { faQ as farQ } from '@fortawesome/pro-regular-svg-icons/faQ';
import { faQq as farQq } from '@fortawesome/free-brands-svg-icons/faQq';
import { faQrcode as farQrcode } from '@fortawesome/pro-regular-svg-icons/faQrcode';
import { faQuestion as farQuestion } from '@fortawesome/pro-regular-svg-icons/faQuestion';
import { faQuestionCircle as farQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';
import { faQuestionSquare as farQuestionSquare } from '@fortawesome/pro-regular-svg-icons/faQuestionSquare';
import { faQuidditch as farQuidditch } from '@fortawesome/pro-regular-svg-icons/faQuidditch';
import { faQuidditchBroomBall as farQuidditchBroomBall } from '@fortawesome/pro-regular-svg-icons/faQuidditchBroomBall';
import { faQuinscape as farQuinscape } from '@fortawesome/free-brands-svg-icons/faQuinscape';
import { faQuora as farQuora } from '@fortawesome/free-brands-svg-icons/faQuora';
import { faQuoteLeft as farQuoteLeft } from '@fortawesome/pro-regular-svg-icons/faQuoteLeft';
import { faQuoteLeftAlt as farQuoteLeftAlt } from '@fortawesome/pro-regular-svg-icons/faQuoteLeftAlt';
import { faQuoteRight as farQuoteRight } from '@fortawesome/pro-regular-svg-icons/faQuoteRight';
import { faQuoteRightAlt as farQuoteRightAlt } from '@fortawesome/pro-regular-svg-icons/faQuoteRightAlt';
import { faQuotes as farQuotes } from '@fortawesome/pro-regular-svg-icons/faQuotes';
import { faQuran as farQuran } from '@fortawesome/pro-regular-svg-icons/faQuran';
import { faR as farR } from '@fortawesome/pro-regular-svg-icons/faR';
import { faRabbit as farRabbit } from '@fortawesome/pro-regular-svg-icons/faRabbit';
import { faRabbitFast as farRabbitFast } from '@fortawesome/pro-regular-svg-icons/faRabbitFast';
import { faRabbitRunning as farRabbitRunning } from '@fortawesome/pro-regular-svg-icons/faRabbitRunning';
import { faRaccoon as farRaccoon } from '@fortawesome/pro-regular-svg-icons/faRaccoon';
import { faRacquet as farRacquet } from '@fortawesome/pro-regular-svg-icons/faRacquet';
import { faRadar as farRadar } from '@fortawesome/pro-regular-svg-icons/faRadar';
import { faRadiation as farRadiation } from '@fortawesome/pro-regular-svg-icons/faRadiation';
import { faRadiationAlt as farRadiationAlt } from '@fortawesome/pro-regular-svg-icons/faRadiationAlt';
import { faRadio as farRadio } from '@fortawesome/pro-regular-svg-icons/faRadio';
import { faRadioAlt as farRadioAlt } from '@fortawesome/pro-regular-svg-icons/faRadioAlt';
import { faRadioTuner as farRadioTuner } from '@fortawesome/pro-regular-svg-icons/faRadioTuner';
import { faRainbow as farRainbow } from '@fortawesome/pro-regular-svg-icons/faRainbow';
import { faRaindrops as farRaindrops } from '@fortawesome/pro-regular-svg-icons/faRaindrops';
import { faRam as farRam } from '@fortawesome/pro-regular-svg-icons/faRam';
import { faRampLoading as farRampLoading } from '@fortawesome/pro-regular-svg-icons/faRampLoading';
import { faRandom as farRandom } from '@fortawesome/pro-regular-svg-icons/faRandom';
import { faRankingStar as farRankingStar } from '@fortawesome/pro-regular-svg-icons/faRankingStar';
import { faRaspberryPi as farRaspberryPi } from '@fortawesome/free-brands-svg-icons/faRaspberryPi';
import { faRavelry as farRavelry } from '@fortawesome/free-brands-svg-icons/faRavelry';
import { faRaygun as farRaygun } from '@fortawesome/pro-regular-svg-icons/faRaygun';
import { faReact as farReact } from '@fortawesome/free-brands-svg-icons/faReact';
import { faReacteurope as farReacteurope } from '@fortawesome/free-brands-svg-icons/faReacteurope';
import { faReadme as farReadme } from '@fortawesome/free-brands-svg-icons/faReadme';
import { faRebel as farRebel } from '@fortawesome/free-brands-svg-icons/faRebel';
import { faReceipt as farReceipt } from '@fortawesome/pro-regular-svg-icons/faReceipt';
import { faRecordVinyl as farRecordVinyl } from '@fortawesome/pro-regular-svg-icons/faRecordVinyl';
import { faRectangle as farRectangle } from '@fortawesome/pro-regular-svg-icons/faRectangle';
import { faRectangleAd as farRectangleAd } from '@fortawesome/pro-regular-svg-icons/faRectangleAd';
import { faRectangleBarcode as farRectangleBarcode } from '@fortawesome/pro-regular-svg-icons/faRectangleBarcode';
import { faRectangleCode as farRectangleCode } from '@fortawesome/pro-regular-svg-icons/faRectangleCode';
import { faRectangleHd as farRectangleHd } from '@fortawesome/pro-regular-svg-icons/faRectangleHd';
import { faRectangleHistory as farRectangleHistory } from '@fortawesome/pro-regular-svg-icons/faRectangleHistory';
import { faRectangleHistoryCirclePlus as farRectangleHistoryCirclePlus } from '@fortawesome/pro-regular-svg-icons/faRectangleHistoryCirclePlus';
import { faRectangleHistoryCircleUser as farRectangleHistoryCircleUser } from '@fortawesome/pro-regular-svg-icons/faRectangleHistoryCircleUser';
import { faRectangleLandscape as farRectangleLandscape } from '@fortawesome/pro-regular-svg-icons/faRectangleLandscape';
import { faRectangleList as farRectangleList } from '@fortawesome/pro-regular-svg-icons/faRectangleList';
import { faRectanglePortrait as farRectanglePortrait } from '@fortawesome/pro-regular-svg-icons/faRectanglePortrait';
import { faRectanglePro as farRectanglePro } from '@fortawesome/pro-regular-svg-icons/faRectanglePro';
import { faRectangleSd as farRectangleSd } from '@fortawesome/pro-regular-svg-icons/faRectangleSd';
import { faRectanglesMixed as farRectanglesMixed } from '@fortawesome/pro-regular-svg-icons/faRectanglesMixed';
import { faRectangleTerminal as farRectangleTerminal } from '@fortawesome/pro-regular-svg-icons/faRectangleTerminal';
import { faRectangleTimes as farRectangleTimes } from '@fortawesome/pro-regular-svg-icons/faRectangleTimes';
import { faRectangleVertical as farRectangleVertical } from '@fortawesome/pro-regular-svg-icons/faRectangleVertical';
import { faRectangleVerticalHistory as farRectangleVerticalHistory } from '@fortawesome/pro-regular-svg-icons/faRectangleVerticalHistory';
import { faRectangleWide as farRectangleWide } from '@fortawesome/pro-regular-svg-icons/faRectangleWide';
import { faRectangleXmark as farRectangleXmark } from '@fortawesome/pro-regular-svg-icons/faRectangleXmark';
import { faRecycle as farRecycle } from '@fortawesome/pro-regular-svg-icons/faRecycle';
import { faReddit as farReddit } from '@fortawesome/free-brands-svg-icons/faReddit';
import { faRedditAlien as farRedditAlien } from '@fortawesome/free-brands-svg-icons/faRedditAlien';
import { faRedditSquare as farRedditSquare } from '@fortawesome/free-brands-svg-icons/faRedditSquare';
import { faRedhat as farRedhat } from '@fortawesome/free-brands-svg-icons/faRedhat';
import { faRedo as farRedo } from '@fortawesome/pro-regular-svg-icons/faRedo';
import { faRedoAlt as farRedoAlt } from '@fortawesome/pro-regular-svg-icons/faRedoAlt';
import { faRedRiver as farRedRiver } from '@fortawesome/free-brands-svg-icons/faRedRiver';
import { faReel as farReel } from '@fortawesome/pro-regular-svg-icons/faReel';
import { faReflectHorizontal as farReflectHorizontal } from '@fortawesome/pro-regular-svg-icons/faReflectHorizontal';
import { faReflectVertical as farReflectVertical } from '@fortawesome/pro-regular-svg-icons/faReflectVertical';
import { faRefresh as farRefresh } from '@fortawesome/pro-regular-svg-icons/faRefresh';
import { faRefrigerator as farRefrigerator } from '@fortawesome/pro-regular-svg-icons/faRefrigerator';
import { faRegistered as farRegistered } from '@fortawesome/pro-regular-svg-icons/faRegistered';
import { faRemove as farRemove } from '@fortawesome/pro-regular-svg-icons/faRemove';
import { faRemoveFormat as farRemoveFormat } from '@fortawesome/pro-regular-svg-icons/faRemoveFormat';
import { faRendact as farRendact } from '@fortawesome/free-brands-svg-icons/faRendact';
import { faRenren as farRenren } from '@fortawesome/free-brands-svg-icons/faRenren';
import { faReorder as farReorder } from '@fortawesome/pro-regular-svg-icons/faReorder';
import { faRepeat as farRepeat } from '@fortawesome/pro-regular-svg-icons/faRepeat';
import { faRepeat1 as farRepeat1 } from '@fortawesome/pro-regular-svg-icons/faRepeat1';
import { faRepeat1Alt as farRepeat1Alt } from '@fortawesome/pro-regular-svg-icons/faRepeat1Alt';
import { faRepeatAlt as farRepeatAlt } from '@fortawesome/pro-regular-svg-icons/faRepeatAlt';
import { faReply as farReply } from '@fortawesome/pro-regular-svg-icons/faReply';
import { faReplyAll as farReplyAll } from '@fortawesome/pro-regular-svg-icons/faReplyAll';
import { faReplyClock as farReplyClock } from '@fortawesome/pro-regular-svg-icons/faReplyClock';
import { faReplyd as farReplyd } from '@fortawesome/free-brands-svg-icons/faReplyd';
import { faReplyTime as farReplyTime } from '@fortawesome/pro-regular-svg-icons/faReplyTime';
import { faRepublican as farRepublican } from '@fortawesome/pro-regular-svg-icons/faRepublican';
import { faResearchgate as farResearchgate } from '@fortawesome/free-brands-svg-icons/faResearchgate';
import { faResolving as farResolving } from '@fortawesome/free-brands-svg-icons/faResolving';
import { faRestroom as farRestroom } from '@fortawesome/pro-regular-svg-icons/faRestroom';
import { faRestroomSimple as farRestroomSimple } from '@fortawesome/pro-regular-svg-icons/faRestroomSimple';
import { faRetweet as farRetweet } from '@fortawesome/pro-regular-svg-icons/faRetweet';
import { faRetweetAlt as farRetweetAlt } from '@fortawesome/pro-regular-svg-icons/faRetweetAlt';
import { faRev as farRev } from '@fortawesome/free-brands-svg-icons/faRev';
import { faRhombus as farRhombus } from '@fortawesome/pro-regular-svg-icons/faRhombus';
import { faRibbon as farRibbon } from '@fortawesome/pro-regular-svg-icons/faRibbon';
import { faRight as farRight } from '@fortawesome/pro-regular-svg-icons/faRight';
import { faRightFromBracket as farRightFromBracket } from '@fortawesome/pro-regular-svg-icons/faRightFromBracket';
import { faRightFromLine as farRightFromLine } from '@fortawesome/pro-regular-svg-icons/faRightFromLine';
import { faRightLeft as farRightLeft } from '@fortawesome/pro-regular-svg-icons/faRightLeft';
import { faRightLeftLarge as farRightLeftLarge } from '@fortawesome/pro-regular-svg-icons/faRightLeftLarge';
import { faRightLong as farRightLong } from '@fortawesome/pro-regular-svg-icons/faRightLong';
import { faRightLongToLine as farRightLongToLine } from '@fortawesome/pro-regular-svg-icons/faRightLongToLine';
import { faRightToBracket as farRightToBracket } from '@fortawesome/pro-regular-svg-icons/faRightToBracket';
import { faRightToLine as farRightToLine } from '@fortawesome/pro-regular-svg-icons/faRightToLine';
import { faRing as farRing } from '@fortawesome/pro-regular-svg-icons/faRing';
import { faRingDiamond as farRingDiamond } from '@fortawesome/pro-regular-svg-icons/faRingDiamond';
import { faRingsWedding as farRingsWedding } from '@fortawesome/pro-regular-svg-icons/faRingsWedding';
import { faRmb as farRmb } from '@fortawesome/pro-regular-svg-icons/faRmb';
import { faRoad as farRoad } from '@fortawesome/pro-regular-svg-icons/faRoad';
import { faRoadBarrier as farRoadBarrier } from '@fortawesome/pro-regular-svg-icons/faRoadBarrier';
import { faRoadBridge as farRoadBridge } from '@fortawesome/pro-regular-svg-icons/faRoadBridge';
import { faRoadCircleCheck as farRoadCircleCheck } from '@fortawesome/pro-regular-svg-icons/faRoadCircleCheck';
import { faRoadCircleExclamation as farRoadCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faRoadCircleExclamation';
import { faRoadCircleXmark as farRoadCircleXmark } from '@fortawesome/pro-regular-svg-icons/faRoadCircleXmark';
import { faRoadLock as farRoadLock } from '@fortawesome/pro-regular-svg-icons/faRoadLock';
import { faRoadSpikes as farRoadSpikes } from '@fortawesome/pro-regular-svg-icons/faRoadSpikes';
import { faRobot as farRobot } from '@fortawesome/pro-regular-svg-icons/faRobot';
import { faRobotAstromech as farRobotAstromech } from '@fortawesome/pro-regular-svg-icons/faRobotAstromech';
import { faRocket as farRocket } from '@fortawesome/pro-regular-svg-icons/faRocket';
import { faRocketchat as farRocketchat } from '@fortawesome/free-brands-svg-icons/faRocketchat';
import { faRocketLaunch as farRocketLaunch } from '@fortawesome/pro-regular-svg-icons/faRocketLaunch';
import { faRockrms as farRockrms } from '@fortawesome/free-brands-svg-icons/faRockrms';
import { faRodAsclepius as farRodAsclepius } from '@fortawesome/pro-regular-svg-icons/faRodAsclepius';
import { faRodSnake as farRodSnake } from '@fortawesome/pro-regular-svg-icons/faRodSnake';
import { faRollerCoaster as farRollerCoaster } from '@fortawesome/pro-regular-svg-icons/faRollerCoaster';
import { faRotate as farRotate } from '@fortawesome/pro-regular-svg-icons/faRotate';
import { faRotateBack as farRotateBack } from '@fortawesome/pro-regular-svg-icons/faRotateBack';
import { faRotateBackward as farRotateBackward } from '@fortawesome/pro-regular-svg-icons/faRotateBackward';
import { faRotateExclamation as farRotateExclamation } from '@fortawesome/pro-regular-svg-icons/faRotateExclamation';
import { faRotateForward as farRotateForward } from '@fortawesome/pro-regular-svg-icons/faRotateForward';
import { faRotateLeft as farRotateLeft } from '@fortawesome/pro-regular-svg-icons/faRotateLeft';
import { faRotateReverse as farRotateReverse } from '@fortawesome/pro-regular-svg-icons/faRotateReverse';
import { faRotateRight as farRotateRight } from '@fortawesome/pro-regular-svg-icons/faRotateRight';
import { faRouble as farRouble } from '@fortawesome/pro-regular-svg-icons/faRouble';
import { faRoute as farRoute } from '@fortawesome/pro-regular-svg-icons/faRoute';
import { faRouteHighway as farRouteHighway } from '@fortawesome/pro-regular-svg-icons/faRouteHighway';
import { faRouteInterstate as farRouteInterstate } from '@fortawesome/pro-regular-svg-icons/faRouteInterstate';
import { faRouter as farRouter } from '@fortawesome/pro-regular-svg-icons/faRouter';
import { faRows as farRows } from '@fortawesome/pro-regular-svg-icons/faRows';
import { faRProject as farRProject } from '@fortawesome/free-brands-svg-icons/faRProject';
import { faRss as farRss } from '@fortawesome/pro-regular-svg-icons/faRss';
import { faRssSquare as farRssSquare } from '@fortawesome/pro-regular-svg-icons/faRssSquare';
import { faRub as farRub } from '@fortawesome/pro-regular-svg-icons/faRub';
import { faRuble as farRuble } from '@fortawesome/pro-regular-svg-icons/faRuble';
import { faRubleSign as farRubleSign } from '@fortawesome/pro-regular-svg-icons/faRubleSign';
import { faRug as farRug } from '@fortawesome/pro-regular-svg-icons/faRug';
import { faRugbyBall as farRugbyBall } from '@fortawesome/pro-regular-svg-icons/faRugbyBall';
import { faRuler as farRuler } from '@fortawesome/pro-regular-svg-icons/faRuler';
import { faRulerCombined as farRulerCombined } from '@fortawesome/pro-regular-svg-icons/faRulerCombined';
import { faRulerHorizontal as farRulerHorizontal } from '@fortawesome/pro-regular-svg-icons/faRulerHorizontal';
import { faRulerTriangle as farRulerTriangle } from '@fortawesome/pro-regular-svg-icons/faRulerTriangle';
import { faRulerVertical as farRulerVertical } from '@fortawesome/pro-regular-svg-icons/faRulerVertical';
import { faRunning as farRunning } from '@fortawesome/pro-regular-svg-icons/faRunning';
import { faRupee as farRupee } from '@fortawesome/pro-regular-svg-icons/faRupee';
import { faRupeeSign as farRupeeSign } from '@fortawesome/pro-regular-svg-icons/faRupeeSign';
import { faRupiahSign as farRupiahSign } from '@fortawesome/pro-regular-svg-icons/faRupiahSign';
import { faRust as farRust } from '@fortawesome/free-brands-svg-icons/faRust';
import { faRv as farRv } from '@fortawesome/pro-regular-svg-icons/faRv';
import { faS as farS } from '@fortawesome/pro-regular-svg-icons/faS';
import { faSack as farSack } from '@fortawesome/pro-regular-svg-icons/faSack';
import { faSackDollar as farSackDollar } from '@fortawesome/pro-regular-svg-icons/faSackDollar';
import { faSackXmark as farSackXmark } from '@fortawesome/pro-regular-svg-icons/faSackXmark';
import { faSadCry as farSadCry } from '@fortawesome/pro-regular-svg-icons/faSadCry';
import { faSadTear as farSadTear } from '@fortawesome/pro-regular-svg-icons/faSadTear';
import { faSafari as farSafari } from '@fortawesome/free-brands-svg-icons/faSafari';
import { faSailboat as farSailboat } from '@fortawesome/pro-regular-svg-icons/faSailboat';
import { faSalad as farSalad } from '@fortawesome/pro-regular-svg-icons/faSalad';
import { faSalesforce as farSalesforce } from '@fortawesome/free-brands-svg-icons/faSalesforce';
import { faSaltShaker as farSaltShaker } from '@fortawesome/pro-regular-svg-icons/faSaltShaker';
import { faSandwich as farSandwich } from '@fortawesome/pro-regular-svg-icons/faSandwich';
import { faSass as farSass } from '@fortawesome/free-brands-svg-icons/faSass';
import { faSatellite as farSatellite } from '@fortawesome/pro-regular-svg-icons/faSatellite';
import { faSatelliteDish as farSatelliteDish } from '@fortawesome/pro-regular-svg-icons/faSatelliteDish';
import { faSausage as farSausage } from '@fortawesome/pro-regular-svg-icons/faSausage';
import { faSave as farSave } from '@fortawesome/pro-regular-svg-icons/faSave';
import { faSaveCircleArrowRight as farSaveCircleArrowRight } from '@fortawesome/pro-regular-svg-icons/faSaveCircleArrowRight';
import { faSaveCircleXmark as farSaveCircleXmark } from '@fortawesome/pro-regular-svg-icons/faSaveCircleXmark';
import { faSaveTimes as farSaveTimes } from '@fortawesome/pro-regular-svg-icons/faSaveTimes';
import { faSaxHot as farSaxHot } from '@fortawesome/pro-regular-svg-icons/faSaxHot';
import { faSaxophone as farSaxophone } from '@fortawesome/pro-regular-svg-icons/faSaxophone';
import { faSaxophoneFire as farSaxophoneFire } from '@fortawesome/pro-regular-svg-icons/faSaxophoneFire';
import { faScaleBalanced as farScaleBalanced } from '@fortawesome/pro-regular-svg-icons/faScaleBalanced';
import { faScaleUnbalanced as farScaleUnbalanced } from '@fortawesome/pro-regular-svg-icons/faScaleUnbalanced';
import { faScaleUnbalancedFlip as farScaleUnbalancedFlip } from '@fortawesome/pro-regular-svg-icons/faScaleUnbalancedFlip';
import { faScalpel as farScalpel } from '@fortawesome/pro-regular-svg-icons/faScalpel';
import { faScalpelLineDashed as farScalpelLineDashed } from '@fortawesome/pro-regular-svg-icons/faScalpelLineDashed';
import { faScalpelPath as farScalpelPath } from '@fortawesome/pro-regular-svg-icons/faScalpelPath';
import { faScanner as farScanner } from '@fortawesome/pro-regular-svg-icons/faScanner';
import { faScannerGun as farScannerGun } from '@fortawesome/pro-regular-svg-icons/faScannerGun';
import { faScannerImage as farScannerImage } from '@fortawesome/pro-regular-svg-icons/faScannerImage';
import { faScannerKeyboard as farScannerKeyboard } from '@fortawesome/pro-regular-svg-icons/faScannerKeyboard';
import { faScannerTouchscreen as farScannerTouchscreen } from '@fortawesome/pro-regular-svg-icons/faScannerTouchscreen';
import { faScarecrow as farScarecrow } from '@fortawesome/pro-regular-svg-icons/faScarecrow';
import { faScarf as farScarf } from '@fortawesome/pro-regular-svg-icons/faScarf';
import { faSchlix as farSchlix } from '@fortawesome/free-brands-svg-icons/faSchlix';
import { faSchool as farSchool } from '@fortawesome/pro-regular-svg-icons/faSchool';
import { faSchoolCircleCheck as farSchoolCircleCheck } from '@fortawesome/pro-regular-svg-icons/faSchoolCircleCheck';
import { faSchoolCircleExclamation as farSchoolCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faSchoolCircleExclamation';
import { faSchoolCircleXmark as farSchoolCircleXmark } from '@fortawesome/pro-regular-svg-icons/faSchoolCircleXmark';
import { faSchoolFlag as farSchoolFlag } from '@fortawesome/pro-regular-svg-icons/faSchoolFlag';
import { faSchoolLock as farSchoolLock } from '@fortawesome/pro-regular-svg-icons/faSchoolLock';
import { faScissors as farScissors } from '@fortawesome/pro-regular-svg-icons/faScissors';
import { faScreencast as farScreencast } from '@fortawesome/pro-regular-svg-icons/faScreencast';
import { faScreenpal as farScreenpal } from '@fortawesome/free-brands-svg-icons/faScreenpal';
import { faScreenshot as farScreenshot } from '@fortawesome/pro-regular-svg-icons/faScreenshot';
import { faScreenUsers as farScreenUsers } from '@fortawesome/pro-regular-svg-icons/faScreenUsers';
import { faScrewdriver as farScrewdriver } from '@fortawesome/pro-regular-svg-icons/faScrewdriver';
import { faScrewdriverWrench as farScrewdriverWrench } from '@fortawesome/pro-regular-svg-icons/faScrewdriverWrench';
import { faScribble as farScribble } from '@fortawesome/pro-regular-svg-icons/faScribble';
import { faScribd as farScribd } from '@fortawesome/free-brands-svg-icons/faScribd';
import { faScroll as farScroll } from '@fortawesome/pro-regular-svg-icons/faScroll';
import { faScrollOld as farScrollOld } from '@fortawesome/pro-regular-svg-icons/faScrollOld';
import { faScrollRibbon as farScrollRibbon } from '@fortawesome/pro-regular-svg-icons/faScrollRibbon';
import { faScrollTorah as farScrollTorah } from '@fortawesome/pro-regular-svg-icons/faScrollTorah';
import { faScrubber as farScrubber } from '@fortawesome/pro-regular-svg-icons/faScrubber';
import { faScythe as farScythe } from '@fortawesome/pro-regular-svg-icons/faScythe';
import { faSdCard as farSdCard } from '@fortawesome/pro-regular-svg-icons/faSdCard';
import { faSdCards as farSdCards } from '@fortawesome/pro-regular-svg-icons/faSdCards';
import { faSeal as farSeal } from '@fortawesome/pro-regular-svg-icons/faSeal';
import { faSealExclamation as farSealExclamation } from '@fortawesome/pro-regular-svg-icons/faSealExclamation';
import { faSealQuestion as farSealQuestion } from '@fortawesome/pro-regular-svg-icons/faSealQuestion';
import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faSearchDollar as farSearchDollar } from '@fortawesome/pro-regular-svg-icons/faSearchDollar';
import { faSearchengin as farSearchengin } from '@fortawesome/free-brands-svg-icons/faSearchengin';
import { faSearchLocation as farSearchLocation } from '@fortawesome/pro-regular-svg-icons/faSearchLocation';
import { faSearchMinus as farSearchMinus } from '@fortawesome/pro-regular-svg-icons/faSearchMinus';
import { faSearchPlus as farSearchPlus } from '@fortawesome/pro-regular-svg-icons/faSearchPlus';
import { faSeatAirline as farSeatAirline } from '@fortawesome/pro-regular-svg-icons/faSeatAirline';
import { faSection as farSection } from '@fortawesome/pro-regular-svg-icons/faSection';
import { faSeedling as farSeedling } from '@fortawesome/pro-regular-svg-icons/faSeedling';
import { faSellcast as farSellcast } from '@fortawesome/free-brands-svg-icons/faSellcast';
import { faSellsy as farSellsy } from '@fortawesome/free-brands-svg-icons/faSellsy';
import { faSemicolon as farSemicolon } from '@fortawesome/pro-regular-svg-icons/faSemicolon';
import { faSend as farSend } from '@fortawesome/pro-regular-svg-icons/faSend';
import { faSendBack as farSendBack } from '@fortawesome/pro-regular-svg-icons/faSendBack';
import { faSendBackward as farSendBackward } from '@fortawesome/pro-regular-svg-icons/faSendBackward';
import { faSensor as farSensor } from '@fortawesome/pro-regular-svg-icons/faSensor';
import { faSensorAlert as farSensorAlert } from '@fortawesome/pro-regular-svg-icons/faSensorAlert';
import { faSensorCloud as farSensorCloud } from '@fortawesome/pro-regular-svg-icons/faSensorCloud';
import { faSensorFire as farSensorFire } from '@fortawesome/pro-regular-svg-icons/faSensorFire';
import { faSensorOn as farSensorOn } from '@fortawesome/pro-regular-svg-icons/faSensorOn';
import { faSensorSmoke as farSensorSmoke } from '@fortawesome/pro-regular-svg-icons/faSensorSmoke';
import { faSensorTriangleExclamation as farSensorTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faSensorTriangleExclamation';
import { faServer as farServer } from '@fortawesome/pro-regular-svg-icons/faServer';
import { faServicestack as farServicestack } from '@fortawesome/free-brands-svg-icons/faServicestack';
import { faShapes as farShapes } from '@fortawesome/pro-regular-svg-icons/faShapes';
import { faShare as farShare } from '@fortawesome/pro-regular-svg-icons/faShare';
import { faShareAll as farShareAll } from '@fortawesome/pro-regular-svg-icons/faShareAll';
import { faShareAlt as farShareAlt } from '@fortawesome/pro-regular-svg-icons/faShareAlt';
import { faShareAltSquare as farShareAltSquare } from '@fortawesome/pro-regular-svg-icons/faShareAltSquare';
import { faShareFromSquare as farShareFromSquare } from '@fortawesome/pro-regular-svg-icons/faShareFromSquare';
import { faShareNodes as farShareNodes } from '@fortawesome/pro-regular-svg-icons/faShareNodes';
import { faShareSquare as farShareSquare } from '@fortawesome/pro-regular-svg-icons/faShareSquare';
import { faSheep as farSheep } from '@fortawesome/pro-regular-svg-icons/faSheep';
import { faSheetPlastic as farSheetPlastic } from '@fortawesome/pro-regular-svg-icons/faSheetPlastic';
import { faShekel as farShekel } from '@fortawesome/pro-regular-svg-icons/faShekel';
import { faShekelSign as farShekelSign } from '@fortawesome/pro-regular-svg-icons/faShekelSign';
import { faShelves as farShelves } from '@fortawesome/pro-regular-svg-icons/faShelves';
import { faShelvesEmpty as farShelvesEmpty } from '@fortawesome/pro-regular-svg-icons/faShelvesEmpty';
import { faSheqel as farSheqel } from '@fortawesome/pro-regular-svg-icons/faSheqel';
import { faSheqelSign as farSheqelSign } from '@fortawesome/pro-regular-svg-icons/faSheqelSign';
import { faShield as farShield } from '@fortawesome/pro-regular-svg-icons/faShield';
import { faShieldAlt as farShieldAlt } from '@fortawesome/pro-regular-svg-icons/faShieldAlt';
import { faShieldBlank as farShieldBlank } from '@fortawesome/pro-regular-svg-icons/faShieldBlank';
import { faShieldCat as farShieldCat } from '@fortawesome/pro-regular-svg-icons/faShieldCat';
import { faShieldCheck as farShieldCheck } from '@fortawesome/pro-regular-svg-icons/faShieldCheck';
import { faShieldCross as farShieldCross } from '@fortawesome/pro-regular-svg-icons/faShieldCross';
import { faShieldDog as farShieldDog } from '@fortawesome/pro-regular-svg-icons/faShieldDog';
import { faShieldExclamation as farShieldExclamation } from '@fortawesome/pro-regular-svg-icons/faShieldExclamation';
import { faShieldHalved as farShieldHalved } from '@fortawesome/pro-regular-svg-icons/faShieldHalved';
import { faShieldHeart as farShieldHeart } from '@fortawesome/pro-regular-svg-icons/faShieldHeart';
import { faShieldKeyhole as farShieldKeyhole } from '@fortawesome/pro-regular-svg-icons/faShieldKeyhole';
import { faShieldMinus as farShieldMinus } from '@fortawesome/pro-regular-svg-icons/faShieldMinus';
import { faShieldPlus as farShieldPlus } from '@fortawesome/pro-regular-svg-icons/faShieldPlus';
import { faShieldQuartered as farShieldQuartered } from '@fortawesome/pro-regular-svg-icons/faShieldQuartered';
import { faShieldSlash as farShieldSlash } from '@fortawesome/pro-regular-svg-icons/faShieldSlash';
import { faShieldTimes as farShieldTimes } from '@fortawesome/pro-regular-svg-icons/faShieldTimes';
import { faShieldVirus as farShieldVirus } from '@fortawesome/pro-regular-svg-icons/faShieldVirus';
import { faShieldXmark as farShieldXmark } from '@fortawesome/pro-regular-svg-icons/faShieldXmark';
import { faShip as farShip } from '@fortawesome/pro-regular-svg-icons/faShip';
import { faShippingFast as farShippingFast } from '@fortawesome/pro-regular-svg-icons/faShippingFast';
import { faShippingTimed as farShippingTimed } from '@fortawesome/pro-regular-svg-icons/faShippingTimed';
import { faShirt as farShirt } from '@fortawesome/pro-regular-svg-icons/faShirt';
import { faShirtLongSleeve as farShirtLongSleeve } from '@fortawesome/pro-regular-svg-icons/faShirtLongSleeve';
import { faShirtRunning as farShirtRunning } from '@fortawesome/pro-regular-svg-icons/faShirtRunning';
import { faShirtsinbulk as farShirtsinbulk } from '@fortawesome/free-brands-svg-icons/faShirtsinbulk';
import { faShirtTankTop as farShirtTankTop } from '@fortawesome/pro-regular-svg-icons/faShirtTankTop';
import { faShishKebab as farShishKebab } from '@fortawesome/pro-regular-svg-icons/faShishKebab';
import { faShoelace as farShoelace } from '@fortawesome/free-brands-svg-icons/faShoelace';
import { faShoePrints as farShoePrints } from '@fortawesome/pro-regular-svg-icons/faShoePrints';
import { faShop as farShop } from '@fortawesome/pro-regular-svg-icons/faShop';
import { faShopify as farShopify } from '@fortawesome/free-brands-svg-icons/faShopify';
import { faShopLock as farShopLock } from '@fortawesome/pro-regular-svg-icons/faShopLock';
import { faShoppingBag as farShoppingBag } from '@fortawesome/pro-regular-svg-icons/faShoppingBag';
import { faShoppingBasket as farShoppingBasket } from '@fortawesome/pro-regular-svg-icons/faShoppingBasket';
import { faShoppingBasketAlt as farShoppingBasketAlt } from '@fortawesome/pro-regular-svg-icons/faShoppingBasketAlt';
import { faShoppingCart as farShoppingCart } from '@fortawesome/pro-regular-svg-icons/faShoppingCart';
import { faShopSlash as farShopSlash } from '@fortawesome/pro-regular-svg-icons/faShopSlash';
import { faShopware as farShopware } from '@fortawesome/free-brands-svg-icons/faShopware';
import { faShortcake as farShortcake } from '@fortawesome/pro-regular-svg-icons/faShortcake';
import { faShovel as farShovel } from '@fortawesome/pro-regular-svg-icons/faShovel';
import { faShovelSnow as farShovelSnow } from '@fortawesome/pro-regular-svg-icons/faShovelSnow';
import { faShower as farShower } from '@fortawesome/pro-regular-svg-icons/faShower';
import { faShowerAlt as farShowerAlt } from '@fortawesome/pro-regular-svg-icons/faShowerAlt';
import { faShowerDown as farShowerDown } from '@fortawesome/pro-regular-svg-icons/faShowerDown';
import { faShredder as farShredder } from '@fortawesome/pro-regular-svg-icons/faShredder';
import { faShrimp as farShrimp } from '@fortawesome/pro-regular-svg-icons/faShrimp';
import { faShuffle as farShuffle } from '@fortawesome/pro-regular-svg-icons/faShuffle';
import { faShutters as farShutters } from '@fortawesome/pro-regular-svg-icons/faShutters';
import { faShuttlecock as farShuttlecock } from '@fortawesome/pro-regular-svg-icons/faShuttlecock';
import { faShuttleSpace as farShuttleSpace } from '@fortawesome/pro-regular-svg-icons/faShuttleSpace';
import { faShuttleVan as farShuttleVan } from '@fortawesome/pro-regular-svg-icons/faShuttleVan';
import { faSickle as farSickle } from '@fortawesome/pro-regular-svg-icons/faSickle';
import { faSidebar as farSidebar } from '@fortawesome/pro-regular-svg-icons/faSidebar';
import { faSidebarFlip as farSidebarFlip } from '@fortawesome/pro-regular-svg-icons/faSidebarFlip';
import { faSigma as farSigma } from '@fortawesome/pro-regular-svg-icons/faSigma';
import { faSign as farSign } from '@fortawesome/pro-regular-svg-icons/faSign';
import { faSignal as farSignal } from '@fortawesome/pro-regular-svg-icons/faSignal';
import { faSignal1 as farSignal1 } from '@fortawesome/pro-regular-svg-icons/faSignal1';
import { faSignal2 as farSignal2 } from '@fortawesome/pro-regular-svg-icons/faSignal2';
import { faSignal3 as farSignal3 } from '@fortawesome/pro-regular-svg-icons/faSignal3';
import { faSignal4 as farSignal4 } from '@fortawesome/pro-regular-svg-icons/faSignal4';
import { faSignal5 as farSignal5 } from '@fortawesome/pro-regular-svg-icons/faSignal5';
import { faSignalAlt as farSignalAlt } from '@fortawesome/pro-regular-svg-icons/faSignalAlt';
import { faSignalAlt1 as farSignalAlt1 } from '@fortawesome/pro-regular-svg-icons/faSignalAlt1';
import { faSignalAlt2 as farSignalAlt2 } from '@fortawesome/pro-regular-svg-icons/faSignalAlt2';
import { faSignalAlt3 as farSignalAlt3 } from '@fortawesome/pro-regular-svg-icons/faSignalAlt3';
import { faSignalAlt4 as farSignalAlt4 } from '@fortawesome/pro-regular-svg-icons/faSignalAlt4';
import { faSignalAltSlash as farSignalAltSlash } from '@fortawesome/pro-regular-svg-icons/faSignalAltSlash';
import { faSignalBars as farSignalBars } from '@fortawesome/pro-regular-svg-icons/faSignalBars';
import { faSignalBarsFair as farSignalBarsFair } from '@fortawesome/pro-regular-svg-icons/faSignalBarsFair';
import { faSignalBarsGood as farSignalBarsGood } from '@fortawesome/pro-regular-svg-icons/faSignalBarsGood';
import { faSignalBarsSlash as farSignalBarsSlash } from '@fortawesome/pro-regular-svg-icons/faSignalBarsSlash';
import { faSignalBarsStrong as farSignalBarsStrong } from '@fortawesome/pro-regular-svg-icons/faSignalBarsStrong';
import { faSignalBarsWeak as farSignalBarsWeak } from '@fortawesome/pro-regular-svg-icons/faSignalBarsWeak';
import { faSignalFair as farSignalFair } from '@fortawesome/pro-regular-svg-icons/faSignalFair';
import { faSignalGood as farSignalGood } from '@fortawesome/pro-regular-svg-icons/faSignalGood';
import { faSignalMessenger as farSignalMessenger } from '@fortawesome/free-brands-svg-icons/faSignalMessenger';
import { faSignalPerfect as farSignalPerfect } from '@fortawesome/pro-regular-svg-icons/faSignalPerfect';
import { faSignalSlash as farSignalSlash } from '@fortawesome/pro-regular-svg-icons/faSignalSlash';
import { faSignalStream as farSignalStream } from '@fortawesome/pro-regular-svg-icons/faSignalStream';
import { faSignalStreamSlash as farSignalStreamSlash } from '@fortawesome/pro-regular-svg-icons/faSignalStreamSlash';
import { faSignalStrong as farSignalStrong } from '@fortawesome/pro-regular-svg-icons/faSignalStrong';
import { faSignalWeak as farSignalWeak } from '@fortawesome/pro-regular-svg-icons/faSignalWeak';
import { faSignature as farSignature } from '@fortawesome/pro-regular-svg-icons/faSignature';
import { faSignatureLock as farSignatureLock } from '@fortawesome/pro-regular-svg-icons/faSignatureLock';
import { faSignatureSlash as farSignatureSlash } from '@fortawesome/pro-regular-svg-icons/faSignatureSlash';
import { faSignHanging as farSignHanging } from '@fortawesome/pro-regular-svg-icons/faSignHanging';
import { faSignIn as farSignIn } from '@fortawesome/pro-regular-svg-icons/faSignIn';
import { faSignInAlt as farSignInAlt } from '@fortawesome/pro-regular-svg-icons/faSignInAlt';
import { faSigning as farSigning } from '@fortawesome/pro-regular-svg-icons/faSigning';
import { faSignLanguage as farSignLanguage } from '@fortawesome/pro-regular-svg-icons/faSignLanguage';
import { faSignOut as farSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut';
import { faSignOutAlt as farSignOutAlt } from '@fortawesome/pro-regular-svg-icons/faSignOutAlt';
import { faSignPost as farSignPost } from '@fortawesome/pro-regular-svg-icons/faSignPost';
import { faSignPosts as farSignPosts } from '@fortawesome/pro-regular-svg-icons/faSignPosts';
import { faSignPostsWrench as farSignPostsWrench } from '@fortawesome/pro-regular-svg-icons/faSignPostsWrench';
import { faSignsPost as farSignsPost } from '@fortawesome/pro-regular-svg-icons/faSignsPost';
import { faSimCard as farSimCard } from '@fortawesome/pro-regular-svg-icons/faSimCard';
import { faSimCards as farSimCards } from '@fortawesome/pro-regular-svg-icons/faSimCards';
import { faSimplybuilt as farSimplybuilt } from '@fortawesome/free-brands-svg-icons/faSimplybuilt';
import { faSink as farSink } from '@fortawesome/pro-regular-svg-icons/faSink';
import { faSiren as farSiren } from '@fortawesome/pro-regular-svg-icons/faSiren';
import { faSirenOn as farSirenOn } from '@fortawesome/pro-regular-svg-icons/faSirenOn';
import { faSistrix as farSistrix } from '@fortawesome/free-brands-svg-icons/faSistrix';
import { faSitemap as farSitemap } from '@fortawesome/pro-regular-svg-icons/faSitemap';
import { faSith as farSith } from '@fortawesome/free-brands-svg-icons/faSith';
import { faSitrox as farSitrox } from '@fortawesome/free-brands-svg-icons/faSitrox';
import { faSkating as farSkating } from '@fortawesome/pro-regular-svg-icons/faSkating';
import { faSkeleton as farSkeleton } from '@fortawesome/pro-regular-svg-icons/faSkeleton';
import { faSkeletonRibs as farSkeletonRibs } from '@fortawesome/pro-regular-svg-icons/faSkeletonRibs';
import { faSketch as farSketch } from '@fortawesome/free-brands-svg-icons/faSketch';
import { faSkiBoot as farSkiBoot } from '@fortawesome/pro-regular-svg-icons/faSkiBoot';
import { faSkiBootSki as farSkiBootSki } from '@fortawesome/pro-regular-svg-icons/faSkiBootSki';
import { faSkiing as farSkiing } from '@fortawesome/pro-regular-svg-icons/faSkiing';
import { faSkiingNordic as farSkiingNordic } from '@fortawesome/pro-regular-svg-icons/faSkiingNordic';
import { faSkiJump as farSkiJump } from '@fortawesome/pro-regular-svg-icons/faSkiJump';
import { faSkiLift as farSkiLift } from '@fortawesome/pro-regular-svg-icons/faSkiLift';
import { faSkull as farSkull } from '@fortawesome/pro-regular-svg-icons/faSkull';
import { faSkullCow as farSkullCow } from '@fortawesome/pro-regular-svg-icons/faSkullCow';
import { faSkullCrossbones as farSkullCrossbones } from '@fortawesome/pro-regular-svg-icons/faSkullCrossbones';
import { faSkyatlas as farSkyatlas } from '@fortawesome/free-brands-svg-icons/faSkyatlas';
import { faSkype as farSkype } from '@fortawesome/free-brands-svg-icons/faSkype';
import { faSlack as farSlack } from '@fortawesome/free-brands-svg-icons/faSlack';
import { faSlackHash as farSlackHash } from '@fortawesome/free-brands-svg-icons/faSlackHash';
import { faSlash as farSlash } from '@fortawesome/pro-regular-svg-icons/faSlash';
import { faSlashBack as farSlashBack } from '@fortawesome/pro-regular-svg-icons/faSlashBack';
import { faSlashForward as farSlashForward } from '@fortawesome/pro-regular-svg-icons/faSlashForward';
import { faSledding as farSledding } from '@fortawesome/pro-regular-svg-icons/faSledding';
import { faSleigh as farSleigh } from '@fortawesome/pro-regular-svg-icons/faSleigh';
import { faSlider as farSlider } from '@fortawesome/pro-regular-svg-icons/faSlider';
import { faSliders as farSliders } from '@fortawesome/pro-regular-svg-icons/faSliders';
import { faSlidersH as farSlidersH } from '@fortawesome/pro-regular-svg-icons/faSlidersH';
import { faSlidersHSquare as farSlidersHSquare } from '@fortawesome/pro-regular-svg-icons/faSlidersHSquare';
import { faSlidersSimple as farSlidersSimple } from '@fortawesome/pro-regular-svg-icons/faSlidersSimple';
import { faSlidersUp as farSlidersUp } from '@fortawesome/pro-regular-svg-icons/faSlidersUp';
import { faSlidersV as farSlidersV } from '@fortawesome/pro-regular-svg-icons/faSlidersV';
import { faSlidersVSquare as farSlidersVSquare } from '@fortawesome/pro-regular-svg-icons/faSlidersVSquare';
import { faSlideshare as farSlideshare } from '@fortawesome/free-brands-svg-icons/faSlideshare';
import { faSlotMachine as farSlotMachine } from '@fortawesome/pro-regular-svg-icons/faSlotMachine';
import { faSmile as farSmile } from '@fortawesome/pro-regular-svg-icons/faSmile';
import { faSmileBeam as farSmileBeam } from '@fortawesome/pro-regular-svg-icons/faSmileBeam';
import { faSmilePlus as farSmilePlus } from '@fortawesome/pro-regular-svg-icons/faSmilePlus';
import { faSmileWink as farSmileWink } from '@fortawesome/pro-regular-svg-icons/faSmileWink';
import { faSmog as farSmog } from '@fortawesome/pro-regular-svg-icons/faSmog';
import { faSmoke as farSmoke } from '@fortawesome/pro-regular-svg-icons/faSmoke';
import { faSmoking as farSmoking } from '@fortawesome/pro-regular-svg-icons/faSmoking';
import { faSmokingBan as farSmokingBan } from '@fortawesome/pro-regular-svg-icons/faSmokingBan';
import { faSms as farSms } from '@fortawesome/pro-regular-svg-icons/faSms';
import { faSnake as farSnake } from '@fortawesome/pro-regular-svg-icons/faSnake';
import { faSnapchat as farSnapchat } from '@fortawesome/free-brands-svg-icons/faSnapchat';
import { faSnapchatGhost as farSnapchatGhost } from '@fortawesome/free-brands-svg-icons/faSnapchatGhost';
import { faSnapchatSquare as farSnapchatSquare } from '@fortawesome/free-brands-svg-icons/faSnapchatSquare';
import { faSnooze as farSnooze } from '@fortawesome/pro-regular-svg-icons/faSnooze';
import { faSnowBlowing as farSnowBlowing } from '@fortawesome/pro-regular-svg-icons/faSnowBlowing';
import { faSnowboarding as farSnowboarding } from '@fortawesome/pro-regular-svg-icons/faSnowboarding';
import { faSnowflake as farSnowflake } from '@fortawesome/pro-regular-svg-icons/faSnowflake';
import { faSnowflakeDroplets as farSnowflakeDroplets } from '@fortawesome/pro-regular-svg-icons/faSnowflakeDroplets';
import { faSnowflakes as farSnowflakes } from '@fortawesome/pro-regular-svg-icons/faSnowflakes';
import { faSnowman as farSnowman } from '@fortawesome/pro-regular-svg-icons/faSnowman';
import { faSnowmanHead as farSnowmanHead } from '@fortawesome/pro-regular-svg-icons/faSnowmanHead';
import { faSnowmobile as farSnowmobile } from '@fortawesome/pro-regular-svg-icons/faSnowmobile';
import { faSnowplow as farSnowplow } from '@fortawesome/pro-regular-svg-icons/faSnowplow';
import { faSoap as farSoap } from '@fortawesome/pro-regular-svg-icons/faSoap';
import { faSoccerBall as farSoccerBall } from '@fortawesome/pro-regular-svg-icons/faSoccerBall';
import { faSocks as farSocks } from '@fortawesome/pro-regular-svg-icons/faSocks';
import { faSoftServe as farSoftServe } from '@fortawesome/pro-regular-svg-icons/faSoftServe';
import { faSolarPanel as farSolarPanel } from '@fortawesome/pro-regular-svg-icons/faSolarPanel';
import { faSolarSystem as farSolarSystem } from '@fortawesome/pro-regular-svg-icons/faSolarSystem';
import { faSort as farSort } from '@fortawesome/pro-regular-svg-icons/faSort';
import { faSortAlphaAsc as farSortAlphaAsc } from '@fortawesome/pro-regular-svg-icons/faSortAlphaAsc';
import { faSortAlphaDesc as farSortAlphaDesc } from '@fortawesome/pro-regular-svg-icons/faSortAlphaDesc';
import { faSortAlphaDown as farSortAlphaDown } from '@fortawesome/pro-regular-svg-icons/faSortAlphaDown';
import { faSortAlphaDownAlt as farSortAlphaDownAlt } from '@fortawesome/pro-regular-svg-icons/faSortAlphaDownAlt';
import { faSortAlphaUp as farSortAlphaUp } from '@fortawesome/pro-regular-svg-icons/faSortAlphaUp';
import { faSortAlphaUpAlt as farSortAlphaUpAlt } from '@fortawesome/pro-regular-svg-icons/faSortAlphaUpAlt';
import { faSortAlt as farSortAlt } from '@fortawesome/pro-regular-svg-icons/faSortAlt';
import { faSortAmountAsc as farSortAmountAsc } from '@fortawesome/pro-regular-svg-icons/faSortAmountAsc';
import { faSortAmountDesc as farSortAmountDesc } from '@fortawesome/pro-regular-svg-icons/faSortAmountDesc';
import { faSortAmountDown as farSortAmountDown } from '@fortawesome/pro-regular-svg-icons/faSortAmountDown';
import { faSortAmountDownAlt as farSortAmountDownAlt } from '@fortawesome/pro-regular-svg-icons/faSortAmountDownAlt';
import { faSortAmountUp as farSortAmountUp } from '@fortawesome/pro-regular-svg-icons/faSortAmountUp';
import { faSortAmountUpAlt as farSortAmountUpAlt } from '@fortawesome/pro-regular-svg-icons/faSortAmountUpAlt';
import { faSortAsc as farSortAsc } from '@fortawesome/pro-regular-svg-icons/faSortAsc';
import { faSortCircle as farSortCircle } from '@fortawesome/pro-regular-svg-icons/faSortCircle';
import { faSortCircleDown as farSortCircleDown } from '@fortawesome/pro-regular-svg-icons/faSortCircleDown';
import { faSortCircleUp as farSortCircleUp } from '@fortawesome/pro-regular-svg-icons/faSortCircleUp';
import { faSortDesc as farSortDesc } from '@fortawesome/pro-regular-svg-icons/faSortDesc';
import { faSortDown as farSortDown } from '@fortawesome/pro-regular-svg-icons/faSortDown';
import { faSortNumericAsc as farSortNumericAsc } from '@fortawesome/pro-regular-svg-icons/faSortNumericAsc';
import { faSortNumericDesc as farSortNumericDesc } from '@fortawesome/pro-regular-svg-icons/faSortNumericDesc';
import { faSortNumericDown as farSortNumericDown } from '@fortawesome/pro-regular-svg-icons/faSortNumericDown';
import { faSortNumericDownAlt as farSortNumericDownAlt } from '@fortawesome/pro-regular-svg-icons/faSortNumericDownAlt';
import { faSortNumericUp as farSortNumericUp } from '@fortawesome/pro-regular-svg-icons/faSortNumericUp';
import { faSortNumericUpAlt as farSortNumericUpAlt } from '@fortawesome/pro-regular-svg-icons/faSortNumericUpAlt';
import { faSortShapesDown as farSortShapesDown } from '@fortawesome/pro-regular-svg-icons/faSortShapesDown';
import { faSortShapesDownAlt as farSortShapesDownAlt } from '@fortawesome/pro-regular-svg-icons/faSortShapesDownAlt';
import { faSortShapesUp as farSortShapesUp } from '@fortawesome/pro-regular-svg-icons/faSortShapesUp';
import { faSortShapesUpAlt as farSortShapesUpAlt } from '@fortawesome/pro-regular-svg-icons/faSortShapesUpAlt';
import { faSortSizeDown as farSortSizeDown } from '@fortawesome/pro-regular-svg-icons/faSortSizeDown';
import { faSortSizeDownAlt as farSortSizeDownAlt } from '@fortawesome/pro-regular-svg-icons/faSortSizeDownAlt';
import { faSortSizeUp as farSortSizeUp } from '@fortawesome/pro-regular-svg-icons/faSortSizeUp';
import { faSortSizeUpAlt as farSortSizeUpAlt } from '@fortawesome/pro-regular-svg-icons/faSortSizeUpAlt';
import { faSortUp as farSortUp } from '@fortawesome/pro-regular-svg-icons/faSortUp';
import { faSortUpDown as farSortUpDown } from '@fortawesome/pro-regular-svg-icons/faSortUpDown';
import { faSoundcloud as farSoundcloud } from '@fortawesome/free-brands-svg-icons/faSoundcloud';
import { faSoup as farSoup } from '@fortawesome/pro-regular-svg-icons/faSoup';
import { faSourcetree as farSourcetree } from '@fortawesome/free-brands-svg-icons/faSourcetree';
import { faSpa as farSpa } from '@fortawesome/pro-regular-svg-icons/faSpa';
import { faSpaceAwesome as farSpaceAwesome } from '@fortawesome/free-brands-svg-icons/faSpaceAwesome';
import { faSpaceShuttle as farSpaceShuttle } from '@fortawesome/pro-regular-svg-icons/faSpaceShuttle';
import { faSpaceStationMoon as farSpaceStationMoon } from '@fortawesome/pro-regular-svg-icons/faSpaceStationMoon';
import { faSpaceStationMoonAlt as farSpaceStationMoonAlt } from '@fortawesome/pro-regular-svg-icons/faSpaceStationMoonAlt';
import { faSpaceStationMoonConstruction as farSpaceStationMoonConstruction } from '@fortawesome/pro-regular-svg-icons/faSpaceStationMoonConstruction';
import { faSpade as farSpade } from '@fortawesome/pro-regular-svg-icons/faSpade';
import { faSpaghettiMonsterFlying as farSpaghettiMonsterFlying } from '@fortawesome/pro-regular-svg-icons/faSpaghettiMonsterFlying';
import { faSparkle as farSparkle } from '@fortawesome/pro-regular-svg-icons/faSparkle';
import { faSparkles as farSparkles } from '@fortawesome/pro-regular-svg-icons/faSparkles';
import { faSpeakap as farSpeakap } from '@fortawesome/free-brands-svg-icons/faSpeakap';
import { faSpeaker as farSpeaker } from '@fortawesome/pro-regular-svg-icons/faSpeaker';
import { faSpeakerDeck as farSpeakerDeck } from '@fortawesome/free-brands-svg-icons/faSpeakerDeck';
import { faSpeakers as farSpeakers } from '@fortawesome/pro-regular-svg-icons/faSpeakers';
import { faSpellCheck as farSpellCheck } from '@fortawesome/pro-regular-svg-icons/faSpellCheck';
import { faSpider as farSpider } from '@fortawesome/pro-regular-svg-icons/faSpider';
import { faSpiderBlackWidow as farSpiderBlackWidow } from '@fortawesome/pro-regular-svg-icons/faSpiderBlackWidow';
import { faSpiderWeb as farSpiderWeb } from '@fortawesome/pro-regular-svg-icons/faSpiderWeb';
import { faSpinner as farSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
import { faSpinnerScale as farSpinnerScale } from '@fortawesome/pro-regular-svg-icons/faSpinnerScale';
import { faSpinnerThird as farSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird';
import { faSplit as farSplit } from '@fortawesome/pro-regular-svg-icons/faSplit';
import { faSplotch as farSplotch } from '@fortawesome/pro-regular-svg-icons/faSplotch';
import { faSpoon as farSpoon } from '@fortawesome/pro-regular-svg-icons/faSpoon';
import { faSportsball as farSportsball } from '@fortawesome/pro-regular-svg-icons/faSportsball';
import { faSpotify as farSpotify } from '@fortawesome/free-brands-svg-icons/faSpotify';
import { faSprayCan as farSprayCan } from '@fortawesome/pro-regular-svg-icons/faSprayCan';
import { faSprayCanSparkles as farSprayCanSparkles } from '@fortawesome/pro-regular-svg-icons/faSprayCanSparkles';
import { faSprinkler as farSprinkler } from '@fortawesome/pro-regular-svg-icons/faSprinkler';
import { faSprinklerCeiling as farSprinklerCeiling } from '@fortawesome/pro-regular-svg-icons/faSprinklerCeiling';
import { faSprout as farSprout } from '@fortawesome/pro-regular-svg-icons/faSprout';
import { faSquare as farSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { faSquare0 as farSquare0 } from '@fortawesome/pro-regular-svg-icons/faSquare0';
import { faSquare1 as farSquare1 } from '@fortawesome/pro-regular-svg-icons/faSquare1';
import { faSquare2 as farSquare2 } from '@fortawesome/pro-regular-svg-icons/faSquare2';
import { faSquare3 as farSquare3 } from '@fortawesome/pro-regular-svg-icons/faSquare3';
import { faSquare4 as farSquare4 } from '@fortawesome/pro-regular-svg-icons/faSquare4';
import { faSquare5 as farSquare5 } from '@fortawesome/pro-regular-svg-icons/faSquare5';
import { faSquare6 as farSquare6 } from '@fortawesome/pro-regular-svg-icons/faSquare6';
import { faSquare7 as farSquare7 } from '@fortawesome/pro-regular-svg-icons/faSquare7';
import { faSquare8 as farSquare8 } from '@fortawesome/pro-regular-svg-icons/faSquare8';
import { faSquare9 as farSquare9 } from '@fortawesome/pro-regular-svg-icons/faSquare9';
import { faSquareA as farSquareA } from '@fortawesome/pro-regular-svg-icons/faSquareA';
import { faSquareALock as farSquareALock } from '@fortawesome/pro-regular-svg-icons/faSquareALock';
import { faSquareAmpersand as farSquareAmpersand } from '@fortawesome/pro-regular-svg-icons/faSquareAmpersand';
import { faSquareArrowDown as farSquareArrowDown } from '@fortawesome/pro-regular-svg-icons/faSquareArrowDown';
import { faSquareArrowDownLeft as farSquareArrowDownLeft } from '@fortawesome/pro-regular-svg-icons/faSquareArrowDownLeft';
import { faSquareArrowDownRight as farSquareArrowDownRight } from '@fortawesome/pro-regular-svg-icons/faSquareArrowDownRight';
import { faSquareArrowLeft as farSquareArrowLeft } from '@fortawesome/pro-regular-svg-icons/faSquareArrowLeft';
import { faSquareArrowRight as farSquareArrowRight } from '@fortawesome/pro-regular-svg-icons/faSquareArrowRight';
import { faSquareArrowUp as farSquareArrowUp } from '@fortawesome/pro-regular-svg-icons/faSquareArrowUp';
import { faSquareArrowUpLeft as farSquareArrowUpLeft } from '@fortawesome/pro-regular-svg-icons/faSquareArrowUpLeft';
import { faSquareArrowUpRight as farSquareArrowUpRight } from '@fortawesome/pro-regular-svg-icons/faSquareArrowUpRight';
import { faSquareB as farSquareB } from '@fortawesome/pro-regular-svg-icons/faSquareB';
import { faSquareBehance as farSquareBehance } from '@fortawesome/free-brands-svg-icons/faSquareBehance';
import { faSquareBolt as farSquareBolt } from '@fortawesome/pro-regular-svg-icons/faSquareBolt';
import { faSquareC as farSquareC } from '@fortawesome/pro-regular-svg-icons/faSquareC';
import { faSquareCaretDown as farSquareCaretDown } from '@fortawesome/pro-regular-svg-icons/faSquareCaretDown';
import { faSquareCaretLeft as farSquareCaretLeft } from '@fortawesome/pro-regular-svg-icons/faSquareCaretLeft';
import { faSquareCaretRight as farSquareCaretRight } from '@fortawesome/pro-regular-svg-icons/faSquareCaretRight';
import { faSquareCaretUp as farSquareCaretUp } from '@fortawesome/pro-regular-svg-icons/faSquareCaretUp';
import { faSquareCheck as farSquareCheck } from '@fortawesome/pro-regular-svg-icons/faSquareCheck';
import { faSquareChevronDown as farSquareChevronDown } from '@fortawesome/pro-regular-svg-icons/faSquareChevronDown';
import { faSquareChevronLeft as farSquareChevronLeft } from '@fortawesome/pro-regular-svg-icons/faSquareChevronLeft';
import { faSquareChevronRight as farSquareChevronRight } from '@fortawesome/pro-regular-svg-icons/faSquareChevronRight';
import { faSquareChevronUp as farSquareChevronUp } from '@fortawesome/pro-regular-svg-icons/faSquareChevronUp';
import { faSquareCode as farSquareCode } from '@fortawesome/pro-regular-svg-icons/faSquareCode';
import { faSquareD as farSquareD } from '@fortawesome/pro-regular-svg-icons/faSquareD';
import { faSquareDashed as farSquareDashed } from '@fortawesome/pro-regular-svg-icons/faSquareDashed';
import { faSquareDashedCirclePlus as farSquareDashedCirclePlus } from '@fortawesome/pro-regular-svg-icons/faSquareDashedCirclePlus';
import { faSquareDivide as farSquareDivide } from '@fortawesome/pro-regular-svg-icons/faSquareDivide';
import { faSquareDollar as farSquareDollar } from '@fortawesome/pro-regular-svg-icons/faSquareDollar';
import { faSquareDown as farSquareDown } from '@fortawesome/pro-regular-svg-icons/faSquareDown';
import { faSquareDownLeft as farSquareDownLeft } from '@fortawesome/pro-regular-svg-icons/faSquareDownLeft';
import { faSquareDownRight as farSquareDownRight } from '@fortawesome/pro-regular-svg-icons/faSquareDownRight';
import { faSquareDribbble as farSquareDribbble } from '@fortawesome/free-brands-svg-icons/faSquareDribbble';
import { faSquareE as farSquareE } from '@fortawesome/pro-regular-svg-icons/faSquareE';
import { faSquareEllipsis as farSquareEllipsis } from '@fortawesome/pro-regular-svg-icons/faSquareEllipsis';
import { faSquareEllipsisVertical as farSquareEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faSquareEllipsisVertical';
import { faSquareEnvelope as farSquareEnvelope } from '@fortawesome/pro-regular-svg-icons/faSquareEnvelope';
import { faSquareExclamation as farSquareExclamation } from '@fortawesome/pro-regular-svg-icons/faSquareExclamation';
import { faSquareF as farSquareF } from '@fortawesome/pro-regular-svg-icons/faSquareF';
import { faSquareFacebook as farSquareFacebook } from '@fortawesome/free-brands-svg-icons/faSquareFacebook';
import { faSquareFontAwesome as farSquareFontAwesome } from '@fortawesome/free-brands-svg-icons/faSquareFontAwesome';
import { faSquareFontAwesomeStroke as farSquareFontAwesomeStroke } from '@fortawesome/free-brands-svg-icons/faSquareFontAwesomeStroke';
import { faSquareFragile as farSquareFragile } from '@fortawesome/pro-regular-svg-icons/faSquareFragile';
import { faSquareFull as farSquareFull } from '@fortawesome/pro-regular-svg-icons/faSquareFull';
import { faSquareG as farSquareG } from '@fortawesome/pro-regular-svg-icons/faSquareG';
import { faSquareGit as farSquareGit } from '@fortawesome/free-brands-svg-icons/faSquareGit';
import { faSquareGithub as farSquareGithub } from '@fortawesome/free-brands-svg-icons/faSquareGithub';
import { faSquareGitlab as farSquareGitlab } from '@fortawesome/free-brands-svg-icons/faSquareGitlab';
import { faSquareGooglePlus as farSquareGooglePlus } from '@fortawesome/free-brands-svg-icons/faSquareGooglePlus';
import { faSquareH as farSquareH } from '@fortawesome/pro-regular-svg-icons/faSquareH';
import { faSquareHackerNews as farSquareHackerNews } from '@fortawesome/free-brands-svg-icons/faSquareHackerNews';
import { faSquareHeart as farSquareHeart } from '@fortawesome/pro-regular-svg-icons/faSquareHeart';
import { faSquareI as farSquareI } from '@fortawesome/pro-regular-svg-icons/faSquareI';
import { faSquareInfo as farSquareInfo } from '@fortawesome/pro-regular-svg-icons/faSquareInfo';
import { faSquareInstagram as farSquareInstagram } from '@fortawesome/free-brands-svg-icons/faSquareInstagram';
import { faSquareJ as farSquareJ } from '@fortawesome/pro-regular-svg-icons/faSquareJ';
import { faSquareJs as farSquareJs } from '@fortawesome/free-brands-svg-icons/faSquareJs';
import { faSquareK as farSquareK } from '@fortawesome/pro-regular-svg-icons/faSquareK';
import { faSquareKanban as farSquareKanban } from '@fortawesome/pro-regular-svg-icons/faSquareKanban';
import { faSquareL as farSquareL } from '@fortawesome/pro-regular-svg-icons/faSquareL';
import { faSquareLastfm as farSquareLastfm } from '@fortawesome/free-brands-svg-icons/faSquareLastfm';
import { faSquareLeft as farSquareLeft } from '@fortawesome/pro-regular-svg-icons/faSquareLeft';
import { faSquareLetterboxd as farSquareLetterboxd } from '@fortawesome/free-brands-svg-icons/faSquareLetterboxd';
import { faSquareList as farSquareList } from '@fortawesome/pro-regular-svg-icons/faSquareList';
import { faSquareM as farSquareM } from '@fortawesome/pro-regular-svg-icons/faSquareM';
import { faSquareMinus as farSquareMinus } from '@fortawesome/pro-regular-svg-icons/faSquareMinus';
import { faSquareN as farSquareN } from '@fortawesome/pro-regular-svg-icons/faSquareN';
import { faSquareNfi as farSquareNfi } from '@fortawesome/pro-regular-svg-icons/faSquareNfi';
import { faSquareO as farSquareO } from '@fortawesome/pro-regular-svg-icons/faSquareO';
import { faSquareOdnoklassniki as farSquareOdnoklassniki } from '@fortawesome/free-brands-svg-icons/faSquareOdnoklassniki';
import { faSquareP as farSquareP } from '@fortawesome/pro-regular-svg-icons/faSquareP';
import { faSquareParking as farSquareParking } from '@fortawesome/pro-regular-svg-icons/faSquareParking';
import { faSquareParkingSlash as farSquareParkingSlash } from '@fortawesome/pro-regular-svg-icons/faSquareParkingSlash';
import { faSquarePen as farSquarePen } from '@fortawesome/pro-regular-svg-icons/faSquarePen';
import { faSquarePersonConfined as farSquarePersonConfined } from '@fortawesome/pro-regular-svg-icons/faSquarePersonConfined';
import { faSquarePhone as farSquarePhone } from '@fortawesome/pro-regular-svg-icons/faSquarePhone';
import { faSquarePhoneFlip as farSquarePhoneFlip } from '@fortawesome/pro-regular-svg-icons/faSquarePhoneFlip';
import { faSquarePhoneHangup as farSquarePhoneHangup } from '@fortawesome/pro-regular-svg-icons/faSquarePhoneHangup';
import { faSquarePiedPiper as farSquarePiedPiper } from '@fortawesome/free-brands-svg-icons/faSquarePiedPiper';
import { faSquarePinterest as farSquarePinterest } from '@fortawesome/free-brands-svg-icons/faSquarePinterest';
import { faSquarePlus as farSquarePlus } from '@fortawesome/pro-regular-svg-icons/faSquarePlus';
import { faSquarePollHorizontal as farSquarePollHorizontal } from '@fortawesome/pro-regular-svg-icons/faSquarePollHorizontal';
import { faSquarePollVertical as farSquarePollVertical } from '@fortawesome/pro-regular-svg-icons/faSquarePollVertical';
import { faSquareQ as farSquareQ } from '@fortawesome/pro-regular-svg-icons/faSquareQ';
import { faSquareQuarters as farSquareQuarters } from '@fortawesome/pro-regular-svg-icons/faSquareQuarters';
import { faSquareQuestion as farSquareQuestion } from '@fortawesome/pro-regular-svg-icons/faSquareQuestion';
import { faSquareQuote as farSquareQuote } from '@fortawesome/pro-regular-svg-icons/faSquareQuote';
import { faSquareR as farSquareR } from '@fortawesome/pro-regular-svg-icons/faSquareR';
import { faSquareReddit as farSquareReddit } from '@fortawesome/free-brands-svg-icons/faSquareReddit';
import { faSquareRight as farSquareRight } from '@fortawesome/pro-regular-svg-icons/faSquareRight';
import { faSquareRing as farSquareRing } from '@fortawesome/pro-regular-svg-icons/faSquareRing';
import { faSquareRoot as farSquareRoot } from '@fortawesome/pro-regular-svg-icons/faSquareRoot';
import { faSquareRootAlt as farSquareRootAlt } from '@fortawesome/pro-regular-svg-icons/faSquareRootAlt';
import { faSquareRootVariable as farSquareRootVariable } from '@fortawesome/pro-regular-svg-icons/faSquareRootVariable';
import { faSquareRss as farSquareRss } from '@fortawesome/pro-regular-svg-icons/faSquareRss';
import { faSquareS as farSquareS } from '@fortawesome/pro-regular-svg-icons/faSquareS';
import { faSquareShareNodes as farSquareShareNodes } from '@fortawesome/pro-regular-svg-icons/faSquareShareNodes';
import { faSquareSliders as farSquareSliders } from '@fortawesome/pro-regular-svg-icons/faSquareSliders';
import { faSquareSlidersVertical as farSquareSlidersVertical } from '@fortawesome/pro-regular-svg-icons/faSquareSlidersVertical';
import { faSquareSmall as farSquareSmall } from '@fortawesome/pro-regular-svg-icons/faSquareSmall';
import { faSquareSnapchat as farSquareSnapchat } from '@fortawesome/free-brands-svg-icons/faSquareSnapchat';
import { faSquarespace as farSquarespace } from '@fortawesome/free-brands-svg-icons/faSquarespace';
import { faSquareStar as farSquareStar } from '@fortawesome/pro-regular-svg-icons/faSquareStar';
import { faSquareSteam as farSquareSteam } from '@fortawesome/free-brands-svg-icons/faSquareSteam';
import { faSquareT as farSquareT } from '@fortawesome/pro-regular-svg-icons/faSquareT';
import { faSquareTerminal as farSquareTerminal } from '@fortawesome/pro-regular-svg-icons/faSquareTerminal';
import { faSquareThisWayUp as farSquareThisWayUp } from '@fortawesome/pro-regular-svg-icons/faSquareThisWayUp';
import { faSquareThreads as farSquareThreads } from '@fortawesome/free-brands-svg-icons/faSquareThreads';
import { faSquareTumblr as farSquareTumblr } from '@fortawesome/free-brands-svg-icons/faSquareTumblr';
import { faSquareTwitter as farSquareTwitter } from '@fortawesome/free-brands-svg-icons/faSquareTwitter';
import { faSquareU as farSquareU } from '@fortawesome/pro-regular-svg-icons/faSquareU';
import { faSquareUp as farSquareUp } from '@fortawesome/pro-regular-svg-icons/faSquareUp';
import { faSquareUpLeft as farSquareUpLeft } from '@fortawesome/pro-regular-svg-icons/faSquareUpLeft';
import { faSquareUpRight as farSquareUpRight } from '@fortawesome/pro-regular-svg-icons/faSquareUpRight';
import { faSquareUser as farSquareUser } from '@fortawesome/pro-regular-svg-icons/faSquareUser';
import { faSquareV as farSquareV } from '@fortawesome/pro-regular-svg-icons/faSquareV';
import { faSquareViadeo as farSquareViadeo } from '@fortawesome/free-brands-svg-icons/faSquareViadeo';
import { faSquareVimeo as farSquareVimeo } from '@fortawesome/free-brands-svg-icons/faSquareVimeo';
import { faSquareVirus as farSquareVirus } from '@fortawesome/pro-regular-svg-icons/faSquareVirus';
import { faSquareW as farSquareW } from '@fortawesome/pro-regular-svg-icons/faSquareW';
import { faSquareWhatsapp as farSquareWhatsapp } from '@fortawesome/free-brands-svg-icons/faSquareWhatsapp';
import { faSquareWineGlassCrack as farSquareWineGlassCrack } from '@fortawesome/pro-regular-svg-icons/faSquareWineGlassCrack';
import { faSquareX as farSquareX } from '@fortawesome/pro-regular-svg-icons/faSquareX';
import { faSquareXing as farSquareXing } from '@fortawesome/free-brands-svg-icons/faSquareXing';
import { faSquareXmark as farSquareXmark } from '@fortawesome/pro-regular-svg-icons/faSquareXmark';
import { faSquareXTwitter as farSquareXTwitter } from '@fortawesome/free-brands-svg-icons/faSquareXTwitter';
import { faSquareY as farSquareY } from '@fortawesome/pro-regular-svg-icons/faSquareY';
import { faSquareYoutube as farSquareYoutube } from '@fortawesome/free-brands-svg-icons/faSquareYoutube';
import { faSquareZ as farSquareZ } from '@fortawesome/pro-regular-svg-icons/faSquareZ';
import { faSquid as farSquid } from '@fortawesome/pro-regular-svg-icons/faSquid';
import { faSquirrel as farSquirrel } from '@fortawesome/pro-regular-svg-icons/faSquirrel';
import { faStackExchange as farStackExchange } from '@fortawesome/free-brands-svg-icons/faStackExchange';
import { faStackOverflow as farStackOverflow } from '@fortawesome/free-brands-svg-icons/faStackOverflow';
import { faStackpath as farStackpath } from '@fortawesome/free-brands-svg-icons/faStackpath';
import { faStaff as farStaff } from '@fortawesome/pro-regular-svg-icons/faStaff';
import { faStaffAesculapius as farStaffAesculapius } from '@fortawesome/pro-regular-svg-icons/faStaffAesculapius';
import { faStaffSnake as farStaffSnake } from '@fortawesome/pro-regular-svg-icons/faStaffSnake';
import { faStairs as farStairs } from '@fortawesome/pro-regular-svg-icons/faStairs';
import { faStamp as farStamp } from '@fortawesome/pro-regular-svg-icons/faStamp';
import { faStandardDefinition as farStandardDefinition } from '@fortawesome/pro-regular-svg-icons/faStandardDefinition';
import { faStapler as farStapler } from '@fortawesome/pro-regular-svg-icons/faStapler';
import { faStar as farStar } from '@fortawesome/pro-regular-svg-icons/faStar';
import { faStarAndCrescent as farStarAndCrescent } from '@fortawesome/pro-regular-svg-icons/faStarAndCrescent';
import { faStarChristmas as farStarChristmas } from '@fortawesome/pro-regular-svg-icons/faStarChristmas';
import { faStarCircle as farStarCircle } from '@fortawesome/pro-regular-svg-icons/faStarCircle';
import { faStarExclamation as farStarExclamation } from '@fortawesome/pro-regular-svg-icons/faStarExclamation';
import { faStarfighter as farStarfighter } from '@fortawesome/pro-regular-svg-icons/faStarfighter';
import { faStarfighterAlt as farStarfighterAlt } from '@fortawesome/pro-regular-svg-icons/faStarfighterAlt';
import { faStarfighterAltAdvanced as farStarfighterAltAdvanced } from '@fortawesome/pro-regular-svg-icons/faStarfighterAltAdvanced';
import { faStarfighterTwinIonEngine as farStarfighterTwinIonEngine } from '@fortawesome/pro-regular-svg-icons/faStarfighterTwinIonEngine';
import { faStarfighterTwinIonEngineAdvanced as farStarfighterTwinIonEngineAdvanced } from '@fortawesome/pro-regular-svg-icons/faStarfighterTwinIonEngineAdvanced';
import { faStarHalf as farStarHalf } from '@fortawesome/pro-regular-svg-icons/faStarHalf';
import { faStarHalfAlt as farStarHalfAlt } from '@fortawesome/pro-regular-svg-icons/faStarHalfAlt';
import { faStarHalfStroke as farStarHalfStroke } from '@fortawesome/pro-regular-svg-icons/faStarHalfStroke';
import { faStarOfDavid as farStarOfDavid } from '@fortawesome/pro-regular-svg-icons/faStarOfDavid';
import { faStarOfLife as farStarOfLife } from '@fortawesome/pro-regular-svg-icons/faStarOfLife';
import { faStars as farStars } from '@fortawesome/pro-regular-svg-icons/faStars';
import { faStarSharp as farStarSharp } from '@fortawesome/pro-regular-svg-icons/faStarSharp';
import { faStarSharpHalf as farStarSharpHalf } from '@fortawesome/pro-regular-svg-icons/faStarSharpHalf';
import { faStarSharpHalfAlt as farStarSharpHalfAlt } from '@fortawesome/pro-regular-svg-icons/faStarSharpHalfAlt';
import { faStarSharpHalfStroke as farStarSharpHalfStroke } from '@fortawesome/pro-regular-svg-icons/faStarSharpHalfStroke';
import { faStarship as farStarship } from '@fortawesome/pro-regular-svg-icons/faStarship';
import { faStarshipFreighter as farStarshipFreighter } from '@fortawesome/pro-regular-svg-icons/faStarshipFreighter';
import { faStarShooting as farStarShooting } from '@fortawesome/pro-regular-svg-icons/faStarShooting';
import { faStaylinked as farStaylinked } from '@fortawesome/free-brands-svg-icons/faStaylinked';
import { faSteak as farSteak } from '@fortawesome/pro-regular-svg-icons/faSteak';
import { faSteam as farSteam } from '@fortawesome/free-brands-svg-icons/faSteam';
import { faSteamSquare as farSteamSquare } from '@fortawesome/free-brands-svg-icons/faSteamSquare';
import { faSteamSymbol as farSteamSymbol } from '@fortawesome/free-brands-svg-icons/faSteamSymbol';
import { faSteeringWheel as farSteeringWheel } from '@fortawesome/pro-regular-svg-icons/faSteeringWheel';
import { faStepBackward as farStepBackward } from '@fortawesome/pro-regular-svg-icons/faStepBackward';
import { faStepForward as farStepForward } from '@fortawesome/pro-regular-svg-icons/faStepForward';
import { faSterlingSign as farSterlingSign } from '@fortawesome/pro-regular-svg-icons/faSterlingSign';
import { faStethoscope as farStethoscope } from '@fortawesome/pro-regular-svg-icons/faStethoscope';
import { faStickerMule as farStickerMule } from '@fortawesome/free-brands-svg-icons/faStickerMule';
import { faStickyNote as farStickyNote } from '@fortawesome/pro-regular-svg-icons/faStickyNote';
import { faStocking as farStocking } from '@fortawesome/pro-regular-svg-icons/faStocking';
import { faStomach as farStomach } from '@fortawesome/pro-regular-svg-icons/faStomach';
import { faStop as farStop } from '@fortawesome/pro-regular-svg-icons/faStop';
import { faStopCircle as farStopCircle } from '@fortawesome/pro-regular-svg-icons/faStopCircle';
import { faStopwatch as farStopwatch } from '@fortawesome/pro-regular-svg-icons/faStopwatch';
import { faStopwatch20 as farStopwatch20 } from '@fortawesome/pro-regular-svg-icons/faStopwatch20';
import { faStore as farStore } from '@fortawesome/pro-regular-svg-icons/faStore';
import { faStoreAlt as farStoreAlt } from '@fortawesome/pro-regular-svg-icons/faStoreAlt';
import { faStoreAltSlash as farStoreAltSlash } from '@fortawesome/pro-regular-svg-icons/faStoreAltSlash';
import { faStoreLock as farStoreLock } from '@fortawesome/pro-regular-svg-icons/faStoreLock';
import { faStoreSlash as farStoreSlash } from '@fortawesome/pro-regular-svg-icons/faStoreSlash';
import { faStrava as farStrava } from '@fortawesome/free-brands-svg-icons/faStrava';
import { faStrawberry as farStrawberry } from '@fortawesome/pro-regular-svg-icons/faStrawberry';
import { faStream as farStream } from '@fortawesome/pro-regular-svg-icons/faStream';
import { faStreetView as farStreetView } from '@fortawesome/pro-regular-svg-icons/faStreetView';
import { faStretcher as farStretcher } from '@fortawesome/pro-regular-svg-icons/faStretcher';
import { faStrikethrough as farStrikethrough } from '@fortawesome/pro-regular-svg-icons/faStrikethrough';
import { faStripe as farStripe } from '@fortawesome/free-brands-svg-icons/faStripe';
import { faStripeS as farStripeS } from '@fortawesome/free-brands-svg-icons/faStripeS';
import { faStroopwafel as farStroopwafel } from '@fortawesome/pro-regular-svg-icons/faStroopwafel';
import { faStubber as farStubber } from '@fortawesome/free-brands-svg-icons/faStubber';
import { faStudiovinari as farStudiovinari } from '@fortawesome/free-brands-svg-icons/faStudiovinari';
import { faStumbleupon as farStumbleupon } from '@fortawesome/free-brands-svg-icons/faStumbleupon';
import { faStumbleuponCircle as farStumbleuponCircle } from '@fortawesome/free-brands-svg-icons/faStumbleuponCircle';
import { faSubscript as farSubscript } from '@fortawesome/pro-regular-svg-icons/faSubscript';
import { faSubtitles as farSubtitles } from '@fortawesome/pro-regular-svg-icons/faSubtitles';
import { faSubtitlesSlash as farSubtitlesSlash } from '@fortawesome/pro-regular-svg-icons/faSubtitlesSlash';
import { faSubtract as farSubtract } from '@fortawesome/pro-regular-svg-icons/faSubtract';
import { faSubway as farSubway } from '@fortawesome/pro-regular-svg-icons/faSubway';
import { faSubwayTunnel as farSubwayTunnel } from '@fortawesome/pro-regular-svg-icons/faSubwayTunnel';
import { faSuitcase as farSuitcase } from '@fortawesome/pro-regular-svg-icons/faSuitcase';
import { faSuitcaseMedical as farSuitcaseMedical } from '@fortawesome/pro-regular-svg-icons/faSuitcaseMedical';
import { faSuitcaseRolling as farSuitcaseRolling } from '@fortawesome/pro-regular-svg-icons/faSuitcaseRolling';
import { faSun as farSun } from '@fortawesome/pro-regular-svg-icons/faSun';
import { faSunAlt as farSunAlt } from '@fortawesome/pro-regular-svg-icons/faSunAlt';
import { faSunBright as farSunBright } from '@fortawesome/pro-regular-svg-icons/faSunBright';
import { faSunCloud as farSunCloud } from '@fortawesome/pro-regular-svg-icons/faSunCloud';
import { faSunDust as farSunDust } from '@fortawesome/pro-regular-svg-icons/faSunDust';
import { faSunglasses as farSunglasses } from '@fortawesome/pro-regular-svg-icons/faSunglasses';
import { faSunHaze as farSunHaze } from '@fortawesome/pro-regular-svg-icons/faSunHaze';
import { faSunPlantWilt as farSunPlantWilt } from '@fortawesome/pro-regular-svg-icons/faSunPlantWilt';
import { faSunrise as farSunrise } from '@fortawesome/pro-regular-svg-icons/faSunrise';
import { faSunset as farSunset } from '@fortawesome/pro-regular-svg-icons/faSunset';
import { faSuperpowers as farSuperpowers } from '@fortawesome/free-brands-svg-icons/faSuperpowers';
import { faSuperscript as farSuperscript } from '@fortawesome/pro-regular-svg-icons/faSuperscript';
import { faSupple as farSupple } from '@fortawesome/free-brands-svg-icons/faSupple';
import { faSurprise as farSurprise } from '@fortawesome/pro-regular-svg-icons/faSurprise';
import { faSuse as farSuse } from '@fortawesome/free-brands-svg-icons/faSuse';
import { faSushi as farSushi } from '@fortawesome/pro-regular-svg-icons/faSushi';
import { faSushiRoll as farSushiRoll } from '@fortawesome/pro-regular-svg-icons/faSushiRoll';
import { faSwap as farSwap } from '@fortawesome/pro-regular-svg-icons/faSwap';
import { faSwapArrows as farSwapArrows } from '@fortawesome/pro-regular-svg-icons/faSwapArrows';
import { faSwatchbook as farSwatchbook } from '@fortawesome/pro-regular-svg-icons/faSwatchbook';
import { faSwift as farSwift } from '@fortawesome/free-brands-svg-icons/faSwift';
import { faSwimmer as farSwimmer } from '@fortawesome/pro-regular-svg-icons/faSwimmer';
import { faSwimmingPool as farSwimmingPool } from '@fortawesome/pro-regular-svg-icons/faSwimmingPool';
import { faSword as farSword } from '@fortawesome/pro-regular-svg-icons/faSword';
import { faSwordLaser as farSwordLaser } from '@fortawesome/pro-regular-svg-icons/faSwordLaser';
import { faSwordLaserAlt as farSwordLaserAlt } from '@fortawesome/pro-regular-svg-icons/faSwordLaserAlt';
import { faSwords as farSwords } from '@fortawesome/pro-regular-svg-icons/faSwords';
import { faSwordsLaser as farSwordsLaser } from '@fortawesome/pro-regular-svg-icons/faSwordsLaser';
import { faSymbols as farSymbols } from '@fortawesome/pro-regular-svg-icons/faSymbols';
import { faSymfony as farSymfony } from '@fortawesome/free-brands-svg-icons/faSymfony';
import { faSynagogue as farSynagogue } from '@fortawesome/pro-regular-svg-icons/faSynagogue';
import { faSync as farSync } from '@fortawesome/pro-regular-svg-icons/faSync';
import { faSyncAlt as farSyncAlt } from '@fortawesome/pro-regular-svg-icons/faSyncAlt';
import { faSyringe as farSyringe } from '@fortawesome/pro-regular-svg-icons/faSyringe';
import { faT as farT } from '@fortawesome/pro-regular-svg-icons/faT';
import { faTable as farTable } from '@fortawesome/pro-regular-svg-icons/faTable';
import { faTableCells as farTableCells } from '@fortawesome/pro-regular-svg-icons/faTableCells';
import { faTableCellsLarge as farTableCellsLarge } from '@fortawesome/pro-regular-svg-icons/faTableCellsLarge';
import { faTableColumns as farTableColumns } from '@fortawesome/pro-regular-svg-icons/faTableColumns';
import { faTableLayout as farTableLayout } from '@fortawesome/pro-regular-svg-icons/faTableLayout';
import { faTableList as farTableList } from '@fortawesome/pro-regular-svg-icons/faTableList';
import { faTablePicnic as farTablePicnic } from '@fortawesome/pro-regular-svg-icons/faTablePicnic';
import { faTablePivot as farTablePivot } from '@fortawesome/pro-regular-svg-icons/faTablePivot';
import { faTableRows as farTableRows } from '@fortawesome/pro-regular-svg-icons/faTableRows';
import { faTablet as farTablet } from '@fortawesome/pro-regular-svg-icons/faTablet';
import { faTabletAlt as farTabletAlt } from '@fortawesome/pro-regular-svg-icons/faTabletAlt';
import { faTabletAndroid as farTabletAndroid } from '@fortawesome/pro-regular-svg-icons/faTabletAndroid';
import { faTabletAndroidAlt as farTabletAndroidAlt } from '@fortawesome/pro-regular-svg-icons/faTabletAndroidAlt';
import { faTabletButton as farTabletButton } from '@fortawesome/pro-regular-svg-icons/faTabletButton';
import { faTableTennis as farTableTennis } from '@fortawesome/pro-regular-svg-icons/faTableTennis';
import { faTableTennisPaddleBall as farTableTennisPaddleBall } from '@fortawesome/pro-regular-svg-icons/faTableTennisPaddleBall';
import { faTableTree as farTableTree } from '@fortawesome/pro-regular-svg-icons/faTableTree';
import { faTabletRugged as farTabletRugged } from '@fortawesome/pro-regular-svg-icons/faTabletRugged';
import { faTablets as farTablets } from '@fortawesome/pro-regular-svg-icons/faTablets';
import { faTabletScreen as farTabletScreen } from '@fortawesome/pro-regular-svg-icons/faTabletScreen';
import { faTabletScreenButton as farTabletScreenButton } from '@fortawesome/pro-regular-svg-icons/faTabletScreenButton';
import { faTachographDigital as farTachographDigital } from '@fortawesome/pro-regular-svg-icons/faTachographDigital';
import { faTachometer as farTachometer } from '@fortawesome/pro-regular-svg-icons/faTachometer';
import { faTachometerAlt as farTachometerAlt } from '@fortawesome/pro-regular-svg-icons/faTachometerAlt';
import { faTachometerAltAverage as farTachometerAltAverage } from '@fortawesome/pro-regular-svg-icons/faTachometerAltAverage';
import { faTachometerAltFast as farTachometerAltFast } from '@fortawesome/pro-regular-svg-icons/faTachometerAltFast';
import { faTachometerAltFastest as farTachometerAltFastest } from '@fortawesome/pro-regular-svg-icons/faTachometerAltFastest';
import { faTachometerAltSlow as farTachometerAltSlow } from '@fortawesome/pro-regular-svg-icons/faTachometerAltSlow';
import { faTachometerAltSlowest as farTachometerAltSlowest } from '@fortawesome/pro-regular-svg-icons/faTachometerAltSlowest';
import { faTachometerAverage as farTachometerAverage } from '@fortawesome/pro-regular-svg-icons/faTachometerAverage';
import { faTachometerFast as farTachometerFast } from '@fortawesome/pro-regular-svg-icons/faTachometerFast';
import { faTachometerFastest as farTachometerFastest } from '@fortawesome/pro-regular-svg-icons/faTachometerFastest';
import { faTachometerSlow as farTachometerSlow } from '@fortawesome/pro-regular-svg-icons/faTachometerSlow';
import { faTachometerSlowest as farTachometerSlowest } from '@fortawesome/pro-regular-svg-icons/faTachometerSlowest';
import { faTaco as farTaco } from '@fortawesome/pro-regular-svg-icons/faTaco';
import { faTag as farTag } from '@fortawesome/pro-regular-svg-icons/faTag';
import { faTags as farTags } from '@fortawesome/pro-regular-svg-icons/faTags';
import { faTally as farTally } from '@fortawesome/pro-regular-svg-icons/faTally';
import { faTally1 as farTally1 } from '@fortawesome/pro-regular-svg-icons/faTally1';
import { faTally2 as farTally2 } from '@fortawesome/pro-regular-svg-icons/faTally2';
import { faTally3 as farTally3 } from '@fortawesome/pro-regular-svg-icons/faTally3';
import { faTally4 as farTally4 } from '@fortawesome/pro-regular-svg-icons/faTally4';
import { faTally5 as farTally5 } from '@fortawesome/pro-regular-svg-icons/faTally5';
import { faTamale as farTamale } from '@fortawesome/pro-regular-svg-icons/faTamale';
import { faTanakh as farTanakh } from '@fortawesome/pro-regular-svg-icons/faTanakh';
import { faTankWater as farTankWater } from '@fortawesome/pro-regular-svg-icons/faTankWater';
import { faTape as farTape } from '@fortawesome/pro-regular-svg-icons/faTape';
import { faTarp as farTarp } from '@fortawesome/pro-regular-svg-icons/faTarp';
import { faTarpDroplet as farTarpDroplet } from '@fortawesome/pro-regular-svg-icons/faTarpDroplet';
import { faTasks as farTasks } from '@fortawesome/pro-regular-svg-icons/faTasks';
import { faTasksAlt as farTasksAlt } from '@fortawesome/pro-regular-svg-icons/faTasksAlt';
import { faTaxi as farTaxi } from '@fortawesome/pro-regular-svg-icons/faTaxi';
import { faTaxiBus as farTaxiBus } from '@fortawesome/pro-regular-svg-icons/faTaxiBus';
import { faTeamspeak as farTeamspeak } from '@fortawesome/free-brands-svg-icons/faTeamspeak';
import { faTeddyBear as farTeddyBear } from '@fortawesome/pro-regular-svg-icons/faTeddyBear';
import { faTeeth as farTeeth } from '@fortawesome/pro-regular-svg-icons/faTeeth';
import { faTeethOpen as farTeethOpen } from '@fortawesome/pro-regular-svg-icons/faTeethOpen';
import { faTelegram as farTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faTelegramPlane as farTelegramPlane } from '@fortawesome/free-brands-svg-icons/faTelegramPlane';
import { faTelescope as farTelescope } from '@fortawesome/pro-regular-svg-icons/faTelescope';
import { faTeletype as farTeletype } from '@fortawesome/pro-regular-svg-icons/faTeletype';
import { faTeletypeAnswer as farTeletypeAnswer } from '@fortawesome/pro-regular-svg-icons/faTeletypeAnswer';
import { faTelevision as farTelevision } from '@fortawesome/pro-regular-svg-icons/faTelevision';
import { faTemperature0 as farTemperature0 } from '@fortawesome/pro-regular-svg-icons/faTemperature0';
import { faTemperature1 as farTemperature1 } from '@fortawesome/pro-regular-svg-icons/faTemperature1';
import { faTemperature2 as farTemperature2 } from '@fortawesome/pro-regular-svg-icons/faTemperature2';
import { faTemperature3 as farTemperature3 } from '@fortawesome/pro-regular-svg-icons/faTemperature3';
import { faTemperature4 as farTemperature4 } from '@fortawesome/pro-regular-svg-icons/faTemperature4';
import { faTemperatureArrowDown as farTemperatureArrowDown } from '@fortawesome/pro-regular-svg-icons/faTemperatureArrowDown';
import { faTemperatureArrowUp as farTemperatureArrowUp } from '@fortawesome/pro-regular-svg-icons/faTemperatureArrowUp';
import { faTemperatureDown as farTemperatureDown } from '@fortawesome/pro-regular-svg-icons/faTemperatureDown';
import { faTemperatureEmpty as farTemperatureEmpty } from '@fortawesome/pro-regular-svg-icons/faTemperatureEmpty';
import { faTemperatureFrigid as farTemperatureFrigid } from '@fortawesome/pro-regular-svg-icons/faTemperatureFrigid';
import { faTemperatureFull as farTemperatureFull } from '@fortawesome/pro-regular-svg-icons/faTemperatureFull';
import { faTemperatureHalf as farTemperatureHalf } from '@fortawesome/pro-regular-svg-icons/faTemperatureHalf';
import { faTemperatureHigh as farTemperatureHigh } from '@fortawesome/pro-regular-svg-icons/faTemperatureHigh';
import { faTemperatureHot as farTemperatureHot } from '@fortawesome/pro-regular-svg-icons/faTemperatureHot';
import { faTemperatureList as farTemperatureList } from '@fortawesome/pro-regular-svg-icons/faTemperatureList';
import { faTemperatureLow as farTemperatureLow } from '@fortawesome/pro-regular-svg-icons/faTemperatureLow';
import { faTemperatureQuarter as farTemperatureQuarter } from '@fortawesome/pro-regular-svg-icons/faTemperatureQuarter';
import { faTemperatureSnow as farTemperatureSnow } from '@fortawesome/pro-regular-svg-icons/faTemperatureSnow';
import { faTemperatureSun as farTemperatureSun } from '@fortawesome/pro-regular-svg-icons/faTemperatureSun';
import { faTemperatureThreeQuarters as farTemperatureThreeQuarters } from '@fortawesome/pro-regular-svg-icons/faTemperatureThreeQuarters';
import { faTemperatureUp as farTemperatureUp } from '@fortawesome/pro-regular-svg-icons/faTemperatureUp';
import { faTencentWeibo as farTencentWeibo } from '@fortawesome/free-brands-svg-icons/faTencentWeibo';
import { faTenge as farTenge } from '@fortawesome/pro-regular-svg-icons/faTenge';
import { faTengeSign as farTengeSign } from '@fortawesome/pro-regular-svg-icons/faTengeSign';
import { faTennisBall as farTennisBall } from '@fortawesome/pro-regular-svg-icons/faTennisBall';
import { faTent as farTent } from '@fortawesome/pro-regular-svg-icons/faTent';
import { faTentArrowDownToLine as farTentArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faTentArrowDownToLine';
import { faTentArrowLeftRight as farTentArrowLeftRight } from '@fortawesome/pro-regular-svg-icons/faTentArrowLeftRight';
import { faTentArrowsDown as farTentArrowsDown } from '@fortawesome/pro-regular-svg-icons/faTentArrowsDown';
import { faTentArrowTurnLeft as farTentArrowTurnLeft } from '@fortawesome/pro-regular-svg-icons/faTentArrowTurnLeft';
import { faTentDoublePeak as farTentDoublePeak } from '@fortawesome/pro-regular-svg-icons/faTentDoublePeak';
import { faTents as farTents } from '@fortawesome/pro-regular-svg-icons/faTents';
import { faTerminal as farTerminal } from '@fortawesome/pro-regular-svg-icons/faTerminal';
import { faText as farText } from '@fortawesome/pro-regular-svg-icons/faText';
import { faTextHeight as farTextHeight } from '@fortawesome/pro-regular-svg-icons/faTextHeight';
import { faTextSize as farTextSize } from '@fortawesome/pro-regular-svg-icons/faTextSize';
import { faTextSlash as farTextSlash } from '@fortawesome/pro-regular-svg-icons/faTextSlash';
import { faTextWidth as farTextWidth } from '@fortawesome/pro-regular-svg-icons/faTextWidth';
import { faTh as farTh } from '@fortawesome/pro-regular-svg-icons/faTh';
import { faTheaterMasks as farTheaterMasks } from '@fortawesome/pro-regular-svg-icons/faTheaterMasks';
import { faThemeco as farThemeco } from '@fortawesome/free-brands-svg-icons/faThemeco';
import { faThemeisle as farThemeisle } from '@fortawesome/free-brands-svg-icons/faThemeisle';
import { faTheRedYeti as farTheRedYeti } from '@fortawesome/free-brands-svg-icons/faTheRedYeti';
import { faThermometer as farThermometer } from '@fortawesome/pro-regular-svg-icons/faThermometer';
import { faThermometer0 as farThermometer0 } from '@fortawesome/pro-regular-svg-icons/faThermometer0';
import { faThermometer1 as farThermometer1 } from '@fortawesome/pro-regular-svg-icons/faThermometer1';
import { faThermometer2 as farThermometer2 } from '@fortawesome/pro-regular-svg-icons/faThermometer2';
import { faThermometer3 as farThermometer3 } from '@fortawesome/pro-regular-svg-icons/faThermometer3';
import { faThermometer4 as farThermometer4 } from '@fortawesome/pro-regular-svg-icons/faThermometer4';
import { faThermometerEmpty as farThermometerEmpty } from '@fortawesome/pro-regular-svg-icons/faThermometerEmpty';
import { faThermometerFull as farThermometerFull } from '@fortawesome/pro-regular-svg-icons/faThermometerFull';
import { faThermometerHalf as farThermometerHalf } from '@fortawesome/pro-regular-svg-icons/faThermometerHalf';
import { faThermometerQuarter as farThermometerQuarter } from '@fortawesome/pro-regular-svg-icons/faThermometerQuarter';
import { faThermometerThreeQuarters as farThermometerThreeQuarters } from '@fortawesome/pro-regular-svg-icons/faThermometerThreeQuarters';
import { faTheta as farTheta } from '@fortawesome/pro-regular-svg-icons/faTheta';
import { faThinkPeaks as farThinkPeaks } from '@fortawesome/free-brands-svg-icons/faThinkPeaks';
import { faThLarge as farThLarge } from '@fortawesome/pro-regular-svg-icons/faThLarge';
import { faThList as farThList } from '@fortawesome/pro-regular-svg-icons/faThList';
import { faThoughtBubble as farThoughtBubble } from '@fortawesome/pro-regular-svg-icons/faThoughtBubble';
import { faThreads as farThreads } from '@fortawesome/free-brands-svg-icons/faThreads';
import { faThumbsDown as farThumbsDown } from '@fortawesome/pro-regular-svg-icons/faThumbsDown';
import { faThumbsUp as farThumbsUp } from '@fortawesome/pro-regular-svg-icons/faThumbsUp';
import { faThumbtack as farThumbtack } from '@fortawesome/pro-regular-svg-icons/faThumbtack';
import { faThunderstorm as farThunderstorm } from '@fortawesome/pro-regular-svg-icons/faThunderstorm';
import { faThunderstormMoon as farThunderstormMoon } from '@fortawesome/pro-regular-svg-icons/faThunderstormMoon';
import { faThunderstormSun as farThunderstormSun } from '@fortawesome/pro-regular-svg-icons/faThunderstormSun';
import { faTick as farTick } from '@fortawesome/pro-regular-svg-icons/faTick';
import { faTicket as farTicket } from '@fortawesome/pro-regular-svg-icons/faTicket';
import { faTicketAirline as farTicketAirline } from '@fortawesome/pro-regular-svg-icons/faTicketAirline';
import { faTicketAlt as farTicketAlt } from '@fortawesome/pro-regular-svg-icons/faTicketAlt';
import { faTicketPerforated as farTicketPerforated } from '@fortawesome/pro-regular-svg-icons/faTicketPerforated';
import { faTicketPerforatedPlane as farTicketPerforatedPlane } from '@fortawesome/pro-regular-svg-icons/faTicketPerforatedPlane';
import { faTicketPlane as farTicketPlane } from '@fortawesome/pro-regular-svg-icons/faTicketPlane';
import { faTickets as farTickets } from '@fortawesome/pro-regular-svg-icons/faTickets';
import { faTicketsAirline as farTicketsAirline } from '@fortawesome/pro-regular-svg-icons/faTicketsAirline';
import { faTicketSimple as farTicketSimple } from '@fortawesome/pro-regular-svg-icons/faTicketSimple';
import { faTicketsPerforated as farTicketsPerforated } from '@fortawesome/pro-regular-svg-icons/faTicketsPerforated';
import { faTicketsPerforatedPlane as farTicketsPerforatedPlane } from '@fortawesome/pro-regular-svg-icons/faTicketsPerforatedPlane';
import { faTicketsPlane as farTicketsPlane } from '@fortawesome/pro-regular-svg-icons/faTicketsPlane';
import { faTicketsSimple as farTicketsSimple } from '@fortawesome/pro-regular-svg-icons/faTicketsSimple';
import { faTiktok as farTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faTilde as farTilde } from '@fortawesome/pro-regular-svg-icons/faTilde';
import { faTimeline as farTimeline } from '@fortawesome/pro-regular-svg-icons/faTimeline';
import { faTimelineArrow as farTimelineArrow } from '@fortawesome/pro-regular-svg-icons/faTimelineArrow';
import { faTimer as farTimer } from '@fortawesome/pro-regular-svg-icons/faTimer';
import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faTimesCircle as farTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';
import { faTimesHexagon as farTimesHexagon } from '@fortawesome/pro-regular-svg-icons/faTimesHexagon';
import { faTimesOctagon as farTimesOctagon } from '@fortawesome/pro-regular-svg-icons/faTimesOctagon';
import { faTimesRectangle as farTimesRectangle } from '@fortawesome/pro-regular-svg-icons/faTimesRectangle';
import { faTimesSquare as farTimesSquare } from '@fortawesome/pro-regular-svg-icons/faTimesSquare';
import { faTimesToSlot as farTimesToSlot } from '@fortawesome/pro-regular-svg-icons/faTimesToSlot';
import { faTint as farTint } from '@fortawesome/pro-regular-svg-icons/faTint';
import { faTintSlash as farTintSlash } from '@fortawesome/pro-regular-svg-icons/faTintSlash';
import { faTire as farTire } from '@fortawesome/pro-regular-svg-icons/faTire';
import { faTired as farTired } from '@fortawesome/pro-regular-svg-icons/faTired';
import { faTireFlat as farTireFlat } from '@fortawesome/pro-regular-svg-icons/faTireFlat';
import { faTirePressureWarning as farTirePressureWarning } from '@fortawesome/pro-regular-svg-icons/faTirePressureWarning';
import { faTireRugged as farTireRugged } from '@fortawesome/pro-regular-svg-icons/faTireRugged';
import { faToggleLargeOff as farToggleLargeOff } from '@fortawesome/pro-regular-svg-icons/faToggleLargeOff';
import { faToggleLargeOn as farToggleLargeOn } from '@fortawesome/pro-regular-svg-icons/faToggleLargeOn';
import { faToggleOff as farToggleOff } from '@fortawesome/pro-regular-svg-icons/faToggleOff';
import { faToggleOn as farToggleOn } from '@fortawesome/pro-regular-svg-icons/faToggleOn';
import { faToilet as farToilet } from '@fortawesome/pro-regular-svg-icons/faToilet';
import { faToiletPaper as farToiletPaper } from '@fortawesome/pro-regular-svg-icons/faToiletPaper';
import { faToiletPaperAlt as farToiletPaperAlt } from '@fortawesome/pro-regular-svg-icons/faToiletPaperAlt';
import { faToiletPaperBlank as farToiletPaperBlank } from '@fortawesome/pro-regular-svg-icons/faToiletPaperBlank';
import { faToiletPaperBlankUnder as farToiletPaperBlankUnder } from '@fortawesome/pro-regular-svg-icons/faToiletPaperBlankUnder';
import { faToiletPaperCheck as farToiletPaperCheck } from '@fortawesome/pro-regular-svg-icons/faToiletPaperCheck';
import { faToiletPaperReverse as farToiletPaperReverse } from '@fortawesome/pro-regular-svg-icons/faToiletPaperReverse';
import { faToiletPaperReverseAlt as farToiletPaperReverseAlt } from '@fortawesome/pro-regular-svg-icons/faToiletPaperReverseAlt';
import { faToiletPaperReverseSlash as farToiletPaperReverseSlash } from '@fortawesome/pro-regular-svg-icons/faToiletPaperReverseSlash';
import { faToiletPaperSlash as farToiletPaperSlash } from '@fortawesome/pro-regular-svg-icons/faToiletPaperSlash';
import { faToiletPaperUnder as farToiletPaperUnder } from '@fortawesome/pro-regular-svg-icons/faToiletPaperUnder';
import { faToiletPaperUnderSlash as farToiletPaperUnderSlash } from '@fortawesome/pro-regular-svg-icons/faToiletPaperUnderSlash';
import { faToiletPaperXmark as farToiletPaperXmark } from '@fortawesome/pro-regular-svg-icons/faToiletPaperXmark';
import { faToiletPortable as farToiletPortable } from '@fortawesome/pro-regular-svg-icons/faToiletPortable';
import { faToiletsPortable as farToiletsPortable } from '@fortawesome/pro-regular-svg-icons/faToiletsPortable';
import { faTomato as farTomato } from '@fortawesome/pro-regular-svg-icons/faTomato';
import { faTombstone as farTombstone } from '@fortawesome/pro-regular-svg-icons/faTombstone';
import { faTombstoneAlt as farTombstoneAlt } from '@fortawesome/pro-regular-svg-icons/faTombstoneAlt';
import { faTombstoneBlank as farTombstoneBlank } from '@fortawesome/pro-regular-svg-icons/faTombstoneBlank';
import { faToolbox as farToolbox } from '@fortawesome/pro-regular-svg-icons/faToolbox';
import { faTools as farTools } from '@fortawesome/pro-regular-svg-icons/faTools';
import { faTooth as farTooth } from '@fortawesome/pro-regular-svg-icons/faTooth';
import { faToothbrush as farToothbrush } from '@fortawesome/pro-regular-svg-icons/faToothbrush';
import { faTorah as farTorah } from '@fortawesome/pro-regular-svg-icons/faTorah';
import { faToriiGate as farToriiGate } from '@fortawesome/pro-regular-svg-icons/faToriiGate';
import { faTornado as farTornado } from '@fortawesome/pro-regular-svg-icons/faTornado';
import { faTowerBroadcast as farTowerBroadcast } from '@fortawesome/pro-regular-svg-icons/faTowerBroadcast';
import { faTowerCell as farTowerCell } from '@fortawesome/pro-regular-svg-icons/faTowerCell';
import { faTowerControl as farTowerControl } from '@fortawesome/pro-regular-svg-icons/faTowerControl';
import { faTowerObservation as farTowerObservation } from '@fortawesome/pro-regular-svg-icons/faTowerObservation';
import { faTractor as farTractor } from '@fortawesome/pro-regular-svg-icons/faTractor';
import { faTradeFederation as farTradeFederation } from '@fortawesome/free-brands-svg-icons/faTradeFederation';
import { faTrademark as farTrademark } from '@fortawesome/pro-regular-svg-icons/faTrademark';
import { faTrafficCone as farTrafficCone } from '@fortawesome/pro-regular-svg-icons/faTrafficCone';
import { faTrafficLight as farTrafficLight } from '@fortawesome/pro-regular-svg-icons/faTrafficLight';
import { faTrafficLightGo as farTrafficLightGo } from '@fortawesome/pro-regular-svg-icons/faTrafficLightGo';
import { faTrafficLightSlow as farTrafficLightSlow } from '@fortawesome/pro-regular-svg-icons/faTrafficLightSlow';
import { faTrafficLightStop as farTrafficLightStop } from '@fortawesome/pro-regular-svg-icons/faTrafficLightStop';
import { faTrailer as farTrailer } from '@fortawesome/pro-regular-svg-icons/faTrailer';
import { faTrain as farTrain } from '@fortawesome/pro-regular-svg-icons/faTrain';
import { faTrainSubway as farTrainSubway } from '@fortawesome/pro-regular-svg-icons/faTrainSubway';
import { faTrainSubwayTunnel as farTrainSubwayTunnel } from '@fortawesome/pro-regular-svg-icons/faTrainSubwayTunnel';
import { faTrainTrack as farTrainTrack } from '@fortawesome/pro-regular-svg-icons/faTrainTrack';
import { faTrainTram as farTrainTram } from '@fortawesome/pro-regular-svg-icons/faTrainTram';
import { faTrainTunnel as farTrainTunnel } from '@fortawesome/pro-regular-svg-icons/faTrainTunnel';
import { faTram as farTram } from '@fortawesome/pro-regular-svg-icons/faTram';
import { faTransformerBolt as farTransformerBolt } from '@fortawesome/pro-regular-svg-icons/faTransformerBolt';
import { faTransgender as farTransgender } from '@fortawesome/pro-regular-svg-icons/faTransgender';
import { faTransgenderAlt as farTransgenderAlt } from '@fortawesome/pro-regular-svg-icons/faTransgenderAlt';
import { faTransporter as farTransporter } from '@fortawesome/pro-regular-svg-icons/faTransporter';
import { faTransporter1 as farTransporter1 } from '@fortawesome/pro-regular-svg-icons/faTransporter1';
import { faTransporter2 as farTransporter2 } from '@fortawesome/pro-regular-svg-icons/faTransporter2';
import { faTransporter3 as farTransporter3 } from '@fortawesome/pro-regular-svg-icons/faTransporter3';
import { faTransporter4 as farTransporter4 } from '@fortawesome/pro-regular-svg-icons/faTransporter4';
import { faTransporter5 as farTransporter5 } from '@fortawesome/pro-regular-svg-icons/faTransporter5';
import { faTransporter6 as farTransporter6 } from '@fortawesome/pro-regular-svg-icons/faTransporter6';
import { faTransporter7 as farTransporter7 } from '@fortawesome/pro-regular-svg-icons/faTransporter7';
import { faTransporterEmpty as farTransporterEmpty } from '@fortawesome/pro-regular-svg-icons/faTransporterEmpty';
import { faTrash as farTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faTrashAlt as farTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import { faTrashAltSlash as farTrashAltSlash } from '@fortawesome/pro-regular-svg-icons/faTrashAltSlash';
import { faTrashArrowTurnLeft as farTrashArrowTurnLeft } from '@fortawesome/pro-regular-svg-icons/faTrashArrowTurnLeft';
import { faTrashArrowUp as farTrashArrowUp } from '@fortawesome/pro-regular-svg-icons/faTrashArrowUp';
import { faTrashCan as farTrashCan } from '@fortawesome/pro-regular-svg-icons/faTrashCan';
import { faTrashCanArrowTurnLeft as farTrashCanArrowTurnLeft } from '@fortawesome/pro-regular-svg-icons/faTrashCanArrowTurnLeft';
import { faTrashCanArrowUp as farTrashCanArrowUp } from '@fortawesome/pro-regular-svg-icons/faTrashCanArrowUp';
import { faTrashCanCheck as farTrashCanCheck } from '@fortawesome/pro-regular-svg-icons/faTrashCanCheck';
import { faTrashCanClock as farTrashCanClock } from '@fortawesome/pro-regular-svg-icons/faTrashCanClock';
import { faTrashCanList as farTrashCanList } from '@fortawesome/pro-regular-svg-icons/faTrashCanList';
import { faTrashCanPlus as farTrashCanPlus } from '@fortawesome/pro-regular-svg-icons/faTrashCanPlus';
import { faTrashCanSlash as farTrashCanSlash } from '@fortawesome/pro-regular-svg-icons/faTrashCanSlash';
import { faTrashCanUndo as farTrashCanUndo } from '@fortawesome/pro-regular-svg-icons/faTrashCanUndo';
import { faTrashCanXmark as farTrashCanXmark } from '@fortawesome/pro-regular-svg-icons/faTrashCanXmark';
import { faTrashCheck as farTrashCheck } from '@fortawesome/pro-regular-svg-icons/faTrashCheck';
import { faTrashCircle as farTrashCircle } from '@fortawesome/pro-regular-svg-icons/faTrashCircle';
import { faTrashClock as farTrashClock } from '@fortawesome/pro-regular-svg-icons/faTrashClock';
import { faTrashList as farTrashList } from '@fortawesome/pro-regular-svg-icons/faTrashList';
import { faTrashPlus as farTrashPlus } from '@fortawesome/pro-regular-svg-icons/faTrashPlus';
import { faTrashRestore as farTrashRestore } from '@fortawesome/pro-regular-svg-icons/faTrashRestore';
import { faTrashRestoreAlt as farTrashRestoreAlt } from '@fortawesome/pro-regular-svg-icons/faTrashRestoreAlt';
import { faTrashSlash as farTrashSlash } from '@fortawesome/pro-regular-svg-icons/faTrashSlash';
import { faTrashUndo as farTrashUndo } from '@fortawesome/pro-regular-svg-icons/faTrashUndo';
import { faTrashUndoAlt as farTrashUndoAlt } from '@fortawesome/pro-regular-svg-icons/faTrashUndoAlt';
import { faTrashXmark as farTrashXmark } from '@fortawesome/pro-regular-svg-icons/faTrashXmark';
import { faTreasureChest as farTreasureChest } from '@fortawesome/pro-regular-svg-icons/faTreasureChest';
import { faTree as farTree } from '@fortawesome/pro-regular-svg-icons/faTree';
import { faTreeAlt as farTreeAlt } from '@fortawesome/pro-regular-svg-icons/faTreeAlt';
import { faTreeChristmas as farTreeChristmas } from '@fortawesome/pro-regular-svg-icons/faTreeChristmas';
import { faTreeCity as farTreeCity } from '@fortawesome/pro-regular-svg-icons/faTreeCity';
import { faTreeDeciduous as farTreeDeciduous } from '@fortawesome/pro-regular-svg-icons/faTreeDeciduous';
import { faTreeDecorated as farTreeDecorated } from '@fortawesome/pro-regular-svg-icons/faTreeDecorated';
import { faTreeLarge as farTreeLarge } from '@fortawesome/pro-regular-svg-icons/faTreeLarge';
import { faTreePalm as farTreePalm } from '@fortawesome/pro-regular-svg-icons/faTreePalm';
import { faTrees as farTrees } from '@fortawesome/pro-regular-svg-icons/faTrees';
import { faTrello as farTrello } from '@fortawesome/free-brands-svg-icons/faTrello';
import { faTRex as farTRex } from '@fortawesome/pro-regular-svg-icons/faTRex';
import { faTrianBalbot as farTrianBalbot } from '@fortawesome/pro-regular-svg-icons/faTrianBalbot';
import { faTriangle as farTriangle } from '@fortawesome/pro-regular-svg-icons/faTriangle';
import { faTriangleCircleSquare as farTriangleCircleSquare } from '@fortawesome/pro-regular-svg-icons/faTriangleCircleSquare';
import { faTriangleExclamation as farTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { faTriangleInstrument as farTriangleInstrument } from '@fortawesome/pro-regular-svg-icons/faTriangleInstrument';
import { faTriangleMusic as farTriangleMusic } from '@fortawesome/pro-regular-svg-icons/faTriangleMusic';
import { faTrianglePersonDigging as farTrianglePersonDigging } from '@fortawesome/pro-regular-svg-icons/faTrianglePersonDigging';
import { faTricycle as farTricycle } from '@fortawesome/pro-regular-svg-icons/faTricycle';
import { faTricycleAdult as farTricycleAdult } from '@fortawesome/pro-regular-svg-icons/faTricycleAdult';
import { faTrillium as farTrillium } from '@fortawesome/pro-regular-svg-icons/faTrillium';
import { faTrophy as farTrophy } from '@fortawesome/pro-regular-svg-icons/faTrophy';
import { faTrophyAlt as farTrophyAlt } from '@fortawesome/pro-regular-svg-icons/faTrophyAlt';
import { faTrophyStar as farTrophyStar } from '@fortawesome/pro-regular-svg-icons/faTrophyStar';
import { faTrowel as farTrowel } from '@fortawesome/pro-regular-svg-icons/faTrowel';
import { faTrowelBricks as farTrowelBricks } from '@fortawesome/pro-regular-svg-icons/faTrowelBricks';
import { faTruck as farTruck } from '@fortawesome/pro-regular-svg-icons/faTruck';
import { faTruckArrowRight as farTruckArrowRight } from '@fortawesome/pro-regular-svg-icons/faTruckArrowRight';
import { faTruckBolt as farTruckBolt } from '@fortawesome/pro-regular-svg-icons/faTruckBolt';
import { faTruckClock as farTruckClock } from '@fortawesome/pro-regular-svg-icons/faTruckClock';
import { faTruckContainer as farTruckContainer } from '@fortawesome/pro-regular-svg-icons/faTruckContainer';
import { faTruckContainerEmpty as farTruckContainerEmpty } from '@fortawesome/pro-regular-svg-icons/faTruckContainerEmpty';
import { faTruckCouch as farTruckCouch } from '@fortawesome/pro-regular-svg-icons/faTruckCouch';
import { faTruckDroplet as farTruckDroplet } from '@fortawesome/pro-regular-svg-icons/faTruckDroplet';
import { faTruckFast as farTruckFast } from '@fortawesome/pro-regular-svg-icons/faTruckFast';
import { faTruckField as farTruckField } from '@fortawesome/pro-regular-svg-icons/faTruckField';
import { faTruckFieldUn as farTruckFieldUn } from '@fortawesome/pro-regular-svg-icons/faTruckFieldUn';
import { faTruckFire as farTruckFire } from '@fortawesome/pro-regular-svg-icons/faTruckFire';
import { faTruckFlatbed as farTruckFlatbed } from '@fortawesome/pro-regular-svg-icons/faTruckFlatbed';
import { faTruckFront as farTruckFront } from '@fortawesome/pro-regular-svg-icons/faTruckFront';
import { faTruckLadder as farTruckLadder } from '@fortawesome/pro-regular-svg-icons/faTruckLadder';
import { faTruckLoading as farTruckLoading } from '@fortawesome/pro-regular-svg-icons/faTruckLoading';
import { faTruckMedical as farTruckMedical } from '@fortawesome/pro-regular-svg-icons/faTruckMedical';
import { faTruckMonster as farTruckMonster } from '@fortawesome/pro-regular-svg-icons/faTruckMonster';
import { faTruckMoving as farTruckMoving } from '@fortawesome/pro-regular-svg-icons/faTruckMoving';
import { faTruckPickup as farTruckPickup } from '@fortawesome/pro-regular-svg-icons/faTruckPickup';
import { faTruckPlane as farTruckPlane } from '@fortawesome/pro-regular-svg-icons/faTruckPlane';
import { faTruckPlow as farTruckPlow } from '@fortawesome/pro-regular-svg-icons/faTruckPlow';
import { faTruckRamp as farTruckRamp } from '@fortawesome/pro-regular-svg-icons/faTruckRamp';
import { faTruckRampBox as farTruckRampBox } from '@fortawesome/pro-regular-svg-icons/faTruckRampBox';
import { faTruckRampCouch as farTruckRampCouch } from '@fortawesome/pro-regular-svg-icons/faTruckRampCouch';
import { faTruckTow as farTruckTow } from '@fortawesome/pro-regular-svg-icons/faTruckTow';
import { faTruckUtensils as farTruckUtensils } from '@fortawesome/pro-regular-svg-icons/faTruckUtensils';
import { faTrumpet as farTrumpet } from '@fortawesome/pro-regular-svg-icons/faTrumpet';
import { faTry as farTry } from '@fortawesome/pro-regular-svg-icons/faTry';
import { faTshirt as farTshirt } from '@fortawesome/pro-regular-svg-icons/faTshirt';
import { faTty as farTty } from '@fortawesome/pro-regular-svg-icons/faTty';
import { faTtyAnswer as farTtyAnswer } from '@fortawesome/pro-regular-svg-icons/faTtyAnswer';
import { faTugrikSign as farTugrikSign } from '@fortawesome/pro-regular-svg-icons/faTugrikSign';
import { faTumblr as farTumblr } from '@fortawesome/free-brands-svg-icons/faTumblr';
import { faTumblrSquare as farTumblrSquare } from '@fortawesome/free-brands-svg-icons/faTumblrSquare';
import { faTurkey as farTurkey } from '@fortawesome/pro-regular-svg-icons/faTurkey';
import { faTurkishLira as farTurkishLira } from '@fortawesome/pro-regular-svg-icons/faTurkishLira';
import { faTurkishLiraSign as farTurkishLiraSign } from '@fortawesome/pro-regular-svg-icons/faTurkishLiraSign';
import { faTurnDown as farTurnDown } from '@fortawesome/pro-regular-svg-icons/faTurnDown';
import { faTurnDownLeft as farTurnDownLeft } from '@fortawesome/pro-regular-svg-icons/faTurnDownLeft';
import { faTurnDownRight as farTurnDownRight } from '@fortawesome/pro-regular-svg-icons/faTurnDownRight';
import { faTurnLeft as farTurnLeft } from '@fortawesome/pro-regular-svg-icons/faTurnLeft';
import { faTurnLeftDown as farTurnLeftDown } from '@fortawesome/pro-regular-svg-icons/faTurnLeftDown';
import { faTurnLeftUp as farTurnLeftUp } from '@fortawesome/pro-regular-svg-icons/faTurnLeftUp';
import { faTurnRight as farTurnRight } from '@fortawesome/pro-regular-svg-icons/faTurnRight';
import { faTurntable as farTurntable } from '@fortawesome/pro-regular-svg-icons/faTurntable';
import { faTurnUp as farTurnUp } from '@fortawesome/pro-regular-svg-icons/faTurnUp';
import { faTurtle as farTurtle } from '@fortawesome/pro-regular-svg-icons/faTurtle';
import { faTv as farTv } from '@fortawesome/pro-regular-svg-icons/faTv';
import { faTvAlt as farTvAlt } from '@fortawesome/pro-regular-svg-icons/faTvAlt';
import { faTvMusic as farTvMusic } from '@fortawesome/pro-regular-svg-icons/faTvMusic';
import { faTvRetro as farTvRetro } from '@fortawesome/pro-regular-svg-icons/faTvRetro';
import { faTwitch as farTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch';
import { faTwitter as farTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faTwitterSquare as farTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faTypewriter as farTypewriter } from '@fortawesome/pro-regular-svg-icons/faTypewriter';
import { faTypo3 as farTypo3 } from '@fortawesome/free-brands-svg-icons/faTypo3';
import { faU as farU } from '@fortawesome/pro-regular-svg-icons/faU';
import { faUber as farUber } from '@fortawesome/free-brands-svg-icons/faUber';
import { faUbuntu as farUbuntu } from '@fortawesome/free-brands-svg-icons/faUbuntu';
import { faUfo as farUfo } from '@fortawesome/pro-regular-svg-icons/faUfo';
import { faUfoBeam as farUfoBeam } from '@fortawesome/pro-regular-svg-icons/faUfoBeam';
import { faUikit as farUikit } from '@fortawesome/free-brands-svg-icons/faUikit';
import { faUmbraco as farUmbraco } from '@fortawesome/free-brands-svg-icons/faUmbraco';
import { faUmbrella as farUmbrella } from '@fortawesome/pro-regular-svg-icons/faUmbrella';
import { faUmbrellaAlt as farUmbrellaAlt } from '@fortawesome/pro-regular-svg-icons/faUmbrellaAlt';
import { faUmbrellaBeach as farUmbrellaBeach } from '@fortawesome/pro-regular-svg-icons/faUmbrellaBeach';
import { faUmbrellaSimple as farUmbrellaSimple } from '@fortawesome/pro-regular-svg-icons/faUmbrellaSimple';
import { faUncharted as farUncharted } from '@fortawesome/free-brands-svg-icons/faUncharted';
import { faUnderline as farUnderline } from '@fortawesome/pro-regular-svg-icons/faUnderline';
import { faUndo as farUndo } from '@fortawesome/pro-regular-svg-icons/faUndo';
import { faUndoAlt as farUndoAlt } from '@fortawesome/pro-regular-svg-icons/faUndoAlt';
import { faUnicorn as farUnicorn } from '@fortawesome/pro-regular-svg-icons/faUnicorn';
import { faUniformMartialArts as farUniformMartialArts } from '@fortawesome/pro-regular-svg-icons/faUniformMartialArts';
import { faUnion as farUnion } from '@fortawesome/pro-regular-svg-icons/faUnion';
import { faUniregistry as farUniregistry } from '@fortawesome/free-brands-svg-icons/faUniregistry';
import { faUnity as farUnity } from '@fortawesome/free-brands-svg-icons/faUnity';
import { faUniversalAccess as farUniversalAccess } from '@fortawesome/pro-regular-svg-icons/faUniversalAccess';
import { faUniversity as farUniversity } from '@fortawesome/pro-regular-svg-icons/faUniversity';
import { faUnlink as farUnlink } from '@fortawesome/pro-regular-svg-icons/faUnlink';
import { faUnlock as farUnlock } from '@fortawesome/pro-regular-svg-icons/faUnlock';
import { faUnlockAlt as farUnlockAlt } from '@fortawesome/pro-regular-svg-icons/faUnlockAlt';
import { faUnlockKeyhole as farUnlockKeyhole } from '@fortawesome/pro-regular-svg-icons/faUnlockKeyhole';
import { faUnsorted as farUnsorted } from '@fortawesome/pro-regular-svg-icons/faUnsorted';
import { faUnsplash as farUnsplash } from '@fortawesome/free-brands-svg-icons/faUnsplash';
import { faUntappd as farUntappd } from '@fortawesome/free-brands-svg-icons/faUntappd';
import { faUp as farUp } from '@fortawesome/pro-regular-svg-icons/faUp';
import { faUpDown as farUpDown } from '@fortawesome/pro-regular-svg-icons/faUpDown';
import { faUpDownLeftRight as farUpDownLeftRight } from '@fortawesome/pro-regular-svg-icons/faUpDownLeftRight';
import { faUpFromBracket as farUpFromBracket } from '@fortawesome/pro-regular-svg-icons/faUpFromBracket';
import { faUpFromDottedLine as farUpFromDottedLine } from '@fortawesome/pro-regular-svg-icons/faUpFromDottedLine';
import { faUpFromLine as farUpFromLine } from '@fortawesome/pro-regular-svg-icons/faUpFromLine';
import { faUpLeft as farUpLeft } from '@fortawesome/pro-regular-svg-icons/faUpLeft';
import { faUpload as farUpload } from '@fortawesome/pro-regular-svg-icons/faUpload';
import { faUpLong as farUpLong } from '@fortawesome/pro-regular-svg-icons/faUpLong';
import { faUpRight as farUpRight } from '@fortawesome/pro-regular-svg-icons/faUpRight';
import { faUpRightAndDownLeftFromCenter as farUpRightAndDownLeftFromCenter } from '@fortawesome/pro-regular-svg-icons/faUpRightAndDownLeftFromCenter';
import { faUpRightFromSquare as farUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faUpRightFromSquare';
import { faUps as farUps } from '@fortawesome/free-brands-svg-icons/faUps';
import { faUpToDottedLine as farUpToDottedLine } from '@fortawesome/pro-regular-svg-icons/faUpToDottedLine';
import { faUpToLine as farUpToLine } from '@fortawesome/pro-regular-svg-icons/faUpToLine';
import { faUpwork as farUpwork } from '@fortawesome/free-brands-svg-icons/faUpwork';
import { faUsb as farUsb } from '@fortawesome/free-brands-svg-icons/faUsb';
import { faUsbDrive as farUsbDrive } from '@fortawesome/pro-regular-svg-icons/faUsbDrive';
import { faUsd as farUsd } from '@fortawesome/pro-regular-svg-icons/faUsd';
import { faUsdCircle as farUsdCircle } from '@fortawesome/pro-regular-svg-icons/faUsdCircle';
import { faUsdSquare as farUsdSquare } from '@fortawesome/pro-regular-svg-icons/faUsdSquare';
import { faUser as farUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faUserAlien as farUserAlien } from '@fortawesome/pro-regular-svg-icons/faUserAlien';
import { faUserAlt as farUserAlt } from '@fortawesome/pro-regular-svg-icons/faUserAlt';
import { faUserAltSlash as farUserAltSlash } from '@fortawesome/pro-regular-svg-icons/faUserAltSlash';
import { faUserAstronaut as farUserAstronaut } from '@fortawesome/pro-regular-svg-icons/faUserAstronaut';
import { faUserBountyHunter as farUserBountyHunter } from '@fortawesome/pro-regular-svg-icons/faUserBountyHunter';
import { faUserChart as farUserChart } from '@fortawesome/pro-regular-svg-icons/faUserChart';
import { faUserCheck as farUserCheck } from '@fortawesome/pro-regular-svg-icons/faUserCheck';
import { faUserChef as farUserChef } from '@fortawesome/pro-regular-svg-icons/faUserChef';
import { faUserCircle as farUserCircle } from '@fortawesome/pro-regular-svg-icons/faUserCircle';
import { faUserClock as farUserClock } from '@fortawesome/pro-regular-svg-icons/faUserClock';
import { faUserCog as farUserCog } from '@fortawesome/pro-regular-svg-icons/faUserCog';
import { faUserConstruction as farUserConstruction } from '@fortawesome/pro-regular-svg-icons/faUserConstruction';
import { faUserCowboy as farUserCowboy } from '@fortawesome/pro-regular-svg-icons/faUserCowboy';
import { faUserCrown as farUserCrown } from '@fortawesome/pro-regular-svg-icons/faUserCrown';
import { faUserDoctor as farUserDoctor } from '@fortawesome/pro-regular-svg-icons/faUserDoctor';
import { faUserDoctorHair as farUserDoctorHair } from '@fortawesome/pro-regular-svg-icons/faUserDoctorHair';
import { faUserDoctorHairLong as farUserDoctorHairLong } from '@fortawesome/pro-regular-svg-icons/faUserDoctorHairLong';
import { faUserDoctorMessage as farUserDoctorMessage } from '@fortawesome/pro-regular-svg-icons/faUserDoctorMessage';
import { faUserEdit as farUserEdit } from '@fortawesome/pro-regular-svg-icons/faUserEdit';
import { faUserFriends as farUserFriends } from '@fortawesome/pro-regular-svg-icons/faUserFriends';
import { faUserGear as farUserGear } from '@fortawesome/pro-regular-svg-icons/faUserGear';
import { faUserGraduate as farUserGraduate } from '@fortawesome/pro-regular-svg-icons/faUserGraduate';
import { faUserGroup as farUserGroup } from '@fortawesome/pro-regular-svg-icons/faUserGroup';
import { faUserGroupCrown as farUserGroupCrown } from '@fortawesome/pro-regular-svg-icons/faUserGroupCrown';
import { faUserGroupSimple as farUserGroupSimple } from '@fortawesome/pro-regular-svg-icons/faUserGroupSimple';
import { faUserHair as farUserHair } from '@fortawesome/pro-regular-svg-icons/faUserHair';
import { faUserHairBuns as farUserHairBuns } from '@fortawesome/pro-regular-svg-icons/faUserHairBuns';
import { faUserHairLong as farUserHairLong } from '@fortawesome/pro-regular-svg-icons/faUserHairLong';
import { faUserHairMullet as farUserHairMullet } from '@fortawesome/pro-regular-svg-icons/faUserHairMullet';
import { faUserHardHat as farUserHardHat } from '@fortawesome/pro-regular-svg-icons/faUserHardHat';
import { faUserHeadset as farUserHeadset } from '@fortawesome/pro-regular-svg-icons/faUserHeadset';
import { faUserHelmetSafety as farUserHelmetSafety } from '@fortawesome/pro-regular-svg-icons/faUserHelmetSafety';
import { faUserInjured as farUserInjured } from '@fortawesome/pro-regular-svg-icons/faUserInjured';
import { faUserLarge as farUserLarge } from '@fortawesome/pro-regular-svg-icons/faUserLarge';
import { faUserLargeSlash as farUserLargeSlash } from '@fortawesome/pro-regular-svg-icons/faUserLargeSlash';
import { faUserLock as farUserLock } from '@fortawesome/pro-regular-svg-icons/faUserLock';
import { faUserMagnifyingGlass as farUserMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faUserMagnifyingGlass';
import { faUserMd as farUserMd } from '@fortawesome/pro-regular-svg-icons/faUserMd';
import { faUserMdChat as farUserMdChat } from '@fortawesome/pro-regular-svg-icons/faUserMdChat';
import { faUserMinus as farUserMinus } from '@fortawesome/pro-regular-svg-icons/faUserMinus';
import { faUserMusic as farUserMusic } from '@fortawesome/pro-regular-svg-icons/faUserMusic';
import { faUserNinja as farUserNinja } from '@fortawesome/pro-regular-svg-icons/faUserNinja';
import { faUserNurse as farUserNurse } from '@fortawesome/pro-regular-svg-icons/faUserNurse';
import { faUserNurseHair as farUserNurseHair } from '@fortawesome/pro-regular-svg-icons/faUserNurseHair';
import { faUserNurseHairLong as farUserNurseHairLong } from '@fortawesome/pro-regular-svg-icons/faUserNurseHairLong';
import { faUserPen as farUserPen } from '@fortawesome/pro-regular-svg-icons/faUserPen';
import { faUserPilot as farUserPilot } from '@fortawesome/pro-regular-svg-icons/faUserPilot';
import { faUserPilotTie as farUserPilotTie } from '@fortawesome/pro-regular-svg-icons/faUserPilotTie';
import { faUserPlus as farUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import { faUserPolice as farUserPolice } from '@fortawesome/pro-regular-svg-icons/faUserPolice';
import { faUserPoliceTie as farUserPoliceTie } from '@fortawesome/pro-regular-svg-icons/faUserPoliceTie';
import { faUserRobot as farUserRobot } from '@fortawesome/pro-regular-svg-icons/faUserRobot';
import { faUserRobotXmarks as farUserRobotXmarks } from '@fortawesome/pro-regular-svg-icons/faUserRobotXmarks';
import { faUsers as farUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { faUsersBetweenLines as farUsersBetweenLines } from '@fortawesome/pro-regular-svg-icons/faUsersBetweenLines';
import { faUsersClass as farUsersClass } from '@fortawesome/pro-regular-svg-icons/faUsersClass';
import { faUsersCog as farUsersCog } from '@fortawesome/pro-regular-svg-icons/faUsersCog';
import { faUsersCrown as farUsersCrown } from '@fortawesome/pro-regular-svg-icons/faUsersCrown';
import { faUserSecret as farUserSecret } from '@fortawesome/pro-regular-svg-icons/faUserSecret';
import { faUsersGear as farUsersGear } from '@fortawesome/pro-regular-svg-icons/faUsersGear';
import { faUserShakespeare as farUserShakespeare } from '@fortawesome/pro-regular-svg-icons/faUserShakespeare';
import { faUserShield as farUserShield } from '@fortawesome/pro-regular-svg-icons/faUserShield';
import { faUserSlash as farUserSlash } from '@fortawesome/pro-regular-svg-icons/faUserSlash';
import { faUsersLine as farUsersLine } from '@fortawesome/pro-regular-svg-icons/faUsersLine';
import { faUsersMedical as farUsersMedical } from '@fortawesome/pro-regular-svg-icons/faUsersMedical';
import { faUsersRays as farUsersRays } from '@fortawesome/pro-regular-svg-icons/faUsersRays';
import { faUsersRectangle as farUsersRectangle } from '@fortawesome/pro-regular-svg-icons/faUsersRectangle';
import { faUsersSlash as farUsersSlash } from '@fortawesome/pro-regular-svg-icons/faUsersSlash';
import { faUsersViewfinder as farUsersViewfinder } from '@fortawesome/pro-regular-svg-icons/faUsersViewfinder';
import { faUserTag as farUserTag } from '@fortawesome/pro-regular-svg-icons/faUserTag';
import { faUserTie as farUserTie } from '@fortawesome/pro-regular-svg-icons/faUserTie';
import { faUserTieHair as farUserTieHair } from '@fortawesome/pro-regular-svg-icons/faUserTieHair';
import { faUserTieHairLong as farUserTieHairLong } from '@fortawesome/pro-regular-svg-icons/faUserTieHairLong';
import { faUserTimes as farUserTimes } from '@fortawesome/pro-regular-svg-icons/faUserTimes';
import { faUserUnlock as farUserUnlock } from '@fortawesome/pro-regular-svg-icons/faUserUnlock';
import { faUserVisor as farUserVisor } from '@fortawesome/pro-regular-svg-icons/faUserVisor';
import { faUserVneck as farUserVneck } from '@fortawesome/pro-regular-svg-icons/faUserVneck';
import { faUserVneckHair as farUserVneckHair } from '@fortawesome/pro-regular-svg-icons/faUserVneckHair';
import { faUserVneckHairLong as farUserVneckHairLong } from '@fortawesome/pro-regular-svg-icons/faUserVneckHairLong';
import { faUserXmark as farUserXmark } from '@fortawesome/pro-regular-svg-icons/faUserXmark';
import { faUsps as farUsps } from '@fortawesome/free-brands-svg-icons/faUsps';
import { faUssunnah as farUssunnah } from '@fortawesome/free-brands-svg-icons/faUssunnah';
import { faUtensilFork as farUtensilFork } from '@fortawesome/pro-regular-svg-icons/faUtensilFork';
import { faUtensilKnife as farUtensilKnife } from '@fortawesome/pro-regular-svg-icons/faUtensilKnife';
import { faUtensils as farUtensils } from '@fortawesome/pro-regular-svg-icons/faUtensils';
import { faUtensilsAlt as farUtensilsAlt } from '@fortawesome/pro-regular-svg-icons/faUtensilsAlt';
import { faUtensilSpoon as farUtensilSpoon } from '@fortawesome/pro-regular-svg-icons/faUtensilSpoon';
import { faUtensilsSlash as farUtensilsSlash } from '@fortawesome/pro-regular-svg-icons/faUtensilsSlash';
import { faUtilityPole as farUtilityPole } from '@fortawesome/pro-regular-svg-icons/faUtilityPole';
import { faUtilityPoleDouble as farUtilityPoleDouble } from '@fortawesome/pro-regular-svg-icons/faUtilityPoleDouble';
import { faV as farV } from '@fortawesome/pro-regular-svg-icons/faV';
import { faVaadin as farVaadin } from '@fortawesome/free-brands-svg-icons/faVaadin';
import { faVacuum as farVacuum } from '@fortawesome/pro-regular-svg-icons/faVacuum';
import { faVacuumRobot as farVacuumRobot } from '@fortawesome/pro-regular-svg-icons/faVacuumRobot';
import { faValueAbsolute as farValueAbsolute } from '@fortawesome/pro-regular-svg-icons/faValueAbsolute';
import { faVanShuttle as farVanShuttle } from '@fortawesome/pro-regular-svg-icons/faVanShuttle';
import { faVault as farVault } from '@fortawesome/pro-regular-svg-icons/faVault';
import { faVcard as farVcard } from '@fortawesome/pro-regular-svg-icons/faVcard';
import { faVectorCircle as farVectorCircle } from '@fortawesome/pro-regular-svg-icons/faVectorCircle';
import { faVectorPolygon as farVectorPolygon } from '@fortawesome/pro-regular-svg-icons/faVectorPolygon';
import { faVectorSquare as farVectorSquare } from '@fortawesome/pro-regular-svg-icons/faVectorSquare';
import { faVentDamper as farVentDamper } from '@fortawesome/pro-regular-svg-icons/faVentDamper';
import { faVenus as farVenus } from '@fortawesome/pro-regular-svg-icons/faVenus';
import { faVenusDouble as farVenusDouble } from '@fortawesome/pro-regular-svg-icons/faVenusDouble';
import { faVenusMars as farVenusMars } from '@fortawesome/pro-regular-svg-icons/faVenusMars';
import { faVest as farVest } from '@fortawesome/pro-regular-svg-icons/faVest';
import { faVestPatches as farVestPatches } from '@fortawesome/pro-regular-svg-icons/faVestPatches';
import { faVhs as farVhs } from '@fortawesome/pro-regular-svg-icons/faVhs';
import { faViacoin as farViacoin } from '@fortawesome/free-brands-svg-icons/faViacoin';
import { faViadeo as farViadeo } from '@fortawesome/free-brands-svg-icons/faViadeo';
import { faViadeoSquare as farViadeoSquare } from '@fortawesome/free-brands-svg-icons/faViadeoSquare';
import { faVial as farVial } from '@fortawesome/pro-regular-svg-icons/faVial';
import { faVialCircleCheck as farVialCircleCheck } from '@fortawesome/pro-regular-svg-icons/faVialCircleCheck';
import { faVials as farVials } from '@fortawesome/pro-regular-svg-icons/faVials';
import { faVialVirus as farVialVirus } from '@fortawesome/pro-regular-svg-icons/faVialVirus';
import { faViber as farViber } from '@fortawesome/free-brands-svg-icons/faViber';
import { faVideo as farVideo } from '@fortawesome/pro-regular-svg-icons/faVideo';
import { faVideoArrowDownLeft as farVideoArrowDownLeft } from '@fortawesome/pro-regular-svg-icons/faVideoArrowDownLeft';
import { faVideoArrowUpRight as farVideoArrowUpRight } from '@fortawesome/pro-regular-svg-icons/faVideoArrowUpRight';
import { faVideoCamera as farVideoCamera } from '@fortawesome/pro-regular-svg-icons/faVideoCamera';
import { faVideoCircle as farVideoCircle } from '@fortawesome/pro-regular-svg-icons/faVideoCircle';
import { faVideoHandheld as farVideoHandheld } from '@fortawesome/pro-regular-svg-icons/faVideoHandheld';
import { faVideoPlus as farVideoPlus } from '@fortawesome/pro-regular-svg-icons/faVideoPlus';
import { faVideoSlash as farVideoSlash } from '@fortawesome/pro-regular-svg-icons/faVideoSlash';
import { faVihara as farVihara } from '@fortawesome/pro-regular-svg-icons/faVihara';
import { faVimeo as farVimeo } from '@fortawesome/free-brands-svg-icons/faVimeo';
import { faVimeoSquare as farVimeoSquare } from '@fortawesome/free-brands-svg-icons/faVimeoSquare';
import { faVimeoV as farVimeoV } from '@fortawesome/free-brands-svg-icons/faVimeoV';
import { faVine as farVine } from '@fortawesome/free-brands-svg-icons/faVine';
import { faViolin as farViolin } from '@fortawesome/pro-regular-svg-icons/faViolin';
import { faVirus as farVirus } from '@fortawesome/pro-regular-svg-icons/faVirus';
import { faVirusCovid as farVirusCovid } from '@fortawesome/pro-regular-svg-icons/faVirusCovid';
import { faVirusCovidSlash as farVirusCovidSlash } from '@fortawesome/pro-regular-svg-icons/faVirusCovidSlash';
import { faViruses as farViruses } from '@fortawesome/pro-regular-svg-icons/faViruses';
import { faVirusSlash as farVirusSlash } from '@fortawesome/pro-regular-svg-icons/faVirusSlash';
import { faVk as farVk } from '@fortawesome/free-brands-svg-icons/faVk';
import { faVnv as farVnv } from '@fortawesome/free-brands-svg-icons/faVnv';
import { faVoicemail as farVoicemail } from '@fortawesome/pro-regular-svg-icons/faVoicemail';
import { faVolcano as farVolcano } from '@fortawesome/pro-regular-svg-icons/faVolcano';
import { faVolleyball as farVolleyball } from '@fortawesome/pro-regular-svg-icons/faVolleyball';
import { faVolleyballBall as farVolleyballBall } from '@fortawesome/pro-regular-svg-icons/faVolleyballBall';
import { faVolume as farVolume } from '@fortawesome/pro-regular-svg-icons/faVolume';
import { faVolumeControlPhone as farVolumeControlPhone } from '@fortawesome/pro-regular-svg-icons/faVolumeControlPhone';
import { faVolumeDown as farVolumeDown } from '@fortawesome/pro-regular-svg-icons/faVolumeDown';
import { faVolumeHigh as farVolumeHigh } from '@fortawesome/pro-regular-svg-icons/faVolumeHigh';
import { faVolumeLow as farVolumeLow } from '@fortawesome/pro-regular-svg-icons/faVolumeLow';
import { faVolumeMedium as farVolumeMedium } from '@fortawesome/pro-regular-svg-icons/faVolumeMedium';
import { faVolumeMute as farVolumeMute } from '@fortawesome/pro-regular-svg-icons/faVolumeMute';
import { faVolumeOff as farVolumeOff } from '@fortawesome/pro-regular-svg-icons/faVolumeOff';
import { faVolumeSlash as farVolumeSlash } from '@fortawesome/pro-regular-svg-icons/faVolumeSlash';
import { faVolumeTimes as farVolumeTimes } from '@fortawesome/pro-regular-svg-icons/faVolumeTimes';
import { faVolumeUp as farVolumeUp } from '@fortawesome/pro-regular-svg-icons/faVolumeUp';
import { faVolumeXmark as farVolumeXmark } from '@fortawesome/pro-regular-svg-icons/faVolumeXmark';
import { faVoteNay as farVoteNay } from '@fortawesome/pro-regular-svg-icons/faVoteNay';
import { faVoteYea as farVoteYea } from '@fortawesome/pro-regular-svg-icons/faVoteYea';
import { faVrCardboard as farVrCardboard } from '@fortawesome/pro-regular-svg-icons/faVrCardboard';
import { faVuejs as farVuejs } from '@fortawesome/free-brands-svg-icons/faVuejs';
import { faW as farW } from '@fortawesome/pro-regular-svg-icons/faW';
import { faWaffle as farWaffle } from '@fortawesome/pro-regular-svg-icons/faWaffle';
import { faWagonCovered as farWagonCovered } from '@fortawesome/pro-regular-svg-icons/faWagonCovered';
import { faWalker as farWalker } from '@fortawesome/pro-regular-svg-icons/faWalker';
import { faWalkieTalkie as farWalkieTalkie } from '@fortawesome/pro-regular-svg-icons/faWalkieTalkie';
import { faWalking as farWalking } from '@fortawesome/pro-regular-svg-icons/faWalking';
import { faWallBrick as farWallBrick } from '@fortawesome/pro-regular-svg-icons/faWallBrick';
import { faWallet as farWallet } from '@fortawesome/pro-regular-svg-icons/faWallet';
import { faWand as farWand } from '@fortawesome/pro-regular-svg-icons/faWand';
import { faWandMagic as farWandMagic } from '@fortawesome/pro-regular-svg-icons/faWandMagic';
import { faWandMagicSparkles as farWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons/faWandMagicSparkles';
import { faWandSparkles as farWandSparkles } from '@fortawesome/pro-regular-svg-icons/faWandSparkles';
import { faWarehouse as farWarehouse } from '@fortawesome/pro-regular-svg-icons/faWarehouse';
import { faWarehouseAlt as farWarehouseAlt } from '@fortawesome/pro-regular-svg-icons/faWarehouseAlt';
import { faWarehouseFull as farWarehouseFull } from '@fortawesome/pro-regular-svg-icons/faWarehouseFull';
import { faWarning as farWarning } from '@fortawesome/pro-regular-svg-icons/faWarning';
import { faWasher as farWasher } from '@fortawesome/pro-regular-svg-icons/faWasher';
import { faWashingMachine as farWashingMachine } from '@fortawesome/pro-regular-svg-icons/faWashingMachine';
import { faWatch as farWatch } from '@fortawesome/pro-regular-svg-icons/faWatch';
import { faWatchApple as farWatchApple } from '@fortawesome/pro-regular-svg-icons/faWatchApple';
import { faWatchCalculator as farWatchCalculator } from '@fortawesome/pro-regular-svg-icons/faWatchCalculator';
import { faWatchFitness as farWatchFitness } from '@fortawesome/pro-regular-svg-icons/faWatchFitness';
import { faWatchmanMonitoring as farWatchmanMonitoring } from '@fortawesome/free-brands-svg-icons/faWatchmanMonitoring';
import { faWatchSmart as farWatchSmart } from '@fortawesome/pro-regular-svg-icons/faWatchSmart';
import { faWater as farWater } from '@fortawesome/pro-regular-svg-icons/faWater';
import { faWaterArrowDown as farWaterArrowDown } from '@fortawesome/pro-regular-svg-icons/faWaterArrowDown';
import { faWaterArrowUp as farWaterArrowUp } from '@fortawesome/pro-regular-svg-icons/faWaterArrowUp';
import { faWaterLadder as farWaterLadder } from '@fortawesome/pro-regular-svg-icons/faWaterLadder';
import { faWaterLower as farWaterLower } from '@fortawesome/pro-regular-svg-icons/faWaterLower';
import { faWatermelonSlice as farWatermelonSlice } from '@fortawesome/pro-regular-svg-icons/faWatermelonSlice';
import { faWaterRise as farWaterRise } from '@fortawesome/pro-regular-svg-icons/faWaterRise';
import { faWave as farWave } from '@fortawesome/pro-regular-svg-icons/faWave';
import { faWaveform as farWaveform } from '@fortawesome/pro-regular-svg-icons/faWaveform';
import { faWaveformCircle as farWaveformCircle } from '@fortawesome/pro-regular-svg-icons/faWaveformCircle';
import { faWaveformLines as farWaveformLines } from '@fortawesome/pro-regular-svg-icons/faWaveformLines';
import { faWaveformPath as farWaveformPath } from '@fortawesome/pro-regular-svg-icons/faWaveformPath';
import { faWavePulse as farWavePulse } from '@fortawesome/pro-regular-svg-icons/faWavePulse';
import { faWaveSine as farWaveSine } from '@fortawesome/pro-regular-svg-icons/faWaveSine';
import { faWaveSquare as farWaveSquare } from '@fortawesome/pro-regular-svg-icons/faWaveSquare';
import { faWavesSine as farWavesSine } from '@fortawesome/pro-regular-svg-icons/faWavesSine';
import { faWaveTriangle as farWaveTriangle } from '@fortawesome/pro-regular-svg-icons/faWaveTriangle';
import { faWaze as farWaze } from '@fortawesome/free-brands-svg-icons/faWaze';
import { faWebcam as farWebcam } from '@fortawesome/pro-regular-svg-icons/faWebcam';
import { faWebcamSlash as farWebcamSlash } from '@fortawesome/pro-regular-svg-icons/faWebcamSlash';
import { faWebflow as farWebflow } from '@fortawesome/free-brands-svg-icons/faWebflow';
import { faWebhook as farWebhook } from '@fortawesome/pro-regular-svg-icons/faWebhook';
import { faWeebly as farWeebly } from '@fortawesome/free-brands-svg-icons/faWeebly';
import { faWeibo as farWeibo } from '@fortawesome/free-brands-svg-icons/faWeibo';
import { faWeight as farWeight } from '@fortawesome/pro-regular-svg-icons/faWeight';
import { faWeightHanging as farWeightHanging } from '@fortawesome/pro-regular-svg-icons/faWeightHanging';
import { faWeightScale as farWeightScale } from '@fortawesome/pro-regular-svg-icons/faWeightScale';
import { faWeixin as farWeixin } from '@fortawesome/free-brands-svg-icons/faWeixin';
import { faWhale as farWhale } from '@fortawesome/pro-regular-svg-icons/faWhale';
import { faWhatsapp as farWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faWhatsappSquare as farWhatsappSquare } from '@fortawesome/free-brands-svg-icons/faWhatsappSquare';
import { faWheat as farWheat } from '@fortawesome/pro-regular-svg-icons/faWheat';
import { faWheatAlt as farWheatAlt } from '@fortawesome/pro-regular-svg-icons/faWheatAlt';
import { faWheatAwn as farWheatAwn } from '@fortawesome/pro-regular-svg-icons/faWheatAwn';
import { faWheatAwnCircleExclamation as farWheatAwnCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faWheatAwnCircleExclamation';
import { faWheatAwnSlash as farWheatAwnSlash } from '@fortawesome/pro-regular-svg-icons/faWheatAwnSlash';
import { faWheatSlash as farWheatSlash } from '@fortawesome/pro-regular-svg-icons/faWheatSlash';
import { faWheelchair as farWheelchair } from '@fortawesome/pro-regular-svg-icons/faWheelchair';
import { faWheelchairAlt as farWheelchairAlt } from '@fortawesome/pro-regular-svg-icons/faWheelchairAlt';
import { faWheelchairMove as farWheelchairMove } from '@fortawesome/pro-regular-svg-icons/faWheelchairMove';
import { faWhiskeyGlass as farWhiskeyGlass } from '@fortawesome/pro-regular-svg-icons/faWhiskeyGlass';
import { faWhiskeyGlassIce as farWhiskeyGlassIce } from '@fortawesome/pro-regular-svg-icons/faWhiskeyGlassIce';
import { faWhistle as farWhistle } from '@fortawesome/pro-regular-svg-icons/faWhistle';
import { faWhmcs as farWhmcs } from '@fortawesome/free-brands-svg-icons/faWhmcs';
import { faWifi as farWifi } from '@fortawesome/pro-regular-svg-icons/faWifi';
import { faWifi1 as farWifi1 } from '@fortawesome/pro-regular-svg-icons/faWifi1';
import { faWifi2 as farWifi2 } from '@fortawesome/pro-regular-svg-icons/faWifi2';
import { faWifi3 as farWifi3 } from '@fortawesome/pro-regular-svg-icons/faWifi3';
import { faWifiExclamation as farWifiExclamation } from '@fortawesome/pro-regular-svg-icons/faWifiExclamation';
import { faWifiFair as farWifiFair } from '@fortawesome/pro-regular-svg-icons/faWifiFair';
import { faWifiSlash as farWifiSlash } from '@fortawesome/pro-regular-svg-icons/faWifiSlash';
import { faWifiStrong as farWifiStrong } from '@fortawesome/pro-regular-svg-icons/faWifiStrong';
import { faWifiWeak as farWifiWeak } from '@fortawesome/pro-regular-svg-icons/faWifiWeak';
import { faWikipediaW as farWikipediaW } from '@fortawesome/free-brands-svg-icons/faWikipediaW';
import { faWind as farWind } from '@fortawesome/pro-regular-svg-icons/faWind';
import { faWindCircleExclamation as farWindCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faWindCircleExclamation';
import { faWindow as farWindow } from '@fortawesome/pro-regular-svg-icons/faWindow';
import { faWindowAlt as farWindowAlt } from '@fortawesome/pro-regular-svg-icons/faWindowAlt';
import { faWindowClose as farWindowClose } from '@fortawesome/pro-regular-svg-icons/faWindowClose';
import { faWindowFlip as farWindowFlip } from '@fortawesome/pro-regular-svg-icons/faWindowFlip';
import { faWindowFrame as farWindowFrame } from '@fortawesome/pro-regular-svg-icons/faWindowFrame';
import { faWindowFrameOpen as farWindowFrameOpen } from '@fortawesome/pro-regular-svg-icons/faWindowFrameOpen';
import { faWindowMaximize as farWindowMaximize } from '@fortawesome/pro-regular-svg-icons/faWindowMaximize';
import { faWindowMinimize as farWindowMinimize } from '@fortawesome/pro-regular-svg-icons/faWindowMinimize';
import { faWindowRestore as farWindowRestore } from '@fortawesome/pro-regular-svg-icons/faWindowRestore';
import { faWindows as farWindows } from '@fortawesome/free-brands-svg-icons/faWindows';
import { faWindsock as farWindsock } from '@fortawesome/pro-regular-svg-icons/faWindsock';
import { faWindTurbine as farWindTurbine } from '@fortawesome/pro-regular-svg-icons/faWindTurbine';
import { faWindWarning as farWindWarning } from '@fortawesome/pro-regular-svg-icons/faWindWarning';
import { faWineBottle as farWineBottle } from '@fortawesome/pro-regular-svg-icons/faWineBottle';
import { faWineGlass as farWineGlass } from '@fortawesome/pro-regular-svg-icons/faWineGlass';
import { faWineGlassAlt as farWineGlassAlt } from '@fortawesome/pro-regular-svg-icons/faWineGlassAlt';
import { faWineGlassCrack as farWineGlassCrack } from '@fortawesome/pro-regular-svg-icons/faWineGlassCrack';
import { faWineGlassEmpty as farWineGlassEmpty } from '@fortawesome/pro-regular-svg-icons/faWineGlassEmpty';
import { faWirsindhandwerk as farWirsindhandwerk } from '@fortawesome/free-brands-svg-icons/faWirsindhandwerk';
import { faWix as farWix } from '@fortawesome/free-brands-svg-icons/faWix';
import { faWizardsOfTheCoast as farWizardsOfTheCoast } from '@fortawesome/free-brands-svg-icons/faWizardsOfTheCoast';
import { faWodu as farWodu } from '@fortawesome/free-brands-svg-icons/faWodu';
import { faWolfPackBattalion as farWolfPackBattalion } from '@fortawesome/free-brands-svg-icons/faWolfPackBattalion';
import { faWon as farWon } from '@fortawesome/pro-regular-svg-icons/faWon';
import { faWonSign as farWonSign } from '@fortawesome/pro-regular-svg-icons/faWonSign';
import { faWordpress as farWordpress } from '@fortawesome/free-brands-svg-icons/faWordpress';
import { faWordpressSimple as farWordpressSimple } from '@fortawesome/free-brands-svg-icons/faWordpressSimple';
import { faWorm as farWorm } from '@fortawesome/pro-regular-svg-icons/faWorm';
import { faWpbeginner as farWpbeginner } from '@fortawesome/free-brands-svg-icons/faWpbeginner';
import { faWpexplorer as farWpexplorer } from '@fortawesome/free-brands-svg-icons/faWpexplorer';
import { faWpforms as farWpforms } from '@fortawesome/free-brands-svg-icons/faWpforms';
import { faWpressr as farWpressr } from '@fortawesome/free-brands-svg-icons/faWpressr';
import { faWreath as farWreath } from '@fortawesome/pro-regular-svg-icons/faWreath';
import { faWreathLaurel as farWreathLaurel } from '@fortawesome/pro-regular-svg-icons/faWreathLaurel';
import { faWrench as farWrench } from '@fortawesome/pro-regular-svg-icons/faWrench';
import { faWrenchSimple as farWrenchSimple } from '@fortawesome/pro-regular-svg-icons/faWrenchSimple';
import { faWsh as farWsh } from '@fortawesome/free-brands-svg-icons/faWsh';
import { faX as farX } from '@fortawesome/pro-regular-svg-icons/faX';
import { faXbox as farXbox } from '@fortawesome/free-brands-svg-icons/faXbox';
import { faXing as farXing } from '@fortawesome/free-brands-svg-icons/faXing';
import { faXingSquare as farXingSquare } from '@fortawesome/free-brands-svg-icons/faXingSquare';
import { faXmark as farXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faXmarkCircle as farXmarkCircle } from '@fortawesome/pro-regular-svg-icons/faXmarkCircle';
import { faXmarkHexagon as farXmarkHexagon } from '@fortawesome/pro-regular-svg-icons/faXmarkHexagon';
import { faXmarkLarge as farXmarkLarge } from '@fortawesome/pro-regular-svg-icons/faXmarkLarge';
import { faXmarkOctagon as farXmarkOctagon } from '@fortawesome/pro-regular-svg-icons/faXmarkOctagon';
import { faXmarksLines as farXmarksLines } from '@fortawesome/pro-regular-svg-icons/faXmarksLines';
import { faXmarkSquare as farXmarkSquare } from '@fortawesome/pro-regular-svg-icons/faXmarkSquare';
import { faXmarkToSlot as farXmarkToSlot } from '@fortawesome/pro-regular-svg-icons/faXmarkToSlot';
import { faXRay as farXRay } from '@fortawesome/pro-regular-svg-icons/faXRay';
import { faXTwitter as farXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faY as farY } from '@fortawesome/pro-regular-svg-icons/faY';
import { faYahoo as farYahoo } from '@fortawesome/free-brands-svg-icons/faYahoo';
import { faYammer as farYammer } from '@fortawesome/free-brands-svg-icons/faYammer';
import { faYandex as farYandex } from '@fortawesome/free-brands-svg-icons/faYandex';
import { faYandexInternational as farYandexInternational } from '@fortawesome/free-brands-svg-icons/faYandexInternational';
import { faYarn as farYarn } from '@fortawesome/free-brands-svg-icons/faYarn';
import { faYCombinator as farYCombinator } from '@fortawesome/free-brands-svg-icons/faYCombinator';
import { faYelp as farYelp } from '@fortawesome/free-brands-svg-icons/faYelp';
import { faYen as farYen } from '@fortawesome/pro-regular-svg-icons/faYen';
import { faYenSign as farYenSign } from '@fortawesome/pro-regular-svg-icons/faYenSign';
import { faYinYang as farYinYang } from '@fortawesome/pro-regular-svg-icons/faYinYang';
import { faYoast as farYoast } from '@fortawesome/free-brands-svg-icons/faYoast';
import { faYoutube as farYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faYoutubeSquare as farYoutubeSquare } from '@fortawesome/free-brands-svg-icons/faYoutubeSquare';
import { faZ as farZ } from '@fortawesome/pro-regular-svg-icons/faZ';
import { faZap as farZap } from '@fortawesome/pro-regular-svg-icons/faZap';
import { faZhihu as farZhihu } from '@fortawesome/free-brands-svg-icons/faZhihu';
import { faZzz as farZzz } from '@fortawesome/pro-regular-svg-icons/faZzz';

@Injectable({
    providedIn: 'root',
})
export class IconService implements FaIconLibraryInterface {

    addIcons(...icons: IconDefinition[]): void {
        
    }

    addIconPacks(...packs: IconPack[]): void {
        
    }

    getIconDefinition(prefix: IconPrefix, name: IconName): IconDefinition {
        return undefined;
    }

    far0 = far0;
    far00 = far00;
    far1 = far1;
    far100 = far100;
    far2 = far2;
    far3 = far3;
    far360Degrees = far360Degrees;
    far4 = far4;
    far42Group = far42Group;
    far5 = far5;
    far500px = far500px;
    far6 = far6;
    far7 = far7;
    far8 = far8;
    far9 = far9;
    farA = farA;
    farAbacus = farAbacus;
    farAccentGrave = farAccentGrave;
    farAccessibleIcon = farAccessibleIcon;
    farAccusoft = farAccusoft;
    farAcorn = farAcorn;
    farAd = farAd;
    farAdd = farAdd;
    farAddressBook = farAddressBook;
    farAddressCard = farAddressCard;
    farAdjust = farAdjust;
    farAdn = farAdn;
    farAdversal = farAdversal;
    farAffiliatetheme = farAffiliatetheme;
    farAirbnb = farAirbnb;
    farAirConditioner = farAirConditioner;
    farAirFreshener = farAirFreshener;
    farAirplay = farAirplay;
    farAlarmClock = farAlarmClock;
    farAlarmExclamation = farAlarmExclamation;
    farAlarmPlus = farAlarmPlus;
    farAlarmSnooze = farAlarmSnooze;
    farAlbum = farAlbum;
    farAlbumCirclePlus = farAlbumCirclePlus;
    farAlbumCircleUser = farAlbumCircleUser;
    farAlbumCollection = farAlbumCollection;
    farAlbumCollectionCirclePlus = farAlbumCollectionCirclePlus;
    farAlbumCollectionCircleUser = farAlbumCollectionCircleUser;
    farAlgolia = farAlgolia;
    farAlicorn = farAlicorn;
    farAlien = farAlien;
    farAlienMonster = farAlienMonster;
    farAlignCenter = farAlignCenter;
    farAlignJustify = farAlignJustify;
    farAlignLeft = farAlignLeft;
    farAlignRight = farAlignRight;
    farAlignSlash = farAlignSlash;
    farAlipay = farAlipay;
    farAllergies = farAllergies;
    farAlt = farAlt;
    farAmazon = farAmazon;
    farAmazonPay = farAmazonPay;
    farAmbulance = farAmbulance;
    farAmericanSignLanguageInterpreting = farAmericanSignLanguageInterpreting;
    farAmilia = farAmilia;
    farAmpersand = farAmpersand;
    farAmpGuitar = farAmpGuitar;
    farAnalytics = farAnalytics;
    farAnchor = farAnchor;
    farAnchorCircleCheck = farAnchorCircleCheck;
    farAnchorCircleExclamation = farAnchorCircleExclamation;
    farAnchorCircleXmark = farAnchorCircleXmark;
    farAnchorLock = farAnchorLock;
    farAndroid = farAndroid;
    farAngel = farAngel;
    farAngellist = farAngellist;
    farAngle = farAngle;
    farAngle90 = farAngle90;
    farAngleDoubleDown = farAngleDoubleDown;
    farAngleDoubleLeft = farAngleDoubleLeft;
    farAngleDoubleRight = farAngleDoubleRight;
    farAngleDoubleUp = farAngleDoubleUp;
    farAngleDown = farAngleDown;
    farAngleLeft = farAngleLeft;
    farAngleRight = farAngleRight;
    farAnglesDown = farAnglesDown;
    farAnglesLeft = farAnglesLeft;
    farAnglesRight = farAnglesRight;
    farAnglesUp = farAnglesUp;
    farAnglesUpDown = farAnglesUpDown;
    farAngleUp = farAngleUp;
    farAngry = farAngry;
    farAngrycreative = farAngrycreative;
    farAngular = farAngular;
    farAnkh = farAnkh;
    farApartment = farApartment;
    farAperture = farAperture;
    farApostrophe = farApostrophe;
    farApper = farApper;
    farApple = farApple;
    farAppleAlt = farAppleAlt;
    farAppleCore = farAppleCore;
    farAppleCrate = farAppleCrate;
    farApplePay = farApplePay;
    farAppleWhole = farAppleWhole;
    farAppStore = farAppStore;
    farAppStoreIos = farAppStoreIos;
    farArchive = farArchive;
    farArchway = farArchway;
    farAreaChart = farAreaChart;
    farArrowAltCircleDown = farArrowAltCircleDown;
    farArrowAltCircleLeft = farArrowAltCircleLeft;
    farArrowAltCircleRight = farArrowAltCircleRight;
    farArrowAltCircleUp = farArrowAltCircleUp;
    farArrowAltDown = farArrowAltDown;
    farArrowAltFromBottom = farArrowAltFromBottom;
    farArrowAltFromLeft = farArrowAltFromLeft;
    farArrowAltFromRight = farArrowAltFromRight;
    farArrowAltFromTop = farArrowAltFromTop;
    farArrowAltLeft = farArrowAltLeft;
    farArrowAltRight = farArrowAltRight;
    farArrowAltSquareDown = farArrowAltSquareDown;
    farArrowAltSquareLeft = farArrowAltSquareLeft;
    farArrowAltSquareRight = farArrowAltSquareRight;
    farArrowAltSquareUp = farArrowAltSquareUp;
    farArrowAltToBottom = farArrowAltToBottom;
    farArrowAltToLeft = farArrowAltToLeft;
    farArrowAltToRight = farArrowAltToRight;
    farArrowAltToTop = farArrowAltToTop;
    farArrowAltUp = farArrowAltUp;
    farArrowCircleDown = farArrowCircleDown;
    farArrowCircleLeft = farArrowCircleLeft;
    farArrowCircleRight = farArrowCircleRight;
    farArrowCircleUp = farArrowCircleUp;
    farArrowDown = farArrowDown;
    farArrowDown19 = farArrowDown19;
    farArrowDown91 = farArrowDown91;
    farArrowDownArrowUp = farArrowDownArrowUp;
    farArrowDownAZ = farArrowDownAZ;
    farArrowDownBigSmall = farArrowDownBigSmall;
    farArrowDownFromArc = farArrowDownFromArc;
    farArrowDownFromDottedLine = farArrowDownFromDottedLine;
    farArrowDownFromLine = farArrowDownFromLine;
    farArrowDownLeft = farArrowDownLeft;
    farArrowDownLeftAndArrowUpRightToCenter = farArrowDownLeftAndArrowUpRightToCenter;
    farArrowDownLong = farArrowDownLong;
    farArrowDownRight = farArrowDownRight;
    farArrowDownShortWide = farArrowDownShortWide;
    farArrowDownSmallBig = farArrowDownSmallBig;
    farArrowDownSquareTriangle = farArrowDownSquareTriangle;
    farArrowDownToArc = farArrowDownToArc;
    farArrowDownToBracket = farArrowDownToBracket;
    farArrowDownToDottedLine = farArrowDownToDottedLine;
    farArrowDownToLine = farArrowDownToLine;
    farArrowDownToSquare = farArrowDownToSquare;
    farArrowDownTriangleSquare = farArrowDownTriangleSquare;
    farArrowDownUpAcrossLine = farArrowDownUpAcrossLine;
    farArrowDownUpLock = farArrowDownUpLock;
    farArrowDownWideShort = farArrowDownWideShort;
    farArrowDownZA = farArrowDownZA;
    farArrowFromBottom = farArrowFromBottom;
    farArrowFromLeft = farArrowFromLeft;
    farArrowFromRight = farArrowFromRight;
    farArrowFromTop = farArrowFromTop;
    farArrowLeft = farArrowLeft;
    farArrowLeftFromArc = farArrowLeftFromArc;
    farArrowLeftFromLine = farArrowLeftFromLine;
    farArrowLeftLong = farArrowLeftLong;
    farArrowLeftLongToLine = farArrowLeftLongToLine;
    farArrowLeftRotate = farArrowLeftRotate;
    farArrowLeftToArc = farArrowLeftToArc;
    farArrowLeftToLine = farArrowLeftToLine;
    farArrowPointer = farArrowPointer;
    farArrowProgress = farArrowProgress;
    farArrowRight = farArrowRight;
    farArrowRightArrowLeft = farArrowRightArrowLeft;
    farArrowRightFromArc = farArrowRightFromArc;
    farArrowRightFromBracket = farArrowRightFromBracket;
    farArrowRightFromFile = farArrowRightFromFile;
    farArrowRightFromLine = farArrowRightFromLine;
    farArrowRightLong = farArrowRightLong;
    farArrowRightLongToLine = farArrowRightLongToLine;
    farArrowRightRotate = farArrowRightRotate;
    farArrowRightToArc = farArrowRightToArc;
    farArrowRightToBracket = farArrowRightToBracket;
    farArrowRightToCity = farArrowRightToCity;
    farArrowRightToFile = farArrowRightToFile;
    farArrowRightToLine = farArrowRightToLine;
    farArrowRotateBack = farArrowRotateBack;
    farArrowRotateBackward = farArrowRotateBackward;
    farArrowRotateForward = farArrowRotateForward;
    farArrowRotateLeft = farArrowRotateLeft;
    farArrowRotateRight = farArrowRotateRight;
    farArrows = farArrows;
    farArrowsAlt = farArrowsAlt;
    farArrowsAltH = farArrowsAltH;
    farArrowsAltV = farArrowsAltV;
    farArrowsCross = farArrowsCross;
    farArrowsDownToLine = farArrowsDownToLine;
    farArrowsDownToPeople = farArrowsDownToPeople;
    farArrowsFromDottedLine = farArrowsFromDottedLine;
    farArrowsFromLine = farArrowsFromLine;
    farArrowsH = farArrowsH;
    farArrowsLeftRight = farArrowsLeftRight;
    farArrowsLeftRightToLine = farArrowsLeftRightToLine;
    farArrowsMaximize = farArrowsMaximize;
    farArrowsMinimize = farArrowsMinimize;
    farArrowSquareDown = farArrowSquareDown;
    farArrowSquareLeft = farArrowSquareLeft;
    farArrowSquareRight = farArrowSquareRight;
    farArrowSquareUp = farArrowSquareUp;
    farArrowsRepeat = farArrowsRepeat;
    farArrowsRepeat1 = farArrowsRepeat1;
    farArrowsRetweet = farArrowsRetweet;
    farArrowsRotate = farArrowsRotate;
    farArrowsRotateReverse = farArrowsRotateReverse;
    farArrowsSpin = farArrowsSpin;
    farArrowsSplitUpAndLeft = farArrowsSplitUpAndLeft;
    farArrowsToCircle = farArrowsToCircle;
    farArrowsToDot = farArrowsToDot;
    farArrowsToDottedLine = farArrowsToDottedLine;
    farArrowsToEye = farArrowsToEye;
    farArrowsToLine = farArrowsToLine;
    farArrowsTurnRight = farArrowsTurnRight;
    farArrowsTurnToDots = farArrowsTurnToDots;
    farArrowsUpDown = farArrowsUpDown;
    farArrowsUpDownLeftRight = farArrowsUpDownLeftRight;
    farArrowsUpToLine = farArrowsUpToLine;
    farArrowsV = farArrowsV;
    farArrowToBottom = farArrowToBottom;
    farArrowToLeft = farArrowToLeft;
    farArrowToRight = farArrowToRight;
    farArrowToTop = farArrowToTop;
    farArrowTrendDown = farArrowTrendDown;
    farArrowTrendUp = farArrowTrendUp;
    farArrowTurnDown = farArrowTurnDown;
    farArrowTurnDownLeft = farArrowTurnDownLeft;
    farArrowTurnDownRight = farArrowTurnDownRight;
    farArrowTurnLeft = farArrowTurnLeft;
    farArrowTurnLeftDown = farArrowTurnLeftDown;
    farArrowTurnLeftUp = farArrowTurnLeftUp;
    farArrowTurnRight = farArrowTurnRight;
    farArrowTurnUp = farArrowTurnUp;
    farArrowUp = farArrowUp;
    farArrowUp19 = farArrowUp19;
    farArrowUp91 = farArrowUp91;
    farArrowUpArrowDown = farArrowUpArrowDown;
    farArrowUpAZ = farArrowUpAZ;
    farArrowUpBigSmall = farArrowUpBigSmall;
    farArrowUpFromArc = farArrowUpFromArc;
    farArrowUpFromBracket = farArrowUpFromBracket;
    farArrowUpFromDottedLine = farArrowUpFromDottedLine;
    farArrowUpFromGroundWater = farArrowUpFromGroundWater;
    farArrowUpFromLine = farArrowUpFromLine;
    farArrowUpFromSquare = farArrowUpFromSquare;
    farArrowUpFromWaterPump = farArrowUpFromWaterPump;
    farArrowUpLeft = farArrowUpLeft;
    farArrowUpLeftFromCircle = farArrowUpLeftFromCircle;
    farArrowUpLong = farArrowUpLong;
    farArrowUpRight = farArrowUpRight;
    farArrowUpRightAndArrowDownLeftFromCenter = farArrowUpRightAndArrowDownLeftFromCenter;
    farArrowUpRightDots = farArrowUpRightDots;
    farArrowUpRightFromSquare = farArrowUpRightFromSquare;
    farArrowUpShortWide = farArrowUpShortWide;
    farArrowUpSmallBig = farArrowUpSmallBig;
    farArrowUpSquareTriangle = farArrowUpSquareTriangle;
    farArrowUpToArc = farArrowUpToArc;
    farArrowUpToDottedLine = farArrowUpToDottedLine;
    farArrowUpToLine = farArrowUpToLine;
    farArrowUpTriangleSquare = farArrowUpTriangleSquare;
    farArrowUpWideShort = farArrowUpWideShort;
    farArrowUpZA = farArrowUpZA;
    farArtstation = farArtstation;
    farAslInterpreting = farAslInterpreting;
    farAssistiveListeningSystems = farAssistiveListeningSystems;
    farAsterisk = farAsterisk;
    farAsymmetrik = farAsymmetrik;
    farAt = farAt;
    farAtlas = farAtlas;
    farAtlassian = farAtlassian;
    farAtom = farAtom;
    farAtomAlt = farAtomAlt;
    farAtomSimple = farAtomSimple;
    farAudible = farAudible;
    farAudioDescription = farAudioDescription;
    farAudioDescriptionSlash = farAudioDescriptionSlash;
    farAustralSign = farAustralSign;
    farAutomobile = farAutomobile;
    farAutoprefixer = farAutoprefixer;
    farAvianex = farAvianex;
    farAviato = farAviato;
    farAvocado = farAvocado;
    farAward = farAward;
    farAwardSimple = farAwardSimple;
    farAws = farAws;
    farAxe = farAxe;
    farAxeBattle = farAxeBattle;
    farB = farB;
    farBaby = farBaby;
    farBabyCarriage = farBabyCarriage;
    farBackpack = farBackpack;
    farBackspace = farBackspace;
    farBackward = farBackward;
    farBackwardFast = farBackwardFast;
    farBackwardStep = farBackwardStep;
    farBacon = farBacon;
    farBacteria = farBacteria;
    farBacterium = farBacterium;
    farBadge = farBadge;
    farBadgeCheck = farBadgeCheck;
    farBadgeDollar = farBadgeDollar;
    farBadgePercent = farBadgePercent;
    farBadgerHoney = farBadgerHoney;
    farBadgeSheriff = farBadgeSheriff;
    farBadminton = farBadminton;
    farBagel = farBagel;
    farBagSeedling = farBagSeedling;
    farBagShopping = farBagShopping;
    farBagShoppingMinus = farBagShoppingMinus;
    farBagShoppingPlus = farBagShoppingPlus;
    farBagsShopping = farBagsShopping;
    farBaguette = farBaguette;
    farBahai = farBahai;
    farBahtSign = farBahtSign;
    farBalanceScale = farBalanceScale;
    farBalanceScaleLeft = farBalanceScaleLeft;
    farBalanceScaleRight = farBalanceScaleRight;
    farBalloon = farBalloon;
    farBalloons = farBalloons;
    farBallot = farBallot;
    farBallotCheck = farBallotCheck;
    farBallPile = farBallPile;
    farBan = farBan;
    farBanana = farBanana;
    farBanBug = farBanBug;
    farBandage = farBandage;
    farBandAid = farBandAid;
    farBandcamp = farBandcamp;
    farBangladeshiTakaSign = farBangladeshiTakaSign;
    farBanjo = farBanjo;
    farBank = farBank;
    farBanParking = farBanParking;
    farBanSmoking = farBanSmoking;
    farBarChart = farBarChart;
    farBarcode = farBarcode;
    farBarcodeAlt = farBarcodeAlt;
    farBarcodeRead = farBarcodeRead;
    farBarcodeScan = farBarcodeScan;
    farBarnSilo = farBarnSilo;
    farBars = farBars;
    farBarsFilter = farBarsFilter;
    farBarsProgress = farBarsProgress;
    farBarsSort = farBarsSort;
    farBarsStaggered = farBarsStaggered;
    farBaseball = farBaseball;
    farBaseballBall = farBaseballBall;
    farBaseballBatBall = farBaseballBatBall;
    farBasketball = farBasketball;
    farBasketballBall = farBasketballBall;
    farBasketballHoop = farBasketballHoop;
    farBasketShopping = farBasketShopping;
    farBasketShoppingMinus = farBasketShoppingMinus;
    farBasketShoppingPlus = farBasketShoppingPlus;
    farBasketShoppingSimple = farBasketShoppingSimple;
    farBat = farBat;
    farBath = farBath;
    farBathtub = farBathtub;
    farBattery = farBattery;
    farBattery0 = farBattery0;
    farBattery1 = farBattery1;
    farBattery2 = farBattery2;
    farBattery3 = farBattery3;
    farBattery4 = farBattery4;
    farBattery5 = farBattery5;
    farBatteryBolt = farBatteryBolt;
    farBatteryCar = farBatteryCar;
    farBatteryEmpty = farBatteryEmpty;
    farBatteryExclamation = farBatteryExclamation;
    farBatteryFull = farBatteryFull;
    farBatteryHalf = farBatteryHalf;
    farBatteryLow = farBatteryLow;
    farBatteryQuarter = farBatteryQuarter;
    farBatterySlash = farBatterySlash;
    farBatteryThreeQuarters = farBatteryThreeQuarters;
    farBattleNet = farBattleNet;
    farBed = farBed;
    farBedAlt = farBedAlt;
    farBedBunk = farBedBunk;
    farBedEmpty = farBedEmpty;
    farBedFront = farBedFront;
    farBedPulse = farBedPulse;
    farBee = farBee;
    farBeer = farBeer;
    farBeerFoam = farBeerFoam;
    farBeerMug = farBeerMug;
    farBeerMugEmpty = farBeerMugEmpty;
    farBehance = farBehance;
    farBehanceSquare = farBehanceSquare;
    farBell = farBell;
    farBellConcierge = farBellConcierge;
    farBellExclamation = farBellExclamation;
    farBellOn = farBellOn;
    farBellPlus = farBellPlus;
    farBellRing = farBellRing;
    farBells = farBells;
    farBellSchool = farBellSchool;
    farBellSchoolSlash = farBellSchoolSlash;
    farBellSlash = farBellSlash;
    farBenchTree = farBenchTree;
    farBetamax = farBetamax;
    farBezierCurve = farBezierCurve;
    farBible = farBible;
    farBicycle = farBicycle;
    farBiking = farBiking;
    farBikingMountain = farBikingMountain;
    farBilibili = farBilibili;
    farBillboard = farBillboard;
    farBimobject = farBimobject;
    farBinary = farBinary;
    farBinaryCircleCheck = farBinaryCircleCheck;
    farBinaryLock = farBinaryLock;
    farBinarySlash = farBinarySlash;
    farBinBottles = farBinBottles;
    farBinBottlesRecycle = farBinBottlesRecycle;
    farBinoculars = farBinoculars;
    farBinRecycle = farBinRecycle;
    farBiohazard = farBiohazard;
    farBird = farBird;
    farBirthdayCake = farBirthdayCake;
    farBitbucket = farBitbucket;
    farBitcoin = farBitcoin;
    farBitcoinSign = farBitcoinSign;
    farBity = farBity;
    farBlackberry = farBlackberry;
    farBlackboard = farBlackboard;
    farBlackTie = farBlackTie;
    farBlanket = farBlanket;
    farBlanketFire = farBlanketFire;
    farBlender = farBlender;
    farBlenderPhone = farBlenderPhone;
    farBlind = farBlind;
    farBlinds = farBlinds;
    farBlindsOpen = farBlindsOpen;
    farBlindsRaised = farBlindsRaised;
    farBlock = farBlock;
    farBlockBrick = farBlockBrick;
    farBlockBrickFire = farBlockBrickFire;
    farBlockQuestion = farBlockQuestion;
    farBlockQuote = farBlockQuote;
    farBlog = farBlog;
    farBlogger = farBlogger;
    farBloggerB = farBloggerB;
    farBlueberries = farBlueberries;
    farBluetooth = farBluetooth;
    farBluetoothB = farBluetoothB;
    farBold = farBold;
    farBolt = farBolt;
    farBoltAuto = farBoltAuto;
    farBoltLightning = farBoltLightning;
    farBoltSlash = farBoltSlash;
    farBomb = farBomb;
    farBone = farBone;
    farBoneBreak = farBoneBreak;
    farBong = farBong;
    farBook = farBook;
    farBookAlt = farBookAlt;
    farBookArrowRight = farBookArrowRight;
    farBookArrowUp = farBookArrowUp;
    farBookAtlas = farBookAtlas;
    farBookBible = farBookBible;
    farBookBlank = farBookBlank;
    farBookBookmark = farBookBookmark;
    farBookCircle = farBookCircle;
    farBookCircleArrowRight = farBookCircleArrowRight;
    farBookCircleArrowUp = farBookCircleArrowUp;
    farBookCopy = farBookCopy;
    farBookDead = farBookDead;
    farBookFont = farBookFont;
    farBookHeart = farBookHeart;
    farBookJournalWhills = farBookJournalWhills;
    farBookLaw = farBookLaw;
    farBookmark = farBookmark;
    farBookmarkCircle = farBookmarkCircle;
    farBookmarkSlash = farBookmarkSlash;
    farBookMedical = farBookMedical;
    farBookOpen = farBookOpen;
    farBookOpenAlt = farBookOpenAlt;
    farBookOpenCover = farBookOpenCover;
    farBookOpenReader = farBookOpenReader;
    farBookQuran = farBookQuran;
    farBookReader = farBookReader;
    farBooks = farBooks;
    farBookSection = farBookSection;
    farBookSkull = farBookSkull;
    farBooksMedical = farBooksMedical;
    farBookSparkles = farBookSparkles;
    farBookSpells = farBookSpells;
    farBookTanakh = farBookTanakh;
    farBookUser = farBookUser;
    farBoombox = farBoombox;
    farBoot = farBoot;
    farBoothCurtain = farBoothCurtain;
    farBootHeeled = farBootHeeled;
    farBootstrap = farBootstrap;
    farBorderAll = farBorderAll;
    farBorderBottom = farBorderBottom;
    farBorderBottomRight = farBorderBottomRight;
    farBorderCenterH = farBorderCenterH;
    farBorderCenterV = farBorderCenterV;
    farBorderInner = farBorderInner;
    farBorderLeft = farBorderLeft;
    farBorderNone = farBorderNone;
    farBorderOuter = farBorderOuter;
    farBorderRight = farBorderRight;
    farBorderStyle = farBorderStyle;
    farBorderStyleAlt = farBorderStyleAlt;
    farBorderTop = farBorderTop;
    farBorderTopLeft = farBorderTopLeft;
    farBoreHole = farBoreHole;
    farBots = farBots;
    farBottleDroplet = farBottleDroplet;
    farBottleWater = farBottleWater;
    farBowArrow = farBowArrow;
    farBowlChopsticks = farBowlChopsticks;
    farBowlChopsticksNoodles = farBowlChopsticksNoodles;
    farBowlFood = farBowlFood;
    farBowlHot = farBowlHot;
    farBowlingBall = farBowlingBall;
    farBowlingBallPin = farBowlingBallPin;
    farBowlingPins = farBowlingPins;
    farBowlRice = farBowlRice;
    farBowlSalad = farBowlSalad;
    farBowlScoop = farBowlScoop;
    farBowlScoops = farBowlScoops;
    farBowlShavedIce = farBowlShavedIce;
    farBowlSoftServe = farBowlSoftServe;
    farBowlSpoon = farBowlSpoon;
    farBox = farBox;
    farBoxAlt = farBoxAlt;
    farBoxArchive = farBoxArchive;
    farBoxBallot = farBoxBallot;
    farBoxCheck = farBoxCheck;
    farBoxCircleCheck = farBoxCircleCheck;
    farBoxDollar = farBoxDollar;
    farBoxes = farBoxes;
    farBoxesAlt = farBoxesAlt;
    farBoxesPacking = farBoxesPacking;
    farBoxesStacked = farBoxesStacked;
    farBoxFragile = farBoxFragile;
    farBoxFull = farBoxFull;
    farBoxHeart = farBoxHeart;
    farBoxingGlove = farBoxingGlove;
    farBoxOpen = farBoxOpen;
    farBoxOpenFull = farBoxOpenFull;
    farBoxTaped = farBoxTaped;
    farBoxTissue = farBoxTissue;
    farBoxUp = farBoxUp;
    farBoxUsd = farBoxUsd;
    farBracket = farBracket;
    farBracketCurly = farBracketCurly;
    farBracketCurlyLeft = farBracketCurlyLeft;
    farBracketCurlyRight = farBracketCurlyRight;
    farBracketLeft = farBracketLeft;
    farBracketRound = farBracketRound;
    farBracketRoundRight = farBracketRoundRight;
    farBrackets = farBrackets;
    farBracketsCurly = farBracketsCurly;
    farBracketSquare = farBracketSquare;
    farBracketSquareRight = farBracketSquareRight;
    farBracketsRound = farBracketsRound;
    farBracketsSquare = farBracketsSquare;
    farBraille = farBraille;
    farBrain = farBrain;
    farBrainArrowCurvedRight = farBrainArrowCurvedRight;
    farBrainCircuit = farBrainCircuit;
    farBrakeWarning = farBrakeWarning;
    farBrave = farBrave;
    farBraveReverse = farBraveReverse;
    farBrazilianRealSign = farBrazilianRealSign;
    farBreadLoaf = farBreadLoaf;
    farBreadSlice = farBreadSlice;
    farBreadSliceButter = farBreadSliceButter;
    farBridge = farBridge;
    farBridgeCircleCheck = farBridgeCircleCheck;
    farBridgeCircleExclamation = farBridgeCircleExclamation;
    farBridgeCircleXmark = farBridgeCircleXmark;
    farBridgeLock = farBridgeLock;
    farBridgeSuspension = farBridgeSuspension;
    farBridgeWater = farBridgeWater;
    farBriefcase = farBriefcase;
    farBriefcaseArrowRight = farBriefcaseArrowRight;
    farBriefcaseBlank = farBriefcaseBlank;
    farBriefcaseClock = farBriefcaseClock;
    farBriefcaseMedical = farBriefcaseMedical;
    farBrightness = farBrightness;
    farBrightnessLow = farBrightnessLow;
    farBringForward = farBringForward;
    farBringFront = farBringFront;
    farBroadcastTower = farBroadcastTower;
    farBroccoli = farBroccoli;
    farBroom = farBroom;
    farBroomBall = farBroomBall;
    farBroomWide = farBroomWide;
    farBrowser = farBrowser;
    farBrowsers = farBrowsers;
    farBrush = farBrush;
    farBtc = farBtc;
    farBucket = farBucket;
    farBuffer = farBuffer;
    farBug = farBug;
    farBugs = farBugs;
    farBugSlash = farBugSlash;
    farBuilding = farBuilding;
    farBuildingCircleArrowRight = farBuildingCircleArrowRight;
    farBuildingCircleCheck = farBuildingCircleCheck;
    farBuildingCircleExclamation = farBuildingCircleExclamation;
    farBuildingCircleXmark = farBuildingCircleXmark;
    farBuildingColumns = farBuildingColumns;
    farBuildingFlag = farBuildingFlag;
    farBuildingLock = farBuildingLock;
    farBuildingMagnifyingGlass = farBuildingMagnifyingGlass;
    farBuildingMemo = farBuildingMemo;
    farBuildingNgo = farBuildingNgo;
    farBuildings = farBuildings;
    farBuildingShield = farBuildingShield;
    farBuildingUn = farBuildingUn;
    farBuildingUser = farBuildingUser;
    farBuildingWheat = farBuildingWheat;
    farBulldozer = farBulldozer;
    farBullhorn = farBullhorn;
    farBullseye = farBullseye;
    farBullseyeArrow = farBullseyeArrow;
    farBullseyePointer = farBullseyePointer;
    farBuoy = farBuoy;
    farBuoyMooring = farBuoyMooring;
    farBurger = farBurger;
    farBurgerCheese = farBurgerCheese;
    farBurgerFries = farBurgerFries;
    farBurgerGlass = farBurgerGlass;
    farBurgerLettuce = farBurgerLettuce;
    farBurgerSoda = farBurgerSoda;
    farBurn = farBurn;
    farBuromobelexperte = farBuromobelexperte;
    farBurrito = farBurrito;
    farBurst = farBurst;
    farBus = farBus;
    farBusAlt = farBusAlt;
    farBusinessFront = farBusinessFront;
    farBusinessTime = farBusinessTime;
    farBusSchool = farBusSchool;
    farBusSimple = farBusSimple;
    farButter = farButter;
    farBuyNLarge = farBuyNLarge;
    farBuysellads = farBuysellads;
    farC = farC;
    farCab = farCab;
    farCabin = farCabin;
    farCabinetFiling = farCabinetFiling;
    farCableCar = farCableCar;
    farCactus = farCactus;
    farCake = farCake;
    farCakeCandles = farCakeCandles;
    farCakeSlice = farCakeSlice;
    farCalculator = farCalculator;
    farCalculatorAlt = farCalculatorAlt;
    farCalculatorSimple = farCalculatorSimple;
    farCalendar = farCalendar;
    farCalendarAlt = farCalendarAlt;
    farCalendarArrowDown = farCalendarArrowDown;
    farCalendarArrowUp = farCalendarArrowUp;
    farCalendarCheck = farCalendarCheck;
    farCalendarCircle = farCalendarCircle;
    farCalendarCircleExclamation = farCalendarCircleExclamation;
    farCalendarCircleMinus = farCalendarCircleMinus;
    farCalendarCirclePlus = farCalendarCirclePlus;
    farCalendarCircleUser = farCalendarCircleUser;
    farCalendarClock = farCalendarClock;
    farCalendarDay = farCalendarDay;
    farCalendarDays = farCalendarDays;
    farCalendarDownload = farCalendarDownload;
    farCalendarEdit = farCalendarEdit;
    farCalendarExclamation = farCalendarExclamation;
    farCalendarHeart = farCalendarHeart;
    farCalendarImage = farCalendarImage;
    farCalendarLines = farCalendarLines;
    farCalendarLinesPen = farCalendarLinesPen;
    farCalendarMinus = farCalendarMinus;
    farCalendarNote = farCalendarNote;
    farCalendarPen = farCalendarPen;
    farCalendarPlus = farCalendarPlus;
    farCalendarRange = farCalendarRange;
    farCalendars = farCalendars;
    farCalendarStar = farCalendarStar;
    farCalendarTime = farCalendarTime;
    farCalendarTimes = farCalendarTimes;
    farCalendarUpload = farCalendarUpload;
    farCalendarUsers = farCalendarUsers;
    farCalendarWeek = farCalendarWeek;
    farCalendarXmark = farCalendarXmark;
    farCamcorder = farCamcorder;
    farCamera = farCamera;
    farCameraAlt = farCameraAlt;
    farCameraCctv = farCameraCctv;
    farCameraCircle = farCameraCircle;
    farCameraHome = farCameraHome;
    farCameraMovie = farCameraMovie;
    farCameraPolaroid = farCameraPolaroid;
    farCameraRetro = farCameraRetro;
    farCameraRotate = farCameraRotate;
    farCameraSecurity = farCameraSecurity;
    farCameraSlash = farCameraSlash;
    farCameraViewfinder = farCameraViewfinder;
    farCameraWeb = farCameraWeb;
    farCameraWebSlash = farCameraWebSlash;
    farCampfire = farCampfire;
    farCampground = farCampground;
    farCanadianMapleLeaf = farCanadianMapleLeaf;
    farCancel = farCancel;
    farCandleHolder = farCandleHolder;
    farCandy = farCandy;
    farCandyBar = farCandyBar;
    farCandyCane = farCandyCane;
    farCandyCorn = farCandyCorn;
    farCanFood = farCanFood;
    farCannabis = farCannabis;
    farCannon = farCannon;
    farCapsules = farCapsules;
    farCar = farCar;
    farCarAlt = farCarAlt;
    farCaravan = farCaravan;
    farCaravanAlt = farCaravanAlt;
    farCaravanSimple = farCaravanSimple;
    farCarBattery = farCarBattery;
    farCarBolt = farCarBolt;
    farCarBuilding = farCarBuilding;
    farCarBump = farCarBump;
    farCarBurst = farCarBurst;
    farCarBus = farCarBus;
    farCarCircleBolt = farCarCircleBolt;
    farCarCrash = farCarCrash;
    farCardClub = farCardClub;
    farCardDiamond = farCardDiamond;
    farCardHeart = farCardHeart;
    farCards = farCards;
    farCardsBlank = farCardsBlank;
    farCardSpade = farCardSpade;
    farCaretCircleDown = farCaretCircleDown;
    farCaretCircleLeft = farCaretCircleLeft;
    farCaretCircleRight = farCaretCircleRight;
    farCaretCircleUp = farCaretCircleUp;
    farCaretDown = farCaretDown;
    farCaretLeft = farCaretLeft;
    farCaretRight = farCaretRight;
    farCaretSquareDown = farCaretSquareDown;
    farCaretSquareLeft = farCaretSquareLeft;
    farCaretSquareRight = farCaretSquareRight;
    farCaretSquareUp = farCaretSquareUp;
    farCaretUp = farCaretUp;
    farCarGarage = farCarGarage;
    farCarMechanic = farCarMechanic;
    farCarMirrors = farCarMirrors;
    farCarOn = farCarOn;
    farCarRear = farCarRear;
    farCarriageBaby = farCarriageBaby;
    farCarrot = farCarrot;
    farCars = farCars;
    farCarSide = farCarSide;
    farCarSideBolt = farCarSideBolt;
    farCartArrowDown = farCartArrowDown;
    farCartArrowUp = farCartArrowUp;
    farCartCircleArrowDown = farCartCircleArrowDown;
    farCartCircleArrowUp = farCartCircleArrowUp;
    farCartCircleCheck = farCartCircleCheck;
    farCartCircleExclamation = farCartCircleExclamation;
    farCartCirclePlus = farCartCirclePlus;
    farCartCircleXmark = farCartCircleXmark;
    farCartFlatbed = farCartFlatbed;
    farCartFlatbedBoxes = farCartFlatbedBoxes;
    farCartFlatbedEmpty = farCartFlatbedEmpty;
    farCartFlatbedSuitcase = farCartFlatbedSuitcase;
    farCarTilt = farCarTilt;
    farCartMinus = farCartMinus;
    farCartPlus = farCartPlus;
    farCartShopping = farCartShopping;
    farCartShoppingFast = farCartShoppingFast;
    farCarTunnel = farCarTunnel;
    farCartXmark = farCartXmark;
    farCarWash = farCarWash;
    farCarWrench = farCarWrench;
    farCashRegister = farCashRegister;
    farCassetteBetamax = farCassetteBetamax;
    farCassetteTape = farCassetteTape;
    farCassetteVhs = farCassetteVhs;
    farCastle = farCastle;
    farCat = farCat;
    farCatSpace = farCatSpace;
    farCauldron = farCauldron;
    farCcAmazonPay = farCcAmazonPay;
    farCcAmex = farCcAmex;
    farCcApplePay = farCcApplePay;
    farCcDinersClub = farCcDinersClub;
    farCcDiscover = farCcDiscover;
    farCcJcb = farCcJcb;
    farCcMastercard = farCcMastercard;
    farCcPaypal = farCcPaypal;
    farCcStripe = farCcStripe;
    farCctv = farCctv;
    farCcVisa = farCcVisa;
    farCediSign = farCediSign;
    farCentercode = farCentercode;
    farCentos = farCentos;
    farCentSign = farCentSign;
    farCertificate = farCertificate;
    farChain = farChain;
    farChainBroken = farChainBroken;
    farChainHorizontal = farChainHorizontal;
    farChainHorizontalSlash = farChainHorizontalSlash;
    farChainSlash = farChainSlash;
    farChair = farChair;
    farChairOffice = farChairOffice;
    farChalkboard = farChalkboard;
    farChalkboardTeacher = farChalkboardTeacher;
    farChalkboardUser = farChalkboardUser;
    farChampagneGlass = farChampagneGlass;
    farChampagneGlasses = farChampagneGlasses;
    farChargingStation = farChargingStation;
    farChartArea = farChartArea;
    farChartBar = farChartBar;
    farChartBullet = farChartBullet;
    farChartCandlestick = farChartCandlestick;
    farChartColumn = farChartColumn;
    farChartGantt = farChartGantt;
    farChartKanban = farChartKanban;
    farChartLine = farChartLine;
    farChartLineDown = farChartLineDown;
    farChartLineUp = farChartLineUp;
    farChartLineUpDown = farChartLineUpDown;
    farChartMixed = farChartMixed;
    farChartMixedUpCircleCurrency = farChartMixedUpCircleCurrency;
    farChartMixedUpCircleDollar = farChartMixedUpCircleDollar;
    farChartNetwork = farChartNetwork;
    farChartPie = farChartPie;
    farChartPieAlt = farChartPieAlt;
    farChartPieSimple = farChartPieSimple;
    farChartPieSimpleCircleCurrency = farChartPieSimpleCircleCurrency;
    farChartPieSimpleCircleDollar = farChartPieSimpleCircleDollar;
    farChartPyramid = farChartPyramid;
    farChartRadar = farChartRadar;
    farChartScatter = farChartScatter;
    farChartScatter3d = farChartScatter3d;
    farChartScatterBubble = farChartScatterBubble;
    farChartSimple = farChartSimple;
    farChartSimpleHorizontal = farChartSimpleHorizontal;
    farChartTreeMap = farChartTreeMap;
    farChartUser = farChartUser;
    farChartWaterfall = farChartWaterfall;
    farCheck = farCheck;
    farCheckCircle = farCheckCircle;
    farCheckDouble = farCheckDouble;
    farCheckSquare = farCheckSquare;
    farCheckToSlot = farCheckToSlot;
    farCheese = farCheese;
    farCheeseburger = farCheeseburger;
    farCheeseSwiss = farCheeseSwiss;
    farCherries = farCherries;
    farChess = farChess;
    farChessBishop = farChessBishop;
    farChessBishopAlt = farChessBishopAlt;
    farChessBishopPiece = farChessBishopPiece;
    farChessBoard = farChessBoard;
    farChessClock = farChessClock;
    farChessClockAlt = farChessClockAlt;
    farChessClockFlip = farChessClockFlip;
    farChessKing = farChessKing;
    farChessKingAlt = farChessKingAlt;
    farChessKingPiece = farChessKingPiece;
    farChessKnight = farChessKnight;
    farChessKnightAlt = farChessKnightAlt;
    farChessKnightPiece = farChessKnightPiece;
    farChessPawn = farChessPawn;
    farChessPawnAlt = farChessPawnAlt;
    farChessPawnPiece = farChessPawnPiece;
    farChessQueen = farChessQueen;
    farChessQueenAlt = farChessQueenAlt;
    farChessQueenPiece = farChessQueenPiece;
    farChessRook = farChessRook;
    farChessRookAlt = farChessRookAlt;
    farChessRookPiece = farChessRookPiece;
    farChestnut = farChestnut;
    farChevronCircleDown = farChevronCircleDown;
    farChevronCircleLeft = farChevronCircleLeft;
    farChevronCircleRight = farChevronCircleRight;
    farChevronCircleUp = farChevronCircleUp;
    farChevronDoubleDown = farChevronDoubleDown;
    farChevronDoubleLeft = farChevronDoubleLeft;
    farChevronDoubleRight = farChevronDoubleRight;
    farChevronDoubleUp = farChevronDoubleUp;
    farChevronDown = farChevronDown;
    farChevronLeft = farChevronLeft;
    farChevronRight = farChevronRight;
    farChevronsDown = farChevronsDown;
    farChevronsLeft = farChevronsLeft;
    farChevronSquareDown = farChevronSquareDown;
    farChevronSquareLeft = farChevronSquareLeft;
    farChevronSquareRight = farChevronSquareRight;
    farChevronSquareUp = farChevronSquareUp;
    farChevronsRight = farChevronsRight;
    farChevronsUp = farChevronsUp;
    farChevronUp = farChevronUp;
    farChfSign = farChfSign;
    farChild = farChild;
    farChildCombatant = farChildCombatant;
    farChildDress = farChildDress;
    farChildReaching = farChildReaching;
    farChildren = farChildren;
    farChildRifle = farChildRifle;
    farChimney = farChimney;
    farChocolateBar = farChocolateBar;
    farChopsticks = farChopsticks;
    farChrome = farChrome;
    farChromecast = farChromecast;
    farChurch = farChurch;
    farCircle = farCircle;
    farCircle0 = farCircle0;
    farCircle1 = farCircle1;
    farCircle2 = farCircle2;
    farCircle3 = farCircle3;
    farCircle4 = farCircle4;
    farCircle5 = farCircle5;
    farCircle6 = farCircle6;
    farCircle7 = farCircle7;
    farCircle8 = farCircle8;
    farCircle9 = farCircle9;
    farCircleA = farCircleA;
    farCircleAmpersand = farCircleAmpersand;
    farCircleArrowDown = farCircleArrowDown;
    farCircleArrowDownLeft = farCircleArrowDownLeft;
    farCircleArrowDownRight = farCircleArrowDownRight;
    farCircleArrowLeft = farCircleArrowLeft;
    farCircleArrowRight = farCircleArrowRight;
    farCircleArrowUp = farCircleArrowUp;
    farCircleArrowUpLeft = farCircleArrowUpLeft;
    farCircleArrowUpRight = farCircleArrowUpRight;
    farCircleB = farCircleB;
    farCircleBolt = farCircleBolt;
    farCircleBookmark = farCircleBookmark;
    farCircleBookOpen = farCircleBookOpen;
    farCircleC = farCircleC;
    farCircleCalendar = farCircleCalendar;
    farCircleCamera = farCircleCamera;
    farCircleCaretDown = farCircleCaretDown;
    farCircleCaretLeft = farCircleCaretLeft;
    farCircleCaretRight = farCircleCaretRight;
    farCircleCaretUp = farCircleCaretUp;
    farCircleCheck = farCircleCheck;
    farCircleChevronDown = farCircleChevronDown;
    farCircleChevronLeft = farCircleChevronLeft;
    farCircleChevronRight = farCircleChevronRight;
    farCircleChevronUp = farCircleChevronUp;
    farCircleD = farCircleD;
    farCircleDashed = farCircleDashed;
    farCircleDivide = farCircleDivide;
    farCircleDollar = farCircleDollar;
    farCircleDollarToSlot = farCircleDollarToSlot;
    farCircleDot = farCircleDot;
    farCircleDown = farCircleDown;
    farCircleDownLeft = farCircleDownLeft;
    farCircleDownRight = farCircleDownRight;
    farCircleE = farCircleE;
    farCircleEllipsis = farCircleEllipsis;
    farCircleEllipsisVertical = farCircleEllipsisVertical;
    farCircleEnvelope = farCircleEnvelope;
    farCircleEuro = farCircleEuro;
    farCircleExclamation = farCircleExclamation;
    farCircleExclamationCheck = farCircleExclamationCheck;
    farCircleF = farCircleF;
    farCircleG = farCircleG;
    farCircleH = farCircleH;
    farCircleHalf = farCircleHalf;
    farCircleHalfStroke = farCircleHalfStroke;
    farCircleHeart = farCircleHeart;
    farCircleI = farCircleI;
    farCircleInfo = farCircleInfo;
    farCircleJ = farCircleJ;
    farCircleK = farCircleK;
    farCircleL = farCircleL;
    farCircleLeft = farCircleLeft;
    farCircleLocationArrow = farCircleLocationArrow;
    farCircleM = farCircleM;
    farCircleMicrophone = farCircleMicrophone;
    farCircleMicrophoneLines = farCircleMicrophoneLines;
    farCircleMinus = farCircleMinus;
    farCircleN = farCircleN;
    farCircleNodes = farCircleNodes;
    farCircleNotch = farCircleNotch;
    farCircleO = farCircleO;
    farCircleP = farCircleP;
    farCircleParking = farCircleParking;
    farCirclePause = farCirclePause;
    farCirclePhone = farCirclePhone;
    farCirclePhoneFlip = farCirclePhoneFlip;
    farCirclePhoneHangup = farCirclePhoneHangup;
    farCirclePlay = farCirclePlay;
    farCirclePlus = farCirclePlus;
    farCircleQ = farCircleQ;
    farCircleQuarter = farCircleQuarter;
    farCircleQuarters = farCircleQuarters;
    farCircleQuarterStroke = farCircleQuarterStroke;
    farCircleQuestion = farCircleQuestion;
    farCircleR = farCircleR;
    farCircleRadiation = farCircleRadiation;
    farCircleRight = farCircleRight;
    farCircleS = farCircleS;
    farCircleSmall = farCircleSmall;
    farCircleSort = farCircleSort;
    farCircleSortDown = farCircleSortDown;
    farCircleSortUp = farCircleSortUp;
    farCirclesOverlap = farCirclesOverlap;
    farCircleStar = farCircleStar;
    farCircleSterling = farCircleSterling;
    farCircleStop = farCircleStop;
    farCircleT = farCircleT;
    farCircleThreeQuarters = farCircleThreeQuarters;
    farCircleThreeQuartersStroke = farCircleThreeQuartersStroke;
    farCircleTrash = farCircleTrash;
    farCircleU = farCircleU;
    farCircleUp = farCircleUp;
    farCircleUpLeft = farCircleUpLeft;
    farCircleUpRight = farCircleUpRight;
    farCircleUser = farCircleUser;
    farCircleV = farCircleV;
    farCircleVideo = farCircleVideo;
    farCircleW = farCircleW;
    farCircleWaveformLines = farCircleWaveformLines;
    farCircleX = farCircleX;
    farCircleXmark = farCircleXmark;
    farCircleY = farCircleY;
    farCircleYen = farCircleYen;
    farCircleZ = farCircleZ;
    farCitrus = farCitrus;
    farCitrusSlice = farCitrusSlice;
    farCity = farCity;
    farClapperboard = farClapperboard;
    farClapperboardPlay = farClapperboardPlay;
    farClarinet = farClarinet;
    farClawMarks = farClawMarks;
    farClinicMedical = farClinicMedical;
    farClipboard = farClipboard;
    farClipboardCheck = farClipboardCheck;
    farClipboardList = farClipboardList;
    farClipboardListCheck = farClipboardListCheck;
    farClipboardMedical = farClipboardMedical;
    farClipboardPrescription = farClipboardPrescription;
    farClipboardQuestion = farClipboardQuestion;
    farClipboardUser = farClipboardUser;
    farClock = farClock;
    farClockDesk = farClockDesk;
    farClockEight = farClockEight;
    farClockEightThirty = farClockEightThirty;
    farClockEleven = farClockEleven;
    farClockElevenThirty = farClockElevenThirty;
    farClockFive = farClockFive;
    farClockFiveThirty = farClockFiveThirty;
    farClockFour = farClockFour;
    farClockFourThirty = farClockFourThirty;
    farClockNine = farClockNine;
    farClockNineThirty = farClockNineThirty;
    farClockOne = farClockOne;
    farClockOneThirty = farClockOneThirty;
    farClockRotateLeft = farClockRotateLeft;
    farClockSeven = farClockSeven;
    farClockSevenThirty = farClockSevenThirty;
    farClockSix = farClockSix;
    farClockSixThirty = farClockSixThirty;
    farClockTen = farClockTen;
    farClockTenThirty = farClockTenThirty;
    farClockThree = farClockThree;
    farClockThreeThirty = farClockThreeThirty;
    farClockTwelve = farClockTwelve;
    farClockTwelveThirty = farClockTwelveThirty;
    farClockTwo = farClockTwo;
    farClockTwoThirty = farClockTwoThirty;
    farClone = farClone;
    farClose = farClose;
    farClosedCaptioning = farClosedCaptioning;
    farClosedCaptioningSlash = farClosedCaptioningSlash;
    farClothesHanger = farClothesHanger;
    farCloud = farCloud;
    farCloudArrowDown = farCloudArrowDown;
    farCloudArrowUp = farCloudArrowUp;
    farCloudBinary = farCloudBinary;
    farCloudBolt = farCloudBolt;
    farCloudBoltMoon = farCloudBoltMoon;
    farCloudBoltSun = farCloudBoltSun;
    farCloudCheck = farCloudCheck;
    farCloudDownload = farCloudDownload;
    farCloudDownloadAlt = farCloudDownloadAlt;
    farCloudDrizzle = farCloudDrizzle;
    farCloudExclamation = farCloudExclamation;
    farCloudflare = farCloudflare;
    farCloudFog = farCloudFog;
    farCloudHail = farCloudHail;
    farCloudHailMixed = farCloudHailMixed;
    farCloudMeatball = farCloudMeatball;
    farCloudMinus = farCloudMinus;
    farCloudMoon = farCloudMoon;
    farCloudMoonRain = farCloudMoonRain;
    farCloudMusic = farCloudMusic;
    farCloudPlus = farCloudPlus;
    farCloudQuestion = farCloudQuestion;
    farCloudRain = farCloudRain;
    farCloudRainbow = farCloudRainbow;
    farClouds = farClouds;
    farCloudscale = farCloudscale;
    farCloudShowers = farCloudShowers;
    farCloudShowersHeavy = farCloudShowersHeavy;
    farCloudShowersWater = farCloudShowersWater;
    farCloudSlash = farCloudSlash;
    farCloudSleet = farCloudSleet;
    farCloudsmith = farCloudsmith;
    farCloudsMoon = farCloudsMoon;
    farCloudSnow = farCloudSnow;
    farCloudsSun = farCloudsSun;
    farCloudSun = farCloudSun;
    farCloudSunRain = farCloudSunRain;
    farCloudUpload = farCloudUpload;
    farCloudUploadAlt = farCloudUploadAlt;
    farCloudversify = farCloudversify;
    farCloudWord = farCloudWord;
    farCloudXmark = farCloudXmark;
    farClover = farClover;
    farClub = farClub;
    farCmplid = farCmplid;
    farCny = farCny;
    farCocktail = farCocktail;
    farCoconut = farCoconut;
    farCode = farCode;
    farCodeBranch = farCodeBranch;
    farCodeCommit = farCodeCommit;
    farCodeCompare = farCodeCompare;
    farCodeFork = farCodeFork;
    farCodeMerge = farCodeMerge;
    farCodepen = farCodepen;
    farCodePullRequest = farCodePullRequest;
    farCodePullRequestClosed = farCodePullRequestClosed;
    farCodePullRequestDraft = farCodePullRequestDraft;
    farCodeSimple = farCodeSimple;
    farCodiepie = farCodiepie;
    farCoffee = farCoffee;
    farCoffeeBean = farCoffeeBean;
    farCoffeeBeans = farCoffeeBeans;
    farCoffeePot = farCoffeePot;
    farCoffeeTogo = farCoffeeTogo;
    farCoffin = farCoffin;
    farCoffinCross = farCoffinCross;
    farCog = farCog;
    farCogs = farCogs;
    farCoin = farCoin;
    farCoinBlank = farCoinBlank;
    farCoinFront = farCoinFront;
    farCoins = farCoins;
    farCoinVertical = farCoinVertical;
    farColon = farColon;
    farColonSign = farColonSign;
    farColumns = farColumns;
    farColumns3 = farColumns3;
    farComet = farComet;
    farComma = farComma;
    farCommand = farCommand;
    farComment = farComment;
    farCommentAlt = farCommentAlt;
    farCommentAltArrowDown = farCommentAltArrowDown;
    farCommentAltArrowUp = farCommentAltArrowUp;
    farCommentAltCaptions = farCommentAltCaptions;
    farCommentAltCheck = farCommentAltCheck;
    farCommentAltDollar = farCommentAltDollar;
    farCommentAltDots = farCommentAltDots;
    farCommentAltEdit = farCommentAltEdit;
    farCommentAltExclamation = farCommentAltExclamation;
    farCommentAltImage = farCommentAltImage;
    farCommentAltLines = farCommentAltLines;
    farCommentAltMedical = farCommentAltMedical;
    farCommentAltMinus = farCommentAltMinus;
    farCommentAltMusic = farCommentAltMusic;
    farCommentAltPlus = farCommentAltPlus;
    farCommentAltQuote = farCommentAltQuote;
    farCommentAltSlash = farCommentAltSlash;
    farCommentAltSmile = farCommentAltSmile;
    farCommentAltText = farCommentAltText;
    farCommentAltTimes = farCommentAltTimes;
    farCommentArrowDown = farCommentArrowDown;
    farCommentArrowUp = farCommentArrowUp;
    farCommentArrowUpRight = farCommentArrowUpRight;
    farCommentCaptions = farCommentCaptions;
    farCommentCheck = farCommentCheck;
    farCommentCode = farCommentCode;
    farCommentDollar = farCommentDollar;
    farCommentDots = farCommentDots;
    farCommentEdit = farCommentEdit;
    farCommentExclamation = farCommentExclamation;
    farCommentHeart = farCommentHeart;
    farCommentImage = farCommentImage;
    farCommenting = farCommenting;
    farCommentLines = farCommentLines;
    farCommentMedical = farCommentMedical;
    farCommentMiddle = farCommentMiddle;
    farCommentMiddleAlt = farCommentMiddleAlt;
    farCommentMiddleTop = farCommentMiddleTop;
    farCommentMiddleTopAlt = farCommentMiddleTopAlt;
    farCommentMinus = farCommentMinus;
    farCommentMusic = farCommentMusic;
    farCommentPen = farCommentPen;
    farCommentPlus = farCommentPlus;
    farCommentQuestion = farCommentQuestion;
    farCommentQuote = farCommentQuote;
    farComments = farComments;
    farCommentsAlt = farCommentsAlt;
    farCommentsAltDollar = farCommentsAltDollar;
    farCommentsDollar = farCommentsDollar;
    farCommentSlash = farCommentSlash;
    farCommentSmile = farCommentSmile;
    farCommentSms = farCommentSms;
    farCommentsQuestion = farCommentsQuestion;
    farCommentsQuestionCheck = farCommentsQuestionCheck;
    farCommentText = farCommentText;
    farCommentTimes = farCommentTimes;
    farCommentXmark = farCommentXmark;
    farCompactDisc = farCompactDisc;
    farCompass = farCompass;
    farCompassDrafting = farCompassDrafting;
    farCompassSlash = farCompassSlash;
    farCompress = farCompress;
    farCompressAlt = farCompressAlt;
    farCompressArrows = farCompressArrows;
    farCompressArrowsAlt = farCompressArrowsAlt;
    farCompressWide = farCompressWide;
    farComputer = farComputer;
    farComputerClassic = farComputerClassic;
    farComputerMouse = farComputerMouse;
    farComputerMouseScrollwheel = farComputerMouseScrollwheel;
    farComputerSpeaker = farComputerSpeaker;
    farConciergeBell = farConciergeBell;
    farConfluence = farConfluence;
    farConnectdevelop = farConnectdevelop;
    farConstruction = farConstruction;
    farContactBook = farContactBook;
    farContactCard = farContactCard;
    farContainerStorage = farContainerStorage;
    farContao = farContao;
    farConveyorBelt = farConveyorBelt;
    farConveyorBeltAlt = farConveyorBeltAlt;
    farConveyorBeltArm = farConveyorBeltArm;
    farConveyorBeltBoxes = farConveyorBeltBoxes;
    farConveyorBeltEmpty = farConveyorBeltEmpty;
    farCookie = farCookie;
    farCookieBite = farCookieBite;
    farCopy = farCopy;
    farCopyright = farCopyright;
    farCorn = farCorn;
    farCorner = farCorner;
    farCottonBureau = farCottonBureau;
    farCouch = farCouch;
    farCouchSmall = farCouchSmall;
    farCourtSport = farCourtSport;
    farCow = farCow;
    farCowbell = farCowbell;
    farCowbellCirclePlus = farCowbellCirclePlus;
    farCowbellMore = farCowbellMore;
    farCpanel = farCpanel;
    farCrab = farCrab;
    farCrateApple = farCrateApple;
    farCrateEmpty = farCrateEmpty;
    farCreativeCommons = farCreativeCommons;
    farCreativeCommonsBy = farCreativeCommonsBy;
    farCreativeCommonsNc = farCreativeCommonsNc;
    farCreativeCommonsNcEu = farCreativeCommonsNcEu;
    farCreativeCommonsNcJp = farCreativeCommonsNcJp;
    farCreativeCommonsNd = farCreativeCommonsNd;
    farCreativeCommonsPd = farCreativeCommonsPd;
    farCreativeCommonsPdAlt = farCreativeCommonsPdAlt;
    farCreativeCommonsRemix = farCreativeCommonsRemix;
    farCreativeCommonsSa = farCreativeCommonsSa;
    farCreativeCommonsSampling = farCreativeCommonsSampling;
    farCreativeCommonsSamplingPlus = farCreativeCommonsSamplingPlus;
    farCreativeCommonsShare = farCreativeCommonsShare;
    farCreativeCommonsZero = farCreativeCommonsZero;
    farCreditCard = farCreditCard;
    farCreditCardAlt = farCreditCardAlt;
    farCreditCardBlank = farCreditCardBlank;
    farCreditCardFront = farCreditCardFront;
    farCreemee = farCreemee;
    farCricket = farCricket;
    farCricketBatBall = farCricketBatBall;
    farCriticalRole = farCriticalRole;
    farCroissant = farCroissant;
    farCrop = farCrop;
    farCropAlt = farCropAlt;
    farCropSimple = farCropSimple;
    farCross = farCross;
    farCrosshairs = farCrosshairs;
    farCrosshairsSimple = farCrosshairsSimple;
    farCrow = farCrow;
    farCrown = farCrown;
    farCrutch = farCrutch;
    farCrutches = farCrutches;
    farCruzeiroSign = farCruzeiroSign;
    farCrystalBall = farCrystalBall;
    farCss3 = farCss3;
    farCss3Alt = farCss3Alt;
    farCube = farCube;
    farCubes = farCubes;
    farCubesStacked = farCubesStacked;
    farCucumber = farCucumber;
    farCupcake = farCupcake;
    farCupStraw = farCupStraw;
    farCupStrawSwoosh = farCupStrawSwoosh;
    farCupTogo = farCupTogo;
    farCurling = farCurling;
    farCurlingStone = farCurlingStone;
    farCustard = farCustard;
    farCut = farCut;
    farCutlery = farCutlery;
    farCuttlefish = farCuttlefish;
    farD = farD;
    farDagger = farDagger;
    farDailymotion = farDailymotion;
    farDAndD = farDAndD;
    farDAndDBeyond = farDAndDBeyond;
    farDash = farDash;
    farDashboard = farDashboard;
    farDashcube = farDashcube;
    farDatabase = farDatabase;
    farDeaf = farDeaf;
    farDeafness = farDeafness;
    farDebian = farDebian;
    farDebug = farDebug;
    farDedent = farDedent;
    farDeer = farDeer;
    farDeerRudolph = farDeerRudolph;
    farDeezer = farDeezer;
    farDeleteLeft = farDeleteLeft;
    farDeleteRight = farDeleteRight;
    farDelicious = farDelicious;
    farDemocrat = farDemocrat;
    farDeploydog = farDeploydog;
    farDeskpro = farDeskpro;
    farDesktop = farDesktop;
    farDesktopAlt = farDesktopAlt;
    farDesktopArrowDown = farDesktopArrowDown;
    farDesktopCode = farDesktopCode;
    farDesktopMedical = farDesktopMedical;
    farDesktopSlash = farDesktopSlash;
    farDev = farDev;
    farDeviantart = farDeviantart;
    farDewpoint = farDewpoint;
    farDharmachakra = farDharmachakra;
    farDhl = farDhl;
    farDiagnoses = farDiagnoses;
    farDiagramCells = farDiagramCells;
    farDiagramLeanCanvas = farDiagramLeanCanvas;
    farDiagramNested = farDiagramNested;
    farDiagramNext = farDiagramNext;
    farDiagramPredecessor = farDiagramPredecessor;
    farDiagramPrevious = farDiagramPrevious;
    farDiagramProject = farDiagramProject;
    farDiagramSankey = farDiagramSankey;
    farDiagramSubtask = farDiagramSubtask;
    farDiagramSuccessor = farDiagramSuccessor;
    farDiagramVenn = farDiagramVenn;
    farDial = farDial;
    farDialHigh = farDialHigh;
    farDialLow = farDialLow;
    farDialMax = farDialMax;
    farDialMed = farDialMed;
    farDialMedHigh = farDialMedHigh;
    farDialMedLow = farDialMedLow;
    farDialMin = farDialMin;
    farDialOff = farDialOff;
    farDiamond = farDiamond;
    farDiamondExclamation = farDiamondExclamation;
    farDiamondHalf = farDiamondHalf;
    farDiamondHalfStroke = farDiamondHalfStroke;
    farDiamondTurnRight = farDiamondTurnRight;
    farDiaspora = farDiaspora;
    farDice = farDice;
    farDiceD10 = farDiceD10;
    farDiceD12 = farDiceD12;
    farDiceD20 = farDiceD20;
    farDiceD4 = farDiceD4;
    farDiceD6 = farDiceD6;
    farDiceD8 = farDiceD8;
    farDiceFive = farDiceFive;
    farDiceFour = farDiceFour;
    farDiceOne = farDiceOne;
    farDiceSix = farDiceSix;
    farDiceThree = farDiceThree;
    farDiceTwo = farDiceTwo;
    farDigg = farDigg;
    farDigging = farDigging;
    farDigitalOcean = farDigitalOcean;
    farDigitalTachograph = farDigitalTachograph;
    farDinosaur = farDinosaur;
    farDiploma = farDiploma;
    farDirections = farDirections;
    farDiscDrive = farDiscDrive;
    farDiscord = farDiscord;
    farDiscourse = farDiscourse;
    farDisease = farDisease;
    farDisplay = farDisplay;
    farDisplayArrowDown = farDisplayArrowDown;
    farDisplayChartUp = farDisplayChartUp;
    farDisplayChartUpCircleCurrency = farDisplayChartUpCircleCurrency;
    farDisplayChartUpCircleDollar = farDisplayChartUpCircleDollar;
    farDisplayCode = farDisplayCode;
    farDisplayMedical = farDisplayMedical;
    farDisplaySlash = farDisplaySlash;
    farDistributeSpacingHorizontal = farDistributeSpacingHorizontal;
    farDistributeSpacingVertical = farDistributeSpacingVertical;
    farDitto = farDitto;
    farDivide = farDivide;
    farDizzy = farDizzy;
    farDna = farDna;
    farDochub = farDochub;
    farDocker = farDocker;
    farDog = farDog;
    farDogLeashed = farDogLeashed;
    farDollar = farDollar;
    farDollarCircle = farDollarCircle;
    farDollarSign = farDollarSign;
    farDollarSquare = farDollarSquare;
    farDolly = farDolly;
    farDollyBox = farDollyBox;
    farDollyEmpty = farDollyEmpty;
    farDollyFlatbed = farDollyFlatbed;
    farDollyFlatbedAlt = farDollyFlatbedAlt;
    farDollyFlatbedEmpty = farDollyFlatbedEmpty;
    farDolphin = farDolphin;
    farDonate = farDonate;
    farDongSign = farDongSign;
    farDoNotEnter = farDoNotEnter;
    farDonut = farDonut;
    farDoorClosed = farDoorClosed;
    farDoorOpen = farDoorOpen;
    farDotCircle = farDotCircle;
    farDoughnut = farDoughnut;
    farDove = farDove;
    farDown = farDown;
    farDownFromDottedLine = farDownFromDottedLine;
    farDownFromLine = farDownFromLine;
    farDownLeft = farDownLeft;
    farDownLeftAndUpRightToCenter = farDownLeftAndUpRightToCenter;
    farDownload = farDownload;
    farDownLong = farDownLong;
    farDownRight = farDownRight;
    farDownToBracket = farDownToBracket;
    farDownToDottedLine = farDownToDottedLine;
    farDownToLine = farDownToLine;
    farDraft2digital = farDraft2digital;
    farDraftingCompass = farDraftingCompass;
    farDragon = farDragon;
    farDrawCircle = farDrawCircle;
    farDrawPolygon = farDrawPolygon;
    farDrawSquare = farDrawSquare;
    farDreidel = farDreidel;
    farDribbble = farDribbble;
    farDribbbleSquare = farDribbbleSquare;
    farDriversLicense = farDriversLicense;
    farDrone = farDrone;
    farDroneAlt = farDroneAlt;
    farDroneFront = farDroneFront;
    farDropbox = farDropbox;
    farDroplet = farDroplet;
    farDropletDegree = farDropletDegree;
    farDropletPercent = farDropletPercent;
    farDropletSlash = farDropletSlash;
    farDrum = farDrum;
    farDrumSteelpan = farDrumSteelpan;
    farDrumstick = farDrumstick;
    farDrumstickBite = farDrumstickBite;
    farDrupal = farDrupal;
    farDryer = farDryer;
    farDryerAlt = farDryerAlt;
    farDryerHeat = farDryerHeat;
    farDuck = farDuck;
    farDumbbell = farDumbbell;
    farDumpster = farDumpster;
    farDumpsterFire = farDumpsterFire;
    farDungeon = farDungeon;
    farDyalog = farDyalog;
    farE = farE;
    farEar = farEar;
    farEarDeaf = farEarDeaf;
    farEarListen = farEarListen;
    farEarlybirds = farEarlybirds;
    farEarMuffs = farEarMuffs;
    farEarth = farEarth;
    farEarthAfrica = farEarthAfrica;
    farEarthAmerica = farEarthAmerica;
    farEarthAmericas = farEarthAmericas;
    farEarthAsia = farEarthAsia;
    farEarthEurope = farEarthEurope;
    farEarthOceania = farEarthOceania;
    farEbay = farEbay;
    farEclipse = farEclipse;
    farEclipseAlt = farEclipseAlt;
    farEdge = farEdge;
    farEdgeLegacy = farEdgeLegacy;
    farEdit = farEdit;
    farEgg = farEgg;
    farEggFried = farEggFried;
    farEggplant = farEggplant;
    farEject = farEject;
    farElementor = farElementor;
    farElephant = farElephant;
    farElevator = farElevator;
    farEllipsis = farEllipsis;
    farEllipsisH = farEllipsisH;
    farEllipsisHAlt = farEllipsisHAlt;
    farEllipsisStroke = farEllipsisStroke;
    farEllipsisStrokeVertical = farEllipsisStrokeVertical;
    farEllipsisV = farEllipsisV;
    farEllipsisVAlt = farEllipsisVAlt;
    farEllipsisVertical = farEllipsisVertical;
    farEllo = farEllo;
    farEmber = farEmber;
    farEmpire = farEmpire;
    farEmptySet = farEmptySet;
    farEngine = farEngine;
    farEngineExclamation = farEngineExclamation;
    farEngineWarning = farEngineWarning;
    farEnvelope = farEnvelope;
    farEnvelopeBadge = farEnvelopeBadge;
    farEnvelopeCircle = farEnvelopeCircle;
    farEnvelopeCircleCheck = farEnvelopeCircleCheck;
    farEnvelopeDot = farEnvelopeDot;
    farEnvelopeOpen = farEnvelopeOpen;
    farEnvelopeOpenDollar = farEnvelopeOpenDollar;
    farEnvelopeOpenText = farEnvelopeOpenText;
    farEnvelopes = farEnvelopes;
    farEnvelopesBulk = farEnvelopesBulk;
    farEnvelopeSquare = farEnvelopeSquare;
    farEnvira = farEnvira;
    farEquals = farEquals;
    farEraser = farEraser;
    farErlang = farErlang;
    farEscalator = farEscalator;
    farEthereum = farEthereum;
    farEthernet = farEthernet;
    farEtsy = farEtsy;
    farEur = farEur;
    farEuro = farEuro;
    farEuroSign = farEuroSign;
    farEvernote = farEvernote;
    farExcavator = farExcavator;
    farExchange = farExchange;
    farExchangeAlt = farExchangeAlt;
    farExclamation = farExclamation;
    farExclamationCircle = farExclamationCircle;
    farExclamationSquare = farExclamationSquare;
    farExclamationTriangle = farExclamationTriangle;
    farExpand = farExpand;
    farExpandAlt = farExpandAlt;
    farExpandArrows = farExpandArrows;
    farExpandArrowsAlt = farExpandArrowsAlt;
    farExpandWide = farExpandWide;
    farExpeditedssl = farExpeditedssl;
    farExplodingHead = farExplodingHead;
    farExplosion = farExplosion;
    farExternalLink = farExternalLink;
    farExternalLinkAlt = farExternalLinkAlt;
    farExternalLinkSquare = farExternalLinkSquare;
    farExternalLinkSquareAlt = farExternalLinkSquareAlt;
    farEye = farEye;
    farEyedropper = farEyedropper;
    farEyeDropperEmpty = farEyeDropperEmpty;
    farEyeDropperFull = farEyeDropperFull;
    farEyeDropperHalf = farEyeDropperHalf;
    farEyeEvil = farEyeEvil;
    farEyeLowVision = farEyeLowVision;
    farEyes = farEyes;
    farEyeSlash = farEyeSlash;
    farF = farF;
    farFaceAngry = farFaceAngry;
    farFaceAngryHorns = farFaceAngryHorns;
    farFaceAnguished = farFaceAnguished;
    farFaceAnxiousSweat = farFaceAnxiousSweat;
    farFaceAstonished = farFaceAstonished;
    farFaceAwesome = farFaceAwesome;
    farFaceBeamHandOverMouth = farFaceBeamHandOverMouth;
    farFacebook = farFacebook;
    farFacebookF = farFacebookF;
    farFacebookMessenger = farFacebookMessenger;
    farFacebookSquare = farFacebookSquare;
    farFaceClouds = farFaceClouds;
    farFaceConfounded = farFaceConfounded;
    farFaceConfused = farFaceConfused;
    farFaceCowboyHat = farFaceCowboyHat;
    farFaceDiagonalMouth = farFaceDiagonalMouth;
    farFaceDisappointed = farFaceDisappointed;
    farFaceDisguise = farFaceDisguise;
    farFaceDizzy = farFaceDizzy;
    farFaceDotted = farFaceDotted;
    farFaceDowncastSweat = farFaceDowncastSweat;
    farFaceDrooling = farFaceDrooling;
    farFaceExhaling = farFaceExhaling;
    farFaceExplode = farFaceExplode;
    farFaceExpressionless = farFaceExpressionless;
    farFaceEyesXmarks = farFaceEyesXmarks;
    farFaceFearful = farFaceFearful;
    farFaceFlushed = farFaceFlushed;
    farFaceFrown = farFaceFrown;
    farFaceFrownOpen = farFaceFrownOpen;
    farFaceFrownSlight = farFaceFrownSlight;
    farFaceGlasses = farFaceGlasses;
    farFaceGrimace = farFaceGrimace;
    farFaceGrin = farFaceGrin;
    farFaceGrinBeam = farFaceGrinBeam;
    farFaceGrinBeamSweat = farFaceGrinBeamSweat;
    farFaceGrinHearts = farFaceGrinHearts;
    farFaceGrinSquint = farFaceGrinSquint;
    farFaceGrinSquintTears = farFaceGrinSquintTears;
    farFaceGrinStars = farFaceGrinStars;
    farFaceGrinTears = farFaceGrinTears;
    farFaceGrinTongue = farFaceGrinTongue;
    farFaceGrinTongueSquint = farFaceGrinTongueSquint;
    farFaceGrinTongueWink = farFaceGrinTongueWink;
    farFaceGrinWide = farFaceGrinWide;
    farFaceGrinWink = farFaceGrinWink;
    farFaceHandOverMouth = farFaceHandOverMouth;
    farFaceHandPeeking = farFaceHandPeeking;
    farFaceHandYawn = farFaceHandYawn;
    farFaceHeadBandage = farFaceHeadBandage;
    farFaceHoldingBackTears = farFaceHoldingBackTears;
    farFaceHushed = farFaceHushed;
    farFaceIcicles = farFaceIcicles;
    farFaceKiss = farFaceKiss;
    farFaceKissBeam = farFaceKissBeam;
    farFaceKissClosedEyes = farFaceKissClosedEyes;
    farFaceKissWinkHeart = farFaceKissWinkHeart;
    farFaceLaugh = farFaceLaugh;
    farFaceLaughBeam = farFaceLaughBeam;
    farFaceLaughSquint = farFaceLaughSquint;
    farFaceLaughWink = farFaceLaughWink;
    farFaceLying = farFaceLying;
    farFaceMask = farFaceMask;
    farFaceMeh = farFaceMeh;
    farFaceMehBlank = farFaceMehBlank;
    farFaceMelting = farFaceMelting;
    farFaceMonocle = farFaceMonocle;
    farFaceNauseated = farFaceNauseated;
    farFaceNoseSteam = farFaceNoseSteam;
    farFaceParty = farFaceParty;
    farFacePensive = farFacePensive;
    farFacePersevering = farFacePersevering;
    farFacePleading = farFacePleading;
    farFacePouting = farFacePouting;
    farFaceRaisedEyebrow = farFaceRaisedEyebrow;
    farFaceRelieved = farFaceRelieved;
    farFaceRollingEyes = farFaceRollingEyes;
    farFaceSadCry = farFaceSadCry;
    farFaceSadSweat = farFaceSadSweat;
    farFaceSadTear = farFaceSadTear;
    farFaceSaluting = farFaceSaluting;
    farFaceScream = farFaceScream;
    farFaceShush = farFaceShush;
    farFaceSleeping = farFaceSleeping;
    farFaceSleepy = farFaceSleepy;
    farFaceSmile = farFaceSmile;
    farFaceSmileBeam = farFaceSmileBeam;
    farFaceSmileHalo = farFaceSmileHalo;
    farFaceSmileHearts = farFaceSmileHearts;
    farFaceSmileHorns = farFaceSmileHorns;
    farFaceSmilePlus = farFaceSmilePlus;
    farFaceSmileRelaxed = farFaceSmileRelaxed;
    farFaceSmileTear = farFaceSmileTear;
    farFaceSmileTongue = farFaceSmileTongue;
    farFaceSmileUpsideDown = farFaceSmileUpsideDown;
    farFaceSmileWink = farFaceSmileWink;
    farFaceSmilingHands = farFaceSmilingHands;
    farFaceSmirking = farFaceSmirking;
    farFaceSpiralEyes = farFaceSpiralEyes;
    farFaceSunglasses = farFaceSunglasses;
    farFaceSurprise = farFaceSurprise;
    farFaceSwear = farFaceSwear;
    farFaceThermometer = farFaceThermometer;
    farFaceThinking = farFaceThinking;
    farFaceTired = farFaceTired;
    farFaceTissue = farFaceTissue;
    farFaceTongueMoney = farFaceTongueMoney;
    farFaceTongueSweat = farFaceTongueSweat;
    farFaceUnamused = farFaceUnamused;
    farFaceViewfinder = farFaceViewfinder;
    farFaceVomit = farFaceVomit;
    farFaceWeary = farFaceWeary;
    farFaceWoozy = farFaceWoozy;
    farFaceWorried = farFaceWorried;
    farFaceZany = farFaceZany;
    farFaceZipper = farFaceZipper;
    farFalafel = farFalafel;
    farFamily = farFamily;
    farFamilyDress = farFamilyDress;
    farFamilyPants = farFamilyPants;
    farFan = farFan;
    farFanTable = farFanTable;
    farFantasyFlightGames = farFantasyFlightGames;
    farFarm = farFarm;
    farFastBackward = farFastBackward;
    farFastForward = farFastForward;
    farFaucet = farFaucet;
    farFaucetDrip = farFaucetDrip;
    farFax = farFax;
    farFeather = farFeather;
    farFeatherAlt = farFeatherAlt;
    farFeatherPointed = farFeatherPointed;
    farFedex = farFedex;
    farFedora = farFedora;
    farFeed = farFeed;
    farFemale = farFemale;
    farFence = farFence;
    farFerrisWheel = farFerrisWheel;
    farFerry = farFerry;
    farFieldHockey = farFieldHockey;
    farFieldHockeyStickBall = farFieldHockeyStickBall;
    farFighterJet = farFighterJet;
    farFigma = farFigma;
    farFile = farFile;
    farFileAlt = farFileAlt;
    farFileArchive = farFileArchive;
    farFileArrowDown = farFileArrowDown;
    farFileArrowUp = farFileArrowUp;
    farFileAudio = farFileAudio;
    farFileAward = farFileAward;
    farFileBinary = farFileBinary;
    farFileCaretDown = farFileCaretDown;
    farFileCaretUp = farFileCaretUp;
    farFileCertificate = farFileCertificate;
    farFileChartColumn = farFileChartColumn;
    farFileChartLine = farFileChartLine;
    farFileChartPie = farFileChartPie;
    farFileCheck = farFileCheck;
    farFileCircleCheck = farFileCircleCheck;
    farFileCircleExclamation = farFileCircleExclamation;
    farFileCircleInfo = farFileCircleInfo;
    farFileCircleMinus = farFileCircleMinus;
    farFileCirclePlus = farFileCirclePlus;
    farFileCircleQuestion = farFileCircleQuestion;
    farFileCircleXmark = farFileCircleXmark;
    farFileClipboard = farFileClipboard;
    farFileCode = farFileCode;
    farFileContract = farFileContract;
    farFileCsv = farFileCsv;
    farFileDashedLine = farFileDashedLine;
    farFileDoc = farFileDoc;
    farFileDownload = farFileDownload;
    farFileEdit = farFileEdit;
    farFileEps = farFileEps;
    farFileExcel = farFileExcel;
    farFileExclamation = farFileExclamation;
    farFileExport = farFileExport;
    farFileGif = farFileGif;
    farFileHeart = farFileHeart;
    farFileImage = farFileImage;
    farFileImport = farFileImport;
    farFileInvoice = farFileInvoice;
    farFileInvoiceDollar = farFileInvoiceDollar;
    farFileJpg = farFileJpg;
    farFileLines = farFileLines;
    farFileLock = farFileLock;
    farFileMagnifyingGlass = farFileMagnifyingGlass;
    farFileMedical = farFileMedical;
    farFileMedicalAlt = farFileMedicalAlt;
    farFileMinus = farFileMinus;
    farFileMov = farFileMov;
    farFileMp3 = farFileMp3;
    farFileMp4 = farFileMp4;
    farFileMusic = farFileMusic;
    farFilePdf = farFilePdf;
    farFilePen = farFilePen;
    farFilePlus = farFilePlus;
    farFilePlusMinus = farFilePlusMinus;
    farFilePng = farFilePng;
    farFilePowerpoint = farFilePowerpoint;
    farFilePpt = farFilePpt;
    farFilePrescription = farFilePrescription;
    farFiles = farFiles;
    farFileSearch = farFileSearch;
    farFileShield = farFileShield;
    farFileSignature = farFileSignature;
    farFileSlash = farFileSlash;
    farFilesMedical = farFilesMedical;
    farFileSpreadsheet = farFileSpreadsheet;
    farFileSvg = farFileSvg;
    farFileText = farFileText;
    farFileTimes = farFileTimes;
    farFileUpload = farFileUpload;
    farFileUser = farFileUser;
    farFileVector = farFileVector;
    farFileVideo = farFileVideo;
    farFileWaveform = farFileWaveform;
    farFileWord = farFileWord;
    farFileXls = farFileXls;
    farFileXmark = farFileXmark;
    farFileXml = farFileXml;
    farFileZip = farFileZip;
    farFileZipper = farFileZipper;
    farFill = farFill;
    farFillDrip = farFillDrip;
    farFilm = farFilm;
    farFilmAlt = farFilmAlt;
    farFilmCanister = farFilmCanister;
    farFilmCannister = farFilmCannister;
    farFilms = farFilms;
    farFilmSimple = farFilmSimple;
    farFilmSlash = farFilmSlash;
    farFilter = farFilter;
    farFilterCircleDollar = farFilterCircleDollar;
    farFilterCircleXmark = farFilterCircleXmark;
    farFilterList = farFilterList;
    farFilters = farFilters;
    farFilterSlash = farFilterSlash;
    farFingerprint = farFingerprint;
    farFire = farFire;
    farFireAlt = farFireAlt;
    farFireBurner = farFireBurner;
    farFireExtinguisher = farFireExtinguisher;
    farFireFlame = farFireFlame;
    farFireFlameCurved = farFireFlameCurved;
    farFireFlameSimple = farFireFlameSimple;
    farFirefox = farFirefox;
    farFirefoxBrowser = farFirefoxBrowser;
    farFireHydrant = farFireHydrant;
    farFireplace = farFireplace;
    farFireSmoke = farFireSmoke;
    farFirewall = farFirewall;
    farFirstAid = farFirstAid;
    farFirstdraft = farFirstdraft;
    farFirstOrder = farFirstOrder;
    farFirstOrderAlt = farFirstOrderAlt;
    farFish = farFish;
    farFishBones = farFishBones;
    farFishCooked = farFishCooked;
    farFishFins = farFishFins;
    farFishingRod = farFishingRod;
    farFistRaised = farFistRaised;
    farFlag = farFlag;
    farFlagAlt = farFlagAlt;
    farFlagCheckered = farFlagCheckered;
    farFlagPennant = farFlagPennant;
    farFlagSwallowtail = farFlagSwallowtail;
    farFlagUsa = farFlagUsa;
    farFlame = farFlame;
    farFlashlight = farFlashlight;
    farFlask = farFlask;
    farFlaskGear = farFlaskGear;
    farFlaskPoison = farFlaskPoison;
    farFlaskPotion = farFlaskPotion;
    farFlaskRoundPoison = farFlaskRoundPoison;
    farFlaskRoundPotion = farFlaskRoundPotion;
    farFlaskVial = farFlaskVial;
    farFlatbread = farFlatbread;
    farFlatbreadStuffed = farFlatbreadStuffed;
    farFlickr = farFlickr;
    farFlipboard = farFlipboard;
    farFloppyDisk = farFloppyDisk;
    farFloppyDiskCircleArrowRight = farFloppyDiskCircleArrowRight;
    farFloppyDiskCircleXmark = farFloppyDiskCircleXmark;
    farFloppyDiskPen = farFloppyDiskPen;
    farFloppyDisks = farFloppyDisks;
    farFloppyDiskTimes = farFloppyDiskTimes;
    farFlorinSign = farFlorinSign;
    farFlower = farFlower;
    farFlowerDaffodil = farFlowerDaffodil;
    farFlowerTulip = farFlowerTulip;
    farFlushed = farFlushed;
    farFlute = farFlute;
    farFluxCapacitor = farFluxCapacitor;
    farFly = farFly;
    farFlyingDisc = farFlyingDisc;
    farFog = farFog;
    farFolder = farFolder;
    farFolderArrowDown = farFolderArrowDown;
    farFolderArrowUp = farFolderArrowUp;
    farFolderBlank = farFolderBlank;
    farFolderBookmark = farFolderBookmark;
    farFolderCheck = farFolderCheck;
    farFolderClosed = farFolderClosed;
    farFolderCog = farFolderCog;
    farFolderDownload = farFolderDownload;
    farFolderGear = farFolderGear;
    farFolderGrid = farFolderGrid;
    farFolderHeart = farFolderHeart;
    farFolderImage = farFolderImage;
    farFolderMagnifyingGlass = farFolderMagnifyingGlass;
    farFolderMedical = farFolderMedical;
    farFolderMinus = farFolderMinus;
    farFolderMusic = farFolderMusic;
    farFolderOpen = farFolderOpen;
    farFolderPlus = farFolderPlus;
    farFolders = farFolders;
    farFolderSearch = farFolderSearch;
    farFolderTimes = farFolderTimes;
    farFolderTree = farFolderTree;
    farFolderUpload = farFolderUpload;
    farFolderUser = farFolderUser;
    farFolderXmark = farFolderXmark;
    farFonduePot = farFonduePot;
    farFont = farFont;
    farFontAwesome = farFontAwesome;
    farFontAwesomeAlt = farFontAwesomeAlt;
    farFontAwesomeFlag = farFontAwesomeFlag;
    farFontAwesomeLogoFull = farFontAwesomeLogoFull;
    farFontCase = farFontCase;
    farFonticons = farFonticons;
    farFonticonsFi = farFonticonsFi;
    farFootball = farFootball;
    farFootballBall = farFootballBall;
    farFootballHelmet = farFootballHelmet;
    farFork = farFork;
    farForkKnife = farForkKnife;
    farForklift = farForklift;
    farFort = farFort;
    farFortAwesome = farFortAwesome;
    farFortAwesomeAlt = farFortAwesomeAlt;
    farForumbee = farForumbee;
    farForward = farForward;
    farForwardFast = farForwardFast;
    farForwardStep = farForwardStep;
    farFoursquare = farFoursquare;
    farFragile = farFragile;
    farFrame = farFrame;
    farFrancSign = farFrancSign;
    farFreebsd = farFreebsd;
    farFreeCodeCamp = farFreeCodeCamp;
    farFrenchFries = farFrenchFries;
    farFrog = farFrog;
    farFrostyHead = farFrostyHead;
    farFrown = farFrown;
    farFrownOpen = farFrownOpen;
    farFulcrum = farFulcrum;
    farFunction = farFunction;
    farFunnelDollar = farFunnelDollar;
    farFutbol = farFutbol;
    farFutbolBall = farFutbolBall;
    farG = farG;
    farGalacticRepublic = farGalacticRepublic;
    farGalacticSenate = farGalacticSenate;
    farGalaxy = farGalaxy;
    farGalleryThumbnails = farGalleryThumbnails;
    farGameBoard = farGameBoard;
    farGameBoardAlt = farGameBoardAlt;
    farGameBoardSimple = farGameBoardSimple;
    farGameConsoleHandheld = farGameConsoleHandheld;
    farGameConsoleHandheldCrank = farGameConsoleHandheldCrank;
    farGamepad = farGamepad;
    farGamepadAlt = farGamepadAlt;
    farGamepadModern = farGamepadModern;
    farGarage = farGarage;
    farGarageCar = farGarageCar;
    farGarageOpen = farGarageOpen;
    farGarlic = farGarlic;
    farGasPump = farGasPump;
    farGasPumpSlash = farGasPumpSlash;
    farGauge = farGauge;
    farGaugeCircleBolt = farGaugeCircleBolt;
    farGaugeCircleMinus = farGaugeCircleMinus;
    farGaugeCirclePlus = farGaugeCirclePlus;
    farGaugeHigh = farGaugeHigh;
    farGaugeLow = farGaugeLow;
    farGaugeMax = farGaugeMax;
    farGaugeMed = farGaugeMed;
    farGaugeMin = farGaugeMin;
    farGaugeSimple = farGaugeSimple;
    farGaugeSimpleHigh = farGaugeSimpleHigh;
    farGaugeSimpleLow = farGaugeSimpleLow;
    farGaugeSimpleMax = farGaugeSimpleMax;
    farGaugeSimpleMed = farGaugeSimpleMed;
    farGaugeSimpleMin = farGaugeSimpleMin;
    farGaveDandy = farGaveDandy;
    farGavel = farGavel;
    farGbp = farGbp;
    farGear = farGear;
    farGearCode = farGearCode;
    farGearComplex = farGearComplex;
    farGearComplexCode = farGearComplexCode;
    farGears = farGears;
    farGem = farGem;
    farGenderless = farGenderless;
    farGetPocket = farGetPocket;
    farGg = farGg;
    farGgCircle = farGgCircle;
    farGhost = farGhost;
    farGif = farGif;
    farGift = farGift;
    farGiftCard = farGiftCard;
    farGifts = farGifts;
    farGingerbreadMan = farGingerbreadMan;
    farGit = farGit;
    farGitAlt = farGitAlt;
    farGithub = farGithub;
    farGithubAlt = farGithubAlt;
    farGithubSquare = farGithubSquare;
    farGitkraken = farGitkraken;
    farGitlab = farGitlab;
    farGitlabSquare = farGitlabSquare;
    farGitSquare = farGitSquare;
    farGitter = farGitter;
    farGlass = farGlass;
    farGlassChampagne = farGlassChampagne;
    farGlassCheers = farGlassCheers;
    farGlassCitrus = farGlassCitrus;
    farGlassEmpty = farGlassEmpty;
    farGlasses = farGlasses;
    farGlassesAlt = farGlassesAlt;
    farGlassesRound = farGlassesRound;
    farGlassHalf = farGlassHalf;
    farGlassHalfEmpty = farGlassHalfEmpty;
    farGlassHalfFull = farGlassHalfFull;
    farGlassMartini = farGlassMartini;
    farGlassMartiniAlt = farGlassMartiniAlt;
    farGlassWater = farGlassWater;
    farGlassWaterDroplet = farGlassWaterDroplet;
    farGlassWhiskey = farGlassWhiskey;
    farGlassWhiskeyRocks = farGlassWhiskeyRocks;
    farGlide = farGlide;
    farGlideG = farGlideG;
    farGlobe = farGlobe;
    farGlobeAfrica = farGlobeAfrica;
    farGlobeAmericas = farGlobeAmericas;
    farGlobeAsia = farGlobeAsia;
    farGlobeEurope = farGlobeEurope;
    farGlobeOceania = farGlobeOceania;
    farGlobePointer = farGlobePointer;
    farGlobeSnow = farGlobeSnow;
    farGlobeStand = farGlobeStand;
    farGloveBoxing = farGloveBoxing;
    farGoalNet = farGoalNet;
    farGofore = farGofore;
    farGolang = farGolang;
    farGolfBall = farGolfBall;
    farGolfBallTee = farGolfBallTee;
    farGolfClub = farGolfClub;
    farGolfFlagHole = farGolfFlagHole;
    farGoodreads = farGoodreads;
    farGoodreadsG = farGoodreadsG;
    farGoogle = farGoogle;
    farGoogleDrive = farGoogleDrive;
    farGooglePay = farGooglePay;
    farGooglePlay = farGooglePlay;
    farGooglePlus = farGooglePlus;
    farGooglePlusG = farGooglePlusG;
    farGooglePlusSquare = farGooglePlusSquare;
    farGoogleScholar = farGoogleScholar;
    farGoogleWallet = farGoogleWallet;
    farGopuram = farGopuram;
    farGraduationCap = farGraduationCap;
    farGramophone = farGramophone;
    farGrapes = farGrapes;
    farGrate = farGrate;
    farGrateDroplet = farGrateDroplet;
    farGratipay = farGratipay;
    farGrav = farGrav;
    farGreaterThan = farGreaterThan;
    farGreaterThanEqual = farGreaterThanEqual;
    farGrid = farGrid;
    farGrid2 = farGrid2;
    farGrid2Plus = farGrid2Plus;
    farGrid4 = farGrid4;
    farGrid5 = farGrid5;
    farGrid3 = farGrid3;
    farGridDividers = farGridDividers;
    farGridHorizontal = farGridHorizontal;
    farGridRound = farGridRound;
    farGridRound2 = farGridRound2;
    farGridRound2Plus = farGridRound2Plus;
    farGridRound4 = farGridRound4;
    farGridRound5 = farGridRound5;
    farGrill = farGrill;
    farGrillFire = farGrillFire;
    farGrillHot = farGrillHot;
    farGrimace = farGrimace;
    farGrin = farGrin;
    farGrinAlt = farGrinAlt;
    farGrinBeam = farGrinBeam;
    farGrinBeamSweat = farGrinBeamSweat;
    farGrinHearts = farGrinHearts;
    farGrinSquint = farGrinSquint;
    farGrinSquintTears = farGrinSquintTears;
    farGrinStars = farGrinStars;
    farGrinTears = farGrinTears;
    farGrinTongue = farGrinTongue;
    farGrinTongueSquint = farGrinTongueSquint;
    farGrinTongueWink = farGrinTongueWink;
    farGrinWink = farGrinWink;
    farGrip = farGrip;
    farGripDots = farGripDots;
    farGripDotsVertical = farGripDotsVertical;
    farGripfire = farGripfire;
    farGripHorizontal = farGripHorizontal;
    farGripLines = farGripLines;
    farGripLinesVertical = farGripLinesVertical;
    farGripVertical = farGripVertical;
    farGroupArrowsRotate = farGroupArrowsRotate;
    farGrunt = farGrunt;
    farGuaraniSign = farGuaraniSign;
    farGuilded = farGuilded;
    farGuitar = farGuitar;
    farGuitarElectric = farGuitarElectric;
    farGuitars = farGuitars;
    farGulp = farGulp;
    farGun = farGun;
    farGunSlash = farGunSlash;
    farGunSquirt = farGunSquirt;
    farH = farH;
    farH1 = farH1;
    farH2 = farH2;
    farH3 = farH3;
    farH4 = farH4;
    farH5 = farH5;
    farH6 = farH6;
    farHackerNews = farHackerNews;
    farHackerNewsSquare = farHackerNewsSquare;
    farHackerrank = farHackerrank;
    farHamburger = farHamburger;
    farHammer = farHammer;
    farHammerBrush = farHammerBrush;
    farHammerCrash = farHammerCrash;
    farHammerWar = farHammerWar;
    farHamsa = farHamsa;
    farHand = farHand;
    farHandBackFist = farHandBackFist;
    farHandBackPointDown = farHandBackPointDown;
    farHandBackPointLeft = farHandBackPointLeft;
    farHandBackPointRibbon = farHandBackPointRibbon;
    farHandBackPointRight = farHandBackPointRight;
    farHandBackPointUp = farHandBackPointUp;
    farHandcuffs = farHandcuffs;
    farHandDots = farHandDots;
    farHandFingersCrossed = farHandFingersCrossed;
    farHandFist = farHandFist;
    farHandHeart = farHandHeart;
    farHandHolding = farHandHolding;
    farHandHoldingBox = farHandHoldingBox;
    farHandHoldingCircleDollar = farHandHoldingCircleDollar;
    farHandHoldingDollar = farHandHoldingDollar;
    farHandHoldingDroplet = farHandHoldingDroplet;
    farHandHoldingHand = farHandHoldingHand;
    farHandHoldingHeart = farHandHoldingHeart;
    farHandHoldingMagic = farHandHoldingMagic;
    farHandHoldingMedical = farHandHoldingMedical;
    farHandHoldingSeedling = farHandHoldingSeedling;
    farHandHoldingSkull = farHandHoldingSkull;
    farHandHoldingUsd = farHandHoldingUsd;
    farHandHoldingWater = farHandHoldingWater;
    farHandHorns = farHandHorns;
    farHandLizard = farHandLizard;
    farHandLove = farHandLove;
    farHandMiddleFinger = farHandMiddleFinger;
    farHandPaper = farHandPaper;
    farHandPeace = farHandPeace;
    farHandPointDown = farHandPointDown;
    farHandPointer = farHandPointer;
    farHandPointLeft = farHandPointLeft;
    farHandPointRibbon = farHandPointRibbon;
    farHandPointRight = farHandPointRight;
    farHandPointUp = farHandPointUp;
    farHandReceiving = farHandReceiving;
    farHandRock = farHandRock;
    farHands = farHands;
    farHandsAmericanSignLanguageInterpreting = farHandsAmericanSignLanguageInterpreting;
    farHandsAslInterpreting = farHandsAslInterpreting;
    farHandsBound = farHandsBound;
    farHandsBubbles = farHandsBubbles;
    farHandScissors = farHandScissors;
    farHandsClapping = farHandsClapping;
    farHandshake = farHandshake;
    farHandshakeAlt = farHandshakeAlt;
    farHandshakeAltSlash = farHandshakeAltSlash;
    farHandshakeAngle = farHandshakeAngle;
    farHandshakeSimple = farHandshakeSimple;
    farHandshakeSimpleSlash = farHandshakeSimpleSlash;
    farHandshakeSlash = farHandshakeSlash;
    farHandsHeart = farHandsHeart;
    farHandsHelping = farHandsHelping;
    farHandsHolding = farHandsHolding;
    farHandsHoldingChild = farHandsHoldingChild;
    farHandsHoldingCircle = farHandsHoldingCircle;
    farHandsHoldingDiamond = farHandsHoldingDiamond;
    farHandsHoldingDollar = farHandsHoldingDollar;
    farHandsHoldingHeart = farHandsHoldingHeart;
    farHandSparkles = farHandSparkles;
    farHandSpock = farHandSpock;
    farHandsPraying = farHandsPraying;
    farHandsUsd = farHandsUsd;
    farHandsWash = farHandsWash;
    farHandWave = farHandWave;
    farHanukiah = farHanukiah;
    farHardDrive = farHardDrive;
    farHardHat = farHardHat;
    farHardOfHearing = farHardOfHearing;
    farHashnode = farHashnode;
    farHashtag = farHashtag;
    farHashtagLock = farHashtagLock;
    farHatBeach = farHatBeach;
    farHatChef = farHatChef;
    farHatCowboy = farHatCowboy;
    farHatCowboySide = farHatCowboySide;
    farHatHard = farHatHard;
    farHatSanta = farHatSanta;
    farHatWinter = farHatWinter;
    farHatWitch = farHatWitch;
    farHatWizard = farHatWizard;
    farHaykal = farHaykal;
    farHdd = farHdd;
    farHeader = farHeader;
    farHeading = farHeading;
    farHeadphones = farHeadphones;
    farHeadphonesAlt = farHeadphonesAlt;
    farHeadphonesSimple = farHeadphonesSimple;
    farHeadset = farHeadset;
    farHeadSide = farHeadSide;
    farHeadSideBrain = farHeadSideBrain;
    farHeadSideCough = farHeadSideCough;
    farHeadSideCoughSlash = farHeadSideCoughSlash;
    farHeadSideGear = farHeadSideGear;
    farHeadSideGoggles = farHeadSideGoggles;
    farHeadSideHeadphones = farHeadSideHeadphones;
    farHeadSideHeart = farHeadSideHeart;
    farHeadSideMask = farHeadSideMask;
    farHeadSideMedical = farHeadSideMedical;
    farHeadSideVirus = farHeadSideVirus;
    farHeadVr = farHeadVr;
    farHeart = farHeart;
    farHeartbeat = farHeartbeat;
    farHeartBroken = farHeartBroken;
    farHeartCircle = farHeartCircle;
    farHeartCircleBolt = farHeartCircleBolt;
    farHeartCircleCheck = farHeartCircleCheck;
    farHeartCircleExclamation = farHeartCircleExclamation;
    farHeartCircleMinus = farHeartCircleMinus;
    farHeartCirclePlus = farHeartCirclePlus;
    farHeartCircleXmark = farHeartCircleXmark;
    farHeartCrack = farHeartCrack;
    farHeartHalf = farHeartHalf;
    farHeartHalfAlt = farHeartHalfAlt;
    farHeartHalfStroke = farHeartHalfStroke;
    farHeartMusicCameraBolt = farHeartMusicCameraBolt;
    farHeartPulse = farHeartPulse;
    farHeartRate = farHeartRate;
    farHeartSquare = farHeartSquare;
    farHeat = farHeat;
    farHelicopter = farHelicopter;
    farHelicopterSymbol = farHelicopterSymbol;
    farHelmetBattle = farHelmetBattle;
    farHelmetSafety = farHelmetSafety;
    farHelmetUn = farHelmetUn;
    farHexagon = farHexagon;
    farHexagonCheck = farHexagonCheck;
    farHexagonDivide = farHexagonDivide;
    farHexagonExclamation = farHexagonExclamation;
    farHexagonImage = farHexagonImage;
    farHexagonMinus = farHexagonMinus;
    farHexagonPlus = farHexagonPlus;
    farHexagonVerticalNft = farHexagonVerticalNft;
    farHexagonVerticalNftSlanted = farHexagonVerticalNftSlanted;
    farHexagonXmark = farHexagonXmark;
    farHighDefinition = farHighDefinition;
    farHighlighter = farHighlighter;
    farHighlighterLine = farHighlighterLine;
    farHiking = farHiking;
    farHillAvalanche = farHillAvalanche;
    farHillRockslide = farHillRockslide;
    farHippo = farHippo;
    farHips = farHips;
    farHireAHelper = farHireAHelper;
    farHistory = farHistory;
    farHive = farHive;
    farHockeyMask = farHockeyMask;
    farHockeyPuck = farHockeyPuck;
    farHockeyStickPuck = farHockeyStickPuck;
    farHockeySticks = farHockeySticks;
    farHollyBerry = farHollyBerry;
    farHome = farHome;
    farHomeAlt = farHomeAlt;
    farHomeBlank = farHomeBlank;
    farHomeHeart = farHomeHeart;
    farHomeLg = farHomeLg;
    farHomeLgAlt = farHomeLgAlt;
    farHomeUser = farHomeUser;
    farHoneyPot = farHoneyPot;
    farHoodCloak = farHoodCloak;
    farHooli = farHooli;
    farHorizontalRule = farHorizontalRule;
    farHornbill = farHornbill;
    farHorse = farHorse;
    farHorseHead = farHorseHead;
    farHorseSaddle = farHorseSaddle;
    farHose = farHose;
    farHoseReel = farHoseReel;
    farHospital = farHospital;
    farHospitalAlt = farHospitalAlt;
    farHospitals = farHospitals;
    farHospitalSymbol = farHospitalSymbol;
    farHospitalUser = farHospitalUser;
    farHospitalWide = farHospitalWide;
    farHotdog = farHotdog;
    farHotel = farHotel;
    farHotjar = farHotjar;
    farHotTub = farHotTub;
    farHotTubPerson = farHotTubPerson;
    farHourglass = farHourglass;
    farHourglass1 = farHourglass1;
    farHourglass2 = farHourglass2;
    farHourglass3 = farHourglass3;
    farHourglassClock = farHourglassClock;
    farHourglassEmpty = farHourglassEmpty;
    farHourglassEnd = farHourglassEnd;
    farHourglassHalf = farHourglassHalf;
    farHourglassStart = farHourglassStart;
    farHouse = farHouse;
    farHouseBlank = farHouseBlank;
    farHouseBuilding = farHouseBuilding;
    farHouseChimney = farHouseChimney;
    farHouseChimneyBlank = farHouseChimneyBlank;
    farHouseChimneyCrack = farHouseChimneyCrack;
    farHouseChimneyHeart = farHouseChimneyHeart;
    farHouseChimneyMedical = farHouseChimneyMedical;
    farHouseChimneyUser = farHouseChimneyUser;
    farHouseChimneyWindow = farHouseChimneyWindow;
    farHouseCircleCheck = farHouseCircleCheck;
    farHouseCircleExclamation = farHouseCircleExclamation;
    farHouseCircleXmark = farHouseCircleXmark;
    farHouseCrack = farHouseCrack;
    farHouseDamage = farHouseDamage;
    farHouseDay = farHouseDay;
    farHouseFire = farHouseFire;
    farHouseFlag = farHouseFlag;
    farHouseFlood = farHouseFlood;
    farHouseFloodWater = farHouseFloodWater;
    farHouseFloodWaterCircleArrowRight = farHouseFloodWaterCircleArrowRight;
    farHouseHeart = farHouseHeart;
    farHouseLaptop = farHouseLaptop;
    farHouseLeave = farHouseLeave;
    farHouseLock = farHouseLock;
    farHouseMedical = farHouseMedical;
    farHouseMedicalCircleCheck = farHouseMedicalCircleCheck;
    farHouseMedicalCircleExclamation = farHouseMedicalCircleExclamation;
    farHouseMedicalCircleXmark = farHouseMedicalCircleXmark;
    farHouseMedicalFlag = farHouseMedicalFlag;
    farHouseNight = farHouseNight;
    farHousePersonArrive = farHousePersonArrive;
    farHousePersonDepart = farHousePersonDepart;
    farHousePersonLeave = farHousePersonLeave;
    farHousePersonReturn = farHousePersonReturn;
    farHouseReturn = farHouseReturn;
    farHouseSignal = farHouseSignal;
    farHouseTree = farHouseTree;
    farHouseTsunami = farHouseTsunami;
    farHouseTurret = farHouseTurret;
    farHouseUser = farHouseUser;
    farHouseWater = farHouseWater;
    farHouseWindow = farHouseWindow;
    farHouzz = farHouzz;
    farHryvnia = farHryvnia;
    farHryvniaSign = farHryvniaSign;
    farHSquare = farHSquare;
    farHtml5 = farHtml5;
    farHubspot = farHubspot;
    farHumidity = farHumidity;
    farHundredPoints = farHundredPoints;
    farHurricane = farHurricane;
    farHyphen = farHyphen;
    farI = farI;
    farIceCream = farIceCream;
    farIceSkate = farIceSkate;
    farIcicles = farIcicles;
    farIcons = farIcons;
    farIconsAlt = farIconsAlt;
    farICursor = farICursor;
    farIdBadge = farIdBadge;
    farIdCard = farIdCard;
    farIdCardAlt = farIdCardAlt;
    farIdCardClip = farIdCardClip;
    farIdeal = farIdeal;
    farIgloo = farIgloo;
    farIls = farIls;
    farImage = farImage;
    farImageLandscape = farImageLandscape;
    farImagePolaroid = farImagePolaroid;
    farImagePolaroidUser = farImagePolaroidUser;
    farImagePortrait = farImagePortrait;
    farImages = farImages;
    farImageSlash = farImageSlash;
    farImagesUser = farImagesUser;
    farImageUser = farImageUser;
    farImdb = farImdb;
    farInbox = farInbox;
    farInboxArrowDown = farInboxArrowDown;
    farInboxArrowUp = farInboxArrowUp;
    farInboxes = farInboxes;
    farInboxFull = farInboxFull;
    farInboxIn = farInboxIn;
    farInboxOut = farInboxOut;
    farIndent = farIndent;
    farIndianRupee = farIndianRupee;
    farIndianRupeeSign = farIndianRupeeSign;
    farIndustry = farIndustry;
    farIndustryAlt = farIndustryAlt;
    farIndustryWindows = farIndustryWindows;
    farInfinity = farInfinity;
    farInfo = farInfo;
    farInfoCircle = farInfoCircle;
    farInfoSquare = farInfoSquare;
    farInhaler = farInhaler;
    farInnosoft = farInnosoft;
    farInputNumeric = farInputNumeric;
    farInputPipe = farInputPipe;
    farInputText = farInputText;
    farInr = farInr;
    farInstagram = farInstagram;
    farInstagramSquare = farInstagramSquare;
    farInstalod = farInstalod;
    farInstitution = farInstitution;
    farIntegral = farIntegral;
    farIntercom = farIntercom;
    farInternetExplorer = farInternetExplorer;
    farInterrobang = farInterrobang;
    farIntersection = farIntersection;
    farInventory = farInventory;
    farInvision = farInvision;
    farIoxhost = farIoxhost;
    farIslandTreePalm = farIslandTreePalm;
    farIslandTropical = farIslandTropical;
    farItalic = farItalic;
    farItchIo = farItchIo;
    farItunes = farItunes;
    farItunesNote = farItunesNote;
    farJ = farJ;
    farJackOLantern = farJackOLantern;
    farJar = farJar;
    farJarWheat = farJarWheat;
    farJava = farJava;
    farJedi = farJedi;
    farJediOrder = farJediOrder;
    farJenkins = farJenkins;
    farJetFighter = farJetFighter;
    farJetFighterUp = farJetFighterUp;
    farJira = farJira;
    farJoget = farJoget;
    farJoint = farJoint;
    farJoomla = farJoomla;
    farJournalWhills = farJournalWhills;
    farJoystick = farJoystick;
    farJpy = farJpy;
    farJs = farJs;
    farJsfiddle = farJsfiddle;
    farJsSquare = farJsSquare;
    farJug = farJug;
    farJugBottle = farJugBottle;
    farJugDetergent = farJugDetergent;
    farK = farK;
    farKaaba = farKaaba;
    farKaggle = farKaggle;
    farKazoo = farKazoo;
    farKerning = farKerning;
    farKey = farKey;
    farKeybase = farKeybase;
    farKeyboard = farKeyboard;
    farKeyboardBrightness = farKeyboardBrightness;
    farKeyboardBrightnessLow = farKeyboardBrightnessLow;
    farKeyboardDown = farKeyboardDown;
    farKeyboardLeft = farKeyboardLeft;
    farKeycdn = farKeycdn;
    farKeynote = farKeynote;
    farKeySkeleton = farKeySkeleton;
    farKeySkeletonLeftRight = farKeySkeletonLeftRight;
    farKhanda = farKhanda;
    farKickstarter = farKickstarter;
    farKickstarterK = farKickstarterK;
    farKidneys = farKidneys;
    farKipSign = farKipSign;
    farKiss = farKiss;
    farKissBeam = farKissBeam;
    farKissWinkHeart = farKissWinkHeart;
    farKitchenSet = farKitchenSet;
    farKite = farKite;
    farKitMedical = farKitMedical;
    farKiwiBird = farKiwiBird;
    farKiwiFruit = farKiwiFruit;
    farKnife = farKnife;
    farKnifeKitchen = farKnifeKitchen;
    farKorvue = farKorvue;
    farKrw = farKrw;
    farL = farL;
    farLacrosseStick = farLacrosseStick;
    farLacrosseStickBall = farLacrosseStickBall;
    farLadderWater = farLadderWater;
    farLambda = farLambda;
    farLamp = farLamp;
    farLampDesk = farLampDesk;
    farLampFloor = farLampFloor;
    farLampStreet = farLampStreet;
    farLandmark = farLandmark;
    farLandmarkAlt = farLandmarkAlt;
    farLandmarkDome = farLandmarkDome;
    farLandmarkFlag = farLandmarkFlag;
    farLandmarkMagnifyingGlass = farLandmarkMagnifyingGlass;
    farLandMineOn = farLandMineOn;
    farLandscape = farLandscape;
    farLanguage = farLanguage;
    farLaptop = farLaptop;
    farLaptopArrowDown = farLaptopArrowDown;
    farLaptopBinary = farLaptopBinary;
    farLaptopCode = farLaptopCode;
    farLaptopFile = farLaptopFile;
    farLaptopHouse = farLaptopHouse;
    farLaptopMedical = farLaptopMedical;
    farLaptopMobile = farLaptopMobile;
    farLaptopSlash = farLaptopSlash;
    farLaravel = farLaravel;
    farLariSign = farLariSign;
    farLasso = farLasso;
    farLassoSparkles = farLassoSparkles;
    farLastfm = farLastfm;
    farLastfmSquare = farLastfmSquare;
    farLaugh = farLaugh;
    farLaughBeam = farLaughBeam;
    farLaughSquint = farLaughSquint;
    farLaughWink = farLaughWink;
    farLayerGroup = farLayerGroup;
    farLayerGroupMinus = farLayerGroupMinus;
    farLayerGroupPlus = farLayerGroupPlus;
    farLayerMinus = farLayerMinus;
    farLayerPlus = farLayerPlus;
    farLeaf = farLeaf;
    farLeafHeart = farLeafHeart;
    farLeafMaple = farLeafMaple;
    farLeafOak = farLeafOak;
    farLeafyGreen = farLeafyGreen;
    farLeanpub = farLeanpub;
    farLeft = farLeft;
    farLeftFromLine = farLeftFromLine;
    farLeftLong = farLeftLong;
    farLeftLongToLine = farLeftLongToLine;
    farLeftRight = farLeftRight;
    farLeftToLine = farLeftToLine;
    farLegal = farLegal;
    farLemon = farLemon;
    farLess = farLess;
    farLessThan = farLessThan;
    farLessThanEqual = farLessThanEqual;
    farLetterboxd = farLetterboxd;
    farLevelDown = farLevelDown;
    farLevelDownAlt = farLevelDownAlt;
    farLevelUp = farLevelUp;
    farLevelUpAlt = farLevelUpAlt;
    farLifeRing = farLifeRing;
    farLightbulb = farLightbulb;
    farLightbulbCfl = farLightbulbCfl;
    farLightbulbCflOn = farLightbulbCflOn;
    farLightbulbDollar = farLightbulbDollar;
    farLightbulbExclamation = farLightbulbExclamation;
    farLightbulbExclamationOn = farLightbulbExclamationOn;
    farLightbulbGear = farLightbulbGear;
    farLightbulbOn = farLightbulbOn;
    farLightbulbSlash = farLightbulbSlash;
    farLightCeiling = farLightCeiling;
    farLightEmergency = farLightEmergency;
    farLightEmergencyOn = farLightEmergencyOn;
    farLighthouse = farLighthouse;
    farLightsHoliday = farLightsHoliday;
    farLightSwitch = farLightSwitch;
    farLightSwitchOff = farLightSwitchOff;
    farLightSwitchOn = farLightSwitchOn;
    farLine = farLine;
    farLineChart = farLineChart;
    farLineColumns = farLineColumns;
    farLineHeight = farLineHeight;
    farLinesLeaning = farLinesLeaning;
    farLink = farLink;
    farLinkedin = farLinkedin;
    farLinkedinIn = farLinkedinIn;
    farLinkHorizontal = farLinkHorizontal;
    farLinkHorizontalSlash = farLinkHorizontalSlash;
    farLinkSimple = farLinkSimple;
    farLinkSimpleSlash = farLinkSimpleSlash;
    farLinkSlash = farLinkSlash;
    farLinode = farLinode;
    farLinux = farLinux;
    farLips = farLips;
    farLiraSign = farLiraSign;
    farList = farList;
    farList12 = farList12;
    farListAlt = farListAlt;
    farListCheck = farListCheck;
    farListDots = farListDots;
    farListDropdown = farListDropdown;
    farListMusic = farListMusic;
    farListNumeric = farListNumeric;
    farListOl = farListOl;
    farListRadio = farListRadio;
    farListSquares = farListSquares;
    farListTimeline = farListTimeline;
    farListTree = farListTree;
    farListUl = farListUl;
    farLitecoinSign = farLitecoinSign;
    farLoader = farLoader;
    farLobster = farLobster;
    farLocation = farLocation;
    farLocationArrow = farLocationArrow;
    farLocationArrowUp = farLocationArrowUp;
    farLocationCheck = farLocationCheck;
    farLocationCircle = farLocationCircle;
    farLocationCrosshairs = farLocationCrosshairs;
    farLocationCrosshairsSlash = farLocationCrosshairsSlash;
    farLocationDot = farLocationDot;
    farLocationDotSlash = farLocationDotSlash;
    farLocationExclamation = farLocationExclamation;
    farLocationMinus = farLocationMinus;
    farLocationPen = farLocationPen;
    farLocationPin = farLocationPin;
    farLocationPinLock = farLocationPinLock;
    farLocationPinSlash = farLocationPinSlash;
    farLocationPlus = farLocationPlus;
    farLocationQuestion = farLocationQuestion;
    farLocationSlash = farLocationSlash;
    farLocationSmile = farLocationSmile;
    farLocationXmark = farLocationXmark;
    farLock = farLock;
    farLockA = farLockA;
    farLockAlt = farLockAlt;
    farLockHashtag = farLockHashtag;
    farLockKeyhole = farLockKeyhole;
    farLockKeyholeOpen = farLockKeyholeOpen;
    farLockOpen = farLockOpen;
    farLockOpenAlt = farLockOpenAlt;
    farLocust = farLocust;
    farLollipop = farLollipop;
    farLollypop = farLollypop;
    farLongArrowAltDown = farLongArrowAltDown;
    farLongArrowAltLeft = farLongArrowAltLeft;
    farLongArrowAltRight = farLongArrowAltRight;
    farLongArrowAltUp = farLongArrowAltUp;
    farLongArrowDown = farLongArrowDown;
    farLongArrowLeft = farLongArrowLeft;
    farLongArrowRight = farLongArrowRight;
    farLongArrowUp = farLongArrowUp;
    farLoveseat = farLoveseat;
    farLowVision = farLowVision;
    farLuchador = farLuchador;
    farLuchadorMask = farLuchadorMask;
    farLuggageCart = farLuggageCart;
    farLungs = farLungs;
    farLungsVirus = farLungsVirus;
    farLyft = farLyft;
    farM = farM;
    farMace = farMace;
    farMagento = farMagento;
    farMagic = farMagic;
    farMagicWandSparkles = farMagicWandSparkles;
    farMagnet = farMagnet;
    farMagnifyingGlass = farMagnifyingGlass;
    farMagnifyingGlassArrowRight = farMagnifyingGlassArrowRight;
    farMagnifyingGlassArrowsRotate = farMagnifyingGlassArrowsRotate;
    farMagnifyingGlassChart = farMagnifyingGlassChart;
    farMagnifyingGlassDollar = farMagnifyingGlassDollar;
    farMagnifyingGlassLocation = farMagnifyingGlassLocation;
    farMagnifyingGlassMinus = farMagnifyingGlassMinus;
    farMagnifyingGlassMusic = farMagnifyingGlassMusic;
    farMagnifyingGlassPlay = farMagnifyingGlassPlay;
    farMagnifyingGlassPlus = farMagnifyingGlassPlus;
    farMagnifyingGlassWaveform = farMagnifyingGlassWaveform;
    farMailbox = farMailbox;
    farMailboxFlagUp = farMailboxFlagUp;
    farMailBulk = farMailBulk;
    farMailchimp = farMailchimp;
    farMailForward = farMailForward;
    farMailReply = farMailReply;
    farMailReplyAll = farMailReplyAll;
    farMakiRoll = farMakiRoll;
    farMakizushi = farMakizushi;
    farMale = farMale;
    farManatSign = farManatSign;
    farMandalorian = farMandalorian;
    farMandolin = farMandolin;
    farMango = farMango;
    farManhole = farManhole;
    farMap = farMap;
    farMapLocation = farMapLocation;
    farMapLocationDot = farMapLocationDot;
    farMapMarked = farMapMarked;
    farMapMarkedAlt = farMapMarkedAlt;
    farMapMarker = farMapMarker;
    farMapMarkerAlt = farMapMarkerAlt;
    farMapMarkerAltSlash = farMapMarkerAltSlash;
    farMapMarkerCheck = farMapMarkerCheck;
    farMapMarkerEdit = farMapMarkerEdit;
    farMapMarkerExclamation = farMapMarkerExclamation;
    farMapMarkerMinus = farMapMarkerMinus;
    farMapMarkerPlus = farMapMarkerPlus;
    farMapMarkerQuestion = farMapMarkerQuestion;
    farMapMarkerSlash = farMapMarkerSlash;
    farMapMarkerSmile = farMapMarkerSmile;
    farMapMarkerTimes = farMapMarkerTimes;
    farMapMarkerXmark = farMapMarkerXmark;
    farMapPin = farMapPin;
    farMapSigns = farMapSigns;
    farMarkdown = farMarkdown;
    farMarker = farMarker;
    farMars = farMars;
    farMarsAndVenus = farMarsAndVenus;
    farMarsAndVenusBurst = farMarsAndVenusBurst;
    farMarsDouble = farMarsDouble;
    farMarsStroke = farMarsStroke;
    farMarsStrokeH = farMarsStrokeH;
    farMarsStrokeRight = farMarsStrokeRight;
    farMarsStrokeUp = farMarsStrokeUp;
    farMarsStrokeV = farMarsStrokeV;
    farMartiniGlass = farMartiniGlass;
    farMartiniGlassCitrus = farMartiniGlassCitrus;
    farMartiniGlassEmpty = farMartiniGlassEmpty;
    farMask = farMask;
    farMaskFace = farMaskFace;
    farMaskLuchador = farMaskLuchador;
    farMaskSnorkel = farMaskSnorkel;
    farMasksTheater = farMasksTheater;
    farMaskVentilator = farMaskVentilator;
    farMastodon = farMastodon;
    farMattressPillow = farMattressPillow;
    farMaxcdn = farMaxcdn;
    farMaximize = farMaximize;
    farMdb = farMdb;
    farMeat = farMeat;
    farMedal = farMedal;
    farMedapps = farMedapps;
    farMedium = farMedium;
    farMediumM = farMediumM;
    farMedkit = farMedkit;
    farMedrt = farMedrt;
    farMeetup = farMeetup;
    farMegaphone = farMegaphone;
    farMegaport = farMegaport;
    farMeh = farMeh;
    farMehBlank = farMehBlank;
    farMehRollingEyes = farMehRollingEyes;
    farMelon = farMelon;
    farMelonSlice = farMelonSlice;
    farMemo = farMemo;
    farMemoCircleCheck = farMemoCircleCheck;
    farMemoCircleInfo = farMemoCircleInfo;
    farMemoPad = farMemoPad;
    farMemory = farMemory;
    farMendeley = farMendeley;
    farMenorah = farMenorah;
    farMercury = farMercury;
    farMerge = farMerge;
    farMessage = farMessage;
    farMessageArrowDown = farMessageArrowDown;
    farMessageArrowUp = farMessageArrowUp;
    farMessageArrowUpRight = farMessageArrowUpRight;
    farMessageBot = farMessageBot;
    farMessageCaptions = farMessageCaptions;
    farMessageCheck = farMessageCheck;
    farMessageCode = farMessageCode;
    farMessageDollar = farMessageDollar;
    farMessageDots = farMessageDots;
    farMessageEdit = farMessageEdit;
    farMessageExclamation = farMessageExclamation;
    farMessageHeart = farMessageHeart;
    farMessageImage = farMessageImage;
    farMessageLines = farMessageLines;
    farMessageMedical = farMessageMedical;
    farMessageMiddle = farMessageMiddle;
    farMessageMiddleTop = farMessageMiddleTop;
    farMessageMinus = farMessageMinus;
    farMessageMusic = farMessageMusic;
    farMessagePen = farMessagePen;
    farMessagePlus = farMessagePlus;
    farMessageQuestion = farMessageQuestion;
    farMessageQuote = farMessageQuote;
    farMessages = farMessages;
    farMessagesDollar = farMessagesDollar;
    farMessageSlash = farMessageSlash;
    farMessageSmile = farMessageSmile;
    farMessageSms = farMessageSms;
    farMessagesQuestion = farMessagesQuestion;
    farMessageText = farMessageText;
    farMessageTimes = farMessageTimes;
    farMessageXmark = farMessageXmark;
    farMessaging = farMessaging;
    farMeta = farMeta;
    farMeteor = farMeteor;
    farMeter = farMeter;
    farMeterBolt = farMeterBolt;
    farMeterDroplet = farMeterDroplet;
    farMeterFire = farMeterFire;
    farMicroblog = farMicroblog;
    farMicrochip = farMicrochip;
    farMicrochipAi = farMicrochipAi;
    farMicrophone = farMicrophone;
    farMicrophoneAlt = farMicrophoneAlt;
    farMicrophoneAltSlash = farMicrophoneAltSlash;
    farMicrophoneCircle = farMicrophoneCircle;
    farMicrophoneCircleAlt = farMicrophoneCircleAlt;
    farMicrophoneLines = farMicrophoneLines;
    farMicrophoneLinesSlash = farMicrophoneLinesSlash;
    farMicrophoneSlash = farMicrophoneSlash;
    farMicrophoneStand = farMicrophoneStand;
    farMicroscope = farMicroscope;
    farMicrosoft = farMicrosoft;
    farMicrowave = farMicrowave;
    farMillSign = farMillSign;
    farMindShare = farMindShare;
    farMinimize = farMinimize;
    farMintbit = farMintbit;
    farMinus = farMinus;
    farMinusCircle = farMinusCircle;
    farMinusHexagon = farMinusHexagon;
    farMinusLarge = farMinusLarge;
    farMinusOctagon = farMinusOctagon;
    farMinusSquare = farMinusSquare;
    farMistletoe = farMistletoe;
    farMitten = farMitten;
    farMix = farMix;
    farMixcloud = farMixcloud;
    farMixer = farMixer;
    farMizuni = farMizuni;
    farMobile = farMobile;
    farMobileAlt = farMobileAlt;
    farMobileAndroid = farMobileAndroid;
    farMobileAndroidAlt = farMobileAndroidAlt;
    farMobileButton = farMobileButton;
    farMobileIphone = farMobileIphone;
    farMobileNotch = farMobileNotch;
    farMobilePhone = farMobilePhone;
    farMobileRetro = farMobileRetro;
    farMobileScreen = farMobileScreen;
    farMobileScreenButton = farMobileScreenButton;
    farMobileSignal = farMobileSignal;
    farMobileSignalOut = farMobileSignalOut;
    farModx = farModx;
    farMonero = farMonero;
    farMoneyBill = farMoneyBill;
    farMoneyBill1 = farMoneyBill1;
    farMoneyBill1Wave = farMoneyBill1Wave;
    farMoneyBillAlt = farMoneyBillAlt;
    farMoneyBills = farMoneyBills;
    farMoneyBillsAlt = farMoneyBillsAlt;
    farMoneyBillSimple = farMoneyBillSimple;
    farMoneyBillSimpleWave = farMoneyBillSimpleWave;
    farMoneyBillsSimple = farMoneyBillsSimple;
    farMoneyBillTransfer = farMoneyBillTransfer;
    farMoneyBillTrendUp = farMoneyBillTrendUp;
    farMoneyBillWave = farMoneyBillWave;
    farMoneyBillWaveAlt = farMoneyBillWaveAlt;
    farMoneyBillWheat = farMoneyBillWheat;
    farMoneyCheck = farMoneyCheck;
    farMoneyCheckAlt = farMoneyCheckAlt;
    farMoneyCheckDollar = farMoneyCheckDollar;
    farMoneyCheckDollarPen = farMoneyCheckDollarPen;
    farMoneyCheckEdit = farMoneyCheckEdit;
    farMoneyCheckEditAlt = farMoneyCheckEditAlt;
    farMoneyCheckPen = farMoneyCheckPen;
    farMoneyFromBracket = farMoneyFromBracket;
    farMoneySimpleFromBracket = farMoneySimpleFromBracket;
    farMonitorHeartRate = farMonitorHeartRate;
    farMonitorWaveform = farMonitorWaveform;
    farMonkey = farMonkey;
    farMonument = farMonument;
    farMoon = farMoon;
    farMoonCloud = farMoonCloud;
    farMoonOverSun = farMoonOverSun;
    farMoonStars = farMoonStars;
    farMoped = farMoped;
    farMortarBoard = farMortarBoard;
    farMortarPestle = farMortarPestle;
    farMosque = farMosque;
    farMosquito = farMosquito;
    farMosquitoNet = farMosquitoNet;
    farMotorcycle = farMotorcycle;
    farMound = farMound;
    farMountain = farMountain;
    farMountainCity = farMountainCity;
    farMountains = farMountains;
    farMountainSun = farMountainSun;
    farMouse = farMouse;
    farMouseAlt = farMouseAlt;
    farMouseField = farMouseField;
    farMousePointer = farMousePointer;
    farMp3Player = farMp3Player;
    farMug = farMug;
    farMugHot = farMugHot;
    farMugMarshmallows = farMugMarshmallows;
    farMugSaucer = farMugSaucer;
    farMugTea = farMugTea;
    farMugTeaSaucer = farMugTeaSaucer;
    farMultiply = farMultiply;
    farMuseum = farMuseum;
    farMushroom = farMushroom;
    farMusic = farMusic;
    farMusicAlt = farMusicAlt;
    farMusicAltSlash = farMusicAltSlash;
    farMusicMagnifyingGlass = farMusicMagnifyingGlass;
    farMusicNote = farMusicNote;
    farMusicNoteSlash = farMusicNoteSlash;
    farMusicSlash = farMusicSlash;
    farMustache = farMustache;
    farN = farN;
    farNairaSign = farNairaSign;
    farNapster = farNapster;
    farNarwhal = farNarwhal;
    farNavicon = farNavicon;
    farNeos = farNeos;
    farNestingDolls = farNestingDolls;
    farNetworkWired = farNetworkWired;
    farNeuter = farNeuter;
    farNewspaper = farNewspaper;
    farNfc = farNfc;
    farNfcDirectional = farNfcDirectional;
    farNfcLock = farNfcLock;
    farNfcMagnifyingGlass = farNfcMagnifyingGlass;
    farNfcPen = farNfcPen;
    farNfcSignal = farNfcSignal;
    farNfcSlash = farNfcSlash;
    farNfcSymbol = farNfcSymbol;
    farNfcTrash = farNfcTrash;
    farNigiri = farNigiri;
    farNimblr = farNimblr;
    farNode = farNode;
    farNodeJs = farNodeJs;
    farNose = farNose;
    farNotdef = farNotdef;
    farNote = farNote;
    farNotebook = farNotebook;
    farNoteMedical = farNoteMedical;
    farNotEqual = farNotEqual;
    farNotes = farNotes;
    farNotesMedical = farNotesMedical;
    farNoteSticky = farNoteSticky;
    farNpm = farNpm;
    farNs8 = farNs8;
    farNutritionix = farNutritionix;
    farO = farO;
    farObjectExclude = farObjectExclude;
    farObjectGroup = farObjectGroup;
    farObjectIntersect = farObjectIntersect;
    farObjectsAlignBottom = farObjectsAlignBottom;
    farObjectsAlignCenterHorizontal = farObjectsAlignCenterHorizontal;
    farObjectsAlignCenterVertical = farObjectsAlignCenterVertical;
    farObjectsAlignLeft = farObjectsAlignLeft;
    farObjectsAlignRight = farObjectsAlignRight;
    farObjectsAlignTop = farObjectsAlignTop;
    farObjectsColumn = farObjectsColumn;
    farObjectSubtract = farObjectSubtract;
    farObjectUngroup = farObjectUngroup;
    farObjectUnion = farObjectUnion;
    farOctagon = farOctagon;
    farOctagonCheck = farOctagonCheck;
    farOctagonDivide = farOctagonDivide;
    farOctagonExclamation = farOctagonExclamation;
    farOctagonMinus = farOctagonMinus;
    farOctagonPlus = farOctagonPlus;
    farOctagonXmark = farOctagonXmark;
    farOctopusDeploy = farOctopusDeploy;
    farOdnoklassniki = farOdnoklassniki;
    farOdnoklassnikiSquare = farOdnoklassnikiSquare;
    farOdysee = farOdysee;
    farOilCan = farOilCan;
    farOilCanDrip = farOilCanDrip;
    farOilTemp = farOilTemp;
    farOilTemperature = farOilTemperature;
    farOilWell = farOilWell;
    farOldRepublic = farOldRepublic;
    farOlive = farOlive;
    farOliveBranch = farOliveBranch;
    farOm = farOm;
    farOmega = farOmega;
    farOnion = farOnion;
    farOpencart = farOpencart;
    farOpenid = farOpenid;
    farOpensuse = farOpensuse;
    farOpera = farOpera;
    farOptinMonster = farOptinMonster;
    farOption = farOption;
    farOrcid = farOrcid;
    farOrnament = farOrnament;
    farOsi = farOsi;
    farOtter = farOtter;
    farOutdent = farOutdent;
    farOutlet = farOutlet;
    farOven = farOven;
    farOverline = farOverline;
    farP = farP;
    farPadlet = farPadlet;
    farPage = farPage;
    farPage4 = farPage4;
    farPageBreak = farPageBreak;
    farPageCaretDown = farPageCaretDown;
    farPageCaretUp = farPageCaretUp;
    farPagelines = farPagelines;
    farPager = farPager;
    farPaintbrush = farPaintbrush;
    farPaintbrushAlt = farPaintbrushAlt;
    farPaintbrushFine = farPaintbrushFine;
    farPaintbrushPencil = farPaintbrushPencil;
    farPaintRoller = farPaintRoller;
    farPalette = farPalette;
    farPaletteBoxes = farPaletteBoxes;
    farPalfed = farPalfed;
    farPallet = farPallet;
    farPalletAlt = farPalletAlt;
    farPalletBox = farPalletBox;
    farPalletBoxes = farPalletBoxes;
    farPancakes = farPancakes;
    farPanelEws = farPanelEws;
    farPanelFire = farPanelFire;
    farPanFood = farPanFood;
    farPanFrying = farPanFrying;
    farPanorama = farPanorama;
    farPaperclip = farPaperclip;
    farPaperclipVertical = farPaperclipVertical;
    farPaperPlane = farPaperPlane;
    farPaperPlaneAlt = farPaperPlaneAlt;
    farPaperPlaneTop = farPaperPlaneTop;
    farParachuteBox = farParachuteBox;
    farParagraph = farParagraph;
    farParagraphLeft = farParagraphLeft;
    farParagraphRtl = farParagraphRtl;
    farParentheses = farParentheses;
    farParenthesis = farParenthesis;
    farParking = farParking;
    farParkingCircle = farParkingCircle;
    farParkingCircleSlash = farParkingCircleSlash;
    farParkingSlash = farParkingSlash;
    farPartyBack = farPartyBack;
    farPartyBell = farPartyBell;
    farPartyHorn = farPartyHorn;
    farPassport = farPassport;
    farPastafarianism = farPastafarianism;
    farPaste = farPaste;
    farPatreon = farPatreon;
    farPause = farPause;
    farPauseCircle = farPauseCircle;
    farPaw = farPaw;
    farPawAlt = farPawAlt;
    farPawClaws = farPawClaws;
    farPawSimple = farPawSimple;
    farPaypal = farPaypal;
    farPeace = farPeace;
    farPeach = farPeach;
    farPeanut = farPeanut;
    farPeanuts = farPeanuts;
    farPeapod = farPeapod;
    farPear = farPear;
    farPedestal = farPedestal;
    farPegasus = farPegasus;
    farPen = farPen;
    farPenAlt = farPenAlt;
    farPenAltSlash = farPenAltSlash;
    farPencil = farPencil;
    farPencilAlt = farPencilAlt;
    farPencilMechanical = farPencilMechanical;
    farPencilPaintbrush = farPencilPaintbrush;
    farPencilRuler = farPencilRuler;
    farPencilSlash = farPencilSlash;
    farPencilSquare = farPencilSquare;
    farPenCircle = farPenCircle;
    farPenClip = farPenClip;
    farPenClipSlash = farPenClipSlash;
    farPenFancy = farPenFancy;
    farPenFancySlash = farPenFancySlash;
    farPenField = farPenField;
    farPenLine = farPenLine;
    farPennant = farPennant;
    farPenNib = farPenNib;
    farPenNibSlash = farPenNibSlash;
    farPenPaintbrush = farPenPaintbrush;
    farPenRuler = farPenRuler;
    farPenSlash = farPenSlash;
    farPenSquare = farPenSquare;
    farPenSwirl = farPenSwirl;
    farPenToSquare = farPenToSquare;
    farPeople = farPeople;
    farPeopleArrows = farPeopleArrows;
    farPeopleArrowsLeftRight = farPeopleArrowsLeftRight;
    farPeopleCarry = farPeopleCarry;
    farPeopleCarryBox = farPeopleCarryBox;
    farPeopleDress = farPeopleDress;
    farPeopleDressSimple = farPeopleDressSimple;
    farPeopleGroup = farPeopleGroup;
    farPeopleLine = farPeopleLine;
    farPeoplePants = farPeoplePants;
    farPeoplePantsSimple = farPeoplePantsSimple;
    farPeoplePulling = farPeoplePulling;
    farPeopleRobbery = farPeopleRobbery;
    farPeopleRoof = farPeopleRoof;
    farPeopleSimple = farPeopleSimple;
    farPepper = farPepper;
    farPepperHot = farPepperHot;
    farPerbyte = farPerbyte;
    farPercent = farPercent;
    farPercentage = farPercentage;
    farPeriod = farPeriod;
    farPeriscope = farPeriscope;
    farPerson = farPerson;
    farPersonArrowDownToLine = farPersonArrowDownToLine;
    farPersonArrowUpFromLine = farPersonArrowUpFromLine;
    farPersonBiking = farPersonBiking;
    farPersonBikingMountain = farPersonBikingMountain;
    farPersonBooth = farPersonBooth;
    farPersonBreastfeeding = farPersonBreastfeeding;
    farPersonBurst = farPersonBurst;
    farPersonCane = farPersonCane;
    farPersonCarry = farPersonCarry;
    farPersonCarryBox = farPersonCarryBox;
    farPersonChalkboard = farPersonChalkboard;
    farPersonCircleCheck = farPersonCircleCheck;
    farPersonCircleExclamation = farPersonCircleExclamation;
    farPersonCircleMinus = farPersonCircleMinus;
    farPersonCirclePlus = farPersonCirclePlus;
    farPersonCircleQuestion = farPersonCircleQuestion;
    farPersonCircleXmark = farPersonCircleXmark;
    farPersonDigging = farPersonDigging;
    farPersonDolly = farPersonDolly;
    farPersonDollyEmpty = farPersonDollyEmpty;
    farPersonDotsFromLine = farPersonDotsFromLine;
    farPersonDress = farPersonDress;
    farPersonDressBurst = farPersonDressBurst;
    farPersonDressFairy = farPersonDressFairy;
    farPersonDressSimple = farPersonDressSimple;
    farPersonDrowning = farPersonDrowning;
    farPersonFairy = farPersonFairy;
    farPersonFalling = farPersonFalling;
    farPersonFallingBurst = farPersonFallingBurst;
    farPersonFromPortal = farPersonFromPortal;
    farPersonHalfDress = farPersonHalfDress;
    farPersonHarassing = farPersonHarassing;
    farPersonHiking = farPersonHiking;
    farPersonMilitaryPointing = farPersonMilitaryPointing;
    farPersonMilitaryRifle = farPersonMilitaryRifle;
    farPersonMilitaryToPerson = farPersonMilitaryToPerson;
    farPersonPinball = farPersonPinball;
    farPersonPraying = farPersonPraying;
    farPersonPregnant = farPersonPregnant;
    farPersonRays = farPersonRays;
    farPersonRifle = farPersonRifle;
    farPersonRunning = farPersonRunning;
    farPersonRunningFast = farPersonRunningFast;
    farPersonSeat = farPersonSeat;
    farPersonSeatReclined = farPersonSeatReclined;
    farPersonShelter = farPersonShelter;
    farPersonSign = farPersonSign;
    farPersonSimple = farPersonSimple;
    farPersonSkating = farPersonSkating;
    farPersonSkiing = farPersonSkiing;
    farPersonSkiingNordic = farPersonSkiingNordic;
    farPersonSkiJumping = farPersonSkiJumping;
    farPersonSkiLift = farPersonSkiLift;
    farPersonSledding = farPersonSledding;
    farPersonSnowboarding = farPersonSnowboarding;
    farPersonSnowmobiling = farPersonSnowmobiling;
    farPersonSwimming = farPersonSwimming;
    farPersonThroughWindow = farPersonThroughWindow;
    farPersonToDoor = farPersonToDoor;
    farPersonToPortal = farPersonToPortal;
    farPersonWalking = farPersonWalking;
    farPersonWalkingArrowLoopLeft = farPersonWalkingArrowLoopLeft;
    farPersonWalkingArrowRight = farPersonWalkingArrowRight;
    farPersonWalkingDashedLineArrowRight = farPersonWalkingDashedLineArrowRight;
    farPersonWalkingLuggage = farPersonWalkingLuggage;
    farPersonWalkingWithCane = farPersonWalkingWithCane;
    farPesetaSign = farPesetaSign;
    farPesoSign = farPesoSign;
    farPhabricator = farPhabricator;
    farPhoenixFramework = farPhoenixFramework;
    farPhoenixSquadron = farPhoenixSquadron;
    farPhone = farPhone;
    farPhoneAlt = farPhoneAlt;
    farPhoneArrowDown = farPhoneArrowDown;
    farPhoneArrowDownLeft = farPhoneArrowDownLeft;
    farPhoneArrowRight = farPhoneArrowRight;
    farPhoneArrowUp = farPhoneArrowUp;
    farPhoneArrowUpRight = farPhoneArrowUpRight;
    farPhoneCircle = farPhoneCircle;
    farPhoneCircleAlt = farPhoneCircleAlt;
    farPhoneCircleDown = farPhoneCircleDown;
    farPhoneFlip = farPhoneFlip;
    farPhoneHangup = farPhoneHangup;
    farPhoneIncoming = farPhoneIncoming;
    farPhoneIntercom = farPhoneIntercom;
    farPhoneLaptop = farPhoneLaptop;
    farPhoneMissed = farPhoneMissed;
    farPhoneOffice = farPhoneOffice;
    farPhoneOutgoing = farPhoneOutgoing;
    farPhonePlus = farPhonePlus;
    farPhoneRotary = farPhoneRotary;
    farPhoneSlash = farPhoneSlash;
    farPhoneSquare = farPhoneSquare;
    farPhoneSquareAlt = farPhoneSquareAlt;
    farPhoneSquareDown = farPhoneSquareDown;
    farPhoneVolume = farPhoneVolume;
    farPhoneXmark = farPhoneXmark;
    farPhotoFilm = farPhotoFilm;
    farPhotoFilmMusic = farPhotoFilmMusic;
    farPhotoVideo = farPhotoVideo;
    farPhp = farPhp;
    farPi = farPi;
    farPiano = farPiano;
    farPianoKeyboard = farPianoKeyboard;
    farPickaxe = farPickaxe;
    farPickleball = farPickleball;
    farPie = farPie;
    farPieChart = farPieChart;
    farPiedPiper = farPiedPiper;
    farPiedPiperAlt = farPiedPiperAlt;
    farPiedPiperHat = farPiedPiperHat;
    farPiedPiperPp = farPiedPiperPp;
    farPiedPiperSquare = farPiedPiperSquare;
    farPig = farPig;
    farPiggyBank = farPiggyBank;
    farPills = farPills;
    farPinata = farPinata;
    farPinball = farPinball;
    farPineapple = farPineapple;
    farPingPongPaddleBall = farPingPongPaddleBall;
    farPinterest = farPinterest;
    farPinterestP = farPinterestP;
    farPinterestSquare = farPinterestSquare;
    farPipe = farPipe;
    farPipeCircleCheck = farPipeCircleCheck;
    farPipeCollar = farPipeCollar;
    farPipeSection = farPipeSection;
    farPipeSmoking = farPipeSmoking;
    farPipeValve = farPipeValve;
    farPix = farPix;
    farPixiv = farPixiv;
    farPizza = farPizza;
    farPizzaSlice = farPizzaSlice;
    farPlaceOfWorship = farPlaceOfWorship;
    farPlane = farPlane;
    farPlaneAlt = farPlaneAlt;
    farPlaneArrival = farPlaneArrival;
    farPlaneCircleCheck = farPlaneCircleCheck;
    farPlaneCircleExclamation = farPlaneCircleExclamation;
    farPlaneCircleXmark = farPlaneCircleXmark;
    farPlaneDeparture = farPlaneDeparture;
    farPlaneEngines = farPlaneEngines;
    farPlaneLock = farPlaneLock;
    farPlaneProp = farPlaneProp;
    farPlaneSlash = farPlaneSlash;
    farPlaneTail = farPlaneTail;
    farPlanetMoon = farPlanetMoon;
    farPlanetRinged = farPlanetRinged;
    farPlaneUp = farPlaneUp;
    farPlaneUpSlash = farPlaneUpSlash;
    farPlantWilt = farPlantWilt;
    farPlateUtensils = farPlateUtensils;
    farPlateWheat = farPlateWheat;
    farPlay = farPlay;
    farPlayCircle = farPlayCircle;
    farPlayPause = farPlayPause;
    farPlaystation = farPlaystation;
    farPlug = farPlug;
    farPlugCircleBolt = farPlugCircleBolt;
    farPlugCircleCheck = farPlugCircleCheck;
    farPlugCircleExclamation = farPlugCircleExclamation;
    farPlugCircleMinus = farPlugCircleMinus;
    farPlugCirclePlus = farPlugCirclePlus;
    farPlugCircleXmark = farPlugCircleXmark;
    farPlus = farPlus;
    farPlusCircle = farPlusCircle;
    farPlusHexagon = farPlusHexagon;
    farPlusLarge = farPlusLarge;
    farPlusMinus = farPlusMinus;
    farPlusOctagon = farPlusOctagon;
    farPlusSquare = farPlusSquare;
    farPodcast = farPodcast;
    farPodium = farPodium;
    farPodiumStar = farPodiumStar;
    farPoliceBox = farPoliceBox;
    farPoll = farPoll;
    farPollH = farPollH;
    farPollPeople = farPollPeople;
    farPompebled = farPompebled;
    farPoo = farPoo;
    farPooBolt = farPooBolt;
    farPool8Ball = farPool8Ball;
    farPoop = farPoop;
    farPooStorm = farPooStorm;
    farPopcorn = farPopcorn;
    farPopsicle = farPopsicle;
    farPortalEnter = farPortalEnter;
    farPortalExit = farPortalExit;
    farPortrait = farPortrait;
    farPotato = farPotato;
    farPotFood = farPotFood;
    farPoundSign = farPoundSign;
    farPowerOff = farPowerOff;
    farPray = farPray;
    farPrayingHands = farPrayingHands;
    farPrescription = farPrescription;
    farPrescriptionBottle = farPrescriptionBottle;
    farPrescriptionBottleAlt = farPrescriptionBottleAlt;
    farPrescriptionBottleMedical = farPrescriptionBottleMedical;
    farPrescriptionBottlePill = farPrescriptionBottlePill;
    farPresentation = farPresentation;
    farPresentationScreen = farPresentationScreen;
    farPretzel = farPretzel;
    farPrint = farPrint;
    farPrintMagnifyingGlass = farPrintMagnifyingGlass;
    farPrintSearch = farPrintSearch;
    farPrintSlash = farPrintSlash;
    farPro = farPro;
    farProcedures = farProcedures;
    farProductHunt = farProductHunt;
    farProjectDiagram = farProjectDiagram;
    farProjector = farProjector;
    farPump = farPump;
    farPumpkin = farPumpkin;
    farPumpMedical = farPumpMedical;
    farPumpSoap = farPumpSoap;
    farPushed = farPushed;
    farPuzzle = farPuzzle;
    farPuzzlePiece = farPuzzlePiece;
    farPuzzlePieceAlt = farPuzzlePieceAlt;
    farPuzzlePieceSimple = farPuzzlePieceSimple;
    farPython = farPython;
    farQ = farQ;
    farQq = farQq;
    farQrcode = farQrcode;
    farQuestion = farQuestion;
    farQuestionCircle = farQuestionCircle;
    farQuestionSquare = farQuestionSquare;
    farQuidditch = farQuidditch;
    farQuidditchBroomBall = farQuidditchBroomBall;
    farQuinscape = farQuinscape;
    farQuora = farQuora;
    farQuoteLeft = farQuoteLeft;
    farQuoteLeftAlt = farQuoteLeftAlt;
    farQuoteRight = farQuoteRight;
    farQuoteRightAlt = farQuoteRightAlt;
    farQuotes = farQuotes;
    farQuran = farQuran;
    farR = farR;
    farRabbit = farRabbit;
    farRabbitFast = farRabbitFast;
    farRabbitRunning = farRabbitRunning;
    farRaccoon = farRaccoon;
    farRacquet = farRacquet;
    farRadar = farRadar;
    farRadiation = farRadiation;
    farRadiationAlt = farRadiationAlt;
    farRadio = farRadio;
    farRadioAlt = farRadioAlt;
    farRadioTuner = farRadioTuner;
    farRainbow = farRainbow;
    farRaindrops = farRaindrops;
    farRam = farRam;
    farRampLoading = farRampLoading;
    farRandom = farRandom;
    farRankingStar = farRankingStar;
    farRaspberryPi = farRaspberryPi;
    farRavelry = farRavelry;
    farRaygun = farRaygun;
    farReact = farReact;
    farReacteurope = farReacteurope;
    farReadme = farReadme;
    farRebel = farRebel;
    farReceipt = farReceipt;
    farRecordVinyl = farRecordVinyl;
    farRectangle = farRectangle;
    farRectangleAd = farRectangleAd;
    farRectangleBarcode = farRectangleBarcode;
    farRectangleCode = farRectangleCode;
    farRectangleHd = farRectangleHd;
    farRectangleHistory = farRectangleHistory;
    farRectangleHistoryCirclePlus = farRectangleHistoryCirclePlus;
    farRectangleHistoryCircleUser = farRectangleHistoryCircleUser;
    farRectangleLandscape = farRectangleLandscape;
    farRectangleList = farRectangleList;
    farRectanglePortrait = farRectanglePortrait;
    farRectanglePro = farRectanglePro;
    farRectangleSd = farRectangleSd;
    farRectanglesMixed = farRectanglesMixed;
    farRectangleTerminal = farRectangleTerminal;
    farRectangleTimes = farRectangleTimes;
    farRectangleVertical = farRectangleVertical;
    farRectangleVerticalHistory = farRectangleVerticalHistory;
    farRectangleWide = farRectangleWide;
    farRectangleXmark = farRectangleXmark;
    farRecycle = farRecycle;
    farReddit = farReddit;
    farRedditAlien = farRedditAlien;
    farRedditSquare = farRedditSquare;
    farRedhat = farRedhat;
    farRedo = farRedo;
    farRedoAlt = farRedoAlt;
    farRedRiver = farRedRiver;
    farReel = farReel;
    farReflectHorizontal = farReflectHorizontal;
    farReflectVertical = farReflectVertical;
    farRefresh = farRefresh;
    farRefrigerator = farRefrigerator;
    farRegistered = farRegistered;
    farRemove = farRemove;
    farRemoveFormat = farRemoveFormat;
    farRendact = farRendact;
    farRenren = farRenren;
    farReorder = farReorder;
    farRepeat = farRepeat;
    farRepeat1 = farRepeat1;
    farRepeat1Alt = farRepeat1Alt;
    farRepeatAlt = farRepeatAlt;
    farReply = farReply;
    farReplyAll = farReplyAll;
    farReplyClock = farReplyClock;
    farReplyd = farReplyd;
    farReplyTime = farReplyTime;
    farRepublican = farRepublican;
    farResearchgate = farResearchgate;
    farResolving = farResolving;
    farRestroom = farRestroom;
    farRestroomSimple = farRestroomSimple;
    farRetweet = farRetweet;
    farRetweetAlt = farRetweetAlt;
    farRev = farRev;
    farRhombus = farRhombus;
    farRibbon = farRibbon;
    farRight = farRight;
    farRightFromBracket = farRightFromBracket;
    farRightFromLine = farRightFromLine;
    farRightLeft = farRightLeft;
    farRightLeftLarge = farRightLeftLarge;
    farRightLong = farRightLong;
    farRightLongToLine = farRightLongToLine;
    farRightToBracket = farRightToBracket;
    farRightToLine = farRightToLine;
    farRing = farRing;
    farRingDiamond = farRingDiamond;
    farRingsWedding = farRingsWedding;
    farRmb = farRmb;
    farRoad = farRoad;
    farRoadBarrier = farRoadBarrier;
    farRoadBridge = farRoadBridge;
    farRoadCircleCheck = farRoadCircleCheck;
    farRoadCircleExclamation = farRoadCircleExclamation;
    farRoadCircleXmark = farRoadCircleXmark;
    farRoadLock = farRoadLock;
    farRoadSpikes = farRoadSpikes;
    farRobot = farRobot;
    farRobotAstromech = farRobotAstromech;
    farRocket = farRocket;
    farRocketchat = farRocketchat;
    farRocketLaunch = farRocketLaunch;
    farRockrms = farRockrms;
    farRodAsclepius = farRodAsclepius;
    farRodSnake = farRodSnake;
    farRollerCoaster = farRollerCoaster;
    farRotate = farRotate;
    farRotateBack = farRotateBack;
    farRotateBackward = farRotateBackward;
    farRotateExclamation = farRotateExclamation;
    farRotateForward = farRotateForward;
    farRotateLeft = farRotateLeft;
    farRotateReverse = farRotateReverse;
    farRotateRight = farRotateRight;
    farRouble = farRouble;
    farRoute = farRoute;
    farRouteHighway = farRouteHighway;
    farRouteInterstate = farRouteInterstate;
    farRouter = farRouter;
    farRows = farRows;
    farRProject = farRProject;
    farRss = farRss;
    farRssSquare = farRssSquare;
    farRub = farRub;
    farRuble = farRuble;
    farRubleSign = farRubleSign;
    farRug = farRug;
    farRugbyBall = farRugbyBall;
    farRuler = farRuler;
    farRulerCombined = farRulerCombined;
    farRulerHorizontal = farRulerHorizontal;
    farRulerTriangle = farRulerTriangle;
    farRulerVertical = farRulerVertical;
    farRunning = farRunning;
    farRupee = farRupee;
    farRupeeSign = farRupeeSign;
    farRupiahSign = farRupiahSign;
    farRust = farRust;
    farRv = farRv;
    farS = farS;
    farSack = farSack;
    farSackDollar = farSackDollar;
    farSackXmark = farSackXmark;
    farSadCry = farSadCry;
    farSadTear = farSadTear;
    farSafari = farSafari;
    farSailboat = farSailboat;
    farSalad = farSalad;
    farSalesforce = farSalesforce;
    farSaltShaker = farSaltShaker;
    farSandwich = farSandwich;
    farSass = farSass;
    farSatellite = farSatellite;
    farSatelliteDish = farSatelliteDish;
    farSausage = farSausage;
    farSave = farSave;
    farSaveCircleArrowRight = farSaveCircleArrowRight;
    farSaveCircleXmark = farSaveCircleXmark;
    farSaveTimes = farSaveTimes;
    farSaxHot = farSaxHot;
    farSaxophone = farSaxophone;
    farSaxophoneFire = farSaxophoneFire;
    farScaleBalanced = farScaleBalanced;
    farScaleUnbalanced = farScaleUnbalanced;
    farScaleUnbalancedFlip = farScaleUnbalancedFlip;
    farScalpel = farScalpel;
    farScalpelLineDashed = farScalpelLineDashed;
    farScalpelPath = farScalpelPath;
    farScanner = farScanner;
    farScannerGun = farScannerGun;
    farScannerImage = farScannerImage;
    farScannerKeyboard = farScannerKeyboard;
    farScannerTouchscreen = farScannerTouchscreen;
    farScarecrow = farScarecrow;
    farScarf = farScarf;
    farSchlix = farSchlix;
    farSchool = farSchool;
    farSchoolCircleCheck = farSchoolCircleCheck;
    farSchoolCircleExclamation = farSchoolCircleExclamation;
    farSchoolCircleXmark = farSchoolCircleXmark;
    farSchoolFlag = farSchoolFlag;
    farSchoolLock = farSchoolLock;
    farScissors = farScissors;
    farScreencast = farScreencast;
    farScreenpal = farScreenpal;
    farScreenshot = farScreenshot;
    farScreenUsers = farScreenUsers;
    farScrewdriver = farScrewdriver;
    farScrewdriverWrench = farScrewdriverWrench;
    farScribble = farScribble;
    farScribd = farScribd;
    farScroll = farScroll;
    farScrollOld = farScrollOld;
    farScrollRibbon = farScrollRibbon;
    farScrollTorah = farScrollTorah;
    farScrubber = farScrubber;
    farScythe = farScythe;
    farSdCard = farSdCard;
    farSdCards = farSdCards;
    farSeal = farSeal;
    farSealExclamation = farSealExclamation;
    farSealQuestion = farSealQuestion;
    farSearch = farSearch;
    farSearchDollar = farSearchDollar;
    farSearchengin = farSearchengin;
    farSearchLocation = farSearchLocation;
    farSearchMinus = farSearchMinus;
    farSearchPlus = farSearchPlus;
    farSeatAirline = farSeatAirline;
    farSection = farSection;
    farSeedling = farSeedling;
    farSellcast = farSellcast;
    farSellsy = farSellsy;
    farSemicolon = farSemicolon;
    farSend = farSend;
    farSendBack = farSendBack;
    farSendBackward = farSendBackward;
    farSensor = farSensor;
    farSensorAlert = farSensorAlert;
    farSensorCloud = farSensorCloud;
    farSensorFire = farSensorFire;
    farSensorOn = farSensorOn;
    farSensorSmoke = farSensorSmoke;
    farSensorTriangleExclamation = farSensorTriangleExclamation;
    farServer = farServer;
    farServicestack = farServicestack;
    farShapes = farShapes;
    farShare = farShare;
    farShareAll = farShareAll;
    farShareAlt = farShareAlt;
    farShareAltSquare = farShareAltSquare;
    farShareFromSquare = farShareFromSquare;
    farShareNodes = farShareNodes;
    farShareSquare = farShareSquare;
    farSheep = farSheep;
    farSheetPlastic = farSheetPlastic;
    farShekel = farShekel;
    farShekelSign = farShekelSign;
    farShelves = farShelves;
    farShelvesEmpty = farShelvesEmpty;
    farSheqel = farSheqel;
    farSheqelSign = farSheqelSign;
    farShield = farShield;
    farShieldAlt = farShieldAlt;
    farShieldBlank = farShieldBlank;
    farShieldCat = farShieldCat;
    farShieldCheck = farShieldCheck;
    farShieldCross = farShieldCross;
    farShieldDog = farShieldDog;
    farShieldExclamation = farShieldExclamation;
    farShieldHalved = farShieldHalved;
    farShieldHeart = farShieldHeart;
    farShieldKeyhole = farShieldKeyhole;
    farShieldMinus = farShieldMinus;
    farShieldPlus = farShieldPlus;
    farShieldQuartered = farShieldQuartered;
    farShieldSlash = farShieldSlash;
    farShieldTimes = farShieldTimes;
    farShieldVirus = farShieldVirus;
    farShieldXmark = farShieldXmark;
    farShip = farShip;
    farShippingFast = farShippingFast;
    farShippingTimed = farShippingTimed;
    farShirt = farShirt;
    farShirtLongSleeve = farShirtLongSleeve;
    farShirtRunning = farShirtRunning;
    farShirtsinbulk = farShirtsinbulk;
    farShirtTankTop = farShirtTankTop;
    farShishKebab = farShishKebab;
    farShoelace = farShoelace;
    farShoePrints = farShoePrints;
    farShop = farShop;
    farShopify = farShopify;
    farShopLock = farShopLock;
    farShoppingBag = farShoppingBag;
    farShoppingBasket = farShoppingBasket;
    farShoppingBasketAlt = farShoppingBasketAlt;
    farShoppingCart = farShoppingCart;
    farShopSlash = farShopSlash;
    farShopware = farShopware;
    farShortcake = farShortcake;
    farShovel = farShovel;
    farShovelSnow = farShovelSnow;
    farShower = farShower;
    farShowerAlt = farShowerAlt;
    farShowerDown = farShowerDown;
    farShredder = farShredder;
    farShrimp = farShrimp;
    farShuffle = farShuffle;
    farShutters = farShutters;
    farShuttlecock = farShuttlecock;
    farShuttleSpace = farShuttleSpace;
    farShuttleVan = farShuttleVan;
    farSickle = farSickle;
    farSidebar = farSidebar;
    farSidebarFlip = farSidebarFlip;
    farSigma = farSigma;
    farSign = farSign;
    farSignal = farSignal;
    farSignal1 = farSignal1;
    farSignal2 = farSignal2;
    farSignal3 = farSignal3;
    farSignal4 = farSignal4;
    farSignal5 = farSignal5;
    farSignalAlt = farSignalAlt;
    farSignalAlt1 = farSignalAlt1;
    farSignalAlt2 = farSignalAlt2;
    farSignalAlt3 = farSignalAlt3;
    farSignalAlt4 = farSignalAlt4;
    farSignalAltSlash = farSignalAltSlash;
    farSignalBars = farSignalBars;
    farSignalBarsFair = farSignalBarsFair;
    farSignalBarsGood = farSignalBarsGood;
    farSignalBarsSlash = farSignalBarsSlash;
    farSignalBarsStrong = farSignalBarsStrong;
    farSignalBarsWeak = farSignalBarsWeak;
    farSignalFair = farSignalFair;
    farSignalGood = farSignalGood;
    farSignalMessenger = farSignalMessenger;
    farSignalPerfect = farSignalPerfect;
    farSignalSlash = farSignalSlash;
    farSignalStream = farSignalStream;
    farSignalStreamSlash = farSignalStreamSlash;
    farSignalStrong = farSignalStrong;
    farSignalWeak = farSignalWeak;
    farSignature = farSignature;
    farSignatureLock = farSignatureLock;
    farSignatureSlash = farSignatureSlash;
    farSignHanging = farSignHanging;
    farSignIn = farSignIn;
    farSignInAlt = farSignInAlt;
    farSigning = farSigning;
    farSignLanguage = farSignLanguage;
    farSignOut = farSignOut;
    farSignOutAlt = farSignOutAlt;
    farSignPost = farSignPost;
    farSignPosts = farSignPosts;
    farSignPostsWrench = farSignPostsWrench;
    farSignsPost = farSignsPost;
    farSimCard = farSimCard;
    farSimCards = farSimCards;
    farSimplybuilt = farSimplybuilt;
    farSink = farSink;
    farSiren = farSiren;
    farSirenOn = farSirenOn;
    farSistrix = farSistrix;
    farSitemap = farSitemap;
    farSith = farSith;
    farSitrox = farSitrox;
    farSkating = farSkating;
    farSkeleton = farSkeleton;
    farSkeletonRibs = farSkeletonRibs;
    farSketch = farSketch;
    farSkiBoot = farSkiBoot;
    farSkiBootSki = farSkiBootSki;
    farSkiing = farSkiing;
    farSkiingNordic = farSkiingNordic;
    farSkiJump = farSkiJump;
    farSkiLift = farSkiLift;
    farSkull = farSkull;
    farSkullCow = farSkullCow;
    farSkullCrossbones = farSkullCrossbones;
    farSkyatlas = farSkyatlas;
    farSkype = farSkype;
    farSlack = farSlack;
    farSlackHash = farSlackHash;
    farSlash = farSlash;
    farSlashBack = farSlashBack;
    farSlashForward = farSlashForward;
    farSledding = farSledding;
    farSleigh = farSleigh;
    farSlider = farSlider;
    farSliders = farSliders;
    farSlidersH = farSlidersH;
    farSlidersHSquare = farSlidersHSquare;
    farSlidersSimple = farSlidersSimple;
    farSlidersUp = farSlidersUp;
    farSlidersV = farSlidersV;
    farSlidersVSquare = farSlidersVSquare;
    farSlideshare = farSlideshare;
    farSlotMachine = farSlotMachine;
    farSmile = farSmile;
    farSmileBeam = farSmileBeam;
    farSmilePlus = farSmilePlus;
    farSmileWink = farSmileWink;
    farSmog = farSmog;
    farSmoke = farSmoke;
    farSmoking = farSmoking;
    farSmokingBan = farSmokingBan;
    farSms = farSms;
    farSnake = farSnake;
    farSnapchat = farSnapchat;
    farSnapchatGhost = farSnapchatGhost;
    farSnapchatSquare = farSnapchatSquare;
    farSnooze = farSnooze;
    farSnowBlowing = farSnowBlowing;
    farSnowboarding = farSnowboarding;
    farSnowflake = farSnowflake;
    farSnowflakeDroplets = farSnowflakeDroplets;
    farSnowflakes = farSnowflakes;
    farSnowman = farSnowman;
    farSnowmanHead = farSnowmanHead;
    farSnowmobile = farSnowmobile;
    farSnowplow = farSnowplow;
    farSoap = farSoap;
    farSoccerBall = farSoccerBall;
    farSocks = farSocks;
    farSoftServe = farSoftServe;
    farSolarPanel = farSolarPanel;
    farSolarSystem = farSolarSystem;
    farSort = farSort;
    farSortAlphaAsc = farSortAlphaAsc;
    farSortAlphaDesc = farSortAlphaDesc;
    farSortAlphaDown = farSortAlphaDown;
    farSortAlphaDownAlt = farSortAlphaDownAlt;
    farSortAlphaUp = farSortAlphaUp;
    farSortAlphaUpAlt = farSortAlphaUpAlt;
    farSortAlt = farSortAlt;
    farSortAmountAsc = farSortAmountAsc;
    farSortAmountDesc = farSortAmountDesc;
    farSortAmountDown = farSortAmountDown;
    farSortAmountDownAlt = farSortAmountDownAlt;
    farSortAmountUp = farSortAmountUp;
    farSortAmountUpAlt = farSortAmountUpAlt;
    farSortAsc = farSortAsc;
    farSortCircle = farSortCircle;
    farSortCircleDown = farSortCircleDown;
    farSortCircleUp = farSortCircleUp;
    farSortDesc = farSortDesc;
    farSortDown = farSortDown;
    farSortNumericAsc = farSortNumericAsc;
    farSortNumericDesc = farSortNumericDesc;
    farSortNumericDown = farSortNumericDown;
    farSortNumericDownAlt = farSortNumericDownAlt;
    farSortNumericUp = farSortNumericUp;
    farSortNumericUpAlt = farSortNumericUpAlt;
    farSortShapesDown = farSortShapesDown;
    farSortShapesDownAlt = farSortShapesDownAlt;
    farSortShapesUp = farSortShapesUp;
    farSortShapesUpAlt = farSortShapesUpAlt;
    farSortSizeDown = farSortSizeDown;
    farSortSizeDownAlt = farSortSizeDownAlt;
    farSortSizeUp = farSortSizeUp;
    farSortSizeUpAlt = farSortSizeUpAlt;
    farSortUp = farSortUp;
    farSortUpDown = farSortUpDown;
    farSoundcloud = farSoundcloud;
    farSoup = farSoup;
    farSourcetree = farSourcetree;
    farSpa = farSpa;
    farSpaceAwesome = farSpaceAwesome;
    farSpaceShuttle = farSpaceShuttle;
    farSpaceStationMoon = farSpaceStationMoon;
    farSpaceStationMoonAlt = farSpaceStationMoonAlt;
    farSpaceStationMoonConstruction = farSpaceStationMoonConstruction;
    farSpade = farSpade;
    farSpaghettiMonsterFlying = farSpaghettiMonsterFlying;
    farSparkle = farSparkle;
    farSparkles = farSparkles;
    farSpeakap = farSpeakap;
    farSpeaker = farSpeaker;
    farSpeakerDeck = farSpeakerDeck;
    farSpeakers = farSpeakers;
    farSpellCheck = farSpellCheck;
    farSpider = farSpider;
    farSpiderBlackWidow = farSpiderBlackWidow;
    farSpiderWeb = farSpiderWeb;
    farSpinner = farSpinner;
    farSpinnerScale = farSpinnerScale;
    farSpinnerThird = farSpinnerThird;
    farSplit = farSplit;
    farSplotch = farSplotch;
    farSpoon = farSpoon;
    farSportsball = farSportsball;
    farSpotify = farSpotify;
    farSprayCan = farSprayCan;
    farSprayCanSparkles = farSprayCanSparkles;
    farSprinkler = farSprinkler;
    farSprinklerCeiling = farSprinklerCeiling;
    farSprout = farSprout;
    farSquare = farSquare;
    farSquare0 = farSquare0;
    farSquare1 = farSquare1;
    farSquare2 = farSquare2;
    farSquare3 = farSquare3;
    farSquare4 = farSquare4;
    farSquare5 = farSquare5;
    farSquare6 = farSquare6;
    farSquare7 = farSquare7;
    farSquare8 = farSquare8;
    farSquare9 = farSquare9;
    farSquareA = farSquareA;
    farSquareALock = farSquareALock;
    farSquareAmpersand = farSquareAmpersand;
    farSquareArrowDown = farSquareArrowDown;
    farSquareArrowDownLeft = farSquareArrowDownLeft;
    farSquareArrowDownRight = farSquareArrowDownRight;
    farSquareArrowLeft = farSquareArrowLeft;
    farSquareArrowRight = farSquareArrowRight;
    farSquareArrowUp = farSquareArrowUp;
    farSquareArrowUpLeft = farSquareArrowUpLeft;
    farSquareArrowUpRight = farSquareArrowUpRight;
    farSquareB = farSquareB;
    farSquareBehance = farSquareBehance;
    farSquareBolt = farSquareBolt;
    farSquareC = farSquareC;
    farSquareCaretDown = farSquareCaretDown;
    farSquareCaretLeft = farSquareCaretLeft;
    farSquareCaretRight = farSquareCaretRight;
    farSquareCaretUp = farSquareCaretUp;
    farSquareCheck = farSquareCheck;
    farSquareChevronDown = farSquareChevronDown;
    farSquareChevronLeft = farSquareChevronLeft;
    farSquareChevronRight = farSquareChevronRight;
    farSquareChevronUp = farSquareChevronUp;
    farSquareCode = farSquareCode;
    farSquareD = farSquareD;
    farSquareDashed = farSquareDashed;
    farSquareDashedCirclePlus = farSquareDashedCirclePlus;
    farSquareDivide = farSquareDivide;
    farSquareDollar = farSquareDollar;
    farSquareDown = farSquareDown;
    farSquareDownLeft = farSquareDownLeft;
    farSquareDownRight = farSquareDownRight;
    farSquareDribbble = farSquareDribbble;
    farSquareE = farSquareE;
    farSquareEllipsis = farSquareEllipsis;
    farSquareEllipsisVertical = farSquareEllipsisVertical;
    farSquareEnvelope = farSquareEnvelope;
    farSquareExclamation = farSquareExclamation;
    farSquareF = farSquareF;
    farSquareFacebook = farSquareFacebook;
    farSquareFontAwesome = farSquareFontAwesome;
    farSquareFontAwesomeStroke = farSquareFontAwesomeStroke;
    farSquareFragile = farSquareFragile;
    farSquareFull = farSquareFull;
    farSquareG = farSquareG;
    farSquareGit = farSquareGit;
    farSquareGithub = farSquareGithub;
    farSquareGitlab = farSquareGitlab;
    farSquareGooglePlus = farSquareGooglePlus;
    farSquareH = farSquareH;
    farSquareHackerNews = farSquareHackerNews;
    farSquareHeart = farSquareHeart;
    farSquareI = farSquareI;
    farSquareInfo = farSquareInfo;
    farSquareInstagram = farSquareInstagram;
    farSquareJ = farSquareJ;
    farSquareJs = farSquareJs;
    farSquareK = farSquareK;
    farSquareKanban = farSquareKanban;
    farSquareL = farSquareL;
    farSquareLastfm = farSquareLastfm;
    farSquareLeft = farSquareLeft;
    farSquareLetterboxd = farSquareLetterboxd;
    farSquareList = farSquareList;
    farSquareM = farSquareM;
    farSquareMinus = farSquareMinus;
    farSquareN = farSquareN;
    farSquareNfi = farSquareNfi;
    farSquareO = farSquareO;
    farSquareOdnoklassniki = farSquareOdnoklassniki;
    farSquareP = farSquareP;
    farSquareParking = farSquareParking;
    farSquareParkingSlash = farSquareParkingSlash;
    farSquarePen = farSquarePen;
    farSquarePersonConfined = farSquarePersonConfined;
    farSquarePhone = farSquarePhone;
    farSquarePhoneFlip = farSquarePhoneFlip;
    farSquarePhoneHangup = farSquarePhoneHangup;
    farSquarePiedPiper = farSquarePiedPiper;
    farSquarePinterest = farSquarePinterest;
    farSquarePlus = farSquarePlus;
    farSquarePollHorizontal = farSquarePollHorizontal;
    farSquarePollVertical = farSquarePollVertical;
    farSquareQ = farSquareQ;
    farSquareQuarters = farSquareQuarters;
    farSquareQuestion = farSquareQuestion;
    farSquareQuote = farSquareQuote;
    farSquareR = farSquareR;
    farSquareReddit = farSquareReddit;
    farSquareRight = farSquareRight;
    farSquareRing = farSquareRing;
    farSquareRoot = farSquareRoot;
    farSquareRootAlt = farSquareRootAlt;
    farSquareRootVariable = farSquareRootVariable;
    farSquareRss = farSquareRss;
    farSquareS = farSquareS;
    farSquareShareNodes = farSquareShareNodes;
    farSquareSliders = farSquareSliders;
    farSquareSlidersVertical = farSquareSlidersVertical;
    farSquareSmall = farSquareSmall;
    farSquareSnapchat = farSquareSnapchat;
    farSquarespace = farSquarespace;
    farSquareStar = farSquareStar;
    farSquareSteam = farSquareSteam;
    farSquareT = farSquareT;
    farSquareTerminal = farSquareTerminal;
    farSquareThisWayUp = farSquareThisWayUp;
    farSquareThreads = farSquareThreads;
    farSquareTumblr = farSquareTumblr;
    farSquareTwitter = farSquareTwitter;
    farSquareU = farSquareU;
    farSquareUp = farSquareUp;
    farSquareUpLeft = farSquareUpLeft;
    farSquareUpRight = farSquareUpRight;
    farSquareUser = farSquareUser;
    farSquareV = farSquareV;
    farSquareViadeo = farSquareViadeo;
    farSquareVimeo = farSquareVimeo;
    farSquareVirus = farSquareVirus;
    farSquareW = farSquareW;
    farSquareWhatsapp = farSquareWhatsapp;
    farSquareWineGlassCrack = farSquareWineGlassCrack;
    farSquareX = farSquareX;
    farSquareXing = farSquareXing;
    farSquareXmark = farSquareXmark;
    farSquareXTwitter = farSquareXTwitter;
    farSquareY = farSquareY;
    farSquareYoutube = farSquareYoutube;
    farSquareZ = farSquareZ;
    farSquid = farSquid;
    farSquirrel = farSquirrel;
    farStackExchange = farStackExchange;
    farStackOverflow = farStackOverflow;
    farStackpath = farStackpath;
    farStaff = farStaff;
    farStaffAesculapius = farStaffAesculapius;
    farStaffSnake = farStaffSnake;
    farStairs = farStairs;
    farStamp = farStamp;
    farStandardDefinition = farStandardDefinition;
    farStapler = farStapler;
    farStar = farStar;
    farStarAndCrescent = farStarAndCrescent;
    farStarChristmas = farStarChristmas;
    farStarCircle = farStarCircle;
    farStarExclamation = farStarExclamation;
    farStarfighter = farStarfighter;
    farStarfighterAlt = farStarfighterAlt;
    farStarfighterAltAdvanced = farStarfighterAltAdvanced;
    farStarfighterTwinIonEngine = farStarfighterTwinIonEngine;
    farStarfighterTwinIonEngineAdvanced = farStarfighterTwinIonEngineAdvanced;
    farStarHalf = farStarHalf;
    farStarHalfAlt = farStarHalfAlt;
    farStarHalfStroke = farStarHalfStroke;
    farStarOfDavid = farStarOfDavid;
    farStarOfLife = farStarOfLife;
    farStars = farStars;
    farStarSharp = farStarSharp;
    farStarSharpHalf = farStarSharpHalf;
    farStarSharpHalfAlt = farStarSharpHalfAlt;
    farStarSharpHalfStroke = farStarSharpHalfStroke;
    farStarship = farStarship;
    farStarshipFreighter = farStarshipFreighter;
    farStarShooting = farStarShooting;
    farStaylinked = farStaylinked;
    farSteak = farSteak;
    farSteam = farSteam;
    farSteamSquare = farSteamSquare;
    farSteamSymbol = farSteamSymbol;
    farSteeringWheel = farSteeringWheel;
    farStepBackward = farStepBackward;
    farStepForward = farStepForward;
    farSterlingSign = farSterlingSign;
    farStethoscope = farStethoscope;
    farStickerMule = farStickerMule;
    farStickyNote = farStickyNote;
    farStocking = farStocking;
    farStomach = farStomach;
    farStop = farStop;
    farStopCircle = farStopCircle;
    farStopwatch = farStopwatch;
    farStopwatch20 = farStopwatch20;
    farStore = farStore;
    farStoreAlt = farStoreAlt;
    farStoreAltSlash = farStoreAltSlash;
    farStoreLock = farStoreLock;
    farStoreSlash = farStoreSlash;
    farStrava = farStrava;
    farStrawberry = farStrawberry;
    farStream = farStream;
    farStreetView = farStreetView;
    farStretcher = farStretcher;
    farStrikethrough = farStrikethrough;
    farStripe = farStripe;
    farStripeS = farStripeS;
    farStroopwafel = farStroopwafel;
    farStubber = farStubber;
    farStudiovinari = farStudiovinari;
    farStumbleupon = farStumbleupon;
    farStumbleuponCircle = farStumbleuponCircle;
    farSubscript = farSubscript;
    farSubtitles = farSubtitles;
    farSubtitlesSlash = farSubtitlesSlash;
    farSubtract = farSubtract;
    farSubway = farSubway;
    farSubwayTunnel = farSubwayTunnel;
    farSuitcase = farSuitcase;
    farSuitcaseMedical = farSuitcaseMedical;
    farSuitcaseRolling = farSuitcaseRolling;
    farSun = farSun;
    farSunAlt = farSunAlt;
    farSunBright = farSunBright;
    farSunCloud = farSunCloud;
    farSunDust = farSunDust;
    farSunglasses = farSunglasses;
    farSunHaze = farSunHaze;
    farSunPlantWilt = farSunPlantWilt;
    farSunrise = farSunrise;
    farSunset = farSunset;
    farSuperpowers = farSuperpowers;
    farSuperscript = farSuperscript;
    farSupple = farSupple;
    farSurprise = farSurprise;
    farSuse = farSuse;
    farSushi = farSushi;
    farSushiRoll = farSushiRoll;
    farSwap = farSwap;
    farSwapArrows = farSwapArrows;
    farSwatchbook = farSwatchbook;
    farSwift = farSwift;
    farSwimmer = farSwimmer;
    farSwimmingPool = farSwimmingPool;
    farSword = farSword;
    farSwordLaser = farSwordLaser;
    farSwordLaserAlt = farSwordLaserAlt;
    farSwords = farSwords;
    farSwordsLaser = farSwordsLaser;
    farSymbols = farSymbols;
    farSymfony = farSymfony;
    farSynagogue = farSynagogue;
    farSync = farSync;
    farSyncAlt = farSyncAlt;
    farSyringe = farSyringe;
    farT = farT;
    farTable = farTable;
    farTableCells = farTableCells;
    farTableCellsLarge = farTableCellsLarge;
    farTableColumns = farTableColumns;
    farTableLayout = farTableLayout;
    farTableList = farTableList;
    farTablePicnic = farTablePicnic;
    farTablePivot = farTablePivot;
    farTableRows = farTableRows;
    farTablet = farTablet;
    farTabletAlt = farTabletAlt;
    farTabletAndroid = farTabletAndroid;
    farTabletAndroidAlt = farTabletAndroidAlt;
    farTabletButton = farTabletButton;
    farTableTennis = farTableTennis;
    farTableTennisPaddleBall = farTableTennisPaddleBall;
    farTableTree = farTableTree;
    farTabletRugged = farTabletRugged;
    farTablets = farTablets;
    farTabletScreen = farTabletScreen;
    farTabletScreenButton = farTabletScreenButton;
    farTachographDigital = farTachographDigital;
    farTachometer = farTachometer;
    farTachometerAlt = farTachometerAlt;
    farTachometerAltAverage = farTachometerAltAverage;
    farTachometerAltFast = farTachometerAltFast;
    farTachometerAltFastest = farTachometerAltFastest;
    farTachometerAltSlow = farTachometerAltSlow;
    farTachometerAltSlowest = farTachometerAltSlowest;
    farTachometerAverage = farTachometerAverage;
    farTachometerFast = farTachometerFast;
    farTachometerFastest = farTachometerFastest;
    farTachometerSlow = farTachometerSlow;
    farTachometerSlowest = farTachometerSlowest;
    farTaco = farTaco;
    farTag = farTag;
    farTags = farTags;
    farTally = farTally;
    farTally1 = farTally1;
    farTally2 = farTally2;
    farTally3 = farTally3;
    farTally4 = farTally4;
    farTally5 = farTally5;
    farTamale = farTamale;
    farTanakh = farTanakh;
    farTankWater = farTankWater;
    farTape = farTape;
    farTarp = farTarp;
    farTarpDroplet = farTarpDroplet;
    farTasks = farTasks;
    farTasksAlt = farTasksAlt;
    farTaxi = farTaxi;
    farTaxiBus = farTaxiBus;
    farTeamspeak = farTeamspeak;
    farTeddyBear = farTeddyBear;
    farTeeth = farTeeth;
    farTeethOpen = farTeethOpen;
    farTelegram = farTelegram;
    farTelegramPlane = farTelegramPlane;
    farTelescope = farTelescope;
    farTeletype = farTeletype;
    farTeletypeAnswer = farTeletypeAnswer;
    farTelevision = farTelevision;
    farTemperature0 = farTemperature0;
    farTemperature1 = farTemperature1;
    farTemperature2 = farTemperature2;
    farTemperature3 = farTemperature3;
    farTemperature4 = farTemperature4;
    farTemperatureArrowDown = farTemperatureArrowDown;
    farTemperatureArrowUp = farTemperatureArrowUp;
    farTemperatureDown = farTemperatureDown;
    farTemperatureEmpty = farTemperatureEmpty;
    farTemperatureFrigid = farTemperatureFrigid;
    farTemperatureFull = farTemperatureFull;
    farTemperatureHalf = farTemperatureHalf;
    farTemperatureHigh = farTemperatureHigh;
    farTemperatureHot = farTemperatureHot;
    farTemperatureList = farTemperatureList;
    farTemperatureLow = farTemperatureLow;
    farTemperatureQuarter = farTemperatureQuarter;
    farTemperatureSnow = farTemperatureSnow;
    farTemperatureSun = farTemperatureSun;
    farTemperatureThreeQuarters = farTemperatureThreeQuarters;
    farTemperatureUp = farTemperatureUp;
    farTencentWeibo = farTencentWeibo;
    farTenge = farTenge;
    farTengeSign = farTengeSign;
    farTennisBall = farTennisBall;
    farTent = farTent;
    farTentArrowDownToLine = farTentArrowDownToLine;
    farTentArrowLeftRight = farTentArrowLeftRight;
    farTentArrowsDown = farTentArrowsDown;
    farTentArrowTurnLeft = farTentArrowTurnLeft;
    farTentDoublePeak = farTentDoublePeak;
    farTents = farTents;
    farTerminal = farTerminal;
    farText = farText;
    farTextHeight = farTextHeight;
    farTextSize = farTextSize;
    farTextSlash = farTextSlash;
    farTextWidth = farTextWidth;
    farTh = farTh;
    farTheaterMasks = farTheaterMasks;
    farThemeco = farThemeco;
    farThemeisle = farThemeisle;
    farTheRedYeti = farTheRedYeti;
    farThermometer = farThermometer;
    farThermometer0 = farThermometer0;
    farThermometer1 = farThermometer1;
    farThermometer2 = farThermometer2;
    farThermometer3 = farThermometer3;
    farThermometer4 = farThermometer4;
    farThermometerEmpty = farThermometerEmpty;
    farThermometerFull = farThermometerFull;
    farThermometerHalf = farThermometerHalf;
    farThermometerQuarter = farThermometerQuarter;
    farThermometerThreeQuarters = farThermometerThreeQuarters;
    farTheta = farTheta;
    farThinkPeaks = farThinkPeaks;
    farThLarge = farThLarge;
    farThList = farThList;
    farThoughtBubble = farThoughtBubble;
    farThreads = farThreads;
    farThumbsDown = farThumbsDown;
    farThumbsUp = farThumbsUp;
    farThumbtack = farThumbtack;
    farThunderstorm = farThunderstorm;
    farThunderstormMoon = farThunderstormMoon;
    farThunderstormSun = farThunderstormSun;
    farTick = farTick;
    farTicket = farTicket;
    farTicketAirline = farTicketAirline;
    farTicketAlt = farTicketAlt;
    farTicketPerforated = farTicketPerforated;
    farTicketPerforatedPlane = farTicketPerforatedPlane;
    farTicketPlane = farTicketPlane;
    farTickets = farTickets;
    farTicketsAirline = farTicketsAirline;
    farTicketSimple = farTicketSimple;
    farTicketsPerforated = farTicketsPerforated;
    farTicketsPerforatedPlane = farTicketsPerforatedPlane;
    farTicketsPlane = farTicketsPlane;
    farTicketsSimple = farTicketsSimple;
    farTiktok = farTiktok;
    farTilde = farTilde;
    farTimeline = farTimeline;
    farTimelineArrow = farTimelineArrow;
    farTimer = farTimer;
    farTimes = farTimes;
    farTimesCircle = farTimesCircle;
    farTimesHexagon = farTimesHexagon;
    farTimesOctagon = farTimesOctagon;
    farTimesRectangle = farTimesRectangle;
    farTimesSquare = farTimesSquare;
    farTimesToSlot = farTimesToSlot;
    farTint = farTint;
    farTintSlash = farTintSlash;
    farTire = farTire;
    farTired = farTired;
    farTireFlat = farTireFlat;
    farTirePressureWarning = farTirePressureWarning;
    farTireRugged = farTireRugged;
    farToggleLargeOff = farToggleLargeOff;
    farToggleLargeOn = farToggleLargeOn;
    farToggleOff = farToggleOff;
    farToggleOn = farToggleOn;
    farToilet = farToilet;
    farToiletPaper = farToiletPaper;
    farToiletPaperAlt = farToiletPaperAlt;
    farToiletPaperBlank = farToiletPaperBlank;
    farToiletPaperBlankUnder = farToiletPaperBlankUnder;
    farToiletPaperCheck = farToiletPaperCheck;
    farToiletPaperReverse = farToiletPaperReverse;
    farToiletPaperReverseAlt = farToiletPaperReverseAlt;
    farToiletPaperReverseSlash = farToiletPaperReverseSlash;
    farToiletPaperSlash = farToiletPaperSlash;
    farToiletPaperUnder = farToiletPaperUnder;
    farToiletPaperUnderSlash = farToiletPaperUnderSlash;
    farToiletPaperXmark = farToiletPaperXmark;
    farToiletPortable = farToiletPortable;
    farToiletsPortable = farToiletsPortable;
    farTomato = farTomato;
    farTombstone = farTombstone;
    farTombstoneAlt = farTombstoneAlt;
    farTombstoneBlank = farTombstoneBlank;
    farToolbox = farToolbox;
    farTools = farTools;
    farTooth = farTooth;
    farToothbrush = farToothbrush;
    farTorah = farTorah;
    farToriiGate = farToriiGate;
    farTornado = farTornado;
    farTowerBroadcast = farTowerBroadcast;
    farTowerCell = farTowerCell;
    farTowerControl = farTowerControl;
    farTowerObservation = farTowerObservation;
    farTractor = farTractor;
    farTradeFederation = farTradeFederation;
    farTrademark = farTrademark;
    farTrafficCone = farTrafficCone;
    farTrafficLight = farTrafficLight;
    farTrafficLightGo = farTrafficLightGo;
    farTrafficLightSlow = farTrafficLightSlow;
    farTrafficLightStop = farTrafficLightStop;
    farTrailer = farTrailer;
    farTrain = farTrain;
    farTrainSubway = farTrainSubway;
    farTrainSubwayTunnel = farTrainSubwayTunnel;
    farTrainTrack = farTrainTrack;
    farTrainTram = farTrainTram;
    farTrainTunnel = farTrainTunnel;
    farTram = farTram;
    farTransformerBolt = farTransformerBolt;
    farTransgender = farTransgender;
    farTransgenderAlt = farTransgenderAlt;
    farTransporter = farTransporter;
    farTransporter1 = farTransporter1;
    farTransporter2 = farTransporter2;
    farTransporter3 = farTransporter3;
    farTransporter4 = farTransporter4;
    farTransporter5 = farTransporter5;
    farTransporter6 = farTransporter6;
    farTransporter7 = farTransporter7;
    farTransporterEmpty = farTransporterEmpty;
    farTrash = farTrash;
    farTrashAlt = farTrashAlt;
    farTrashAltSlash = farTrashAltSlash;
    farTrashArrowTurnLeft = farTrashArrowTurnLeft;
    farTrashArrowUp = farTrashArrowUp;
    farTrashCan = farTrashCan;
    farTrashCanArrowTurnLeft = farTrashCanArrowTurnLeft;
    farTrashCanArrowUp = farTrashCanArrowUp;
    farTrashCanCheck = farTrashCanCheck;
    farTrashCanClock = farTrashCanClock;
    farTrashCanList = farTrashCanList;
    farTrashCanPlus = farTrashCanPlus;
    farTrashCanSlash = farTrashCanSlash;
    farTrashCanUndo = farTrashCanUndo;
    farTrashCanXmark = farTrashCanXmark;
    farTrashCheck = farTrashCheck;
    farTrashCircle = farTrashCircle;
    farTrashClock = farTrashClock;
    farTrashList = farTrashList;
    farTrashPlus = farTrashPlus;
    farTrashRestore = farTrashRestore;
    farTrashRestoreAlt = farTrashRestoreAlt;
    farTrashSlash = farTrashSlash;
    farTrashUndo = farTrashUndo;
    farTrashUndoAlt = farTrashUndoAlt;
    farTrashXmark = farTrashXmark;
    farTreasureChest = farTreasureChest;
    farTree = farTree;
    farTreeAlt = farTreeAlt;
    farTreeChristmas = farTreeChristmas;
    farTreeCity = farTreeCity;
    farTreeDeciduous = farTreeDeciduous;
    farTreeDecorated = farTreeDecorated;
    farTreeLarge = farTreeLarge;
    farTreePalm = farTreePalm;
    farTrees = farTrees;
    farTrello = farTrello;
    farTRex = farTRex;
    farTrianBalbot = farTrianBalbot;
    farTriangle = farTriangle;
    farTriangleCircleSquare = farTriangleCircleSquare;
    farTriangleExclamation = farTriangleExclamation;
    farTriangleInstrument = farTriangleInstrument;
    farTriangleMusic = farTriangleMusic;
    farTrianglePersonDigging = farTrianglePersonDigging;
    farTricycle = farTricycle;
    farTricycleAdult = farTricycleAdult;
    farTrillium = farTrillium;
    farTrophy = farTrophy;
    farTrophyAlt = farTrophyAlt;
    farTrophyStar = farTrophyStar;
    farTrowel = farTrowel;
    farTrowelBricks = farTrowelBricks;
    farTruck = farTruck;
    farTruckArrowRight = farTruckArrowRight;
    farTruckBolt = farTruckBolt;
    farTruckClock = farTruckClock;
    farTruckContainer = farTruckContainer;
    farTruckContainerEmpty = farTruckContainerEmpty;
    farTruckCouch = farTruckCouch;
    farTruckDroplet = farTruckDroplet;
    farTruckFast = farTruckFast;
    farTruckField = farTruckField;
    farTruckFieldUn = farTruckFieldUn;
    farTruckFire = farTruckFire;
    farTruckFlatbed = farTruckFlatbed;
    farTruckFront = farTruckFront;
    farTruckLadder = farTruckLadder;
    farTruckLoading = farTruckLoading;
    farTruckMedical = farTruckMedical;
    farTruckMonster = farTruckMonster;
    farTruckMoving = farTruckMoving;
    farTruckPickup = farTruckPickup;
    farTruckPlane = farTruckPlane;
    farTruckPlow = farTruckPlow;
    farTruckRamp = farTruckRamp;
    farTruckRampBox = farTruckRampBox;
    farTruckRampCouch = farTruckRampCouch;
    farTruckTow = farTruckTow;
    farTruckUtensils = farTruckUtensils;
    farTrumpet = farTrumpet;
    farTry = farTry;
    farTshirt = farTshirt;
    farTty = farTty;
    farTtyAnswer = farTtyAnswer;
    farTugrikSign = farTugrikSign;
    farTumblr = farTumblr;
    farTumblrSquare = farTumblrSquare;
    farTurkey = farTurkey;
    farTurkishLira = farTurkishLira;
    farTurkishLiraSign = farTurkishLiraSign;
    farTurnDown = farTurnDown;
    farTurnDownLeft = farTurnDownLeft;
    farTurnDownRight = farTurnDownRight;
    farTurnLeft = farTurnLeft;
    farTurnLeftDown = farTurnLeftDown;
    farTurnLeftUp = farTurnLeftUp;
    farTurnRight = farTurnRight;
    farTurntable = farTurntable;
    farTurnUp = farTurnUp;
    farTurtle = farTurtle;
    farTv = farTv;
    farTvAlt = farTvAlt;
    farTvMusic = farTvMusic;
    farTvRetro = farTvRetro;
    farTwitch = farTwitch;
    farTwitter = farTwitter;
    farTwitterSquare = farTwitterSquare;
    farTypewriter = farTypewriter;
    farTypo3 = farTypo3;
    farU = farU;
    farUber = farUber;
    farUbuntu = farUbuntu;
    farUfo = farUfo;
    farUfoBeam = farUfoBeam;
    farUikit = farUikit;
    farUmbraco = farUmbraco;
    farUmbrella = farUmbrella;
    farUmbrellaAlt = farUmbrellaAlt;
    farUmbrellaBeach = farUmbrellaBeach;
    farUmbrellaSimple = farUmbrellaSimple;
    farUncharted = farUncharted;
    farUnderline = farUnderline;
    farUndo = farUndo;
    farUndoAlt = farUndoAlt;
    farUnicorn = farUnicorn;
    farUniformMartialArts = farUniformMartialArts;
    farUnion = farUnion;
    farUniregistry = farUniregistry;
    farUnity = farUnity;
    farUniversalAccess = farUniversalAccess;
    farUniversity = farUniversity;
    farUnlink = farUnlink;
    farUnlock = farUnlock;
    farUnlockAlt = farUnlockAlt;
    farUnlockKeyhole = farUnlockKeyhole;
    farUnsorted = farUnsorted;
    farUnsplash = farUnsplash;
    farUntappd = farUntappd;
    farUp = farUp;
    farUpDown = farUpDown;
    farUpDownLeftRight = farUpDownLeftRight;
    farUpFromBracket = farUpFromBracket;
    farUpFromDottedLine = farUpFromDottedLine;
    farUpFromLine = farUpFromLine;
    farUpLeft = farUpLeft;
    farUpload = farUpload;
    farUpLong = farUpLong;
    farUpRight = farUpRight;
    farUpRightAndDownLeftFromCenter = farUpRightAndDownLeftFromCenter;
    farUpRightFromSquare = farUpRightFromSquare;
    farUps = farUps;
    farUpToDottedLine = farUpToDottedLine;
    farUpToLine = farUpToLine;
    farUpwork = farUpwork;
    farUsb = farUsb;
    farUsbDrive = farUsbDrive;
    farUsd = farUsd;
    farUsdCircle = farUsdCircle;
    farUsdSquare = farUsdSquare;
    farUser = farUser;
    farUserAlien = farUserAlien;
    farUserAlt = farUserAlt;
    farUserAltSlash = farUserAltSlash;
    farUserAstronaut = farUserAstronaut;
    farUserBountyHunter = farUserBountyHunter;
    farUserChart = farUserChart;
    farUserCheck = farUserCheck;
    farUserChef = farUserChef;
    farUserCircle = farUserCircle;
    farUserClock = farUserClock;
    farUserCog = farUserCog;
    farUserConstruction = farUserConstruction;
    farUserCowboy = farUserCowboy;
    farUserCrown = farUserCrown;
    farUserDoctor = farUserDoctor;
    farUserDoctorHair = farUserDoctorHair;
    farUserDoctorHairLong = farUserDoctorHairLong;
    farUserDoctorMessage = farUserDoctorMessage;
    farUserEdit = farUserEdit;
    farUserFriends = farUserFriends;
    farUserGear = farUserGear;
    farUserGraduate = farUserGraduate;
    farUserGroup = farUserGroup;
    farUserGroupCrown = farUserGroupCrown;
    farUserGroupSimple = farUserGroupSimple;
    farUserHair = farUserHair;
    farUserHairBuns = farUserHairBuns;
    farUserHairLong = farUserHairLong;
    farUserHairMullet = farUserHairMullet;
    farUserHardHat = farUserHardHat;
    farUserHeadset = farUserHeadset;
    farUserHelmetSafety = farUserHelmetSafety;
    farUserInjured = farUserInjured;
    farUserLarge = farUserLarge;
    farUserLargeSlash = farUserLargeSlash;
    farUserLock = farUserLock;
    farUserMagnifyingGlass = farUserMagnifyingGlass;
    farUserMd = farUserMd;
    farUserMdChat = farUserMdChat;
    farUserMinus = farUserMinus;
    farUserMusic = farUserMusic;
    farUserNinja = farUserNinja;
    farUserNurse = farUserNurse;
    farUserNurseHair = farUserNurseHair;
    farUserNurseHairLong = farUserNurseHairLong;
    farUserPen = farUserPen;
    farUserPilot = farUserPilot;
    farUserPilotTie = farUserPilotTie;
    farUserPlus = farUserPlus;
    farUserPolice = farUserPolice;
    farUserPoliceTie = farUserPoliceTie;
    farUserRobot = farUserRobot;
    farUserRobotXmarks = farUserRobotXmarks;
    farUsers = farUsers;
    farUsersBetweenLines = farUsersBetweenLines;
    farUsersClass = farUsersClass;
    farUsersCog = farUsersCog;
    farUsersCrown = farUsersCrown;
    farUserSecret = farUserSecret;
    farUsersGear = farUsersGear;
    farUserShakespeare = farUserShakespeare;
    farUserShield = farUserShield;
    farUserSlash = farUserSlash;
    farUsersLine = farUsersLine;
    farUsersMedical = farUsersMedical;
    farUsersRays = farUsersRays;
    farUsersRectangle = farUsersRectangle;
    farUsersSlash = farUsersSlash;
    farUsersViewfinder = farUsersViewfinder;
    farUserTag = farUserTag;
    farUserTie = farUserTie;
    farUserTieHair = farUserTieHair;
    farUserTieHairLong = farUserTieHairLong;
    farUserTimes = farUserTimes;
    farUserUnlock = farUserUnlock;
    farUserVisor = farUserVisor;
    farUserVneck = farUserVneck;
    farUserVneckHair = farUserVneckHair;
    farUserVneckHairLong = farUserVneckHairLong;
    farUserXmark = farUserXmark;
    farUsps = farUsps;
    farUssunnah = farUssunnah;
    farUtensilFork = farUtensilFork;
    farUtensilKnife = farUtensilKnife;
    farUtensils = farUtensils;
    farUtensilsAlt = farUtensilsAlt;
    farUtensilSpoon = farUtensilSpoon;
    farUtensilsSlash = farUtensilsSlash;
    farUtilityPole = farUtilityPole;
    farUtilityPoleDouble = farUtilityPoleDouble;
    farV = farV;
    farVaadin = farVaadin;
    farVacuum = farVacuum;
    farVacuumRobot = farVacuumRobot;
    farValueAbsolute = farValueAbsolute;
    farVanShuttle = farVanShuttle;
    farVault = farVault;
    farVcard = farVcard;
    farVectorCircle = farVectorCircle;
    farVectorPolygon = farVectorPolygon;
    farVectorSquare = farVectorSquare;
    farVentDamper = farVentDamper;
    farVenus = farVenus;
    farVenusDouble = farVenusDouble;
    farVenusMars = farVenusMars;
    farVest = farVest;
    farVestPatches = farVestPatches;
    farVhs = farVhs;
    farViacoin = farViacoin;
    farViadeo = farViadeo;
    farViadeoSquare = farViadeoSquare;
    farVial = farVial;
    farVialCircleCheck = farVialCircleCheck;
    farVials = farVials;
    farVialVirus = farVialVirus;
    farViber = farViber;
    farVideo = farVideo;
    farVideoArrowDownLeft = farVideoArrowDownLeft;
    farVideoArrowUpRight = farVideoArrowUpRight;
    farVideoCamera = farVideoCamera;
    farVideoCircle = farVideoCircle;
    farVideoHandheld = farVideoHandheld;
    farVideoPlus = farVideoPlus;
    farVideoSlash = farVideoSlash;
    farVihara = farVihara;
    farVimeo = farVimeo;
    farVimeoSquare = farVimeoSquare;
    farVimeoV = farVimeoV;
    farVine = farVine;
    farViolin = farViolin;
    farVirus = farVirus;
    farVirusCovid = farVirusCovid;
    farVirusCovidSlash = farVirusCovidSlash;
    farViruses = farViruses;
    farVirusSlash = farVirusSlash;
    farVk = farVk;
    farVnv = farVnv;
    farVoicemail = farVoicemail;
    farVolcano = farVolcano;
    farVolleyball = farVolleyball;
    farVolleyballBall = farVolleyballBall;
    farVolume = farVolume;
    farVolumeControlPhone = farVolumeControlPhone;
    farVolumeDown = farVolumeDown;
    farVolumeHigh = farVolumeHigh;
    farVolumeLow = farVolumeLow;
    farVolumeMedium = farVolumeMedium;
    farVolumeMute = farVolumeMute;
    farVolumeOff = farVolumeOff;
    farVolumeSlash = farVolumeSlash;
    farVolumeTimes = farVolumeTimes;
    farVolumeUp = farVolumeUp;
    farVolumeXmark = farVolumeXmark;
    farVoteNay = farVoteNay;
    farVoteYea = farVoteYea;
    farVrCardboard = farVrCardboard;
    farVuejs = farVuejs;
    farW = farW;
    farWaffle = farWaffle;
    farWagonCovered = farWagonCovered;
    farWalker = farWalker;
    farWalkieTalkie = farWalkieTalkie;
    farWalking = farWalking;
    farWallBrick = farWallBrick;
    farWallet = farWallet;
    farWand = farWand;
    farWandMagic = farWandMagic;
    farWandMagicSparkles = farWandMagicSparkles;
    farWandSparkles = farWandSparkles;
    farWarehouse = farWarehouse;
    farWarehouseAlt = farWarehouseAlt;
    farWarehouseFull = farWarehouseFull;
    farWarning = farWarning;
    farWasher = farWasher;
    farWashingMachine = farWashingMachine;
    farWatch = farWatch;
    farWatchApple = farWatchApple;
    farWatchCalculator = farWatchCalculator;
    farWatchFitness = farWatchFitness;
    farWatchmanMonitoring = farWatchmanMonitoring;
    farWatchSmart = farWatchSmart;
    farWater = farWater;
    farWaterArrowDown = farWaterArrowDown;
    farWaterArrowUp = farWaterArrowUp;
    farWaterLadder = farWaterLadder;
    farWaterLower = farWaterLower;
    farWatermelonSlice = farWatermelonSlice;
    farWaterRise = farWaterRise;
    farWave = farWave;
    farWaveform = farWaveform;
    farWaveformCircle = farWaveformCircle;
    farWaveformLines = farWaveformLines;
    farWaveformPath = farWaveformPath;
    farWavePulse = farWavePulse;
    farWaveSine = farWaveSine;
    farWaveSquare = farWaveSquare;
    farWavesSine = farWavesSine;
    farWaveTriangle = farWaveTriangle;
    farWaze = farWaze;
    farWebcam = farWebcam;
    farWebcamSlash = farWebcamSlash;
    farWebflow = farWebflow;
    farWebhook = farWebhook;
    farWeebly = farWeebly;
    farWeibo = farWeibo;
    farWeight = farWeight;
    farWeightHanging = farWeightHanging;
    farWeightScale = farWeightScale;
    farWeixin = farWeixin;
    farWhale = farWhale;
    farWhatsapp = farWhatsapp;
    farWhatsappSquare = farWhatsappSquare;
    farWheat = farWheat;
    farWheatAlt = farWheatAlt;
    farWheatAwn = farWheatAwn;
    farWheatAwnCircleExclamation = farWheatAwnCircleExclamation;
    farWheatAwnSlash = farWheatAwnSlash;
    farWheatSlash = farWheatSlash;
    farWheelchair = farWheelchair;
    farWheelchairAlt = farWheelchairAlt;
    farWheelchairMove = farWheelchairMove;
    farWhiskeyGlass = farWhiskeyGlass;
    farWhiskeyGlassIce = farWhiskeyGlassIce;
    farWhistle = farWhistle;
    farWhmcs = farWhmcs;
    farWifi = farWifi;
    farWifi1 = farWifi1;
    farWifi2 = farWifi2;
    farWifi3 = farWifi3;
    farWifiExclamation = farWifiExclamation;
    farWifiFair = farWifiFair;
    farWifiSlash = farWifiSlash;
    farWifiStrong = farWifiStrong;
    farWifiWeak = farWifiWeak;
    farWikipediaW = farWikipediaW;
    farWind = farWind;
    farWindCircleExclamation = farWindCircleExclamation;
    farWindow = farWindow;
    farWindowAlt = farWindowAlt;
    farWindowClose = farWindowClose;
    farWindowFlip = farWindowFlip;
    farWindowFrame = farWindowFrame;
    farWindowFrameOpen = farWindowFrameOpen;
    farWindowMaximize = farWindowMaximize;
    farWindowMinimize = farWindowMinimize;
    farWindowRestore = farWindowRestore;
    farWindows = farWindows;
    farWindsock = farWindsock;
    farWindTurbine = farWindTurbine;
    farWindWarning = farWindWarning;
    farWineBottle = farWineBottle;
    farWineGlass = farWineGlass;
    farWineGlassAlt = farWineGlassAlt;
    farWineGlassCrack = farWineGlassCrack;
    farWineGlassEmpty = farWineGlassEmpty;
    farWirsindhandwerk = farWirsindhandwerk;
    farWix = farWix;
    farWizardsOfTheCoast = farWizardsOfTheCoast;
    farWodu = farWodu;
    farWolfPackBattalion = farWolfPackBattalion;
    farWon = farWon;
    farWonSign = farWonSign;
    farWordpress = farWordpress;
    farWordpressSimple = farWordpressSimple;
    farWorm = farWorm;
    farWpbeginner = farWpbeginner;
    farWpexplorer = farWpexplorer;
    farWpforms = farWpforms;
    farWpressr = farWpressr;
    farWreath = farWreath;
    farWreathLaurel = farWreathLaurel;
    farWrench = farWrench;
    farWrenchSimple = farWrenchSimple;
    farWsh = farWsh;
    farX = farX;
    farXbox = farXbox;
    farXing = farXing;
    farXingSquare = farXingSquare;
    farXmark = farXmark;
    farXmarkCircle = farXmarkCircle;
    farXmarkHexagon = farXmarkHexagon;
    farXmarkLarge = farXmarkLarge;
    farXmarkOctagon = farXmarkOctagon;
    farXmarksLines = farXmarksLines;
    farXmarkSquare = farXmarkSquare;
    farXmarkToSlot = farXmarkToSlot;
    farXRay = farXRay;
    farXTwitter = farXTwitter;
    farY = farY;
    farYahoo = farYahoo;
    farYammer = farYammer;
    farYandex = farYandex;
    farYandexInternational = farYandexInternational;
    farYarn = farYarn;
    farYCombinator = farYCombinator;
    farYelp = farYelp;
    farYen = farYen;
    farYenSign = farYenSign;
    farYinYang = farYinYang;
    farYoast = farYoast;
    farYoutube = farYoutube;
    farYoutubeSquare = farYoutubeSquare;
    farZ = farZ;
    farZap = farZap;
    farZhihu = farZhihu;
    farZzz = farZzz;
}
