import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { AppCommonModule } from '@app/shared/common/app-common.module';
import { SubMenuComponent } from './sub-menu.component';
import { MenubarModule } from 'primeng/menubar';

@NgModule({
  declarations: [
    SubMenuComponent
  ],
  imports: [
    CommonModule,
    MenubarModule,
    //AppCommonModule,
  ],
  exports: [
    SubMenuComponent
  ]
})
export class SubMenuModule { }
