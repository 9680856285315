import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LeaveRequestAddComponent } from '@app/leave/leave-request-add/leave-request-add.component';
import { LeaveRequestEditComponent } from '@app/leave/leave-request-edit/leave-request-edit.component';
import { SubMenuModule } from '../common/sub-menu/sub-menu.module';
import { ReferralAddComponent } from '@app/referral/referral-add/referral-add.component';
import { SvlAddEditComponent } from '@app/svl/svl-add-edit/svl-add-edit.component';
import { ChangesComponent } from '@app/changes/changes.component';
//import { AppCommonModule } from '../common/app-common.module';
import { VendorSharedModule } from '../vendor/vendor-shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UtilsModule } from "../../../shared/utils/utils.module";

@NgModule({
    imports: [
        CommonModule,
        SubMenuModule,
        FormsModule,
        VendorSharedModule,
        ModalModule.forRoot(),
        AppBsModalModule,
        BsDatepickerModule.forRoot(),
        UtilsModule
    ],
    declarations: [
        LeaveRequestAddComponent,
        LeaveRequestEditComponent,
        ReferralAddComponent,
        SvlAddEditComponent,
        ChangesComponent,
    ],
    exports: [
        LeaveRequestAddComponent,
        LeaveRequestEditComponent,
        ReferralAddComponent,
        SvlAddEditComponent,
        SubMenuModule,
        ChangesComponent,
        FormsModule,
        VendorSharedModule,
        //AppCommonModule,
    ],
    providers: [BsDatepickerConfig]
    
})
export class ComponentSharedModule {
}
