import { Injectable, Injector } from '@angular/core';
import { ChangeService } from '@app/changes/_services/change.service';
import { TimecardApiService } from '@app/finance/timecard/_services/timecard-api.service';
import { TimecardDataService } from '@app/finance/timecard/_services/timecard-data.service';
import { TimecardFormService } from '@app/finance/timecard/_services/timecard-form.service';
import { TimecardMainService } from '@app/finance/timecard/_services/timecard-main.service';
import { TimecardVariablesService } from '@app/finance/timecard/_services/timecard-variables.service';
import { LeaveRequestService } from '@app/leave/_service/leave-request.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpBaseService } from './http-base.service';
import { ReferralService } from '@app/referral/_service/referral.service';
import { AzureFileService } from './azure-file.service';
import { RedisService } from './redis.service';


@Injectable({
    providedIn: 'root',
})

// prettier-ignore
export class RootService {
    _api:        TimecardApiService;
    _azfile:         AzureFileService;
    _change:     ChangeService
    _data:       TimecardDataService;
    _form:       TimecardFormService;
    _http:       HttpBaseService;
    _leave:      LeaveRequestService;
    _main:       TimecardMainService;
    _modal:      BsModalService
    _referral:   ReferralService;
    _redis:      RedisService;
    _var:        TimecardVariablesService;

    constructor(private injector: Injector
    ) {
        
    }

    initServices() {
        this._api      =   this.injector.get<TimecardApiService>(TimecardApiService);
        this._azfile       =   this.injector.get<AzureFileService>(AzureFileService);
        this._change   =   this.injector.get<ChangeService>(ChangeService);
        this._data     =   this.injector.get<TimecardDataService>(TimecardDataService);
        this._form     =   this.injector.get<TimecardFormService>(TimecardFormService);
        this._http     =   this.injector.get<HttpBaseService>(HttpBaseService);
        this._leave    =   this.injector.get<LeaveRequestService>(LeaveRequestService);
        this._main     =   this.injector.get<TimecardMainService>(TimecardMainService);
        this._modal    =   this.injector.get<BsModalService>(BsModalService);
        this._referral =   this.injector.get<ReferralService>(ReferralService);
        this._redis    =   this.injector.get<RedisService>(RedisService);
        this._var      =   this.injector.get<TimecardVariablesService>(TimecardVariablesService);
    }

    // prettier-ignore
    //? ** This is called from the timecard.component.ts as a hack to get around circular dependency **
    // initServices(injector: Injector) {
    //     injector       =   injector;
    //     this._api      =   injector.get(TimecardApiService);
    //     this._azfile       =   injector.get(AzureFileService);
    //     this._change   =   injector.get(ChangeService);
    //     this._data     =   injector.get(TimecardDataService);
    //     this._form     =   injector.get(TimecardFormService);
    //     this._http     =   injector.get(HttpBaseService);
    //     this._leave    =   injector.get(LeaveRequestService);
    //     this._main     =   injector.get(TimecardMainService);
    //     this._modal    =   injector.get(BsModalService);
    //     this._referral =   injector.get(ReferralService);
    //     this._redis    =   injector.get(RedisService);
    //     this._var      =   injector.get(TimecardVariablesService);
    // }

}
