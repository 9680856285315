import { Injectable } from '@angular/core';
import { RootService } from '@app/shared/_services/root.service';
import { TimecardDataService } from './timecard-data.service';
import { TimecardVariablesService } from './timecard-variables.service';

@Injectable({
    providedIn: 'root',
})
export class TimecardMainService {
    timecard_tooltips_visible: boolean = false;
    allow_future_dates: boolean = false;

    constructor(
        private _root: RootService,
        //private _data: TimecardDataService,
        //private _var: TimecardVariablesService,

        ) {}

    openWorkTypeModal(e) {}

    openOvertimeAuthorizerModal(e) {}

    functionRunner(e) {}

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    setCalendar(offset: number) {
        //this._fg.rootForm = this._fg.setRootForm(null);
        this._root._data
            .parseEventValues({
                target: {
                    dataset: {
                        rowIndex: 0,
                        fieldType: 'standard',
                        dayIndex: 0,
                        fetchRow: 'true',
                        fetchDay: 'true',
                        saveRow: 'false',
                        autoSave: 'false',
                    },
                },
            })
            .then((res) => {
                this._root._data.setWeekData(offset).then((res) => {
                    this._root._data
                        .getRequestsByTsAndWeek(
                            1,
                            this._root._var.page.weekDates.week.number,
                            this._root._var.page.weekDates.year.number,
                        )
                        .then(() => {
                            this._root._data
                                .getRequestsPoPartByTsAndWeek(
                                    1,
                                    this._root._var.page.weekDates.week.number,
                                    this._root._var.page.weekDates.year.number,
                                )
                                .then(() => {
                                    this._root._data
                                        .getPendingTimecardsByWeekByTs(
                                            '',
                                            this._root._var.page.weekDates.week.number,
                                            this._root._var.page.weekDates.year.number,
                                        )
                                        .then(() => {
                                            this._root._data.createDayData();
                                        });
                                });
                        });
                });
            });

        //await this.initializeForm();

        //await this.mergePoPartData();
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    toggleTimecardTooltips() {
        this.timecard_tooltips_visible = !this.timecard_tooltips_visible;
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    changeTypeTab(e) {
        this._root._data.parseEventValues(e);
        this._root._data.setRowIdentifier();
        this._root._var.page.rows[this._root._var.rowIdentifier.row_index].display_entry_type =
            this._root._var.rowIdentifier.field_type;

        if (this._root._var.page.rows[this._root._var.rowIdentifier.row_index]._id) {
            this._root._var.rowIdentifier.auto_save = true;
            this._root._data.autoSaveTeEntry();
        }
    }
    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    // prettier-ignore
    showExpenseModal() {
        //this._root._data.s_expense.row_index = this._root._var.rowIdentifier.row_index;
        //this._root._data.s_expense.day_index = this._root._var.rowIdentifier.day_index;
        //this._root._data.s_expense.airfare = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].airfare;
        //this._root._data.s_expense.has_airfare_receipt = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].has_airfare_receipt;
        //this._root._data.s_expense.meals = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].meals;
        //this._root._data.s_expense.has_meals_receipt = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].has_meals_receipt;
        //this._root._data.s_expense.car_rental = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].car_rental;
        //this._root._data.s_expense.has_car_rental_receipt = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].has_car_rental_receipt;
        //this._root._data.s_expense.phone_fax = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].phone_fax;
        //this._root._data.s_expense.has_phone_fax_receipt = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].has_phone_fax_receipt;
        //this._root._data.s_expense.parking = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].parking;
        //this._root._data.s_expense.has_parking_receipt = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].has_parking_receipt;
        //this._root._data.s_expense.tolls = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].tolls;
        //this._root._data.s_expense.has_tolls_receipt = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].has_tolls_receipt;
        //this._root._data.s_expense.fuel = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].fuel;
        //this._root._data.s_expense.has_fuel_receipt = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].has_fuel_receipt;
        //this._root._data.s_expense.hotel = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].hotel;
        //this._root._data.s_expense.has_hotel_receipt = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].has_hotel_receipt;
        //this._root._data.s_expense.postage = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].postage;
        //this._root._data.s_expense.has_postage_receipt = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].has_postage_receipt;
        //this._root._data.s_expense.other =this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].other;
        //this._root._data.s_expense.has_other_receipt = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].has_other_receipt;
        //this._root._data.s_expense.other_comment = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].other_comment;
        //this._root._data.s_expense.travel_auth = this._root._var.page.rows[this._root._var.rowIdentifier.row_index].day_root._data[this._root._var.rowIdentifier.day_index].travel_auth;
        //this._root._data.display_expense_modal = true;
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

    showPoPartsModal() {
        //let promise = new Promise((resolve, reject) => {
            //this.setRowAndDay();
            //resolve(true);
        //}).then(() => {
            //this._root._data.s_po_part = new PoPartDto();
            //this._root._data.display_po_parts_modal = true;
            //this._root._data.s_po_part.day_index = this._root._data.s_day.day_index;
            //this._root._data.s_po_part.service_date = this._root._data.s_day.service_date;
            //this._root._data.s_po_part.day_index = this._root._data.s_day.day_index;
        //});
    }

    //*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
}
