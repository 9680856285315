import { Injectable } from '@angular/core';
import {
    LeaveCheckParams,
    LeaveCheckResult,
    LeaveDistributionList,
    LeaveTypeList,
    TsLeave,
} from '@app/leave/_model/leave-request.model';
import { Observable } from 'rxjs';
import { HttpBaseService } from '@app/shared/_services/http-base.service';
import { GetManyFilter } from '@app/shared/_interfaces/get-many-filter';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class LeaveRequestService {
    tsLeave: TsLeave = new TsLeave();
    tsLeaves: TsLeave[] = [];
    selectedLeaveRequest: TsLeave = new TsLeave();
    leaveTypeList: LeaveTypeList[] = [];
    leaveDates: Date[];
    minDate: Date;

    mode: string;

    constructor(private _http: HttpBaseService) {}

    // getLeaveTypes(): Observable<LeaveTypeListModel[]> {
    //   return this.http.get<LeaveTypeListModel[]>(environment.dataapi + 'LeaveRequest/GetLeaveTypes/' + abp.session.userId + '/false/', { headers: this.global.dataApiHeaders });
    // }

    createLeaveRequest(params: TsLeave): Observable<any> {
        return this._http.post('/leave/create', params);
    }



    updateLeaveRequest(params: TsLeave): Observable<TsLeave> {
        return this._http.patch('/leave/update-one', params);
    }

    // updateLeaveRequest(tsLeave: TsLeave): Observable<any> {
    //   return this.http.post<TsLeaveRequest>(
    //     environment.dataapi + 'LeaveRequest/UpdateLeaveRequest', tsLeave, { headers: this.global.dataApiHeaders }
    //   );
    // }

    //deleteLeaveRequest(id: number) {
    //  return this._http.delete(`/leave/delete-one`, id );
    //}

    deleteLeaveRequest(id: number) {
        return this._http.delete(`/leave/delete-one/${id}`);
    }
    
    getLeaveRequests(params: GetManyFilter): Observable<TsLeave[]> {
        return this._http.post('/leave/get-many/', params);
    }

    checkLeaveRequestExists(params: LeaveCheckParams): Observable<LeaveCheckResult> {
        return this._http.post('/leave/check', params); // <LeaveCheckResultModel>(
        //environment.dataapi + 'LeaveRequest/CheckLeaveRequest', leaveCheckRequest, { headers: this.global.dataApiHeaders }
    }
}
