import { Injectable } from '@angular/core';
import { HttpBaseService } from '@app/shared/_services/http-base.service';

@Injectable({
  providedIn: 'root'
})
export class ChangeService {

  constructor(
    private _http: HttpBaseService,
    //private _r: RootService,
  ) { }

  getChanges(): any {
    return this._http.get('/changes');
  }

}
