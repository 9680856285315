/**
 * @description : This base service provides angular get and post functionality to other pages
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import axios from 'axios';


@Injectable({
    providedIn: 'root',
})
export class HttpBaseService {
    env = '_STAGE';

    //env = '';
    constructor(public http: HttpClient) {}

    /**
     * Makes the http get request call using the http client
     * @params {string} url
     * @params {HttpParams} urlParams
     */

    //? AXIOS FUNCTIONS ?//

    public axInstance = axios.create({
        baseURL: environment.apiBaseUrl,
        headers: environment.axHeaders,
    });

    //?? STANDARD FUNCTIONS ??//

    public get(url: string, urlParams?: HttpParams): Observable<any> {
        console.log('url',environment.apiBaseUrl + url);
        return this.http.get(environment.apiBaseUrl + url, { params: urlParams, headers: environment.apiHeaders });
    }

    public post(url: string, body: any): Observable<any> {
        console.log(body);
        return this.http.post(environment.apiBaseUrl + url, body, { headers: environment.apiHeaders });
    }
    
    public put(url: string, body: any): Observable<any> {
        return this.http.put(environment.apiBaseUrl + url, body, { headers: environment.apiHeaders });
    }
    
    public patch(url: string, body: any): Observable<any> {
        return this.http.patch(environment.apiBaseUrl + url, body, { headers: environment.apiHeaders });
    }
    
    public delete(url: string, urlParams?: HttpParams): Observable<any> {
        return this.http.delete(environment.apiBaseUrl + url, { params: urlParams, headers: environment.apiHeaders });
    }


    //?? SPECIAL FUNCTIONS ??//

    public getReturnText(url: string, urlParams?: HttpParams): Observable<any> {
        return this.http.get(environment.apiBaseUrl + url, {
            params: urlParams,
            headers: environment.apiHeaders,
            responseType: 'text',
        });
    }

    public postServiceBus(url: string, body: any): Observable<any> {
        return this.http.post(environment.apiBaseUrl + url, body, {
            headers: environment.apiHeaders,
            responseType: 'blob',
        });
    }

    public postReturnText(url: string, body: any): Observable<any> {
        return this.http.post(environment.apiBaseUrl + url, body, {
            headers: environment.apiHeaders,
            responseType: 'text',
        });
    }

    public postMultiPart(url: string, body: any): Observable<any> {
        return this.http.post(environment.apiBaseUrl + url, body, { headers: environment.multiPartApiHeaders });
    }

    //?? MOCK FUNCTIONS ??//

    public mget(url: string, urlParams?: HttpParams): Observable<any> {
        return this.http.get(environment.mockApiBaseUrl + url, { params: urlParams, headers: environment.mockApiHeaders });
    }

    public mpost(url: string, body: any): Observable<any> {
        return this.http.post(environment.mockApiBaseUrl + url, body, { headers: environment.mockApiHeaders });
    }
    
    public mput(url: string, body: any): Observable<any> {
        return this.http.put(environment.mockApiBaseUrl + url, body, { headers: environment.mockApiHeaders });
    }
    
    public mpatch(url: string, body: any): Observable<any> {
        return this.http.patch(environment.mockApiBaseUrl + url, body, { headers: environment.mockApiHeaders });
    }
    
    public mdelete(url: string, urlParams?: HttpParams): Observable<any> {
        return this.http.delete(environment.mockApiBaseUrl + url, { params: urlParams, headers: environment.mockApiHeaders });
    }
}
