import { Injectable } from '@angular/core';
import moment from 'moment';
import { RowIdentifier } from '../_models/row-identifier';
import { Page } from '../_models/page.model';
import { Timecard } from '../_models/timecard.interface';
import { PoPart } from '../_models/po-part.interface';

@Injectable({
  providedIn: 'root'
})
export class TimecardVariablesService {

    tsId: number = 251063;

    page: Page = new Page();
    rowIdentifier: RowIdentifier = new RowIdentifier();
    savingRow: boolean = false;

    //weekDates: WeekDate = new WeekDate();
    //rowData = [] as Array<Row>;
    //dayData = [] as Array<Day>;
    
    prefilledTimecardData = [] as Array<Timecard>;
    prefilledPoPartData = [] as Array<PoPart>;
    existingTimecardList = [] as Array<Timecard>;
    existingTimecardData = [] as Array<Timecard>;
    tempPrefilledTimecardData = [] as Array<Timecard>;
    tempExistingTimecardDataManual = [] as Array<Timecard>;
    tempTimecardData = [] as Array<Timecard>;
    
    //timecardData = [] as Array<Timecard>;
    
    timecardKeys: Array<string>;

    selectedRowIndex: number = -1;
    selectedDayIndex: number = -1;
    expenseModalHeader: string;
    isEditMode = false;
    poPartsModalHeader: string;

    rowsToCheck: number[] = [];

    initRowIdentifier = {
        day_index: 0,
        row_index: 0,
        field_type: 'standard',
        event: null,
        from: 'timecard',
        auto_save: false,
        selected_date: null,
        prompt_type: null,
        head: null,
        receiver: null,
        focus_field: null,
        action: null,
        po_index: null,
        fetch_row: 'true',
        fetch_day: 'true',
        save_row: 'false',
    };

    initWeekData = {
        week: {
            number: parseInt(moment().locale('en-gb').startOf('week').format('W')),
            offset: 0,
            start: moment().locale('en-gb').startOf('week').format('MM/DD/YYYY'),
            end: moment().locale('en-gb').startOf('week').add(6, 'day').format('MM/DD/YYYY'),
            start_end_dates: `${moment().locale('en-gb').startOf('week').format('MM/DD/YYYY')},${moment()
                .locale('en-gb')
                .endOf('week')
                .format('MM/DD/YYYY')}`,
        },
        month: {
            number: parseInt(moment().locale('en-gb').startOf('month').format('M')),
            start: moment().locale('en-gb').startOf('month').format('MM/DD/YYYY'),
            end: moment().locale('en-gb').endOf('month').format('MM/DD/YYYY'),
        },
        year: {
            number: parseInt(moment().locale('en-gb').startOf('week').format('Y')),
            start: moment().locale('en-gb').startOf('year').format('MM/DD/YYYY'),
            end: moment().locale('en-gb').endOf('year').format('MM/DD/YYYY'),
        },
        day: {
            monday: {
                dom: moment().locale('en-gb').startOf('week').format('D'),
                month: moment().locale('en-gb').startOf('week').format('MMM'),
                julian_date: parseInt(moment().locale('en-gb').startOf('week').format('YYYYDDDD')),
                calendar_date: moment().locale('en-gb').startOf('week').format('MM/DD/YYYY'),
            },
            tuesday: {
                dom: moment().locale('en-gb').startOf('week').add(1, 'day').format('D'),
                month: moment().locale('en-gb').startOf('week').add(1, 'day').format('MMM'),
                julian_date: parseInt(moment().locale('en-gb').startOf('week').add(1, 'day').format('YYYYDDDD')),
                calendar_date: moment().locale('en-gb').startOf('week').add(1, 'day').format('MM/DD/YYYY'),
            },
            wednesday: {
                dom: moment().locale('en-gb').startOf('week').add(2, 'day').format('D'),
                month: moment().locale('en-gb').startOf('week').add(2, 'day').format('MMM'),
                julian_date: parseInt(moment().locale('en-gb').startOf('week').add(2, 'day').format('YYYYDDDD')),
                calendar_date: moment().locale('en-gb').startOf('week').add(2, 'day').format('MM/DD/YYYY'),
            },
            thursday: {
                dom: moment().locale('en-gb').startOf('week').add(3, 'day').format('D'),
                month: moment().locale('en-gb').startOf('week').add(3, 'day').format('MMM'),
                julian_date: parseInt(moment().locale('en-gb').startOf('week').add(3, 'day').format('YYYYDDDD')),
                calendar_date: moment().locale('en-gb').startOf('week').add(3, 'day').format('MM/DD/YYYY'),
            },
            friday: {
                dom: moment().locale('en-gb').startOf('week').add(4, 'day').format('D'),
                month: moment().locale('en-gb').startOf('week').add(4, 'day').format('MMM'),
                julian_date: parseInt(moment().locale('en-gb').startOf('week').add(4, 'day').format('YYYYDDDD')),
                calendar_date: moment().locale('en-gb').startOf('week').add(4, 'day').format('MM/DD/YYYY'),
            },
            saturday: {
                dom: moment().locale('en-gb').startOf('week').add(5, 'day').format('D'),
                month: moment().locale('en-gb').startOf('week').add(5, 'day').format('MMM'),
                julian_date: parseInt(moment().locale('en-gb').startOf('week').add(5, 'day').format('YYYYDDDD')),
                calendar_date: moment().locale('en-gb').startOf('week').add(5, 'day').format('MM/DD/YYYY'),
            },
            sunday: {
                dom: moment().locale('en-gb').startOf('week').add(6, 'day').format('D'),
                month: moment().locale('en-gb').startOf('week').add(6, 'day').format('MMM'),
                julian_date: parseInt(moment().locale('en-gb').startOf('week').add(6, 'day').format('YYYYDDDD')),
                calendar_date: moment().locale('en-gb').startOf('week').add(6, 'day').format('MM/DD/YYYY'),
            },
        },
        todays_julian_date: parseInt(moment().format('YYYYDDDD')),
        selected_date: moment().locale('en-gb').startOf('week').toDate(),
    };

    constructor(
    ) {
        this.rowIdentifier = this.initRowIdentifier;
        this.page.weekDates = this.initWeekData;
    }
}
