import { NgModule } from '@angular/core';
//import { CommonModule } from '@angular/common';

import { DialogModule } from 'primeng/dialog';
import { InplaceModule } from 'primeng/inplace';
import { DynamicDialogModule, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
//import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
 import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
 import {
     BsDatepickerModule,
     //BsDatepickerConfig,
     //BsDaterangepickerConfig,
     //BsLocaleService,
 } from 'ngx-bootstrap/datepicker';
//import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { PerfectScrollbarModule } from '@craftsjs/perfect-scrollbar';
import { Angular2CountoModule } from '@awaismirza/angular2-counto';

//PRIMENG
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel'
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider'
import { TabViewModule } from 'primeng/tabview'
import { TagModule } from 'primeng/tag';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeSelectModule } from 'primeng/treeselect';

//DEVEXPRESS

import { DxAccordionModule } from 'devextreme-angular';
import { DxCheckBoxModule } from 'devextreme-angular';
import { DxDataGridModule, DxPopupModule, DxTreeListModule, DxButtonModule } from 'devextreme-angular';
import { DxDateBoxModule } from 'devextreme-angular';
import { DxListModule } from 'devextreme-angular';
import { DxMultiViewModule } from 'devextreme-angular';
import { DxSparklineModule } from 'devextreme-angular';
import { DxToastModule } from 'devextreme-angular';

const imports = [
    AccordionModule,
    Angular2CountoModule,
    //AppBsModalModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CalendarModule,
    CardModule,
    ChipModule,
    ContextMenuModule,
    DialogModule,
    DropdownModule,
    DxAccordionModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxListModule,
    DxMultiViewModule,
    DxPopupModule, 
    DxSparklineModule,
    DxToastModule,
    DxTreeListModule, 
    DynamicDialogModule,
    EditorModule,
    FieldsetModule,
    FontAwesomeModule,
    InplaceModule,
    InputTextareaModule,
    InputTextModule,
    ListboxModule,
    MenubarModule,
    MenuModule,
    ModalModule.forRoot(),
    NgSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PerfectScrollbarModule,
    PickListModule,
    ProgressSpinnerModule,
    ScrollPanelModule,
    SidebarModule,
    SliderModule,
    TableModule,
    TabsModule.forRoot(),
    TabViewModule,
    TagModule,
    ToggleButtonModule,
    TooltipModule,
    TreeSelectModule,
    CollapseModule.forRoot(),
];
const exports = [
    AccordionModule,
    Angular2CountoModule,
    //AppBsModalModule,
    CalendarModule,
    CardModule,
    ChipModule,
    ContextMenuModule,
    DialogModule,
    DropdownModule,
    DxAccordionModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxListModule,
    DxMultiViewModule,
    DxPopupModule, 
    DxSparklineModule,
    DxToastModule,
    DxTreeListModule, 
    DynamicDialogModule,
    EditorModule,
    FieldsetModule,
    FontAwesomeModule,
    InplaceModule,
    InputTextareaModule,
    InputTextModule,
    ListboxModule,
    MenubarModule,
    MenuModule,
    NgSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PerfectScrollbarModule,
    PickListModule,
    ProgressSpinnerModule,
    ScrollPanelModule,
    SidebarModule,
    SliderModule,
    TableModule,
    TabViewModule,
    TagModule,
    ToggleButtonModule,
    TooltipModule,
    TreeSelectModule,
    CollapseModule,
];
const providers = [
    DynamicDialogRef, 
    DialogService,
    //{ provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
    //{ provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
    //{ provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
];


@NgModule({
    imports: [...imports],
    exports: [...exports],
    providers: [...providers],
    declarations: [],
})
export class VendorSharedModule {
}
